// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { ProductsService } from 'src/app/services/products.service';
import { arrayAddDistinct, arrayRemove } from 'src/utils/array.utils';
import { BatchStatus } from 'src/app/entities/batch';
import { findBatchInProduct } from 'src/app/entities/product';

@Injectable({
  providedIn: 'root',
})
export class WriteOffBatchHandler implements IMyTaskHandler {
  name = 'write-off-batch';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private productsService: ProductsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (!task.productId) {
      console.log('ProductId is undefined');
      return of(null);
    }
    const productId: string = task.productId;
    const product = this.productsService.getProductById(productId);
    if (!product) {
      console.log('Product not found');
      return of(null);
    }

    const batch = findBatchInProduct(product, data['Lote']);

    if (!batch) {
      console.log('Batch not found');
      return of(null);
    }

    if (isBatchFinished(data)) {
      batch.status = BatchStatus.Finished;
    }

    if (isBatchDonated(data)) {
      batch.status = BatchStatus.Donated;
    }

    const productUpdateOps = [
      {
        $set: {
          batches: product.batches,
        },
      },
    ];
    return this.productsService.patchProduct(
      product,
      productUpdateOps,
      this.authenticationService.getAuthInfo()
    );
  }
}
function isBatchFinished(data: TaskResponse): boolean {
  return !!data['Produto já não existe'];
}

function isBatchDonated(data: TaskResponse): boolean {
  return data['Destino do produot'] === 'Doação';
}
