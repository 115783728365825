import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of, forkJoin } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { FieldType } from 'src/app/entities/field';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { mergeMap } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CheckCreateEligibilityArea implements IMyTaskHandler {
  name = 'check-create-eligibility-area';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const sectionObservables: Observable<any>[] = [];

    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }

    const typeTask = this.customTaskTypesService.getCustomTaskType(
      'eligibilityResponseEFN'
    );
    if (!typeTask) {
      console.log('Task type eligibilityEFN not found');
      return of(null); // Retorno se o tipo de tarefa não for encontrado
    }

    const typeTaskDescription =
      this.customTaskTypesService.getCustomTaskType('talkProjectEFN');
    if (!typeTaskDescription) {
      console.log('Task type talkProjectEFN not found');
      return of(null); // Retorno se o tipo de tarefa não for encontrado
    }

    // Verifica se pode prosseguir com o tipo de tarefa atual
    if (this.customTaskTypesService.canProceed(task.type, data)) {
      // Criação do primeiro form - Elegibilidade Utilizador
      const eligibilityForm = {
        id: cuid(),
        title: '2 - Elegibilidade Resposta',
        type: FieldType.Form,
        storeId: this.storeService.store?.id, // Certifique-se de que a store foi inicializada
        userIds: [this.storeService.store?.modules?.utilizadorUdeId], // Verifique se existe
        style: {
          imageUrl: 'eligibilidade.svg',
          backgroundColor: '#08a853',
        },
      };

      const sectionObservable = this.fieldsService
        .newField(eligibilityForm, this.authenticationService.getAuthInfo())
        .pipe(
          mergeMap(() => {
            const newTask: Task = {
              id: cuid(),
              type: typeTask.type, // Usando typeTask com segurança
              formId: eligibilityForm.id, // Usando o formId do eligibilityForm
              title: this.getTitle(typeTask, data),
              subtitle: this.getSubtitle(typeTask, data),
              startDate: moment().valueOf(),
              endDate: moment().add(1, 'year').valueOf(),
              visualizationDate: moment().add(1, 'year').valueOf(),
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: this.storeService.store?.id,
                userIds: [this.storeService.store?.modules?.utilizadorUdeId],
              },
              documentsIds: [],
              isCustomType: true,
              precedingTaskId: task.id,
              precedingTaskData: {
                stateDebt: data['Dívidas ao Estado'],
                debtsToState: data['Dívidas a Segurança Social'],
                hasStateDebts:
                  data[
                    'Possuir estabelecimento e/ou ter sede na Região Autónoma dos Acores'
                  ],
                isEligibility: data['É elegivel'],
              },
            };

            return this.tasksService.newTask(
              newTask,
              this.authenticationService.getAuthInfo()
            );
          })
        );

      sectionObservables.push(sectionObservable);

      // Verifica se é elegível para criar o segundo form
      if (data['É elegivel'] === 'Sim') {
        const projectDescriptionForm = {
          id: cuid(),
          title: '3 - Descrição do Projeto',
          type: FieldType.Form,
          storeId: this.storeService.store?.id,
          userIds: [this.storeService.store?.modules?.utilizadorUdeId], // Verifique se existe
          style: {
            imageUrl: 'descricao_do_projeto_ude.svg',
          },
          titleEmptyForm:
            'Parabéns, foi executada a tarefa. Carregue no botão VOLTAR para aceder ao menu principal para aceder a etapa 4 - Acesso à Plataforma Balcão.',
        };

        const projectObservable = this.fieldsService
          .newField(
            projectDescriptionForm,
            this.authenticationService.getAuthInfo()
          )
          .pipe(
            mergeMap(() => {
              const newTask: Task = {
                id: cuid(),
                type: typeTaskDescription.type,
                formId: projectDescriptionForm.id, // Usando o formId correto
                title: this.getTitle(typeTaskDescription, data),
                subtitle: this.getSubtitle(typeTaskDescription, data),
                startDate: moment().valueOf(),
                endDate: moment().add(1, 'year').valueOf(),
                visualizationDate: moment().add(1, 'year').valueOf(),
                nonConformities: [],
                causes: [],
                corrections: [],
                periodicity: 'Única',
                target: {
                  storeId: this.storeService.store?.id,
                  userIds: [this.storeService.store?.modules?.utilizadorUdeId], // Ajuste os IDs conforme necessário
                },
                documentsIds: [],
                isCustomType: true,
                precedingTaskId: task.id,
              };

              return this.tasksService.newTask(
                newTask,
                this.authenticationService.getAuthInfo()
              );
            })
          );

        sectionObservables.push(projectObservable);
      }
    }

    if (sectionObservables.length > 0) {
      return forkJoin(sectionObservables);
    }

    return of(null);
  }
}
