import { Injectable, Injector } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { environment } from 'src/environments/environment';
import { JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { NewMachineUploadInfo, UploadAll } from './upload-all';

interface TemperatureIndicator {
  apiId?:string;
  okRange: { min: number; max: number };
  alertRange: { min: number; max: number };
  dangerRange: { min: number; max: number };
}

export interface MachineIndicator {
  lastReading: any;
  id: string;
  machine: string;
  type: string;
  temperatureMeasurements: { value: string; date: string }[];
  temperatureIndicator: TemperatureIndicator;
  zoneId: string;
  inactive?:boolean;
  historicalSince?:number;
  
  infoMachine: {
    categoria: string;
  };
  sellerData: Array<{
    name: string;
    email: string[];
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class SensorsService {
  private etag = '';
  private cache = new Map<string, MachineIndicator>();
  machines: MachineIndicator[] = [];

  private uploadAll: UploadAll;

  tempFiltersMap: any = {
    Ok: (indicator: MachineIndicator) => {
      const result = this.isOkRange(indicator);
      return result;
    },
    
    Alerta: (indicator: MachineIndicator) => {
      const isNotOk = !this.isOkRange(indicator);
      const isAlarm = this.isAlarmRange(indicator);
      const result = isNotOk && isAlarm;
      return result;
    },
    
    Perigo: (indicator: MachineIndicator) => {
      const isNotOk = !this.isOkRange(indicator);
      const isNotAlarm = !this.isAlarmRange(indicator);
      const isDanger = this.isDangerRange(indicator);
      const result = isNotOk && isNotAlarm && isDanger;
      return result;
    }
  };

  constructor(private http: AuthenticatedHttpService, protected envService: EnvironmentService, private injector: Injector) {
    this.uploadAll = injector.get(UploadAll);
  }

  getMachineIndicatorsFromStore(
    companyId: string,
    storeId: string
  ): Observable<void> {
    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/machines`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );
    return this.http.get(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const indicators: MachineIndicator[] = response.body || [];
        indicators.forEach((i) => this.cache.set(i.id, i));
      }),
      map(() => void 0)
    );
  }

  handleNewMachineError(
    err: any,
    machine: MachineIndicator,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ): void {
    this.uploadAll
      .addPendingNewMachineRequest(
        new NewMachineUploadInfo(machine, authInfo, originalTryTimestamp)
      )
      .subscribe(() => {
        this.cache.set(machine.id, machine);
      });
  }


  newMachine(machineData: any, authInfo: AuthenticationInfo): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;
  
    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/machines`,
      JSON_HEADERS
    );  
    // Define originalTryTimestamp here
    const originalTryTimestamp = Date.now();
  
    return this.http.post(link, machineData, authInfo).pipe(
      tap(() => {
        this.machines.push(machineData); // Just adds the new machine.
      }),
      catchError((err) => {
        this.handleNewMachineError(err, machineData, authInfo, originalTryTimestamp);
        return throwError(err);
      })
    );
  }
  
  
  

  getTemperatureMachinesIndicators(filter: string) {
    // Verifique se o filtro existe em tempFiltersMap antes de tentar aplicá-lo
    if (!this.tempFiltersMap[filter]) {
      return [];
    }

    const filteredIndicators = Array.from(this.cache.values()).filter((indicator) => {
  
      // Filtra apenas os indicadores que têm temperatureIndicator.apiId definido
      if (!indicator.temperatureIndicator || !indicator.temperatureIndicator.apiId) {
        return false;
      }
  
      // Verifica se lastReading e lastReading.value estão definidos
      if (!indicator.lastReading || typeof indicator.lastReading.value !== 'number') {
        return false;
      }
  
      const temperatureValue = indicator.lastReading.value;
  
      let inRange = false;
  
      // Checa o intervalo de acordo com o filtro
      if (filter === 'Ok') {
        const range = indicator.temperatureIndicator.okRange;
      
        if (range && range.min != null && range.max != null) {
          inRange = temperatureValue >= range.min && temperatureValue <= range.max;
        }
      } else if (filter === 'Alerta') {
        const range = indicator.temperatureIndicator.okRange; // Usamos o mesmo range de Ok para verificar se está fora
      
        if (range && range.min != null && range.max != null) {
          inRange = temperatureValue < range.min || temperatureValue > range.max; // Alerta está fora do Ok range
        } 
      } else if (filter === 'Perigo') {
        const dangerRange = indicator.temperatureIndicator.dangerRange;
      
        if (dangerRange && dangerRange.min != null && dangerRange.max != null) {
          inRange = temperatureValue < dangerRange.min || temperatureValue > dangerRange.max;
        }
      }
      
  
      return inRange;
    });
  
    return filteredIndicators;
  }
  

  getMachinesName(): string[] {
    return Array.from(this.cache.values()).map((machine) => machine.machine);
  }

  getMachinesByCategory(categoryMachine: string): string[] {
    return Array.from(this.cache.values())
      .filter(
        (machine) =>
          machine.infoMachine?.categoria?.trim() === categoryMachine.trim() &&
          machine.inactive !== true && // Verifica se não está inativa
          (!machine.historicalSince || machine.historicalSince <= 0) // Verifica se não há histórico ou o valor é inválido
      ) // Filtra pelas máquinas que correspondem à categoria
      .map((machine) => machine.machine); // Mapeia apenas o nome da máquina
  }

  getMachinesSupplier(machineName: string): string[] {
    // Filtrar para encontrar a máquina correspondente pelo nome
    const machine = Array.from(this.cache.values()).find(
      (machine) => machine.machine?.trim() === machineName?.trim()
    );

    // Verificar se a máquina foi encontrada e se infoSupplier existe como array de objetos
    if (machine && Array.isArray(machine.sellerData)) {
      // Mapear para obter os nomes dos fornecedores
      return machine.sellerData.map((supplier) => supplier.name);
    }

    // Se não encontrar ou não houver fornecedores, retornar um array vazio
    return [];
  }

  getMachinesSupplierEmail(equipmentName: string): string[] {
    // Encontrar a máquina no cache com o nome especificado
    const machine = Array.from(this.cache.values()).find(
      (machine) => machine.machine?.trim() === equipmentName?.trim()
    );
  
    // Verificar se a máquina foi encontrada e se há dados de vendedores
    if (machine && Array.isArray(machine.sellerData)) {
      // Obter todos os emails dos vendedores associados à máquina
      const emails = machine.sellerData
        .map((seller: any) => seller.email) // Extrair o campo de email
        .filter((email: string) => typeof email === 'string'); // Filtrar apenas strings válidas
  
      return emails; // Retornar todos os emails como um array
    }
  
    // Se não encontrar, retornar um array vazio
    return [];
  }
  
  

  getOilMachinesWithNameRegex(): string[] {
    const regex = new RegExp('Fritadeira', 'i'); // 'i' flag for case-insensitive search
    return Array.from(this.cache.values())
      .filter((machine) => regex.test(machine.machine))
      .map((filteredMachine) => filteredMachine.machine);
  }

  getMachineById(id: string): MachineIndicator {
    const machine = this.cache.get(id);
    if (!machine) {
      throw new Error(`Machine with id ${id} not found`);
    }
    return machine;
  }

  getMachinesByType(type: string): MachineIndicator[] {
    return Array.from(this.cache.values()).filter(
      (machine) => machine.type === type
    );
  }

  getMachineByName(machineName: string): MachineIndicator | null {
    const machine = Array.from(this.cache.values()).find(
      (m) => m.machine.trim() === machineName.trim()
    );
    return machine || null; // Retorna a máquina encontrada ou null se não houver correspondência
  }

  private isOkRange(indicator: MachineIndicator): boolean {
    if (
      !indicator.temperatureMeasurements ||
      !indicator.temperatureMeasurements.length
    ) {
      return false;
    }
    const temperatureValue = parseFloat(
      indicator.temperatureMeasurements[0].value
    );
    const { min, max } = indicator.temperatureIndicator.okRange;
    return temperatureValue >= min && temperatureValue < max;
  }

  private isAlarmRange(indicator: MachineIndicator): boolean {
    if (
      !indicator.temperatureMeasurements ||
      !indicator.temperatureMeasurements.length
    ) {
      return false;
    }
    const temperatureValue = parseFloat(
      indicator.temperatureMeasurements[0].value
    );
    const { min, max } = indicator.temperatureIndicator.alertRange;
    return temperatureValue >= min && temperatureValue < max;
  }

  private isDangerRange(indicator: MachineIndicator): boolean {
    if (
      !indicator.temperatureMeasurements ||
      !indicator.temperatureMeasurements.length
    ) {
      return false;
    }
    const temperatureValue = parseFloat(
      indicator.temperatureMeasurements[0].value
    );
    const { min, max } = indicator.temperatureIndicator.dangerRange;
    return temperatureValue >= min && temperatureValue < max;
  }
}
