<div class="scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{ 'PHOTO' | translate }}</h3>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalActions">
        <div class="actionsLeft">
          <img
            src="assets/img/plus.svg"
            class="fa-btn"
            (click)="pressGreenPlusButton()"
          />
          <h5 (click)="pressGreenPlusButton()">
            {{ 'PHOTO_NEW' | translate }}
          </h5>
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="close();">
            {{ 'OK' | translate }}
          </button>
        </div>
      </div>
      <div class="modalPicture">
        <img
          src="assets/img/delete_picture_button.svg"
          class="fa-btn removePic"
          *ngIf="photos.length > 0"
          (click)="pressRedMinusButton()"
        />
        <img
          class="pic"
          height="100%"
          width="100%"
          [src]="currentPhotoPath"
          *ngIf="photos[0]"
        />
        <img
          class="photoArrow"
          src="assets/img/backward_arrow.svg"
          (click)="pressArrowLeft()"
        />
        <img
          class="photoArrow"
          style="float: right"
          src="assets/img/arrow_forward.svg"
          (click)="pressArrowRight()"
        />
      </div>
    </div>
  </div>
</div>
