import {Injectable} from '@angular/core';

@Injectable()
export class TabsService {
  
  justificationFilter = 'NONE';
  historyFilter = 'NONE';
  tasksFilter = 'NONE';
  
}
