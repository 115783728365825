
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalController, NavParams } from "@ionic/angular";
import moment from 'moment';
import { getProductDesignation, Product } from "../../../../entities/product";
import { ResponseField } from "../../../../entities/tasks/task";

@Component({
  selector: 'app-reception-detail-modal',
  templateUrl: "./reception-detail.modal.html",
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ReceptionDetailModal implements OnInit {

  product = this.navParams.data["product"];
  tagInfo = this.navParams.data["tagInfo"];
  receptionDate: string = new Date().toISOString().substr(0, 16);
  quantityTags: number = this.tagInfo.quantityTags;
  nonApplicable: boolean = false;  // Assuming this is needed


  productNameResponseField: ResponseField = {
    name: "Produto",
    type: "product",
    responseValidation: {
      required: true,
    },
    placeholder: "Produto",
    required: true,
    locked: true,
  };
/*   nonApplicableResponseField: ResponseField = {
    name: "Não aplicável",
    type: "boolean",
  }; */
  receptionDateResponseField: ResponseField = {
    name: "Data de receção",
    type: "datetime",
    responseValidation: {
      required: true,
    },
    required: true,
  };
  quantityTagsResponseField: ResponseField = {
    name: `Quantidade de etiquetas a imprimir`,
    type: "number",
    responseValidation: {
      required: true,
    },
    required: true,
  };

  receptionDateControl = new FormControl("", [Validators.required]);

  formGroup: FormGroup = this.formBuilder.group([this.receptionDateControl]);


  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private formBuilder: FormBuilder
  ) {
    this.product = this.navParams.get('product');
    this.tagInfo = this.navParams.get('tagInfo');
    this.quantityTags = this.tagInfo.quantityTags;
    this.formGroup = this.formBuilder.group({
      receptionDate: this.receptionDateControl
    });
  }

  ngOnInit() {
    this.product = this.navParams.get('product');
    this.tagInfo = this.navParams.get('tagInfo');
    this.quantityTags = this.tagInfo.quantityTags;
  }

  async close() {
    await this.modalController.dismiss({
      quantityTags: this.quantityTags,
    });
  }

  pressPrint() {
    // Implementation for the print button
  }

  getProductDesignation(product: Product) {
    return getProductDesignation(product);
  }
}
