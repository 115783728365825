export function arrayRemove(arr: any[] = [], item: any) {
  let idx = arr.indexOf(item);
  if (idx !== -1) {
    arr.splice(idx, 1);
  }
  return arr;
}

export function arrayRemoveIdx(arr: any[] = [], index: number) {
  arr.splice(index, 1);
  return arr;
}

export function arrayAddDistinct(arr: any[] = [], item: any) {
  if (!arr) {
    // If the array does not exist, create a new one with the item
    return [item];
  }
  if (arr.indexOf(item) === -1) {
    // If the item does not exist in the array, add it
    arr.push(item);
  }
  return arr;
}

export function arrayContains(arr: any[] = [], item: any) {
  return arr.indexOf(item) !== -1;
}

export function arrayAddOrReplace(arr: any[] = [], item: any, key?: string) {
  if (key) {
    let idx = arr.findIndex((item) => item.id === key);
    if (idx === -1) {
      arr.unshift(item);
    } else {
      arr[idx] = item;
    }
  }
}

export function distinct<T>(array: T[]): T[] {
  return array.filter(onlyUnique);

  function onlyUnique<T>(value: T, index: number, self: T[]) {
    return objectIndexOf(self, value) === index;
  }

  /** 08062022 - https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#3-shallow-equality */
  function shallowEqual(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  /** 08062022 - https://stackoverflow.com/a/12604120/4227263 */
  function objectIndexOf<T>(array: T[], object: T): number {
    for (let i = 0; i < array.length; ++i) {
      if (shallowEqual(array[i], object)) {
        return i;
      }
    }
    return -1;
  }
}
