import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResponseField } from '../../../entities/tasks/task'; // Update path as needed

@Component({
  selector: 'app-divider-input',
  styleUrls: ['./divider.input.scss'],
  template: `
    <div
      class="modalTextSection"
      [ngClass]="{
        small: responseField.template?.size === 'small',
        medium: responseField.template?.size === 'medium',
        large: responseField.template?.size === 'large',
        'no-border': responseField.template?.noLine
      }"
    >
      <div class="text-container">
        <div *ngIf="!responseField.template?.noText" class="text">
          {{ getTranslation(responseField) }}
        </div>
        <div class="icon-container" *ngIf="responseField.isGrouper">
          <ion-icon
            name="arrow-down"
            class="icon"
            *ngIf="responseField.isHiding"
          ></ion-icon>
          <ion-icon
            name="arrow-up"
            class="icon"
            *ngIf="!responseField.isHiding"
          ></ion-icon>
        </div>
      </div>
    </div>
  `,
})
export class DividerInputComponent {
  @Input() responseField!: ResponseField;
  @Input() getTranslation: (responseField: ResponseField) => string = (
    responseField
  ) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.name || '';
    } else if (lang === 'es') {
      return responseField.nameEs || responseField.name || '';
    }
    return responseField.name || '';
  };

  constructor(private translator: TranslateService) {}
}
