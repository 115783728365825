import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of, catchError } from 'rxjs';
import {
  Batch,
  calculateExpirationDate,
  getBatchStatus,
  CreatedByMethod,
} from '../../entities/batch';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';
import { IMyTaskHandler } from '../handlers.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import cuid from 'cuid';
import { MomentDateFormats } from 'src/utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class CreateNewBatch implements IMyTaskHandler {
  name = 'create-new-batch';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private productsService: ProductsService,
    private storesService: StoresService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const productId = task.productId;

    if (typeof productId !== 'string') {
      console.log('Invalid product ID');
      return of(null);
    }

    const product = this.productsService.getProductById(productId);
    if (!product) {
      console.log('Product not found');
      return of(null);
    }

    let batch: Batch | null = null;

    // Determine if a batch should be created or updated
      if ('Data de validade atribuída' in data) {
        batch = this.upsertBatch(
          'Pré-embalado',
          data['Data de pré-embalamento'],
          'Data de validade atribuída',
          task,
          data,
          product
        );
      } else if (data['Validade Pré-embaladas']) {
        batch = this.upsertBatch(
          'Pré-embalado',
          data['Data'],
          'Validade Pré-embaladas ',
          task,
          data,
          product
        );
      } else {
        batch = this.upsertBatch(
          'Fabrico',
          data['Período de fabrico / hora'],
          data['Durabilidade de vida']
            ? 'Durabilidade de vida'
            : 'Durabilidade de vida (Balcão tradicional)',
          task,
          data,
          product
        );
      }

      if (batch) {
        return this.productsService
          .newProduct(product, this.authenticationService.getAuthInfo())
          .pipe(
            catchError((err) => {
              console.log(err);
              return of(null);
            })
          );
      
    }
    return of(null);
  }

  private upsertBatch(
    lotPrefix: string,
    dateValue: string,
    expirationKey: string,
    task: Task,
    data: TaskResponse,
    product: any // Assuming product has a 'batches' property
  ): Batch {
    // Verifica se o batchId está presente na tarefa
    const batchId = task['batchId'];
  
    if (batchId) {
     
      // Busca o lote correspondente pelo ID
      let existingBatch = product.batches.find((b: Batch) => b.id === batchId);
  
      if (existingBatch) {
        console.log('Lote existente encontrado:', existingBatch);
        
        // Atualiza o lote existente
        existingBatch.expirationDate = calculateExpirationDate(data[expirationKey] || '');
        existingBatch.status = getBatchStatus(
          existingBatch.expirationDate,
          this.storesService.getExpiringWarningsDays()
        );
        existingBatch.createdBy = {
          method: CreatedByMethod.Task,
          creationDate: moment(data.clientDate).format(MomentDateFormats.YYYYMMDD_HHmm),
          taskId: task.id,
        };
  
        return existingBatch;
      } else {
        console.log('Lote não encontrado pelo batchId:', batchId);
      }
    }
  
    // Caso contrário, cria um novo lote
    const expirationDate = calculateExpirationDate(data[expirationKey] || '');
    const formattedDate = moment(dateValue).format(MomentDateFormats.YYYYMMDD_HHmm);
    const lot = `${lotPrefix}: ${formattedDate}`;
  
    const newBatch: Batch = {
      id: cuid(), // Gerando o ID único
      lot,
      expirationDate,
      status: getBatchStatus(
        expirationDate,
        this.storesService.getExpiringWarningsDays()
      ),
      createdBy: {
        method: CreatedByMethod.Task,
        creationDate: moment(data.clientDate).format(MomentDateFormats.YYYYMMDD_HHmm),
        taskId: task.id,
      },
    };
  
    console.log('Novo lote criado');
  
    // Adiciona o novo lote à lista de batches do produto
    product.batches.unshift(newBatch);
  
    // Associa o novo lote à tarefa
    task['batchId'] = newBatch.id;  
    return newBatch;
  }
  
    
}
