import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { storeAuthGuard } from './guards/store-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'store-login',
    pathMatch: 'full',
  },
  {
    path: 'store-login',
    loadChildren: () =>
      import('./store-login/store-login.module').then(
        (m) => m.StoreLoginPageModule
      ),
  },
  {
    path: 'user-login',
    loadChildren: () =>
      import('./user-login/user-login.module').then(
        (m) => m.UserLoginPageModule
      ),
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [storeAuthGuard],
  },
  // {
  //   path: 'history',
  //   loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  // },
  {
    path: 'justification',
    loadChildren: () =>
      import('./justification/justification.module').then(
        (m) => m.JustificationPageModule
      ),
    canActivate: [storeAuthGuard],
  },
  // {
  //   path: 'labels',
  //   loadChildren: () =>
  //     import('./labels/labels.module').then((m) => m.LabelsPageModule),
  //   canActivate: [storeAuthGuard],
  // },
  {
    path: 'manuals',
    loadChildren: () =>
      import('./manuals/manuals.module').then((m) => m.ManualsPageModule),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'printer',
    loadChildren: () =>
      import('./printer/printer.module').then((m) => m.PrinterPageModule),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'temperature.sensor',
    loadChildren: () =>
      import('./sensors/temperature.sensor.module').then(
        (m) => m.TemperatureSensorPageModule
      ),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'tags',
    loadChildren: () =>
      import('./tags/tags.module').then((m) => m.TagsPageModule),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./tasks/tasks.module').then((m) => m.TasksPageModule),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'fields',
    loadChildren: () =>
      import('./fields/fields.module').then((m) => m.FieldsModule),
    canActivate: [storeAuthGuard],
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsPageModule),
    canActivate: [storeAuthGuard],
  },
  // {
  //   path: 'expiration',
  //   loadChildren: () => import('./expiration/expiration.module').then( m => m.ExpirationPageModule)
  // },t`
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
