// tag-printing.service.ts
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  from,
  Observable,
  of,
  throwError,
  concat,
} from 'rxjs';
import { switchMap, concatMap, ignoreElements } from 'rxjs/operators';
import { calculateUnfrozenDate, Product } from 'src/app/entities/product';
import {
  BrotherPrinterService,
  PrintResult,
} from 'src/app/services/brother-printer.service';
import { ProductsService } from './products.service';
import { parse } from 'date-fns';
import { FNSDateFormats, REFERENCE_DATE } from 'src/utils/date.utils';
import { waitFor } from 'src/utils/async.utils';
import * as htmlToImage from 'html-to-image';

export interface TagInfo {
  batch: BatchTagInfo;
  quantityTags?: number;
}

export interface BatchTagInfo {
  lot?: string;
  expirationDate?: string;
}

export interface ProductWithTagInfo {
  product: Product;
  tagInfo: TagInfo;
}

@Injectable({
  providedIn: 'root',
})
export class TagPrintingService {
  private currentTagSubject = new BehaviorSubject<any>(null);
  currentTag$ = this.currentTagSubject.asObservable();

  constructor(
    private brotherPrinterService: BrotherPrinterService,
    private productsService: ProductsService
  ) {}

  setTag(tag: any) {
    this.currentTagSubject.next(tag);
  }

  clearTag() {
    this.currentTagSubject.next(null);
  }

  printOne(
    preparationDate: Date,
    product: ProductWithTagInfo
  ): Observable<Product> {
    return this.printMany(preparationDate, [product]);
  }

  printMany(
    preparationDate: Date,
    products: ProductWithTagInfo[]
  ): Observable<Product> {
    return from(this.brotherPrinterService.checkPrinter()).pipe(
      switchMap((isAvailable) => {
        if (!isAvailable)
          return throwError(
            () =>
              new Error(
                'Impressora não selecionada. Selecionar uma impressora em Menu > Configuração da impressora.'
              )
          );
        return this.performPrintAll(preparationDate, products);
      })
    );
  }

  private async performPrintProduct(
    preparationDate: Date,
    { product, tagInfo }: ProductWithTagInfo
  ): Promise<void> {
    if (tagInfo && tagInfo.quantityTags) {
      const quantity = +tagInfo.quantityTags;

      const primaryExpirationDate = tagInfo.batch.expirationDate
        ? parse(
            tagInfo.batch.expirationDate,
            FNSDateFormats.YYYYMMDD_HHmm,
            REFERENCE_DATE
          )
        : null;
      try {
        // Use the TagPrintingService to set the tag
        this.setTag({
          product,
          lot: tagInfo.batch.lot,
          primaryExpirationDate: tagInfo.batch.expirationDate || '',
          preparationDate,
          secondaryExpirationDate: this.productsService.calculateExpirationDate(
            preparationDate,
            primaryExpirationDate,
            product
          ),
          unfrozenDate: calculateUnfrozenDate(preparationDate, product),
          unfreezingTime: product.unfreezingTime,
          preparationTime: product.preparationTime,
          secondaryExpirationHoursSameAsPrimary:
            product.secondaryExpirationHoursSameAsPrimary,
        });

        await waitFor(50); // Allow Angular to update the view

        const tagElement = document.getElementById('tag');

        if (tagElement == null) {
          console.debug('Not found tag.');
          throw new Error('Tag element not found.');
        }

        await this.processPrint(tagElement, quantity);

        // Clear the tag after printing
        this.clearTag();
      } catch (error) {
        console.error('Error during processing printing:', error);
        throw error; // Rethrow the error to be caught by the caller
      }
    }
    return;
  }

  performPrintAll(
    preparationDate: Date,
    products: ProductWithTagInfo[]
  ): Observable<Product> {
    return from(products).pipe(
      concatMap((productWithTagInfo) =>
        concat(
          // Emit the product name before printing
          of(productWithTagInfo.product),
          // Perform the printing operation and ignore the result
          from(
            this.performPrintProduct(preparationDate, productWithTagInfo)
          ).pipe(ignoreElements())
        )
      )
    );
  }

  async processPrint(tag: HTMLElement, quantity: number): Promise<void> {
    const printerDPI = 300; // Replace with your printer's DPI if different

    // Get image from html-to-image
    const imgData = await htmlToImage.toPng(tag, {
      pixelRatio: Math.floor(printerDPI / 72),
    });

    const printResult: PrintResult = await this.brotherPrinterService
      .printImage(imgData, quantity)
      .catch((reason) => {
        throw new Error(reason.msg);
      });

    if (printResult.status === 500) {
      throw new Error(printResult.msg);
    }
  }
}
