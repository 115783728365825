import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';
import { Batch } from 'src/app/entities/batch';
import {
  getLastBatchInformation,
  getProductDesignation,
  Product,
} from 'src/app/entities/product';
import { ProductsService } from 'src/app/services/products.service';
import { SyncingService } from 'src/app/services/syncing';

export interface BatchBasics {
  lot?: string;
  expirationDate?: string;
}

export interface ProductWithBatchInfo {
  product: Product;
  batchInfo: BatchBasics;
}

@Component({
  selector: 'reception-products-page',
  templateUrl: 'reception-products.page.html',
  styleUrls: ['../refrigerated-products/refrigerated-products.page.scss'],
})
export class ReceptionProductsPage {
  searchTerm: string = '';
  searchControl: FormControl;
  searching = false;
  isSearched = false;
  filter: any;

  allProducts: Product[] = [];
  products: Product[] = [];
  batchesInfo: Record<string, BatchBasics> = {};
  formGroups: any = {};
  counter = 0;
  private ITEMS_PER_TICK = 12;

  init = () => {
    this.searchTerm = '';
    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search) => {
        this.products = this.allProducts.filter((p: Product) => {
          if (!p || !p.name) return false;
          return (
            p.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
          );
        });
        this.counter = this.ITEMS_PER_TICK;
        this.searching = false;
      });
    this.allProducts = this.productsService
      .getProducts()
      .filter((p) => !p.isPreparedInStore)
      .sort((p1, p2) => {
        return p1.name.localeCompare(p2.name);
      });
    // .filter((p) => p.expirationType === "Refrigerado");
    this.products = this.allProducts;

    this.products.map((p) => {
      if (!this.batchesInfo[p.id]) {
        const lastBatch: Batch | null = getLastBatchInformation(p);
        let expirationDate = lastBatch?.primaryExpirationDate?.substring(0, 10);
        this.batchesInfo[p.id] = {
          lot: lastBatch?.lot,
          expirationDate: expirationDate,
        };
        this.formGroups[p.id] = this.formBuilder.group(
          {
            lot: [''],
            expirationDate: [''],
            quantityTags: [
              '',
              [
                Validators.required,
                Validators.min(0),
                Validators.pattern(/^\d+$/),
              ],
            ],
          },
          {
            validator: (g: FormGroup) => this.atLeastOneFilledValidator(g),
          }
        );
        if (p.ingredientsIds && p.ingredientsIds.length) {
          this.formGroups[p.id].controls.lot.disable();
          this.formGroups[p.id].controls.expirationDate.disable();
        }
      }
    });
    this.counter = this.ITEMS_PER_TICK;
    this.syncing.initer = this.init;
  };

  constructor(
    private productsService: ProductsService,
    private formBuilder: FormBuilder,
    private syncing: SyncingService
  ) {
    this.searchControl = new FormControl();
  }

  ionViewWillEnter() {
    this.init();
  }

  onSearchInput() {
    this.isSearched = true;
    this.searching = true;
  }

  atLeastOneFilledValidator(group: FormGroup) {
    const lot = group.controls['lot'];
    const expirationDate = group.controls['expirationDate'];

    if (!lot.value && !expirationDate.value) {
      return { atLeastOneFilled: true };
    }
    return false;
  }

  isProcessing: boolean = false;
  async saveAll() {
    console.log('BRUH');
    if (this.isProcessing) return;
    this.isProcessing = true;

    const productsToSave: ProductWithBatchInfo[] = this.allProducts
      .filter((p) => this.formGroups[p.id].touched)
      .map((p) => {
        return { product: p, batchInfo: { ...this.batchesInfo[p.id] } };
      })
      .filter((p) => this.isValidBatch(p.batchInfo));

    await this.createBatches(productsToSave);
    productsToSave.forEach(({ product }) => {
      this.formGroups[product.id].touched = false;
    });
    this.isProcessing = false;
  }

  async createBatches(products: ProductWithBatchInfo[]) {
    await this.productsService.processCreateBatchesTasks(products);
  }

  doInfinite(event: any) {
    this.counter =
      this.counter + this.ITEMS_PER_TICK <= this.allProducts.length
        ? this.counter + this.ITEMS_PER_TICK
        : this.allProducts.length;

    // Complete the infinite scroll action
    event.target.complete();
  }

  getProductDesignation(product: Product) {
    return getProductDesignation(product);
  }

  touch(productId: string, type?: string, value?: any) {
    this.formGroups[productId].touched = true;
  }

  isValidBatch(batch: BatchBasics): boolean {
    return !!batch.lot || !!batch.expirationDate;
  }
}
