<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ 'Escolher entrada' | translate }} - {{ product.name }}</h3>
          <img src="assets/img/close.svg" (click)="close()" />
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <ion-content style="height: 300px; overflow-y: auto;">
            <ion-list>
              <ion-item *ngFor="let entry of product.entries" (click)="pressEntry(entry)">
                <ion-label>
                  <h2>Data de entrada: {{ entry.date | date: 'yyyy-MM-dd' }}</h2>
                  <h2>Título: {{ entry.title }}</h2>
                  <h3>Lote: {{ entry.lot }}</h3>
                  <h3>Data de validade: {{ entry.expirationDate }}</h3>
                  <h3>Nº Identificação: {{ entry.idNumber }}</h3>
                </ion-label>
                <ion-icon name="arrow-dropright" slot="end"></ion-icon>
              </ion-item>
              <ion-item *ngIf="product.entries.length === 0">
                <ion-label>Sem entradas disponíveis.</ion-label>
              </ion-item>
            </ion-list>
          </ion-content>
          
        </div>
        <div class="modalActions">
          <div class="actionsLeft">
            <button class="btn btn-cancel" (click)="pressManualEntry()">
              {{ 'ENTRADA MANUAL' | translate }}
            </button>
          </div>
          <div class="actionsRight">
            <button class="btn btn-cancel light-grey" (click)="close()">
              {{ 'CANCEL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  