import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Loading } from '../../utils/loading/loading';
import { AuthenticationService } from './authentication';
import { ConfigsService } from './configs.service';
import { StoresService } from './stores.service';
import { JPEG_HEADERS, JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { NewMediaUploadInfo, UploadAll } from './upload-all';
import { Task } from '../entities/tasks/task';
import { AuthenticationInfo } from '../entities/authentication-info';
import { Media } from '../entities/media';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticatedHttpService } from './authenticated-http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private cache = new Map<string, any>();
  private uploadAll!: UploadAll;

  constructor(
    private authenticatedHttp: AuthenticatedHttpService,
    private loading: Loading,
    private authenticationService: AuthenticationService,
    private configsService: ConfigsService,
    private storesService: StoresService,
    private injector: Injector,
    private platform: Platform,
    protected envService: EnvironmentService
  ) {
    setTimeout(() => (this.uploadAll = this.injector.get(UploadAll)));
  }

  setUploadAll(uploadAll: UploadAll) {
    this.uploadAll = uploadAll;
  }

  private handleNewPictureError(
    err: any,
    id: string,
    originalTryTimestamp: number,
    data: Media,
    authInfo: AuthenticationInfo
  ) {
    this.uploadAll
      .addPendingNewPictureRequest(
        new NewMediaUploadInfo(id, data, authInfo, originalTryTimestamp)
      )
      .subscribe();
  }

  async downloadMedia(pId: string): Promise<void> {
    try {
      const authInfo = this.authenticationService.getAuthInfo();
      if (!authInfo) {
        throw new Error('Authentication information is missing');
      }
      const url = `${this.envService.getApiUri()}/media/${pId}`;
      if (this.platform.is('android') && this.platform.is('capacitor')) {
        await Filesystem.downloadFile({
          url,
          headers: {
            'Access-token': this.authenticationService.getAuthToken(),
          },
          path: `${pId}.jpeg`,
          directory: Directory.Data,
        });
      }
    } catch (error) {
      console.error('Error downloading media', error);
    }
  }

  getSingleMediaDataFromServer(pId: string): Observable<string> {
    const link = new Link(`${this.envService.getApiUri()}/media/${pId}`, {
      ...JPEG_HEADERS,
    });
    return this.authenticatedHttp.getBlob(link).pipe(
      map((response: any) => {
        return URL.createObjectURL(response.body);
      })
    );
  }

  getPicturesFromTask(task: Task): void {
    this.cache = new Map();
    if (task.responses) {
      task.responses.forEach((res) => {
        if (res.picturesIds) {
          res.picturesIds.forEach(async (pId: string) => {
            try {
              await Filesystem.stat({
                path: `${pId}.jpeg`,
                directory: Directory.Data,
              });
            } catch (error: any) {
              if (error.message === 'File does not exist') {
                await this.downloadMedia(pId);
              }
            }
          });
        }
      });

      if (
        [
          'goodsReception',
          'goodsReceptionNP',
          'goodsReception2',
          'goodsReceptionNP2',
        ].includes(task.type)
      ) {
        task.responses.forEach((res: any) => {
          if (res.ticketPicturesIds) {
            res.ticketPicturesIds.forEach(async (pId: string) => {
              try {
                await Filesystem.stat({
                  path: `${pId}.jpeg`,
                  directory: Directory.Data,
                });
              } catch (error: any) {
                if (error.message === 'File does not exist') {
                  await this.downloadMedia(pId);
                }
              }
            });
          }
          if (res.invoicePicturesIds) {
            res.invoicePicturesIds.forEach(async (pId: string) => {
              try {
                await Filesystem.stat({
                  path: `${pId}.jpeg`,
                  directory: Directory.Data,
                });
              } catch (error: any) {
                if (error.message === 'File does not exist') {
                  await this.downloadMedia(pId);
                }
              }
            });
          }
        });
      }

      if (
        [
          'confectionTraceability',
          'preparationTraceability',
          'rawMaterialTraceability',
          'defrostingTraceability',
        ].includes(task.type)
      ) {
        task.responses.forEach((res: any) => {
          if (res.productPicturesIds) {
            res.productPicturesIds.forEach(async (pId: string) => {
              try {
                await Filesystem.stat({
                  path: `${pId}.jpeg`,
                  directory: Directory.Data,
                });
              } catch (error: any) {
                if (error.message === 'File does not exist') {
                  await this.downloadMedia(pId);
                }
              }
            });
          }
        });
      }
    }
  }

  async getInfoFromTask(task: Task): Promise<void> {
    if (!this.configsService.shouldUse3GToDownloadMedia()) {
      return;
    }

    this.cache = new Map();

    if (task.description?.documentId) {
      try {
        await Filesystem.stat({
          path: `${task.description.documentId}.pdf`,
          directory: Directory.Data,
        });
      } catch (error: any) {
        if (error.message === 'File does not exist') {
          try {
            const link = new Link(
              `${this.envService.getApiUri()}/media/${task.description.documentId}`,
              { responseType: 'blob' }
            );
            const response = await firstValueFrom(
              this.authenticatedHttp.get(link)
            );

            // const base64Data = await this.convertBlobToBase64(response);

            await Filesystem.writeFile({
              path: `${task.description.documentId}.pdf`,
              // data: base64Data.split(',')[1], // Strip the data URL prefix
              data: '', // Strip the data URL prefix

              directory: Directory.Data,
              encoding: Encoding.UTF8,
            });
          } catch (downloadError) {
            console.error('Error downloading the file', downloadError);
          }
        } else {
          // console.error('Error checking file existence', error);
        }
      }
    }
  }

  readPicture(id: any): any {
    return Filesystem.readFile({
      path: `${id}.jpeg`,
      directory: Directory.Data,
    });
  }

  submitPicture(
    id: string,
    data: Media,
    authInfo: AuthenticationInfo
  ): Observable<any> {
    const originalTryTimestamp = Date.now();
    const link = new Link(this.envService.getApiUri() + '/media/' + id, JSON_HEADERS);
    const picture = { id: id, data: data.data, contentType: 'image/jpeg' };
    this.cache.set(id, picture);

    const regex = /^data:(.+);base64,(.*)$/;
    const matches = data.data.match(regex);
    if (!matches) {
      throw new Error('Invalid image data format');
    }

    const ext = matches[1];

    return this.authenticatedHttp
      .put(
        link,
        { id: id, data: data.data, contentType: 'image/jpeg' },
        authInfo
      )
      .pipe(
        tap((res) => console.log(res)),
        catchError((err) => {
          this.handleNewPictureError(
            err,
            id,
            originalTryTimestamp,
            data,
            authInfo
          );
          return err;
        })
      );
  }

  getPicture(id: string): any {
    return this.cache.get(id);
  }
}
