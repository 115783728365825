import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of, catchError } from 'rxjs';
import {
  Batch,
  calculateExpirationDate,
  getBatchStatus,
  CreatedByMethod,
} from '../../entities/batch';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';
import { IMyTaskHandler } from '../handlers.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import cuid from 'cuid';
import { calculateUnfrozenDate, Product } from 'src/app/entities/product';
import {
  FNSDateFormats,
  MomentDateFormats,
  REFERENCE_DATE,
} from 'src/utils/date.utils';
import { format, parse } from 'date-fns';
import { setNonce } from '@ionic/pwa-elements/loader';

@Injectable({
  providedIn: 'root',
})
export class CreateNewBatchIber implements IMyTaskHandler {
  name = 'create-new-batch-iber';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private productsService: ProductsService,
    private storesService: StoresService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const productId = task.productId;

    if (typeof productId !== 'string') {
      console.log('Invalid product ID');
      return of(null);
    }

    const product = this.productsService.getProductById(productId);
    if (!product) {
      console.log('Product not found');
      return of(null);
    }

    let batch: Batch | null = null;

    batch = this.upsertBatch(
      data['Lote'],
      data['Validade'],
      task,
      product
    );

    if (batch) {
      data['batchId'] = batch.id;
      return this.productsService
        .newProduct(product, this.authenticationService.getAuthInfo())
        .pipe(
          catchError((err) => {
            console.log(err);
            return of(null);
          })
        );
    }
    return of(null);
  }

  private upsertBatch(
    lot: string,
    primaryExpirationDate: string,
    task: Task,
    product: Product // Assuming product has a 'batches' property
  ): Batch {
    // Caso contrário, cria um novo lote
    const newBatch: Batch = {
      id: cuid(), // Gerando o ID único
      lot,
      expirationDate: primaryExpirationDate,
      primaryExpirationDate,
      status: getBatchStatus(primaryExpirationDate, 1),
      createdBy: {
        method: CreatedByMethod.Task,
        creationDate: moment(primaryExpirationDate).format(
          MomentDateFormats.YYYYMMDD_HHmm
        ),
        taskId: task.id,
      },
    };

    console.log('Novo lote criado');

    // Adiciona o novo lote à lista de batches do produto
    product.batches.unshift(newBatch);

    // Associa o novo lote à tarefa
    task['batchId'] = newBatch.id;
    return newBatch;
  }
}
