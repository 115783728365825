import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { debounceTime } from 'rxjs/operators';
import { Product } from 'src/app/entities/product';


@Component({
  selector: 'app-input-barcode-modal',
  templateUrl: 'input-barcode.modal.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InputBarcodeModal implements OnInit {
  barcodeValue = {
    text: ''
  };

  searchControl = new FormControl();
  searchResult: Product[] = [];

  constructor(
    private modalCtrl: ModalController,
    private storeService: StoresService,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(search => {
      if (!search) {
        this.searchResult = [];
      } else {
        this.searchResult = this.productsService.getProducts().filter((p: Product) => {
          if (p.supplierName === 'Fabricado') return false;
          if (p.barcode && p.barcode.itm8 && p.barcode.itm8.includes(search)) {
            return true;
          }
          if (p.barcode && p.barcode.value) {
            if (!Array.isArray(p.barcode.value)) {
              if (p.barcode.value.length < 13) {
                if (p.barcode.value.toString().includes(search.substring(0, 7))) {
                  return true;
                }
              }
              if (p.barcode.value.toString().includes(search))
                return true;
            }
            for (let i = 0; i < p.barcode.value.length; ++i) {
              if (p.barcode.value[i].length < 13) {
                if (p.barcode.value[i].toString().includes(search.substring(0, 7))) {
                  return true;
                }
              }
              if (p.barcode.value[i].toString().includes(search))
                return true;
            }
          }
          if (!p.name) return false;
          return p.name.toLowerCase().includes(search.toLowerCase());
        });
      }
    });
  }


  close() {
    this.modalCtrl.dismiss();
  }

  pressSubmit() {
    this.barcodeValue.text = this.barcodeValue.text.toString();
    this.modalCtrl.dismiss(this.barcodeValue);
  }

  pressProduct(product: Product) {
    this.barcodeValue.text = product.barcode.itm8 || product.barcode.value as string;
    if (!this.barcodeValue.text) {
      if (product && product.barcode && product.barcode.value && Array.isArray(product.barcode.value)) {
        this.barcodeValue.text = product.barcode.value[0];
      }
    }
    this.modalCtrl.dismiss(this.barcodeValue);
  }
}
