import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { JSON_HEADERS } from '../../utils/headers';
import { environment } from 'src/environments/environment';
import { Field, FieldType, FORM, Level, LEVEL } from '../entities/field';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { NewFieldUploadInfo, UploadAll } from './upload-all';
import { StoresService } from './stores.service';
import { AuthenticatedHttpService } from './authenticated-http';
import { Link } from '../../utils/link';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class FieldsService {
  private cache = new Map<string, any>();
  private cacheSyncing = new Map<string, any>();

  private etag = '';

  private uploadAll: UploadAll;

  constructor(
    private http: AuthenticatedHttpService,
    private storesService: StoresService,
    private injector: Injector,
    protected envService: EnvironmentService
  ) {
    this.uploadAll = injector.get(UploadAll);
  }

  private static mapFieldType(type: string): FieldType {
    if (type === 'level') {
      return FieldType.Level;
    } else if (type === 'form') {
      return FieldType.Form;
    }
    throw new Error('Unknown field type');
  }

  getFieldsFromStore(companyId: string, storeId: string) {
    const link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/fields`,
      {
        ...JSON_HEADERS,
        "If-None-Match": this.etag,
      }
    );
  
    return this.http.get(link).pipe(
      map((response: any) => {
        const resp = {
          fields: [],
          status: response.status,
        };
        if (response.status === 304) {
          return resp;
        }
        this.cache = new Map();
        this.etag = response.headers.get('etag') || '';
        const fields = response.body || [];
        resp.fields = fields.map((field: any) => {
          if (field.type === LEVEL) {
            field.type = FieldsService.mapFieldType(field.type);
            field.childrenIds = this.getChildren(field, fields);
            return field;
          } else if (field.type === FORM) {
            field.type = FieldsService.mapFieldType(field.type);
            return field;
          }
        });
        return resp;
      }),
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        response.fields.forEach((f:any) => {
          this.cache.set(f.id, f);
        });
        this.cache.set("root-level", {
          id: "root-level",
          storeId: storeId,
          title: "Root Field",
          type: LEVEL,
          childrenIds: this.getRootFieldChildren(response.fields),
        });
      })
    );
  }

  // getFieldsFromStore(companyId: string, storeId: string) {
  //   const link = new Link(
  //     this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/fields`,
  //     {
  //       ...JSON_HEADERS,
  //       'If-None-Match': this.etag,
  //     }
  //   );

  //   return this.http.get(link).pipe(
  //     map((response: any) => {
  //       const resp = { fields: [], status: response.status };
  //       if (response.status === 304) {
  //         return resp;
  //       }
  //       this.cache = new Map();
  //       this.etag = response.headers.get('etag') || '';
  //       const fields = response.body || [];
  //       resp.fields = fields.map((field: any) => {
  //         field.type = FieldsService.mapFieldType(field.type);
  //         if (field.type === LEVEL) {
  //           field.childrenIds = this.getChildren(field, fields);
  //         }
  //         return field;
  //       });
  //       return resp;
  //     }),
  //     tap((response: any) => {
  //       if (response.status === 304) {
  //         return;
  //       }
  //       response.fields.forEach((f: Field) => {
  //         this.cache.set(f.id, f);
  //       });
  //       this.cache.set('root-level', {
  //         id: 'root-level',
  //         storeId: storeId,
  //         title: 'Root Field',
  //         type: LEVEL,
  //         childrenIds: this.getRootFieldChildren(response.fields),
  //       });
  //     }),
  //     catchError((error) => throwError(error))
  //   );
  // 

  getRootLevel(): any {
    return this.cache.get('root-level');
  }

  getField(fieldId: string): Field {
    return this.cache.get(fieldId);
  }

  isLevel(field: Field): boolean {
    return field.type === LEVEL;
  }

  isForm(field: Field): boolean {
    return field.type === FORM;
  }

  getChildrenFromLevel(field: Level | undefined): Field[] {
    if (!field || !field.childrenIds) {
      return [];
    }
    return field.childrenIds.map((cId) => this.getField(cId));
  }

  getForms(): Field[] {
    return Array.from(this.cache.values()).filter((f) => f.type === FORM);
  }

  getFormTitle(formId: string): string {
    const form = this.getField(formId);
    return form ? form.title : '';
  }

  updateLevelChildrenWithNewChildren(
    fieldId: string,
    childrenId: string
  ): Field {
    let field = this.cache.get(fieldId);
    field.childrenIds.push(childrenId);
    this.cache.set(fieldId, field);
    return field;
  }

  private getRootFieldChildren(fields: any[]): string[] {
    return fields.filter((f) => !f.upperLevelId).map((f) => f.id);
  }

  private getChildren(field: any, fields: any[]): string[] {
    return fields.filter((f) => f.upperLevelId === field.id).map((f) => f.id);
  }

  getFieldsWithoutChildren(): Field[] {
    return Array.from(this.cache.values()).filter((f) => {
      return (
        Array.from(this.cache.values()).filter((f1) => f1.upperLevelId === f.id)
          .length === 0 && f.id !== 'root-level'
      );
    });
  }

  getAllDescendantsFromField(field: Field): Field[] {
    return Array.from(this.cache.values()).filter((f) => {
      return f.upperLevelIds ? f.upperLevelIds.indexOf(field.id) > -1 : false;
    });
  }

  getAllRootLevelFields(): Field[] {
    return Array.from(this.cache.values()).filter((f) => !f.upperLevelId);
  }

  handleNewFieldSuccess(field: Field): void {
    if (this.uploadAll.uploading) {
      this.removeFieldFromCaches(field);
    } else {
      if (field.upperLevelId) {
        const upperLevel = this.cache.get(field.upperLevelId);
        if (upperLevel.childrenIds) {
          upperLevel.childrenIds.push(field.id);
        } else {
          upperLevel.childrenIds = [field.id];
        }
        this.cache.set(upperLevel.id, upperLevel);
      } else {
        const rootLevel = this.cache.get('root-level');
        rootLevel.childrenIds.push(field.id);
        this.cache.set(rootLevel.id, rootLevel);
      }
      this.cache.set(field.id, field);
    }
  }

  handleNewFieldError(
    err: any,
    field: Field,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ): void {
    this.uploadAll
      .addPendingNewFieldRequest(
        new NewFieldUploadInfo(field, authInfo, originalTryTimestamp)
      )
      .subscribe(() => {
        this.cache.set(field.id, field);
      });
  }

  newField(field: Field, authInfo: AuthenticationInfo): Observable<any> {
    const originalTryTimestamp = Date.now();
    const user: UserInfo | undefined = authInfo.user;
    if (!user) {
      return throwError('User info is missing in authInfo');
    }

    const companyId = user.companyId;
    const storeId = user.storeId;
    const link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/fields/${field.id}`,
      JSON_HEADERS
    );
    return this.http.put(link, field, authInfo).pipe(
      tap(() => this.handleNewFieldSuccess(field)),
      catchError((err) => {
        this.handleNewFieldError(err, field, authInfo, originalTryTimestamp);
        return throwError(err);
      })
    );
  }

  removeFieldFromCaches(field: Field): void {
    const removeFieldFromCache = (cache: Map<string, any>) =>
      cache.delete(field.id);
    removeFieldFromCache(this.cache);
    removeFieldFromCache(this.cacheSyncing);
  }

  getClosestHerdadeOrLagarField(fieldId: string): any {
    const field = this.cache.get(fieldId);
    if (!field) return null;
    if (field.subtype === 'herdade' || field.subtype === 'lagar') {
      return field;
    } else if (field.upperLevelId) {
      return this.getClosestHerdadeOrLagarField(field.upperLevelId);
    } else {
      return null;
    }
  }

  getClosestHerdadeField(fieldId: string): any {
    const field = this.cache.get(fieldId);
    if (!field) return null;
    if (field.subtype === 'herdade') {
      return field;
    } else if (field.upperLevelId) {
      return this.getClosestHerdadeField(field.upperLevelId);
    }
    return null;
  }

  getClosestLagarField(fieldId: string): any {
    const field = this.cache.get(fieldId);
    if (!field) return null;
    if (field.subtype === 'lagar') {
      return field;
    } else if (field.upperLevelId) {
      return this.getClosestLagarField(field.upperLevelId);
    }
    return null;
  }

  getSovenaLagar(): any {
    return this.cache.get('clku1qm4f00033q743k0t5ds7');
  }

  getFieldByName(title: string): any {
    return Array.from(this.cache.values()).find(
      (f) => f.title === title || f.shortName === title || f.fullName === title
    );
  }

  getFormByName(title: string): any {
    return Array.from(this.cache.values())
      .filter((f) => f.type === FORM)
      .find(
        (f) =>
          f.title === title || f.shortName === title || f.fullName === title
      );
  }

  getHerdadeByShortName(shortName: string): any {
    return Array.from(this.cache.values()).find(
      (f) => f.shortName === shortName
    );
  }

  getFieldName(filterType: string): string[] {
    return Array.from(this.cache.values())
      .filter((f) => f.type === filterType)
      .map((field) => field.title || '');
  }

  getFieldNames(): string[] {
    return Array.from(this.cache.values()).map((field) => field.title || '');
  }

  getFieldBySubtype(subtype: string = 'herdade'): string[] {
    return Array.from(this.cache.values())
      .filter((f) => f.subtype === subtype)
      .map((field) => field.fullName);
  }

  getFieldNameQuiz(filterType: string): string[] {
    return Array.from(this.cache.values())
      .filter((f) => f.type === filterType && f.title !== 'Reabrir Pergunta')
      .map((field) => field.title || '');
  }

  
}
