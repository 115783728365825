<ion-header>
  <ion-toolbar>
    <ion-title>Atenção!</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Fechar</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <app-expiring-products-summary
    [expiringProducts]="expiringProducts"
  ></app-expiring-products-summary>
</ion-content>
