import { Injectable } from '@angular/core';
import moment from 'moment';
import { PrintService } from './print.service';
import { Task, TaskResponse } from '../entities/tasks/task';

interface Producer {
  code: string;
  nif: string;
  producerNumber: string;
  ggn: string;
}

interface Buyer {
  fullName: string;
  nif: string;
  country: string;
  postalCode: string;
  address: string;
  deliveryLocation: string;
}

interface Plot {
  parish: string;
  code: string;
  counters?: Array<{ dId: string }>;
}

@Injectable({
  providedIn: 'root',
})
export class PrintTorribaService {
  constructor(private printService: PrintService) {}

  async printTransportGuide(
    task: Task,
    response: TaskResponse,
    producer: Producer,
    buyer: Buyer,
    plot: Plot,
    deviceInfo: { uuid: string }
  ) {
    let logoBase64;
    if (
      task.type === 'inProductApanha' ||
      task.type === 'inProductApanhaPimento' ||
      task.type === 'inProductApanhaPea2'
    )
      logoBase64 = await this.printService.getImageBase64(
        this.printService.getImagePath('assets/img/logo_torriba_small.jpg')
      );
    else
      logoBase64 = await this.printService.getImageBase64(
        this.printService.getImagePath('assets/img/logo_cerpro_small.jpg')
      );
    await this.printService.printBase64(logoBase64);
    if (
      task.type === 'inProductApanha' ||
      task.type === 'inProductApanhaPimento' ||
      task.type === 'inProductApanhaPea2'
    ) {
      await this.printService.printTextBoldCenter(` `);
    } else {
      await this.printService.printTextBoldCenter(`CERPRO`);
    }
    await this.printService.printTextBoldCenter(
      `DOCUMENTO COMPROVATIVO DO TRANSPORTE DE BENS`
    );
    await this.printService.printTextBoldCenter(`AGRÍCOLAS`);
    await this.printService.printTextSmallBoldCenter(
      `(n.1, alínea d) e n.3 e 4 do art. 3. do DL n. 147/2003, de 11 de julho)`
    );
    await this.printService.printTextLine('');
    await this.printService.printTextLine(`Guia N. ${response['clientDate']}`);
    await this.printService.printTextLine(
      `Data: ${moment(response['clientDate']).format('DD/MM/YYYY')}`
    );
    if (task.printsCount) task.printsCount++;
    else task.printsCount = 1;
    await this.printService.printTextLine(
      `Cópia física x${task.printsCount || 0}`
    );
    await this.printService.printTextLine(`Produtor: ${producer.code}`);
    await this.printService.printTextLine(`NIF: ${producer.nif}`);
    await this.printService.printTextLine(
      `N.Produtor ${producer.producerNumber}`
    );
    await this.printService.printTextLine(`GGN: ${producer.ggn}`);
    await this.printService.printTextLine('Avaliação Transporte: Conforme');
    await this.printService.printTextLine(
      'Produto Certificado em GLOBAL G.A.P.'
    );
    await this.printService.printTextLine('');
    await this.printService.printTextLine(`Produto: ${response['Produto']}`);
    await this.printService.printTextLine(
      `Variedade: ${response['Variedade']}`
    );
    if (task.type === 'inProductApanha' || task.type === 'inProductApanhaMilho')
      await this.printService.printTextLine('Quantidade: a granel');
    if (task.type !== 'inProductApanhaPimento')
      await this.printService.printTextLine(
        'Peso: ' + (response['Peso'] ? response['Peso'] : 'a pesar no destino')
      );
    if (task.type === 'inProductApanhaMilho') {
      await this.printService.printTextLine(
        `Tipo de produto: ${response['Tipo de produto']}`
      );
    }
    if (
      task.type === 'inProductApanhaPimento' ||
      task.type === 'inProductApanhaPea2'
    )
      await this.printService.printTextLine(
        `Método de colheita: ${response['Método de colheita']}`
      );
    await this.printService.printTextLine(`Parcela: ${response['Parcela']}`);
    if (task.type !== 'inProductApanhaPimento')
      await this.printService.printTextLine(
        `Colhedora: ${response['Colhedora'] ? response['Colhedora'] : '---'}`
      );
    if (task.type !== 'inProductApanhaPimento')
      await this.printService.printTextLine(
        `Operador: ${response['Operador'] ? response['Operador'] : '---'}`
      );
    if (task.type === 'inProductApanhaPimento')
      await this.printService.printTextLine(
        `Contentores: ${
          response['Contentores'] ? response['Contentores'] : '---'
        }`
      );

    if (
      task.type === 'inProductApanhaPimento' &&
      response['Detalhes do produto']
    ) {
      for (const productType of response['Detalhes do produto']) {
        await this.printService.printTextLine(
          `Tipo de produto: ${
            productType['Tipo de produto']
              ? productType['Tipo de produto']
              : '---'
          }`
        );
        await this.printService.printTextLine(
          `Número: ${productType['Número'] ? productType['Número'] : '---'}`
        );
      }
    }

    await this.printService.printTextLine(
      `ID do dispositivo: ${deviceInfo.uuid || 'web'}`
    );
    await this.printService.printTextLine('');
    await this.printService.printTextLine(`Cliente: ${buyer.fullName}`);
    await this.printService.printTextLine(`NIF do Cliente ${buyer.nif}`);
    await this.printService.printTextLine(`País: ${buyer.country}`);
    await this.printService.printTextLine(`Código Postal: ${buyer.postalCode}`);
    await this.printService.printTextLine(`Morada: ${buyer.address}`);
    await this.printService.printTextLine('');
    await this.printService.printTextLine(
      `Data de carga: ${moment(response['Data de carga']).format(
        'DD/MM/YYYY HH:mm'
      )}`
    );
    await this.printService.printTextLine(
      `Hora: ${moment(response['Data de carga']).format('HH:mm')}`
    );
    await this.printService.printTextLine('');
    await this.printService.printTextLine(
      `Matrícula galera: ${
        response['Matrícula'] || response['Matrícula galera']
      }`
    );
    await this.printService.printTextLine(`Local de carga: ${plot.parish}`);
    await this.printService.printTextLine(
      `Local de descarga: ${buyer.deliveryLocation}`
    );
    await this.printService.printTextLine(
      `Transportador: ${response['Transportador']}`
    );
    await this.printService.printTextLine('CMR:');
    await this.printService.printTextLine('');
    await this.printService.printTextLine(
      'Ass. do Transportador:______________________'
    );
    await this.printService.printTextLine(
      'Ass. do Responsável:________________________'
    );
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextSmallBoldCenter(
      `${this.getBarcode(response, producer, plot)}`
    );
    await this.printService.printBarcode(
      this.getBarcode(response, producer, plot)
    );
  }

  getBarcode(response: TaskResponse, producer: Producer, plot: Plot): string {
    const getPlotCounters = (): string => {
      if (plot.counters)
        return (
          plot.counters.findIndex((e) => e.dId === response['deviceId']) + 1
        ).toString();
      else return '1';
    };

    const getChecksum = (sField: string): number => {
      let sOdd = 0;
      let sEven = 0;
      for (let i = sField.length; i > 0; --i) {
        const char = parseInt(sField.charAt(i - 1), 10);
        if (i % 2 === 0) sEven += char;
        else sOdd += char;
      }
      return (10 - ((sEven + 3 * sOdd) % 10)) % 10;
    };

    const producerNumber = Number(producer.producerNumber);
    const plotCode = Number(plot.code);
    const counter = Number(response['counter'] || 0);

    const barcode =
      '44' +
      producerNumber.toString().padStart(5, '0') +
      plotCode.toString().padStart(5, '0') +
      getPlotCounters().padStart(2, '0') +
      counter.toString().padStart(3, '0');

    return barcode + getChecksum(barcode);
  }
}
