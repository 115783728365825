import { Injectable } from '@angular/core';
import moment from 'moment';
import { PrintService } from './print.service';
import QRCode from 'qrcode';
import { getSociedadeByCode } from '../sdk/myharvest';
import { Task, TaskResponse } from '../entities/tasks/task';

interface Producer {
  societies: any;
  sapCode?: string;
  producerNumber?: string;
}

interface Buyer {
  companyFullName: string;
  address: string;
  nif: string;
  deliveryLocation: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrintSovenaService {
  qrCodeDataURL: any;

  constructor(private printService: PrintService) {}

  async printTransportGuide(
    task: Task,
    response: TaskResponse,
    producer: Producer,
    buyer: Buyer
  ) {
    const societyCode = response['Sociedade'] ? response['Sociedade'][0] : '';
    const society = getSociedadeByCode(producer.societies, societyCode);
    if (task.printsCount) task.printsCount++;
    else task.printsCount = 1;
    await this.printService.printTextBoldCenter(`MyHARVEST`);
    await this.printService.printTextBoldCenter(`Guia de Transporte`);
    await this.printService.printTextLine('');
    await this.printService.printTextBoldCenter(
      `${society.nombre_sociedade || ''}`
    );
    await this.printService.printTextSmallBoldCenter(
      'Sede: ' + society.direccion
    );
    await this.printService.printTextSmallBoldCenter(
      'Escritórios: ' + society.morada_faturacao
    );
    await this.printService.printTextSmallBoldCenter(
      `${society.registo_comercial} e Contribuinte Nº ${society.nif}`
    );
    await this.printService.printTextSmallBoldCenter(
      `Capital Social ${society.capital_social.replace('€', ' Euros')}`
    );
    await this.printService.printTextLine('');
    await this.printService.printTextLine(
      `Guia Nº: ${producer.sapCode || ''}${producer.producerNumber || ''}${
        response['Nº Guia'] || ''
      }`
    );
    await this.printService.printTextLine(
      `Herdade: ${response['Herdade'] || ''}`
    );
    await this.printService.printTextLine(
      `Data: ${moment(response['clientDate']).format('DD/MM/YYYY') || ''}`
    );
    await this.printService.printTextLine(
      `Destinatário: ${buyer.companyFullName || ''}`
    );
    await this.printService.printTextLine(`Morada: ${buyer.address || ''}`);
    await this.printService.printTextLine(`NIF: ${buyer.nif || ''}`);
    await this.printService.printTextLine(
      `De: ${buyer.deliveryLocation || ''}`
    );
    await this.printService.printTextLine(``);
    await this.printService.printTextLine(
      `Saída da parcela: ${
        moment(response['clientDate']).format('HH:mm') || ''
      }`
    );
    await this.printService.printTextLine(
      `Motorista: ${response['Motorista'] || ''}`
    );
    await this.printService.printTextLine(
      `Matrícula Transporte: ${response['Matrícula Transporte'] || ''}`
    );
    await this.printService.printTextLine(
      `Grupo de Colheita: ${response['Grupo de Colheita'] || ''}`
    );
    await this.printService.printTextLine(
      `Parcela: ${response['Parcela'] || ''}`
    );
    await this.printService.printTextLine(
      `Variedade: ${response['Variedade'] || ''}`
    );
    await this.printService.printTextLine(
      `Estado Fitossanitário: ${response['Estado Fitossanitário'] || ''}`
    );
    await this.printService.printTextLine(
      `Qualidade: ${response['Qualidade'] || ''}`
    );
    await this.printService.printTextLine(
      `Tipo de Colheita: ${response['Tipo de Colheita'] || ''}`
    );
    await this.printService.printTextLine(
      `Observações: ${response['Observações Transporte'] || ''}`
    );
    await this.printService.printTextLine('Assinatura:______________________');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printQRCode(
      await this.generateQRCode(task, response, producer, buyer)
    );
    if (response['Pesar 100 azeitonas']) {
      await this.printService.printTextLine('');
      await this.printService.printTextLine('');
      await this.printService.printTextLine('');
      let logoBase64;
      logoBase64 = await this.printService.getImageBase64(
        this.printService.getImagePath('../../assets/img/aceituna_pesar.jpeg')
      );
      await this.printService.printBase64(logoBase64);
    }
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
    await this.printService.printTextLine('');
  }

  getBarcode(producer: Producer, response: TaskResponse) {
    return `${producer.sapCode}${producer.producerNumber}${response['Nº Guia']}`;
  }

  async generateQRCode(
    task: Task,
    response: TaskResponse,
    producer: Producer,
    buyer: Buyer
  ) {
    const valueSeparator = 'YwY';
    const fieldSeparator = 'WyW';
    const dataString =
      'A' +
      valueSeparator +
      getCodigoProduto() +
      fieldSeparator +
      'B' +
      valueSeparator +
      getCodigoProveedor() +
      fieldSeparator +
      'C' +
      valueSeparator +
      getMatricula() +
      fieldSeparator +
      'D' +
      valueSeparator +
      getRemolque() +
      fieldSeparator +
      'E' +
      valueSeparator +
      getParaje() +
      fieldSeparator +
      'F' +
      valueSeparator +
      getCodigoTipoCosecha() +
      fieldSeparator +
      'G' +
      valueSeparator +
      getGrupoCosecha() +
      fieldSeparator +
      'H' +
      valueSeparator +
      getGrupoParcela() +
      fieldSeparator +
      'I' +
      valueSeparator +
      getGuia() +
      fieldSeparator +
      'J' +
      valueSeparator +
      getCodigoVariedade() +
      fieldSeparator +
      'K' +
      valueSeparator +
      getCodigoTermino() +
      fieldSeparator +
      'L' +
      valueSeparator +
      getCodigoEFitosanitario() +
      fieldSeparator +
      'M' +
      valueSeparator +
      getSalidaCampo() +
      fieldSeparator +
      'N' +
      valueSeparator +
      getObservacao() +
      fieldSeparator;
    try {
      this.qrCodeDataURL = await QRCode.toDataURL(dataString);
      return this.qrCodeDataURL;
    } catch (error) {
      console.error(error);
    }

    function getCodigoProduto() {
      return '1';
    }
    function getCodigoProveedor() {
      return response['Sociedade'];
    }

    function getMatricula() {
      return response['Matrícula Transporte'];
    }

    function getRemolque() {
      return response['Matrícula Reboque'];
    }

    function getParaje() {
      return response['Herdade'];
    }

    function getCodigoTipoCosecha() {
      return mapCodigoTipoCosecha(response['Tipo de Colheita']);

      function mapCodigoTipoCosecha(nome: string): number | undefined {
        const map: { [key: string]: number } = {
          'ALVES SEITA': 15,
          BARRILLOS: 10,
          'BUGGY-MORRA': 8,
          'BUGGY-OPTI': 7,
          'COL-ELAIA': 6,
          DALMADEL: 11,
          MANUAL: 12,
          NENHUM: 0,
          'NH-DEYA': 5,
          'NH-ELAIA': 1,
          'NH-NUTRIFARMS': 14,
          'NH-OPTI': 2,
          'NH-POVINDIM': 3,
          'NH-TODOLIVO': 4,
          OTROS: 13,
          'VIBRADOR-MORRA': 9,
          VINDIMOR: 16,
        };

        return map[nome.toUpperCase()];
      }
    }

    function getGrupoCosecha() {
      let grupoDeColheita = response['Grupo de Colheita'];
      if (Array.isArray(grupoDeColheita)) {
        return grupoDeColheita.join(', ');
      }
      return grupoDeColheita;
    }

    function getGrupoParcela() {
      let parcela = response['Parcela'];
      if (Array.isArray(parcela)) {
        return parcela.join(', ');
      }
      return parcela;
    }

    function getGuia() {
      return response['Nº Guia'];
    }

    function getCodigoVariedade() {
      return mapCodigoVariedade(response['Variedade']);

      function mapCodigoVariedade(nome: string): number | undefined {
        const map: { [key: string]: number } = {
          ARBEQUINA: 1,
          ARBOSANA: 2,
          COBRANÇOSA: 10,
          CORDOVIL: 12,
          CUQUILLO: 13,
          GALEGA: 11,
          KORONEIKI: 4,
          'OAC-OI': 17,
          OLIANA: 3,
          OUTRA: 16,
          SIKITITA: 5,
          'Sin Definir': -1,
        };

        return map[nome.toUpperCase()] || -1;
      }
    }

    function getCodigoTermino() {
      const parajeToCodTermino: { [key: string]: number } = {
        abegoaria: 4,
        'abrafama e cabeço do marco': 17,
        abreu: 13,
        agrodois: 6,
        alagada: 13,
        'aldeia da luz': 22,
        almadragueira: 9,
        'andre lopes': 14,
        'azinhal e batalhosos': 7,
        'balhamim olivo': 6,
        barrillo: 19,
        belmeque: 12,
        benazurera: 34,
        'blanquillo alto': 11,
        boavista: 13,
        'braz varela': 5,
        cambroneros: 34,
        casqueira: 21,
        'cavaleira de avis': 14,
        charneca: 33,
        choças: 9,
        'convento de s francisco': 3,
        'corte negra': 25,
        cuadrado: 10,
        cumeada: 7,
        espada: 16,
        'esquenta diabos': 9,
        figueirinha: 27,
        fontanas: 16,
        'freiras de uveda': 13,
        fundação: 7,
        gramicha: 8,
        'herdade da serra': 16,
        hoyuelas: 20,
        huesar: 34,
        'jose do vale': 13,
        lameira: 16,
        linharinhos: 1,
        'malhada velha': 17,
        malpica: 13,
        'maria dona': 3,
        marmelo: 17,
        'marmelo ii': 17,
        'marmelo iii': 17,
        mesquita: 28,
        minas: 7,
        'monte branco norte': 16,
        'monte branco sul': 16,
        'monte da faleira': 6,
        'monte da figueira': 16,
        'monte da serra': 15,
        'monte dos frades': 18,
        montinho: 5,
        'nora uveda e padeira': 13,
        outeiro: 27,
        painho: 5,
        'peixe chamine': 13,
        pereiras: 31,
        'pinheiro da fonte': 16,
        poças: 17,
        potosi: 32,
        rascas: 25,
        'rincón de los frailes': 6,
        romeiral: 13,
        's barnabe-vale canelas': 2,
        's bartolome': 30,
        'santo antão': 7,
        sevillano: 24,
        soberanas: 6,
        'terras da igreja': 5,
        tinhosa: 28,
        'torre do caia': 9,
        valada: 9,
        'vale d aguia': 17,
        'vale d arem': 6,
        'vale de palhas': 33,
        'vale sapateiros': 6,
        'vale viveiros': 17,
        'zambujal-cavaleira de quintos': 26,
        zambujalinho: 26,
      };

      const herdade = response['Herdade']
        ? response['Herdade'].toLowerCase()
        : null;
      const codTermino = parajeToCodTermino[herdade];
      return codTermino !== undefined ? codTermino : null;
    }

    function getCodigoEFitosanitario() {
      return response['Estado Fitossanitário'] === 'OK' ? 1 : 2;
    }

    function getSalidaCampo() {
      return moment(response['Data e Hora de Saída']).format('DDMMYYHHmm');
    }

    function getObservacao() {
      return response['Observações Transporte'];
    }
  }
}
