<ion-header>
    <ion-toolbar>
      <ion-title>{{ 'Pesquise por nome de produto ou código de barras utilizando o campo abaixo.' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <div class="container">
      <div class="form-group">
        <ion-label>{{ "Pesquisa:" | translate }}</ion-label>
        <ion-input class="form-control" type="text" [(ngModel)]="barcodeValue.text" placeholder="0" [formControl]="searchControl"></ion-input>
      </div>
      <ion-content style="height: 300px" scrollY="true">
        <ion-list>
          <ion-item *ngFor="let product of searchResult" (click)="pressProduct(product)">
            <ion-label>
              <h2>{{ product.name }}</h2>
              <p *ngIf="product.brand">Marca: {{ product.brand }}</p>
              <p *ngIf="product.barcode.itm8">Cód. barras interno: {{ product.barcode.itm8 }}</p>
              <p *ngIf="product.barcode.value">EAN: {{ product.barcode.value }}</p>
            </ion-label>
            <ion-icon name="arrow-dropright" slot="end"></ion-icon>
          </ion-item>
          <ion-item *ngIf="searchResult.length === 0">
            {{ "Sem resultados" | translate }}
          </ion-item>
        </ion-list>
      </ion-content>
      <div class="modalActions">
        <div class="actionsLeft"></div>
        <div class="actionsRight">
          <ion-button color="primary" (click)="pressSubmit()">{{ 'OK' | translate }}</ion-button>
          <ion-button color="light" (click)="close()">{{ 'CANCEL' | translate }}</ion-button>
        </div>
      </div>
    </div>
  </ion-content>
  