import { Injectable, Injector } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { Observable } from 'rxjs';
import { tap, map, first } from 'rxjs/operators';
import { Store, StoreManual, TraceabilityModule } from '../entities/store';
import { SyncingService } from './syncing';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  private cache = new Map<string, any>();
  store: any;
  private etag = '';
  syncingService!: SyncingService;

  constructor(
    private http: AuthenticatedHttpService,
    private injector: Injector, protected envService: EnvironmentService
  ) {
    setTimeout(() => {
      this.syncingService = this.injector.get(SyncingService);
    }, 100);
  }

  getStoreFromServer(companyId: string, storeId: string): Observable<any> {
    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}?joinCompany`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );

    return this.http.get(link).pipe(
      map((response: any) => {
        const resp: { store: any; status: number } = {
          store: {},
          status: response.status,
        };
        if (response.status === 304) {
          return resp;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        resp.store = response.body;
        return resp;
      }),
      tap((response) => {
        if (response.status === 304) {
          return;
        }
        this.cache.set(response.store.id, response.store);
        this.store = response.store;
      })
    );
  }

  newSupplier(
    newSuppliers: any,
    authInfo: AuthenticationInfo
  ): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-direct-supplier-list`,
      JSON_HEADERS
    );
    return this.http.post(link, { newSuppliers }, authInfo).pipe(
      tap(() => {
        if (
          this.store &&
          this.store.modules &&
          !this.store.modules.goodsReception
        ) {
          this.store.modules.goodsReception = { directSupplierList: [] };
        }
        // this.store.modules.goodsReception.directSupplierList.push(newSuppliers);
      })
    );
  }

  newNonConformities(
    newNonConformities: any,
    authInfo: AuthenticationInfo
  ): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-nonConformities-list`,
      JSON_HEADERS
    );
    return this.http.post(link, { newNonConformities }, authInfo).pipe(
      tap(() => {
        if (
          this.store &&
          this.store.modules &&
          !this.store.modules.goodsReception
        ) {
          this.store.modules.goodsReception = { nonConformitiesList: [] };
        }
        // this.store.modules.goodsReception.directSupplierList.push(newSuppliers);
      })
    );
  }

  newEmailTo(newEmail: any, authInfo: AuthenticationInfo): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-supportRequests-emailsTo-list`,
      JSON_HEADERS
    );
    return this.http.post(link, { newEmail }, authInfo).pipe(
      tap(() => {
        if (
          this.store &&
          this.store.modules &&
          !this.store.modules.supportRequests
        ) {
          this.store.modules.supportRequests = { emailsTo: [] };
        }

        this.store.modules.supportRequests.emailsTo.push(newEmail);
      })
    );
  }

  newHaccpEmailTo(
    newEmail: any,
    authInfo: AuthenticationInfo
  ): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-emailsReport-haccp-emailsTo-list`,
      JSON_HEADERS
    );
    return this.http.post(link, { newEmail }, authInfo).pipe(
      tap(() => {
        if (!this.store.modules.emailsReport.haccp) {
          this.store.modules.emailsReport.haccp = { emailsTo: [] };
        }
        this.store.modules.emailsReport.haccp.emailsTo.push(newEmail);
      })
    );
  }

  newHelpEmailTo(newEmail: any, authInfo: AuthenticationInfo): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-emailsReport-help-emailsTo-list`,
      JSON_HEADERS
    );
    return this.http.post(link, { newEmail }, authInfo).pipe(
      tap(() => {
        if (!this.store.modules.emailsReport.help) {
          this.store.modules.emailsReport.help = { emailsTo: [] };
        }
        this.store.modules.emailsReport.haccp.emailsTo.push(newEmail);
      })
    );
  }

  newDocsEmailTo(newEmail: any, authInfo: AuthenticationInfo): Observable<any> {
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-emailsReport-docs-emailsTo-list`,
      JSON_HEADERS
    );
    return this.http.post(link, { newEmail }, authInfo).pipe(
      tap(() => {
        if (!this.store.modules.emailsReport.docs) {
          this.store.modules.emailsReport.docs = { emailsTo: [] };
        }
        this.store.modules.emailsReport.docs.emailsTo.push(newEmail);

        if (this.store && this.store.modules?.supportRequests)
          this.store.modules.supportRequests.emailsTo.push(newEmail);
      })
    );
  }

  async getStore(): Promise<Store> {
    if (!this.syncingService.syncingSubject.value) {
      return this.store; // If not syncing, return store immediately (even if null)
    }

    // Wait for syncing to finish before returning the store
    return new Promise<Store>((resolve) => {
      this.syncingService.syncingSubject
        .pipe(first((syncing) => syncing === false)) // Wait for syncing to become false
        .subscribe(() => {
          resolve(this.store); // Return store once syncing is false (even if null)
        });
    });
  }

  getManuals() {
    return this.store?.manuals || [];
  }

  getFolderTitles(): string[] {
    const manuals = this.store?.manuals ?? [];
    return manuals
      .filter(
        (manual: StoreManual) =>
          manual.type === 'folder' && typeof manual.title === 'string'
      )
      .map((folder: StoreManual) => folder.title);
  }

  getReportFolderTitles(): string[] {
    const reports = this.store?.reports ?? [];

    function getSubfolderTitles(contents: any[]): string[] {
      return contents
        .filter((item: any) => item.type === 'folder')
        .map((folder: any) => folder.title);
    }

    return reports
      .filter((item: any) => item.type === 'folder')
      .flatMap((folder: any) => getSubfolderTitles(folder.contents));
  }

  getReportFilesTitles(folderTitle: string): string[] {
    const reports = this.store?.reports ?? [];

    function findFolderByTitle(
      reports: any[],
      folderTitle: string
    ): any | null {
      for (const item of reports) {
        if (item.type === 'folder') {
          if (item.title === folderTitle) {
            return item;
          } else if (Array.isArray(item.contents)) {
            const foundFolder = findFolderByTitle(item.contents, folderTitle);
            if (foundFolder) return foundFolder;
          }
        }
      }
      return null;
    }

    const folder = findFolderByTitle(reports, folderTitle);

    if (folder && Array.isArray(folder.contents)) {
      return folder.contents
        .filter((content: any) => content.type === 'report')
        .map((report: any) => report.title);
    } else {
      return [];
    }
  }

  getReports() {
    return this.store?.reports || [];
  }

  getBuyerByName(buyerName: string) {
    return (
      this.store &&
      this.store.buyers?.find((b: any) => b.shortName === buyerName)
    );
  }

  getBuyerByFullNameAndCompanyFullName(buyerName: string) {
    return (
      this.store &&
      this.store.buyers?.find(
        (b: any) => `${b.fullName} (${b.companyFullName})` === buyerName
      )
    );
  }

  getPlotByName(plotName: string) {
    if (this.store?.plots) {
      return this.store.plots.find((p: any) => p.name === plotName);
    }
  }

  getPlotByFullName(plotFullName: string) {
    if (this.store?.plots) {
      return this.store.plots.find((p: any) => p.fullName === plotFullName);
    }
  }

  getTraceabilityTagsModule(): TraceabilityModule | undefined {
    return this.store?.modules?.traceabilityTags;
  }

  getExpiringWarningsDays(): number {
    return this.store?.modules?.expiration?.expiringWarningDays || 4;
  }
}
