// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, forkJoin, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import cuid from 'cuid';
import moment from 'moment';
import { arrayRemove, arrayAddDistinct } from '../../../utils/array.utils';
import { getTargetUser } from '../../entities/authentication-info';
import {
  isProductExpiringBeforeEndOfDay,
  isProductExpiringInLessThan4Days,
} from '../../entities/product';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpTaskProcessExpiration2 implements IMyTaskHandler {
  name = 'follow-up-task-process-expiration2';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}
  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (!data[`Produto já não existe`]) {
      const productId = task.productId;

      if (typeof productId === 'string') {
        if (isProductExpiringBeforeEndOfDay(data[`Lote`].expirationDate)) {
          const store = this.storeService.store;
          if (!store) {
            console.log('No store found');
            return of(null);
          }
          const product = this.productsService.getProductById(productId);
          if (!product) {
            console.log('Product not found');
            return of(null);
          }
          product['nextExpirationDates'] = arrayRemove(
            product['nextExpirationDates'],
            data[`Lote`].expirationDate
          );

          const userInfo = this.authenticationService.getUserInfo();
          if (!userInfo) {
            console.log('User info not found');
            return of(null);
          }

          // product.processedExpirationDates = arrayAddDistinct(product.processedExpirationDates, data[`Data Validade`]);
          const type = this.customTaskTypesService.getCustomTaskType(
            'groupBreakProductIM'
          );
          if (!type) {
            console.log('type not found');
            return of(null);
          }
          if (!store.modules?.expiration?.breakProduct) {
            console.log('Module information not found');
            return of(null);
          }
          const newTask = {
            id: cuid(), //use lib to generate id
            type: type.type,
            formId: store.modules.expiration.breakProduct,
            title: this.getTitle(type, data),
            subtitle: this.getSubtitle(type, data),
            startDate: moment().valueOf(),
            endDate: moment(data[`Lote`].expirationDate).valueOf(),
            visualizationDate: moment().add(1, 'year').valueOf(),
            nonConformities: [],
            causes: [],
            corrections: [],
            periodicity: 'Única',
            target: {
              storeId: store.id,
              userIds: getTargetUser(store, userInfo),
            },
            documentsIds: [],
            isCustomType: true,
            superType: undefined,
            productId: product.id,
            groupedBatchesLots: data['Lote'].lot,
            expirationDate: data[`Lote`].expirationDate,
            precedingTaskId: task.id,
          };

          const newTaskResult = this.tasksService.newTask(
            newTask,
            this.authenticationService.getAuthInfo()
          );
          const newProductResult = this.productsService.newProduct(
            product,
            this.authenticationService.getAuthInfo()
          );

          return forkJoin([newTaskResult, newProductResult]);
        } else if (
          isProductExpiringInLessThan4Days(data[`Lote`].expirationDate)
        ) {
          const store = this.storeService.store;
          if (!store) {
            console.log('No store found');
            return of(null);
          }
          const product = this.productsService.getProductById(productId);

          if (!product) {
            console.log('Product not found');
            return of(null);
          }
          const userInfo = this.authenticationService.getUserInfo();
          if (!userInfo) {
            console.log('User info not found');
            return of(null);
          }

          product['nextExpirationDates'] = arrayRemove(
            product['nextExpirationDates'],
            data[`Lote`].expirationDate
          );
          product['processedExpirationDates'] = arrayAddDistinct(
            product['processedExpirationDates'],
            data[`Lote`].expirationDate
          );
          const type =
            this.customTaskTypesService.getCustomTaskType('groupExpirationIM');
          if (!type) {
            console.log('type info not found');
            return of(null);
          }

          if (!store) {
            console.log('No store found');
            return of(null);
          }
          if (!store.modules?.expiration?.validitiesFormId) {
            console.log('Missing module information');
            return of(null);
          }

          const newTask = {
            id: cuid(), //use lib to generate id
            type: type.type,
            formId: store.modules.expiration.validitiesFormId,
            title: this.getTitle(type, data),
            subtitle: this.getSubtitle(type, data),
            startDate: moment().valueOf(),
            endDate: moment(data[`Lote`].expirationDate).valueOf(),
            visualizationDate: moment().add(1, 'year').valueOf(),
            nonConformities: [],
            causes: [],
            corrections: [],
            periodicity: 'Única',
            target: {
              storeId: store.id,
              userIds: getTargetUser(store, userInfo),
            },
            documentsIds: [],
            isCustomType: true,
            superType: undefined,
            productId: product.id,
            groupedBatchesLots: data['Lote'].lot,
            expirationDate: data[`Lote`].expirationDate,
            precedingTaskId: task.id,
          };

          const newTaskResult = this.tasksService.newTask(
            newTask,
            this.authenticationService.getAuthInfo()
          );
          const newProductResult = this.productsService.newProduct(
            product,
            this.authenticationService.getAuthInfo()
          );

          return forkJoin([newTaskResult, newProductResult]);
        }
      }
    }
    return of(null);
  }
}
