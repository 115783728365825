import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-remove-button-component',
  template: `
    <div style="text-align: end;">
      <ion-button
        fill="clear"
        (click)="pressRemove()"
        style="color: rgba(255, 0, 0, 0.5); font-size: 0.8rem; font-weight: bold"
      >
        {{ buttonText }}
      </ion-button>
    </div>
  `,
})
export class RemoveButtonComponent {
  @Input() buttonText: string = 'Remover'; // Default text, can be customized via input
  @Output() handleRemove: EventEmitter<void> = new EventEmitter<void>();

  pressRemove() {
    this.handleRemove.emit(); // Notify parent when the button is clicked
  }
}
