// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { mergeMap, Observable, of, forkJoin, concatMap } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import {
  CustomTaskService,
  ExecutionState,
} from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { ModalController } from '@ionic/angular';
import cuid from 'cuid';
import moment from 'moment';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldType } from 'src/app/entities/field';
import { FieldsService } from 'src/app/services/fields.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpSigningDeclarations implements IMyTaskHandler {
  name = 'follow-up-signing-declarations';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    return type.getTitle === 'type' ? type.name : `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    const subtitleMappings: any = {
      scheduleCollectionProductApanha: `${
        this.storeService.store?.code || ''
      } - ${data['Parcela']}`,
      ratingApanha: task?.subtitle,
      collectionProductApanha: task?.subtitle,
      complexTask: data['Comentário'],
      NF: `${data['Nº Guia']} - ${data['Herdade']}`,
      inProductApanhaAzeitonaNF: `${data['Nº Guia']}`,
      inTransitAzeitonaNF: `${data['Nº Guia']} - ${data['Matrícula Transporte']}`,
    };

    if (type.getSubtitle in subtitleMappings) {
      return subtitleMappings[type.getSubtitle];
    }

    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;

    return pieces
      .map((piece: any) => this.pieceToString(piece, type, data))
      .join(' - ');
  }

  pieceToString(piece: any, type: any, data: any) {
    const mappings: any = {
      type: type.name,
      product: data['Produto']?.name || '',
      expirationDate: moment(
        data['Data Validade'] || data['Data de fim de validade']
      ).format('DD/MM/YYYY HH:mm'),
    };
    return mappings[piece] || '';
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.error('User info not found');
      return of(null);
    }

    const typeTask =
      this.customTaskTypesService.getCustomTaskType('downloadEFN');
    if (!typeTask) {
      console.error('typeTask not found');
      return of(null);
    }

    if (this.customTaskTypesService.canProceed(task.type, data)) {
      const typeForm1 = {
        ...this.createTypeForm(store, '6 - Exportar declarações'),
        titleEmptyForm: 'Parabéns, foi executada a tarefa. CCarregue no botão VOLTAR para aceder ao menu principal e avançar para aceder a etapa 6 - Confirme os seus dados.',
      };
      
      const typeForm2 = {
        ...this.createTypeForm(store, '7 - Importar declarações assinadas'),
        titleEmptyForm: 'Parabéns, a candidatura foi concluída. Vai receber um email com a submissão.',
      };

      const form1Creation = this.fieldsService.newField(
        typeForm1,
        this.authenticationService.getAuthInfo()
      );
      const form2Creation = this.fieldsService.newField(
        typeForm2,
        this.authenticationService.getAuthInfo()
      );

      // Definir as tarefas associadas ao primeiro formulário
      const tasksForForm1 = [
        {
          title: 'Exporte e assine a Declaração de Compromisso. (Obrigatório)',
          description: {
            download: 'https://techguildadm-my.sharepoint.com/:b:/g/personal/techguild_techguildadm_onmicrosoft_com/EVbQIQzuSapBsY2oE39rhZYBvXRP19Ks-qqPEw0zmSyD7w?e=8InOaM',
          },
        },
        {
          title: 'Exporte e assine a Declaração DNSH. (Obrigatório)',
          description: {
            download: 'https://techguildadm-my.sharepoint.com/:b:/g/personal/techguild_techguildadm_onmicrosoft_com/EfBW-zPPWZlArpPClG1fw1YBcvaKRwxbk9lyybQ556s6zA?e=IdNg2t',
          },
        },
      ].map((taskInfo) =>
        this.createTaskObject(
          taskInfo,
          typeTask,
          data,
          task,
          store,
          typeForm1.id
        )
      );

      // Definir as tarefas associadas ao segundo formulário
      const tasksForForm2: any[] = [
        // Exemplos de tarefas adicionais para o segundo formulário, se houver
      ];

      // Fluxo de criação dos formulários e suas tarefas sequencialmente
      return form1Creation.pipe(
        concatMap(() =>
          forkJoin(
            tasksForForm1.map((newTask) =>
              this.tasksService.newTask(
                newTask,
                this.authenticationService.getAuthInfo()
              )
            )
          )
        ),
        concatMap(() => form2Creation),
        concatMap(() =>
          forkJoin(
            tasksForForm2.map((newTask) =>
              this.tasksService.newTask(
                newTask,
                this.authenticationService.getAuthInfo()
              )
            )
          )
        )
      );
    }

    return of(null);
  }

  private createTypeForm(store: any, title: string): any {
    return {
      id: cuid(),
      title,
      type: FieldType.Form,
      storeId: store?.id,
      userIds: [store?.modules?.utilizadorUdeId],
      style: { imageUrl: 'anexos.svg' },
    };
  }

  private createTaskObject(
    taskInfo: any,
    typeTask: any,
    data: any,
    task: any,
    store: any,
    formId: string
  ): any {
    return {
      id: cuid(),
      type: typeTask.type,
      formId,
      title: taskInfo.title,
      subtitle: this.getSubtitle(typeTask, data, task),
      startDate: moment().valueOf(),
      endDate: moment().add(1, 'year').valueOf(),
      visualizationDate: moment().add(1, 'year').valueOf(),
      periodicity: 'Única',
      target: { storeId: store.id, userIds: [store?.modules?.utilizadorUdeId] },
      description: taskInfo.description,
      isCustomType: true,
    };
  }
}
