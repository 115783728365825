import { Component } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { BatchCreateModal } from "../../batch/batch-create-modal/batch-create.modal";
import { ProductDetailService } from "../product-detail.service";

@Component({
  selector: 'app-product-batches-tab',
  templateUrl: 'product-batches.tab.html',
  styleUrls: ['./product-batches.tab.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductBatchesTab {
  product: any;

  constructor(
    private navCtrl: NavController,
    private productDetailService: ProductDetailService,
    private modalController: ModalController
  ) {
    this.product = this.productDetailService.product;
  }

  async pressCreateNewBatch() {
    const modal = await this.modalController.create({
      component: BatchCreateModal,
      componentProps: {
        product: this.product,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      // Handle the new batch data if needed
    }
  }

  close() {
    this.navCtrl.pop();
  }
}
