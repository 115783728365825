import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormTaskModal } from '../form-task-modal/form-task-modal';
import { CustomTaskService } from 'src/app/services/custom-task.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { ZonesService } from 'src/app/services/zones.service';
import { EquipmentsService } from 'src/app/services/equipments.service';
import { SensorsService } from 'src/app/services/sensors.service';
import { Task, TaskResponse } from 'src/app/entities/tasks/task';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'justification-modal-task',
  templateUrl: 'justification-modal-task.html',
  styleUrls: ['../../../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class JustificationModalTask implements OnInit {
  @Input() task!: Task;
  @Input() response!: TaskResponse;
  @Input() isValid!: () => Promise<boolean>;
  taskTypeObject: any;
  lastRequest: any;

  constructor(
    private customTaskService: CustomTaskService,
    private modalController: ModalController,
    private customTaskTypeService: CustomTaskTypesService,
    public zonesService: ZonesService,
    public equipmentsService: EquipmentsService,
    public sensorsService: SensorsService
  ) {}

  ngOnInit() {
    const type = this.customTaskTypeService.getCustomTaskType(this.task.type);
    this.taskTypeObject = type;
  }

  async pressContent() {
    const modal = await this.modalController.create({
      component: FormTaskModal,
      componentProps: {
        task: this.task,
        taskTypeObject: this.taskTypeObject,
        response: this.response,
      },
    });

    modal.onDidDismiss().then(async (data) => {
      if (data.data) {
        if (await this.isValid()) {
          this.lastRequest = () =>
            this.customTaskService.respondContent(this.task, this.response);
          try {
            await this.lastRequest();
            this.modalController.dismiss(this.response);
          } catch (err) {
            console.log(err);
          }
        }
      }
    });

    await modal.present();
  }

  async pressYes() {
    if (await this.isValid()) {
      this.lastRequest = () =>
        this.customTaskService.respondContent(this.task, this.response);
      try {
        await this.lastRequest();
        this.modalController.dismiss(this.response);
      } catch (err) {
        console.log(err);
      }
    }
  }
}
