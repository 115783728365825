import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { translate } from '../../../utils/translate.utils';
import { BarcodeScanningModalComponent } from '../../components/barcode-scanning/barcode-scanning.modal';
import { BatchListModal } from '../../components/batch/batch-list-modal/batch-list.modal';
import { ProductListModal } from '../../components/product-list-modal/product-list.modal';
import {
  productMatchesSearchTermNormalized,
  productHasBarcode,
} from '../../entities/product';
import { TaskType } from '../../entities/tasks/custom-task-type';
import { AlertService } from '../../services/alert.service';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { CustomTaskService } from '../../services/custom-task.service';
import { FieldsService } from '../../services/fields.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { SyncingService } from '../../services/syncing';
import { TasksService } from '../../services/tasks.service';
import { CreateAndFillCustomTypeTaskModal } from './create-and-fill-custom-type/create-and-fill-custom-type-task.modal';
import { SelectCustomTypeTaskModal } from './select-custom-type-task/select-custom-type-task.modal';
import { CommonlyUsedProductsModal } from './commonly-used-products-modal/commonly-used-products.modal';
import { MissingGoogleBarcodeScannerModuleError } from '../../../utils/errors/missing-google-services.error';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss'],
})
export class FormPage {
  form: any;
  allTasks: any[] = [];
  tasks: any[] = [];
  link = '/Áreas';

  store: any;

  searchTerm: string = '';
  searchControl: FormControl;
  searching = false;
  isSearched = false;

  counter = 0;
  private ITEMS_PER_TICK = 12;

  public unregisterBackButtonAction: any;

  // Translated labels
  creatableTaskTypesLabel: string = '';
  barcodeSearchLabel: string = '';
  commonlyUsedProductsLabel: string = '';
  searchPlaceholder: string = '';
  correspondencesLabel: string = '';
  emptyFormLabel: string = '';

  init = () => {
    const formId = this.route.snapshot.params['id'];
    this.form = this.fieldsService.getField(formId);

    this.store = this.storesService.store;
    this.searchTerm = '';
    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search) => {
        this.tasks = this.allTasks.filter((t) => {
          const searchTermNormalized = this.searchTerm
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          const titleNormalized = t.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          const subtitleNormalized = t.subtitle
            ? t.subtitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            : '';
          const formTitleNormalized = this.tasksService
            .getTaskFormTitleByFormId(t)
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

          return (
            moment(t.startDate)
              .format('DD-MM-YYYY HH:mm')
              .toLowerCase()
              .indexOf(searchTermNormalized.toLowerCase()) !== -1 ||
            titleNormalized
              .toLowerCase()
              .indexOf(searchTermNormalized.toLowerCase()) !== -1 ||
            subtitleNormalized
              .toLowerCase()
              .indexOf(searchTermNormalized.toLowerCase()) !== -1 ||
            formTitleNormalized
              .toLowerCase()
              .indexOf(searchTermNormalized.toLowerCase()) !== -1 ||
            (t.productId
              ? productMatchesSearchTermNormalized(
                  this.productsService.getProductById(t.productId),
                  search
                )
              : false)
          );
        });
        this.counter = this.ITEMS_PER_TICK;
        this.searching = false;
      });
    // Modify this part to sort based on the "order" field
    let sortOrder = this.form?.order === 'desc' ? -1 : 1;
    this.allTasks = this.tasksService
      .getTasksFromForm(this.form.id)
      .sort((a, b) => {
        // Compare by startDate
        if (moment(a.startDate).isBefore(moment(b.startDate))) {
          return -1 * sortOrder;
        } else if (moment(a.startDate).isAfter(moment(b.startDate))) {
          return 1 * sortOrder;
        } else {
          // If startDate is equal, compare by title
          return a.title.localeCompare(b.title) * sortOrder;
        }
      });
    this.tasks = this.allTasks;
    this.counter = this.ITEMS_PER_TICK;
    this.syncing.initer = this.init;
  };

  constructor(
    private route: ActivatedRoute,
    private tasksService: TasksService,
    private syncing: SyncingService,
    private platform: Platform,
    private navCtrl: NavController,
    private modalController: ModalController,
    private customTaskTypesService: CustomTaskTypesService,
    private toastController: ToastController,
    private storesService: StoresService,
    private translator: TranslateService,
    private productsService: ProductsService,
    private customTaskService: CustomTaskService,
    private fieldsService: FieldsService,
    private alertService: AlertService
  ) {
    this.searchControl = new FormControl();
  }
  onSearchInput() {
    this.isSearched = true;
    this.searching = true;
  }

  async ionViewWillEnter() {
    this.init();
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.unregisterBackButtonAction && this.unregisterBackButtonAction;
  }

  private initializeBackButtonCustomHandler(): void {
    this.unregisterBackButtonAction =
      this.platform.backButton.subscribeWithPriority(10, () => {
        this.customHandleBackButton();
      });
  }

  private customHandleBackButton(): void {
    this.navCtrl.pop();
  }

  onTaskClosedSuccessfully = (task: any) => {
    const i = this.tasks.indexOf(task);
    if (i > -1) {
      this.tasks.splice(i, 1);
    }
  };

  async pressNewRegister(barcode?: string) {
    const type = await this.getTaskTypeToCreate();
    if (!type) {
      await this.showNoCreatableTaskTypesToast();
      return;
    }

    let product;
    if (type.product) {
      product = await this.getProduct(type, barcode);
      if (!product) return;
    }

    let batch;
    if (type.batch) {
      batch = await this.getBatch(product);
      if (!batch) return;
    }

    let modal = await this.modalController.create({
      component: CreateAndFillCustomTypeTaskModal,
      componentProps: {
        type,
        form: this.form,
        extras: {
          product,
          batch,
        },
      },
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.task) {
      const message = await translate(
        'O registo foi guardado corretamente. Pode consultar no Histórico.',
        this.translator
      );
      const toast = await this.toastController.create({
        message: message,
        duration: 3000,
        position: 'top',
        buttons: [
          {
            text: await translate('Fechar', this.translator),
            role: 'cancel',
          },
        ],
      });
      await toast.present();
      if (!data.response) {
        this.allTasks.unshift(data.task);
      }
    }
  }

  goBack() {
    this.navCtrl.back();
  }

  async getTaskTypeToCreate(): Promise<TaskType> {
    if (
      !this.form.creatableTaskTypes ||
      this.form.creatableTaskTypes.length === 0
    ) {
      return null as any; // TypeScript workaround, though not ideal
    } else if (
      this.form.creatableTaskTypes &&
      this.form.creatableTaskTypes.length === 1
    ) {
      const taskType = this.customTaskTypesService.getCustomTaskType(
        this.form.creatableTaskTypes[0]
      );
      if (!taskType) {
        throw new Error('TaskType is undefined');
      }
      return taskType;
    } else if (
      this.form.creatableTaskTypes &&
      this.form.creatableTaskTypes.length > 1
    ) {
      const creatableTaskTypes: TaskType[] = this.form.creatableTaskTypes.map(
        (t: any) => {
          const taskType = this.customTaskTypesService.getCustomTaskType(t);
          if (!taskType) {
            throw new Error(`TaskType for ${t} is undefined`);
          }
          return taskType;
        }
      );

      const modal = await this.modalController.create({
        component: SelectCustomTypeTaskModal,
        componentProps: {
          creatableTaskTypes: creatableTaskTypes,
        },
      });
      await modal.present();
      return (await modal.onDidDismiss()).data;
    }

    return null as any; // TypeScript workaround, though not ideal
  }

  async pressCommonlyUsedProducts() {
    try {
      if (!this.form) {
        console.error('Form is not defined');
        return;
      }

      if (!this.form.allowsBarcodeUse) {
        console.error('Form does not allow barcode use');
        return;
      }

      const modal = await this.modalController.create({
        component: CommonlyUsedProductsModal,
      });

      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        const type = await this.getTaskTypeToCreate();
        if (!type) {
          await this.showNoCreatableTaskTypesToast();
          return;
        }
        let batch;
        if (type.batch) {
          batch = await this.getBatch(data);
          if (!batch) return;
        }

        const taskModal = await this.modalController.create({
          component: CreateAndFillCustomTypeTaskModal,
          componentProps: {
            type,
            form: this.form,
            extras: {
              product: data,
              batch,
            },
          },
        });

        await taskModal.present();
        const taskModalData = await taskModal.onDidDismiss();
        if (taskModalData && taskModalData.data && taskModalData.data.task) {
          const message = await translate(
            'O registo foi guardado corretamente. Pode consultar no Histórico.',
            this.translator
          );
          const toast = await this.toastController.create({
            message: message,
            duration: 3000,
            position: 'top',
            buttons: [
              {
                text: await translate('Fechar', this.translator),
                role: 'cancel',
              },
            ],
          });
          await toast.present();
          this.init();
        }
      }
    } catch (error) {
      console.error('Error in pressCommonlyUsedProducts:', error);
    }
  }

  async pressBarcodeScanner() {
    try {
      // Check and request camera permissions
      const permissionStatus = await BarcodeScanner.checkPermissions();
      if (permissionStatus.camera !== 'granted') {
        const permissionRequest = await BarcodeScanner.requestPermissions();
        if (permissionRequest.camera !== 'granted') {
          const alert = await this.alertService.present(
            'Permissão necessária',
            'A permissão da câmera é necessária para digitalizar códigos de barras. Por favor, conceda acesso à câmera nas configurações.'
          );
          return;
        }
      }
  
      // Open Barcode Scanning Modal
      const modal = await this.modalController.create({
        component: BarcodeScanningModalComponent,
        cssClass: 'barcode-scanning-modal',
        componentProps: {},
      });
      await modal.present();
      const result = (await modal.onDidDismiss()).data;
  
      if (result) {
        await this.processBarcode(result);
      } else {
        const toast = await this.toastController.create({
          message: 'Nenhum conteúdo do código de barras foi encontrado.',
          duration: 3000,
          position: 'top',
        });
        await toast.present();
      }
    } catch (err) {
      if (err instanceof MissingGoogleBarcodeScannerModuleError) {
        await this.alertService.present(
          err.message,
          'É necessário instalar o leitor de código de barras da Google. Em caso de dúvida, contacte a equipa de suporte.'
        );
      } else {
        console.error('Error during barcode scan:', err);
        await this.alertService.presentError();
      }
    }
  }
  
  async processBarcode(barcodeValue: string) {
    try {
      if (!barcodeValue || typeof barcodeValue !== 'string') {
        console.error('Código de barras inválido:', barcodeValue);
        await (await this.toastController.create({
          message: 'Código de barras inválido. Por favor, tente novamente.',
          duration: 3000,
          position: 'top',
        })).present();
        return;
      }
      const product = this.productsService
        .getProducts()
        .find((p: any) => productHasBarcode(p, barcodeValue));
      if (!product) {
        console.warn('Produto não encontrado para o código:', barcodeValue);
        await (await this.toastController.create({
          message: 'Produto não encontrado. Deseja registrar um novo?',
          duration: 3000,
          position: 'top',
        })).present();
        this.pressNewRegister(barcodeValue); // Registrar novo produto
        return;
      }
  
      // Continuar com a criação da tarefa
      const type = await this.getTaskTypeToCreate();
      if (!type) {
        await this.showNoCreatableTaskTypesToast();
        return;
      }
      let batch;
      if (type.batch) {
        batch = await this.getBatch(product);
        if (!batch) return;
      }
  
      const modal = await this.modalController.create({
        component: CreateAndFillCustomTypeTaskModal,
        componentProps: {
          type,
          form: this.form,
          extras: {
            product,
            batch,
          },
        },
      });
  
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.task) {
        const message = await translate(
          'O registo foi guardado corretamente. Pode consultar no Histórico.',
          this.translator
        );
        const toast = await this.toastController.create({
          message: message,
          duration: 3000,
          position: 'top',
          buttons: [
            {
              text: await translate('Fechar', this.translator),
              role: 'cancel',
            },
          ],
        });
        await toast.present();
        this.init();
      }
    } catch (error) {
      console.error('Erro ao processar o código de barras:', error);
      await this.alertService.presentError();
    }
  }

  private async showNoCreatableTaskTypesToast() {
    const message = await translate(
      'A área foi configurada sem tipos de tarefa para criar. Contactar o adminstrador de Backoffice.',
      this.translator
    );
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  private async getProduct(type: any, barcode?: string) {
    const productsListTitle = type.productsListTitle;

    let includeProductsWithoutTechnicalSheet: boolean = !type.production;

    const modal = await this.modalController.create({
      component: ProductListModal,
      componentProps: {
        includeProductsWithoutTechnicalSheet,
        barcode,
        productsListTitle: productsListTitle || 'Lista de produtos', // Passa o título ou o padrão
      },
    });

    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  private async getBatch(product: any) {
    const modal = await this.modalController.create({
      component: BatchListModal,
      componentProps: { product },
    });
    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  doInfinite(infiniteScroll: any) {
    this.counter =
      this.counter + this.ITEMS_PER_TICK <= this.allTasks.length
        ? this.counter + this.ITEMS_PER_TICK
        : this.allTasks.length;
    infiniteScroll.target.complete();
  }
}
