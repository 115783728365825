<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{'PHOTO'|translate}}</h3>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalPicture">
        <img class="pic" height="100%" width="100%"
             [src]="currentPhotoPath"
             *ngIf="photos[0]"/>
        <img class="photoArrow" src="assets/img/backward_arrow.svg" (click)="pressArrowLeft()"/>
        <img class="photoArrow" style="float: right" src="assets/img/arrow_forward.svg"
             (click)="pressArrowRight()"/>
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="close();">{{'CLOSE'|translate}}</button>
          <!--<img src="assets/img/pictures_view.svg" class="fa-btn "/>-->
        </div>
      </div>
    </div>
  </div>
</div>
