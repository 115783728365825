import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import moment from 'moment';
import { Batch } from '../../../../entities/batch';
import {
  calculateUnfrozenDate,
  getLastSecondaryExpirationDate,
  getProductDesignation,
  Product,
} from '../../../../entities/product';
import { ResponseField } from '../../../../entities/tasks/task';
import { StoresService } from '../../../../services/stores.service';
import { ProductsService } from '../../../../services/products.service';
import {
  TagInfo,
  TagPrintingService,
} from 'src/app/services/tags-printing.service';
import { FNSDateFormats, REFERENCE_DATE } from 'src/utils/date.utils';
import { format, parse } from 'date-fns';

@Component({
  selector: 'app-refrigerated-product-detail-modal',
  templateUrl: './refrigerated-product-detail.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RefrigeratedProductDetailModal {
  product: Product = this.navParams.get('product');
  tagInfo: TagInfo = this.navParams.get('tagInfo');

  preparationDate: Date = new Date();
  lot: string = this.tagInfo.batch?.lot || '';
  primaryExpirationDate: string = this.tagInfo.batch.expirationDate || '';
  secondaryExpirationDate: Date | null = this.getSecondaryExpirationDate();
  quantityTags?: number = this.tagInfo.quantityTags;
  printed: number = 0;
  nonApplicable: boolean = false; // Assuming this is needed

  productNameResponseField: ResponseField = {
    name: 'Produto',
    type: 'product',
    responseValidation: {
      required: true,
    },
    placeholder: 'Produto',
    required: true,
    locked: true,
  };

  preparationDateResponseField: ResponseField = {
    name: 'Data de preparação',
    type: 'datetime',
    responseValidation: {
      required: true,
    },
    required: true,
  };

  primaryExpirationDateResponseField: ResponseField = {
    name: 'Data de expiração primária',
    type: 'datetime',
  };

  lotResponseField: ResponseField = {
    name: 'Lote',
    type: 'text',
  };

  secondaryExpirationDateResponseField: ResponseField = {
    name: `Validade secundária (${this.getSecondaryHoursString()})`,
    type: 'datetime',
    responseValidation: {
      required: true,
    },
    required: true,
    locked: true,
  };

  quantityTagsResponseField: ResponseField = {
    name: `Quantidade de etiquetas a imprimir`,
    type: 'number',
    responseValidation: {
      required: true,
    },
    required: true,
  };

  preparationDateControl = new FormControl('', [Validators.required]);

  formGroup: FormGroup = this.formBuilder.group({
    preparationDate: this.preparationDateControl,
  });

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private storeService: StoresService,
    protected productsService: ProductsService,
    private tagsPrintingService: TagPrintingService,
    private toastController: ToastController
  ) {}

  getSecondaryHoursString() {
    if (this.product.secondaryExpirationHoursSameAsPrimary)
      return 'Mesma da primária';
    return `${
      this.product.unfreezingTime
        ? this.product.unfreezingTime + ' horas + '
        : ''
    }${this.product.secondaryExpirationHours || '-'} horas`;
  }

  close() {
    this.modalController.dismiss(this.tagInfo);
  }

  getProductDesignation(product: Product) {
    return getProductDesignation(product);
  }

  getLastSecondaryExpirationDate(product: Product) {
    return getLastSecondaryExpirationDate(product);
  }

  getSecondaryExpirationDate(): Date | null {
    return this.productsService.calculateExpirationDate(
      this.preparationDate,
      parse(
        this.primaryExpirationDate,
        FNSDateFormats.YYYYMMDD,
        REFERENCE_DATE
      ) || null,
      this.product
    );
  }

  getUnfrozenDate(): Date | null {
    return calculateUnfrozenDate(this.preparationDate, this.product);
  }

  onBatchValueChanged(value: any) {
    this.secondaryExpirationDate = this.getSecondaryExpirationDate();
  }

  onPreparationDateValueChanged(value: any) {
    this.secondaryExpirationDate = this.getSecondaryExpirationDate();
  }

  onPrimaryExpirationDateChanged(value: any) {
    this.secondaryExpirationDate = this.getSecondaryExpirationDate();
  }

  isPrinting: boolean = false;
  async print() {
    if (this.isPrinting) return;

    this.isPrinting = true;

    let toast: HTMLIonToastElement;

    this.tagInfo = {
      batch: {
        lot: this.lot,
        expirationDate: this.primaryExpirationDate
          ? format(this.primaryExpirationDate, FNSDateFormats.YYYYMMDD)
          : undefined,
      },
      quantityTags: this.quantityTags,
    };
    if (this.quantityTags !== undefined && this.quantityTags > 0) {
      this.tagsPrintingService
        .printOne(this.preparationDate, {
          product: this.product,
          tagInfo: {...this.tagInfo},
        })
        .subscribe({
          next: async (p) => {
            toast = await this.toastController.create({
              message: p.name,
              duration: 0,
            });
            await toast.present();
          },
          error: async (error) => {
            const errorToast = await this.toastController.create({
              message: error.message || error,
              color: 'danger',
              duration: 3000,
            });
            await errorToast.present();
          },
          complete: () => {
            if (toast) toast.dismiss();
            this.isPrinting = false;
          },
        });
    }

    if (this.quantityTags !== undefined && this.quantityTags >= 0) {
      this.productsService.processExpirationDates(this.preparationDate, [
        {
          product: this.product,
          tagInfo: this.tagInfo,
        },
      ]);
    } else {
      toast = await this.toastController.create({
        message: 'A quantidade de etiquetas tem que ser 0 ou maior',
        duration: 2000,
      });
      await toast.present();
    }

    this.isPrinting = false;
    this.tagInfo.quantityTags = undefined;
  }
}
