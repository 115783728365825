import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FieldsService } from "src/app/services/fields.service";
import { GuiaDeTransporte, Previsao, generateGuiasTransporteFromPrevisao, getPrevisaoFromCsv } from "src/utils/handlers/generate-transport-guide-fo";


interface FileData {
  fileName: string;
  fileType: string;
  base64Content: string;
}

@Component({
  selector: "app-previsao-input",
  template: `
    <div class="form-group">
      <label class="form-label">
        {{ getTranslation(responseField) }} {{ responseField?.required ? "*" : "" }}
      </label>
      <div class="file-input" *ngIf="!readonly">
        <input type="file" (change)="onFileChange($event)" />
      </div>
      <div class="file-display" *ngIf="readonly">
        <input
          class="form-control"
          type="text"
          [placeholder]="'Escolher arquivo'"
          [readonly]="true"
          [value]="value?.fileName || ''"
        />
        <img
          src="assets/img/file.svg"
          class="file-icon"
          (click)="downloadFile()"
        />
      </div>
      <ion-item *ngIf="tableData.length > 0">
        <div class="lagar-name" *ngIf="previsao">
          Nome do lagar: {{ previsao.lagarName }}
        </div>
        <div class="total-quantity" *ngIf="previsao">
          Total: {{ previsao.grandTotal }}
        </div>
        <div class="variedades" *ngIf="previsao">
          Variedades:
          <div class="variedade-item" *ngFor="let variedade of previsao.data">
            {{ variedade.oliveType }}: {{ variedade.quantity }}kg
          </div>
        </div>
        <ion-grid>
          <ion-row class="table-header">
            <ion-col>Data</ion-col>
            <ion-col>Herdade</ion-col>
            <ion-col>N_Herdade</ion-col>
            <ion-col>Variedade</ion-col>
            <ion-col>Tipo de transporte</ion-col>
            <ion-col>KGs</ion-col>
            <ion-col>Nº Guias Previstas</ion-col>
          </ion-row>
          <ion-row *ngFor="let row of tableData" class="table-row">
            <ion-col>{{ row.date }}</ion-col>
            <ion-col>{{ row.herdade }}</ion-col>
            <ion-col>{{ row.n_herdade }}</ion-col>
            <ion-col>{{ row.variedade }}</ion-col>
            <ion-col>{{ row.vehicleType }}</ion-col>
            <ion-col>{{ row.quantidade }}</ion-col>
            <ion-col>{{ row.numero }}</ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
  `,
  styles: [
    `
      .form-label {
        font-weight: bold;
        margin-bottom: 0.5em;
      }
      .file-input,
      .file-display {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
      }
      .file-icon {
        width: 60px;
        height: 60px;
        cursor: pointer;
      }
      .lagar-name,
      .total-quantity {
        font-weight: bold;
        margin-bottom: 0.5em;
      }
      .variedades {
        margin-bottom: 1em;
      }
      .variedade-item {
        margin: 0.25em 0;
      }
      .table-header {
        font-weight: bold;
        background-color: #ddd;
      }
      .table-row {
        background-color: #f9f9f9;
        border-bottom: 1px solid #ddd;
        padding: 0.5em 0;
      }
      .table-row:nth-child(odd) {
        background-color: #fff;
      }
      ion-col {
        padding: 8px;
        border: 1px solid #ccc;
      }
      ion-grid {
        border-collapse: collapse;
      }
      .table-header ion-col,
      .table-row ion-col {
        width: calc(100% / 7);
        text-align: center;
      }
      .table-header ion-col,
      .table-row ion-col {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 100px;
      }
      .table-row ion-col {
        white-space: normal;
      }
    `,
  ],
})
export class PrevisaoInputComponent implements OnInit {
  @Input() value: FileData | null = null;
  @Output() valueChange = new EventEmitter<FileData | null>();
  @Input() responseField: any;
  @Input() readonly = false;

  @Input() getPlaceholderTranslation: (responseField: any) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.placeholder || "";
    } else if (lang === "es") {
      return responseField.placeholderEs || responseField.placeholder || "";
    }
    return responseField.placeholder || "";
  };
  @Input() getTranslation: (responseField: any) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.name || "";
    } else if (lang === "es") {
      return responseField.nameEs || responseField.name || "";
    }
    return responseField.name || "";
  };

  tableData: any[] = []; // Store the processed data here
  previsao!: Previsao;

  constructor(
    private translator: TranslateService,
    private fieldsService: FieldsService
  ) {}

  ngOnInit() {
    if (this.value) this.processFile(this.value);
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const fileData: FileData = {
          fileName: file.name,
          fileType: file.type,
          base64Content: reader.result as string,
        };

        this.processFile(fileData);

        this.value = fileData;
        this.valueChange.emit(this.value);
      };

      reader.readAsDataURL(file);
    }
  }

  async processFile(fileData: FileData) {
    try {
      const previsao = await getPrevisaoFromCsv(fileData);
      const guiasDeTransporte = generateGuiasTransporteFromPrevisao(previsao);

      this.previsao = previsao;
      this.tableData = guiasDeTransporte.map((guia: GuiaDeTransporte) => ({
        date: guia.Date,
        herdade: guia.HerdadeShortName,
        n_herdade: this.fieldsService.getHerdadeByShortName(guia.HerdadeShortName).fullName,
        variedade: guia.OliveType,
        vehicleType: guia.VehicleType === "Reboque" ? "Reboque" : "Camião",
        quantidade: guia.QuantityShort,
        numero: this.getNumberOfGuides(guia.QuantityShort, guia.VehicleType),
      }));
    } catch (error) {
      console.error("Error processing file", error);
    }
  }

  getNumberOfGuides(quantity: number, vehicleType: string): number {
    const vehicleCapacity = vehicleType === "Reboque" ? 15000 : 40000;
    return Math.ceil(quantity / vehicleCapacity);
  }

  downloadFile() {
    if (this.value?.base64Content) {
      const byteCharacters = atob(this.value.base64Content.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: this.value.fileType });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = this.value.fileName;
      link.click();

      URL.revokeObjectURL(url);
    }
  }
}
