<ion-header>
  <ion-toolbar>
    <ion-title>Imprimir</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="container scroll-content">
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>Imprimir</h3>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <!-- <app-receipt-torriba 
        [task]="task" 
        [response]="response" 
        [producer]="producer" 
        [buyer]="buyer" 
        [plot]="plot" 
        [device]="deviceInfo">
      </app-receipt-torriba>        -->
       <ion-list>
          <ion-item *ngFor="let item of bluetoothList" (click)="selectPrinter(item.id, item.name)">
            {{ item.name }} {{ item.id }}
          </ion-item>
        </ion-list>
        <div>Selected Printer: {{ selectedPrinter?.name }}</div>
      </div>
      <div class="modalActions">
        <div class="actionsLeft"></div>
        <div class="actionsRight">
          <ion-button color="primary" *ngIf="selectedPrinter" (click)="printTransportGuide()">
            PRINT!
          </ion-button>
          <ion-button [color]="isConnected ? 'primary' : 'medium'" *ngIf="selectedPrinter">
            CONNECT!
          </ion-button>
          <ion-button color="light" (click)="close()">
            {{ 'CLOSE' | translate }}
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</ion-content>
