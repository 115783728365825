<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Close</ion-button>
    </ion-buttons>
    <ion-title>{{ productsListTitle | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 8px;
    "
    (click)="pressBarcodeScanner()"
  >
    <!-- This is the image acting as a button -->
    <img src="assets/img/barcode.svg" style="width: 50px" />
    <!-- This is the button text -->
    <h5 style="margin-left: 10px">{{ 'SCAN CÓDIGO BARRAS' | translate }}</h5>
  </div>
  
  <ion-searchbar
    [(ngModel)]="searchTerm"
    placeholder="'Pesquisa: Código de barras, nome ou marca'"
    [formControl]="searchControl"
    (ionInput)="onSearchInput()"
  ></ion-searchbar>
  
  <div *ngIf="searching" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>
  
  <ion-list>
    <ion-item
      *ngFor="let product of products | slice: 0: counter"
      (click)="pressProduct(product)"
    >
      <ion-label>
        <h2>{{ product.name }}</h2>
        <p *ngIf="product.barcode.itm8">
          Cód. barras interno: {{ product.barcode.itm8s || product.barcode.itm8 }}
        </p>
        <p *ngIf="product.barcode.value">EAN: {{ product.barcode.value }}</p>
        <p *ngIf="product.brand">Marca: {{ product.brand }}</p>
        <p *ngIf="product.barcode.reference">Referência: {{ product.barcode.reference }}</p>
      </ion-label>
      <ion-icon slot="end" name="arrow-forward-circle"></ion-icon>
    </ion-item>
    <ion-item *ngIf="products.length === 0">
      {{ 'Sem resultados' | translate }}
    </ion-item>
  </ion-list>
  
  <ion-infinite-scroll threshold="100px" (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start" style="order: 1">
      <ion-button (click)="pressCreateNew()">
        <ion-icon slot="start" name="add"></ion-icon>
        {{ 'Criar novo produto' }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>