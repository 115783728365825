<ion-card>
  <ion-card-header>
    <div class="card-title-with-icon">
      <ion-icon name="warning" color="danger"></ion-icon>
      <ion-card-title color="primary">Temperaturas Não Conformes</ion-card-title>
    </div>
  </ion-card-header>

  <ion-card-content>
    <div *ngIf="nonConformTemperatures?.length; else noTemperatures">
      <ion-list>
        <ion-item *ngFor="let temp of nonConformTemperatures">
          <ion-label>
            <strong>{{ temp.machine }}</strong> - Temperatura:
            <strong>{{ temp.temperature }}°C</strong> - Data:
            <strong>{{ temp.date }}</strong>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- Button to navigate to non-conforming temperatures page -->
      <ion-button
        expand="block"
        (click)="navigateToNonConformTemperatures()"
        class="navigate-button"
      >
        <ion-icon name="thermometer-outline" slot="start"></ion-icon>
        Ver Temperaturas Não Conformes
      </ion-button>
    </div>

    <ng-template #noTemperatures>
      <p>Não há temperaturas não conformes hoje.</p>
    </ng-template>
  </ion-card-content>
</ion-card>
