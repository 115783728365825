// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpInsertDocsEmailTo implements IMyTaskHandler {
  name = 'follow-up-insert-docs-emailTo';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const newEmail = data['Email para alerta'] || data['E-mail da loja para esclarecimentos'];

    console.log("newEmail", newEmail)
    // Correção: Verifica diretamente sem usar um array
    const existingEmailToSupportRequest =
      this.storeService.store.modules.emailsReport.haccp.emailsTo.includes(
        newEmail
      );

    if (!existingEmailToSupportRequest) {
      // Parece que você está reatribuindo o mesmo valor aqui, o que pode ser desnecessário
      this.storeService
        .newDocsEmailTo(
            newEmail,
          this.authenticationService.getAuthInfo()
        )
        .subscribe(
          (response) => {
            console.log('Success:', response);
          },
          (error) => {
            console.error('Error:', error);
          }
        );
    } else {
      console.log(
        'Fornecedor já existe e não será adicionado novamente:',
        newEmail
      );
    }

    return of(null);
  }
}
