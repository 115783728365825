import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PromptModal } from '../../prompt/prompt.modal';
import { TasksService } from 'src/app/services/tasks.service';
import { CustomTaskService } from 'src/app/services/custom-task.service';
import { AuthenticationService } from 'src/app/services/authentication';
import { checkValidity, ConditionalModalCreationInfo } from 'src/utils/conditional-modal-creator/conditional-modal-creator';
import { translate } from 'src/utils/translate.utils';

@Component({
  templateUrl: 'justification-modal.html',
  styleUrls: ['../../../../theme/modals.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class JustificationModal {
  task: any;
  response = {
    isInConformity: false,
    picturesIds: [],
    non_conformity: '',
    causes: '',
    correction: '',
    commentary: '',
    ncCommentary: '',
    lateCommentary: '',
    clientDate: 0,
     employeeId: ''
  };

  isNonConformityMissingValidator: ConditionalModalCreationInfo = {
    condition: () => this.isMissingNonConformity(),
    modalCreator: () => this.createMissingNonConformityModal(),
  };

  isCauseMissingValidator: ConditionalModalCreationInfo = {
    condition: () => this.isMissingCause(),
    modalCreator: () => this.createMissingCauseModal(),
  };

  isCorrectionMissingValidator: ConditionalModalCreationInfo = {
    condition: () => this.isMissingCorrection(),
    modalCreator: () => this.createMissingCorrectionModal(),
  };

  non_conformitySelector = 'OUTRA';
  causesSelector = 'OUTRA';
  correctionSelector = 'OUTRA';

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private tasksService: TasksService,
    private customTaskService: CustomTaskService,
    private authenticationService: AuthenticationService,
    private translator: TranslateService
  ) {
    this.task = navParams.data['task'];
    this.response = this.task.responses ? { ...this.task.responses[0] } : this.response;
  }

  async pressSubmitAndCloseTaskLater() {
    if (await this.isValid()) {
      this.submitTask();
      this.modalController.dismiss(this.response);
    }
  }

  isValid() {
    return checkValidity(
      [
        this.isNonConformityMissingValidator,
        this.isCauseMissingValidator,
        this.isCorrectionMissingValidator,
      ],
      this.modalController
    );
  }

  async submitTask() {
    try {
      await this.customTaskService.respondNo(this.task, this.response);
    } catch (err) {
      console.log(err);
    }
  }

  isMissingNonConformity() {
    return !this.response.non_conformity;
  }

  isMissingCause() {
    return !this.response.causes;
  }

  isMissingCorrection() {
    return !this.response.correction;
  }

  async createMissingNonConformityModal(): Promise<HTMLIonModalElement> {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('MISSING_NON_CONFORMITY', this.translator),
        yesText: translate('CONTINUE', this.translator),
        noText: translate('CANCEL', this.translator),
      },
    });
  }

  async createMissingCauseModal(): Promise<HTMLIonModalElement> {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('MISSING_CAUSE', this.translator),
        yesText: translate('CONTINUE', this.translator),
        noText: translate('CANCEL', this.translator),
      },
    });
  }

  async createMissingCorrectionModal(): Promise<HTMLIonModalElement> {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('MISSING_ACTION', this.translator),
        yesText: translate('CONTINUE', this.translator),
        noText: translate('CANCEL', this.translator),
      },
    });
  }

  close() {
    this.modalController.dismiss();
  }

  changeCausesValue() {
    this.response.causes = this.causesSelector;
  }

  changeNonConformityValue() {
    this.response.non_conformity = this.non_conformitySelector;
  }

  changeCorrectionValue() {
    this.response.correction = this.correctionSelector;
  }
}
