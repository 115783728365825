<ion-header>
    <ion-toolbar>
      <ion-title>{{ 'Filtros adicionais' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <div class="modalContent">
      <div class="modalDescription">
        <ion-item>
          <ion-label>{{ 'Formulário' | translate }}</ion-label>
          <ion-select [(ngModel)]="selectedField" interface="popover">
            <ion-select-option *ngFor="let option of fields" [value]="option">
              {{ option.title }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
      <div class="modalActions">
        <ion-button expand="full" (click)="accept()">{{ 'OK' | translate }}</ion-button>
      </div>
    </div>
  </ion-content>
  