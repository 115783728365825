import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { KeyValuePipe } from '../../pipes/keyvalue-pipe';
import { ModalController } from '@ionic/angular';
import { MyKeyboard } from '../../keyboard/keyboard';
import { ResponseField } from '../../../entities/tasks/task'; // Update the path according to your project structure

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'number-input',
  template: `
    <div class="form-group">
      <label *ngIf="!noLabel"
        >{{ getTranslation(responseField)
        }}{{ responseField.required ? '*' : '' }}</label
      >
      <input
        class="form-control"
        pattern="-?[0-9]*.?[0-9]+"
        [placeholder]="getPlaceholderTranslation(responseField) || ''"
        [(ngModel)]="value"
        (ngModelChange)="valueChange.emit($event)"
        [formControl]="control"
        [readonly]="!!responseField.locked"
        (click)="!responseField.locked && openMyKeyboard()"
      />
      <div *ngIf="control.touched && control.errors">
        <div *ngFor="let error of control.errors | keyvalue">
          <p style="color: red" *ngIf="error.key === 'required'">
            Este campo é obrigatório
          </p>
          <p style="color: red" *ngIf="error.key === 'min'">
            O valor mínimo é
            {{ responseField.responseValidation?.minChars?.count }}
          </p>
          <p style="color: red" *ngIf="error.key === 'max'">
            O valor máximo é
            {{ responseField.responseValidation?.maxChars?.count }}
          </p>
          <p style="color: red" *ngIf="error.key === 'pattern'">
            Verifique se o valor é válido
          </p>
        </div>
      </div>
    </div>
  `,
  providers: [KeyValuePipe],
})
export class NumberInputComponent implements OnInit, OnChanges {
  @Input() noLabel = false;
  @Input() responseField!: ResponseField;
  @Input() value: number | undefined = undefined;
  @Output() valueChange = new EventEmitter<number>();
  @Input() getPlaceholderTranslation: (responseField: ResponseField) => string =
    (responseField) => {
      const lang = this.translator.currentLang;
      if (lang === 'pt') {
        return responseField.placeholder || '';
      } else if (lang === 'es') {
        return responseField.placeholderEs || responseField.placeholder || '';
      }
      return responseField.placeholder || '';
    };
  @Input() getTranslation: (responseField: ResponseField) => string = (
    responseField
  ) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.name || '';
    } else if (lang === 'es') {
      return responseField.nameEs || responseField.name || '';
    }
    return responseField.name || '';
  };

  control: FormControl = new FormControl('');

  constructor(
    private translator: TranslateService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.initializeFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['responseField']) {
      this.initializeFormControl();
    }
  }

  private initializeFormControl() {
    const validators = [];
    if (this.responseField && this.responseField.responseValidation) {
      if (this.responseField.responseValidation.required) {
        validators.push(Validators.required);
      }
      if (this.responseField.responseValidation.minChars) {
        validators.push(
          Validators.min(this.responseField.responseValidation.minChars.count)
        );
      }
      if (this.responseField.responseValidation.maxChars) {
        validators.push(
          Validators.max(this.responseField.responseValidation.maxChars.count)
        );
      }
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  async openMyKeyboard() {
    const keyboardModal = await this.modalCtrl.create({
      component: MyKeyboard,
      componentProps: { value: this.value ?? 0 },
    });

    await keyboardModal.present();

    const { data } = await keyboardModal.onDidDismiss();
    if (data !== null && data !== undefined) {
      this.value = data;
      this.valueChange.emit(this.value);
      this.control.markAsTouched();
      this.control.updateValueAndValidity();
    }
  }
}
