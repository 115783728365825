import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { PromptModal } from '../../prompt/prompt.modal';
import { checkValidity } from 'src/utils/conditional-modal-creator/conditional-modal-creator';
import { translate } from 'src/utils/translate.utils';

@Component({
  templateUrl: 'nonconformity.form-modal.html',
  styleUrls: ['../../../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NonConformityModal {
  task: any;
  response: any;
  isNonConformityMissingValidator = {
    condition: () => this.isMissingNonConformity(),
    modalCreator: () => this.createMissingNonConformityModal()
  };
  isCauseMissingValidator = {
    condition: () => this.isMissingCause(),
    modalCreator: () => this.createMissingCauseModal()
  };
  isCorrectionMissingValidator = {
    condition: () => this.isMissingCorrection(),
    modalCreator: () => this.createMissingCorrectionModal()
  };
  isEarlySubmitValidator = {
    condition: () => this.isEarlySubmit(),
    modalCreator: () => this.createEarlySubmitModal()
  };
  non_conformitySelector = 'OUTRA';
  causesSelector = 'OUTRA';
  correctionSelector = 'OUTRA';

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private translator: TranslateService
  ) {
    this.task = navParams.data['task'];
    this.response = navParams.data['response'];
  }

  close() {
    this.modalController.dismiss();
  }

  pressSubmit = async () => {
    const isValid = await checkValidity(
      [
        this.isNonConformityMissingValidator,
        this.isCauseMissingValidator,
        this.isCorrectionMissingValidator,
        this.isEarlySubmitValidator
      ],
      this.modalController
    );
    if (isValid) {
      this.successfullySubmit();
    }
  }

  successfullySubmit() {
    this.modalController.dismiss({
      non_conformity: this.response.non_conformity,
      causes: this.response.causes,
      correction: this.response.correction,
      ncCommentary: this.response.ncCommentary,
      picturesIds: this.response.picturesIds
    });
  }

  isMissingNonConformity() {
    return !this.response.non_conformity;
  }

  isMissingCause() {
    return !this.response.causes;
  }

  isMissingCorrection() {
    return !this.response.correction;
  }

  isEarlySubmit() {
    return moment.now() < this.task.startDate;
  }

  async createMissingNonConformityModal() {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('MISSING_NON_CONFORMITY', this.translator),
        yesText: translate('CONTINUE', this.translator),
        noText: translate('CANCEL', this.translator)
      }
    });
    return modal;
  }

  async createMissingCauseModal() {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('MISSING_CAUSE', this.translator),
        yesText: translate('CONTINUE', this.translator),
        noText: translate('CANCEL', this.translator)
      }
    });
    return modal;
  }

  async createMissingCorrectionModal() {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('MISSING_ACTION', this.translator),
        yesText: translate('CONTINUE', this.translator),
        noText: translate('CANCEL', this.translator)
      }
    });
    return modal;
  }

  async createEarlySubmitModal() {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('BEFORE_SCHEDULE_ANSWERING_TEXT', this.translator)
      }
    });
    return modal;
  }

  changeCausesValue() {
    this.response.causes = this.causesSelector;
  }

  changeNonConformityValue() {
    this.response.non_conformity = this.non_conformitySelector;
  }

  changeCorrectionValue() {
    this.response.correction = this.correctionSelector;
  }
}
