import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { PRODUCT_EXPIRATION_TYPES } from "../../../entities/product";
import { ResponseField } from "../../../entities/tasks/task";
import { ProductDetailService } from "../product-detail.service";

@Component({
  selector: 'app-product-expiration-tab',
  templateUrl: "product-expiration.tab.html",
  styleUrls: ['../../../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductExpirationTab {
  product: any;

  productExpirationTypeResponseField: ResponseField = {
    name: "Tipo de Produto",
    type: "select",
    responseValidation: {
      required: true,
    },
    placeholder: "Tipo de Produto",
    required: true,
    autofill: "NONE",
    locked: true,
  }
  secondaryExpirationHoursSameAsPrimaryResponseField: ResponseField = {
    name: "Validade secundária é a mesma que a primária",
    type: "boolean",
    responseValidation: {},
    placeholder: "Validade secundária é a mesma que a primária",
    locked: true,
  };
  secondaryExpirationHoursResponseField: ResponseField = {
    name: "Horas até expiração secundária",
    type: "number",
    responseValidation: {},
    placeholder: "Horas até expiração secundária",
    locked: true,
  };
  expirationHoursAfterOpeningResponseField: ResponseField = {
    name: "Horas até expiração após abertura",
    type: "number",
    responseValidation: {},
    placeholder: "Horas até expiração após abertura",
    locked: true,
  };
  unfreezingTimeResponseField: ResponseField = {
    name: "Tempo de descongelamento",
    type: "number",
    responseValidation: {},
    placeholder: "Tempo de descongelamento",
    locked: true,
  };

  constructor(
    private navParams: NavParams,
    private productDetailService: ProductDetailService
  ) {
    this.product = this.productDetailService.product;
  }

  getProductExpirationTypes = () => {
    return PRODUCT_EXPIRATION_TYPES;
  };
}
