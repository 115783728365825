<ion-header>
  <ion-toolbar>
    <ion-title>
      {{'Produtos expirados e a expirar no dia' | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Fechar</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <!-- Main message or introduction -->
  <p *ngIf="data.message">
    {{ data?.message || 'Here is your daily summary!' }}
  </p>

  <app-expired-products-summary
    [expiredProducts]="data?.expiredProducts"
  ></app-expired-products-summary>

  <!-- Expiring Products Section -->
  <app-expiring-products-summary
    [expiringProducts]="data?.expiringProducts"
  ></app-expiring-products-summary>

  <!-- <app-non-conform-temperatures-summary
    [nonConformTemperatures]="summaryData?.nonConformTemperatures"
  ></app-non-conform-temperatures-summary> -->

  <!-- New Goods Receipts Section -->
  <!-- <app-goods-reception-summary
    [goodsReception]="summaryData?.goodsReception"
  ></app-goods-reception-summary> -->

  <!-- Future sections can be added here in a similar way -->
  <!-- Example:
    <app-expired-products-summary [data]="summaryData?.expiredProducts"></app-expired-products-summary>
    <app-temperature-non-conformities-summary [data]="summaryData?.temperatureIssues"></app-temperature-non-conformities-summary>
    -->
</ion-content>
