import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-my-keyboard',
  templateUrl: './keyboard.html',
  styleUrls: ['./keyboard.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MyKeyboard {

  value: string;

  constructor(private modalController: ModalController, private navParams: NavParams) {
    this.value = this.navParams.get('value') || '';
  }

  press(string: string) {
    if (string === '<-') {
      if (this.value.length > 0) {
        this.value = this.value.substring(0, this.value.length - 1);
      }
    } else {
      this.value = this.value + string;
    }
  }

  finish() {
    this.modalController.dismiss(this.value);
  }

  close() {
    this.modalController.dismiss(null);
  }

}
