// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { format } from 'date-fns'; // Substituindo 'moment' por 'date-fns'
import { getTargetUser } from '../../entities/authentication-info';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';
import cuid from 'cuid';
import moment from 'moment';
import { FieldsService } from 'src/app/services/fields.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpInsertStatements implements IMyTaskHandler {
  name = 'follow-up-insert-statements';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService, // Removido FieldsService já que não usamos createTypeForm
    private fieldsService: FieldsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store.code} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data de fim de validade']).format(
          'DD/MM/YYYY HH:mm'
        );
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
    const userInfo = this.authenticationService.getUserInfo();

    if (!userInfo) {
      console.error('User info not found');
      return of(null);
    }

    const typeTask = this.customTaskTypesService.getCustomTaskType('filesEFN');
    if (!typeTask) {
      console.error('typeTask not found');
      return of(null);
    }

    if (
      data['Já descarregou o documento do botão do azul?'] === 'Sim' &&
      this.customTaskTypesService.canProceed(task.type, data)
    ) {
      const field = this.fieldsService.getFieldByName('7 - Importar declarações assinadas');
      if (field && !field.inactive) {
        const formId = field.id;

        // Cria a nova tarefa usando o formId encontrado
        const taskForUser = this.createNewTask(
          typeTask,
          data,
          task,
          store,
          store?.modules?.utilizadorUdeId,
          formId
        );

        return forkJoin([
          this.tasksService.newTask(
            taskForUser,
            this.authenticationService.getAuthInfo()
          ),
        ]).pipe(
          catchError((error) => {
            console.error('Error executing tasks', error);
            return of(null);
          })
        );
      } else {
        console.error('Active FormId not found');
        return of(null);
      }
    }

    return of(null);
  }

  private createNewTask(
    typeTask: any,
    data: any,
    task: any,
    store: any,
    userId: any,
    formId: any
  ) {
    return {
      id: cuid(),
      type: typeTask.type,
      formId: formId,
      title: task.title.replace(/^Exporte e assine a\s*/, ''),
      subtitle: this.getSubtitle(typeTask, data, task),
      startDate: moment().valueOf(),
      endDate: moment().add(7, 'days').valueOf(),
      visualizationDate: moment().add(1, 'year').valueOf(),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: store.id,
        userIds: [userId],
      },
      documentsIds: [],
      isCustomType: true,
      superType: undefined,
      precedingTaskId: task.id,
    };
  }
}
