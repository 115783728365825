import { Component, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ResponseField } from "src/app/entities/tasks/task";

interface FileData {
  fileName: string;
  fileType: string;
  base64Content: string;
}

@Component({
  selector: "app-file-input",
  template: `
    <div class="form-group">
      <label>{{ getTranslation(responseField) }}{{ responseField.required ? "*" : "" }}</label>
      <div style="display: flex" *ngIf="!readonly">
        <input type="file" (change)="onFileChange($event)" />
      </div>
      <div style="display: flex" *ngIf="readonly">
        <input
          class="form-control"
          type="text"
          placeholder="Escolher arquivo"
          [readonly]="true"
          [value]="value?.fileName || ''"
        />
        <img src="assets/img/file-icon.svg" style="width: 60px; height: 60px" />

      </div>
    </div>
  `,
})
export class FileInputComponent {
  @Input() value: FileData | null = null;
  @Output() valueChange = new EventEmitter<FileData>();
  @Input() responseField!: ResponseField;
  @Input() readonly = false;

  @Input() getPlaceholderTranslation: (responseField: ResponseField) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.placeholder || '';
    } else if (lang === "es") {
      return responseField.placeholderEs || responseField.placeholder || '';
    }
    return responseField.placeholder || '';
  };

  @Input() getTranslation: (responseField: { name?: string; nameEs?: string }) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.name || '';
    } else if (lang === "es") {
      return responseField.nameEs || responseField.name || '';
    }
    return responseField.name || '';
  };

  constructor(private translator: TranslateService) {}

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        const fileData: FileData = {
          fileName: file.name,
          fileType: file.type,
          base64Content: reader.result.toString(), // Ensure the result is converted to string
        };

        this.value = fileData;
        this.valueChange.emit(fileData);
      }
    };

    reader.readAsDataURL(file);
  }
}
