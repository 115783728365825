<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>
            {{product.name}}
            {{product.brand ? ' ' + product.brand : ''}}
            {{product.supplierName || product.supplier?.name}}
          </h3>
          <img src="assets/img/close.svg" (click)="close()" />
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <div class="form-group">
            <label>{{'Data de preparação' | translate}}</label>
            <input
              class="form-control"
              type="datetime-local"
              [(ngModel)]="preparationDate"
            />
          </div>
          <div class="form-group">
            <label>{{'Data de utilização' | translate}}</label>
            <input
              class="form-control"
              type="datetime-local"
              [(ngModel)]="useDate"
            />
          </div>
          <div class="form-group">
            <label>{{'Data de expiração' | translate}}</label>
            <input
              class="form-control"
              type="datetime-local"
              [(ngModel)]="expirationDate"
            />
          </div>
          <div class="modalTextSection">
            <h4 class="justification-modal-header-text">{{product.info}}</h4>
          </div>
        </div>
        <div class="modalActions">
          <div class="actionsLeft">
            <img (click)="pressPrint()" src="assets/img/print.svg" />
          </div>
          <div class="actionsRight">
            <button class="btn btn-cancel light-grey" (click)="close()">
              {{'CLOSE' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  