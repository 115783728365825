import { Component, NgZone, OnInit } from '@angular/core';
import {
  ModalController,
  NavParams,
  Platform,
  ToastController,
} from '@ionic/angular';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { PromptModal } from '../../prompt/prompt.modal';

import { toJpeg } from 'html-to-image';
import { Storage } from '@ionic/storage-angular';

import { TaskResponse, ResponseField } from '../../../entities/tasks/task';
import { PhotoHistoryModalComponent } from '../../photo/photo.history-modal';
import { BrotherPrinterService } from '../../../services/brother-printer.service';
import { AuthenticationService } from '../../../services/authentication';
import { FieldsService } from '../../../services/fields.service';
import { ProductsService } from '../../../services/products.service';
import { StoresService } from '../../../services/stores.service';
import { TasksService } from '../../../services/tasks.service';
import { AlertService } from '../../../services/alert.service';
import { PrinterPage } from '../../../printer/printer.page';
import { EnvironmentService } from 'src/app/services/environment.service';
import { parse } from 'date-fns';
import { FNSDateFormats, REFERENCE_DATE } from 'src/utils/date.utils';
import { TagPrintingService } from 'src/app/services/tags-printing.service';

@Component({
  templateUrl: 'history-task-modal.html',
  styleUrls: ['../../../../theme/modals.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HistoryTaskModal implements OnInit {
  task: any;
  type: any;
  extras: any;
  taskTypeObject: any;
  childrenTasks: any[] = [];

  lastResponse: any;

  prepackageTasks: any[] = [];
  unfreezingTasks: any[] = [];
  warmTemperatureControlTasks: any[] = [];
  inBeefRegisterTasks: any[] = [];

  checkPestActivityExteriorTasks: any[] = [];
  checkPestStructuralFlawsTasks:any[] = [];
  checkPestActivityInteriorTasks:any[] = [];
  checkPestStructuralFlawsInteriorTasks:any[] = [];
  checkPestPreventionMeasuresTasks:any[] = [];
  globalBrandStandardsPretTasks: any[] = [];
  customerServicePretTasks: any[] = [];
  informationPretTasks: any[] = [];
  globalEvaluationPretTasks: any[] = [];

  questionsAndAnswersExterior: any[] = [];
  questionsAndAnswersStructuralFlaws: any[] = [];
  questionsAndAnswersInterior: any[] = [];
  questionsAndAnswersStructuralFlawsInterior: any[] = [];
  questionsAndAnswersPestPreventionMeasures: any[] = [];
  questionsAndAnswersGlobalBrandStandardsPretTasks: any[] = [];
  questionsAndAnswersCustomerServicePretTasks: any[] = [];
  questionsAndAnswersInformationPretTasks: any[] = [];
  questionsAndAnswersGlobalEvaluationPretTasks: any[] = [];

  product: any;

  isPrinting = false;

  quantityTags: number | null = null;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private tasksService: TasksService,
    private translator: TranslateService,
    private storesService: StoresService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private productsService: ProductsService,
    private fieldsService: FieldsService,
    private toastController: ToastController,
    private platform: Platform,
    private zone: NgZone,
    private storage: Storage,
    private printerBrotherService: BrotherPrinterService,
    protected envService: EnvironmentService,
    private tagsPrintingService: TagPrintingService
  ) {}

  async ngOnInit() {
    this.task = this.navParams.data['task'];
    if (this.task?.productId) {
      this.product = this.productsService.getProductById(this.task.productId);
      this.extras = {
        product: this.productsService.getProductById(this.task.productId),
      };
    }
    this.lastResponse = {};
    if (this.task?.responses && this.task.responses[0]) {
      this.lastResponse = this.task.responses[0];
    }
    if (this.lastResponse?.childrenTasksIds) {
      this.childrenTasks = this.lastResponse.childrenTasksIds.map((id: any) => {
        const task = this.tasksService.getTask(id);
        return {
          task,
          response: task?.responses ? task.responses[0] : null,
        };
      });
      this.prepackageTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'prepackagedPDVIntermarcheShort2'
      );
      this.unfreezingTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'unfreezingProductsShort2'
      );
      this.warmTemperatureControlTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'warmTemperatureControlIntermarcheShort2'
      );
      this.inBeefRegisterTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'inBeefRegisterShort2'
      );
      this.checkPestActivityExteriorTasks = this.childrenTasks.filter((t) => 
        t.task.type === 'checkPestActivityExterior'
      );
      this.checkPestStructuralFlawsTasks = this.childrenTasks.filter((t) => 
        t.task.type === 'checkPestStructuralFlaws'
      );
      this.checkPestActivityInteriorTasks = this.childrenTasks.filter((t) => 
        t.task.type === 'checkPestActivityInterior'
      );
      this.checkPestStructuralFlawsInteriorTasks = this.childrenTasks.filter((t) => 
        t.task.type === 'checkPestStructuralFlawsInterior'
      );
      this.checkPestPreventionMeasuresTasks = this.childrenTasks.filter((t) => 
        t.task.type === 'checkPestPreventionMeasures'
      );


      this.globalBrandStandardsPretTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'globalBrandStandardsPret'
      );
      this.customerServicePretTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'customerServicePret'
      );
      this.informationPretTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'informationPret'
      );
      this.globalEvaluationPretTasks = this.childrenTasks.filter(
        (t) => t.task.type === 'globalEvaluationPret'
      );
    }
    this.taskTypeObject = this.navParams.data['taskTypeObject'];
    this.prepareQuestionsAndAnswers();
  }

  getInput(responseField: ResponseField) {
    return `task.responses[0]['${responseField.name}']`;
  }

  async pressCamera(response: TaskResponse, responseField: ResponseField) {
    const photoModal = await this.modalController.create({
      component: PhotoHistoryModalComponent,
      componentProps: { photos: response[responseField.name] },
    });
    photoModal.onDidDismiss().then((data) => {
      if (data.data) {
        response[responseField.name] = data.data;
      }
    });
    await photoModal.present();
  }

  async pressListCamera(object: any, propertyKey: string) {
    const photoModal = await this.modalController.create({
      component: PhotoHistoryModalComponent,
      componentProps: { photos: object[propertyKey] },
    });
    photoModal.onDidDismiss().then((data) => {
      if (data.data) {
        object[propertyKey] = data.data;
      }
    });
    await photoModal.present();
  }

  prepareQuestionsAndAnswers() {
    this.questionsAndAnswersExterior = [];
    this.questionsAndAnswersStructuralFlaws = [];
    this.questionsAndAnswersInterior = [];
    this.questionsAndAnswersStructuralFlawsInterior = [];
    this.questionsAndAnswersPestPreventionMeasures = [];
    this.questionsAndAnswersGlobalBrandStandardsPretTasks = [];
    this.questionsAndAnswersCustomerServicePretTasks = [];
    this.questionsAndAnswersInformationPretTasks = [];
    this.questionsAndAnswersGlobalEvaluationPretTasks = [];

    this.checkPestActivityExteriorTasks.forEach(taskObj => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersExterior.push({ question: key, answer: response[key] });
        }
      }
    });
    

    this.checkPestStructuralFlawsTasks.forEach(taskObj => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersStructuralFlaws.push({ question: key, answer: response[key] });
        }
      }
    });

    this.checkPestActivityInteriorTasks.forEach(taskObj => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersInterior.push({ question: key, answer: response[key] });
        }
      }
    });

    this.checkPestStructuralFlawsInteriorTasks.forEach(taskObj => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersStructuralFlawsInterior.push({ question: key, answer: response[key] });
        }
      }
    });
    this.checkPestPreventionMeasuresTasks.forEach(taskObj => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersPestPreventionMeasures.push({ question: key, answer: response[key] });
        }
      }
    });
    
    this.globalBrandStandardsPretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersGlobalBrandStandardsPretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.customerServicePretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersCustomerServicePretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.informationPretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersInformationPretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.globalEvaluationPretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersGlobalEvaluationPretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });
  }

  processCondition(field: any, response: any) {
    if (!field.condition) return true;
    const conditions = field.condition.split(', ');
    if (conditions.length === 0) return true;
    return (
      conditions.filter((c: any) => {
        if (c.includes('===')) {
          const [leftSide, rightSide] = c.split('===');
          return response[leftSide] === rightSide;
        } else if (c === 'expirationDate') {
          return (
            response['Data Validade'] &&
            moment(response['Data Validade']).isBefore(moment().add(4, 'days'))
          );
        } else if (c.startsWith('!')) {
          const condition = c.substring(1);
          return !response[condition];
        } else {
          return !!response[c];
        }
      }).length === conditions.length
    );
  }

  close() {
    this.modalController.dismiss();
  }

  toggleHideGroupFields(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ) {
    if (!responseField.isGrouper) return;
    this.toggleHideGroupsField2(responseField, index, responseFields);
  }

  toggleHideGroupsField2(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ) {
    for (let i = index; i < responseFields.length; ++i) {
      if (responseFields[i] !== responseField && responseFields[i].isGrouper)
        return;
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleHideGroupsField3(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ) {
    for (let i = index + 1; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) return;
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleOffHideGroupFields(
    responseField: ResponseField,
    responseFields: ResponseField[]
  ) {
    let startingIndex: number | undefined;
    let isValid = false;
    for (let i = 0; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) {
        startingIndex = i;
      }
      if (responseFields[i] === responseField) {
        isValid = true;
        break;
      }
    }
    if (!isValid || startingIndex === undefined) return;
    this.toggleHideGroupsField3(responseField, startingIndex, responseFields);
  }

  getTranslation(object: any) {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.name;
    } else if (lang === 'es') {
      return object.nameEs || object.name;
    }
    return object.name;
  }

  getPlaceholderTranslation(object: any) {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.placeholder;
    } else if (lang === 'es') {
      return object.placeholderEs || object.placeholder;
    }
    return object.placeholder;
  }

  async pressPrint() {
    let buyer;
    let plot =
      this.storesService.getPlotByName(this.lastResponse['Parcela']) ||
      this.storesService.getPlotByFullName(this.lastResponse['Parcela']);
    if (this.lastResponse['Comprador']) {
      buyer = this.storesService.getBuyerByName(this.lastResponse['Comprador']);
    } else if (this.lastResponse['Cliente']) {
      buyer = this.storesService.getBuyerByName(this.lastResponse['Cliente']);
    } else {
      buyer = this.fieldsService.getSovenaLagar();
      plot = this.fieldsService.getFieldByName(this.lastResponse['Herdade']);
    }
    const modal = await this.modalController.create({
      component: PrinterPage,
      componentProps: {
        task: this.task,
        response: this.lastResponse,
        store: this.storesService.store,
        buyer,
        plot,
      },
    });
    await modal.present();
  }

  pressTrackGPS() {
    this.alertService.present(
      'Em desenvolvimento',
      'No futuro poderá dar track do percurso'
    );
  }

  async pressCancel() {
    const prompt = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: 'Tem a certeza que quer cancelar a tarefa?',
        text: "Poderá desfazer o cancelamento no histórico. Na tab 'Canceladas.'",
      },
    });
    prompt.onDidDismiss().then((res) => {
      if (res.data) {
        this.task.canceled = true;
        this.tasksService
          .updateTask(this.task, this.authenticationService.getAuthInfo())
          .subscribe(() => this.modalController.dismiss());
      }
    });
    await prompt.present();
  }

  async pressUncancel() {
    const prompt = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: 'Tem a certeza que quer desfazer o cancelamento?',
      },
    });
    prompt.onDidDismiss().then((res) => {
      if (res.data) {
        this.task.canceled = false;
        this.tasksService
          .updateTask(this.task, this.authenticationService.getAuthInfo())
          .subscribe(() => this.modalController.dismiss());
      }
    });
    await prompt.present();
  }

  getPreparationDate(): Date {
    const batch =
      this.task.batch ||
      this.productsService.getBatchById(
        this.product,
        this.lastResponse.batchId
      );
    return parse(
      batch.preparationDate,
      FNSDateFormats.YYYYMMDD_HHmm,
      REFERENCE_DATE
    );
  }

  getSecondaryExpirationDate(): Date {
    const batch =
      this.task.batch ||
      this.productsService.getBatchById(
        this.product,
        this.lastResponse.batchId
      );
    return parse(
      batch.expirationDate,
      FNSDateFormats.YYYYMMDD_HHmm,
      REFERENCE_DATE
    );
  }

  getUnfrozenDate(): Date {
    const batch =
      this.task.batch ||
      this.productsService.getBatchById(
        this.product,
        this.lastResponse.batchId
      );
    return parse(
      batch.unfrozenDate,
      FNSDateFormats.YYYYMMDD_HHmm,
      REFERENCE_DATE
    );
  }

  getPrimaryExpirationDate(): string {
    const batch =
      this.task.batch ||
      this.productsService.getBatchById(
        this.product,
        this.lastResponse.batchId
      );
    return batch.primaryExpirationDate;
  }

  onChangeQuantity(ev: any) {
    this.quantityTags = ev;
  }

  async pressPrintTag() {
    if (this.isPrinting) return;

    this.isPrinting = true;

    let toast: HTMLIonToastElement;

    this.tagsPrintingService
      .printOne(this.getPreparationDate(), this.product)
      .subscribe({
        next: async (p) => {
          toast = await this.toastController.create({
            message: p.name,
            duration: 0,
          });
          await toast.present();
        },
        error: async (error) => {
          const errorToast = await this.toastController.create({
            message: error.message || error,
            color: 'danger',
            duration: 3000,
          });
          await errorToast.present();
        },
        complete: () => {
          if (toast) toast.dismiss();
          this.isPrinting = false;
        },
      });
  }

  async waitfor(millisec: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, millisec);
    });
  }
}
