import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Product } from '../../../entities/product';
import { Batch } from '../../../entities/batch';

@Component({
  selector: 'app-batch-detail-modal',
  templateUrl: 'batch-detail.modal.html',
  styleUrls: ['../../../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BatchDetailModal {
  product: Product;
  batch: Batch;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController
  ) {
    this.product = this.navParams.get('product');
    this.batch = this.navParams.get('batch');
  }

  close() {
    this.modalController.dismiss();
  }
}
