<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{'Lista de lotes' | translate}} - {{product.name}}</h3>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription ">
        <div class="form-group">
          <label>{{"Pesquisa:" | translate}}</label>
          <input class="form-control" type="text" [(ngModel)]="searchValue" [placeholder]="'Lote ou data de validade'" [formControl]="searchControl"/>
        </div>
          <ion-list style="height: 60vh; overflow-y: auto;">
            <ion-item *ngFor="let batch of searchResult" (click)="pressBatch(batch)">
              <h2 style="font-size: 16px;
              font-weight: normal;
              color: #000; margin: 10px;">Lote: {{batch.lot}}</h2>
              <p style="font-size: 14px;
              font-weight: normal;
              color: #666;
              margin-top: 4px; margin: 10px;">{{getExpirationDate(batch)}}</p>
              <ion-icon name="arrow-dropright" slot="end"></ion-icon>
            </ion-item>
            <ion-item *ngIf="searchResult.length === 0">
              {{"Sem resultados" | translate}}
            </ion-item>
          </ion-list>
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
          <button class="btn btn-cancel light-grey" (click)="pressCreateNew()">{{'Criar novo lote'}}</button>
        </div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="close()">{{'CANCEL'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
