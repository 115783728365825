import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Printer } from '../interfaces/Printer';
import { Platform, ToastController } from '@ionic/angular';
declare var cordova: any;

export interface PrintResult {
  status: number;
  msg: string;
}

/**
 * To set and disable autocut, change here:
 * C:\Users\alex_\Documents\Techguild\MyTASK\mytask-ionic\node_modules\cordova-plugin-brother-label-printer\src\android\BasePrint.java
 * The code below should be in lines 186-192
 *                 case QL_820NWB:
                    mPrinterInfo.labelNameIndex = LabelInfo.QL700.valueOf(
                            sharedPreferences.getString("paperSize", LabelInfo.QL700.W62.toString())).ordinal();
                    mPrinterInfo.isAutoCut = Boolean.parseBoolean(sharedPreferences
                            .getString("autoCut", FALSE));
                    mPrinterInfo.isCutAtEnd = Boolean
                            .parseBoolean(sharedPreferences.getString("endCut", FALSE));
 */
@Injectable({
  providedIn: 'root',
})
export class BrotherPrinterService {
  selectedPrinter: Printer | null = null;

  constructor(
    private platform: Platform,
    private storage: Storage,
    private zone: NgZone,
    private toastController: ToastController
  ) {}

  async findPrinters(): Promise<Printer[]> {
    const printers: Printer[] = [];
    return new Promise<Printer[]>((resolve, reject) =>
      cordova.plugins.brotherPrinter.findNetworkPrinters(
        (bPrinters: Printer[]) => {
          for (const printer of bPrinters) {
            printer.orientation = 'LANDSCAPE';
            printers.push(printer);
          }
          resolve(printers);
        },
        (reason: any) => {
          reject(reason);
        }
      )
    );
  }

  async getSavedPrinter(): Promise<Printer | null> {
    return await this.storage.get('SelectedTagsPrinter');
  }

  async savePrinter(printer: Printer): Promise<boolean> {
    return await this.storage.set('SelectedTagsPrinter', printer);
  }

  async clearSavedPrinter(): Promise<void> {
    await this.storage.remove('SelectedTagsPrinter');
    this.selectedPrinter = null;
  }

  async checkPrinter(): Promise<boolean> {
    if (this.platform.is('android') && this.platform.is('cordova')) {
      const storagePrinter = await this.storage.get('SelectedTagsPrinter');
      if (storagePrinter) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  async printImage(
    dataUrl: string,
    numberOfCopies: number
  ): Promise<PrintResult> {
    const printer = await this.getSavedPrinter();
    const printerWithOptions: any = { ...printer, numberOfCopies };
    this.configurePrinter(printerWithOptions);
    let separatorIdx: number = dataUrl.indexOf(',');
    if (separatorIdx != -1) {
      dataUrl = dataUrl.substring(separatorIdx + 1);
    }
    return new Promise((resolve, reject) => {
      cordova.plugins.brotherPrinter.printViaSDK(
        dataUrl,
        (success: any) => {
          console.debug(`===== in printViaSDK ok`);
          console.debug(success);

          if (
            success &&
            success['result'] &&
            success['result'] === 'Succeeded'
          ) {
            console.debug(`=====print via sdk ok!!!`);
            resolve({ status: 200, msg: 'Printed' });
          } else {
            console.error(
              `Printing on ${this.toString(
                printerWithOptions
              )} returned message : ${JSON.stringify(success)}`
            );
            if (success['result'] === 'ERROR_COVER_OPEN') {
              reject({
                status: 500,
                msg: 'Erro a imprimir. Por favor confirme que a tampa da impressora está bem fechada.',
                error: success['result'],
              });
            } else {
              reject({
                status: 500,
                msg: 'Erro a imprimir. Por favor, verifique a impressora.',
                error: success['result'],
              });
            }
          }
        },
        (err: any) => {
          console.error(`Failed to print via sdk`);
          console.error(
            `Printing failed on ${this.toString(
              printerWithOptions
            )} with message : ${JSON.stringify(err)}`
          );
          reject({
            status: 500,
            msg: 'Erro a imprimir. Por favor, verifique a impressora.',
            error: err,
          });
        }
      );
    });
  }

  private async configurePrinter(printerWithOptions: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      cordova.plugins.brotherPrinter.setPrinter(
        printerWithOptions,
        (success: any) => resolve(),
        (err: any) => {
          console.error(`Setting printer failed: ${JSON.stringify(err)}`);
          reject(new Error('Setting printer failed.'));
        }
      );
    });
  }

  private toString(printer: Printer): string {
    if (!printer) {
      return '';
    }

    return `ip:${printer.ipAddress}, model:${printer.model}, port: ${printer.port}`;
  }

  getLabelWidthInInchesFromPaperSize(paperLabelName: string) {}
}
