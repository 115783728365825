import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular"; // Updated imports for Ionic 5/6/7/8
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators"; // Import debounceTime operator
import { StoresService } from "src/app/services/stores.service";
import { ProductsService } from "src/app/services/products.service";

@Component({
  selector: 'app-search-modal', // Update selector naming convention
  templateUrl: 'search.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SearchModal {

  productName: string = '';
  searchControl = new FormControl();
  searchResult: any[] = [];

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private storeService: StoresService,
    private productsService: ProductsService
  ) {
    this.searchControl.valueChanges.pipe(
      debounceTime(300) // Add debounceTime to delay search input handling
    ).subscribe(search => {
      if (!search) {
        this.searchResult = [];
      } else {
        this.searchResult = this.productsService.getProducts().filter(p => {
          if (!p.hasTechnicalSheet) return false;
          if (p.name.toLowerCase().includes(search.toLowerCase())) return true;
          return false;
        });
      }
    });
  }

  close() {
    this.modalController.dismiss();
  }

  pressProduct(product: any) {
    this.modalController.dismiss(product);
  }

}
