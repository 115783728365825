// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { ProductsService } from 'src/app/services/products.service';
import { BatchStatus } from 'src/app/entities/batch';

@Injectable({
  providedIn: 'root',
})
export class WriteOffBatchIbersolHandler implements IMyTaskHandler {
  name = 'write-off-batch-ibersol';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private productsService: ProductsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (!task.productId) {
      console.error('ProductId is undefined');
      return throwError('ProductId is undefined');
    }
    if (!task.batchId) {
      console.error('Batch ID is undefined in task');
      return throwError('Batch ID is undefined in task');
    }

    const productId: string = task.productId;
    const batchId: string = task.batchId;

    const product = this.productsService.getProductById(productId);

    if (!product) {
      console.error('Product not found');
      return throwError('Product not found');
    }

    const batch = this.productsService.getBatchById(product, batchId);

    if (!batch) {
      console.error(
        `Batch with ID ${batchId} not found in product ${productId}`
      );
      throw new Error('Batch not found');
    }

    batch.status = BatchStatus.Finished;

    const productUpdateOps = [
      {
        $set: {
          batches: product.batches,
        },
      },
    ];

    // Assuming patchProduct returns an Observable
    return this.productsService.patchProduct(
      product,
      productUpdateOps,
      this.authenticationService.getAuthInfo()
    );
  }
}
