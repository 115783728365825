// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { ManualsService } from 'src/app/services/manuals.service';
import cuid from 'cuid';

@Injectable({
  providedIn: 'root',
})
export class FollowUpInserFile implements IMyTaskHandler {
  name = 'follow-up-insert-file';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private manualService: ManualsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
    const base64Data = data['Inserir documento'].base64Content;
    const documentName = data['Nome do documento'];
    const documentNumber = data['Número do documento'];
    const documentDateEndValidation = data['Data de fim de validade'];
    const folderSelect = data['Selecione a pasta'] || data['Categoria do Documento'];

    const newManual = {
      id: cuid(),

      title: documentName || folderSelect,
      number: documentNumber || '',
      data: base64Data, // The file content in base64
      storeId: store.id,
      validation: documentDateEndValidation
    };



    // Create a new manual using the manual service
    this.manualService
      .newManual(
        newManual,
        this.authenticationService.getAuthInfo(),
        folderSelect
      )
      .subscribe(
        (response) => {
          console.log('Success:', response);
        },
        (error) => {
          console.error('Error:', error);
        }
      );

    return of(null);
  }
}
