// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpInsertNonConformities implements IMyTaskHandler {
  name = 'follow-up-insert-nonConformities';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService:StoresService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const nameNonConformities = data['Tipo de Não conformidades Detectadas'];
    // Correção: Verifica diretamente sem usar um array
    const existingNonConformities =
      this.storeService.store.modules.goodsReception.nonConformitiesList.includes(
        nameNonConformities
      );

    if (!existingNonConformities) {
      // Parece que você está reatribuindo o mesmo valor aqui, o que pode ser desnecessário
      return this.storeService
        .newNonConformities(nameNonConformities, this.authenticationService.getAuthInfo())
        
    }

    return of(null);
  }
}
