import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertModal } from '../components/alerts/alert.modal';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../utils/translate.utils';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {}

  async present(titleKey: string, textKey: string, onTryAgain?: () => void) {
    const title = await translate(titleKey, this.translate);
    const text = await translate(textKey, this.translate);
    
    const alertModal = await this.modalCtrl.create({
      component: AlertModal,
      componentProps: { title, text, onTryAgain },
    });
    await alertModal.present();
  }

  async presentError() {
    await this.present(
      'UNEXPECTED_ERROR_TITLE',
      'UNEXPECTED_ERROR_TEXT'
    );
  }

  async presentTimeout() {
    await this.present(
      'TIMEOUT_ERROR_TITLE',
      'TIMEOUT_ERROR_TEXT'
    );
  }

  async presentLogin() {
    await this.present(
      'CONNECTION_ERROR_TITLE',
      'CONNECTION_ERROR_TEXT'
    );
  }

  async presentLoginTimeout() {
    await this.present(
      'LOGIN_TIMEOUT_TITLE',
      'LOGIN_TIMEOUT_TEXT'
    );
  }

  async presentOffline() {
    await this.present(
      'OFFLINE_TITLE',
      'OFFLINE_TEXT'
    );
  }
}
