<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'Criar novo produto' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle taskTitle">
          <h3 class="modalTitleText">{{ 'Criar novo produto' | translate }}</h3>
          <h2 class="modalSubtitleText">{{ product.name }}</h2>
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <text-input
            [responseField]="nameResponseField"
            [(value)]="product.name"
          ></text-input>
          <text-input
          [responseField]="brandResponseField"
          [(value)]="brand"
        ></text-input>
          <text-input
            [responseField]="supplierResponseField"
            [(value)]="product.supplierName"
          ></text-input>
          <text-input
            [responseField]="internalBarcodeResponseField"
            [(value)]="itm8"
          ></text-input>
          <select-input
            [responseField]="productExpirationTypeResponseField"
            [(value)]="expirationType"
            [getSelectOptions]="getProductExpirationTypesOptions"
          ></select-input>
          <boolean-input
            [responseField]="secondaryExpirationHoursSameAsPrimaryResponseField"
            [(value)]="secondaryExpirationHoursSameAsPrimary"
            ></boolean-input>
          <number-input
            [responseField]="secondaryExpirationHoursResponseField"
            [(value)]="product.secondaryExpirationHours"
          ></number-input>
          <number-input
            [responseField]="expirationHoursAfterOpeningResponseField"
            [(value)]="product.expirationHoursAfterOpening"
          ></number-input>
          <number-input
            [responseField]="unfreezingTimeResponseField"
            [(value)]="product.unfreezingTime"
          ></number-input>
          <boolean-input
          [responseField]="noExpirationResponseField"
          [(value)]="noExpiration"
        ></boolean-input>
        
        </div>
        <div class="modalActions">
          <div class="actionsLeft"></div>
          <div class="actionsRight">
            <ion-button expand="full" (click)="pressCreateProduct()">
              {{ 'Criar' | translate }}
            </ion-button>
            <ion-button expand="full" color="light" (click)="close()">
              {{ 'CANCEL' | translate }}
            </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
