import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'datetime-input',
  templateUrl: './datetime.input.html',
  styleUrls: ['./datetime.input.scss'],
})
export class DatetimeInputComponent implements OnInit, OnChanges {
  @Input() control: FormControl = new FormControl();
  @Input() label: string = '';
  @Input() responseField: any; // Ideally, define an interface for this
  @Input() locked: boolean = false;
  @Input() value: any;

  @Output() valueChange = new EventEmitter<number>();

  popoverOpen: boolean = false;
  displayValue: string = '';

  @Input()
  getPlaceholderTranslation: (responseField: any) => string = (
    responseField
  ) => {
    const lang = this.translator.currentLang;
    return lang === 'pt'
      ? responseField.placeholder
      : lang === 'es'
      ? responseField.placeholderEs || responseField.placeholder
      : responseField.placeholder;
  };

  @Input()
  getTranslation: (responseField: any) => string = (responseField) => {
    const lang = this.translator.currentLang;
    return lang === 'pt'
      ? responseField.name
      : lang === 'es'
      ? responseField.nameEs || responseField.name
      : responseField.name;
  };

  private originalValue: string = '';

  constructor(private translator: TranslateService) {}

  ngOnInit() {
    this.initializeFormControl();

    // Update displayValue and emit valueChange when control value changes
    this.control.valueChanges.subscribe((value) => {
      this.updateDisplayValue(value);
      const timestamp = moment(value, 'YYYY-MM-DDTHH:mm').valueOf();
      this.valueChange.emit(timestamp);
    });

    // Set initial value
    if (this.value) {
      this.control.setValue(this.parseInputValue(this.value), {
        emitEvent: false,
      });
    } else if (!this.control.value) {
      const now = moment().format('YYYY-MM-DDTHH:mm');
      this.control.setValue(now, { emitEvent: false });

      // Manually emit the default value to the parent
      const timestamp = moment(now, 'YYYY-MM-DDTHH:mm').valueOf();
      this.valueChange.emit(timestamp);
    }

    this.updateDisplayValue(this.control.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && !changes['value'].firstChange) {
      this.control.setValue(this.parseInputValue(this.value), {
        emitEvent: false,
      });
      this.updateDisplayValue(this.control.value);
    }

    if (changes['responseField']) {
      this.initializeFormControl();
    }
  }

  private initializeFormControl() {
    const validators = [];
    if (this.responseField?.responseValidation?.required) {
      validators.push(Validators.required);
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();

    if (this.locked || this.responseField?.locked) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  confirmPopover() {
    if (this.control.valid) {
      this.value = this.control.value;
      this.valueChange.emit(moment(this.value).valueOf());
      this.popoverOpen = false;
    }
  }

  cancelPopover() {
    this.control.setValue(this.originalValue, { emitEvent: false }); // Revert to original value
    this.updateDisplayValue(this.originalValue); // Update displayValue accordingly
    this.popoverOpen = false;
  }

  openPopover(event: Event) {
    if (!this.locked && !this.responseField?.locked) {
      this.originalValue = this.control.value; // Store the original value
      this.popoverOpen = true;
    }
  }

  private updateDisplayValue(value: string) {
    if (value) {
      const formattedValue = moment(value, 'YYYY-MM-DDTHH:mm').format(
        'DD-MM-YYYY HH:mm'
      );
      this.displayValue =
        formattedValue !== 'Invalid date' ? formattedValue : '';
    } else {
      this.displayValue = '';
    }
  }

  private parseInputValue(val: any): string {
    let parsedValue = moment(val, 'DD-MM-YYYY HH:mm', true);

    if (!parsedValue.isValid()) {
      parsedValue = moment(val);
    }

    if (parsedValue.isValid()) {
      return parsedValue.format('YYYY-MM-DDTHH:mm');
    } else {
      console.error('Invalid date format:', val);
      return '';
    }
  }
}
