import {
  NgModule,
  isDevMode,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Add this import

import { MenuComponent } from './components/menu/menu.component';
import { EmployeeService } from './services/employee.service';
import { AuthenticationService } from './services/authentication';
import { OfflineService } from './services/offline.service';
import { AlertService } from './services/alert.service';
import { SyncingService } from './services/syncing';
import { TasksService } from './services/tasks.service';
import { StoresService } from './services/stores.service';
import { DeveloperNotesModal } from './components/developer-notes/developer-notes.modal';
import { PasswordModal } from './components/password/password.modal';
import { PrinterConfigModal } from './components/modals/printer-config.modal';
import { PromptModal } from './components/prompt/prompt.modal';
import { AlertModal } from './components/alerts/alert.modal';
import { PinpadComponent } from './components/pinpad/pinpad';
import { AuthenticatedHttpService } from './services/authenticated-http';
import { UploadAll } from './services/upload-all';
import { DownloadAll } from './services/download-all';
import { ZonesService } from './services/zones.service';
import { BatchCreateModal } from './components/batch/batch-create-modal/batch-create.modal';
import { BatchDetailModal } from './components/batch/batch-detail-modal/batch-detail.modal';
import { BatchListModal } from './components/batch/batch-list-modal/batch-list.modal';
import { BatchSelectModal } from './components/batch/batch-select-modal/batch-select.modal';
import { IbersolProductCreateModal } from './components/product-create-modal/ibersol/ibersol-product-create.modal';
import { ProductCreateModal } from './components/product-create-modal/product-create.modal';
import { ProductBatchesTab } from './components/product-detail-modal/product-batches-tab/product-batches.tab';
import { ProductExpirationTab } from './components/product-detail-modal/product-expiration-tab/product-expiration.tab';
import { ProductDetailModal } from './components/product-detail-modal/product-detail.modal';
import { ProductListModal } from './components/product-list-modal/product-list.modal';
import { ProductRawMaterialUsedListModal } from './components/product-raw-material-list-modal/product-raw-material-list.modal';
import { FilteredTasksModal } from './components/filtered-tasks/filtered-tasks.modal';
import { CreateTaskModal } from './fields/create-task.modal';
import { ProductInfoDetailComponent } from './components/product-detail-modal/product-info-detail-tab/product-info-detail.component';
import { PhotoButtonComponent } from './components/photo/photo.button';
import { PhotoHistoryButtonComponent } from './components/photo/photo.history-button';
import { PhotoModalComponent } from './components/photo/photo.modal';
import { CreateAndFillCustomTypeTaskModal } from './fields/form/create-and-fill-custom-type/create-and-fill-custom-type-task.modal';
import { TabsService } from './services/tabs.service';
import { DashboardPage } from './dashboard/dashboard.page';
import { HistoryPage } from './history/history.page';
import { HistoryModal } from './history/history.modal';
import { JustificationPage } from './justification/justification.page';
import { LabelsPage } from './labels/labels.page';
import { LabelFieldsModal } from './labels/label-fields/label-fields.modal';
import { ManualsPage } from './manuals/manuals.page';
import { PrinterPage } from './printer/printer.page';
import { PrintSovenaService } from './services/print-sovena.service';
import { PrintTorribaService } from './services/print-torriba.service';
import { PrintService } from './services/print.service';
import { ReceiptTorribaComponent } from './printer/receipt-torriba/receipt-torriba.component';
import { TemperatureSensorPage } from './sensors/temperature.sensor.page';
import { TagModalComponent } from './tags/tags.modal/tag.modal';
import { TasksModalComponent } from './tasks/tasks.modal/tasks.modal';
import { TasksPage } from './tasks/tasks.page';
import { TagsPage } from './tags/tags.page';
import { TasksQuickFillingPage } from './tasks/tasks-quick-filling/tasks-quick-filling.page';
import { DocumentsPage } from './documents/documents.page';
import { DocumentsFiltersModalComponent } from './documents/documents-filters.modal/documents-filters.modal';
import { DocumentsFiltersProductLotModalComponent } from './documents/documents-filters-product-lot/documents-filters-product-lot.modal';
import { ExpirationPage } from './expiration/expiration.page';
import { ExpirationProductDetailModal } from './expiration/expiration-product-detail/expiration-product-detail.modal';
import { InsertManualEntryModal } from './fields/form/insert-manual-entry/insert-manual-entry.modal';
import { MyKeyboard } from './components/keyboard/keyboard';
import { MissingRequiredFieldsModal } from './fields/form/missing-required-fields/missing-required-fields.modal';
import { ProductInstancesModal } from './fields/form/product-instances/product-instances.modal';
import { SearchModal } from './fields/form/search/search.modal';
import { SelectCustomTypeTaskModal } from './fields/form/select-custom-type-task/select-custom-type-task.modal';
import { SelectEntryModal } from './fields/form/select-entry/select-entry.modal';
import { SelectIngredientMenu } from './fields/form/select-ingredient-menu/select-ingredient-menu.modal';
import { InputBarcodeModal } from './fields/level/input-barcode/input-barcode.modal';
import { TraceabilityTagsPage } from './fields/traceability-tags/traceability-tags.page';
import { RefrigeratedProductsPage } from './fields/traceability-tags/refrigerated-products/refrigerated-products.page';
import { RefrigeratedProductDetailModal } from './fields/traceability-tags/refrigerated-products/refrigerated-product-detail/refrigerated-product-detail.modal';
import { FrozenTagComponent } from './fields/traceability-tags/tags/frozen.tag';
import { RefrigeratedTagComponent } from './fields/traceability-tags/tags/refrigerated.tag';
import { CommonlyUsedProductsModal } from './fields/form/commonly-used-products-modal/commonly-used-products.modal';
import { PhotoHistoryModalComponent } from './components/photo/photo.history-modal';
import { BooleanInputComponent } from './components/inputs/boolean-input/boolean.input';
import { DateInputComponent } from './components/inputs/date-input/date.input';
import { DatetimeInputComponent } from './components/inputs/datetime-input/datetime.input';
import { FileInputComponent } from './components/inputs/file-input/file.input';
import { ListInputComponent } from './components/inputs/list/list.input';
import { NumberInputComponent } from './components/inputs/number/number.input';
import { PrevisaoInputComponent } from './components/inputs/previsao-input/previsao.input';
import { SelectInputComponent } from './components/inputs/select-input/select.input';
import { TextInputComponent } from './components/inputs/text-input/text.input';
import { TimeInputComponent } from './components/inputs/time-input/time.input';
import { FormTaskComponent } from './components/tasks/form-task/form-task';
import { HistoryTaskModal } from './components/tasks/history-modal/history-task-modal';
import { FormTaskModal } from './components/tasks/form-task-modal/form-task-modal';
import { HistoryTask } from './components/tasks/history-task/history-task';
import { JustificationModal } from './components/tasks/justification-modal/justification-modal';
import { JustificationModalTask } from './components/tasks/justification-modal-task/justification-modal-task';
import { NonConformityModal } from './components/tasks/nonconformity-form-modal/nonconformity.form-modal';
import { NonConformityHistoryModal } from './components/tasks/nonconformity-history-modal/nonconformity.history-modal';
import { ProductDetailService } from './components/product-detail-modal/product-detail.service';
import { InfoButton } from './components/info/info.button';
import { InfoModal } from './components/info/info.modal';
import { ProductInputComponent } from './components/inputs/product-input/product.input';
import { BatchInputComponent } from './components/inputs/batch-input/batch.input';
import { FormPage } from './fields/form/form.page';
import { CustomTypeChildTaskModal } from './fields/form/custom-type-child-task/custom-type-child-task.modal';
import { HandlersModule } from './handlers/handlers.module';
import { CustomTaskService } from './services/custom-task.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BarcodeScanningModalComponent } from './components/barcode-scanning/barcode-scanning.modal';
import { DividerInputComponent } from './components/inputs/divider-input/divider.input';
import { SmartListModal } from './components/select-smart-modal/select-smart.modal';
import { TechnicalSheetInfoDetailComponent } from './components/product-detail-modal/technical-sheet-info-detail-tab/technical-sheet-info-detail.component';
import { AddButtonComponent } from './components/inputs/add-button/add-button.component';
import { RemoveButtonComponent } from './components/inputs/remove-button/remove-button.component';
import { SharedModule } from './shared/shared.module';
import { GlobalTagContainerComponent } from './fields/traceability-tags/tags/global-tag-container';
import { TagPrintingService } from './services/tags-printing.service';
import { Location } from '@angular/common';
import { Loading } from '../utils/loading/loading';
import { SameSecondaryAsPrimaryTagComponent } from './fields/traceability-tags/tags/same-secondary-as-primary.tag';
import { ReceptionTagComponent } from './fields/traceability-tags/tags/reception.tag';
import { SummaryModalComponent } from './components/summary/summary.modal';
import { ExpiringProductsSummaryComponent } from './components/summary/expiring-products/expiring-products-summary.component';
import { NonConformTemperaturesSummaryComponent } from './components/summary/non-conform-temperatures/non-conform-temperatures-summary.component';
import { GoodsReceptionSummaryComponent } from './components/summary/goods-reception/goods-reception-summary.component';
import { ThermalShockTagComponent } from './fields/traceability-tags/tags/thermal-shock.tag';
import { ExpiringProductsAlertModal } from './alerts/expiring-products-alert.modal';
import { ProductExpirationNotificationService } from './services/product-expiration-notification.service';
import { ExpiredProductsSummaryComponent } from './components/summary/expired-products/expired-products-summary.component';
import { ReceptionProductsPage } from './fields/traceability-tags/reception-products/reception-products.page';

import * as Sentry from '@sentry/capacitor';
import {
  init as sentryAngularInit,
  createErrorHandler,
  replayIntegration,
  TraceService,
  TraceModule,
  makeBrowserOfflineTransport,
  makeFetchTransport,
} from '@sentry/angular';
import { environment } from 'src/environments/environment';

export function HttpLoaderFactory(http: HttpClient, location: Location) {
  // Get the base path where the app is running
  const baseHref = location.prepareExternalUrl(''); // This will return the base URL (e.g., '/app/')

  return new TranslateHttpLoader(http, `${baseHref}assets/i18n/`, '.json');
}

// Init by passing the sibling SDK's init as the second parameter.
Sentry.init(
  {
    dsn: 'https://5c968032411f0aef8148a81c0afadb85@o4508398832779264.ingest.de.sentry.io/4508398888222800',
    integrations: [
      Sentry.browserTracingIntegration(),
      replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    release: environment.version,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', environment.apiUri],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    enabled: environment.production,
    initialScope: {
      tags: { machine: environment.machine, channel: environment.channel },
    },
  },
  sentryAngularInit
);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    DeveloperNotesModal,
    PasswordModal,
    PrinterConfigModal,
    PromptModal,
    PinpadComponent,
    AlertModal,
    BatchCreateModal,
    BatchDetailModal,
    BatchListModal,
    BatchSelectModal,
    IbersolProductCreateModal,
    ProductCreateModal,
    ProductBatchesTab,
    ProductExpirationTab,
    ProductDetailModal,
    ProductListModal,
    FilteredTasksModal,
    CreateTaskModal,
    ProductInfoDetailComponent,
    TechnicalSheetInfoDetailComponent,
    PhotoButtonComponent,
    PhotoHistoryButtonComponent,
    PhotoModalComponent,
    HistoryModal,
    LabelFieldsModal,
    ReceiptTorribaComponent,
    TagModalComponent,
    TasksModalComponent,
    DocumentsFiltersModalComponent,
    DocumentsFiltersProductLotModalComponent,
    ExpirationProductDetailModal,
    RefrigeratedTagComponent,
    FrozenTagComponent,
    ProductInputComponent,
    BatchInputComponent,
    TasksQuickFillingPage,
    ProductRawMaterialUsedListModal,
    CreateAndFillCustomTypeTaskModal,
    CreateAndFillCustomTypeTaskModal,
    InsertManualEntryModal,
    MyKeyboard,
    InfoButton,
    InfoModal,
    MissingRequiredFieldsModal,
    ProductInstancesModal,
    SearchModal,
    SelectCustomTypeTaskModal,
    SelectEntryModal,
    SelectIngredientMenu,
    InputBarcodeModal,
    TraceabilityTagsPage,
    RefrigeratedProductsPage,
    CustomTypeChildTaskModal,
    RefrigeratedProductDetailModal,
    ReceptionTagComponent,
    CommonlyUsedProductsModal,
    PhotoHistoryModalComponent,

    BooleanInputComponent,
    DateInputComponent,
    DatetimeInputComponent,
    FileInputComponent,
    ListInputComponent,
    NumberInputComponent,
    PrevisaoInputComponent,
    SelectInputComponent,
    TextInputComponent,
    TimeInputComponent,
    DividerInputComponent,
    TasksQuickFillingPage,
    FormTaskComponent,
    FormPage,
    FormTaskModal,
    HistoryTaskModal,
    HistoryTask,
    JustificationModal,
    JustificationModalTask,
    NonConformityModal,
    NonConformityHistoryModal,
    BarcodeScanningModalComponent,
    SmartListModal,
    AddButtonComponent,
    RemoveButtonComponent,
    GlobalTagContainerComponent,
    SameSecondaryAsPrimaryTagComponent,
    ThermalShockTagComponent,
    SummaryModalComponent,
    ExpiringProductsSummaryComponent,
    NonConformTemperaturesSummaryComponent,
    GoodsReceptionSummaryComponent,
    ExpiringProductsAlertModal,
    ExpiredProductsSummaryComponent,
    ReceptionProductsPage,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    FormsModule, // Add FormsModule here
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, Location],
      },
    }),
    ReactiveFormsModule,
    HandlersModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedModule,
    TraceModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    EmployeeService,
    AuthenticationService,
    OfflineService,
    Loading,
    AlertService,
    SyncingService,
    TasksService,
    StoresService,
    AuthenticatedHttpService,
    UploadAll, // Add UploadAll here
    DownloadAll, // Add DownloadAll here
    ZonesService,
    TasksService,
    TabsService,
    PrintSovenaService,
    PrintTorribaService,
    PrintService,
    ProductDetailService,
    CustomTaskService,
    TagPrintingService,
    ProductExpirationNotificationService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
