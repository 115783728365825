export class UrlEncodedString {
  private body: string = '';

  public append(name: string, value: string): void {
    if (this.body === '') {
      this.body = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    } else {
      this.body += '&' + encodeURIComponent(name) + '=' + encodeURIComponent(value);
    }
  }

  public appendS(name: string): void {
    if (this.body === '') {
      this.body = encodeURIComponent(name);
    } else {
      this.body += '&' + encodeURIComponent(name);
    }
  }

  public appendD(name: string, value1: string, value2: string): void {
    if (this.body === '') {
      this.body = encodeURIComponent(name) + '=' + encodeURIComponent(value1) + '|' + encodeURIComponent(value2);
    } else {
      this.body += '&' + encodeURIComponent(name) + '=' + encodeURIComponent(value1) + '|' + encodeURIComponent(value2);
    }
  }

  public toString(): string {
    return this.body;
  }
}
