import { Component } from '@angular/core';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { FieldsService } from 'src/app/services/fields.service';
import { StoresService } from 'src/app/services/stores.service';
import { SyncingService } from 'src/app/services/syncing';
import { TasksService } from 'src/app/services/tasks.service';


@Component({
  selector: 'app-label-fields-modal',
  templateUrl: 'label-fields.modal.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LabelFieldsModal {
  store: any;
  product: any;
  preparationDate: string;
  useDate: string;
  expirationDate: string;

  constructor(
    private nav: NavController,
    private fieldsService: FieldsService,
    private syncing: SyncingService,
    public translate: TranslateService,
    private modalController: ModalController,
    private storesService: StoresService,
    private tasksService: TasksService,
    private navParams: NavParams,
  ) {
    this.product = this.navParams.data['product'];
    this.preparationDate = moment().format("YYYY-MM-DDTHH:mm");
    this.expirationDate = moment().add(1, 'day').format("YYYY-MM-DDTHH:mm");
    this.useDate = moment().add(1, 'hour').format("YYYY-MM-DDTHH:mm");
  }

  close() {
    this.modalController.dismiss();
  }

  pressPrint() {
    this.print();
  }

  print() {
    const self = this;

    interface LabelData {
      id: number;
      functions: Record<string, any>;
    }

    interface CallbackData {
      ret: any;
      track1?: any;
      track2?: any;
      track3?: any;
    }

    let label_data: LabelData = { id: 0, functions: {} };
    const localAddress = "//127.0.0.1:18080/WebPrintSDK/";
    const connectionMode: "http:" | "https:" | "ws:" | "wss:" = "http:";  // Adjusted type
    let wsPrint: any = null;
    let label_func: Record<string, any> = {};
    let incLabelNum = 0;

    function viewResult(result: any) {
      // console.log(result);
    }

    class WebSocketPrint {
      private _websocket!: WebSocket;  // Added the ! operator
      private _callback: (data: CallbackData) => void;
      private _request: string;
      private _connectedsocket = false;

      constructor(serverURL: string, strPrinterName: string, request: string, callback: (data: CallbackData) => void) {
        this._callback = callback;
        this._request = request;
        this.init(serverURL, strPrinterName, request);
      }

      private init(serverURL: string, strPrinterName: string, request: string) {
        this._websocket = new WebSocket(serverURL + strPrinterName + request);
        this._websocket.onopen = this.onOpen;
        this._websocket.onerror = this.onError;
        this._websocket.onmessage = this.onMessage;
        this._websocket.onclose = this.onClose;
      }

      private onOpen = (event: Event) => {
        // console.log('open : ' + uri);
      };

      private onError = (event: Event) => {
        if (this._websocket.readyState == 3) {
          this._callback({ ret: "Cannot connect to server" });
        }
      };

      private onMessage = (msg: MessageEvent) => {
        if (this._websocket.readyState == 1) {
          const res = JSON.parse(msg.data);
          const ret = res.Result;
          const responseID = res.ResponseID;

          if (this._request == "/requestMSRData") {
            const track1 = res.Track1;
            const track2 = res.Track2;
            const track3 = res.Track3;

            this._callback({ ret, track1, track2, track3 });
          } else {
            this._callback({ ret: responseID + ":" + ret });
          }
        } else {
          this._callback({ ret: msg.data });
        }
      };

      private onClose = (msg: CloseEvent) => {
        if (!this._connectedsocket) {
          this._callback({ ret: "Cannot connect to server" });
        }

        this._websocket.close();
        this._connectedsocket = false;
        wsPrint = null;
      };

      public send(strSubmit: string) {
        if (this._websocket.readyState == 1) {
          this._websocket.send(strSubmit);
        } else {
          this._websocket.onopen = () => {
            if (this._websocket.readyState == 1) {
              this._websocket.send(strSubmit);
              this._connectedsocket = true;
            }
          };
        }
      }
    }

    PrintLabel();

    function PrintLabel() {
      const _inch = 2;
      const rotation = 3;
      let issueID = 1;
      setLabelId(issueID);
      clearBuffer();

      if (_inch == 2) {
        // 2inch sample
        setWidth(380);
        drawDeviceFont(self.product.name, 0, 20, "0", 2, 1, 0, 0, 0, 0);
        drawDeviceFont('Preparação', 0, 80, "0", 2, 1, 0, 0, 0, 0);
        drawDeviceFont(moment(self.preparationDate).format("DD-MM-YYYY HH:mm"), 0, 120, "0", 2, 1, 0, 0, 0, 0);
        drawDeviceFont('Utilização', 0, 180, "0", 2, 1, 0, 0, 0, 0);
        drawDeviceFont(moment(self.useDate).format("DD-MM-YYYY HH:mm"), 0, 220, "0", 2, 1, 0, 0, 0, 0);
        drawDeviceFont('Expiração', 0, 280, "0", 2, 1, 0, 0, 0, 0);
        drawDeviceFont(moment(self.expirationDate).format("DD-MM-YYYY HH:mm"), 0, 320, "0", 2, 1, 0, 0, 0, 0);
      } else if (_inch == 3) {
        // 3inch sample
        setWidth(576);
        drawDeviceFont("1234567890", 10, 15, "0", 2, 2, 0, 0, 0, 0);

        if (getBrowser().name == 'Netscape') { // for iOS
          drawDeviceFont("Sample", 10, 40, "2", 4, 4, 0, 0, 1, 0);
        } else {
          drawTrueTypeFont("Sample", 10, 40, "Arial", 80, 0, false, true, false, true);
        }
        draw1DBarcode("1234567890", 10, 180, 1, 3, 2, 96, 0, 3);
        drawBlock(10, 60, 556, 170, "B", 5);
        drawVectorFont("Vector Font", 10, 350, "U", 40, 40, 0, 0, 1, 0, 0, 0, 0);
      } else if (_inch == 4) {
        // 4inch sample
        setWidth(832);
        drawDeviceFont("1234567890", 10, 15, "0", 2, 2, 0, 0, 0, 0);

        if (getBrowser().name == 'Netscape') { // for iOS
          drawDeviceFont("Sample", 10, 40, "2", 4, 4, 0, 0, 1, 0);
        } else {
          drawTrueTypeFont("Sample", 10, 40, "Arial", 80, 0, false, true, false, true);
        }
        draw1DBarcode("1234567890", 10, 180, 1, 3, 2, 96, 0, 3);
        drawBlock(10, 60, 800, 170, "B", 5);
        drawVectorFont("Vector Font", 10, 350, "U", 40, 40, 0, 0, 1, 0, 0, 0, 0);
      } else {
        // error
        return;
      }

      printBuffer();

      const strSubmit = getLabelData();

      issueID++;
      requestPrint("Printer1", strSubmit, viewResult);
    }

    function toHexBinary(s: string) {
      const l = s.length;
      const r = new Array(l);
      for (let i = 0; i < l; i++) {
        r[i] = ("0" + s.charCodeAt(i).toString(16)).slice(-2);
      }
      return r.join("");
    }

    function makeResultInquiryData(requestId: any, responseId: any, timeout: any) {
      return `{"RequestID":${requestId},"ResponseID":"${responseId}","Timeout":${timeout}}`;
    }

    function checkResult(method: string, strPrinterName: string, requestId: any, responseId: any, _callback: (result: any) => void) {
      const serverURL = getServerURL().url;
      const requestURL = serverURL + strPrinterName + "/checkStatus";
      const inquiryData = makeResultInquiryData(requestId, responseId, 30);

      let xmlHttpCheck: XMLHttpRequest | false = false;
      if (window.XMLHttpRequest) {
        xmlHttpCheck = new XMLHttpRequest();
      }

      if (xmlHttpCheck) {
        xmlHttpCheck.open(method, requestURL, true);
        xmlHttpCheck.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xmlHttpCheck.send(inquiryData);
        xmlHttpCheck.onreadystatechange = function () {
          if (xmlHttpCheck?.readyState == 4 && xmlHttpCheck.status == 200) {
            const res = JSON.parse(xmlHttpCheck.responseText);
            const ret = res.Result;

            if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
              checkResult(method, strPrinterName, requestId, responseId, _callback);
            } else {
              _callback(res.ResponseID + ":" + ret);
            }
          } else if (xmlHttpCheck?.readyState == 4 && xmlHttpCheck.status == 404) {
            _callback("No printers");
          } else if (xmlHttpCheck?.readyState == 4) {
            _callback("Cannot connect to server");
          }
        };
      }
    }

    function requestPrint(strPrinterName: string, strSubmit: string, _callback: (result: any) => void) {
      _callback("");
      const serverURL = getServerURL().url;
      if (connectionMode == "ws:" || connectionMode == "wss:") {
        if (wsPrint == null)
          wsPrint = new WebSocketPrint(serverURL, strPrinterName, "", _callback);
        wsPrint.send(strSubmit);
      } else {
        const requestURL = serverURL + strPrinterName;
        let xmlHttpReq: XMLHttpRequest | false = false;
        if (window.XMLHttpRequest) {
          xmlHttpReq = new XMLHttpRequest();
        }

        if (xmlHttpReq) {
          xmlHttpReq.open('POST', requestURL, true);
          xmlHttpReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          xmlHttpReq.send(strSubmit);

          xmlHttpReq.onreadystatechange = function () {
            if (xmlHttpReq?.readyState == 4 && xmlHttpReq.status == 200) {
              const res = JSON.parse(xmlHttpReq.responseText);
              const ret = res.Result;
              if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
                checkResult('POST', strPrinterName, res.RequestID, res.ResponseID, _callback);
              } else if (ret.search("duplicated") >= 0) {
                _callback(res.Result);
              }
            } else if (xmlHttpReq?.readyState == 4 && xmlHttpReq.status == 404) {
              _callback("No printers");
            } else if (xmlHttpReq?.readyState == 4) {
              _callback("Cannot connect to server");
            }
          };
        }
      }
    }

    function getServerURL() {
      const serverURL = connectionMode + localAddress;
      return {
        url: serverURL
      };
    }

    function setConnectionMode(mode: string) {
      // connectionMode = mode; // This is now a constant, so it cannot be reassigned
    }

    function getBrowser() {
        const ua = navigator.userAgent;
        let tem: RegExpMatchArray | null;  // Changed from RegExpExecArray to RegExpMatchArray
        const M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) as RegExpMatchArray | null;
          return { name: 'IE', version: (tem && tem[1]) || '' };
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\bOPR|Edge\/(\d+)/) as RegExpMatchArray | null;
          if (tem != null) {
            return { name: 'Opera', version: tem[1] };
          }
        }
        const name = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i) as RegExpMatchArray | null) != null) {
          name.splice(1, 1, tem[1]);
        }
        return {
          name: name[0],
          version: name[1]
        };
      }
      
    function isEmpty(data: any) {
      if (typeof data == "undefined" || data == null || data == "") return true;
      else return false;
    }

    function getLabelData() {
      label_data.functions = label_func;
      label_func = {};
      incLabelNum = 0;

      return JSON.stringify(label_data);
    }

    function setLabelId(setId: any) {
      label_data.id = setId;
    }

    function checkLabelStatus() {
      const _a = { checkLabelStatus: [] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function clearBuffer() {
      const _a = { clearBuffer: [] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function printBuffer() {
      switch (arguments.length) {
        case 0:
          const _a0 = { printBuffer: [] };
          label_func["func" + incLabelNum] = _a0;
          incLabelNum++;
          break;
        case 1:
          const _a1 = { printBuffer: [arguments[0]] };
          label_func["func" + incLabelNum] = _a1;
          incLabelNum++;
          break;
      }
    }

    function directDrawText(text: string) {
      const _a = { directDrawText: [text] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function directDrawHex(hexstring: string) {
      const _a = { directDrawHex: [hexstring] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setCharacterset(ics: any, charset: any) {
      const _a = { setCharacterset: [ics, charset] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawDeviceFont(text: string, x: number, y: number, fontType: string, widthEnlarge: number, heightEnlarge: number, rotation: number, invert: number, bold: number, alignment: number) {
      const _a = { drawDeviceFont: [text, x, y, fontType, widthEnlarge, heightEnlarge, rotation, invert, bold, alignment] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawVectorFont(text: string, x: number, y: number, fontType: string, fontWidth: number, fontHeight: number, rightSpacing: number, bold: number, invert: number, italic: number, rotation: number, alignment: number, rtol: number) {
      const _a = { drawVectorFont: [text, x, y, fontType, fontWidth, fontHeight, rightSpacing, bold, invert, italic, rotation, alignment, rtol] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawTrueTypeFont(text: string, x: number, y: number, fontname: string, fontsize: number, rotation: number, italic: boolean, bold: boolean, underline: boolean, compression: boolean) {
      const _a = { drawTrueTypeFont: [text, x, y, fontname, fontsize, rotation, italic, bold, underline, compression] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function draw1DBarcode(data: string, x: number, y: number, symbol: number, narrowbar: number, widebar: number, height: number, rotation: number, hri: number) {
      const _a = { draw1DBarcode: [data, x, y, symbol, narrowbar, widebar, height, rotation, hri] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawMaxiCode(data: string, x: number, y: number, mode: number) {
      const _a = { drawMaxiCode: [data, x, y, mode] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawPDF417(data: string, x: number, y: number, maxRowCount: number, maxColumnCount: number, eccLevel: number, dataCompressionMethod: number, hri: number, barcodeOriginPoint: number, moduleWidth: number, barHeight: number, rotation: number) {
      const _a = { drawPDF417: [data, x, y, maxRowCount, maxColumnCount, eccLevel, dataCompressionMethod, hri, barcodeOriginPoint, moduleWidth, barHeight, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawQRCode(data: string, x: number, y: number, model: number, eccLevel: number, size: number, rotation: number) {
      const _a = { drawQRCode: [data, x, y, model, eccLevel, size, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawDataMatrix(data: string, x: number, y: number, size: number, invert: number, rotation: number = 0) {
      const _a = { drawDataMatrix: [data, x, y, size, invert, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawAztec(data: string, x: number, y: number, size: number, extendedChannel: number, eccSymbol: number, menuSymbol: number, numberOfSymbols: number, optionalID: number, rotation: number) {
      const _a = { drawAztec: [data, x, y, size, extendedChannel, eccSymbol, menuSymbol, numberOfSymbols, optionalID, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawCode49(data: string, x: number, y: number, narrowbar: number, widebar: number, height: number, hri: number, starting: number, rotation: number) {
      const _a = { drawCode49: [data, x, y, narrowbar, widebar, height, hri, starting, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawCODABLOCK(data: string, x: number, y: number, narrowbar: number, widebar: number, height: number, security: number, dataColumns: number, mode: number, rowsEncode: number) {
      const _a = { drawCODABLOCK: [data, x, y, narrowbar, widebar, height, security, dataColumns, mode, rowsEncode] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawMicroPDF(data: string, x: number, y: number, moduleWidth: number, height: number, mode: number, rotation: number) {
      const _a = { drawMicroPDF: [data, x, y, moduleWidth, height, mode, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawIMB(data: string, x: number, y: number, rotation: number, hri: number) {
      const _a = { drawIMB: [data, x, y, rotation, hri] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawMSIBarcode(data: string, x: number, y: number, narrowbar: number, widebar: number, height: number, checkdigit: number, checkdigitHri: number, rotation: number, hri: number) {
      const _a = { drawMSIBarcode: [data, x, y, narrowbar, widebar, height, checkdigit, checkdigitHri, rotation, hri] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawPlesseyBarcode(data: string, x: number, y: number, narrowbar: number, widebar: number, height: number, checkdigitHri: number, rotation: number, hri: number) {
      const _a = { drawPlesseyBarcode: [data, x, y, narrowbar, widebar, height, checkdigitHri, rotation, hri] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawTLC39Barcode(data: string, x: number, y: number, narrowbar: number, widebar: number, height: number, pdf417Height: number, pdf417narrowbar: number, rotation: number) {
      const _a = { drawTLC39Barcode: [data, x, y, narrowbar, widebar, height, pdf417Height, pdf417narrowbar, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawRSSBarcode(data: string, x: number, y: number, rssType: number, magnification: number, separatorHeight: number, barcodeHeight: number, segmentWidth: number, rotation: number) {
      const _a = { drawRSSBarcode: [data, x, y, rssType, magnification, separatorHeight, barcodeHeight, segmentWidth, rotation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawBitmap(data: string, x: number, y: number, width: number, dither: number) {
      const _a = { drawBitmap: [data, x, y, width, dither] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawBitmapFile(filepath: string, x: number, y: number, width: number, dither: number) {
      const _a = { drawBitmapFile: [filepath, x, y, width, dither] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function printPDF(filepath: string, pageNumber: number, width: number, dither: number) {
      const _a = { printPDF: [filepath, pageNumber, width, dither] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawBlock(startHorizontal: number, startVertical: number, endHorizontal: number, endVertical: number, option: string, thickness: number) {
      const _a = { drawBlock: [startHorizontal, startVertical, endHorizontal, endVertical, option, thickness] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function drawCircle(startHorizontal: number, startVertical: number, circleSize: number, muliplier: number) {
      const _a = { drawCircle: [startHorizontal, startVertical, circleSize, muliplier] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setPrintingType(type: string) {
      const _a = { setPrintingType: [type] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setMargin(horizontal: number, vertical: number) {
      const _a = { setMargin: [horizontal, vertical] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setLength(labelLength: number, gapLength: number, media: string, offset: number) {
      const _a = { setLength: [labelLength, gapLength, media, offset] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setWidth(width: number) {
      const _a = { setWidth: [width] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setSpeed(speed: number) {
      const _a = { setSpeed: [speed] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setDensity(density: number) {
      const _a = { setDensity: [density] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setOrientation(orientation: number) {
      const _a = { setOrientation: [orientation] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setOffset(offset: number) {
      const _a = { setOffset: [offset] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setTearoffPosition(position: number) {
      const _a = { setTearoffPosition: [position] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setAutoCutter(enable: boolean, period: number) {
      const _a = { setAutoCutter: [enable, period] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setupRFID(rfidType: number, numberOfRetries: number, numberOfLabel: number, radioPower: number) {
      const _a = { setupRFID: [rfidType, numberOfRetries, numberOfLabel, radioPower] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function calibrateRFID() {
      const _a = { calibrateRFID: [] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setRFIDPosition(transPosition: number) {
      const _a = { setRFIDPosition: [transPosition] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function writeRFID(dataType: number, startingBlockNumber: number, dataLength: number, data: string) {
      const _a = { writeRFID: [dataType, startingBlockNumber, dataLength, data] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setRFIDPassword(oldAccessPwd: string, oldKillPwd: string, newAccessPwd: string, newKillPwd: string) {
      const _a = { setRFIDPassword: [oldAccessPwd, oldKillPwd, newAccessPwd, newKillPwd] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function lockRFID() {
      const _a = { lockRFID: [] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function setEPCDataStructure(totalSize: number, fieldSizes: number[]) {
      const _a = { setEPCDataStructure: [totalSize, fieldSizes] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }

    function printINI(filename: string, ...args: (string | number | boolean | null | undefined)[]) {
      const _a = { printINI: [filename, ...args] };
      label_func["func" + incLabelNum] = _a;
      incLabelNum++;
    }
  }
}
