<div
  class="field_button"
  [ngStyle]="{
    'background-color': buttonStyle?.backgroundColor || '#45a1c8',
    'border-color': buttonStyle?.borderColor || '#fff'
  }"
>
  <div>
    <h3>{{ title }}</h3>
  </div>
  <div
    style="position: relative; display: inline-block; width: 100%; height: 65px"
  >
    <!-- Imagem personalizada quando existe URL e o tipo é 'level' com creatableTaskTypes -->
    <img
      *ngIf="buttonStyle?.imageUrl"
      [src]="'assets/img/' + buttonStyle?.imageUrl"
      class="fa-btn"
    />
  </div>
  <!--   <div
    class="task-counter"
    [ngClass]="{
      late: isAnyTaskLate,
      'on-time': !isAnyTaskLate
    }"
    *ngIf="includeTasksCounter"
  >
    <span class="task-number"> {{ tasksCount }} </span>
  </div> -->
  <ion-chip
    *ngIf="!noTasksCounter"
    [style.--background]="isAnyTaskLate ? 'orange' : 'green'"
    [style.--color]="'white'"
    >{{ tasksCount }}</ion-chip
  >
</div>
