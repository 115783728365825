import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert.modal.html',
  styleUrls: ['../../../theme/modals.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AlertModal {
  onTryAgain;
  title;
  text;

  constructor(private modalCtrl: ModalController, private navParams: NavParams) {
    this.title = navParams.data['title'];
    this.text = navParams.data['text'];
    this.onTryAgain = navParams.data['onTryAgain'];
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pressTryAgain() {
    if (this.onTryAgain) {
      this.onTryAgain();
    }
    this.modalCtrl.dismiss();
  }
}
