import { HttpHeaders, HttpParams } from '@angular/common/http';

export class Link {
  target!: string;
  headers: HttpHeaders;
  params: HttpParams;

  constructor(path: string, headers?: { [name: string]: string | string[] }, queryString?: string) {
    this.headers = new HttpHeaders(headers || {});
    this.params = new HttpParams();

    if (queryString) {
      this.setTarget(`${path}?${queryString}`);
    } else {
      this.setTarget(path);
    }
  }

  setTarget(target: string): void {
    this.target = target;
  }

  setHeaders(headers: { [name: string]: string | string[] }): void {
    this.headers = new HttpHeaders(headers);
  }

  appendHeader(name: string, value: string | string[]): void {
    this.headers = this.headers.append(name, value);
  }

  get options() {
    return {
      headers: this.headers,
      params: this.params,
    };
  }
}
