import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication';
import { Observable, ReplaySubject, Subject, of, throwError } from 'rxjs';
import { OfflineService } from './offline.service';
import { AuthenticationInfo } from '../entities/authentication-info';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import {
  concatMap,
  catchError,
  finalize,
  tap,
  timeout,
  map,
} from 'rxjs/operators';
import { Link } from 'src/utils/link';
import { ACCESS_TOKEN, APP_VERSION } from 'src/utils/headers';
import { SECOND } from 'src/utils/units/time.units';
import { version } from 'src/environments/version';

@Injectable()
export class AuthenticatedHttpService {
  private requestQueue = new Subject<() => Observable<any>>();

  private processQueue() {
    this.requestQueue
      .asObservable()
      .pipe(
        concatMap((requestFn) =>
          requestFn().pipe(
            catchError((error) => {
              // Handle error here
              return of(error);
            })
          )
        )
      )
      .subscribe();
  }

  enqueueRequest(requestFn: () => Observable<any>): Observable<any> {
    const responseSubject = new ReplaySubject(1);

    const wrappedRequestFn = () =>
      requestFn().pipe(
        tap((response) => responseSubject.next(response)),
        catchError((error) => {
          responseSubject.error(error);
          return of(error); // or throwError(error) depending on how you want to handle this
        }),
        finalize(() => responseSubject.complete())
      );

    this.requestQueue.next(wrappedRequestFn);
    return responseSubject.asObservable();
  }

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService,
    private offlineService: OfflineService
  ) {
    this.processQueue();
  }

  get(link: Link): Observable<any> {
    link.appendHeader(ACCESS_TOKEN, this.authentication.getAuthToken());
    link.appendHeader(APP_VERSION, version);

    if (this.offlineService.isOffline) {
      return throwError(() => new Error('offline'));
    }

    return this.http
      .get(link.target, {
        headers: link.headers,
        observe: 'response',
      })
      .pipe(
        map((response: any) => response),
        catchError((res: any): Observable<any> => {
          if (res.status === 304) {
            return of(res);
          } else {
            return throwError(() => new Error(res.message || 'Server error'));
          }
        }),
        timeout(60 * SECOND)
      );
  }

  getBlob(link: Link): Observable<any> {
    link.appendHeader(ACCESS_TOKEN, this.authentication.getAuthToken());
    link.appendHeader(APP_VERSION, version);

    if (this.offlineService.isOffline) {
      return throwError(() => new Error('offline'));
    }

    return this.http
      .get(link.target, {
        headers: link.headers,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response: any) => response),
        catchError((res: any): Observable<any> => {
          if (res.status === 304) {
            return of(res);
          } else {
            return throwError(() => new Error(res.message || 'Server error'));
          }
        }),
        timeout(60 * SECOND)
      );
  }

  post(link: Link, body: any, authInfo: AuthenticationInfo): Observable<any> {
    const requestFn = () => {
      if (this.offlineService.isOffline) {
        return throwError(() => new Error('offline'));
      }

      if (!authInfo.token) {
        return throwError(() => new Error('No valid token available'));
      }

      if (!link.target) {
        return throwError(() => new Error('Link target is undefined'));
      }

      link.headers = new HttpHeaders()
        .set(ACCESS_TOKEN, authInfo.token)
        .set(APP_VERSION, version);

      return this.http
        .post(link.target, body, link.options)
        .pipe(timeout(60 * SECOND));
    };

    return this.enqueueRequest(requestFn);
  }

  put(
    link: Link,
    body: any,
    authInfo: AuthenticationInfo = this.authentication.getAuthInfo()
  ): Observable<any> {
    const requestFn = () => {
      if (this.offlineService.isOffline) {
        return throwError(() => new Error('offline'));
      }

      if (!authInfo.token) {
        return throwError(() => new Error('No valid token available'));
      }

      if (!link.target) {
        return throwError(() => new Error('Link target is undefined'));
      }

      link.headers = new HttpHeaders()
        .set(ACCESS_TOKEN, authInfo.token)
        .set(APP_VERSION, version);

      return this.http
        .put(link.target, body, link.options)
        .pipe(timeout(60 * SECOND));
    };

    return this.enqueueRequest(requestFn);
  }

  delete(link: Link, authInfo: AuthenticationInfo): Observable<any> {
    const requestFn = () => {
      if (this.offlineService.isOffline) {
        return throwError(() => new Error('offline'));
      }

      if (!authInfo.token) {
        return throwError(() => new Error('No valid token available'));
      }

      if (!link.target) {
        return throwError(() => new Error('Link target is undefined'));
      }

      link.headers = new HttpHeaders()
        .set(ACCESS_TOKEN, authInfo.token)
        .set(APP_VERSION, version);

      return this.http
        .delete(link.target, link.options)
        .pipe(timeout(60 * SECOND));
    };

    return this.enqueueRequest(requestFn);
  }

  patch(
    link: Link,
    body: any,
    authInfo: AuthenticationInfo = this.authentication.getAuthInfo()
  ): Observable<any> {
    const requestFn = () => {
      if (this.offlineService.isOffline) {
        return throwError(() => new Error('offline'));
      }

      if (!authInfo.token) {
        return throwError(() => new Error('No valid token available'));
      }

      if (!link.target) {
        return throwError(() => new Error('Link target is undefined'));
      }

      link.headers = new HttpHeaders()
        .set(ACCESS_TOKEN, authInfo.token)
        .set(APP_VERSION, version);

      return this.http
        .patch(link.target, body, link.options)
        .pipe(timeout(60 * SECOND));
    };

    return this.enqueueRequest(requestFn);
  }

  request(req: HttpRequest<any>): Observable<any> {
    const requestFn = () => {
      if (this.offlineService.isOffline) {
        return throwError(() => new Error('offline'));
      }

      req = req.clone({
        setHeaders: {
          'Access-Token': this.authentication.getAuthToken(),
          'app-version': version,
        },
      });

      return this.http.request(req).pipe(timeout(60 * SECOND));
    };

    return this.enqueueRequest(requestFn);
  }
}
