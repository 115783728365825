import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-non-conform-temperatures-summary',
  templateUrl: './non-conform-temperatures-summary.component.html',
  styleUrls: ['./non-conform-temperatures-summary.component.scss'],
})
export class NonConformTemperaturesSummaryComponent {
  @Input() nonConformTemperatures: {
    machine: string;
    temperature: number;
    date: string;
  }[] = [];

  constructor(private modalController: ModalController) {}

  navigateToNonConformTemperatures() {
    this.modalController.dismiss({ navigateTo: 'non-conform-temperatures' });
  }

  shouldShowWarningIcon(temp: any): boolean {
    // For now, always return true
    return true;
  }
}
