<ion-header>
    <ion-toolbar>
      <ion-title>{{ 'Filtros adicionais' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <div class="modalContent">
      <div class="modalDescription">
        <ion-item>
          <product-input
            [(value)]="selectedProduct"
            [responseField]="productsResponseField"
          ></product-input>
        </ion-item>
        <ion-item>
          <app-batch-input
            [(value)]="selectedBatch"
            [responseField]="batchResponseField"
            [product]="selectedProduct"
          ></app-batch-input>
        </ion-item>
      </div>
      <div class="modalActions">
        <ion-button expand="full" (click)="accept()">{{ 'OK' | translate }}</ion-button>
      </div>
    </div>
  </ion-content>
  