import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProductDetailService } from '../product-detail.service';

@Component({
  selector: 'app-product-info-detail',
  templateUrl: './product-info-detail.component.html',
  styleUrls: ['./product-info-detail.component.scss'],

})
export class ProductInfoDetailComponent implements OnInit {
  product: any;

  constructor(private productDetailService: ProductDetailService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.product = this.productDetailService.product;
  }
}
