import { Component, NgZone, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import moment from 'moment';
import cuid from 'cuid';
import { TaskType } from '../../../entities/tasks/custom-task-type';
import { getTargetUser, UserInfo } from '../../../entities/authentication-info';
import { distinct } from '../../../../utils/array.utils';
import { firstValueFrom, forkJoin, mergeMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PromptModal } from '../../../components/prompt/prompt.modal';
import { Buffer } from 'buffer';
import { ProductListModal } from '../../../components/product-list-modal/product-list.modal';
import { BatchListModal } from '../../../components/batch/batch-list-modal/batch-list.modal';
import { getHerdadeOrLagarCountersString } from '../../../sdk/myharvest';
import { BatchSelectModal } from '../../../components/batch/batch-select-modal/batch-select.modal';
import { Device } from '@capacitor/device';
import { MissingRequiredFieldsModal } from '../missing-required-fields/missing-required-fields.modal';
import { InputBarcodeModal } from '../../level/input-barcode/input-barcode.modal';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { CustomTypeChildTaskModal } from '../custom-type-child-task/custom-type-child-task.modal';
import { checkValidity } from '../../../../utils/conditional-modal-creator/conditional-modal-creator';
import { translate } from '../../../../utils/translate.utils';
import { PhotoModalComponent } from '../../../components/photo/photo.modal';
import { FabricatedProduct } from '../../../entities/product';
import {
  Task,
  TaskAndResponseObject,
  TaskResponse,
  ResponseField,
} from '../../../entities/tasks/task';
import { AlertService } from '../../../services/alert.service';
import { AuthenticationService } from '../../../services/authentication';
import { CustomTaskTypesService } from '../../../services/custom-task-types.service';
import { CustomTaskService } from '../../../services/custom-task.service';
import { EmployeeService } from '../../../services/employee.service';
import { FieldsService } from '../../../services/fields.service';
import { ProductsService } from '../../../services/products.service';
import { SensorsService } from '../../../services/sensors.service';
import { StoresService } from '../../../services/stores.service';
import { TasksService } from '../../../services/tasks.service';
import { scanBarcode } from '../../../../utils/barcode.utils';
import { deepCopy } from '../../../../utils/deepCopy';
import { PrinterPage } from '../../../printer/printer.page';
import { BluetoothService } from '../../../services/bluetoothle.service';
import { myParseFloat } from 'src/utils/number.utils';
import { FormUtils } from 'src/utils/form.utils';

@Component({
  templateUrl: 'create-and-fill-custom-type-task.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateAndFillCustomTypeTaskModal implements OnInit {
  type!: TaskType;
  userInfo!: UserInfo;
  task!: Task;
  response: any;
  form: any;
  extras: any;
  onTaskClosedSuccessfully: any;
  isClicked = false;
  data: any;
  @Output() saveNewOption = new EventEmitter<string>();

  prepackageTasks: TaskAndResponseObject[] = [];
  unfreezingTasks: TaskAndResponseObject[] = [];
  warmTemperatureControlTasks: TaskAndResponseObject[] = [];
  inBeefRegisterTasks: TaskAndResponseObject[] = [];
  pendingTasksAndResponses: TaskAndResponseObject[] = [];
  fermentationProductsTasks: TaskAndResponseObject[] = [];

  isConnected = false;

  areYouSureValidator = {
    condition: () =>
      this.task.type === 'inProductApanhaAzeitonaNF' &&
      this.response['Tarefa terminada'],
    modalCreator: () => this.createAreYouSureModal(),
  };

  isTemperatureOilOutOfBoundsValidator = {
    condition: () => this.isTemperatureOilOutOfBounds(),
    modalCreator: async () => await this.createTemperatureOilOutOfBoundsModal(),
  };
  invalidInput!: boolean;
  inputElement: any;
  characterCounts: { [key: string]: number } = {};
  initialized: boolean = false;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private fieldsService: FieldsService,
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private customTasksService: CustomTaskService,
    public storesService: StoresService,
    private toastController: ToastController,
    private customTaskTypesService: CustomTaskTypesService,
    private translator: TranslateService,
    private productsService: ProductsService,
    private bluetoothService: BluetoothService,
    private zone: NgZone,
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private sensorService: SensorsService
  ) {}
  async ngOnInit(): Promise<void> {
    this.type = deepCopy(this.navParams.get('type'));
    this.form = this.navParams.get('form');
    this.extras = this.navParams.get('extras') || {};
    this.onTaskClosedSuccessfully = this.navParams.get(
      'onTaskClosedSuccessfully'
    );
    this.response = await this.getResponseFromTaskType();
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      throw new Error('UserInfo is null');
    }
    this.userInfo = userInfo;
    if (!this.userInfo.storeId) {
      throw new Error('storeId is undefined');
    }
    this.task = {
      id: cuid(), //use lib to generate id
      type: this.type.type,
      formId: this.form.id,
      title: this.getTitle(),
      subtitle: this.getSubtitle(),
      startDate: moment().valueOf(),
      endDate: moment().hours(23).minutes(59).seconds(59).valueOf(),
      visualizationDate: moment().add(1, 'year').valueOf(),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: this.userInfo.storeId,
        userIds: getTargetUser(this.storesService.store, this.userInfo),
      },
      documentsIds: [],
      isCustomType: true,
      superType: this.type.superType,
      productId: this.extras['product'] ? this.extras['product'].id : undefined,
    };
    if (this.extras && this.extras['productDestiny']) {
      this.response['Destino do produto'] = this.extras['productDestiny'];
    }
    if (
      (this.type.type === 'oil' || this.type.type === 'oilPans') &&
      this.storesService.store &&
      this.storesService.store.modules.bluetoothOilReader
    ) {
      this.bluetoothService.connectBleThermometer(
        (res: any) => {
          if (res.value) {
            let base64Value = res.value;
            let buff = new Buffer(base64Value, 'base64');
            this.zone.run(() => {
              this.response['Temperatura (ºC):'] = buff.toString('ascii');
              this.response['AUTOMATIC_READING'] = true;
            });
          }
        },
        (res: any) => {
          if (res.value) {
            let base64Value = res.value;
            let buff = new Buffer(base64Value, 'base64');
            this.zone.run(() => {
              this.response['Resultado da leitura'] = buff.toString('ascii');
              this.response['AUTOMATIC_READING'] = true;
            });
          }
        },
        () => (this.isConnected = true)
      );
    }
    this.initialized = true;
  }

  createAreYouSureModal() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate(
          'Tem a certeza que quer fechar a tarefa?',
          this.translator
        ),
      },
    });
  }

  isTemperatureOilOutOfBounds(): boolean {
    const { type } = this.task;

    if (type === 'greenOil') {
      const temperatureOil = myParseFloat(this.response['Temperatura (ºC):']);

      const machineName = this.response['Fritadeira'].trim(); // Corrigido para string

      const machine = this.sensorService.getMachineByName(machineName);

      if (machine) {
        const okRangeMin = machine.temperatureIndicator.okRange.min;
        const okRangeMax = machine.temperatureIndicator.okRange.max;

        return temperatureOil < okRangeMin || temperatureOil > okRangeMax;
      } else {
        console.error(`Máquina com o nome ${machineName} não encontrada.`);
        return false;
      }
    }

    return false; // Adicionado fora do bloco if para garantir retorno em todos os casos
  }

  async createTemperatureOilOutOfBoundsModal() {
    const title = await translate('INVALID_TEMPERATURE_TITLE', this.translator);
    const text = await translate('INVALID_ARE_YOU_SURE', this.translator);

    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title,
        text,
        task: this.task,
      },
    });
  }

  updateCharacterCount(fieldName: string, text: string): void {
    this.characterCounts[fieldName] = text ? text.length : 0;
  }

  async getResponseFromTaskType() {
    const response: any = {
      picturesIds: [],
      non_conformity: '',
      causes: '',
      correction: '',
      commentary: '',
      ncCommentary: '',
    };
    for (const rf of this.type.metadata.responseFields) {
      await this.autofillResponse(response, rf);
      if (
        rf.type === 'list' &&
        this.type.type !== 'goodsReceptionTakeawayIntermarche'
      ) {
        if (!response[rf.name]) response[rf.name] = [];
        if (
          this.type.type === 'technicalSheetIM2' ||
          this.type.type === 'serunionTechnicalSheet' ||
          this.type.type === 'greenTechnicalSheet'
        ) {
          response[rf.name] = [
            {
              name: '',
              quantity: 1,
              unit: 'kg',
            },
          ];
        } else {
          response[rf.name] = response[rf.name].map((i: any) => {
            if (!i.productInfo) i.productInfo = [];
            return i;
          });
        }
      }
      if (response[rf.name] || rf.autofill) continue;
      if (rf.type === 'boolean') {
        response[rf.name] = false;
      } else if (rf.type === 'date') {
        response[rf.name] = moment().seconds(0).format('YYYY-MM-DD');
      } else if (rf.type === 'list') {
        response[rf.name] = [
          {
            name: '',
            quantity: 1,
            unit: 'kg',
            productInfo: [],
          },
        ];
      } else if (rf.type === 'checklist') {
        response[rf.name] = [];
      } else if (rf.type === 'group') {
        if (rf.subfields) {
          response[rf.name] = [this.autofillSubfields(rf.subfields)];
        }
      } else if (rf.type === 'photo') {
        response[rf.name] = [];
      } else {
        response[rf.name] = '';
      }
    }
    return response;
  }

  getTitle() {
    const getTitle = this.type.getTitle;
    const title =
      typeof getTitle === 'function'
        ? getTitle(this.data, this.response, this.task)
        : '';

    if (title === 'type') {
      return this.getTranslation(this.type);
    }

    if (this.extras && this.extras.product && this.extras.supplier) {
      return `${this.getTranslation(this.type)}: ${
        this.extras.supplier.name
      } - ${this.extras.product.name}`;
    } else if (
      this.extras &&
      this.extras.productFamily &&
      !this.extras.product &&
      this.extras.supplier
    ) {
      return `${this.getTranslation(this.type)}: ${
        this.extras.supplier.name
      } - ${this.extras.productFamily}`;
    }

    if (this.response['Produto'] && this.response['Fornecedor']) {
      let produtoNome = '';

      if (
        typeof this.response['Produto'] === 'object' &&
        this.response['Produto'] !== null
      ) {
        produtoNome = this.response['Produto'].name || '';
      } else {
        produtoNome = this.response['Produto'];
      }

      return `${this.getTranslation(this.type)}: ${
        this.response['Fornecedor'] === 'Outro'
          ? this.response['Outro Fornecedor']
          : this.response['Fornecedor']
      } - ${produtoNome}`;
    }

    if (title === 'typeFailure') {
      return `${this.type.name} - ${this.response['Tipo da Avaria']}`;
    } else if (title === 'documentName') {
      return `${this.type.name} - ${this.response['Nome documento']}`;
    }

    return `${this.getTranslation(this.type)}`;
  }

  getSubtitle() {
    if (this.type.getSubtitle === 'product') {
      return `${
        (this.extras && this.extras.product && this.extras.product.name) ||
        this.response['Designação do produto'] ||
        this.response['Prato'] ||
        ''
      }`;
    }
    if (this.type.getSubtitle === 'productFamily') {
      return `${
        this.response['Natureza do produto controlado']
          ? this.response['Natureza do produto controlado']
          : ''
      }${
        this.response['Nome do fornecedor']
          ? ' - ' + this.response['Nome do fornecedor']
          : ''
      }`;
    }
    if (this.type.getSubtitle === 'supplier') {
      return `${
        this.response['Fornecedor'] ? this.response['Fornecedor'] : ''
      }`;
    }
    if (this.type.getSubtitle === 'nonProgrammed') {
      return `${this.response['Tipo']}`;
    }
    if (this.type.getSubtitle === 'NF') {
      return `${this.response['Nº Guia']} - ${this.response['Herdade']}`;
    }
    if (this.type.type === 'inProductApanhaAzeitonaNF') {
      return `${this.response['Nº Guia']}`;
    }
    if (this.type.type === 'inTransitAzeitonaNF') {
      return `${this.response['Nº Guia']} - ${this.response['Matrícula Transporte']}`;
    }
    if (this.type.getSubtitle === 'guideNumber') {
      return `Guia ${this.response['Nº Guia de Remessa']}`;
    }
    if (this.type.getSubtitle === 'nameMachine') {
      return `${this.response['Nome da Máquina']}`;
    }
    if (this.type.getSubtitle === 'pestSector') {
      return this.response['Setor'] || '';
    }
    if (this.type.getSubtitle === 'equipmentName') {
      return `${this.response['Nome do equipamento']}`;
    }
    if (this.type.getSubtitle === 'typeFailure') {
      return `${this.response['Tipo da Avaria']}`;
    }
    if (this.type.getSubtitle === 'titleRequest') {
      return `${this.response['Título do pedido']}`;
    }
    if (this.type.getSubtitle === 'titleProduct') {
      return `${this.response['Produtos']}`;
    }
    if (this.type.getSubtitle === 'nameEquipment') {
      return `${this.response['Equipamento']}`;
    }
    if (this.type.getSubtitle === 'productName') {
      return `${this.response['Produto']}`;
    }
    if (this.type.getSubtitle === 'nameNewStore') {
      return `${this.response['Nome da Loja']}`;
    }
  }

  getTranslation(object: any) {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.name;
    } else if (lang === 'es') {
      return object.nameEs || object.name;
    }
    return object.name;
  }

  close() {
    this.modalController.dismiss();
  }

  getInput(responseField: any) {
    return `response['${responseField.name}']`;
  }

  async pressCamera(response: TaskResponse, responseField: ResponseField) {
    const photos = response[responseField.name as keyof TaskResponse]; // Type assertion

    const photoModal = await this.modalController.create({
      component: PhotoModalComponent,
      componentProps: {
        photos: photos,
      },
    });

    photoModal.onDidDismiss().then((result: any) => {
      if (result.data) {
        this.response[responseField.name as keyof TaskResponse] = result.data;
      } else {
        this.response[responseField.name as keyof TaskResponse] = [];
      }
    });

    return await photoModal.present();
  }

  async pressListCamera(object: any, propertyKey: string) {
    const photos = object[propertyKey];
    const photoModal = await this.modalController.create({
      component: PhotoModalComponent,
      componentProps: {
        photos: photos,
      },
    });

    photoModal.onDidDismiss().then((result) => {
      if (result.data) {
        object[propertyKey] = result.data;
      } else {
        object[propertyKey] = [];
      }
    });

    return await photoModal.present();
  }

  async pressSubmit() {
    if (this.isClicked) return;
    this.isClicked = true;
    const missingRequiredFields = this.getMissingRequiredFields();
    let isFrozenStop = false;
    let isRefrigerateStop = false;
    if (
      this.task.type === 'goodsReception' ||
      this.task.type === 'goodsReceptionNP' ||
      this.task.type === 'goodsReception2' ||
      this.task.type === 'goodsReceptionNP2'
    ) {
      if (
        (this.response['Temp. Refrigerados Amostra 1 (ºC)'] !== '' &&
          (this.response['Temp. Refrigerados Amostra 1 (ºC)'] < 0 ||
            this.response['Temp. Refrigerados Amostra 1 (ºC)'] > 5)) ||
        (this.response['Temp. Refrigerados Amostra 2 (ºC)'] !== '' &&
          (this.response['Temp. Refrigerados Amostra 2 (ºC)'] < 0 ||
            this.response['Temp. Refrigerados Amostra 2 (ºC)'] > 5))
      ) {
        const refrigeratePromise = new Promise<boolean>((resolve) => {
          const promptOutOfBounds = this.modalController.create({
            component: PromptModal,
            componentProps: {
              title: translate(
                'OUTOFBOUNDS_REFRIGERATE_TEMPERATURE_TITLE',
                this.translator
              ),
              text: translate(
                'OUTOFBOUNDS_REFRIGERATE_TEMPERATURE_TEXT',
                this.translator
              ),
            },
          });
          promptOutOfBounds.then((modal) =>
            modal.onDidDismiss().then((data: any) => {
              resolve(data);
            })
          );
          promptOutOfBounds.then((modal) => modal.present());
        });
        isRefrigerateStop = !(await refrigeratePromise);
      }
      if (
        (this.response['Temp. Congelados Amostra 1 (ºC)'] !== '' &&
          (this.response['Temp. Congelados Amostra 1 (ºC)'] < -21 ||
            this.response['Temp. Congelados Amostra 1 (ºC)'] > -15)) ||
        (this.response['Temp. Congelados Amostra 2 (ºC)'] !== '' &&
          (this.response['Temp. Congelados Amostra 2 (ºC)'] < -21 ||
            this.response['Temp. Congelados Amostra 2 (ºC)'] > -15))
      ) {
        const frozenPromise = new Promise<boolean>((resolve) => {
          const promptOutOfBounds = this.modalController.create({
            component: PromptModal,
            componentProps: {
              title: translate(
                'OUTOFBOUNDS_FROZEN_TEMPERATURE_TITLE',
                this.translator
              ),
              text: translate(
                'OUTOFBOUNDS_FROZEN_TEMPERATURE_TEXT',
                this.translator
              ),
            },
          });
          promptOutOfBounds.then((modal) =>
            modal.onDidDismiss().then((data: any) => {
              resolve(data);
            })
          );
          promptOutOfBounds.then((modal) => modal.present());
        });
        isFrozenStop = !(await frozenPromise);
      }
    }
    if (missingRequiredFields.length > 0) {
      const missingRequiredFieldsModal = await this.modalController.create({
        component: MissingRequiredFieldsModal,
        componentProps: { missingRequiredFields },
      });
      await missingRequiredFieldsModal.present();
      this.isClicked = false;
    } else if (!isFrozenStop && !isRefrigerateStop) {
      const isValid = await checkValidity(
        [this.areYouSureValidator, this.isTemperatureOilOutOfBoundsValidator],
        this.modalController
      );
      if (isValid) {
        await this.successfullySubmit();
      } else {
        this.isClicked = false;
      }
    } else {
      this.isClicked = false;
    }
  }

  private getMissingRequiredFields(): ResponseField[] {
    if (
      this.type.metadata.responseFields
        .map((rf: any) => rf.name)
        .indexOf('Tarefa terminada') !== -1
    ) {
      if (!this.response['Tarefa terminada']) return [];
    }

    return this.getArrayOfMissingRequiredFields(
      this.response,
      this.type.metadata.responseFields
    );
  }

  getArrayOfMissingRequiredFields(
    response: any,
    responseFields: ResponseField[]
  ): ResponseField[] {
    return responseFields.filter((rf: ResponseField) => {
      if (rf.required && this.processCondition(rf)) {
        const responseValue = response[rf.name];

        if (rf.type === 'text' && !response[rf.name]) {
          return true;
        }
        if (rf.type === 'textarea' && !response[rf.name]) {
          return true;
        }
        if (rf.type === 'boolean' && !responseValue) {
          return true;
        }
        if (
          rf.type === 'photo' &&
          (!responseValue || responseValue.length === 0)
        ) {
          return true;
        }
        if (
          rf.type === 'number' &&
          (responseValue === undefined ||
            responseValue === null ||
            responseValue === '')
        ) {
          return true;
        }
        if (rf.type === 'date' && !responseValue) {
          return true;
        }
        if (rf.type === 'select') {
          if (rf.multiple) {
            if (
              !Array.isArray(responseValue) ||
              responseValue.length === 0 ||
              responseValue.every((item: string) => item === '')
            ) {
              return true;
            }
          } else {
            if (!responseValue || responseValue.length <= 0) {
              return true;
            }
          }
        }
        if (
          rf.type === 'checklist' &&
          (!responseValue || responseValue.length <= 0)
        ) {
          return true;
        }

        if (rf.type === 'batch' && (!responseValue || responseValue === '')) {
          return true;
        }
      }
      if (rf.type === 'list') {
        for (let i = 0; i < response[rf.name].length; ++i) {
          const row = response[rf.name][i];
          if (!row.name) {
            return true;
          }

          if (!row.quantity) {
            return true;
          }

          if (!row.unit) {
            return true;
          }

          if (
            rf.batchPhoto &&
            (!row.photos ||
              (Array.isArray(row.photos) && row.photos.length === 0))
          ) {
            return true; // Validation fails
          }

          if (
            ![
              'technicalSheetIntermarche',
              'technicalSheetIM2',
              'technicalSheetAL',
              'technicalSheetLJ',
              'serunionTechnicalSheet',
              'greenTechnicalSheet',
              'industrialManufacturingIM',
              'industrialManufacturingIM2',
              'serunionProductionNewModel',
              'greenProductionNewModel',
            ].includes(this.type.type) &&
            !row.selectedProductInfo
          ) {
            return true;
          }
        }
      }
      if (rf.type === 'group' && this.processCondition(rf)) {
        for (let group of response[rf.name]) {
          return (
            this.getArrayOfMissingRequiredFields(group, rf.subfields)?.length >
            0
          );
        }
      }

      return false;
    });
  }

  async successfullySubmit() {
    console.log('Starting task submission...');

    try {
      // Set the task details
      this.task.title = this.getTitle();
      this.task.subtitle = this.getSubtitle();

      // Create a new task
      const newTaskResult = await firstValueFrom(
        this.tasksService.newTask(
          this.task,
          this.authenticationService.getAuthInfo()
        )
      );
      console.log('New task created successfully:', newTaskResult);

      // Respond to the new task
      const responseResult = await firstValueFrom(
        this.customTasksService.respondContent(
          this.task,
          this.response,
          this.onTaskClosedSuccessfully
        )
      );
      console.log('Responded to new task successfully:', responseResult);

      // Handle child tasks if present
      if (this.response.childrenTasksIds) {
        console.log('There are children tasks to handle');
        console.log('pendingTasksAndResponses:', this.pendingTasksAndResponses);

        const childTaskResults = await firstValueFrom(
          forkJoin(
            this.pendingTasksAndResponses.map((data) => {
              console.log('Creating new task with data:', data);
              return this.tasksService
                .newTask(data.task, this.authenticationService.getAuthInfo())
                .pipe(
                  mergeMap(() => {
                    console.log('Responding to content for task:', data.task);
                    return this.customTasksService.respondContent(
                      data.task,
                      {
                        ...data.response,
                        'Cód. Barras': this.response['Cód. Barras'],
                      },
                      this.onTaskClosedSuccessfully
                    );
                  })
                );
            })
          )
        );

        console.log('Child tasks handled successfully:', childTaskResults);
      } else {
        console.log(
          'No children tasks to handle or pendingTasksAndResponses is empty'
        );
      }

      const response = responseResult?.responses
        ? responseResult.responses[0]
        : undefined;
      await this.modalController.dismiss({
        task: this.task,
        response: response,
      });
      this.isClicked = false;
      console.log('Modal dismissed successfully');
    } catch (error) {
      console.error('Error caught in main chain:', error);

      try {
        const responseResult = await this.customTasksService
          .respondContent(
            this.task,
            this.response,
            this.onTaskClosedSuccessfully
          )
          .toPromise();

        const response = responseResult?.responses
          ? responseResult.responses[0]
          : undefined;
        await this.modalController.dismiss({
          task: this.task,
          response: response,
        });
        this.isClicked = false;
        console.log('Modal dismissed successfully in error handler');
      } catch (error) {
        console.error('Error in catchError chain:', error);
        this.isClicked = false;

        await this.modalController.dismiss({
          task: this.task,
          response: this.response,
        });
        console.log('Modal dismissed successfully in final error handler');
      }
    }
  }

  autofillSubfields(subfields: ResponseField[]) {
    const response: TaskResponse = {} as TaskResponse;

    subfields.forEach((sf) => {
      if (response[sf.name] || sf.autofill) return;
      if (sf.type === 'boolean') {
        response[sf.name] = false;
      } else if (sf.type === 'date') {
        response[sf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (sf.type === 'list') {
        response[sf.name] = [
          {
            name: '',
            quantity: 1,
            unit: 'kg',
            photo: [],
          },
        ];
      } else if (sf.type === 'checklist') {
        response[sf.name] = [];
      } else if (sf.type === 'photo') {
        response[sf.name] = [];
      } else if (sf.type === 'group') {
        response[sf.name] = [this.autofillSubfields(sf.subfields)];
      } else if (sf.type === 'textarea' && sf.type.count === true) {
        this.characterCounts[sf.name] = sf?.autofill?.length as number;
        response[sf.name] = sf.autofill;
      } else {
        response[sf.name] = '';
      }
    });
    return response;
  }

  addToGroup(responseField: any, group: any[]): void {
    group.push(this.autofillSubfields(responseField.subfields));
  }

  removeFromGroup(group: any[], item: any): void {
    const index = group.indexOf(item);
    if (index > -1) {
      group.splice(index, 1);
    }
  }

  async autofillResponse(
    response: TaskResponse,
    responseField: ResponseField,
    task?: Task
  ) {
    const product =
      (task
        ? task.productId
          ? this.productsService.getProductById(task.productId)
          : null
        : null) || this.extras
        ? this.extras.product
        : null;
    const batch = task ? task.batch : null;

    if (task && task[responseField.name]) {
      response[responseField.name] = task[responseField.name];
    } else if (!response[responseField.name]) {
      if (
        responseField.autofill &&
        this.processCondition(responseField, response)
      ) {
        if (responseField.autofill === 'dlcBalcaoFT') {
          response[responseField.name] =
            product?.technicalSheet?.[
              'Durabilidade de vida (Balcão tradicional)'
            ];
        } else if (responseField.autofill === 'lsPackagingMaterialFT') {
          response[responseField.name] =
            product?.technicalSheet?.['Material de embalagem LS'];
        } else if (responseField.autofill === 'simpleFilmFT') {
          response[responseField.name] =
            product?.technicalSheet?.['Película-simples'];
        } else if (responseField.autofill === 'dlcSimpleFilmFT') {
          response[responseField.name] =
            product?.technicalSheet?.[
              'DLC para pré-embalamento Película-simples'
            ];
        } else if (responseField.autofill === 'VacuumFT') {
          response[responseField.name] = product?.technicalSheet?.['Vácuo'];
        } else if (responseField.autofill === 'dlcVacuumFT') {
          response[responseField.name] =
            product?.technicalSheet?.['DLC para pré-embalamento Vácuo'];
        } else if (responseField.autofill === 'GasATMFT') {
          response[responseField.name] = product?.technicalSheet?.['Gás ATM'];
        } else if (responseField.autofill === 'dlcGasATMFT') {
          response[responseField.name] =
            product?.technicalSheet?.['DLC para pré-embalamento Gás ATM'];
        } else if (responseField.autofill === 'mixtureGasATMFT') {
          response[responseField.name] =
            product?.technicalSheet?.['Mistura de gás'];
        } else if (responseField.autofill === 'precedingTaskLastCargoOfDayNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Última carga do dia'];
        } else if (responseField.autofill === 'dlcPrepackageFT') {
          response[responseField.name] = product?.technicalSheet?.[''];
        } else if (responseField.autofill === 'foodType') {
          response[responseField.name] =
            product?.technicalSheet?.['Tipo de alimento'] ?? '';
        } else if (responseField.autofill === 'orderType') {
          response[responseField.name] =
            product?.technicalSheet?.['Ordem'] ?? '';
        } else if (responseField.autofill === 'portionSize') {
          response[responseField.name] =
            product?.technicalSheet?.['Gramas Aproximadas por porcao'] ?? '';
        } else if (responseField.autofill === 'consumerType') {
          response[responseField.name] =
            product?.technicalSheet?.['Tipo de comensal'] ?? '';
        } else if (responseField.autofill === 'preparationTime') {
          response[responseField.name] =
            product?.technicalSheet?.['Tempo aproximado de preparacao'] ?? '';
        } else if (responseField.autofill === 'cookingTechnique') {
          response[responseField.name] =
            product?.technicalSheet?.['Tecnica culinaria principal'] ?? '';
        } else if (responseField.autofill === 'preparation') {
          response[responseField.name] =
            product?.technicalSheet?.['Instruções de preparação'] ?? '';
        } else if (responseField.autofill === 'precedingTaskVendor') {
          response[responseField.name] = task?.precedingTaskData?.['vendor'];
        } else if (responseField.autofill === 'precedingTaskProduct') {
          response[responseField.name] = task?.precedingTaskData?.['product'];
        } else if (responseField.autofill === 'precedingTaskParcel') {
          response[responseField.name] = task?.precedingTaskData?.['parcel'];
        } else if (responseField.autofill === 'precedingTaskBuyer') {
          response[responseField.name] = task?.precedingTaskData?.['buyer'];
        } else if (responseField.autofill === 'precedingTaskVariety') {
          response[responseField.name] = task?.precedingTaskData?.['variety'];
        } else if (responseField.autofill === 'precedingTaskHarvester') {
          response[responseField.name] = task?.precedingTaskData?.['harvester'];
        } else if (responseField.autofill === 'precedingTaskOperator') {
          response[responseField.name] = task?.precedingTaskData?.['operator'];
        } else if (responseField.autofill === 'precedingTaskTrailerPhoto') {
          response[responseField.name] =
            task?.precedingTaskData?.['photoTrailer'];
        } else if (responseField.autofill === 'precedingTaskLicensePlate') {
          response[responseField.name] =
            task?.precedingTaskData?.['licensePlate'];
        } else if (responseField.autofill === 'precedingTaskProductType') {
          response[responseField.name] =
            task?.precedingTaskData?.['productType'];
        } else if (responseField.autofill === 'precedingTaskMethod') {
          response[responseField.name] = task?.precedingTaskData?.['method'];
        } else if (responseField.autofill === 'precedingTaskWeight') {
          response[responseField.name] = task?.precedingTaskData?.['weight'];
        } else if (responseField.autofill === 'precedingTaskObservations') {
          response[responseField.name] =
            task?.precedingTaskData?.['observations'];
        } else if (responseField.autofill === 'precedingTaskData') {
          response[responseField.name] = moment(
            task?.precedingTaskData?.['data']
          ).format('YYYY-MM-DDTHH:mm:ss');
        } else if (responseField.autofill === 'precedingTaskSetor') {
          response[responseField.name] = task?.precedingTaskData?.['setor'];
        } else if (responseField.autofill === 'precedingTaskPraga') {
          response[responseField.name] = task?.precedingTaskData?.['praga'];
        } else if (responseField.autofill === 'precedingTaskFotografia') {
          response[responseField.name] =
            task?.precedingTaskData?.['fotografia'] || [];
        } else if (responseField.autofill === 'precedingTaskProvidencias') {
          response[responseField.name] =
            task?.precedingTaskData?.['providencias'];
        } else if (responseField.autofill === 'precedingTaskEmail') {
          response[responseField.name] = task?.precedingTaskData?.['email'];
        } else if (responseField.autofill === 'precedingTaskEnviar') {
          response[responseField.name] = task?.precedingTaskData?.['enviar'];
        } else if (responseField.autofill === 'precedingTaskObservacoes') {
          response[responseField.name] =
            task?.precedingTaskData?.['observacoes'];
        } else if (responseField.autofill === 'precedingTaskTitulo') {
          response[responseField.name] = task?.precedingTaskData?.['titulo'];
        } else if (responseField.autofill === 'precedingTaskPedido') {
          response[responseField.name] = task?.precedingTaskData?.['pedido'];
        } else if (responseField.autofill === 'precedingTaskNomeEquipamento') {
          response[responseField.name] =
            task?.precedingTaskData?.['nomeEquipamento'];
        } else if (responseField.autofill === 'precedingTaskTipoAvaria') {
          response[responseField.name] =
            task?.precedingTaskData?.['tipoAvaria'];
        } else if (responseField.autofill === 'precedingTaskDescricaoAvaria') {
          response[responseField.name] =
            task?.precedingTaskData?.['descricaoAvaria'];
        } else if (responseField.autofill === 'precedingTaskDentroGarantia') {
          response[responseField.name] =
            task?.precedingTaskData?.['dentroGarantia'];
        } else if (responseField.autofill === 'precedingTaskNivelUrgencia') {
          response[responseField.name] =
            task?.precedingTaskData?.['nivelUrgencia'];
        } else if (responseField.autofill === 'precedingTaskEmployee') {
          response[responseField.name] = task?.precedingTaskData?.['employee'];
        } else if (responseField.autofill === 'precedingTaskFornecedor') {
          response[responseField.name] =
            task?.precedingTaskData?.['fornecedor'];
        } else if (responseField.autofill === 'precedingTaskComentarios') {
          response[responseField.name] =
            task?.precedingTaskData?.['comentarios'];
        } else if (responseField.autofill === 'precedingTaskNomeLoja') {
          response[responseField.name] = task?.precedingTaskData?.['storeName'];
        } else if (responseField.autofill === 'precedingTaskPacotePro') {
          response[responseField.name] = task?.precedingTaskData?.['pacotePro'];
        } else if (responseField.autofill === 'precedingTaskPacotePremium') {
          response[responseField.name] =
            task?.precedingTaskData?.['pacotePremium'];
        } else if (
          responseField.autofill === 'precedingTaskEnviarEmailTechguild'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['sendEmailTechguild'];
        } else if (responseField.autofill === 'precedingTaskNomeDocumento') {
          response[responseField.name] =
            task?.precedingTaskData?.['documentName'];
        } else if (responseField.autofill === 'precedingTaskNumeroDocumento') {
          response[responseField.name] =
            task?.precedingTaskData?.['documentNumber'];
        } else if (responseField.autofill === 'precedingTaskDataValidade') {
          response[responseField.name] = task?.precedingTaskData?.['dataEnd'];
        } else if (responseField.autofill === 'precedingTaskComent') {
          response[responseField.name] = task?.precedingTaskData?.['coment'];
        } else if (responseField.autofill === 'precedingTaskStartDate') {
          response[responseField.name] = task?.precedingTaskData?.['startDate'];
        } else if (responseField.autofill === 'precedingTaskStart') {
          response[responseField.name] = task?.precedingTaskData?.['start'];
        } else if (responseField.autofill === 'precedingTaskResponsible') {
          response[responseField.name] =
            task?.precedingTaskData?.['responsible'];
        } else if (
          responseField.autofill === 'precedingTaskInternalIdentification'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['internalIdentification'];
        } else if (
          responseField.autofill === 'precedingTaskMeatCutDesignation'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['meatCutDesignation'];
        } else if (responseField.autofill === 'precedingTaskAnimalBreed') {
          response[responseField.name] =
            task?.precedingTaskData?.['animalBreed'];
        } else if (
          responseField.autofill === 'precedingTaskBeefTraceabilityNumber'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['beefTraceabilityNumber'];
        } else if (responseField.autofill === 'precedingTaskSupplier') {
          response[responseField.name] = task?.precedingTaskData?.['supplier'];
        } else if (
          responseField.autofill === 'precedingTaskSupplierBatchNumber'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['supplierBatchNumber'];
        } else if (responseField.autofill === 'precedingTaskSlaughterDate') {
          response[responseField.name] =
            task?.precedingTaskData?.['slaughterData'];
        } else if (
          responseField.autofill === 'precedingTaskReceiptDateAtSalePoint'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['receiptDateAtSalePoint'];
        } else if (
          responseField.autofill === 'precedingTaskShippingGuideInvoiceNumber'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['shippingGuideInvoiceNumber'];
        } else if (
          responseField.autofill === 'precedingTaskMeatTemperatureUponReceipt'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['meatTemperatureUponReceipt'];
        } else if (
          responseField.autofill === 'precedingTaskFishCutDesignation'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['fishCutDesignation'];
        } else if (responseField.autofill === 'precedingTaskProductSmoked') {
          response[responseField.name] =
            task?.precedingTaskData?.['productSmoked'];
        } else if (responseField.autofill === 'precedingTaskProductionMethod') {
          response[responseField.name] =
            task?.precedingTaskData?.['productionMethod'];
        } else if (responseField.autofill === 'precedingTaskOption') {
          response[responseField.name] = task?.precedingTaskData?.['option'];
        } else if (
          responseField.autofill === 'precedingTaskGivePasswordToConsultant'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['givePasswordToConsultant'];
        } else if (responseField.autofill === 'precedingTaskEmail') {
          response[responseField.name] = task?.precedingTaskData?.['email'];
        } else if (responseField.autofill === 'precedingTaskPassword') {
          response[responseField.name] = task?.precedingTaskData?.['password'];
        } else if (
          responseField.autofill === 'precedingTaskCreateExternalProfile'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['createExternalProfile'];
        } else if (responseField.autofill === 'precedingTaskMachine') {
          response[responseField.name] = task?.precedingTaskData?.['machine'];
        } else if (responseField.autofill === 'precedingTaskSupporteOther') {
          response[responseField.name] =
            task?.precedingTaskData?.['supporteOther'];
        } else if (responseField.autofill === 'precedingTaskDate') {
          response[responseField.name] = task?.precedingTaskData?.['date'];
        } else if (responseField.autofill === 'precedingTaskCompanie') {
          response[responseField.name] = task?.precedingTaskData?.['companie'];
        } else if (responseField.autofill === 'precedingTaskStore') {
          response[responseField.name] = task?.precedingTaskData?.['store'];
        } else if (responseField.autofill === 'precedingTaskStoreEmail') {
          response[responseField.name] =
            task?.precedingTaskData?.['storeEmail'];
        } else if (responseField.autofill === 'precedingTaskTitleOrder') {
          response[responseField.name] =
            task?.precedingTaskData?.['titleOrder'];
        } else if (responseField.autofill === 'precedingTaskDescription') {
          response[responseField.name] =
            task?.precedingTaskData?.['description'];
        } else if (responseField.autofill === 'precedingTaskSendEmail') {
          response[responseField.name] = task?.precedingTaskData?.['sendEmail'];
        } else if (responseField.autofill === 'precedingTaskEmailConsultant') {
          response[responseField.name] =
            task?.precedingTaskData?.['emailConsultant'];
        } else if (
          responseField.autofill === 'precedingTaskEmailCreatedPlatform'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['emailCreatedPlatform'];
        } else if (responseField.autofill === 'precedingTaskCorrection') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['correctionType']
          ) {
            response[responseField.name] =
              task.precedingTaskData['correctionType'];
          }
        } else if (
          responseField.autofill === 'precedingTaskActionDescription'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['actionDescription']
          ) {
            response[responseField.name] =
              task.precedingTaskData['actionDescription'];
          }
        } else if (
          responseField.autofill === 'precedingTaskImplementationResponsible'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['implementationResponsible']
          ) {
            response[responseField.name] =
              task.precedingTaskData['implementationResponsible'];
          }
        } else if (responseField.autofill === 'precedingTaskAccessOk') {
          response[responseField.name] = task?.precedingTaskData?.['accessOk'];
        } else if (
          responseField.autofill === 'precedingTaskNewEmailCreatedPlatform'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['newEmailCreatedPlatform'];
        } else if (responseField.autofill === 'precedingTaskRegisterNumber') {
          response[responseField.name] =
            task?.precedingTaskData?.['registerNumber'];
        } else if (
          responseField.autofill === 'precedingTaskNewRegisterNumber'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['newRegisterNumber'];
        } else if (responseField.autofill === 'precedingTaskSumaryProject') {
          response[responseField.name] =
            task?.precedingTaskData?.['sumaryProject'];
        } else if (responseField.autofill === 'precedingTaskNewSumaryProject') {
          response[responseField.name] =
            task?.precedingTaskData?.['newSumaryProject'];
        } else if (
          responseField.autofill === 'precedingTaskNewVersionSumaryProject'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['newVersionSumaryProject'];
        } else if (responseField.autofill === 'precedingTaskAclaraciones') {
          response[responseField.name] =
            task?.precedingTaskData?.['aclaraciones'];
        } else if (responseField.autofill === 'precedingTaskValidation') {
          response[responseField.name] =
            task?.precedingTaskData?.['validation'];
        } else if (responseField.autofill === 'precedingTaskTextAprover') {
          response[responseField.name] =
            task?.precedingTaskData?.['textAprover'];
        } else if (responseField.autofill === 'precedingTaskSelectFolder') {
          response[responseField.name] =
            task?.precedingTaskData?.['selectFolder'];
        } else if (responseField.autofill === 'precedingTaskSelectTitle') {
          console.log("task?.['title']", task?.['title']);
          response[responseField.name] = task?.['title']; // Acessa o título da tarefa anterior
        } else if (
          responseField.autofill === 'precedingTaskInitialWeightSmoking'
        ) {
          response[responseField.name] =
            task?.precedingTaskData?.['initialWeightSmoking'];
        } else if (responseField.autofill === 'precedingTaskStateDebt') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['stateDebt']
          ) {
            response[responseField.name] = task.precedingTaskData['stateDebt'];
          }
        } else if (responseField.autofill === 'precedingTaskDebtsToState') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['debtsToState']
          ) {
            response[responseField.name] =
              task.precedingTaskData['debtsToState'];
          }
        } else if (responseField.autofill === 'precedingTaskHasStateDebts') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['hasStateDebts']
          ) {
            response[responseField.name] =
              task.precedingTaskData['hasStateDebts'];
          }
        } else if (responseField.autofill === 'precedingTaskIsEligibility') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['isEligibility']
          ) {
            response[responseField.name] =
              task.precedingTaskData['isEligibility'];
          }
        } else if (
          responseField.autofill === 'precedingTaskEnquadramentoProjeto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['enquadramentoProjeto']
          ) {
            response[responseField.name] =
              task.precedingTaskData['enquadramentoProjeto'];
          }
        } else if (responseField.autofill === 'precedingTaskSumario') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['sumario']
          ) {
            response[responseField.name] = task.precedingTaskData['sumario'];
          }
        } else if (
          responseField.autofill === 'precedingTaskDescricaoAcoesInvestimentos'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['descricaoAcoesInvestimentos']
          ) {
            response[responseField.name] =
              task.precedingTaskData['descricaoAcoesInvestimentos'];
          }
        } else if (
          responseField.autofill === 'precedingTaskContributoIgualdadeGenero'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['contributoIgualdadeGenero']
          ) {
            response[responseField.name] =
              task.precedingTaskData['contributoIgualdadeGenero'];
          }
        } else if (
          responseField.autofill === 'precedingTaskContributoTransicao'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['contributoTransicao']
          ) {
            response[responseField.name] =
              task.precedingTaskData['contributoTransicao'];
          }
        } else if (responseField.autofill === 'precedingTaskJustificacaoDnsh') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['justificacaoDnsh']
          ) {
            response[responseField.name] =
              task.precedingTaskData['justificacaoDnsh'];
          }
        } else if (
          responseField.autofill === 'precedingTaskCriteriosSelecaoRelevancia'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['criteriosSelecaoRelevancia']
          ) {
            response[responseField.name] =
              task.precedingTaskData['criteriosSelecaoRelevancia'];
          }
        } else if (
          responseField.autofill ===
          'precedingTaskCriteriosSelecaoImpactoDigital'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['criteriosSelecaoImpactoDigital']
          ) {
            response[responseField.name] =
              task.precedingTaskData['criteriosSelecaoImpactoDigital'];
          }
        } else if (
          responseField.autofill === 'precedingTaskTransicaoCapacitacaoDigital'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['transicaoCapacitacaoDigital']
          ) {
            response[responseField.name] =
              task.precedingTaskData['transicaoCapacitacaoDigital'];
          }
        } else if (
          responseField.autofill ===
          'precedingTaskCriteriosSelecaoCoerenciaTecnica'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['criteriosSelecaoCoerenciaTecnica']
          ) {
            response[responseField.name] =
              task.precedingTaskData['criteriosSelecaoCoerenciaTecnica'];
          }
        } else if (
          responseField.autofill === 'precedingTaskExcedeuValor50mil'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['excedeuValor50mil']
          ) {
            response[responseField.name] =
              task.precedingTaskData['excedeuValor50mil'];
          }
        } else if (
          responseField.autofill === 'precedingTaskValorTotalCandidatura'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['valorTotalCandidatura']
          ) {
            response[responseField.name] =
              task.precedingTaskData['valorTotalCandidatura'];
          }
        } else if (
          responseField.autofill === 'precedingTaskValorExcedidoNaoFinanciado'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['valorExcedidoNaoFinanciado']
          ) {
            response[responseField.name] =
              task.precedingTaskData['valorExcedidoNaoFinanciado'];
          }
        } else if (
          responseField.autofill === 'precedingTaskCaeNaoListadoAnexoI'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['caeNaoListadoAnexoI']
          ) {
            response[responseField.name] =
              task.precedingTaskData['caeNaoListadoAnexoI'];
          }
        } else if (
          responseField.autofill ===
          'precedingTaskFinanciamentoDuplicadoImpacto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['financiamentoDuplicadoImpacto']
          ) {
            response[responseField.name] =
              task.precedingTaskData['financiamentoDuplicadoImpacto'];
          }
        } else if (responseField.autofill === 'precedingTaskConfirmData') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['confirmData']
          ) {
            response[responseField.name] =
              task.precedingTaskData['confirmData'];
          }
        } else if (responseField.autofill === 'precedingTaskNomeOrganizacao') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['nomeOrganizacao']
          ) {
            response[responseField.name] =
              task.precedingTaskData['nomeOrganizacao'];
          }
        } else if (responseField.autofill === 'precedingTaskAcessoConsultor') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['acessoConsultor']
          ) {
            response[responseField.name] =
              task.precedingTaskData['acessoConsultor'];
          }
        } else if (responseField.autofill === 'precedingTaskIsAssociatedo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['isAssociated']
          ) {
            response[responseField.name] =
              task.precedingTaskData['isAssociated'];
          }
        } else if (
          responseField.autofill === 'precedingTaskNumeroOrganizacao'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['numeroOrganizacao']
          ) {
            response[responseField.name] =
              task.precedingTaskData['numeroOrganizacao'];
          }
        } else if (responseField.autofill === 'precedingTaskNome') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['nome']
          ) {
            response[responseField.name] = task.precedingTaskData['nome'];
          }
        } else if (responseField.autofill === 'precedingTaskTelemovel') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['telemovel']
          ) {
            response[responseField.name] = task.precedingTaskData['telemovel'];
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['email']
          ) {
            response[responseField.name] = task.precedingTaskData['email'];
          }
        } else if (responseField.autofill === 'precedingTaskCargo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['cargo']
          ) {
            response[responseField.name] = task.precedingTaskData['cargo'];
          }
        } else if (
          responseField.autofill === 'precedingTaskBreveResumoEntidade'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['breveResumoEntidade']
          ) {
            response[responseField.name] =
              task.precedingTaskData['breveResumoEntidade'];
          }
        } else if (
          responseField.autofill === 'precedingTaskSelecaoProdutosCatalogo'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['selecaoProdutosCatalogo']
          ) {
            response[responseField.name] =
              task.precedingTaskData['selecaoProdutosCatalogo'];
          }
        } else if (
          responseField.autofill === 'precedingTaskPequenaExplicacaoProjeto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['pequenaExplicacaoProjeto']
          ) {
            response[responseField.name] =
              task.precedingTaskData['pequenaExplicacaoProjeto'];
          }
        } else if (responseField.autofill === 'product') {
          response[responseField.name] = product
            ? product.name
            : this.extras.product?.name;
        } else if (responseField.autofill === 'brand') {
          response[responseField.name] =
            product?.brand ?? this.extras.product?.brand ?? '';
        } else if (responseField.autofill === 'supplier') {
          let supplierName =
            this.extras.supplier?.name ??
            product?.supplierName ??
            product?.supplier?.name;

          if (Array.isArray(supplierName)) {
            if (supplierName.length === 1) {
              response[responseField.name] = supplierName[0];
            } else if (supplierName.length > 1) {
              // Se quiser que o usuário selecione, defina o campo como 'select'
              responseField.type = 'select';
              responseField.selectOptions = supplierName;
              response[responseField.name] = '';
            } else {
              response[responseField.name] = '';
            }
          } else if (typeof supplierName === 'string') {
            response[responseField.name] = supplierName;
          } else {
            response[responseField.name] = '';
          }
        } else if (responseField.autofill === 'origin') {
          response[responseField.name] = this.extras.product?.origin;
        } else if (responseField.autofill === 'productFamily') {
          response[responseField.name] = this.extras.productFamily;
        } else if (responseField.autofill === 'barcode') {
          response[responseField.name] =
            product?.barcode?.itm8 ??
            product?.barcode?.value ??
            this.extras.barcode;
          if (
            response['Fornecedor'] === 'Fabricado' &&
            !response[responseField.name]
          ) {
            response[responseField.name] = '00000000';
          }
        } else if (responseField.autofill === 'expirationDate') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'nextExpirationDate') {
          response[responseField.name] =
            this.extras.product?.nextExpirationDates?.[0] ?? null;
        } else if (responseField.autofill === 'nextNextExpirationDate') {
          response[responseField.name] =
            this.extras.product?.nextExpirationDates?.[1] ?? null;
        } else if (responseField.autofill === 'problemName') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'problemDate') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'problemDescription') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'TODAY') {
          response[responseField.name] = moment().format('YYYY-MM-DD');
        } else if (responseField.autofill === 'NONE') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'store.waybillCounter') {
          response[responseField.name] = `${this.getHerdadeCountersString()}`;
        } else if (responseField.autofill === 'waybillCounterNF') {
          const deviceInfo = await Device.getId();
          const deviceUUID = deviceInfo.identifier;

          response[responseField.name] = `${getHerdadeOrLagarCountersString(
            this.fieldsService.getClosestHerdadeOrLagarField(this.form.id),
            deviceUUID
          )}`;
        } else if (responseField.autofill === 'destinationNF') {
          response[responseField.name] = `${
            this.fieldsService
              .getClosestHerdadeOrLagarField(this.form.id)
              .buyers.map((b: any) => `${b.fullName} (${b.companyFullName})`)[0]
          }`;
        } else if (responseField.autofill === 'societyNF') {
          response[responseField.name] = `${
            this.fieldsService.getClosestHerdadeOrLagarField(this.form.id)
              .society
          }`;
        } else if (responseField.autofill === 'herdadeNF') {
          response[responseField.name] = `${
            this.fieldsService.getClosestHerdadeOrLagarField(this.form.id)
              .fullName
          }`;
        } else if (responseField.autofill === 'varietyNF') {
          // handle varietyNF case if necessary
        } else if (responseField.autofill === 'waybillEmitterNF') {
          response[
            responseField.name
          ] = `${this.employeeService.getEmployeeName()}`;
        } else if (responseField.autofill === 'getEmployeesName') {
          response[responseField.name] = this.employeeService.getEmployeeName();
        } else {
          response[responseField.name] = responseField.autofill;
        }
      }

      if (product && product.technicalSheet) {
        if (responseField.name === 'O produto vai ser conservado no quente?') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Para embalar?') {
          // handle 'Para embalar?' case if necessary
        } else if (
          responseField.name === 'Durabilidade de vida (Balcão tradicional)'
        ) {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'DLC para pré-embalamento') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Tempo de preparação') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Material de embalagem') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.type === 'list') {
          response[responseField.name] = deepCopy(
            product.technicalSheet[responseField.name]
          );
          if (responseField.name === 'Lista de ingredientes') {
            for (let i = 0; i < response[responseField.name].length; ++i) {
              if (response[responseField.name][i].name === 'Água') {
                response[responseField.name][i].selectedProductInfo =
                  'Automático';
              }
            }
          }
        } else if (responseField.type === 'checklist') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        }
      }

      if (batch || (this.extras.batch && responseField.type === 'batch')) {
        response[responseField.name] = batch || this.extras.batch;
      }

      if (this.extras.product && responseField.type === 'product') {
        response[responseField.name] = this.extras.product;
      }

      if (responseField.autofill === 'NOW') {
        if (responseField.type === 'date') {
          response[responseField.name] = moment()
            .seconds(0)
            .format(
              responseField.format === 'dd/mm/yyyy'
                ? 'DD-MM-YYYY'
                : 'YYYY-MM-DD'
            );
        } else if (responseField.type === 'datetime') {
          response[responseField.name] = moment()
            .seconds(0)
            .format('YYYY-MM-DDTHH:mm:ss');
        } else {
          response[responseField.name] = moment().format('HH:mm');
        }
      }

      if (responseField.autofill === 'NOFILL') {
        response[responseField.name] = '';
      }

      if (
        responseField.autofill &&
        responseField.isHiding &&
        !response[responseField.name] &&
        responseField.autofill !== 'NONE'
      ) {
        this.toggleOffHideGroupFields(
          responseField,
          this.type.metadata.responseFields
        );
      }
    }
  }

  async getHerdadeCountersString(): Promise<string> {
    const deviceInfo = await Device.getId();
    const deviceUUID = deviceInfo.identifier;

    if (this.storesService.store.waybillCounters) {
      let idx = this.storesService.store.waybillCounters.findIndex(
        (e: any) => e.dId === deviceUUID
      );
      if (idx === -1) {
        idx = this.storesService.store.waybillCounters.length;
        return (
          `${idx + 1}` +
          parseInt(`1`).toLocaleString('en-US', {
            minimumIntegerDigits: 4,
            useGrouping: false,
          })
        );
      }
      return (
        `${idx + 1}` +
        parseInt(
          `${this.storesService.store.waybillCounters[idx].counter + 1}`
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 4,
          useGrouping: false,
        })
      );
    } else {
      return '10001';
    }
  }

  processCondition(field: any, response?: any) {
    response = response || this.response;

    // Check if the condition is valid
    if (!field.condition) return true;

    const conditions = field.condition.split(', ');

    // Check if conditions array is valid
    if (conditions.length === 0) return true;

    return (
      conditions.filter((c: string) => {
        // Check for OR condition
        if (c.includes('||')) {
          const orConditions = c.split('||');
          return orConditions.some((orCond: string) => {
            const [left, right] = orCond.split('===').map((s) => s.trim());
            return response[left] === right;
          });
        }
        // Original conditions checking...
        else if (c.includes('===')) {
          const sides = c.split('===').map((s) => s.trim());
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response[leftSide] === rightSide;
        } else if (c.includes('!==')) {
          const sides = c.split('!==').map((s) => s.trim());
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response[leftSide] !== rightSide;
        } else if (c === 'expirationDate') {
          if (
            response['Data de validade'] &&
            moment(response['Data de validade']).isBefore(
              moment().add(4, 'days')
            )
          ) {
            return true;
          }
          return false;
        }
        if (c[0] === '!') {
          const condition = c.substring(1, c.length);
          return !response[condition];
        }
        return !!response[c];
      }).length === conditions.length
    );
  }

  isConditionRelated(condition: string, field: string): boolean {
    // Check if `field` is included in the `condition` string
    return condition.includes(field);
  }

  toggleHideGroupFields(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ): void {
    if (!responseField.isGrouper) return;
    this.toggleHideGroupsField2(responseField, index, responseFields);
  }

  toggleHideGroupsField2(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ): void {
    for (let i = index; i < responseFields.length; ++i) {
      if (responseFields[i] !== responseField && responseFields[i].isGrouper) {
        return;
      }
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleHideGroupsField3(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ): void {
    for (let i = index + 1; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) return;
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleOffHideGroupFields(
    responseField: ResponseField,
    responseFields: ResponseField[]
  ): void {
    let startingIndex: number | undefined;
    let isValid = false;

    for (let i = 0; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) {
        startingIndex = i;
      }
      if (responseFields[i] === responseField) {
        isValid = true;
        break;
      }
    }
    if (!isValid || startingIndex === undefined) return;
    this.toggleHideGroupsField3(responseField, startingIndex, responseFields);
  }

  addToList(responseField: ResponseField): void {
    if (!this.response[responseField.name]) {
      this.response[responseField.name] = [];
    }
    this.response[responseField.name].push({
      name: '',
      quantity: 1,
      unit: 'kg',
    });
  }

  async removeFromList(
    responseField: ResponseField,
    index: number
  ): Promise<void> {
    if (this.response[responseField.name].length <= 1) {
      const toast = await this.toastController.create({
        message: 'A lista tem que conter pelo menos 1 ingrediente.',
        duration: 2000,
        position: 'top',
        buttons: [
          {
            text: this.translator.instant('Fechar'),
            role: 'cancel',
          },
        ],
      });
      await toast.present(); // Ensure to await the present method
      return;
    }
    this.response[responseField.name] = this.arrayRemoveIdx(
      this.response[responseField.name],
      index
    );
  }

  addOrRemoveArray(
    event: { checked: boolean },
    value: any,
    responseArray: any[]
  ): void {
    if (event.checked) {
      this.arrayAddDistinct(responseArray, value);
    } else {
      this.arrayRemove(responseArray, value);
    }
  }

  arrayRemoveIdx(arr: any[], index: number): any[] {
    return arr.filter((_, i) => i !== index);
  }

  arrayAddDistinct(arr: any[], value: any): void {
    if (!arr.includes(value)) {
      arr.push(value);
    }
  }

  arrayRemove(arr: any[], value: any): any[] {
    return arr.filter((item) => item !== value);
  }

  onChangeNumberInput(value: number, responseField: ResponseField): void {
    const product = this.extras.product;
    if (responseField.name === 'Quantidade fabricada') {
      if (this.response['Lista de ingredientes']) {
        this.response['Lista de ingredientes'].forEach((i: any) => {
          if (
            product.technicalSheet['Lista de ingredientes'] &&
            product.technicalSheet['Lista de ingredientes'].find(
              (p: any) => p.name === i.name
            )
          ) {
            i.quantity =
              product.technicalSheet['Lista de ingredientes'].find(
                (p: any) => p.name === i.name
              ).quantity * value;
          } else {
            i.quantity = i.quantity * value;
          }
        });
      }
    }
  }

  onChangeSelectInput(
    value: any,
    responseField: ResponseField,
    response: TaskResponse
  ): void {
    // Implement your logic here
  }

  getSelectOptions(responseField: ResponseField, response?: TaskResponse) {
    if (!response) {
      return responseField.selectOptions || [];
    }

    if (responseField.selectOptionsGetter === 'getFormTasks.info') {
      const form = this.fieldsService.getFormByName(response['Área']);
      const tasks = this.tasksService.getTasksFromForm(form.id);
      return tasks.map(
        (t) =>
          `${t.title}${t.subtitle ? ` - ${t.subtitle}` : ''} - ${moment(
            t.startDate
          ).format('YYYY-MM-DD HH:mm')} - ${t.id}`
      );
    } else if (
      responseField.selectOptionsGetter === 'getRastreabilidadeProductTasks'
    ) {
      const tasks = this.tasksService.getTasksByType(
        'unpackgedProductsIntermarche2'
      );
      return tasks.map(
        (t) =>
          `${t.subtitle} - ${moment(t.startDate).format(
            'YYYY-MM-DD HH:mm'
          )} - ${t.id}`
      );
    } else if (
      responseField.selectOptionsGetter === 'getFabricatedProductTasks'
    ) {
      const tasks = this.tasksService.getTasksByType('productionIM2');
      return tasks.map(
        (t) =>
          `${t.subtitle} - ${moment(t.startDate).format(
            'YYYY-MM-DD HH:mm'
          )} - ${t.id}`
      );
    } else if (responseField.selectOptionsGetter === 'store.machines.oil') {
      return this.sensorService.getMachinesByType('oil').map((m) => m.machine);
    } else if (responseField.selectOptionsGetter === 'store.users') {
      return this.authenticationService.getStoreUsersUsername();
    } else if (responseField.selectOptionsGetter === 'batchPreparationDates') {
      let batch;
      if (this.task && this.task.batch) batch = this.task.batch;
      else if (this.extras && this.extras.batch) batch = this.extras.batch;
      return batch.preparationDates || [];
    } else if (responseField.selectOptionsGetter === 'destinationNF') {
      return this.fieldsService
        .getClosestHerdadeField(this.task.formId)
        .buyers.map((b: any) => `${b.fullName} (${b.companyFullName})`);
    } else if (
      responseField.selectOptionsGetter === 'store.modules.gasMixtures'
    ) {
      return this.storesService.store.modules.gasMixtures;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.packageMaterial'
    ) {
      return this.storesService.store.modules.packageMaterial;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.expiration.productDestinations'
    ) {
      return this.storesService.store.modules.expiration.productDestinations;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.desinfectionProducts'
    ) {
      return this.storesService.store.modules.desinfectionProducts;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.pestSector'
    ) {
      return this.storesService.store.modules.pestSector;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.goodsReception.directSupplierList'
    ) {
      return (
        this.storesService.store.modules.goodsReception.directSupplierList || []
      );
    } else if (responseField.selectOptionsGetter === 'product.supplier') {
      const product = this.task?.productId
        ? this.productsService.getProductById(this.task.productId)
        : null;

      let supplierNames = product?.supplierName;

      if (!supplierNames) {
        return [];
      } else if (Array.isArray(supplierNames)) {
        return supplierNames;
      } else if (typeof supplierNames === 'string') {
        return [supplierNames];
      } else {
        return [];
      }
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.goodsReception.nonConformitiesListt'
    ) {
      return (
        this.storesService.store.modules.goodsReception.nonConformitiesList ||
        []
      );
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.anomaliesReport.emailsTo'
    ) {
      return this.storesService.store.modules.anomaliesReport.emailsTo;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.supportRequests.emailsTo'
    ) {
      return this.storesService.store.modules.supportRequests.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.haccp'
    ) {
      return this.storesService.store.modules.emailsReport.haccp.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.help'
    ) {
      return this.storesService.store.modules.emailsReport.help.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.docs'
    ) {
      return this.storesService.store.modules.emailsReport.docs.emailsTo;
    } else if (responseField.selectOptionsGetter === 'getMachines.name') {
      const machines = this.sensorService.getMachinesName();
      return machines;
    } else if (
      responseField.selectOptionsGetter === 'getMachinesByCategory.name'
    ) {
      const categoryMachine = response['Categoria do Equipamento'].trim();
      const filteredMachines =
        this.sensorService.getMachinesByCategory(categoryMachine);
      return filteredMachines;
    } else if (
      responseField.selectOptionsGetter === 'getMachinesSupplier.name'
    ) {
      const machine =
        response['Selecione o Equipamento para Incidência'].trim();
      const filteredMachines = this.sensorService.getMachinesSupplier(machine);
      return filteredMachines;
    } else if (
      responseField.selectOptionsGetter === 'getMachinesSupplier.email'
    ) {
      const supplierName = response['Fornecedor Responsável'].trim();
      const filteredMachines =
        this.sensorService.getMachinesSupplierEmail(supplierName);
      return filteredMachines;
    } else if (responseField.selectOptionsGetter === 'getOilMachines.name') {
      const oilMachines = this.sensorService.getOilMachinesWithNameRegex();
      return oilMachines;
    } else if (responseField.selectOptionsGetter === 'getEmployees.name') {
      const allEmployeeNames = this.employeeService.getEmployeesName();
      return allEmployeeNames;
    } else if (responseField.selectOptionsGetter === 'getUsers.name') {
      const users = this.authenticationService.getStoreUsersUsername();
      return users;
    } else if (responseField.selectOptionsGetter === 'getFormsQuiz.name') {
      const field = this.fieldsService.getFieldNameQuiz('form');
      return field;
    } else if (
      responseField.selectOptionsGetter === 'getTasksResponseQuiz.name'
    ) {
      const formQuiz = response['Selecione a área'].trim();
      const task = this.tasksService.getTasksToReopen(formQuiz);
      return task;
    } else if (responseField.selectOptionsGetter === 'getField.name') {
      const field = this.fieldsService.getFieldName('form');
      return field;
    } else if (responseField.selectOptionsGetter === 'getFolder.name') {
      const folder = this.storesService.getFolderTitles();
      return folder;
    } else if (responseField.selectOptionsGetter === 'getReportFolder.name') {
      const folder = this.storesService.getReportFolderTitles();
      return folder;
    } else if (responseField.selectOptionsGetter === 'getReportFiles.name') {
      const folderTitle = response['Pasta do relatório'].trim();
      const files = this.storesService.getReportFilesTitles(folderTitle);
      return files;
    } else if (responseField.selectOptionsGetter === 'getForms.name') {
      const field = this.fieldsService.getFieldName('form');
      return field;
    } else if (responseField.selectOptionsGetter === 'getFields.name') {
      const fields = this.fieldsService.getFieldNames();
      return fields;
    } else if (
      responseField.selectOptionsGetter === 'getTechnicalSheets.name'
    ) {
      const products = this.productsService.getFabricatedProducts().map((p) => {
        const fabricatedProduct = p as FabricatedProduct;
        const designation = fabricatedProduct.technicalSheet
          ? fabricatedProduct.technicalSheet['Designação do produto']
          : 'Unknown';
        const version = fabricatedProduct.technicalSheet
          ? fabricatedProduct.technicalSheet['Versão']
          : 'Unknown';
        return `${designation} - ${version} - ${fabricatedProduct.id}`;
      });
      return products;
    } else if (responseField.selectOptionsGetter === 'getIMSections.name') {
      const users = this.authenticationService.getStoreUsersUsername();
      return users.filter((s) => s.toLowerCase().indexOf('admin') === -1);
    } else if (this.type.type === 'trainingIbersolKFC') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PFI BÁSICOS') {
          return [
            'Orientação e Segurança no Trabalho',
            'Segurança Alimentar',
            'Introdução às funções da KFC',
            'Valores HWWT²',
            'Exame Básicos',
          ];
        } else if (response && response['Módulo'] === 'PFO BÁSICOS') {
          return ['Exame Básicos'];
        } else if (response && response['Módulo'] === 'PFI CAIXA (FOH)') {
          return [
            'Hospitalidade',
            'Funções Fusion',
            'Registo do Pedido',
            'Preparação do Pedido',
            'Entrega do Pedido',
            'Gestão de Clientes',
            'Exame FOH',
            'Observation Checklist FOH',
          ];
        } else if (response && response['Módulo'] === 'PFO CAIXA (FOH)') {
          return ['Exame FOH', 'Observation Checklist FOH'];
        } else if (response && response['Módulo'] === 'PFI COZINHA (BOH)') {
          return [
            'Manipulação do Frango',
            'Marinar',
            'Receita Original',
            'Receita Crocante',
            'Exame BOH',
            'Observation Checklist BOH',
          ];
        } else if (response && response['Módulo'] === 'PFO COZINHA (BOH)') {
          return ['Exame BOH', 'Observation Checklist BOH'];
        } else if (response && response['Módulo'] === 'PFI PACK (MOH)') {
          return [
            'FTF - freezer to fryer',
            'Preparação de Acompanhamentos',
            'Sanduíches',
            'Introdução a Embalar',
            'Exame MOH',
            'Observation Checklist MOH',
          ];
        } else if (response && response['Módulo'] === 'PFO PACK (MOH)') {
          return ['Exame MOH', 'Observation Checklist MOH'];
        } else if (response && response['Módulo'] === 'PFI DRIVE THRU') {
          return [
            'Drive Thru',
            'PT KFC EXAM DRIVE THRU',
            'Observation Checklist Drive Thru',
          ];
        } else if (response && response['Módulo'] === 'PFO DRIVE THRU') {
          return ['PT KFC EXAM DRIVE THRU', 'Observation Checklist Drive Thru'];
        } else if (response && response['Módulo'] === 'PFI SALA') {
          return ['Empregado de Sala', 'Exame Sala'];
        } else if (response && response['Módulo'] === 'PFO SALA') {
          return ['Exame Sala'];
        } else if (
          response &&
          response['Módulo'] === 'PFI  LIDERAR UM TURNO (LAS)'
        ) {
          return [
            'Introdução ao LAS',
            'Experiência do cliente',
            'Instrução',
            'Planeamento de Produto',
            'Gestão de Inventário',
            'Gestão de Desempenho',
            'Qualidade de Produto',
            'Tudo sob Controlo',
            'Serviço com velocidade',
            'Segurança',
            'Exame LAS',
            'Observation Checklist LAS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO  LIDERAR UM TURNO (LAS)'
        ) {
          return ['Exame LAS', 'Observation Checklist LAS'];
        } else if (response && response['Módulo'] === 'PFI Alergeneos') {
          return ['Alergeneos'];
        } else if (
          response &&
          response['Módulo'] === 'PFI Gestão de crises (Loja)'
        ) {
          return ['Gestão de crises (Loja)'];
        } else if (response && response['Módulo'] === 'PFI Kiosks') {
          return ['Kiosks'];
        } else if (
          response &&
          response['Módulo'] === 'PFI  LIDERAR UMA LOJA (LAR)'
        ) {
          return [
            'Introdução ao LAR',
            'Impulsionar Vendas',
            'Como formar a sua equipa',
            'Previsão',
            'Gerir Pessoal',
            'Liderança',
            'GES',
            'ROCC',
            'Desafio Final',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO  LIDERAR UMA LOJA (LAR)'
        ) {
          return ['Desafio Final'];
        } else if (response && response['Módulo'] === 'PFI RGM ACADEMY') {
          return [
            'Liderança com o Coração',
            'Trabalhar com Eficiência',
            'Introdução a RGM',
            'Planeamento de Brigadas',
            'Coaching com o Coração',
            'Formar e Desenvolver a tua Equipa',
            'A Experiência do Cliente',
            'Protecção da Marca',
            'Gerir com se fosse Teu',
            'Gestão Financeira',
            'Controlo de Custos',
            'Previsão de Vendas e Horários',
            'Cria uma Cultura de Família e Diversão',
            'Trabalhar Melhor, Juntos',
            'Ótima comunicação',
            'Celebra o Sucesso',
          ];
        }
      } else if (responseField.name === 'Módulo') {
        let modules = responseField.selectOptions;
        if (
          response &&
          (response['Nível interno'] === 'OPERADOR(A)' ||
            (response && response['Nível interno'] === 'OPERADOR(A) DD'))
        ) {
          return (modules || []).filter((s: string) => {
            return (
              s !== 'PFI  LIDERAR UM TURNO (LAS)' &&
              s !== 'PFO  LIDERAR UM TURNO (LAS)' &&
              s !== 'PFI  LIDERAR UMA LOJA (LAR)' &&
              s !== 'PFO  LIDERAR UMA LOJA (LAR)' &&
              s !== 'PFI RGM ACADEMY'
            );
          });
        }
        if (
          response &&
          (response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B')
        ) {
          return (modules ?? []).filter((s: string) => {
            return (
              s !== 'PFO  LIDERAR UMA LOJA (LAR)' && s !== 'PFI RGM ACADEMY'
            );
          });
        }
      }
    } else if (this.type.type === 'trainingIbersolPH') {
      if (responseField.name === 'Ação') {
        if (
          response &&
          response['Módulo'] === 'PFO INICIAL PROGRAMAS FORMAÇÃO SECÇÕES'
        ) {
          return [
            'PROGRAMA FORMAÇÃO PRODUÇÃO',
            'PROGRAMA FORMAÇÃO RESTAURANTE',
            'PROGRAMA FORMAÇÃO DELIVERY',
            'PROGRAMA FORMAÇÃO BALCÃO',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL CLEAN 2 SAFE'
        ) {
          return ['CLEAN 2 SAFE - PH'];
        } else if (response && response['Módulo'] === 'PFI ACOLHIMENTO') {
          return [
            'INTEGRAÇÃO',
            'SEGURANÇA ALIMENTAR',
            'SEGURANÇA E SAÚDE NO TRABALHO',
          ];
        } else if (response && response['Módulo'] === 'PFO CONTINUA HSA/SST') {
          return ['RECERTIFICAÇÃO HSA -2022', 'RECERTIFICAÇÃO SST-2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA PROGRAMAS FORMAÇÃO SECÇÕES'
        ) {
          return [
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT PRODUÇÃO - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT ATENDIMENTO NO RESTAURANTE - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT ATENDIMENTO AO BALCÃO - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT DELIVERY - 2022',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONT. CLEAN 2 SAFE REC.'
        ) {
          return ['RECERTIFICAÇÃO CLEAN TO SAFE - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL HSA @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL HSA @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL SST @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL SST @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO @ LEADING A SHIFT - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA HSA @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO  HSA @ LEADING A SHIFT - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA SST @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO  SST @ LEADING A SHIFT - 2022'];
        }
      }
    } else if (this.type.type === 'trainingIbersolMM') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'BASICS') {
          return [
            'WELCOME',
            'WORK SAFETY',
            'FOOD SAFETY',
            'CLEAN AND SAFE',
            'FOOD ALLERGIES AND INTOLERENCES',
            'CERTIFICAÇÃO INICIAL BASICS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FRONT OF THE HOUSE (FOH)'
        ) {
          return [
            'SERVICE LOVERS',
            'GUEST EXPERIENCE',
            'SALES OPERATIONS',
            'CERTIFICAÇÃO INICIAL FOH',
          ];
        } else if (
          response &&
          response['Módulo'] === 'BACK OF THE HOUSE (BOH)'
        ) {
          return ['FOOD LOVERS'];
        }
      }
    } else if (this.type.type === 'trainingIbersolPANS') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PANS_PFI_BASICS') {
          return [
            'PANS_BASICS_ACOLHIMENTO',
            'SEGURANÇA E SAÚDE NO TRABALHO : PRINCIPIOS BÁSICOS',
            'ACOLHIMENTO E INTEGRAÇÃO',
            'SEGURANÇA ALIMENTAR : PRINCIPIOS BÁSICOS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_BACK OF HOUSE'
        ) {
          return [
            'PANS_BOH_FOLHA DE PREPARAÇÃO',
            'PANS_BOH_PÃO',
            'PANS_BOH_SANDES',
            'PANS_BOH_PRODUTOS FRITOS',
            'PANS_BOH_SALADAS E SOPAS',
            'PANS_BOH_SOBREMESAS',
            'PANS_BOH_PREPARAÇÃO',
            'PANS_BOH_SUMOS',
            'PANS_BOH_MAKE-TABLE',
            'PANS_BOH_PASTELARIA E SALGADOS',
            'PANS_CERTIFICAÇÃO_BOH',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_FRONT OF HOUSE'
        ) {
          return [
            'PANS_FOH_DISTRIBUIÇÃO AO CLIENTE',
            'PANS_FOH_INTERAÇÃO COM O CLIENTE',
            'PANS_FOH_REGISTO DE POS',
            'PANS_FOH_CAFÉ PANS',
            'PANS_CERTIFICAÇÃO_FOH',
            'PANS APP',
            'Delivery',
          ];
        } else if (response && response['Módulo'] === 'PANS_PFC_NOVIDADE') {
          return ['PANS_INVERNO_2023', 'PANS_PRIMAVERA_2023'];
        } else if (response && response['Módulo'] === 'PANS_PFC_BASICS') {
          return [
            'RECERTIFICAÇÃO BASICS 3º TRIMESTRE 2022',
            'RECERTIFICAÇÃO BASICS 4º TRIMESTRE 2022',
          ];
        } else if (response && response['Módulo'] === 'PANS_PFC_OPERAÇÕES') {
          let ret: string[] = [];
          if (response['Nível interno'] === 'OPERADOR(A)') {
            ret = ret.concat([
              'RECERTIFICAÇÃO CHECKLIST OPERAÇÃO 2023',
              'Prova Recertificação Operadores 1º semestre 2023',
              'Prova Recertificação Operadores 2º semestre 2023',
            ]);
          }
          if (
            response['Nível interno'] !== 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] !== 'DIRECTOR(A) UNIDADE - B' ||
            response['Nível interno'] !== 'DIRECTOR(A) UNIDADE - C'
          ) {
            ret = ret.concat([
              'Fazemos sempre melhor-Normas apresent_Op-GT',
              "Equipamentos de trabalho e uso de EPI's",
            ]);
          }
          return ret;
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_EQUIPAS DIREÇÃO'
        ) {
          let ret: string[] = [];
          if (
            response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat(['Prova de Certificação de Gestão de Turno 2023']);
          }
          if (
            response['Nível interno'] !== 'OPERADOR(A)' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - A' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Certificação de Direção de Unidade 2023',
            ]);
          }
          return ret;
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFC_EQUIPAS DIREÇÃO'
        ) {
          let ret: string[] = [];
          if (
            response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Recertificação de Gestão de Turno 2023',
            ]);
          }
          if (
            response['Nível interno'] !== 'OPERADOR(A)' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - A' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Recertificação de Direção de Unidade 2023',
            ]);
          }
          if (response['Nível interno'] !== 'OPERADOR(A)') {
            ret = ret.concat([
              'Recertificação Checklist Equipas de Direção 2023',
              'Prova Recertificação Equipas Direção 2023',
            ]);
          }
          return ret;
        }
      } else if (responseField.name === 'Módulo') {
        let modules: string[] | undefined = responseField.selectOptions;
        if (modules) {
          if (response && response['Nível interno'] === 'OPERADOR(A)') {
            return modules.filter((s) => {
              return (
                s !== 'PANS_PFI_EQUIPAS DIREÇÃO' &&
                s !== 'PANS_PFC_EQUIPAS DIREÇÃO'
              );
            });
          } else if (
            response &&
            (response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
              response['Nível interno'] === 'DIRECTOR(A) UNIDADE - B' ||
              response['Nível interno'] === 'DIRECTOR(A) UNIDADE - C')
          ) {
            return modules.filter((s) => {
              return s !== 'PANS_PFC_OPERAÇÕES';
            });
          }
        }
      }
    } else if (this.type.type === 'trainingIbersolMIIT') {
      if (responseField.name === 'Ação') {
        if (
          response &&
          response['Módulo'] === 'FORMAÇÃO HIGIENE E SEGURANÇA ALIMENTAR MIIT'
        ) {
          return [
            'Tipos de contaminações e como evitá-las',
            'Norma de Higiene - Manipuladores',
            'Norma de Higiene - Lavagem das mãos',
            'Norma de Higiene- Visitantes',
            'Norma de Higiene - Diferença entre limpezas diárias e periódicas',
            'Norma de Higiene - Como limpar',
            'Norma de Higiene - Quem verifica',
            'Alergias e Intolerâncias Alimentares',
            'Acrilamida nos Alimentos',
            'Check list - Lavagem das mãos',
            'Check list - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO SEGURANÇA E SAÚDE NO TRABALHO MIIT'
        ) {
          return [
            'Riscos no posto de trabalho',
            'Organização da Segurança contra Incêndios',
            'Combate a incêndios',
            'Evacuação',
            'Primeiros Socorros',
            'Acidentes de trabalho',
            'Medicina no trabalho',
            'Checklist - Observação ao turno',
          ];
        } else if (response && response['Módulo'] === 'GRELHA') {
          return [
            'Grelhar',
            'Retenção',
            'Abertura e Fecho de grelha',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'PREPARAÇÃO DE CARNES') {
          return [
            'Preparação de carnes',
            'Preparação de temperos para a carne',
            'Limpeza e mantenção da área de produção',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] === 'CONFEÇÃO DE ACOMPANHAMENTOS E EMPRATAMENTO'
        ) {
          return [
            'Regeneração de Molhos',
            'Preparação do acompanhamento Batata rústica ',
            'Preparação do acompanhamento Arroz aromatizado',
            'Preparação dos acompanhamentos de frutas e legumes',
            'Preparação do acompanhamento Ovo estrelado',
            'Montagem da maketable e banho-maria, empratamento e entrega',
            'Confeção de Pão',
            'Outros acompanhamentos',
            'Limpeza e manutenção da área de confeção e empratamento',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] ===
            'PREPARAÇÃO DE VEGETAIS, FRUTA, SOBREMESAS, BEBIDAS E MOLHOS'
        ) {
          return [
            'Higienização de frutas, legumes e ervas aromáticas',
            'Preparação do Acompanhamento Salada',
            'Pré - preparação do Acompanhamento Legumes grelhados',
            'Preparação das frutas',
            'Preparação das sobremesas doces',
            'Preparação de bebidas',
            'Preparação de Molhos',
            'Limpeza e manutenção da área de preparação',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'COPA') {
          return [
            'Gestão de resíduos e óleo alimentar',
            'Lavagem de louça, utensílios, armazenamento e montagem de tabuleiros',
            'Limpeza e manutenção da máquina de lavar',
            'Limpeza e manutenção da área da copa',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'CAIXA E BALCÃO') {
          return [
            'Apresentação e farda',
            'Caixa',
            'Entrega de pedidos',
            'Abertura e fecho do balcão',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'HOSPEDEIRA/HOSPEDEIRO') {
          return [
            'Apresentação e farda',
            'Acolhimento do cliente',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        }
      }
    } else if (this.type.type === 'trainingIbersolRIBS') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'ACOLHIMENTO') {
          return ['BEM VINDO AO RIBS'];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO HIGIENE E SEGURANÇA ALIMENTAR RIBS'
        ) {
          return [
            'Introdução',
            'Segurança Alimentar',
            'Tipos de contaminações e como evitá-las',
            'Normas de Higiene - Manipuladores',
            'Normas de Higiene - Lavagem das mãos',
            'Normas de Higiene - Visitantes',
            'Normas de higiene - Diferença entre Limpeza e Higienização',
            'Normas de higiene - O que Limpar',
            'Normas de higiene - Diferença entres limpezas diárias e periódicas',
            'Normas de higiene - Como limpar',
            'Normas de higiene - Quem Verifica',
            'Alergias e intolerâncias Alimentares',
            'Acrilamida nos alimentos',
            'Check List - Lavagem das mãos',
            'Check List - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO SEGURANÇA E SAÚDE NO TRABALHO RIBS'
        ) {
          return [
            'Noções Básicas de Segurança e Saúde no Trabalho',
            'Riscos no posto de trabalho',
            'Organização da Segurança contra incêndios',
            'Combate a Incêndios',
            'Evacuação',
            'Primeiros Socorros',
            'Acidentes de Trabalho',
            'Medicina no Trabalho',
            'Check List - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'COZINHA - Formação Cozinheiro(a)'
        ) {
          return [
            'Norma de Manipuladores',
            'Rastreabilidade',
            'Tarefas de Abertura',
            'Limpeza de Fritadeiras',
            'Tarefas de passagem de turno',
            'Tarefas de fecho de turno',
            'Operação durante o turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'COZINHA - Formação Churrasqueiro(a)'
        ) {
          return [
            'Norma de Manipuladores',
            'Rastreabilidade',
            'Tarefas de Abertura',
            'Limpeza de Fritadeiras',
            'Tarefas de passagem de turno',
            'Tarefas de fecho de turno',
            'Preparação e confeção de carnes',
            'Operação durante o turno',
          ];
        } else if (response && response['Módulo'] === 'COZINHA FORMAÇÃO COPA') {
          return [
            'Gestão de resíduos e óleo alimentar',
            'Lavagem de louça, utensílios, armazenamento e montagem de tabuleiros',
            'Limpeza e manutenção da máquina de lavar',
            'Limpeza e manutenção da área da copa',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO  CAIXA E  BALCÃO'
        ) {
          return [
            'Apresentação e farda',
            'Caixa',
            'Entrega de pedidos',
            'Abertura e fecho do balcão',
            'Avaliação de competências',
          ];
        }
      }
    } else if (this.type.type === 'trainingIbersolPC') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PFI Módulo de Integração') {
          return ['Bem-Vindo ao Pasta Caffé'];
        } else if (
          response &&
          response['Módulo'] === 'PFO Inicial Segurança Alimentar'
        ) {
          return [
            'Higiene e Segurança Alimentar - Pasta Caffé',
            'Alergias e Intolerãncias Alimentares',
            'Acrilamida',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO Inicial Segurança e Saúde no Trabalho'
        ) {
          return ['Segurança e Saúde no Trabalho- Pasta Caffé'];
        } else if (response && response['Módulo'] === 'PFO Inicial Sala') {
          return [
            'Bar',
            'Organização de sala',
            'Momentos de Verdade',
            'Agregadores - Sala',
          ];
        } else if (response && response['Módulo'] === 'PFO Inicial Cozinha') {
          return [
            'Make table 2',
            'Make table 1',
            'Saida de Forno',
            'Copa',
            'Pesagens',
            'Preparações de Cozinha',
            'Agregadores - cozinha',
          ];
        }
      }
    } else if (responseField.selectOptionsGetter === 'store.plots.name') {
      return this.storesService.store.plots.map((p: any) => p.name);
    } else if (responseField.selectOptionsGetter === 'societyNF') {
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );
        const distinctSocieties = Array.from(
          new Set(matchingGroups.map((group: any) => group.codigo_sociedade))
        );
        response['Sociedade'] = distinctSocieties.map((item) => String(item));
        return distinctSocieties;
      }
      return '';
    } else if (responseField.selectOptionsGetter === 'varietyNF') {
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );
        const distinctVarieties = Array.from(
          new Set(matchingGroups.map((group: any) => group.variedade))
        );
        response['Variedade'] = distinctVarieties;
        return distinctVarieties.sort();
      }
      return '';
    } else if (responseField.selectOptionsGetter === 'field.plots.name') {
      return this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.map((p: any) => p.codigo_grupo_colheita)
        .sort();
    } else if (
      responseField.selectOptionsGetter === 'field.plots.parcels.name'
    ) {
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );
        const parcelasArray = matchingGroups.reduce(
          (acc: any, currentGroup: any) =>
            acc.concat(currentGroup.parcelas.map((p: any) => p.codigo_parcela)),
          []
        );
        return parcelasArray.sort();
      }
      return [];
    } else if (responseField.selectOptionsGetter === 'store.plots.fullName') {
      return this.storesService.store.plots.map((p: any) => p.fullName);
    } else if (
      responseField.selectOptionsGetter === 'store.plots.varieties' &&
      response['Parcela']
    ) {
      let parcel = this.storesService.store.plots.filter(
        (p: any) =>
          p.name === response['Parcela'] || p.fullName === response['Parcela']
      )[0];
      if (!parcel) return [];
      return parcel.varieties;
    } else if (
      responseField.selectOptionsGetter === 'field.plots.varieties' &&
      response['Parcela']
    ) {
      let parcel = this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.filter(
          (p: any) =>
            p.name === response['Parcela'] || p.fullName === response['Parcela']
        )[0];
      if (!parcel) return [];
      return parcel.varieties.sort();
    } else if (
      responseField.selectOptionsGetter === 'store.plots.varieties' &&
      response['Grupo de Colheita']
    ) {
      let parcels = this.storesService.store.plots.filter(
        (p: any) =>
          !!response['Grupo de Colheita'].filter(
            (gc: any) => gc === p.name || gc === p.fullName
          )[0]
      );
      const varieties = distinct(parcels.map((p: any) => p.varieties).flat());
      if (!(varieties.length > 0)) return [];
      return varieties;
    } else if (
      responseField.selectOptionsGetter === 'field.plots.varieties' &&
      response['Grupo de Colheita']
    ) {
      let parcels = this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.filter(
          (p: any) =>
            !!response['Grupo de Colheita'].filter(
              (gc: any) => gc === p.name || gc === p.fullName
            )[0]
        );
      const varieties = distinct(parcels.map((p: any) => p.varieties).flat());
      if (!(varieties.length > 0)) return [];
      return varieties.sort();
    } else if (responseField.selectOptionsGetter === 'store.plots.buyers') {
      return this.storesService.store.buyers.map((b: any) => b.code);
    } else if (responseField.selectOptionsGetter === 'suppliersLJ') {
      return [
        '1 Gelvalados - Produtos Alimentares Lda',
        '2 Pérola da Ilha',
        '3 Repraçores - Comércio e Representações AS',
        '4 Finançor Cach & Carry, Lda',
        '5 Sodril - Representações Insulares, Lda',
        '6 Insco - Insular de Hiper AS',
        '7 Higiaçores - Comercio e Serviços Lda',
        '8 Melo Abreu',
        '9 Paulo Teixeira',
        '10 Grupo Nabeiro',
        '11 Ocean Gin Company Lda',
        '12 O Rei dos Queijos',
        '13 Finançor Distribuição Alimentar, Lda',
        '14 Garcias Wine & Spirits',
        '15 Bom Sumo Lda',
        'Outro',
      ];
    } else if (responseField.selectOptionsGetter === 'productsLJ') {
      if (
        response &&
        response['Fornecedor'] === '5 Sodril - Representações Insulares, Lda'
      ) {
        return [
          'CRF',
          'Martel',
          'Zakapa',
          'Omelca - white',
          'Omelca - reposado',
          'Omelca - choc',
          'Eristoff Black',
          'Stolichnaya - Red',
          'Stolichnaya - Elit',
          'Black Label',
          'Baileys',
          'Beirão',
          'Porto - 20 anos',
          'Porto - branco',
          'Porto - Jockey Club',
          'Porto - LBV',
          'Burmester - Branco',
          'Burmester - Tinto',
          'Casa Burmester - B',
          'Casa Burmester - T',
          'Pedras limão',
          'Pedras tangerina',
          'Somersby',
          'Super bock',
          'S.B Sout',
          'S.B Weiss',
          'S.B Munich',
          'S.B IPA',
          'Não carne picada',
          'Hamburguer não carne',
          'Hamburguer não frango',
          'Nuggets Vegan',
          'Juliana Pimentos',
          'Falafel',
          'Brownie',
          'Sumo limão',
          'Preparado Mojito',
          'Pasta de Manjericão',
          'Nata vegan',
          'Clara de ovo',
          'Gema de ovo',
          "Hellmann's maionese",
          "Hellmann's mostarda",
          'Calve Barbecue',
          'Salada Bulgur & Whet',
          'Cabola pré frita (Kg)',
          'Arroz Basmati (12kg)',
          'Azeite (€/L)',
          'Pasta de Manjericão (340gr)',
          'Bechamel desidratado (800gr)',
          'Massa lasanha Knorr (5kg)',
          'Queijo fatiado flamengo (1KG)',
          'Queijo mozarella ralado (500gr)',
          'Queijo fresco flamengo',
          'Topping Caramelo',
          'Topping chocolate',
          'Topping frutos do bosque',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '3 Repraçores - Comércio e Representações AS'
      ) {
        return [
          'Remi Martin',
          'Amicis',
          'Brockmans',
          'Hendricks',
          'Martin Millers',
          'Sailor Jerry',
          'Brugal (White)',
          'Brugal (Añejo)',
          'Brugal (Extra)',
          'Brugal (1888)',
          'Famous Grouse',
          'Glenfidish',
          "Grant's",
          "Grant's - 12 anos",
          'Monkey Shoulder',
          'Naked',
          'Tullamore',
          'Drambuie',
          'Rocim - Branco',
          'Rocim - Tinto',
          'M.J.Branco',
          'M.J.Tinto',
          'Freixenet',
          'Stolisnaya',
          'pepsi',
          '7up',
          'compal laranja',
          'compal pessego',
          'compal ananas',
          'compal maça',
          'compal limão',
          'REVO',
        ];
      } else if (response && response['Fornecedor'] === '9 Paulo Teixeira') {
        return [
          'Courvoisier',
          'Citadelle',
          'Nordés',
          'Plantation (3Stars)',
          'Plantation (Dark)',
          'Plantation 5 años',
          'Plantation 20 años',
          'Plantation Pinaple',
          "Tito's",
          'Cutty Sark',
          "Jack Daniel's",
          'Batida de côco',
          'Dry Curaçao',
          'Grenadine',
          'Kahlúa',
          'Licor amora',
          'Licor maracujá',
          'Ginger Ale',
          'Ginger Beer',
          'Elderflower',
          'Mediterrain',
          'Indian',
          'Light',
          'Soda',
          'Cola',
          'Contreau',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '14 Garcias Wine & Spirits'
      ) {
        return [
          'Courvoisier',
          'CRF',
          'Citadelle',
          "Tito's",
          'Black Label',
          'Jim Beam',
          'Batida de côco',
          'Beirão',
          'Campari',
          'Cointreau',
          'Kahlúa',
          'Licor amora',
          'Licor maracujá',
          'Vermute',
          'Ginger Ale',
          'Ginger Beer',
          'Elderflower',
          'Mediterrain',
          'Indian',
          'Light',
          'Soda',
          'Cola',
        ];
      } else if (response && response['Fornecedor'] === '15 Bom Sumo Lda') {
        return [
          'Baleia',
          'Licor ananás (500ml)',
          'Licor ananás (100ml)',
          'Compota ananás',
          'Ananás',
        ];
      } else if (response && response['Fornecedor'] === '8 Melo Abreu') {
        return ['Baleia', 'Licor ananás (500ml)', 'Licor ananás (100ml)'];
      } else if (
        response &&
        response['Fornecedor'] === '4 Finançor Cach & Carry, Lda'
      ) {
        return [
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Amendoim',
          'Cajú frito',
          'Sortidos',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Noz moscada',
          'Cereja em calda',
          'Arroz',
          'Cogumelos',
          'Compota uva',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Tostas finas',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Iogurte grego',
          'Pato',
          'Atum natural enlatado (420gr)',
          'Azeite (€/L)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Miolo de noz',
          'Molho inglês Savora (120ml)',
          'Noz moscada (Margão)',
          'Pasta de Manjericão (340gr)',
          'Pimenta preta Margão (grão)',
          'Segredos do méxico (Mrg)',
          'sementes de chia (150gr)',
          'sementes de sêsamo (200gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '13 Finançor Distribuição Alimentar, Lda'
      ) {
        return [
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Pasta de Manjericão',
          'Óleo de côco',
          'Cereja em calda',
          'Arroz',
          'Cogumelos',
          'molho de soja',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Chocolate negro',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Iogurte grego',
          'Molho iogurte',
          'Queijo Parmesão',
          'Queijo fatiado flamengo',
          'Queijo Ilha ralado',
          'Alho picado (100gr)',
          'Atum natural enlatado (420gr)',
          'Azeite (€/L)',
          'Azeitonas preta picadas (200gr)',
          'Batata assar (Kg)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Cogumelos (congelados 400gr)',
          'Feijão preto enlatado (820gr)',
          'Molho soja Kikkoman (150ml)',
          'Molho inglês Savora (120ml)',
          'Óleo de côco (200ml vs 500ml)',
          'Pão cereais (650gr)',
          'Pimenta preta Margão (grão)',
          'sementes de chia (150gr)',
          'sementes de sêsamo (200gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
          'Tostas finas',
          'Aipo (500Kg)',
          'Salada Azores Fresh',
          'Alho frances (kg)',
          'Canoura (Kg)',
          'Gengibre (Kg)',
          'salsa (Kg)',
          'Iogurte grego  (Oîkos)',
          'Chouriço de Carne do Minho Ponte de Lima P.D.',
          'Pato (Kg)',
          'Bacon extra (naco) (Kg)',
          'Queijo Parmesão (150gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '6 Insco - Insular de Hiper AS'
      ) {
        return [
          'Espinafres com alho',
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Pasta de Manjericão',
          'Óleo de côco',
          'Azeite (seleção)',
          'Arroz',
          'Cogumelos',
          'molho de soja',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Chocolate negro',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Massa lasanha',
          'Massa tenra',
          'Iogurte grego',
          'Chouriço carne',
          'Presunto (naco)',
          'Pato',
          'Queijo Parmesão',
          'Queijo fatiado flamengo',
          'Queijo Ilha ralado',
          'Espinafres com alho (750gr)',
          'Azeite (€/L)',
          'Azeitonas preta picadas (200gr)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Cogumelos (congelados 400gr)',
          'Feijão preto enlatado (820gr)',
          'Molho inglês Savora (120ml)',
          'Noz moscada (Margão)',
          'Óleo de côco (200ml vs 500ml)',
          'Segredos do méxico (Mrg)',
          'sementes de chia (150gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
          'Alho frances (kg)',
          'Canoura (Kg)',
          'Iogurte grego  (Oîkos)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '12 O Rei dos Queijos'
      ) {
        return [
          'Compota uva',
          'Queijo Velho S.Miguel',
          'Queijo do Morro',
          'Queijo das Furnas',
          'Queijo S.João',
        ];
      } else if (response && response['Fornecedor'] === '2 Pérola da Ilha') {
        return ['Amendoim', 'Cajú frito', 'Sortidos'];
      } else if (response && response['Fornecedor'] === '10 Grupo Nabeiro') {
        return ['Café', 'Descafeinado'];
      } else if (
        response &&
        response['Fornecedor'] === '7 Higiaçores - Comercio e Serviços Lda'
      ) {
        return [
          'Filtro perfumado para Urinol',
          'Loção de mãos Chá de limão',
          'Sabão de mãos Chá de limão',
          'Pano microfibra amarelo',
          'Pano microfibra azul',
          'Pano microfibra rosa',
          'Pano microfibra verde',
          'Pano microfibra roxo',
          'Suma 4n1',
          'Papel hig folha a folha',
          'Bricer',
          'Suma Alcool Spray D4.12',
          'Suma Bac',
          'Suma Chlorsan D10.4',
          'Suma Multipurpose',
          'Toalhete de Mãos ZZ',
          'Fairy',
          'Saco lixo (15L)',
          'Saco lixo (30L)',
          'Saco lixo (50L)',
          'Saco lixo (100L)',
          'Guardanapo Grd',
          'Guardanapo Peq',
          'Guardanapo (preto)',
          'Papel de mãos autocorte',
          'Rolo térmico 57x40x11',
          'Rolo térmico 80x80x11',
          'Sacos plástico AD neutro 20x30',
          'Sacos plástico AD neutro 30x40',
          'Sacos plástico AD neutro 35x50',
          'Toalha individual mesa (preto)',
        ];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'problems') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const problemsList = ocorenciData.map((item: any) => item.problems);
      return problemsList;
    } else if (responseField.selectOptionsGetter === 'suppliers') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const selectedProblem = response['Tipo da Avaria'];
      const matchingProblemEntry = ocorenciData.find(
        (item: any) => item.problems === selectedProblem
      );
      if (response['previousSelectedProblem'] !== selectedProblem) {
        response[responseField.name] = '';
        response['previousSelectedProblem'] = selectedProblem;
      }
      response['allSuppliers'] = [];
      if (
        matchingProblemEntry &&
        Array.isArray(matchingProblemEntry.suppliers)
      ) {
        response['allSuppliers'] = matchingProblemEntry.suppliers.map(
          (entry: any) => entry.supplier
        );
        if (
          response[responseField.name] === '' &&
          matchingProblemEntry.suppliers.length === 1
        ) {
          response[responseField.name] =
            matchingProblemEntry.suppliers[0].supplier;
        }
      } else {
        response[responseField.name] = '';
      }
      return response['allSuppliers'];
    } else if (responseField.selectOptionsGetter === 'emailSuppliers') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const selectedSupplier = response['Fornecedor'];
      const matchingSupplierEntry = ocorenciData.find((item: any) =>
        item.suppliers.some(
          (supplier: any) => supplier.supplier === selectedSupplier
        )
      );
      const selectedProblem = response['Tipo da Avaria'];
      const matchingProblemEntry = ocorenciData.find(
        (item: any) => item.problems === selectedProblem
      );
      response['allEmailSuppliers'] = [];
      // Add the rest of the code here as needed

      if (matchingProblemEntry && matchingSupplierEntry) {
        const suppliersWithinProblem = matchingProblemEntry.suppliers;
        const selectedSupplierEntry = suppliersWithinProblem.find(
          (supplier: any) => supplier.supplier === selectedSupplier
        );
        if (selectedSupplierEntry && selectedSupplierEntry.emailsupplier) {
          const emails = selectedSupplierEntry.emailsupplier || [];
          if (emails.length > 1) {
            response['allEmailSuppliers'] = emails;
            return response['allEmailSuppliers'];
          } else {
            response[responseField.name] = emails[0] || '';
            return [emails[0] || ''];
          }
        }
      }
      response[responseField.name] = '';
      return [''];
    } else if (responseField.selectOptionsGetter === 'problemsNC') {
      return [
        'Caixas de Gordura',
        'Filtros da Hotte',
        'Desentupimentos',
        'Toldos',
        'A/C',
        'Motor Hotte',
        'Centrais de Incêndio',
        'Extintores',
        'Carreteis de Água',
        'Problemas POS',
        'Assistências',
        'Camaras CCTV',
        'Música Ambiente',
        'Equipamento',
      ];
    } else if (responseField.selectOptionsGetter === 'suppliersNC') {
      if (
        (response && response['Tipo da Avaria'] === 'Caixas de Gordura') ||
        response['Tipo da Avaria'] === 'Filtros da Hotte' ||
        response['Tipo da Avaria'] === 'Desentupimentos'
      ) {
        return ['Desentop / Assist'];
      } else if (response && response['Tipo da Avaria'] === 'Toldos') {
        return ['Tolniber'];
      } else if (
        (response && response['Tipo da Avaria'] === 'A/C') ||
        response['Tipo da Avaria'] === 'Motor Hotte'
      ) {
        return ['Efi3ene'];
      } else if (
        (response && response['Tipo da Avaria'] === 'Centrais de Incêndio') ||
        response['Tipo da Avaria'] === 'Extintores' ||
        response['Tipo da Avaria'] === 'Carreteis de Água'
      ) {
        return ['Alarmes 24'];
      } else if (response && response['Tipo da Avaria'] === 'Problemas POS') {
        return ['Codisys'];
      } else if (response && response['Tipo da Avaria'] === 'Assistências') {
        return [
          'Assistência Refrige',
          'Assistência Nicola',
          'Assistência Superbock',
        ];
      } else if (response && response['Tipo da Avaria'] === 'Camaras CCTV') {
        return ['Smart Solutions'];
      } else if (response && response['Tipo da Avaria'] === 'Música Ambiente') {
        return ['Sonoro Legal'];
      } else if (response && response['Tipo da Avaria'] === 'Equipamento') {
        return ['TecniHotel'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'emailSuppliersNC') {
      if (
        (response &&
          response['Fornecedor'] === 'Desentop / Assist' &&
          response['Tipo da Avaria'] === 'Caixas de Gordura') ||
        response['Tipo da Avaria'] === 'Filtros da Hotte' ||
        response['Tipo da Avaria'] === 'Desentupimentos'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.desentopAssist;
      } else if (
        response &&
        response['Fornecedor'] === 'Tolniber' &&
        response['Tipo da Avaria'] === 'Toldos'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.tolniber;
      } else if (
        (response &&
          response['Fornecedor'] === 'Efi3ene' &&
          response['Tipo da Avaria'] === 'A/C') ||
        response['Tipo da Avaria'] === 'Motor Hotte'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.efi3ene;
      } else if (
        (response &&
          response['Fornecedor'] === 'Alarmes 24' &&
          response['Tipo da Avaria'] === 'Centrais de Incêndio') ||
        response['Tipo da Avaria'] === 'Extintores' ||
        response['Tipo da Avaria'] === 'Carreteis de Água'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.alarmes24;
      } else if (
        response &&
        response['Fornecedor'] === 'Codisys' &&
        response['Tipo da Avaria'] === 'Problemas POS'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.codisys;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Refrige' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaRefrige;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Nicola' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaNicola;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Superbock' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaSuperbock;
      } else if (
        response &&
        response['Fornecedor'] === 'Smart Solutions' &&
        response['Tipo da Avaria'] === 'Camaras CCTV'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.smartSolutions;
      } else if (
        response &&
        response['Fornecedor'] === 'Sonoro Legal' &&
        response['Tipo da Avaria'] === 'Música Ambiente'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.sonoroLegal;
      } else if (
        response &&
        response['Fornecedor'] === 'TecniHotel' &&
        response['Tipo da Avaria'] === 'Equipamento'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.tecniHotel;
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'problemsLJ2') {
      return [
        'Dispensadores Higiene feminina',
        'Extintores',
        'Carretel',
        'Alarme de incêncio',
        'Alarme de intrusão',
        'Equipamentos de frio',
      ];
    } else if (responseField.selectOptionsGetter === 'suppliersLJ2') {
      if (
        response &&
        response['Tipo da Avaria'] === 'Dispensadores Higiene feminina'
      ) {
        return ['Higiaçores'];
      } else if (
        (response && response['Tipo da Avaria'] === 'Extintores') ||
        response['Tipo da Avaria'] === 'Carretel' ||
        response['Tipo da Avaria'] === 'Alarme de incêncio' ||
        response['Tipo da Avaria'] === 'Alarme de intrusão'
      ) {
        return ['Extinçor'];
      } else if (
        response &&
        response['Tipo da Avaria'] === 'Equipamentos de frio'
      ) {
        return ['ASR Santos'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'emailSuppliersLJ2') {
      if (
        response &&
        response['Fornecedor'] === 'Higiaçores' &&
        response['Tipo da Avaria'] === 'Dispensadores Higiene feminina'
      ) {
        return ['geral@higiacores.com'];
      } else if (
        (response &&
          response['Fornecedor'] === 'Extinçor' &&
          response['Tipo da Avaria'] === 'Extintores') ||
        response['Tipo da Avaria'] === 'Carretel' ||
        response['Tipo da Avaria'] === 'Alarme de incêncio' ||
        response['Tipo da Avaria'] === 'Alarme de intrusão'
      ) {
        return ['bruno.serra@extincor.pt'];
      } else if (
        response &&
        response['Fornecedor'] === 'ASR Santos' &&
        response['Tipo da Avaria'] === 'Equipamentos de frio'
      ) {
        return ['tecnicaamilcarsantos@gmail.com'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    }
    return responseField.selectOptions || [];
  }

  async pressAddRegister(taskType: any) {
    console.log('Hello?');
    const type: TaskType | undefined =
      this.customTaskTypesService.getCustomTaskType(taskType);

    if (!type) {
      console.error('Task type is undefined');
      return;
    }

    let product;
    if (type.product) {
      product = await this.getProduct(type);
      if (!product) return;
    }

    let batch;
    if (type.batch) {
      batch = await this.getBatch(product);
      if (!batch) return;
    }

    const modal = await this.modalController.create({
      component: CustomTypeChildTaskModal,
      componentProps: {
        type,
        form: this.form,
        precedingTaskResponse: this.response,
        precedingTask: this.task,
        extras: {
          product,
          batch,
        },
      },
    });

    await modal.present();

    const result = await modal.onDidDismiss();
    const data: TaskAndResponseObject = result.data;

    if (data) {
      this.response.childrenTasksIds
        ? this.response.childrenTasksIds.push(data.task.id)
        : (this.response.childrenTasksIds = [data.task.id]);
      this.pendingTasksAndResponses.push(data);

      // Handle task type specific list update with if-else
      if (data.task.type === 'prepackagedPDVIntermarcheShort2') {
        this.prepackageTasks.push(data);
      } else if (
        data.task.type === 'unfreezingProductsShort2' ||
        data.task.type === 'unfreezingProductsRepsol'
      ) {
        this.unfreezingTasks.push(data);
      } else if (
        data.task.type === 'warmTemperatureControlIntermarcheShort2' ||
        data.task.type === 'warmTemperatureControlRepsol'
      ) {
        this.warmTemperatureControlTasks.push(data);
      } else if (data.task.type === 'inBeefRegisterShort2') {
        this.inBeefRegisterTasks.push(data);
      } else if (data.task.type === 'fermentationProductsRepsol') {
        this.fermentationProductsTasks.push(data);
      } else {
        console.error('Unknown task type:', data.task.type);
      }
    }
  }

  async getProduct(type: any) {
    let includeProductsWithoutTechnicalSheet: boolean = !type.production;

    const modal = await this.modalController.create({
      component: ProductListModal,
      componentProps: {
        includeProductsWithoutTechnicalSheet,
      },
    });

    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  async getBatch(product: any) {
    const modal = await this.modalController.create({
      component: BatchListModal,
      componentProps: { product },
    });
    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  async addInternalReceptionTask(productInfo: any, productDestiny: any) {
    const inputBarcodeModal = await this.modalController.create({
      component: InputBarcodeModal,
    });

    await inputBarcodeModal.present();

    const result = await inputBarcodeModal.onDidDismiss();
    const data = result.data;

    if (!data || data.text === '') {
      const toast = await this.toastController.create({
        message: this.translator.instant(
          'Obrigatório preencher com um código de barras'
        ),
        duration: 2000,
        position: 'top',
        buttons: [
          {
            side: 'end',
            text: this.translator.instant('Fechar'),
            role: 'cancel',
          },
        ],
      });
      await toast.present();
      return;
    }

    await this.pressBarcodeScanner(productInfo, data, productDestiny);
  }

  async pressBarcodeScanner(
    productInfo: any[],
    barcodeValue?: any,
    productDestiny?: any
  ) {
    const type: TaskType | undefined =
      this.customTaskTypesService.getCustomTaskType(
        'goodsReceptionTakeawayIntermarche'
      );
    if (!type) {
      console.error('Task type is undefined');
      return;
    }

    if (!barcodeValue || !barcodeValue.text) {
      const permissionStatus = await BarcodeScanner.checkPermissions();
      if (permissionStatus.camera !== 'granted') {
        await BarcodeScanner.requestPermissions();
      }

      const result = await scanBarcode(BarcodeScanner);

      if (result) {
        barcodeValue = { text: result };
      } else {
        const inputBarcodeModal = await this.modalController.create({
          component: InputBarcodeModal,
        });
        await inputBarcodeModal.present();
        const { data } = await inputBarcodeModal.onDidDismiss();
        barcodeValue = data;
      }
    }

    if (!barcodeValue || barcodeValue.text === '') {
      const toast = await this.toastController.create({
        message: this.translator.instant(
          'Obrigatório preencher com um código de barras'
        ),
        duration: 2000,
        position: 'top',
        buttons: [{ text: this.translator.instant('Fechar'), role: 'cancel' }],
      });
      await toast.present();
      return;
    }

    const product = this.productsService.getProducts().find((p) => {
      if (p.barcode && p.barcode.itm8 && p.barcode.itm8 === barcodeValue.text) {
        return true;
      }
      if (p.barcode && Array.isArray(p.barcode.value)) {
        return p.barcode.value.some((value) => {
          return (
            value.toString() === barcodeValue.text ||
            (value.length < 13 &&
              value.toString() === barcodeValue.text.substring(0, 7)) ||
            (value.toString().substring(7, 13) === '000000' &&
              value.toString().substring(0, 7) ===
                barcodeValue.text.substring(0, 7))
          );
        });
      }
      return false;
    });

    if (!product) {
      const toast = await this.toastController.create({
        message:
          'Código de barras não corresponde a nenhum produto na base de dados.',
        duration: 2000,
        position: 'top',
        buttons: [{ text: this.translator.instant('Fechar'), role: 'cancel' }],
      });
      await toast.present();

      const modal = await this.modalController.create({
        component: CreateAndFillCustomTypeTaskModal,
        componentProps: {
          type,
          form: this.form,
          extras: {
            barcode: barcodeValue ? barcodeValue.text : '',
          },
          onTaskClosedSuccessfully: this.onTaskClosedSuccessfully,
        },
      });
      const { data } = (await modal.onDidDismiss()) as {
        data: TaskAndResponseObject;
      };
      if (data) {
        productInfo.push(data.response);
      }
      await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: CreateAndFillCustomTypeTaskModal,
        componentProps: {
          type,
          form: this.form,
          extras: {
            product,
            supplier: product.supplierName
              ? { name: product.supplierName }
              : this.storesService.store.suppliers.find(
                  (s: any) => s.id === product.supplierId
                ),
            barcode: barcodeValue ? barcodeValue.text : '',
            productDestiny: productDestiny,
          },
          onTaskClosedSuccessfully: this.onTaskClosedSuccessfully,
        },
      });
      const { data } = (await modal.onDidDismiss()) as {
        data: TaskAndResponseObject;
      };
      if (data) {
        productInfo.push(data.response);
      }
      await modal.present();
    }
  }

  getPlaceholderTranslation = (object: {
    placeholder: string;
    placeholderEs?: string;
  }): string => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.placeholder;
    } else if (lang === 'es') {
      return object.placeholderEs || object.placeholder;
    }
    return object.placeholder;
  };

  async openSelectRawMaterialUsed(row: any) {
    const productListModal = await this.modalController.create({
      component: ProductListModal,
      componentProps: {},
    });

    await productListModal.present();

    const productListResult = await productListModal.onDidDismiss();
    const product = productListResult.data;

    if (!product) return;

    const batchSelectModal = await this.modalController.create({
      component: BatchSelectModal,
      componentProps: { product },
    });

    await batchSelectModal.present();

    const batchSelectResult = await batchSelectModal.onDidDismiss();
    const batches = batchSelectResult.data;

    if (!batches || batches.length === 0) return;

    row.selectedProductInfo =
      product.name +
      ' - ' +
      batches.map((b: any) => `${b.lot} - ${b.expirationDate}`).join('; ');
  }

  async pressPrint() {
    const modal = await this.modalController.create({
      component: PrinterPage,
      componentProps: {
        task: this.task,
        response: this.response,
        store: this.storesService.store,
        buyer:
          this.storesService.getBuyerByName(this.response['Comprador']) ||
          this.storesService.getBuyerByName(this.response['Cliente']),
        plot:
          this.storesService.getPlotByName(this.response['Parcela']) ||
          this.storesService.getPlotByFullName(this.response['Parcela']),
      },
    });
    await modal.present();
  }

  pressTrackGPS() {
    this.alertService.present(
      'Em desenvolvimento',
      'No futuro poderá dar track do percurso'
    );
  }

  onNewOptionAdded(newOption: string, responseField: ResponseField) {
    console.log('onNewOptionAdded');
    if (!this.storesService.store.modules) {
      this.storesService.store.modules = { fieldsSelectOptions: {} };
    }
    if (!this.storesService.store.modules.fieldsSelectOptions) {
      this.storesService.store.modules.fieldsSelectOptions = {};
    }
    if (responseField && responseField.name) {
      if (
        !this.storesService.store.modules.fieldsSelectOptions[
          responseField.name
        ]
      ) {
        this.storesService.store.modules.fieldsSelectOptions[
          responseField.name
        ] = [];
      }

      this.storesService.store.modules.fieldsSelectOptions[
        responseField.name
      ].push(newOption);
    } else {
      console.error(
        'Unable to save the new option due to null/undefined values'
      );
    }
  }

  getUserAddedOptions(responseField: ResponseField): string[] {
    if (
      this.storesService?.store?.modules?.fieldsSelectOptions &&
      responseField?.name &&
      this.storesService.store.modules.fieldsSelectOptions[responseField.name]
    ) {
      return this.storesService.store.modules.fieldsSelectOptions[
        responseField.name
      ];
    } else {
      return [];
    }
  }

  recalculateAllData() {
    this.type.metadata.responseFields.forEach((rf) => {
      if (rf.calculateValue) {
        this.response[rf.name] = FormUtils.calculateValue(
          rf.calculateValue,
          this.response[rf.name],
          this.response
        );
      }
    });
  }
}
