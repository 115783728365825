import { Component, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import cuid from 'cuid';

import { Product } from '../../entities/product';
import { ResponseField } from '../../entities/tasks/task';
import { StoresService } from '../../services/stores.service';
import { ProductsService } from '../../services/products.service';
import { translate } from '../../../utils/translate.utils';
import { AuthenticationService } from 'src/app/services/authentication';

@Component({
  selector: 'app-product-create-modal',
  templateUrl: 'product-create.modal.html',
  styleUrls: ['../../../theme/modals.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductCreateModal {
  product: Product;

  nameResponseField: ResponseField = {
    name: 'Designação',
    type: 'text',
    responseValidation: { required: true },
    placeholder: 'Designação',
    required: true,
    autofill: 'NONE',
  };
  familyResponseField: ResponseField = {
    name: 'Família',
    type: 'select',
    responseValidation: { required: true },
    placeholder: 'Família',
    required: true,
    autofill: 'NONE',
  };
  brandResponseField: ResponseField = {
    name: 'Marca',
    type: 'text',
    placeholder: 'Marca',
    required: false,
    autofill: 'NONE',
  };
  supplierResponseField: ResponseField = {
    name: 'Fornecedor',
    type: 'text',
    responseValidation: { required: true },
    placeholder: 'Fornecedor',
    required: true,
    autofill: 'NONE',
  };
  internalBarcodeResponseField: ResponseField = {
    name: 'Cód. barras interno',
    type: 'text',
    placeholder: 'Cód. barras interno',
    autofill: 'NONE',
    required: false,
  };
  eanBarcodeResponseField: ResponseField = {
    name: 'EAN',
    type: 'text',
    placeholder: '13 dígitos',
    autofill: 'NONE',
    required: false,
  };
  pluResponseField: ResponseField = {
    name: 'PLU',
    type: 'text',
    placeholder: '13 dígitos',
    autofill: 'NONE',
    required: false,
  };

  readonly PRODUCT_FAMILIES = [
    'Lacticínios',
    'Ultra-congelados',
    'Charcutaria',
    'Aves',
    'Peixe fresco',
    'Frutas e legumes',
    'Mercearia',
    'Carnes',
    'Novilho',
    'Queijo',
    'Líquida',
    'Padaria/Pastelaria',
    'Pronto a comer',
    'MG',
    'DPH',
    'IV Gama',
    'Pastelaria industrial',
    'Bacalhau',
    'Consumiveis/Detergentes Limpeza Loja',
    'Combustível',
    'Gás',
    'Tabaco',
  ];

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private storeService: StoresService,
    private toastController: ToastController,
    private translator: TranslateService,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService
  ) {
    const ean = this.navParams.get('barcode') || '';
    this.product = {
      name: '',
      id: cuid(),
      barcode: {
        plu: '',
        itm8: '',
        value: ean,
      },
      batches: [],
      createdInStore: true,
      supplierName: '',
      storeId: '',
      family: '',
      brand: '',
    };
  }

  get family(): string[] {
    return this.product.family ? [this.product.family] : [];
  }

  set family(value: string[]) {
    this.product.family = value[0] ?? '';
  }

  get brand(): string {
    return this.product.brand ?? '';
  }

  set brand(value: string) {
    this.product.brand = value;
  }

  get itm8(): string {
    return this.product.barcode.itm8 ?? '';
  }

  set itm8(value: string) {
    this.product.barcode.itm8 = value;
  }

  get ean(): string {
    return this.product.barcode.value ?? '';
  }

  set ean(value: string) {
    this.product.barcode.value = value;
  }

  get plu(): string {
    return this.product.barcode.plu ?? '';
  }

  set plu(value: string) {
    this.product.barcode.plu = value;
  }

  close() {
    this.modalCtrl.dismiss();
  }

  getFamiliesOptions = () => {
    return this.PRODUCT_FAMILIES;
  };

  async pressCreateProduct() {
    let existingProduct;

    const ean = this.product.barcode.value;
    const internal = this.product.barcode.itm8;
    const plu = this.product.barcode.plu;
    if (
      this.nameResponseField.responseValidation?.required &&
      !this.product.name.trim()
    ) {
      (
        await this.makeRequiredFieldToast(this.nameResponseField.name)
      ).present();
      return;
    }

    if (
      this.familyResponseField.responseValidation?.required &&
      !this.product.family
    ) {
      (
        await this.makeRequiredFieldToast(this.familyResponseField.name)
      ).present();
      return;
    }

    if (
      this.supplierResponseField.responseValidation?.required &&
      !this.product.supplierName
    ) {
      (
        await this.makeRequiredFieldToast(this.supplierResponseField.name)
      ).present();
      return;
    }

    const hasAtLeastOneBarcode = ean || internal || plu;

    if (!hasAtLeastOneBarcode) {
      (await this.makeProductAtLeastOneBarcodeNecessaryToast()).present();
      return;
    }

    existingProduct = this.productsService.getProducts().find((p) => {
      const hasSameEan = p.barcode && ean && p.barcode.value === ean;
      const hasSameInternal =
        p.barcode && internal && p.barcode.itm8 === internal;
      const hasSamePlu = p.barcode && plu && p.barcode.plu === plu;

      return hasSameEan || hasSameInternal || hasSamePlu;
    });

    if (existingProduct) {
      if (existingProduct.barcode.value === ean && ean) {
        (await this.makeProductAlreadyExistsValueToast()).present();
      } else if (existingProduct.barcode.itm8 === internal && internal) {
        (await this.makeProductAlreadyExistsITM8Toast()).present();
      } else if (existingProduct.barcode.plu === plu && plu) {
        (await this.makeProductAlreadyExistsPLUToast()).present();
      }
    } else {
      this.product.users = [
        this.authenticationService.getUserInfo()?.id as string,
      ];
      this.productsService
        .newProduct(this.product, this.authenticationService.getAuthInfo())
        .subscribe(
          async (data) => {
            if (data) {
              (await this.makeProductCreatedSuccessfullyToast()).present();
            }
          },
          (err) => console.log(err)
        );
      this.modalCtrl.dismiss(this.product);
    }
  }

  private async makeRequiredFieldToast(fieldName: string) {
    const message = await translate(
      `${fieldName} é obrigatório.`,
      this.translator
    );
    return this.toastController.create({
      message,
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  private async makeProductCreatedSuccessfullyToast() {
    const message = await translate(
      'O produto foi criado corretamente.',
      this.translator
    );
    return this.toastController.create({
      message,
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  private async makeProductAlreadyExistsITM8Toast() {
    return this.toastController.create({
      message: 'Já existe um produto com este código de barras interno.',
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  private async makeProductAlreadyExistsValueToast() {
    return this.toastController.create({
      message: 'Já existe um produto com este EAN.',
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  private async makeProductAlreadyExistsPLUToast() {
    return this.toastController.create({
      message: 'Já existe um produto com este PLU.',
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  private async makeProductAtLeastOneBarcodeNecessaryToast() {
    return this.toastController.create({
      message: 'Necessário pelo menos um código de barras.',
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }
}
