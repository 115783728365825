<div class="modalItem">
  <div class="modalHeader">
    <div class="modalTitle">
      <h3>{{ 'TASK' | translate }}</h3>
      <img src="assets/img/close.svg" (click)="close()" />
    </div>
  </div>
  <div class="modalContent">
    <app-custom-form-task
      [task]="task"
      [onTaskClosedSuccessfully]="onTaskClosedSuccessfully"
    ></app-custom-form-task>
  </div>
</div>
