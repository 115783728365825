import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-button-component',
  template: `
    <div style="text-align: end;">
      <ion-button
        fill="solid"
        (click)="pressAdd()"
        style="font-size: 1rem; padding: 10px; --color: white; --background: var(--ion-color-success)"
      >
        {{ buttonText }}
        <ion-icon
          name="add-outline"
          slot="end"
          style="color: white;"
        ></ion-icon>
      </ion-button>
    </div>
  `,
})
export class AddButtonComponent {
  @Input() buttonText: string = 'Adicionar novo'; // Default text, can be customized via input
  @Output() handleAdd: EventEmitter<void> = new EventEmitter<void>();

  pressAdd() {
    this.handleAdd.emit(); // Notify parent when the button is clicked
  }
}
