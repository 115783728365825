<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ 'Escolher tipo de registo' | translate }}</h3>
          <ion-icon name="close" (click)="close()"></ion-icon>
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <ion-item>
            <ion-label>{{ 'TASK_TYPE' | translate }}:</ion-label>
            <ion-select [(ngModel)]="type">
              <ion-select-option *ngFor="let creatableTaskType of creatableTaskTypes" [value]="creatableTaskType">
                {{ creatableTaskType.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </div>
        <div class="modalActions">
          <div class="actionsLeft"></div>
          <div class="actionsRight">
            <button class="btn btn-ok" (click)="pressSubmit()">
              {{ 'OK' | translate }}
            </button>
            <button class="btn btn-cancel light-grey" (click)="close()">
              {{ 'CANCEL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  