<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Fechar</ion-button>
    </ion-buttons>
    <ion-title>{{ getProductDesignation(product) }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <product-input
    [responseField]="productNameResponseField"
    [(value)]="product"
  ></product-input>
  <!-- <boolean-input
      [responseField]="nonApplicableResponseField"
      [(value)]="nonApplicable"
    ></boolean-input> -->
  <text-input
    *ngIf="!nonApplicable"
    [responseField]="lotResponseField"
    [(value)]="lot"
  ></text-input>
  <date-input
    *ngIf="!nonApplicable"
    [responseField]="primaryExpirationDateResponseField"
    [(value)]="primaryExpirationDate"
    (valueChange)="onPrimaryExpirationDateChanged($event)"
  ></date-input>
  <datetime-input
    *ngIf="!nonApplicable"
    [responseField]="preparationDateResponseField"
    [(value)]="preparationDate"
    (valueChange)="onPreparationDateValueChanged($event)"
  ></datetime-input>
  <!-- <app-batch-input
    *ngIf="!nonApplicable"
    [(value)]="batch"
    [product]="product"
    [responseField]="batchResponseField"
    (valueChange)="onBatchValueChanged($event)"
  ></app-batch-input> -->
  <datetime-input
    *ngIf="!nonApplicable"
    [responseField]="secondaryExpirationDateResponseField"
    [(value)]="secondaryExpirationDate"
  ></datetime-input>
  <number-input
    *ngIf="!nonApplicable"
    [responseField]="quantityTagsResponseField"
    [(value)]="quantityTags"
  ></number-input>
  <refrigerated-tag
    *ngIf="(!product?.unfreezingTime && !product?.['preparationTime']) && !product.secondaryExpirationHoursSameAsPrimary"
    [nonApplicable]="nonApplicable"
    [product]="product"
    [lot]="lot"
    [primaryExpirationDate]="primaryExpirationDate"
    [preparationDate]="preparationDate"
    [secondaryExpirationDate]="secondaryExpirationDate"
  ></refrigerated-tag>
  <frozen-tag
    *ngIf="(product?.unfreezingTime || product?.['preparationTime']) && !product.secondaryExpirationHoursSameAsPrimary"
    [nonApplicable]="nonApplicable"
    [product]="product"
    [lot]="lot"
    [primaryExpirationDate]="primaryExpirationDate"
    [unfrozenDate]="getUnfrozenDate()"
    [secondaryExpirationDate]="secondaryExpirationDate"
  ></frozen-tag>
  <same-secondary-as-primary-tag
    *ngIf="product.secondaryExpirationHoursSameAsPrimary"
    [nonApplicable]="nonApplicable"
    [product]="product"
    [lot]="lot"
    [primaryExpirationDate]="primaryExpirationDate"
    [preparationDate]="preparationDate"
  ></same-secondary-as-primary-tag>
  <thermal-shock-tag
    *ngIf="product.isThermalShock"
    [nonApplicable]="nonApplicable"
    [product]="product"
    [preparationDate]="preparationDate"
  >
  </thermal-shock-tag>

  <ion-card>
    <ion-card-header>
      <ion-card-title>Detalhe</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-list-header
          ><ion-label>Última validade do produto</ion-label></ion-list-header
        >
        <ion-item>
          <ion-label>
            <h3>{{product.name}}</h3>
            <p>
              {{ getLastSecondaryExpirationDate(product) | date: 'yyyy-MM-dd HH:mm' }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list *ngIf="product.ingredientsIds">
        <ion-list-header
          ><ion-label
            >Validades atuais dos ingredientes</ion-label
          ></ion-list-header
        >
        <ion-item
          *ngFor="let pData of productsService.getIngredientsData(product)"
        >
          <ion-label>
            <h3>{{ pData.product.name }}</h3>
            <p>{{ pData.expirationDate | date: 'yyyy-MM-dd HH:mm' }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="print()">Imprimir</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
