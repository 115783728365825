<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>Configuração da Impressora</h3>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <button
          ion-button
          icon-start
          (click)="findPrinters()"
          *ngIf="!refreshing"
          style="float: right; margin-bottom: 5px"
        >
          <ion-icon name="refresh"></ion-icon>
          Refresh list
        </button>

        <ion-list *ngIf="!refreshing">
          <ion-item>
            <p>Found printers will be shown here</p>
          </ion-item>
        </ion-list>

        <ion-list *ngIf="refreshing">
          <ion-item class="centered-content printer-item">
            <ion-spinner name="crescent"></ion-spinner>
            <p>Refreshing...</p>
          </ion-item>
        </ion-list>

        <ion-list *ngIf="!refreshing && printersList.length > 0">
          <ion-item
            *ngFor="let item of printersList"
            (click)="selectPrinter(item)"
            [class.selected]="item.ipAddress === selectedPrinter?.ipAddress"
            class="printer-item"
          >
            <span
              *ngIf="item.ipAddress === selectedPrinter?.ipAddress"
              class="selected-text"
            >
              Selected
            </span>
            {{ item.modelName }} - {{ item.ipAddress }}
          </ion-item>
        </ion-list>
        <ion-item>
          <ion-label>Orientation</ion-label>
          <ion-select
            [(ngModel)]="selectedOrientation"
            (ionChange)="onOrientationChange()"
          >
            <ion-select-option value="PORTRAIT">Portrait</ion-select-option>
            <ion-select-option value="LANDSCAPE">Landscape</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label>Paper Size</ion-label>
          <ion-select
            [(ngModel)]="selectedPaperSize"
            (ionChange)="onPaperLabelNameChange()"
          >
            <ion-select-option default value="W62H29">W62H29</ion-select-option>
            <ion-select-option value="W29H90">W29H90</ion-select-option>
            <ion-select-option value="W62H100">W62H100</ion-select-option>
            <!-- Add more paper sizes as needed -->
          </ion-select>
        </ion-item>
      </div>
      <div class="modalActions">
        <div class="actionsLeft"></div>
        <div class="actionsRight">
          <button
            [disabled]="!selectedPrinter"
            class="btn-clear-selection"
            *ngIf="selectedPrinter"
            (click)="clearSelected()"
          >
            Clear Selection
          </button>
          <button class="btn btn-cancel light-grey" (click)="close()">
            {{ 'CLOSE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
