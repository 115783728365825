import { Component, Input } from '@angular/core';
import { ModalController, Platform, LoadingController } from '@ionic/angular';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Browser } from '@capacitor/browser';
import { InfoModal } from './info.modal';

import { environment } from 'src/environments/environment';
import { UserInfo } from '../../entities/authentication-info';
import { SECOND } from '../../../utils/units/time.units';
import { AlertService } from '../../services/alert.service';
import { OfflineService } from '../../services/offline.service';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'info-button',
  templateUrl: 'info.button.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InfoButton {
  @Input() task: any;
  description: any;
  DOWNLOAD_DOCUMENTS_TIMEOUT_IN_MS = 3 * 60 * SECOND;
  wasAborted = false;

  constructor(
    private loadingController: LoadingController,
    private offlineService: OfflineService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private platform: Platform,
    protected envService: EnvironmentService
  ) {}

  /** We had to edit the svg files. The "svg" element. Look at link.svg and download_dov.svg for reference. */
  getIconSrc(): string {
    if (this.task?.description?.url || this.task?.description?.documentId) {
      return 'assets/img/info.svg';
    }
    if (this.task?.description?.link) {
      return 'assets/img/link.svg';
    }
    if (this.task?.description?.download) {
      return 'assets/img/download_doc.svg';
    }
    return ''; // Caso nenhuma condição seja atendida
  }

  hasAnyInfo(): boolean {
    return !!(
      this.task?.description?.documentId ||
      this.task?.description?.url ||
      this.task?.description?.link ||
      this.task?.description?.download
    );
  }

  async showInfo() {
    // Garante que `this.task` e `this.task.description` existam antes de prosseguir
    const description = this.description || this.task?.description;
    // Verifica se `documentId` está presente
    if (description.documentId) {
      this.openPdf();
    } 
    // Verifica se `url`, `link` ou `download` estão presentes
    else if (description.url || description.link || description.download) {
      this.openUrl();
    } 
    // Se nenhuma informação relevante for encontrada, abre o modal de informações
    else {
      this.openInfoModal();
    }
  }  
  
  async openPdf() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();

    if (this.platform.is('android') && this.platform.is('cordova')) {
      try {
        const filePath = await this.downloadPdf();
        await FileOpener.open({
          filePath,
          contentType: 'application/pdf',
        });
        await loading.dismiss();
      } catch (error) {
        console.error(error);
        await loading.dismiss();
      }
    } else {
      window.open(
        this.envService.getApiUri() + '/media/' + this.getDocument(),
        '_system'
      );
      await loading.dismiss();
    }
  }

  async openInfoModal() {
    const modal = await this.modalCtrl.create({
      component: InfoModal,
      componentProps: { description: this.description },
    });
    await modal.present();
  }

  getDocument() {
    return this.task.description.documentId;
  }

  async openUrl() {
    // Garante que `this.description` está definida antes de acessar suas propriedades
    const description = this.description || this.task?.description;
    const urlToOpen = description?.url || description?.link || description?.download;
  
    // Se `urlToOpen` estiver indefinido, aborta a função
    if (!urlToOpen) {
      console.warn('Nenhuma URL válida foi encontrada para abrir.');
      return;
    }
  
    if (this.platform.is('cordova')) {
      await Browser.open({
        url: urlToOpen,
      });
    } else {
      window.open(urlToOpen, '_system');
    }
  }
  

  async downloadPdf(): Promise<string> {
    const response = await fetch(
      this.envService.getApiUri() + '/media/' + this.getDocument()
    );
    const blob = await response.blob();

    const base64Data = await this.convertBlobToBase64(blob);

    const fileName = `${this.getDocument()}.pdf`;

    const result = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });

    return result.uri;
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }
}
