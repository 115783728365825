<div class="form-group position-relative">
  <label>
    {{ getTranslation(responseField) }}{{ responseField.required ? '*' : '' }}
  </label>

  <input
    class="form-control pl-4"
    [value]="displayValue"
    readonly
    (click)="openPopover($event)"
  />

  <!-- Ícone de Calendário -->
  <ion-icon
    name="calendar"
    class="calendar-icon"
    (click)="openPopover($event)"
  ></ion-icon>

  <ion-popover
    [isOpen]="popoverOpen"
    (didDismiss)="popoverOpen = false"
    cssClass="custom-popover"
  >
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>
            {{ getTranslation(responseField) }}{{ responseField.required ? '*' :
            '' }}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-datetime
          displayFormat="yyyy-MM-dd HH:mm"
          [formControl]="control"
          [readonly]="responseField?.locked || locked"
          locale="pt-PT"
        >
          <span slot="time-label">Horas</span>
        </ion-datetime>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button (click)="cancelPopover()">Cancelar</ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="confirmPopover()" strong>Guardar</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
    </ng-template>
  </ion-popover>

  <div *ngIf="control.touched && control.invalid" class="error-message">
    {{ 'Este campo é obrigatório' | translate }}
  </div>
</div>
