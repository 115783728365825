import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoresService } from 'src/app/services/stores.service';

@Component({
  selector: 'app-expired-products-summary',
  template: ` <ion-card>
    <ion-card-header>
      <div class="card-title-with-icon">
        <ion-icon
          [name]="expiredProducts.length ? 'warning' : 'checkmark-circle'"
          [color]="expiredProducts.length ? 'danger' : 'success'"
        ></ion-icon>
        <ion-card-title color="primary">Produtos Expirados</ion-card-title>
      </div>
    </ion-card-header>

    <ion-card-content>
      <div *ngIf="expiredProducts.length; else noProducts">
        <ion-list>
          <ion-item *ngFor="let product of expiredProducts">
            <ion-label>
              <strong>{{ product.name }}</strong> - Expirado em
              <strong>{{
                product.expirationDate | date : 'YYYY-MM-dd HH:mm'
              }}</strong>
            </ion-label>
          </ion-item>
        </ion-list>

        <!-- Button to navigate to expiring products page -->
        <ion-button
          expand="block"
          (click)="navigateToExpiringProducts()"
          class="navigate-button"
        >
          <ion-icon name="alert-circle-outline" slot="start"></ion-icon>
          Ver Produtos Expirados
        </ion-button>
      </div>

      <ng-template #noProducts>
        <p>Não há produtos expirados.</p>
      </ng-template>
    </ion-card-content>
  </ion-card>`,
  styles: `.navigate-button {
    margin-top: 15px;
    --background: var(--ion-color-primary);
    --color: #fff;
  }
  
  ion-card {
    margin-bottom: 20px;
  }
  
  ion-card-title {
    font-size: 1.4rem;
    font-weight: bold;
  }
  
  ion-item {
    --background: transparent;
  }
  
  ion-label {
    font-size: 1rem;
  }
  
  .card-title-with-icon {
    display: flex;
    align-items: center;
  }
  
  .card-title-with-icon ion-icon {
    margin-right: 8px;
    font-size: 1.4em;
  }
  
  .card-title-with-icon ion-card-title {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
  }
  `,
})
export class ExpiredProductsSummaryComponent {
  @Input() expiredProducts: ProductNameAndExpirationDate[] = [];

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private storeService: StoresService
  ) {}

  async navigateToExpiringProducts() {
    await this.closeAllModals();
    this.router.navigate([
      `/tabs/fields/forms/${this.storeService.store.modules.traceabilityTags.breakProductFormId}`,
    ]);
  }

  async closeAllModals() {
    let topModal = await this.modalCtrl.getTop();
    while (topModal) {
      await topModal.dismiss();
      topModal = await this.modalCtrl.getTop();
    }
  }
}

export interface ProductNameAndExpirationDate {
  name: string;
  expirationDate: number;
}
