<ion-content>
  <div class="buttons-wrapper" *ngIf="store">
    <app-field-button
      *ngIf="store?.modules?.traceabilityTags?.refrigeratedProducts"
      [title]="'Todos os produtos' | translate"
      [buttonStyle]="getModuleStyle(store.modules?.traceabilityTags?.fieldButtonStyle)"
      (click)="pressRefrigeratedProducts()"
    >
    </app-field-button>
    <!-- <app-field-button
      *ngIf="store?.modules?.traceabilityTags?.reception"
      [title]="'Receção' | translate"
      [buttonStyle]="getModuleStyle(store.modules?.traceabilityTags?.fieldButtonStyle)"
      (click)="pressReception()"
    >
    </app-field-button>
    <app-field-button
      *ngIf="store?.modules?.traceabilityTags?.openUnfrozen"
      [title]="'Abrir Descongelados' | translate"
      [buttonStyle]="getModuleStyle(store.modules?.traceabilityTags?.fieldButtonStyle)"
      (click)="pressOpenUnfrozen()"
    >
    </app-field-button>
    <app-field-button
      *ngIf="store?.modules?.traceabilityTags?.allProducts"
      [title]="'Todos os Produtos' | translate"
      [buttonStyle]="getModuleStyle(store.modules?.traceabilityTags?.fieldButtonStyle)"
      (click)="pressAllProducts()"
    >
    </app-field-button> -->
  </div>
</ion-content>
