import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { StoresService } from 'src/app/services/stores.service';

@Component({
  selector: 'app-insert-manual-entry-modal',
  templateUrl: './insert-manual-entry.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InsertManualEntryModal {

  entry = {
    date: undefined,
    expirationDate: undefined,
    lot: undefined,
    title: 'Manual',
    extras: '',
    id: undefined,
    idNumber: undefined
  };

  product: any; // Adjust the type as per your data structure

  searchControl = new FormControl();
  searchResult: any[] = []; // Adjust the type as per your data structure

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private storeService: StoresService
  ) {
    this.product = this.navParams.data['product'];
  }

  close() {
    this.modalController.dismiss();
  }

  pressSubmit() {
    this.modalController.dismiss({ entry: this.entry, newEntry: true });
  }

}
