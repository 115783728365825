<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ 'Pesquise por nome do produto utilizando o campo abaixo.' | translate }}</h3>
          <ion-icon name="close" (click)="close()"></ion-icon>
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <div class="form-group">
            <label for="productName">{{ 'Nome do produto:' }}</label>
            <input id="productName" class="form-control" type="text" [(ngModel)]="productName" [placeholder]="'0'" [formControl]="searchControl" />
          </div>
          <ion-content style="height: 300px; overflow-y: scroll;">
            <ion-list>
              <ion-item *ngFor="let product of searchResult" (click)="pressProduct(product)">
                <ion-label>
                  <h2>Produto: {{ product.name }}</h2>
                  <h2>Versão: {{ product.version }}</h2>
                </ion-label>
                <ion-icon name="arrow-dropright" slot="end"></ion-icon>
              </ion-item>
              <ion-item *ngIf="searchResult.length === 0">
                {{ 'Sem resultados.' | translate }}
              </ion-item>
            </ion-list>
          </ion-content>
          
        </div>
        <div class="modalActions">
          <div class="actionsLeft"></div>
          <div class="actionsRight">
            <!-- 
            <button class="btn btn-ok" (click)="pressSubmit()">
              {{ 'OK' | translate }}
            </button>
            -->
            <button class="btn btn-cancel light-grey" (click)="close()">
              {{ 'CANCEL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  