import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { FieldsService } from 'src/app/services/fields.service';
import { StoresService } from 'src/app/services/stores.service';
import { SyncingService } from 'src/app/services/syncing';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-tag-modal',
  templateUrl: './tag.modal.html',
})
export class TagModalComponent implements OnInit {
  store: any;
  product: any;
  dates: any;

  constructor(
    private nav: NavController,
    private fieldsService: FieldsService,
    private syncing: SyncingService,
    public translate: TranslateService,
    private modalController: ModalController,
    private storesService: StoresService,
    private tasksService: TasksService,
    private navParams: NavParams
  ) {
    this.product = this.navParams.get('product');
  }

  ngOnInit(): void {
    // Add any initialization logic if needed
  }

  close() {
    this.modalController.dismiss();
  }
}
