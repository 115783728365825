import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { UserInfo, getTargetUser } from 'src/app/entities/authentication-info';
import { AuthenticationService } from 'src/app/services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { ProductsService } from 'src/app/services/products.service';
import cuid from 'cuid';
import { PhotoModalComponent } from 'src/app/components/photo/photo.modal';
import { TaskType } from 'src/app/entities/tasks/custom-task-type';
import { deepCopy } from 'src/utils/deepCopy';
import { translate } from 'src/utils/translate.utils';
import {
  arrayAddDistinct,
  arrayRemove,
  arrayRemoveIdx,
} from 'src/utils/array.utils';
import { FabricatedProduct } from 'src/app/entities/product';
import { MissingRequiredFieldsModal } from '../missing-required-fields/missing-required-fields.modal';
import { BatchSelectModal } from 'src/app/components/batch/batch-select-modal/batch-select.modal';
import { ProductListModal } from 'src/app/components/product-list-modal/product-list.modal';
import { AlertService } from 'src/app/services/alert.service';
import {
  TaskResponse,
  Task,
  ResponseField,
  TaskAndResponseObject,
} from 'src/app/entities/tasks/task';
import { FormUtils } from 'src/utils/form.utils';

@Component({
  selector: 'app-create-and-fill-custom-type-task-modal',
  templateUrl:
    './../create-and-fill-custom-type/create-and-fill-custom-type-task.modal.html',
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomTypeChildTaskModal {
  type: TaskType;
  userInfo: UserInfo | null = null; // Initialize as null or undefined
  task: Task;
  response: TaskResponse = {
    isInConformity: false,
    clientDate: 0,
    employeeId: '',
    picturesIds: [],
  };
  form: any; // Adjust type as per your form data type
  extras: any; // Adjust type as per your extras data type
  onTaskClosedSuccessfully: any;
  isClicked = false;
  data: any;
  totalFields: number = 0; // Total de campos com classificação
  questionsAnswered: number = 0; // Total de perguntas respondidas
  progress: string = '0/0'; // Progresso no formato 'respondidas/total'

  childrenTasks: TaskAndResponseObject[] = [];

  prepackageTasks: TaskAndResponseObject[] = [];
  unfreezingTasks: TaskAndResponseObject[] = [];
  warmTemperatureControlTasks: TaskAndResponseObject[] = [];
  fermentationProductsTasks: TaskAndResponseObject[] = [];
  inBeefRegisterTasks: TaskAndResponseObject[] = [];

  precedingTask: Task;
  precedingTaskResponse: TaskResponse;

  initialized: boolean = true;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    protected storesService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private translator: TranslateService,
    private fieldsService: FieldsService,
    private productsService: ProductsService,
    private toastController: ToastController,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {
    this.type = this.navParams.get('type');
    this.precedingTask = this.navParams.get('precedingTask');
    this.precedingTaskResponse = this.navParams.get('precedingTaskResponse');
    this.form = this.navParams.get('form');
    this.extras = this.navParams.get('extras') || {};
    this.onTaskClosedSuccessfully = this.navParams.get(
      'onTaskClosedSuccessfully'
    );
    this.response = this.getResponseFromTaskType();
    const newChildrenTasksIds: string[] = [];
    if (this.response && this.response['childrenTasksIds']) {
      this.childrenTasks = this.response['childrenTasksIds'].map((id: any) => {
        const task = this.tasksService.getTask(id);
        if (task) {
          newChildrenTasksIds.push(id);
          return {
            task: task,
            response: task.responses ? task.responses[0] : undefined,
          };
        } else {
          return { task: undefined, response: undefined };
        }
      });

      this.prepackageTasks = this.childrenTasks
        .filter(
          (t) => t.task && t.task.type === 'prepackagedPDVIntermarcheShort2'
        )
        .map((t) => ({ task: t.task, response: t.response }));

      this.unfreezingTasks = this.childrenTasks
        .filter((t) => t.task && t.task.type === 'unfreezingProductsShort2')
        .map((t) => ({ task: t.task, response: t.response }));

      this.warmTemperatureControlTasks = this.childrenTasks
        .filter(
          (t) =>
            t.task && t.task.type === 'warmTemperatureControlIntermarcheShort2'
        )
        .map((t) => ({ task: t.task, response: t.response }));
    }
    this.response['childrenTasksIds'] = newChildrenTasksIds;
    this.userInfo = this.authenticationService.getUserInfo();
    this.task = {
      id: cuid(), // Use cuid to generate id
      type: this.type.type,
      formId: this.form.id,
      title: this.getTitle(),
      subtitle: this.getSubtitle(),
      startDate: moment().valueOf(),
      endDate: moment().add(1, 'hour').valueOf(),
      visualizationDate: moment().add(1, 'year').valueOf(),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: this.userInfo ? this.userInfo.storeId || '' : '',
        userIds: this.userInfo
          ? getTargetUser(
              this.storesService.store,
              this.authenticationService.getUserInfo()!
            )
          : [],
      },
      documentsIds: [],
      isCustomType: true,
      superType:
        (this.type as unknown as { superType: string }).superType || '',
      productId: this.extras
        ? this.extras.product
          ? this.extras.product.id
          : this.precedingTask.productId
        : this.precedingTask.productId,
      precedingTaskId: this.precedingTask.id,
      isChild: true,
    };
  }

  getTitle() {
    if (typeof this.type === 'object' && 'name' in this.type) {
      return this.type.name || 'Default Title';
    } else if (this.type === 'type') {
      return 'Type Title';
    } else if (this.extras && this.extras.product && this.extras.supplier) {
      return `${this.type}: ${this.extras.supplier.name} - ${this.extras.product.name}`;
    } else if (this.response['Produto'] && this.response['Fornecedor']) {
      let produtoNome = '';

      if (
        typeof this.response['Produto'] === 'object' &&
        this.response['Produto'] !== null
      ) {
        produtoNome = this.response['Produto'].name || '';
      } else {
        produtoNome = this.response['Produto'];
      }

      return `${this.getTranslation(this.type)}: ${
        this.response['Fornecedor'] === 'Outro'
          ? this.response['Outro Fornecedor']
          : this.response['Fornecedor']
      } - ${produtoNome}`;
    } else if (this.type === 'typeFailure') {
      return `${this.type} - ${this.response['Tipo da Avaria']}`;
    } else if (this.type === 'documentName') {
      return `${this.type} - ${this.response['Nome documento']}`;
    } else {
      return `${this.type}`;
    }
  }

  getSubtitle() {
    if (this.type.getSubtitle === 'product') {
      return `${
        this.precedingTaskResponse['Produto']
          ? this.precedingTaskResponse['Produto'].name
          : ''
      }`;
    }
    if (this.type.getSubtitle === 'productFamily') {
      return `${
        this.precedingTaskResponse['Natureza do produto controlado']
          ? this.precedingTaskResponse['Natureza do produto controlado']
          : ''
      }${
        this.precedingTaskResponse['Nome do fornecedor']
          ? ' - ' + this.precedingTaskResponse['Nome do fornecedor']
          : ''
      }`;
    }
    if (this.type.getSubtitle === 'supplier') {
      return `${
        this.precedingTaskResponse['Fornecedor']
          ? this.precedingTaskResponse['Fornecedor']
          : ''
      }`;
    }
    return '';
  }

  async close() {
    await this.modalController.dismiss();
  }

  getInput(responseField: ResponseField) {
    return this.response[responseField.name];
  }

  async pressCamera(response: TaskResponse, responseField: ResponseField) {
    const photos = response[responseField.name as keyof TaskResponse]; // Type assertion

    const photoModal = await this.modalController.create({
      component: PhotoModalComponent,
      componentProps: {
        photos: photos,
      },
    });

    photoModal.onDidDismiss().then((result: any) => {
      if (result.data) {
        this.response[responseField.name as keyof TaskResponse] = result.data;
      } else {
        this.response[responseField.name as keyof TaskResponse] = [];
      }
    });

    return await photoModal.present();
  }

  async pressListCamera(object: any, propertyKey: string) {
    const photos = object[propertyKey];

    const photoModal = await this.modalController.create({
      component: PhotoModalComponent,
      componentProps: {
        photos: photos,
      },
    });

    photoModal.onDidDismiss().then((result) => {
      if (result.data) {
        object[propertyKey] = result.data;
      } else {
        object[propertyKey] = [];
      }
    });

    return await photoModal.present();
  }

  async pressSubmit() {
    if (this.isClicked) return;
    this.isClicked = true;

    const missingRequiredFields = this.getMissingRequiredFields();
    if (missingRequiredFields.length > 0) {
      await this.presentMissingRequiredFieldsModal(missingRequiredFields);
      this.isClicked = false;
      return;
    }

    this.successfullySubmit();
  }

  async presentMissingRequiredFieldsModal(
    missingRequiredFields: ResponseField[]
  ) {
    const modal = await this.modalController.create({
      component: MissingRequiredFieldsModal,
      componentProps: {
        missingRequiredFields: missingRequiredFields,
      },
    });
    await modal.present();
  }

  private getMissingRequiredFields(): ResponseField[] {
    // Check if "Tarefa terminada" is a required field and if it's missing
    if (
      this.type.metadata.responseFields
        .map((rf) => rf.name)
        .indexOf('Tarefa terminada') !== -1
    ) {
      if (!this.response['Tarefa terminada']) return [];
    }

    // Filter response fields to find those that are required and missing
    return this.type.metadata.responseFields.filter((rf) => {
      if (rf.required && this.processCondition(rf)) {
        if (rf.type === 'text' && !this.response[rf.name]) {
          return true;
        }
        if (rf.type === 'boolean' && !this.response[rf.name]) {
          return true;
        } else if (
          rf.type === 'photo' &&
          (!this.response[rf.name] || this.response[rf.name].length === 0)
        ) {
          return true;
        } else if (
          (rf.type === 'number' && this.response[rf.name] === undefined) ||
          this.response[rf.name] === null ||
          this.response[rf.name] === ''
        ) {
          return true;
        } else if (rf.type === 'date' && !this.response[rf.name]) {
          return true;
        } else if (
          rf.type === 'checklist' &&
          (!this.response[rf.name] || this.response[rf.name].length <= 0)
        ) {
          return true;
        }
      }
      if (rf.type === 'list') {
        // Check if required fields in list type are missing
        for (let i = 0; i < this.response[rf.name].length; ++i) {
          const row = this.response[rf.name][i];
          if (!row.name) return true;
          if (!row.quantity) return true;
          if (!row.unit) return true;
          if (
            this.type.type !== 'technicalSheetIntermarche' &&
            this.type.type !== 'technicalSheetIM2' &&
            this.type.type !== 'serunionTechnicalSheet' &&
            this.type.type !== 'greenTechnicalSheet' &&
            this.type.type !== 'technicalSheetAL' &&
            this.type.type !== 'technicalSheetLJ' &&
            this.type.type !== 'industrialManufacturingIM' &&
            !row.selectedProductInfo
          )
            return true;
        }
      }
      if (rf.type === 'group') {
        // Handle required fields in group type (if applicable)
      }
      return false;
    });
  }

  successfullySubmit() {
    // Update task properties
    this.task.title = this.getTitle();
    this.task.subtitle = this.getSubtitle();
    this.task.startDate = moment(this.task.startDate).valueOf();
    this.task.endDate = moment(this.task.endDate).valueOf();
    this.task.visualizationDate = moment(this.task.visualizationDate).valueOf();

    // Dismiss the modal and pass task and response data back
    this.modalController.dismiss({ task: this.task, response: this.response });
    this.isClicked = false;
  }

  getResponseFromTaskType() {
    const response: TaskResponse = {
      picturesIds: [],
      non_conformity: '',
      causes: '',
      correction: '',
      commentary: '',
      ncCommentary: '',
      isInConformity: false,
      clientDate: 0,
      employeeId: '',
    };

    // Autofill response based on response fields metadata
    this.type.metadata.responseFields.forEach((rf) => {
      this.autofillResponse(response, rf);
      if (response[rf.name] !== undefined || rf.autofill) return;
      if (rf.type === 'boolean') {
        response[rf.name] = false;
      } else if (rf.type === 'date') {
        response[rf.name] = moment().seconds(0).format('YYYY-MM-DD');
      } else if (rf.type === 'date' && rf.subtype === 'noHour') {
        response[rf.name] = moment().seconds(0).format('YYYY-MM-DD');
      } else if (rf.type === 'date' && rf.subtype !== 'noHour') {
        response[rf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (rf.type === 'list') {
        response[rf.name] = [
          {
            name: '',
            quantity: 1,
            unit: 'kg',
          },
        ];
      } else if (rf.type === 'checklist') {
        response[rf.name] = [];
      } else if (rf.type === 'group') {
        response[rf.name] = [this.autofillSubfields(rf.subfields)];
      } else {
        response[rf.name] = '';
      }
    });

    return response;
  }

  autofillSubfields(subfields: ResponseField[] | undefined) {
    const response: any = {}; // Use `any` type for `response` to handle dynamic properties
    if (!subfields) {
      return response; // Return empty response if subfields are undefined
    }
    subfields.forEach((sf) => {
      if (response[sf.name] !== undefined || sf.autofill) return; // Check if property already exists or should be autofilled
      if (sf.type === 'boolean') {
        response[sf.name] = false;
      } else if (sf.type === 'date') {
        response[sf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (sf.type === 'list') {
        response[sf.name] = [
          {
            name: '',
            quantity: 1,
            unit: 'kg',
          },
        ];
      } else if (sf.type === 'checklist') {
        response[sf.name] = [];
      } else if (sf.type === 'group') {
        response[sf.name] = [this.autofillSubfields(sf.subfields)]; // Recursively fill nested group structures
      } else if (sf.type === 'photo') {
        response[sf.name] = [];
      } else {
        response[sf.name] = ''; // Default case, handles string or other types
      }
    });
    return response;
  }

  autofillResponse(response: any, responseField: any, task?: any) {
    const product =
      (task?.productId
        ? this.productsService.getProductById(task.productId)
        : null) || (this.extras ? this.extras.product : null);
    const batch = task?.batch || null;

    // Check if task has specific value for the responseField
    if (task && task[responseField.name]) {
      response[responseField.name] = task[responseField.name];
    } else if (!response[responseField.name]) {
      // Autofill based on metadata if responseField value is empty
      if (
        responseField.autofill &&
        this.processCondition(responseField, response)
      ) {
        if (responseField.autofill === 'NOW') {
          if (responseField.type === 'date') {
            response[responseField.name] = moment()
              .seconds(0)
              .format('YYYY-MM-DD');
          } else if (responseField.type === 'datetime') {
            response[responseField.name] = moment()
              .seconds(0)
              .format('YYYY-MM-DDTHH:mm:ss');
          } else {
            response[responseField.name] = moment().format('HH:mm');
          }
        } else if (responseField.autofill === 'NOFILL') {
          response[responseField.name] = '';
        } else if (responseField.autofill === 'product') {
          if (this.extras.product || product) {
            response[responseField.name] = product
              ? product.name
              : this.extras.product.name;
          }
        } else if (responseField.autofill === 'brand') {
          if (this.extras.product || product) {
            response[responseField.name] = product
              ? product.brand
                ? product.brand
                : ''
              : this.extras.product.brand
              ? this.extras.product.brand
              : '';
          }
        } else if (responseField.autofill === 'supplier') {
          let supplierName =
            this.extras.supplier?.name ??
            product?.supplierName ??
            product?.supplier?.name;

          if (Array.isArray(supplierName)) {
            if (supplierName.length === 1) {
              response[responseField.name] = supplierName[0];
            } else if (supplierName.length > 1) {
              // Se quiser que o usuário selecione, defina o campo como 'select'
              responseField.type = 'select';
              responseField.selectOptions = supplierName;
              response[responseField.name] = '';
            } else {
              response[responseField.name] = '';
            }
          } else if (typeof supplierName === 'string') {
            response[responseField.name] = supplierName;
          } else {
            response[responseField.name] = '';
          }
        } else if (responseField.autofill === 'origin') {
          if (this.extras.product) {
            response[responseField.name] = this.extras.product.origin;
          }
        } else if (responseField.autofill === 'productFamily') {
          if (this.extras) {
            response[responseField.name] = this.extras.productFamily;
          }
        } else if (responseField.autofill === 'barcode') {
          if (this.extras || product) {
            response[responseField.name] = product
              ? product.barcode.itm8
                ? product.barcode.itm8
                : product.barcode.value
                ? product.barcode.value
                : this.extras.barcode
              : this.extras.barcode;
            if (
              response['Fornecedor'] === 'Fabricado' &&
              !response[responseField.name]
            ) {
              response[responseField.name] = '00000000';
            }
          }
        } else if (responseField.autofill === 'expirationDate') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'nextExpirationDate') {
          response[responseField.name] = this.extras.product
            ? this.extras.product.nextExpirationDates
              ? this.extras.product.nextExpirationDates[0]
              : null
            : null;
        } else if (responseField.autofill === 'nextNextExpirationDate') {
          response[responseField.name] = this.extras.product
            ? this.extras.product.nextExpirationDates
              ? this.extras.product.nextExpirationDates[1]
              : null
            : null;
        } else if (responseField.autofill === 'NONE') {
          response[responseField.name] = null;
        } else {
          response[responseField.name] = responseField.autofill;
        }
      }

      // Autofill from product technical sheet if available
      if (product && product.technicalSheet) {
        if (responseField.name === 'O produto vai ser conservado no quente?') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (
          responseField.name === 'Durabilidade de vida (Balcão tradicional)'
        ) {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'DLC para pré-embalamento') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Tempo de preparação') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Material de embalagem') {
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Para embalar?') {
          // Handle specific case for 'Para embalar?' field
          // response[responseField.name] = product.technicalSheet[responseField.name];
        } else if (responseField.name === 'checklist') {
          // Handle checklist type fields
          response[responseField.name] =
            product.technicalSheet[responseField.name];
        } else if (responseField.name === 'list') {
          // Handle list type fields
          response[responseField.name] = deepCopy(
            product.technicalSheet[responseField.name]
          );
        }
      }

      // Autofill from batch if available
      if (
        batch ||
        (this.extras && this.extras.batch && responseField.type === 'batch')
      ) {
        response[responseField.name] = batch || this.extras.batch;
      } else if (
        this.extras &&
        this.extras.product &&
        responseField.type === 'product'
      ) {
        response[responseField.name] = this.extras.product;
      } else if (
        this.precedingTaskResponse &&
        this.precedingTaskResponse[responseField.name]
      ) {
        response[responseField.name] =
          this.precedingTaskResponse[responseField.name];
      }
    }

    // Handle hiding conditions
    if (
      responseField.autofill &&
      responseField.isHiding &&
      !response[responseField.name] &&
      responseField.autofill !== 'NONE'
    ) {
      this.toggleOffHideGroupFields(
        responseField,
        this.type.metadata.responseFields
      );
    }
  }

  processCondition(field: any, response?: TaskResponse): boolean {
    response = response || this.response;

    // Check if the condition is valid
    if (!field.condition) return true;

    const conditions = field.condition.split(', ');

    // Check if conditions array is valid
    if (conditions.length === 0) return true;

    return (
      conditions.filter((c: any) => {
        // Check for OR condition
        if (c.includes('||')) {
          const orConditions = c.split('||');
          return orConditions.some((orCond: any) => {
            const [left, right] = orCond.split('===').map((s: any) => s.trim());
            return response![left] === right;
          });
        }
        // Original conditions checking...
        else if (c.includes('===')) {
          const sides = c.split('===').map((s: any) => s.trim());
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response![leftSide] === rightSide;
        } else if (c.includes('!==')) {
          const sides = c.split('!==').map((s: any) => s.trim());
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response![leftSide] !== rightSide;
        } else if (c === 'expirationDate') {
          if (
            response!['Data de validade'] &&
            moment(response!['Data de validade']).isBefore(
              moment().add(4, 'days')
            )
          ) {
            return true;
          }
          return false;
        }
        if (c[0] === '!') {
          const condition = c.substring(1, c.length);
          return !response![condition];
        }
        return !!response![c];
      }).length === conditions.length
    );
  }

  toggleHideGroupFields(
    responseField: any,
    index: number,
    responseFields: any[]
  ) {
    if (!responseField.isGrouper) {
      return;
    }
    this.toggleHideGroupsField2(responseField, index, responseFields);
  }

  toggleHideGroupsField2(
    responseField: any,
    index: number,
    responseFields: any[]
  ) {
    for (let i = index; i < responseFields.length; ++i) {
      if (responseFields[i] !== responseField && responseFields[i].isGrouper) {
        return;
      }
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }
  toggleHideGroupsField3(
    responseField: any,
    index: number,
    responseFields: any[]
  ) {
    for (let i = index + 1; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) {
        return;
      }
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleOffHideGroupFields(responseField: any, responseFields: any[]) {
    let startingIndex: number | undefined;
    let isValid = false;

    for (let i = 0; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) {
        startingIndex = i;
      }
      if (responseFields[i] === responseField) {
        isValid = true;
        break;
      }
    }

    if (!isValid || startingIndex === undefined) {
      return;
    }

    this.toggleHideGroupsField3(responseField, startingIndex, responseFields);
  }

  addToList(responseField: ResponseField): void {
    if (!this.response[responseField.name]) {
      this.response[responseField.name] = [];
    }
  
    const newItem: any = {
      name: '',
      quantity:  1,
      unit: 'kg',
    };
  
    if (this.type?.type === 'serunionProductionNewModel') {
      newItem.photos = [];
      newItem.isNew = true;
    }
  
  
    this.response[responseField.name].push(newItem);
  }

  async removeFromList(responseField: any, index: number) {
    if (this.response[responseField.name].length <= 1) {
      const toast = await this.toastController.create({
        message: 'A lista tem que conter pelo menos 1 ingrediente.',
        duration: 2000,
        position: 'top',
        buttons: [
          {
            side: 'end',
            text: 'Fechar',
            handler: () => {
              // Handle close button action if needed
            },
          },
        ],
      });
      toast.present();
      return;
    }
    this.response[responseField.name] = arrayRemoveIdx(
      this.response[responseField.name],
      index
    );
  }

  addOrRemoveArray(event: any, value: any, responseArray: any[]) {
    if (event.checked) {
      arrayAddDistinct(responseArray, value);
    } else if (!event.checked) {
      arrayRemove(responseArray, value);
    }
  }

  onChangeNumberInput(value: number, responseField: any) {
    const product = this.extras.product;
    if (responseField.name === 'Quantidade fabricada') {
      if (this.response['Lista de ingredientes']) {
        this.response['Lista de ingredientes'].forEach((i: any) => {
          if (
            product.technicalSheet['Lista de ingredientes'] &&
            product.technicalSheet['Lista de ingredientes'].find(
              (p: any) => p.name === i.name
            )
          ) {
            i.quantity =
              product.technicalSheet['Lista de ingredientes'].find(
                (p: any) => p.name === i.name
              ).quantity * value;
          } else {
            i.quantity = i.quantity * value;
          }
        });
      }
    }
  }

  getSelectOptions(responseField: ResponseField, response?: any) {
    if (!response) {
      response = this.response;
    }

    if (responseField.selectOptionsGetter === 'getFormTasks.info') {
      const form = this.fieldsService.getFormByName(response['Área']);
      const tasks = this.tasksService.getTasksFromForm(form.id);
      return tasks.map(
        (t) =>
          `${t.title}${t.subtitle ? ` - ${t.subtitle}` : ''} - ${moment(
            t.startDate
          ).format('YYYY-MM-DD HH:mm')} - ${t.id}`
      );
    } else if (
      responseField.selectOptionsGetter === 'getRastreabilidadeProductTasks'
    ) {
      const tasks = this.tasksService.getTasksByType(
        'unpackgedProductsIntermarche2'
      );
      return tasks.map(
        (t) =>
          `${t.subtitle} - ${moment(t.startDate).format(
            'YYYY-MM-DD HH:mm'
          )} - ${t.id}`
      );
    } else if (
      responseField.selectOptionsGetter === 'getFabricatedProductTasks'
    ) {
      const tasks = this.tasksService.getTasksByType('productionIM2');
      return tasks.map(
        (t) =>
          `${t.subtitle} - ${moment(t.startDate).format(
            'YYYY-MM-DD HH:mm'
          )} - ${t.id}`
      );
    } else if (responseField.selectOptionsGetter === 'store.users') {
      return this.authenticationService.getStoreUsersUsername();
    } else if (responseField.selectOptionsGetter === 'batchPreparationDates') {
      let batch;
      if (this.task && this.task.batch) {
        batch = this.task.batch;
      } else if (this.extras && this.extras.batch) {
        batch = this.extras.batch;
      }
      return batch ? batch.preparationDates || [] : [];
    } else if (responseField.selectOptionsGetter === 'destinationNF') {
      return (
        this.fieldsService
          .getClosestHerdadeOrLagarField(this.form.id)
          .buyers.map((b: any) => b.fullName) || []
      );
    } else if (
      responseField.selectOptionsGetter === 'store.modules.gasMixtures'
    ) {
      return this.storesService.store.modules.gasMixtures;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.packageMaterial'
    ) {
      return this.storesService.store.modules.packageMaterial;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.expiration.productDestinations'
    ) {
      return this.storesService.store.modules.expiration.productDestinations;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.desinfectionProducts'
    ) {
      return this.storesService.store.modules.desinfectionProducts;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.pestSector'
    ) {
      return this.storesService.store.modules.pestSector;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.goodsReception.directSupplierList'
    ) {
      return (
        this.storesService.store.modules.goodsReception.directSupplierList || []
      );
    } else if (responseField.selectOptionsGetter === 'product.supplier') {
      const product = this.task?.productId
        ? this.productsService.getProductById(this.task.productId)
        : null;

      let supplierNames = product?.supplierName;

      if (!supplierNames) {
        return [];
      } else if (Array.isArray(supplierNames)) {
        return supplierNames;
      } else if (typeof supplierNames === 'string') {
        return [supplierNames];
      } else {
        return [];
      }
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.goodsReception.nonConformitiesList'
    ) {
      return (
        this.storesService.store.modules.goodsReception.nonConformitiesList ||
        []
      );
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.anomaliesReport.emailsTo'
    ) {
      return this.storesService.store.modules.anomaliesReport.emailsTo;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.supportRequests.emailsTo'
    ) {
      return this.storesService.store.modules.supportRequests.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.haccp'
    ) {
      return this.storesService.store.modules.emailsReport.haccp.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.help'
    ) {
      return this.storesService.store.modules.emailsReport.help.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.docs'
    ) {
      return this.storesService.store.modules.emailsReport.docs.emailsTo;
    } else if (responseField.selectOptionsGetter === 'getUsers.name') {
      const users = this.authenticationService.getStoreUsersUsername();
      return users;
    } else if (
      responseField.selectOptionsGetter === 'getTasksResponseQuiz.name'
    ) {
      const formQuiz = response['Selecione a área'].trim();
      const task = this.tasksService.getTasksToReopen(formQuiz);
      return task;
    } else if (responseField.selectOptionsGetter === 'getFolder.name') {
      const folder = this.storesService.getFolderTitles();
      return folder;
    } else if (responseField.selectOptionsGetter === 'getReportFolder.name') {
      const folder = this.storesService.getReportFolderTitles();
      return folder;
    } else if (responseField.selectOptionsGetter === 'getReportFiles.name') {
      const folderTitle = response['Pasta do relatório'].trim();
      const files = this.storesService.getReportFilesTitles(folderTitle);
      return files;
    } else if (
      responseField.selectOptionsGetter === 'getTechnicalSheets.name'
    ) {
      const products = this.productsService
        .getFabricatedProducts()
        .filter(
          (p): p is FabricatedProduct =>
            (p as FabricatedProduct).hasTechnicalSheet
        ) // Narrow down to FabricatedProduct
        .map(
          (p: FabricatedProduct) =>
            `${p.technicalSheet['Designação do produto']} - ${p.technicalSheet['Versão']} - ${p.id}`
        );
      return products;
    } else if (responseField.selectOptionsGetter === 'getIMSections.name') {
      const users = this.authenticationService.getStoreUsersUsername();
      return users.filter((s) => s.toLowerCase().indexOf('admin') === -1);
    } else if (this.type.type === 'trainingIbersolKFC') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PFI BÁSICOS') {
          return [
            'Orientação e Segurança no Trabalho',
            'Segurança Alimentar',
            'Introdução às funções da KFC',
            'Valores HWWT²',
            'Exame Básicos',
          ];
        } else if (response && response['Módulo'] === 'PFO BÁSICOS') {
          return ['Exame Básicos'];
        } else if (response && response['Módulo'] === 'PFI CAIXA (FOH)') {
          return [
            'Hospitalidade',
            'Funções Fusion',
            'Registo do Pedido',
            'Preparação do Pedido',
            'Entrega do Pedido',
            'Gestão de Clientes',
            'Exame FOH',
            'Observation Checklist FOH',
          ];
        } else if (response && response['Módulo'] === 'PFO CAIXA (FOH)') {
          return ['Exame FOH', 'Observation Checklist FOH'];
        } else if (response && response['Módulo'] === 'PFI COZINHA (BOH)') {
          return [
            'Manipulação do Frango',
            'Marinar',
            'Receita Original',
            'Receita Crocante',
            'Exame BOH',
            'Observation Checklist BOH',
          ];
        } else if (response && response['Módulo'] === 'PFO COZINHA (BOH)') {
          return ['Exame BOH', 'Observation Checklist BOH'];
        } else if (response && response['Módulo'] === 'PFI PACK (MOH)') {
          return [
            'FTF - freezer to fryer',
            'Preparação de Acompanhamentos',
            'Sanduíches',
            'Introdução a Embalar',
            'Exame MOH',
            'Observation Checklist MOH',
          ];
        } else if (response && response['Módulo'] === 'PFO PACK (MOH)') {
          return ['Exame MOH', 'Observation Checklist MOH'];
        } else if (response && response['Módulo'] === 'PFI DRIVE THRU') {
          return [
            'Drive Thru',
            'PT KFC EXAM DRIVE THRU',
            'Observation Checklist Drive Thru',
          ];
        } else if (response && response['Módulo'] === 'PFO DRIVE THRU') {
          return ['PT KFC EXAM DRIVE THRU', 'Observation Checklist Drive Thru'];
        } else if (response && response['Módulo'] === 'PFI SALA') {
          return ['Empregado de Sala', 'Exame Sala'];
        } else if (response && response['Módulo'] === 'PFO SALA') {
          return ['Exame Sala'];
        } else if (
          response &&
          response['Módulo'] === 'PFI  LIDERAR UM TURNO (LAS)'
        ) {
          return [
            'Introdução ao LAS',
            'Experiência do cliente',
            'Instrução',
            'Planeamento de Produto',
            'Gestão de Inventário',
            'Gestão de Desempenho',
            'Qualidade de Produto',
            'Tudo sob Controlo',
            'Serviço com velocidade',
            'Segurança',
            'Exame LAS',
            'Observation Checklist LAS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO  LIDERAR UM TURNO (LAS)'
        ) {
          return ['Exame LAS', 'Observation Checklist LAS'];
        } else if (response && response['Módulo'] === 'PFI Alergeneos') {
          return ['Alergeneos'];
        } else if (
          response &&
          response['Módulo'] === 'PFI Gestão de crises (Loja)'
        ) {
          return ['Gestão de crises (Loja)'];
        } else if (response && response['Módulo'] === 'PFI Kiosks') {
          return ['Kiosks'];
        } else if (
          response &&
          response['Módulo'] === 'PFI  LIDERAR UMA LOJA (LAR)'
        ) {
          return [
            'Introdução ao LAR',
            'Impulsionar Vendas',
            'Como formar a sua equipa',
            'Previsão',
            'Gerir Pessoal',
            'Liderança',
            'GES',
            'ROCC',
            'Desafio Final',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO  LIDERAR UMA LOJA (LAR)'
        ) {
          return ['Desafio Final'];
        } else if (response && response['Módulo'] === 'PFI RGM ACADEMY') {
          return [
            'Liderança com o Coração',
            'Trabalhar com Eficiência',
            'Introdução a RGM',
            'Planeamento de Brigadas',
            'Coaching com o Coração',
            'Formar e Desenvolver a tua Equipa',
            'A Experiência do Cliente',
            'Protecção da Marca',
            'Gerir com se fosse Teu',
            'Gestão Financeira',
            'Controlo de Custos',
            'Previsão de Vendas e Horários',
            'Cria uma Cultura de Família e Diversão',
            'Trabalhar Melhor, Juntos',
            'Ótima comunicação',
            'Celebra o Sucesso',
          ];
        }
      } else if (responseField.name === 'Módulo') {
        let modules: string[] | undefined = responseField.selectOptions; // Explicitly define modules as string[] | undefined

        if (
          response &&
          (response['Nível interno'] === 'OPERADOR(A)' ||
            (response && response['Nível interno'] === 'OPERADOR(A) DD'))
        ) {
          return (
            modules?.filter((s) => {
              return (
                s !== 'PFI  LIDERAR UM TURNO (LAS)' &&
                s !== 'PFO  LIDERAR UM TURNO (LAS)' &&
                s !== 'PFI  LIDERAR UMA LOJA (LAR)' &&
                s !== 'PFO  LIDERAR UMA LOJA (LAR)' &&
                s !== 'PFI RGM ACADEMY'
              );
            }) || []
          );
        }

        if (
          response &&
          (response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B')
        ) {
          return (
            modules?.filter((s) => {
              return (
                s !== 'PFO  LIDERAR UMA LOJA (LAR)' && s !== 'PFI RGM ACADEMY'
              );
            }) || []
          );
        }
      }
    } else if (this.type.type === 'trainingIbersolPH') {
      if (responseField.name === 'Ação') {
        if (
          response &&
          response['Módulo'] === 'PFO INICIAL PROGRAMAS FORMAÇÃO SECÇÕES'
        ) {
          return [
            'PROGRAMA FORMAÇÃO PRODUÇÃO',
            'PROGRAMA FORMAÇÃO RESTAURANTE',
            'PROGRAMA FORMAÇÃO DELIVERY',
            'PROGRAMA FORMAÇÃO BALCÃO',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL CLEAN 2 SAFE'
        ) {
          return ['CLEAN 2 SAFE - PH'];
        } else if (response && response['Módulo'] === 'PFI ACOLHIMENTO') {
          return [
            'INTEGRAÇÃO',
            'SEGURANÇA ALIMENTAR',
            'SEGURANÇA E SAÚDE NO TRABALHO',
          ];
        } else if (response && response['Módulo'] === 'PFO CONTINUA HSA/SST') {
          return ['RECERTIFICAÇÃO HSA -2022', 'RECERTIFICAÇÃO SST-2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA PROGRAMAS FORMAÇÃO SECÇÕES'
        ) {
          return [
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT PRODUÇÃO - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT ATENDIMENTO NO RESTAURANTE - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT ATENDIMENTO AO BALCÃO - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT DELIVERY - 2022',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONT. CLEAN 2 SAFE REC.'
        ) {
          return ['RECERTIFICAÇÃO CLEAN TO SAFE - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL HSA @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL HSA @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL SST @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL SST @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO @ LEADING A SHIFT - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA HSA @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO  HSA @ LEADING A SHIFT - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA SST @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO  SST @ LEADING A SHIFT - 2022'];
        }
      }
    } else if (this.type.type === 'trainingIbersolMM') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'BASICS') {
          return [
            'WELCOME',
            'WORK SAFETY',
            'FOOD SAFETY',
            'CLEAN AND SAFE',
            'FOOD ALLERGIES AND INTOLERENCES',
            'CERTIFICAÇÃO INICIAL BASICS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FRONT OF THE HOUSE (FOH)'
        ) {
          return [
            'SERVICE LOVERS',
            'GUEST EXPERIENCE',
            'SALES OPERATIONS',
            'CERTIFICAÇÃO INICIAL FOH',
          ];
        } else if (
          response &&
          response['Módulo'] === 'BACK OF THE HOUSE (BOH)'
        ) {
          return ['FOOD LOVERS'];
        }
      }
    } else if (this.type.type === 'trainingIbersolPANS') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PANS_PFI_BASICS') {
          return [
            'PANS_BASICS_ACOLHIMENTO',
            'SEGURANÇA E SAÚDE NO TRABALHO : PRINCIPIOS BÁSICOS',
            'ACOLHIMENTO E INTEGRAÇÃO',
            'SEGURANÇA ALIMENTAR : PRINCIPIOS BÁSICOS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_BACK OF HOUSE'
        ) {
          return [
            'PANS_BOH_FOLHA DE PREPARAÇÃO',
            'PANS_BOH_PÃO',
            'PANS_BOH_SANDES',
            'PANS_BOH_PRODUTOS FRITOS',
            'PANS_BOH_SALADAS E SOPAS',
            'PANS_BOH_SOBREMESAS',
            'PANS_BOH_PREPARAÇÃO',
            'PANS_BOH_SUMOS',
            'PANS_BOH_MAKE-TABLE',
            'PANS_BOH_PASTELARIA E SALGADOS',
            'PANS_CERTIFICAÇÃO_BOH',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_FRONT OF HOUSE'
        ) {
          return [
            'PANS_FOH_DISTRIBUIÇÃO AO CLIENTE',
            'PANS_FOH_INTERAÇÃO COM O CLIENTE',
            'PANS_FOH_REGISTO DE POS',
            'PANS_FOH_CAFÉ PANS',
            'PANS_CERTIFICAÇÃO_FOH',
            'PANS APP',
            'Delivery',
          ];
        } else if (response && response['Módulo'] === 'PANS_PFC_NOVIDADE') {
          return ['PANS_INVERNO_2023', 'PANS_PRIMAVERA_2023'];
        } else if (response && response['Módulo'] === 'PANS_PFC_BASICS') {
          return [
            'RECERTIFICAÇÃO BASICS 3º TRIMESTRE 2022',
            'RECERTIFICAÇÃO BASICS 4º TRIMESTRE 2022',
          ];
        } else if (response && response['Módulo'] === 'PANS_PFC_OPERAÇÕES') {
          let ret: string[] = [];
          if (response['Nível interno'] === 'OPERADOR(A)') {
            ret = ret.concat([
              'RECERTIFICAÇÃO CHECKLIST OPERAÇÃO 2023',
              'Prova Recertificação Operadores 1º semestre 2023',
              'Prova Recertificação Operadores 2º semestre 2023',
            ]);
          }
          if (
            response['Nível interno'] !== 'GESTOR(A) UNIDADE' &&
            response['Nível interno'] !== 'DIRECTOR(A) UNIDADE - B' &&
            response['Nível interno'] !== 'DIRECTOR(A) UNIDADE - C'
          ) {
            ret = ret.concat([
              'Fazemos sempre melhor-Normas apresent_Op-GT',
              "Equipamentos de trabalho e uso de EPI's",
            ]);
          }
          return ret;
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_EQUIPAS DIREÇÃO'
        ) {
          let ret: string[] = [];
          if (
            response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat(['Prova de Certificação de Gestão de Turno 2023']);
          }
          if (
            response['Nível interno'] !== 'OPERADOR(A)' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - A' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Certificação de Direção de Unidade 2023',
            ]);
          }
          return ret;
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFC_EQUIPAS DIREÇÃO'
        ) {
          let ret: string[] = [];
          if (
            response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Recertificação de Gestão de Turno 2023',
            ]);
          }
          if (
            response['Nível interno'] !== 'OPERADOR(A)' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - A' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Recertificação de Direção de Unidade 2023',
            ]);
          }
          if (response['Nível interno'] !== 'OPERADOR(A)') {
            ret = ret.concat([
              'Recertificação Checklist Equipas de Direção 2023',
              'Prova Recertificação Equipas Direção 2023',
            ]);
          }
          return ret;
        }
      } else if (responseField.name === 'Módulo') {
        let modules: string[] = responseField.selectOptions ?? []; // Initialize modules with an empty array if responseField.selectOptions is undefined

        if (response && response['Nível interno'] === 'OPERADOR(A)') {
          return modules.filter((s) => {
            return (
              s !== 'PANS_PFI_EQUIPAS DIREÇÃO' &&
              s !== 'PANS_PFC_EQUIPAS DIREÇÃO'
            );
          });
        } else if (
          response &&
          (response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'DIRECTOR(A) UNIDADE - B' ||
            response['Nível interno'] === 'DIRECTOR(A) UNIDADE - C')
        ) {
          return modules.filter((s) => {
            return s !== 'PANS_PFC_OPERAÇÕES';
          });
        }
      }
    } else if (this.type.type === 'trainingIbersolMIIT') {
      if (responseField.name === 'Ação') {
        if (
          response &&
          response['Módulo'] === 'FORMAÇÃO HIGIENE E SEGURANÇA ALIMENTAR MIIT'
        ) {
          return [
            'Tipos de contaminações e como evitá-las',
            'Norma de Higiene - Manipuladores',
            'Norma de Higiene - Lavagem das mãos',
            'Norma de Higiene- Visitantes',
            'Norma de Higiene - Diferença entre limpezas diárias e periódicas',
            'Norma de Higiene - Como limpar',
            'Norma de Higiene - Quem verifica',
            'Alergias e Intolerâncias Alimentares',
            'Acrilamida nos Alimentos',
            'Check list - Lavagem das mãos',
            'Check list - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO SEGURANÇA E SAÚDE NO TRABALHO MIIT'
        ) {
          return [
            'Riscos no posto de trabalho',
            'Organização da Segurança contra Incêndios',
            'Combate a incêndios',
            'Evacuação',
            'Primeiros Socorros',
            'Acidentes de trabalho',
            'Medicina no trabalho',
            'Checklist - Observação ao turno',
          ];
        } else if (response && response['Módulo'] === 'GRELHA') {
          return [
            'Grelhar',
            'Retenção',
            'Abertura e Fecho de grelha',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'PREPARAÇÃO DE CARNES') {
          return [
            'Preparação de carnes',
            'Preparação de temperos para a carne',
            'Limpeza e mantenção da área de produção',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] === 'CONFEÇÃO DE ACOMPANHAMENTOS E EMPRATAMENTO'
        ) {
          return [
            'Regeneração de Molhos',
            'Preparação do acompanhamento Batata rústica ',
            'Preparação do acompanhamento Arroz aromatizado',
            'Preparação dos acompanhamentos de frutas e legumes',
            'Preparação do acompanhamento Ovo estrelado',
            'Montagem da maketable e banho-maria, empratamento e entrega',
            'Confeção de Pão',
            'Outros acompanhamentos',
            'Limpeza e manutenção da área de confeção e empratamento',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] ===
            'PREPARAÇÃO DE VEGETAIS, FRUTA, SOBREMESAS, BEBIDAS E MOLHOS'
        ) {
          return [
            'Higienização de frutas, legumes e ervas aromáticas',
            'Preparação do Acompanhamento Salada',
            'Pré - preparação do Acompanhamento Legumes grelhados',
            'Preparação das frutas',
            'Preparação das sobremesas doces',
            'Preparação de bebidas',
            'Preparação de Molhos',
            'Limpeza e manutenção da área de preparação',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'COPA') {
          return [
            'Gestão de resíduos e óleo alimentar',
            'Lavagem de louça, utensílios, armazenamento e montagem de tabuleiros',
            'Limpeza e manutenção da máquina de lavar',
            'Limpeza e manutenção da área da copa',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'CAIXA E BALCÃO') {
          return [
            'Apresentação e farda',
            'Caixa',
            'Entrega de pedidos',
            'Abertura e fecho do balcão',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'HOSPEDEIRA/HOSPEDEIRO') {
          return [
            'Apresentação e farda',
            'Acolhimento do cliente',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        }
      }
    } else if (this.type.type === 'trainingIbersolRIBS') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'ACOLHIMENTO') {
          return ['BEM VINDO AO RIBS'];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO HIGIENE E SEGURANÇA ALIMENTAR RIBS'
        ) {
          return [
            'Introdução',
            'Segurança Alimentar',
            'Tipos de contaminações e como evitá-las',
            'Normas de Higiene - Manipuladores',
            'Normas de Higiene - Lavagem das mãos',
            'Normas de Higiene - Visitantes',
            'Normas de higiene - Diferença entre Limpeza e Higienização',
            'Normas de higiene - O que Limpar',
            'Normas de higiene - Diferença entres limpezas diárias e periódicas',
            'Normas de higiene - Como limpar',
            'Normas de higiene - Quem Verifica',
            'Alergias e intolerâncias Alimentares',
            'Acrilamida nos alimentos',
            'Check List - Lavagem das mãos',
            'Check List - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO SEGURANÇA E SAÚDE NO TRABALHO RIBS'
        ) {
          return [
            'Noções Básicas de Segurança e Saúde no Trabalho',
            'Riscos no posto de trabalho',
            'Organização da Segurança contra incêndios',
            'Combate a Incêndios',
            'Evacuação',
            'Primeiros Socorros',
            'Acidentes de Trabalho',
            'Medicina no Trabalho',
            'Check List - Observação ao turno',
          ];
        }
      }
    } else if (this.type.type === 'trainingIbersolPC') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PFI Módulo de Integração') {
          return ['Bem-Vindo ao Pasta Caffé'];
        } else if (
          response &&
          response['Módulo'] === 'PFO Inicial Segurança Alimentar'
        ) {
          return [
            'Higiene e Segurança Alimentar - Pasta Caffé',
            'Alergias e Intolerãncias Alimentares',
            'Acrilamida',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO Inicial Segurança e Saúde no Trabalho'
        ) {
          return ['Segurança e Saúde no Trabalho- Pasta Caffé'];
        } else if (response && response['Módulo'] === 'PFO Inicial Sala') {
          return [
            'Bar',
            'Organização de sala',
            'Momentos de Verdade',
            'Agregadores - Sala',
          ];
        } else if (response && response['Módulo'] === 'PFO Inicial Cozinha') {
          return [
            'Make table 2',
            'Make table 1',
            'Saida de Forno',
            'Copa',
            'Pesagens',
            'Preparações de Cozinha',
            'Agregadores - cozinha',
          ];
        }
      }
    } else if (responseField.selectOptionsGetter === 'store.plots.name') {
      return this.storesService.store.plots.map((p: any) => p.name);
    } else if (responseField.selectOptionsGetter === 'societyNF') {
      // Step 1: Check if 'Grupo de Colheita' exists and it has contents
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        // Step 2: Get the array of groups matching names in the 'Grupo de Colheita' array
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );

        // Step 3: Get the distinct 'sociedade' values from each group
        const distinctSocieties = Array.from(
          new Set(matchingGroups.map((group: any) => group.codigo_sociedade))
        );

        response['Sociedade'] = distinctSocieties;
        // Step 5: Return the joined string
        return distinctSocieties;
      }

      // If 'Grupo de Colheita' does not exist or has no contents, return some fallback value (e.g., an empty string)
      return '';
    } else if (responseField.selectOptionsGetter === 'varietyNF') {
      // Step 1: Check if 'Grupo de Colheita' exists and it has contents
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        // Step 2: Get the array of groups matching names in the 'Grupo de Colheita' array
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );

        // Step 3: Get the distinct 'sociedade' values from each group
        const distinctVarieties = Array.from(
          new Set(matchingGroups.map((group: any) => group.variedade))
        );

        response['Variedade'] = distinctVarieties;
        // Step 5: Return the joined string
        return distinctVarieties.sort();
      }

      // If 'Grupo de Colheita' does not exist or has no contents, return some fallback value (e.g., an empty string)
      return '';
    } else if (responseField.selectOptionsGetter === 'field.plots.name') {
      return this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.map((p: any) => p.codigo_grupo_colheita)
        .sort();
    } else if (
      responseField.selectOptionsGetter === 'field.plots.parcels.name'
    ) {
      // Step 1: Check if 'Grupo de Colheita' exists and it has contents
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        // Step 2: Get the array of groups matching names in the 'Grupo de Colheita' array
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );

        // Step 3: Join all the 'plots.parcelas' array items in a single array
        const parcelasArray = matchingGroups.reduce(
          (acc: any, currentGroup: any) =>
            acc.concat(currentGroup.parcelas.map((p: any) => p.codigo_parcela)),
          []
        );

        // Step 4: Return the single array
        return parcelasArray.sort();
      }

      // If 'Grupo de Colheita' does not exist or has no contents, return some fallback value (e.g., an empty array)
      return [];
    } else if (responseField.selectOptionsGetter === 'store.plots.fullName') {
      return this.storesService.store.plots.map((p: any) => p.fullName);
    } else if (
      responseField.selectOptionsGetter === 'store.plots.varieties' &&
      response['Parcela']
    ) {
      let parcel = this.storesService.store.plots.find(
        (p: any) =>
          p.name === response['Parcela'] || p.fullName === response['Parcela']
      );
      if (!parcel) return [];
      return parcel.varieties;
    } else if (
      responseField.selectOptionsGetter === 'field.plots.varieties' &&
      response['Parcela']
    ) {
      let parcel = this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.find(
          (p: any) =>
            p.name === response['Parcela'] || p.fullName === response['Parcela']
        );
      if (!parcel) return [];
      return parcel.varieties.sort();
    } else if (responseField.selectOptionsGetter === 'store.plots.buyers') {
      return this.storesService.store.buyers.map((b: any) => b.code);
    } else if (responseField.selectOptionsGetter === 'suppliersLJ') {
      return [
        '1 Gelvalados - Produtos Alimentares Lda',
        '2 Pérola da Ilha',
        '3 Repraçores - Comércio e Representações AS',
        '4 Finançor Cach & Carry, Lda',
        '5 Sodril - Representações Insulares, Lda',
        '6 Insco - Insular de Hiper AS',
        '7 Higiaçores - Comercio e Serviços Lda',
        '8 Melo Abreu',
        '9 Paulo Teixeira',
        '10 Grupo Nabeiro',
        '11 Ocean Gin Company Lda',
        '12 O Rei dos Queijos',
        '13 Finançor Distribuição Alimentar, Lda',
        '14 Garcias Wine & Spirits',
        '15 Bom Sumo Lda',
        'Outro',
      ];
    } else if (responseField.selectOptionsGetter === 'productsLJ') {
      if (
        response &&
        response['Fornecedor'] === '5 Sodril - Representações Insulares, Lda'
      ) {
        return [
          'CRF',
          'Martel',
          'Zakapa',
          'Omelca - white',
          'Omelca - reposado',
          'Omelca - choc',
          'Eristoff Black',
          'Stolichnaya - Red',
          'Stolichnaya - Elit',
          'Black Label',
          'Baileys',
          'Beirão',
          'Porto - 20 anos',
          'Porto - branco',
          'Porto - Jockey Club',
          'Porto - LBV',
          'Burmester - Branco',
          'Burmester - Tinto',
          'Casa Burmester - B',
          'Casa Burmester - T',
          'Pedras limão',
          'Pedras tangerina',
          'Somersby',
          'Super bock',
          'S.B Sout',
          'S.B Weiss',
          'S.B Munich',
          'S.B IPA',
          'Não carne picada',
          'Hamburguer não carne',
          'Hamburguer não frango',
          'Nuggets Vegan',
          'Juliana Pimentos',
          'Falafel',
          'Brownie',
          'Sumo limão',
          'Preparado Mojito',
          'Pasta de Manjericão',
          'Nata vegan',
          'Clara de ovo',
          'Gema de ovo',
          "Hellmann's maionese",
          "Hellmann's mostarda",
          'Calve Barbecue',
          'Salada Bulgur & Whet',
          'Cabola pré frita (Kg)',
          'Arroz Basmati (12kg)',
          'Azeite (€/L)',
          'Pasta de Manjericão (340gr)',
          'Bechamel desidratado (800gr)',
          'Massa lasanha Knorr (5kg)',
          'Queijo fatiado flamengo (1KG)',
          'Queijo mozarella ralado (500gr)',
          'Queijo fresco flamengo',
          'Topping Caramelo',
          'Topping chocolate',
          'Topping frutos do bosque',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '3 Repraçores - Comércio e Representações AS'
      ) {
        return [
          'Remi Martin',
          'Amicis',
          'Brockmans',
          'Hendricks',
          'Martin Millers',
          'Sailor Jerry',
          'Brugal (White)',
          'Brugal (Añejo)',
          'Brugal (Extra)',
          'Brugal (1888)',
          'Famous Grouse',
          'Glenfidish',
          "Grant's",
          "Grant's - 12 anos",
          'Monkey Shoulder',
          'Naked',
          'Tullamore',
          'Drambuie',
          'Rocim - Branco',
          'Rocim - Tinto',
          'M.J.Branco',
          'M.J.Tinto',
          'Freixenet',
          'Stolisnaya',
          'pepsi',
          '7up',
          'compal laranja',
          'compal pessego',
          'compal ananas',
          'compal maça',
          'compal limão',
          'REVO',
        ];
      } else if (response && response['Fornecedor'] === '9 Paulo Teixeira') {
        return [
          'Courvoisier',
          'Citadelle',
          'Nordés',
          'Plantation (3Stars)',
          'Plantation (Dark)',
          'Plantation 5 años',
          'Plantation 20 años',
          'Plantation Pinaple',
          "Tito's",
          'Cutty Sark',
          "Jack Daniel's",
          'Batida de côco',
          'Dry Curaçao',
          'Grenadine',
          'Kahlúa',
          'Licor amora',
          'Licor maracujá',
          'Ginger Ale',
          'Ginger Beer',
          'Elderflower',
          'Mediterrain',
          'Indian',
          'Light',
          'Soda',
          'Cola',
          'Contreau',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '14 Garcias Wine & Spirits'
      ) {
        return [
          'Courvoisier',
          'CRF',
          'Citadelle',
          "Tito's",
          'Black Label',
          'Jim Beam',
          'Batida de côco',
          'Beirão',
          'Campari',
          'Cointreau',
          'Kahlúa',
          'Licor amora',
          'Licor maracujá',
          'Vermute',
          'Ginger Ale',
          'Ginger Beer',
          'Elderflower',
          'Mediterrain',
          'Indian',
          'Light',
          'Soda',
          'Cola',
        ];
      } else if (response && response['Fornecedor'] === '15 Bom Sumo Lda') {
        return [
          'Baleia',
          'Licor ananás (500ml)',
          'Licor ananás (100ml)',
          'Compota ananás',
          'Ananás',
        ];
      } else if (response && response['Fornecedor'] === '8 Melo Abreu') {
        return ['Baleia', 'Licor ananás (500ml)', 'Licor ananás (100ml)'];
      } else if (
        response &&
        response['Fornecedor'] === '4 Finançor Cach & Carry, Lda'
      ) {
        return [
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Amendoim',
          'Cajú frito',
          'Sortidos',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Noz moscada',
          'Cereja em calda',
          'Arroz',
          'Cogumelos',
          'Compota uva',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Tostas finas',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Iogurte grego',
          'Pato',
          'Atum natural enlatado (420gr)',
          'Azeite (€/L)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Miolo de noz',
          'Molho inglês Savora (120ml)',
          'Noz moscada (Margão)',
          'Pasta de Manjericão (340gr)',
          'Pimenta preta Margão (grão)',
          'Segredos do méxico (Mrg)',
          'sementes de chia (150gr)',
          'sementes de sêsamo (200gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '13 Finançor Distribuição Alimentar, Lda'
      ) {
        return [
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Pasta de Manjericão',
          'Óleo de côco',
          'Cereja em calda',
          'Arroz',
          'Cogumelos',
          'molho de soja',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Chocolate negro',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Iogurte grego',
          'Molho iogurte',
          'Queijo Parmesão',
          'Queijo fatiado flamengo',
          'Queijo Ilha ralado',
          'Alho picado (100gr)',
          'Atum natural enlatado (420gr)',
          'Azeite (€/L)',
          'Azeitonas preta picadas (200gr)',
          'Batata assar (Kg)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Cogumelos (congelados 400gr)',
          'Feijão preto enlatado (820gr)',
          'Molho soja Kikkoman (150ml)',
          'Molho inglês Savora (120ml)',
          'Óleo de côco (200ml vs 500ml)',
          'Pão cereais (650gr)',
          'Pimenta preta Margão (grão)',
          'sementes de chia (150gr)',
          'sementes de sêsamo (200gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
          'Tostas finas',
          'Aipo (500Kg)',
          'Salada Azores Fresh',
          'Alho frances (kg)',
          'Canoura (Kg)',
          'Gengibre (Kg)',
          'salsa (Kg)',
          'Iogurte grego  (Oîkos)',
          'Chouriço de Carne do Minho Ponte de Lima P.D.',
          'Pato (Kg)',
          'Bacon extra (naco) (Kg)',
          'Queijo Parmesão (150gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '6 Insco - Insular de Hiper AS'
      ) {
        return [
          'Espinafres com alho',
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Pasta de Manjericão',
          'Óleo de côco',
          'Azeite (seleção)',
          'Arroz',
          'Cogumelos',
          'molho de soja',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Chocolate negro',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Massa lasanha',
          'Massa tenra',
          'Iogurte grego',
          'Chouriço carne',
          'Presunto (naco)',
          'Pato',
          'Queijo Parmesão',
          'Queijo fatiado flamengo',
          'Queijo Ilha ralado',
          'Espinafres com alho (750gr)',
          'Azeite (€/L)',
          'Azeitonas preta picadas (200gr)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Cogumelos (congelados 400gr)',
          'Feijão preto enlatado (820gr)',
          'Molho inglês Savora (120ml)',
          'Noz moscada (Margão)',
          'Óleo de côco (200ml vs 500ml)',
          'Segredos do méxico (Mrg)',
          'sementes de chia (150gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
          'Alho frances (kg)',
          'Canoura (Kg)',
          'Iogurte grego  (Oîkos)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '12 O Rei dos Queijos'
      ) {
        return [
          'Compota uva',
          'Queijo Velho S.Miguel',
          'Queijo do Morro',
          'Queijo das Furnas',
          'Queijo S.João',
        ];
      } else if (response && response['Fornecedor'] === '2 Pérola da Ilha') {
        return ['Amendoim', 'Cajú frito', 'Sortidos'];
      } else if (response && response['Fornecedor'] === '10 Grupo Nabeiro') {
        return ['Café', 'Descafeinado'];
      } else if (
        response &&
        response['Fornecedor'] === '7 Higiaçores - Comercio e Serviços Lda'
      ) {
        return [
          'Filtro perfumado para Urinol',
          'Loção de mãos Chá de limão',
          'Sabão de mãos Chá de limão',
          'Pano microfibra amarelo',
          'Pano microfibra azul',
          'Pano microfibra rosa',
          'Pano microfibra verde',
          'Pano microfibra roxo',
          'Suma 4n1',
          'Papel hig folha a folha',
          'Bricer',
          'Suma Alcool Spray D4.12',
          'Suma Bac',
          'Suma Chlorsan D10.4',
          'Suma Multipurpose',
          'Toalhete de Mãos ZZ',
          'Fairy',
          'Saco lixo (15L)',
          'Saco lixo (30L)',
          'Saco lixo (50L)',
          'Saco lixo (100L)',
          'Guardanapo Grd',
          'Guardanapo Peq',
          'Guardanapo (preto)',
          'Papel de mãos autocorte',
          'Rolo térmico 57x40x11',
          'Rolo térmico 80x80x11',
          'Sacos plástico AD neutro 20x30',
          'Sacos plástico AD neutro 30x40',
          'Sacos plástico AD neutro 35x50',
          'Toalha individual mesa (preto)',
        ];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'problems') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const problemsList = ocorenciData.map((item: any) => item.problems);
      return problemsList;
    } else if (responseField.selectOptionsGetter === 'suppliers') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const selectedProblem = response['Tipo da Avaria'];
      const matchingProblemEntry = ocorenciData.find(
        (item: any) => item.problems === selectedProblem
      );

      // Reset selection and update previous problem
      if (response['previousSelectedProblem'] !== selectedProblem) {
        response[responseField.name] = '';
        response['previousSelectedProblem'] = selectedProblem;
      }

      response['allSuppliers'] = [];

      if (
        matchingProblemEntry &&
        Array.isArray(matchingProblemEntry.suppliers)
      ) {
        response['allSuppliers'] = matchingProblemEntry.suppliers.map(
          (entry: any) => entry.supplier
        );

        if (
          response[responseField.name] === '' &&
          matchingProblemEntry.suppliers.length === 1
        ) {
          response[responseField.name] =
            matchingProblemEntry.suppliers[0].supplier;
        }
      } else {
        response[responseField.name] = '';
      }

      return response['allSuppliers'];
    } else if (responseField.selectOptionsGetter === 'emailSuppliers') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const selectedSupplier = response['Fornecedor'];
      const selectedProblem = response['Tipo da Avaria'];

      // Find matching problem entry
      const matchingProblemEntry = ocorenciData.find(
        (item: any) => item.problems === selectedProblem
      );

      // Ensure matching problem entry exists before continuing
      if (matchingProblemEntry) {
        // Find matching supplier entry within the selected problem entry
        const matchingSupplierEntry = matchingProblemEntry.suppliers.find(
          (supplier: any) => supplier.supplier === selectedSupplier
        );

        response['allEmailSuppliers'] = [];

        // If a matching supplier entry is found and it has email suppliers, return them
        if (matchingSupplierEntry && matchingSupplierEntry.emailsupplier) {
          response['allEmailSuppliers'] = matchingSupplierEntry.emailsupplier;

          if (response['allEmailSuppliers'].length === 1) {
            response[responseField.name] = response['allEmailSuppliers'][0];
          }
        } else {
          response[responseField.name] = '';
        }
      }

      return response['allEmailSuppliers'];
    } else if (responseField.selectOptionsGetter === 'problemsNC') {
      return [
        'Caixas de Gordura',
        'Filtros da Hotte',
        'Desentupimentos',
        'Toldos',
        'A/C',
        'Motor Hotte',
        'Centrais de Incêndio',
        'Extintores',
        'Carreteis de Água',
        'Problemas POS',
        'Assistências',
        'Camaras CCTV',
        'Música Ambiente',
        'Equipamento',
      ];
    } else if (responseField.selectOptionsGetter === 'suppliersNC') {
      if (
        response &&
        (response['Tipo da Avaria'] === 'Caixas de Gordura' ||
          response['Tipo da Avaria'] === 'Filtros da Hotte' ||
          response['Tipo da Avaria'] === 'Desentupimentos')
      ) {
        return ['Desentop / Assist'];
      } else if (response && response['Tipo da Avaria'] === 'Toldos') {
        return ['Tolniber'];
      } else if (
        response &&
        (response['Tipo da Avaria'] === 'A/C' ||
          response['Tipo da Avaria'] === 'Motor Hotte')
      ) {
        return ['Efi3ene'];
      } else if (
        response &&
        (response['Tipo da Avaria'] === 'Centrais de Incêndio' ||
          response['Tipo da Avaria'] === 'Extintores' ||
          response['Tipo da Avaria'] === 'Carreteis de Água')
      ) {
        return ['Alarmes 24'];
      } else if (response && response['Tipo da Avaria'] === 'Problemas POS') {
        return ['Codisys'];
      } else if (response && response['Tipo da Avaria'] === 'Assistências') {
        return [
          'Assistência Refrige',
          'Assistência Nicola',
          'Assistência Superbock',
        ];
      } else if (response && response['Tipo da Avaria'] === 'Camaras CCTV') {
        return ['Smart Solutions'];
      } else if (response && response['Tipo da Avaria'] === 'Música Ambiente') {
        return ['Sonoro Legal'];
      } else if (response && response['Tipo da Avaria'] === 'Equipamento') {
        return ['TecniHotel'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'emailSuppliersNC') {
      if (
        response &&
        ((response['Fornecedor'] === 'Desentop / Assist' &&
          response['Tipo da Avaria'] === 'Caixas de Gordura') ||
          response['Tipo da Avaria'] === 'Filtros da Hotte' ||
          response['Tipo da Avaria'] === 'Desentupimentos')
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.desentopAssist;
      } else if (
        response &&
        response['Fornecedor'] === 'Tolniber' &&
        response['Tipo da Avaria'] === 'Toldos'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.tolniber;
      } else if (
        response &&
        ((response['Fornecedor'] === 'Efi3ene' &&
          response['Tipo da Avaria'] === 'A/C') ||
          response['Tipo da Avaria'] === 'Motor Hotte')
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.efi3ene;
      } else if (
        response &&
        ((response['Fornecedor'] === 'Alarmes 24' &&
          response['Tipo da Avaria'] === 'Centrais de Incêndio') ||
          response['Tipo da Avaria'] === 'Extintores' ||
          response['Tipo da Avaria'] === 'Carreteis de Água')
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.alarmes24;
      } else if (
        response &&
        response['Fornecedor'] === 'Codisys' &&
        response['Tipo da Avaria'] === 'Problemas POS'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.codisys;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Refrige' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaRefrige;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Nicola' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaNicola;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Superbock' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaSuperbock;
      } else if (
        response &&
        response['Fornecedor'] === 'Smart Solutions' &&
        response['Tipo da Avaria'] === 'Camaras CCTV'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.smartSolutions;
      } else if (
        response &&
        response['Fornecedor'] === 'Sonoro Legal' &&
        response['Tipo da Avaria'] === 'Música Ambiente'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.sonoroLegal;
      } else if (
        response &&
        response['Fornecedor'] === 'TecniHotel' &&
        response['Tipo da Avaria'] === 'Equipamento'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.tecniHotel;
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'problemsLJ2') {
      return [
        'Dispensadores Higiene feminina',
        'Extintores',
        'Carretel',
        'Alarme de incêncio',
        'Alarme de intrusão',
        'Equipamentos de frio',
      ];
    } else if (responseField.selectOptionsGetter === 'suppliersLJ2') {
      if (
        response &&
        response['Tipo da Avaria'] === 'Dispensadores Higiene feminina'
      ) {
        return ['Higiaçores'];
      } else if (
        response &&
        (response['Tipo da Avaria'] === 'Extintores' ||
          response['Tipo da Avaria'] === 'Carretel' ||
          response['Tipo da Avaria'] === 'Alarme de incêncio' ||
          response['Tipo da Avaria'] === 'Alarme de intrusão')
      ) {
        return ['Extinçor'];
      } else if (
        response &&
        response['Tipo da Avaria'] === 'Equipamentos de frio'
      ) {
        return ['ASR Santos'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'emailSuppliersLJ2') {
      if (
        response &&
        response['Fornecedor'] === 'Higiaçores' &&
        response['Tipo da Avaria'] === 'Dispensadores Higiene feminina'
      ) {
        return ['geral@higiacores.com'];
      } else if (
        response &&
        ((response['Fornecedor'] === 'Extinçor' &&
          response['Tipo da Avaria'] === 'Extintores') ||
          response['Tipo da Avaria'] === 'Carretel' ||
          response['Tipo da Avaria'] === 'Alarme de incêncio' ||
          response['Tipo da Avaria'] === 'Alarme de intrusão')
      ) {
        return ['bruno.serra@extincor.pt'];
      } else if (
        response &&
        response['Fornecedor'] === 'ASR Santos' &&
        response['Tipo da Avaria'] === 'Equipamentos de frio'
      ) {
        return ['tecnicaamilcarsantos@gmail.com'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    }
    return responseField.selectOptions || [];
  }

  removeFromGroup(group: any[], item: any): void {
    const index = group.indexOf(item);
    if (index !== -1) {
      group.splice(index, 1);
    }
  }

  async pressAddRegister(taskType: any) {
    const modal = await this.modalController.create({
      component: CustomTypeChildTaskModal,
      componentProps: {
        type: this.customTaskTypesService.getCustomTaskType(taskType),
        form: this.form,
        precedingTaskResponse: this.response,
        precedingTask: this.task,
      },
    });

    modal.present();

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        const returnedData = data.data;

        this.handleReturnedTask(returnedData);
      }
    });

    // Add more else if conditions outside the modal.onDidDismiss() block if needed
  }

  handleReturnedTask(returnedData: any) {
    this.response['childrenTasksIds']
      ? this.response['childrenTasksIds'].push(returnedData.task.id)
      : (this.response['childrenTasksIds'] = [returnedData.task.id]);

    if (returnedData.task.type === 'prepackagedPDVIntermarcheShort2') {
      this.prepackageTasks.push(returnedData);
    } else if (
      returnedData.task.type === 'unfreezingProductsShort2' ||
      returnedData.task.type === 'unfreezingProductsRepsol'
    ) {
      this.unfreezingTasks.push(returnedData);
    } else if (
      returnedData.task.type === 'warmTemperatureControlIntermarcheShort2' ||
      returnedData.task.type === 'warmTemperatureControlRepsol'
    ) {
      this.warmTemperatureControlTasks.push(returnedData);
    } else if (returnedData.task.type === 'fermentationProductsRepsol') {
      this.fermentationProductsTasks.push(returnedData);
    } else {
      // Handle additional else if conditions here based on task types
      // Example:
      // if (returnedData.task.type === "someOtherTaskType") {
      //   // Handle some other task type
      // }
    }
  }

  getTranslation(object: any): string {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.name;
    } else if (lang === 'es') {
      return object.nameEs || object.name;
    }
    return object.name;
  }

  getPlaceholderTranslation(object: any) {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.placeholder;
    } else if (lang === 'es') {
      return object.placeholderEs || object.placeholder;
    }
    return object.placeholder;
  }

  getUserAddedOptions(responseField: ResponseField): string[] {
    if (
      this.storesService &&
      this.storesService.store &&
      this.storesService.store.modules &&
      this.storesService.store.modules.fieldsSelectOptions &&
      responseField &&
      responseField.name &&
      this.storesService.store.modules.fieldsSelectOptions[responseField.name]
    ) {
      return this.storesService.store.modules.fieldsSelectOptions[
        responseField.name
      ];
    } else {
      return [];
    }
  }

  addToGroup(responseField: any, group: any[]): void {
    group.push(this.autofillSubfields(responseField.subfields));
  }

  isConnected = false;
  characterCounts: { [key: string]: number } = {};
  updateCharacterCount(fieldName: string, text: string): void {
    this.characterCounts[fieldName] = text ? text.length : 0;
  }

  onNewOptionAdded(newOption: string, responseField: ResponseField) {
    if (!this.storesService.store.modules) {
      this.storesService.store.modules = { fieldsSelectOptions: {} };
    }
    if (!this.storesService.store.modules.fieldsSelectOptions) {
      this.storesService.store.modules.fieldsSelectOptions = {};
    }
    if (responseField && responseField.name) {
      if (
        !this.storesService.store.modules.fieldsSelectOptions[
          responseField.name
        ]
      ) {
        this.storesService.store.modules.fieldsSelectOptions[
          responseField.name
        ] = [];
      }

      this.storesService.store.modules.fieldsSelectOptions[
        responseField.name
      ].push(newOption);
    } else {
      console.error(
        'Unable to save the new option due to null/undefined values'
      );
    }
  }
  async openSelectRawMaterialUsed(row: any) {
    const productListModal = await this.modalController.create({
      component: ProductListModal,
    });

    await productListModal.present();

    const productListResult = await productListModal.onDidDismiss();
    const product = productListResult.data;

    if (!product) return;

    const batchSelectModal = await this.modalController.create({
      component: BatchSelectModal,
      componentProps: { product },
    });

    await batchSelectModal.present();

    const batchSelectResult = await batchSelectModal.onDidDismiss();
    const batches = batchSelectResult.data;

    if (!batches || batches.length === 0) return;

    row.selectedProductInfo =
      product.name +
      ' - ' +
      batches.map((b: any) => `${b.lot} - ${b.expirationDate}`).join('; ');
  }

  pressTrackGPS() {
    this.alertService.present(
      'Em desenvolvimento',
      'No futuro poderá dar track do percurso'
    );
  }

  recalculateAllData() {
    this.type.metadata.responseFields.forEach((rf) => {
      if (rf.calculateValue) {
        this.response[rf.name] = FormUtils.calculateValue(
          rf.calculateValue,
          this.response[rf.name],
          this.response
        );
      }
    });
  }
}
