import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Loading {
  private loading: HTMLIonLoadingElement | null = null;

  constructor(
    private loadingCtrl: LoadingController,
    private translator: TranslateService
  ) {}

  async present() {
    if (this.loading) return;
    const loadingMessage: string = await firstValueFrom(
      this.translator.get('LOADING_MESSAGE')
    );
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles', // Ajustando o tipo de spinner para null, pois "hide" não é mais suportado
      message: loadingMessage,
    });
    return await this.loading.present();
  }

  async dismiss() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }
}
