import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  from,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { UploadAll } from './upload-all';
import { DownloadAll } from './download-all';
import { Network } from '@capacitor/network';
import { AuthenticationService } from './authentication';
import { TasksService } from './tasks.service';
import { UserInfo } from '../entities/authentication-info';
import { ModalController, Platform } from '@ionic/angular';
import { PromptModal } from '../components/prompt/prompt.modal';
import { LocalNotifications } from '@capacitor/local-notifications'; // Certifique-se de adicionar esse import corretamente

@Injectable({
  providedIn: 'root',
})
export class SyncingService implements OnDestroy {
  initer: any;
  syncingSubject = new BehaviorSubject<boolean>(false);
  public syncCompleted$ = new Subject<void>();

  private syncInterval: any;
  private syncPeriod = 15 * 60 * 1000; // 15 minute in milliseconds
  private lastExecutionTime: number = 0; // Track the last execution timestamp
  private pauseSubscription: Subscription | null = null;
  private resumeSubscription: Subscription | null = null;
  private lastTaskCount: number = 0;
  private isFirstSync: boolean = true;

  constructor(
    private downloadAll: DownloadAll,
    private uploadAll: UploadAll,
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private modalController: ModalController,
    private platform: Platform
  ) {}

  setSyncing(isSyncing: boolean) {
    this.syncingSubject.next(isSyncing);
  }

  sync(): Observable<void> {
    this.setSyncing(true);
    return from(this.uploadAll.upload()).pipe(
      concatMap(() => this.downloadAll.download()),
      tap(() => {
        this.setSyncing(false);
        this.syncCompleted$.next();
        if (this.initer) this.initer();
      })
    );
  }

  public startSyncInterval() {
    console.log('Starting sync interval.');

    // Check if the job should be run immediately (1 minute has passed)
    const now = Date.now();
    if (now - this.lastExecutionTime >= this.syncPeriod) {
      console.log(
        'Executing sync immediately since more than 1 minute has passed.'
      );
      this.executeSync();
    }

    // Set the interval for periodic sync
    this.syncInterval = setInterval(() => {
      console.log('Sync interval triggered.');
      this.executeSync();
    }, this.syncPeriod);
  }

  public clearSyncInterval() {
    if (this.syncInterval) {
      console.log('Clearing sync interval completely.');
      clearInterval(this.syncInterval);
      this.syncInterval = null;
      this.lastExecutionTime = 0; // Resetar o tempo de execução, se necessário
      this.lastTaskCount = 0; // Resetar a contagem de tarefas
      this.isFirstSync = true; // Resetar o estado da primeira sincronização, se necessário
    } else {
      console.log('No sync interval to clear.');
    }
  }

  public stopSyncInterval() {
    console.log('Stopping sync interval.');
    clearInterval(this.syncInterval);
    this.syncInterval = null;
  }
  private async executeSync() {
    const now = Date.now();
    console.log(`Executing synchronization at " ${new Date()}`);

    // Atualiza o último tempo de execução
    this.lastExecutionTime = now;

    // Busca as informações do usuário
    const user: UserInfo | null = this.authenticationService.getUserInfo();
    if (!user || !user.companyId || !user.storeId) {
      console.error('User information is incomplete. Skipping sync.');
      return;
    }

    const companyId = user.companyId;
    const storeId = user.storeId;

    // Usa a contagem de tarefas anterior ou inicializa corretamente após a primeira sincronização
    const oldTaskCount = this.tasksService.getJustificationCount();

    // Chama o serviço para buscar as tarefas de justificativa da loja
    try {
      const response = await this.tasksService
        .getJustificationTasksFromStore(companyId, storeId)
        .toPromise();

      // Extrai as tarefas da resposta, assumindo que a resposta é um array com objetos contendo um array de tarefas
      const tasks = response?.[0]?.tasks || [];

      console.log('Old task count:', oldTaskCount);
      console.log('Fetched tasks:', tasks.length);

      // Se houver mais tarefas do que a contagem anterior, exibe o modal (ou notificação local)
      if (tasks.length > oldTaskCount) {
        console.log('New justification tasks detected:', tasks);
        await this.showNewTasksModal(); // Aqui será chamada a notificação local ou modal, conforme a plataforma
      }

      // Atualiza a contagem de tarefas para o número atual
      this.lastTaskCount = tasks.length;
    } catch (error) {
      console.error('Error fetching justification tasks:', error);
    }
  }

  private async showNewTasksModal() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      // Verificar o status da permissão de notificações locais
      const permission = await LocalNotifications.checkPermissions();

      // Checar se a permissão de notificação já está ativa (granted)
      if (permission.display === 'granted') {
        // Disparar notificação local em dispositivos móveis se a permissão já foi concedida
        await this.showNewTasksNotification();
      } else {
        console.log(
          'Notificações locais não estão ativas. Nenhuma ação será realizada.'
        );
      }
    } else {
      // Exibir modal na web
      const modal = await this.modalController.create({
        component: PromptModal,
        componentProps: {
          title: 'Novas Não Conformidades',
        },
      });

      await modal.present();
      await modal.onDidDismiss();
      console.log('Modal dismissed.');
    }
  }

  private async showNewTasksNotification() {
    await LocalNotifications.schedule({
      notifications: [
        {
          title: 'Novas Não Conformidades',
          body: 'Atenção. Tem novas Não Conformidades.',
          id: 1,
          schedule: { at: new Date(Date.now() + 1000) },
          sound: undefined, // Ajuste aqui, removendo 'null' e definindo como 'undefined' ou omitindo completamente
          attachments: undefined, // Omitido ou definido como 'undefined'
          actionTypeId: '',
          extra: null,
        },
      ],
    });
    console.log('Local notification scheduled.');
  }

  // Set up pause and resume events for app lifecycle
  public setupLifecycleEvents() {
    // Pause event
    this.pauseSubscription = fromEvent(document, 'pause').subscribe(() => {
      console.log('App paused. Stopping sync.');
      this.stopSyncInterval();
    });

    // Resume event
    this.resumeSubscription = fromEvent(document, 'resume').subscribe(() => {
      console.log('App resumed. Starting sync.');
      this.startSyncInterval();
    });
  }

  // Clean up when component is destroyed
  ngOnDestroy() {
    this.stopSyncInterval();
    if (this.pauseSubscription) {
      this.pauseSubscription.unsubscribe();
    }
    if (this.resumeSubscription) {
      this.resumeSubscription.unsubscribe();
    }
  }
}
