import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { FieldType } from 'src/app/entities/field';
import { getTargetUser } from 'src/app/entities/authentication-info';
import moment from 'moment';

function getDailyCleaningListAhrespQuizTaskTypeFromSectionName(
  sectionForm: any
) {
  if (sectionForm.title.includes('Cozinha')) {
    return 'dailyCleaningCozinhaQuizAhresp';
  } else if (
    sectionForm.title.includes('Sala') ||
    sectionForm.title.includes('Esplanada')
  ) {
    return 'dailyCleaningSalaQuizAhresp';
  } else if (sectionForm.title.includes('WC Clientes')) {
    return 'dailyCleaningWcClienteQuizAhresp';
  } else if (
    sectionForm.title.includes('WC Pessoal') ||
    sectionForm.title.includes('Vestiários')
  ) {
    return 'dailyCleaningWcPessoalQuizAhresp';
  } else {
    // Retorna um valor padrão caso nenhum título conhecido seja fornecido
    return 'dailyCleaningListAhrespQuiz';
  }
}

function getDailyCleaning2xListAhrespQuizTaskTypeFromSectionName(
  sectionForm: any
) {
  if (sectionForm.title.includes('Cozinha')) {
    return 'dailyCleaning2xCozinhaQuizAhresp';
  } else if (
    sectionForm.title.includes('Sala') ||
    sectionForm.title.includes('Esplanada')
  ) {
    return 'dailyCleaning2xSalaQuizAhresp';
  } else if (sectionForm.title.includes('WC Clientes')) {
    return 'dailyCleaning2xWcClienteQuizAhresp';
  } else if (
    sectionForm.title.includes('WC Pessoal') ||
    sectionForm.title.includes('Vestiários')
  ) {
    return 'dailyCleaning2xWcPessoalQuizAhresp';
  } else if (sectionForm.title.includes('Armazém')) {
    return 'dailyCleaning2xArmazemQuizAhresp';
  } else {
    // Retorna um valor padrão caso nenhum título conhecido seja fornecido
    return 'dailyCleaning2xQuizAhresp';
  }
}

function getPeriodicCleaningListAhrespQuizTaskTypeFromSectionName(
  sectionForm: any
) {
  if (sectionForm.title.includes('Cozinha')) {
    return 'periodicCleaningCozinhaQuizAhresp';
  } else if (
    sectionForm.title.includes('Sala') ||
    sectionForm.title.includes('Esplanada')
  ) {
    return 'periodicCleaningSalaQuizAhresp';
  } else if (sectionForm.title.includes('WC Clientes')) {
    return 'periodicCleaningWcClienteQuizAhresp';
  } else if (
    sectionForm.title.includes('WC Pessoal') ||
    sectionForm.title.includes('Vestiários')
  ) {
    return 'periodicCleaningWcPessoalQuizAhresp';
  } else if (sectionForm.title.includes('Armazém')) {
    return 'periodicCleaningArmazemQuizAhresp';
  } else {
    // Retorna um valor padrão caso nenhum título conhecido seja fornecido
    return 'periodicCleaningListAhrespQuiz';
  }
}

@Injectable({
  providedIn: 'root',
})
export class CreateSectionsTasksAhresp implements IMyTaskHandler {
  name = 'create-sections-tasks-ahresp';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const existingSections = new Set();

    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }

    const sectionLevel = {
      id: cuid(),
      title: `2º - Detalhes dos espaços`,
      type: FieldType.Level,
      storeId: this.storeService.store.id,
      position: 2,
    };
    this.fieldsService
      .newField(sectionLevel, this.authenticationService.getAuthInfo())
      .subscribe(
        (data) => {},
        (err) => console.log(err)
      );

    data['Que espaços existem?'].map((s: any) => {
      //Create form
      console.log('estou aqui !');
      const sectionForm = {
        id: cuid(),
        title: `Configuração ${s}`,
        type: FieldType.Form,
        storeId: this.storeService.store.id,
        upperLevelId: sectionLevel.id,
        upperLevelIds: [sectionLevel.id],
      };

      this.fieldsService
        .newField(sectionForm, this.authenticationService.getAuthInfo())
        .subscribe(
          (data) => {},
          (err) => console.log(err)
        );

      function getMachineRefrigerationListAhrespTaskTypeFromSectionName(
        sectionForm: any
      ) {
        return sectionForm;
      }

      const machineRefrigerationListAhrespQuizTypeString =
        getMachineRefrigerationListAhrespTaskTypeFromSectionName(sectionForm);
      if (
        machineRefrigerationListAhrespQuizTypeString &&
        !sectionForm.title.includes('WC')
      ) {
        const machineRefrigerationListAhrespType =
          this.customTaskTypesService.getCustomTaskType(
            'machineRefrigerationListAhrespQuiz'
          );

        if (!machineRefrigerationListAhrespType) {
          console.log('machineRefrigerationListAhrespType info not found');
          return of(null);
        }
        this.tasksService
          .newTask(
            {
              id: cuid(), //use lib to generate id
              type: machineRefrigerationListAhrespType.type,
              formId: sectionForm.id,
              title: machineRefrigerationListAhrespType.name + ' ' + s,
              startDate: task.startDate,
              endDate: task.endDate,
              visualizationDate: task.visualizationDate,
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: sectionForm.storeId,
                userIds: getTargetUser(this.storeService.store, userInfo),
              },
              documentsIds: [],
              isCustomType: true,
            },
            this.authenticationService.getAuthInfo()
          )
          .subscribe(
            (data) => {},
            (err) => console.log(err)
          );
      }

      function getMachineFreezingListAhrespTaskTypeFromSectionName(
        sectionForm: any
      ) {
        return sectionForm;
      }

      const machineFreezingListAhrespQuizTypeString =
        getMachineFreezingListAhrespTaskTypeFromSectionName(sectionForm);
      if (
        machineFreezingListAhrespQuizTypeString &&
        !sectionForm.title.includes('WC')
      ) {
        const machineFreezingListAhrespType =
          this.customTaskTypesService.getCustomTaskType(
            'machineFreezingListAhrespQuiz'
          );

        if (!machineFreezingListAhrespType) {
          console.log('machineFreezingListAhrespType info not found');
          return of(null);
        }
        this.tasksService
          .newTask(
            {
              id: cuid(), //use lib to generate id
              type: machineFreezingListAhrespType.type,
              formId: sectionForm.id,
              title: machineFreezingListAhrespType.name + ' ' + s,
              startDate: task.startDate,
              endDate: task.endDate,
              visualizationDate: task.visualizationDate,
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: sectionForm.storeId,
                userIds: getTargetUser(this.storeService.store, userInfo),
              },
              documentsIds: [],
              isCustomType: true,
            },
            this.authenticationService.getAuthInfo()
          )
          .subscribe(
            (data) => {},
            (err) => console.log(err)
          );
      }

      function getMachineWaterBathListAhrespTaskTypeFromSectionName(
        sectionForm: any
      ) {
        return sectionForm;
      }

      const machineWaterBathListAhrespQuizTypeString =
        getMachineWaterBathListAhrespTaskTypeFromSectionName(sectionForm);
      if (
        (machineWaterBathListAhrespQuizTypeString &&
          sectionForm.title.includes('Cozinha')) ||
        sectionForm.title.includes('Sala') 
      ) {
        const machineWaterBathListAhrespType =
          this.customTaskTypesService.getCustomTaskType(
            'machineWaterBathListAhrespQuiz'
          );
        if (!machineWaterBathListAhrespType) {
          console.log('machineWaterBathListAhrespType info not found');
          return of(null);
        }
        this.tasksService
          .newTask(
            {
              id: cuid(), //use lib to generate id
              type: machineWaterBathListAhrespType.type,
              formId: sectionForm.id,
              title: machineWaterBathListAhrespType.name + ' ' + s,
              startDate: task.startDate,
              endDate: task.endDate,
              visualizationDate: task.visualizationDate,
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: sectionForm.storeId,
                userIds: getTargetUser(this.storeService.store, userInfo),
              },
              documentsIds: [],
              isCustomType: true,
            },
            this.authenticationService.getAuthInfo()
          )
          .subscribe(
            (data) => {},
            (err) => console.log(err)
          );
      }

      //Daily cleaning

      const dailyCleaningListAhrespQuizTypeString =
        getDailyCleaningListAhrespQuizTaskTypeFromSectionName(sectionForm);
      if (dailyCleaningListAhrespQuizTypeString) {
        const dailyCleaningListAhrespQuizType =
          this.customTaskTypesService.getCustomTaskType(
            dailyCleaningListAhrespQuizTypeString
          );
        if (!dailyCleaningListAhrespQuizType) {
          console.log('dailyCleaningListAhrespQuizType info not found');
          return of(null);
        }
        this.tasksService
          .newTask(
            {
              id: cuid(), //use lib to generate id
              type: dailyCleaningListAhrespQuizType.type,
              formId: sectionForm.id,
              title: dailyCleaningListAhrespQuizType.name + ' ' + s,
              startDate: task.startDate,
              endDate: moment(task.endDate).add(4, 'years').valueOf(),
              visualizationDate: moment(task.visualizationDate)
                .add(4, 'years')
                .valueOf(),
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: sectionForm.storeId,
                userIds: getTargetUser(this.storeService.store, userInfo),
              },
              documentsIds: [],
              isCustomType: true,
            },
            this.authenticationService.getAuthInfo()
          )
          .subscribe(
            (data) => {},
            (err) => console.log(err)
          );
      }

      const dailyCleaning2xListAhrespQuizTypeString =
        getDailyCleaning2xListAhrespQuizTaskTypeFromSectionName(sectionForm);
      if (dailyCleaning2xListAhrespQuizTypeString) {
        const dailyCleaning2xListAhrespQuizType =
          this.customTaskTypesService.getCustomTaskType(
            dailyCleaning2xListAhrespQuizTypeString
          );

        if (!dailyCleaning2xListAhrespQuizType) {
          console.log('dailyCleaning2xListAhrespQuizType info not found');
          return of(null);
        }

        this.tasksService
          .newTask(
            {
              id: cuid(), //use lib to generate id
              type: dailyCleaning2xListAhrespQuizType.type,
              formId: sectionForm.id,
              title: dailyCleaning2xListAhrespQuizType.name + ' ' + s,
              startDate: task.startDate,
              endDate: moment(task.endDate).add(4, 'years').valueOf(),
              visualizationDate: moment(task.visualizationDate)
                .add(4, 'years')
                .valueOf(),
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: sectionForm.storeId,
                userIds: getTargetUser(this.storeService.store, userInfo),
              },
              documentsIds: [],
              isCustomType: true,
            },
            this.authenticationService.getAuthInfo()
          )
          .subscribe(
            (data) => {},
            (err) => console.log(err)
          );
      }

      const periodicCleaningListAhrespQuizTypeString =
        getPeriodicCleaningListAhrespQuizTaskTypeFromSectionName(sectionForm);
      if (periodicCleaningListAhrespQuizTypeString) {
        const periocidCleaningListAhrespQuizType =
          this.customTaskTypesService.getCustomTaskType(
            periodicCleaningListAhrespQuizTypeString
          );

        if (!periocidCleaningListAhrespQuizType) {
          console.log('periocidCleaningListAhrespQuizType info not found');
          return of(null);
        }

        this.tasksService
          .newTask(
            {
              id: cuid(), //use lib to generate id
              type: periocidCleaningListAhrespQuizType.type,
              formId: sectionForm.id,
              title: periocidCleaningListAhrespQuizType.name + ' ' + s,
              startDate: task.startDate,
              endDate: moment(task.endDate).add(4, 'years').valueOf(),
              visualizationDate: moment(task.visualizationDate)
                .add(4, 'years')
                .valueOf(),
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: sectionForm.storeId,
                userIds: getTargetUser(this.storeService.store, userInfo),
              },
              documentsIds: [],
              isCustomType: true,
            },
            this.authenticationService.getAuthInfo()
          )
          .subscribe(
            (data) => {},
            (err) => console.log(err)
          );
      }
      return undefined;
    });
    return of(null);
  }
}
