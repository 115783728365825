import { Component, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { NavParams } from '@ionic/angular';
import { TasksService } from "../services/tasks.service";
import { AuthenticationService } from "../services/authentication";
import { PrintService } from "../services/print.service";
import { PrinterService } from "../services/printer.service";
import { PrintSovenaService } from "../services/print-sovena.service";
import { PrintTorribaService } from "../services/print-torriba.service";
import { Device } from "@capacitor/device";

@Component({
  selector: "app-modal-printer",
  templateUrl: "./printer.page.html",
})
export class PrinterPage implements OnInit {
  bluetoothList: any = [];
  selectedPrinter: any;
  errorText = "";
  isConnected = false;

  //TODO Extract this from service
  task: any;
  response: any;
  producer: any;
  buyer: any;
  plot: any;
  deviceInfo: any; // Correctly named device info

  constructor(
    private print: PrintService,
    private printerService: PrinterService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private taskService: TasksService,
    private authenticationService: AuthenticationService,
    private platform: Platform,
    private printSovenaService: PrintSovenaService,
    private printTorribaService: PrintTorribaService
  ) {
    this.task = navParams.get('task');
    this.response = navParams.get('response');
    this.producer = navParams.get('store');
    this.buyer = navParams.get('buyer');
    this.plot = navParams.get('plot');
  }

  async ngOnInit() {
    if (this.platform.is("android") && this.platform.is("cordova")) {
      this.bluetoothList = await this.printerService.getPrinters();
    }
    this.deviceInfo = await Device.getInfo(); // Fetch device info here
  }

  // This will list all of your Bluetooth devices
  listPrinter() {
    this.printerService.searchBluetoothPrinter().then((resp: any) => {
      // List of Bluetooth device list
      this.bluetoothList = resp;
    });
  }

  // This will store selected Bluetooth device MAC address
  selectPrinter(macAddress: string, name: string) {
    this.printerService.selectedPrinter = { macAddress, name };
    this.selectedPrinter = {
      macAddress,
      name,
    };
  }

  async printTransportGuide() {
    console.log("Printing");
    try {
      // Ensure we have a connection before printing
      await this.printerService.ensureConnection(this.selectedPrinter.name);
      if (this.task.printsCount) this.task.printsCount++;
      else this.task.printsCount = 1;
      if (this.task.type === "inProductApanhaAzeitonaNF") {
        await this.printSovenaService.printTransportGuide(
          this.task,
          this.response,
          this.producer,
          this.buyer
        );
      } else {
        await this.printTorribaService.printTransportGuide(
          this.task,
          this.response,
          this.producer,
          this.buyer,
          this.plot,
          this.deviceInfo // Pass the device info here
        );
      }

      /* this.taskService
        .updateTask(this.task, this.authenticationService.getAuthInfo())
        .subscribe(); */
    } catch (err) {
      console.log("Printing failed:", err);
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
