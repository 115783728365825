import { Injectable } from '@angular/core';
import { EnvironmentConfigs } from '../../environments/environment-configs';
import { environment } from '../../environments/environment';
import { ENV_MACHINES } from '../../environments/env-machines';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  environment: EnvironmentConfigs = environment;

  isProduction() {
    return !!environment.production;
  }

  getApiUri() {
    return environment.apiUri;
  }

  getMachine() {
    return environment.machine;
  }

  getChannel() {
    return environment.channel;
  }

  getDefaultStoreCredentials() {
    return environment.storeCredentials;
  }

  getVersion() {
    return environment.version;
  }

  isMachineGreenselection() {
    return environment.machine === ENV_MACHINES.GREENSELECTION;
  }

  isMachineMyTask() {
    return environment.machine === ENV_MACHINES.MYTASK;
  }

  isMachineIntermarche() {
    return environment.machine === ENV_MACHINES.INTERMARCHE;
  }

  isMachineIbersol() {
    return environment.machine === ENV_MACHINES.IBERSOL;
  }

  isMachineAWS() {
    return environment.machine === ENV_MACHINES.PROD;
  }

  isMachineEFN() {
    return environment.machine === ENV_MACHINES.EFN;
  }

  isMachineMyHarvest() {
    return environment.machine === ENV_MACHINES.MYHARVEST;
  }
}
