import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { FormControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-product-raw-material-list-modal',
  templateUrl: './product-raw-material-list.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductRawMaterialUsedListModal {

  product: any;
  searchValue: string = '';

  searchControl = new FormControl();
  searchResult: any[] = [];

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
  ) {
    this.product = this.navParams.get('product');
    this.searchResult = this.product?.rawMaterialUsedBatches || [];
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(search => {
      if (!this.product?.rawMaterialUsedBatches) {
        return;
      }
      this.searchResult = this.product.rawMaterialUsedBatches.filter((b: any) => {
        return b.lot.includes(search);
      });
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pressBatch(batch: any) {
    this.modalCtrl.dismiss(batch);
  }
}
