import { Injectable } from "@angular/core";
import { PrinterService } from "./printer.service";
import { Platform } from "@ionic/angular";
declare let BTPrinter: any;

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  constructor(private printerService: PrinterService, private platform: Platform) {}

  getImagePath(imgUrl: string): string {
    return imgUrl;
  }

  async getImageBase64(imgUrl: string, width = 50, height = 50): Promise<string> {
    return new Promise((resolve, reject) => {
      toDataURL(imgUrl, (dataUrl: string) => {
        const getBase64StringFromDataURL = (dataURL: string) =>
          dataURL.replace("data:", "").replace(/^.+,/, "");
        resolve(getBase64StringFromDataURL(dataUrl));
      });

      function toDataURL(url: string, callback: (dataUrl: string) => void) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result as string);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      }
    });
  }

  async printBase64(base64: string): Promise<void> {
    return new Promise((resolve, reject) => {
      BTPrinter.printBase64(
        (data: any) => {
          console.log("Success");
          resolve(data);
        },
        (err: any) => {
          console.log("Error");
          console.log(err);
          reject(err);
        },
        base64,
        "1"
      );
    });
  }

  async printBarcode(data: string): Promise<void> {
    const dataBytes = Array.from(data, (c: string) => c.charCodeAt(0));
    const commands = new Uint8Array([
      0x1b, 0x61, 0x01, 0x1d, 0x68, 0x64, 0x1d, 0x77, 0x02, 0x1d, 0x6b, 0x49,
      data.length + 2, 0x7b, 0x42, ...dataBytes, 0x0a, 0x0a, 0x0a, 0x0a,
    ]);
  
    const dataView = new DataView(commands.buffer);
    await this.printerService.sendToBluetoothPrinter(dataView);
  }
  

  async printQRCode(dataUrl: string): Promise<void> {
    const base64Data = dataUrl.replace("data:image/png;base64,", "");
    return new Promise((resolve, reject) => {
      BTPrinter.printBase64(
        (data: any) => {
          console.log("Success");
          resolve(data);
        },
        (err: any) => {
          console.log("Error");
          reject(err);
        },
        base64Data,
        "1"
      );
    });
  }

  async printTextLine(stringToPrint = ""): Promise<void> {
    return new Promise((resolve, reject) => {
      BTPrinter.printText(
        (data: any) => {
          console.log("Success");
          resolve(data);
        },
        (err: any) => {
          console.log("Error");
          console.log(err);
          reject(err);
        },
        stringToPrint.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "\n"
      );
    });
  }

  async printTextBoldCenter(stringToPrint = ""): Promise<void> {
    await this.printTextSizeAlign(stringToPrint, 8, 1);
  }

  async printTextSmallBoldCenter(stringToPrint = ""): Promise<void> {
    await this.printTextSizeAlign(stringToPrint, 1, 1);
  }

  async printTextSizeAlign(stringToPrint: string, size: number, align: number): Promise<void> {
    return new Promise((resolve, reject) => {
      BTPrinter.printTextSizeAlign(
        (data: any) => {
          console.log("Success");
          resolve(data);
        },
        (err: any) => {
          console.log("Error");
          console.log(err);
          reject(err);
        },
        stringToPrint.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        size,
        align
      );
    });
  }
}
