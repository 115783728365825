// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import cuid from 'cuid';
import { FabricatedProduct } from '../../entities/product';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateTechnicalSheet2 implements IMyTaskHandler {
  name = 'generate-technical-sheet2';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private productsService: ProductsService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }

    if (!this.storeService.store) {
      console.log('No store found');
      return of(null);
    }
    if (this.customTaskTypesService.canProceed(task.type, data)) {
      const newProduct: FabricatedProduct = {
        id: cuid(),
        name: data['Designação do produto'],
        supplierName: 'Fabricado',
        hasTechnicalSheet: true,
        technicalSheet: data,
        barcode: {
          itm8: '',
          value: '',
          plu: data['PLU'],
        },
        batches: [],
        storeId: this.storeService.store.id,
        users: [userInfo.id],
      };
      return this.productsService.newProduct(
        newProduct,
        this.authenticationService.getAuthInfo()
      );
    }

    return of(null);
  }
}
