import { ENV_MACHINES } from 'src/environments/env-machines';
import { EnvironmentConfigs } from 'src/environments/environment-configs';
import { version } from 'src/environments/version';

export const environment: EnvironmentConfigs = {
  production: true,
  apiUri: 'https://api.myhaccp.pt',
  machine: ENV_MACHINES.GREENSELECTION,
  channel: 'NONE',
  storeCredentials: {
    company: '',
    store: '',
    password: '',
    username: '',
    employeePin: '',
  },
  version,
};
