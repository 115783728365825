// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import moment from 'moment';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpFinishUnfreezing2 implements IMyTaskHandler {
  name = 'follow-up-finish-unfreezing2';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store.code} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const productId = task.productId;

    if (typeof productId === 'string') {
      const store = this.storeService.store;
      const product = this.productsService.getProductById(productId);
      const typeTask = this.customTaskTypesService.getCustomTaskType(
        'finishUnfreezingProducts2' || 'finishUnfreezingRepsol'
      );
      const userInfo = this.authenticationService.getUserInfo();
      if (!userInfo) {
        console.log('User info not found');
        return of(null);
      }
      if (!product) {
        console.log('product info not found');
        return of(null);
      }

      if (!typeTask) {
        console.log('typeTask info not found');
        return of(null);
      }
      const newTask = {
        id: cuid(), //use lib to generate id
        type: typeTask.type,
        formId: task.formId,
        title: this.getTitle(typeTask, data),
        subtitle: this.getSubtitle(typeTask, data),
        startDate: moment(data['Início de Descongelação'])
          .add(20, 'minutes')
          .valueOf(), //20 minutos, tempo indicado por Ana
        endDate: moment(data['Início de Descongelação'])
          .add(8, 'hour')
          .valueOf(),
        visualizationDate: moment().add(1, 'year').valueOf(),
        nonConformities: [],
        causes: [],
        corrections: [],
        periodicity: 'Única',
        target: {
          storeId: store.id,
          userIds: getTargetUser(
            this.storeService.store,
            userInfo
          ),
        },
        documentsIds: [],
        isCustomType: true,
        superType: undefined,
        productId: product.id,
        batch: data['Lote'],
        precedingTaskId: task.id,
      };
      return this.tasksService.newTask(
        newTask,
        this.authenticationService.getAuthInfo()
      );
    }

    return of(null);
  }
}
