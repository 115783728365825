import jsonLogic, { RulesLogic } from 'json-logic-js';

jsonLogic.add_operation('toFixed', (n: any, decimals: number): number => {
  const num = parseFloat(n);
  if (isNaN(num)) return NaN;
  return parseFloat(num.toFixed(decimals));
});

export const FormUtils = {
  calculateValue: function (
    calculateValue: RulesLogic,
    dataValue: any,
    data: Record<string, any>
  ): any {
    return doValueCalculation(calculateValue, dataValue, data, data);
  },
};

function doValueCalculation(
  calculateValue: RulesLogic,
  dataValue: any,
  data: Record<string, any>,
  row: Record<string, any>
): any {
  return evaluate(calculateValue, {
    value: dataValue,
    data,
    row: row || data,
  });
}

function evaluate(
  logicRule: RulesLogic,
  data: {
    value: any;
    data: Record<string, any>;
    row: Record<string, any>;
  }
): any {
  let returnVal: any = null;

  try {
    returnVal = jsonLogic.apply(logicRule, data);
  } catch (err) {
    console.error('Error evaluating JSONLogic rule:', err);
    returnVal = null;
  }

  return returnVal;
}
