<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{'Escolher ingrediente' | translate}}</h3>
          <img src="assets/img/close.svg" (click)="close()" />
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <div class="form-group">
            <label>Nome do produto:</label>
            <!-- Input field for searching products -->
            <input class="form-control" type="text" [(ngModel)]="productName" [placeholder]="'0' | translate" [formControl]="searchControl" />
          </div>
          
          <!-- Scrollable list for displaying search results -->
          <ion-content style="height: 300px; overflow-y: auto;">
            <ion-list>
              <!-- Display each product in a list item -->
              <ion-item *ngFor="let product of searchResult" (click)="pressProduct(product)">
                <ion-label>
                  <h2>Produto: {{ product.name }}</h2>
                  <!-- Optional details of the product -->
                  <p *ngIf="product.version">Versão: {{ product.version }}</p>
                  <p *ngIf="product.supplierName">Fornecedor: {{ product.supplierName }}</p>
                  <p *ngIf="product.brand">Marca: {{ product.brand }}</p>
                  <p *ngIf="product.barcode?.itm8">Cód. barras interno: {{ product.barcode.itm8 }}</p>
                  <p *ngIf="product.barcode?.value">EAN: {{ product.barcode.value }}</p>
                </ion-label>
                <ion-icon name="arrow-dropright" slot="end"></ion-icon>
              </ion-item>
              <!-- Message when there are no search results -->
              <ion-item *ngIf="searchResult.length === 0">
                <ion-label>{{ 'Sem resultados' | translate }}</ion-label>
              </ion-item>
            </ion-list>
          </ion-content>
          
        </div>
        
        <!-- Modal actions section -->
        <div class="modalActions">
          <div class="actionsLeft"></div>
          <div class="actionsRight">
            <!-- Button for creating a new product -->
            <button class="btn btn-green" (click)="pressNew()">{{ 'NOVO' | translate }}</button>
            <!-- Button to close the modal -->
            <button class="btn btn-cancel light-grey" (click)="close()">{{ 'CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  