import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { FormControl } from "@angular/forms";
import { StoresService } from "src/app/services/stores.service";
import { InsertManualEntryModal } from "../insert-manual-entry/insert-manual-entry.modal";


@Component({
  selector: 'app-select-entry-modal',
  templateUrl: 'select-entry.modal.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SelectEntryModal {

  product: any; // Adjust the type based on your actual data structure

  searchControl = new FormControl();
  searchResult: any[] = [];

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private storeService: StoresService
  ) {
    this.product = this.navParams.get('product'); // Use get method to retrieve 'product'
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pressEntry(entry: any) {
    this.modalCtrl.dismiss({ entry });
  }

  async pressManualEntry() {
    const modal = await this.modalCtrl.create({
      component: InsertManualEntryModal,
      componentProps: { product: this.product }
    });

    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.modalCtrl.dismiss(data);
    }
  }

}
