<ion-content scroll="false">
  <ion-searchbar
    [(ngModel)]="searchTerm"
    placeholder="{{ 'SEARCH' | translate }}"
    [formControl]="searchControl"
    (ionInput)="onSearchInput()"
  ></ion-searchbar>
  <div *ngIf="searching" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>
  <div
    *ngIf="searchTerm.length > 0"
    style="text-align: right; margin-right: 10px"
  >
    {{ products.length }} {{ 'correspondências' | translate }}
  </div>
  <ion-list-header class="tasks-list-header" no-margin>
    <ion-row class="tasks-row" align-items-center>
      <ion-col size="4" class="tasks-title-col"
        >{{ 'Designação' | translate }}</ion-col
      >
      <ion-col size="4" class="tasks-title-col"
        >{{ 'Lote' | translate }}</ion-col
      >
      <ion-col size="4" class="tasks-title-col"
        >{{ 'Validade' | translate }}</ion-col
      >
    </ion-row>
  </ion-list-header>
  <ion-list>
    <ion-item
      class="tasks-item"
      *ngFor="let product of products | slice : 0 : counter"
    >
      <ion-row
        [formGroup]="formGroups[product.id]"
        class="tasks-row inner-tasks-item"
        align-items-center
        no-margin
        [ngClass]="{'touched-item': formGroups[product.id].touched}"
      >
        <ion-col size="4" class="tasks-col">
          <div class="task-content">
            <span>{{ getProductDesignation(product) }}</span>
          </div></ion-col
        >
        <ion-col size="4" class="tasks-col">
          <ion-item>
            <ion-input
              [(ngModel)]="batchesInfo[product.id].lot"
              placeholder="Lote"
              (change)="touch(product.id)"
              [formControl]="formGroups[product.id].controls.lot"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="4" class="tasks-col">
          <ion-item>
            <ion-input
              type="date"
              [(ngModel)]="batchesInfo[product.id].expirationDate"
              placeholder="Validade Primária (YYYY-MM-DD)"
              (change)="touch(product.id)"
              [formControl]="formGroups[product.id].controls.expirationDate"
            ></ion-input>
          </ion-item>
        </ion-col>
        <p
          *ngIf="
                formGroups[product.id].errors?.atLeastOneFilled &&
                formGroups[product.id].touched
              " 
          style="
            color: red;
            background-color: white;
            margin-left: 5px;
            font-weight: bold;
          "
        >
          Pelo menos um dos campos "Validade" ou "Lote" precisa ser preenchido.
        </p></ion-row
      >
    </ion-item>
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content> </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start" style="order: 1">
      <ion-button
        icon-start
        (click)="saveAll()"
        style="background-color: #45a1c8; color: white"
      >
        <ion-icon name="save"></ion-icon>
        {{ 'Guardar' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
