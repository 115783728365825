import { Injectable, Injector } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { Link } from '../../utils/link';
import { JSON_HEADERS } from '../../utils/headers';
import {
  getLastSecondaryExpirationDate,
  getProductLastBatch,
  calculateExpirationDate,
  Product,
} from '../entities/product';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { NewProductUploadInfo, UploadAll } from './upload-all';
import cuid from 'cuid';
import moment from 'moment';
import { StoresService } from './stores.service';
import { EMPTY, from, Observable, of } from 'rxjs';
import { map, tap, catchError, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EnvironmentService } from './environment.service';
import { Batch, BatchStatus } from '../entities/batch';
import { addDays, format, isBefore } from 'date-fns';
import { ProductWithTagInfo } from './tags-printing.service';
import { TasksService } from './tasks.service';
import { CustomTaskService } from './custom-task.service';
import { CustomTaskTypesService } from './custom-task-types.service';
import { AuthenticationService } from './authentication';
import { FNSDateFormats } from 'src/utils/date.utils';

export interface ProductWithSecondaryExpiration {
  product: Product;
  expirationDate: Date | null;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private cache = new Map<string, Product>();
  private cacheSyncing = new Map<string, Product>();
  private lastUpdatedTimestamp: number | undefined;
  private etag = '';
  private uploadAll!: UploadAll;
  private tasksService!: TasksService;
  private customTaskService!: CustomTaskService;
  private customTaskTypesService!: CustomTaskTypesService;
  private authenticationService!: AuthenticationService;

  constructor(
    private http: AuthenticatedHttpService,
    private injector: Injector,
    private storesService: StoresService,
    protected envService: EnvironmentService
  ) {
    setTimeout(() => (this.uploadAll = injector.get(UploadAll)));
    setTimeout(() => (this.tasksService = injector.get(TasksService)));
    setTimeout(
      () => (this.customTaskService = injector.get(CustomTaskService))
    );
    setTimeout(
      () => (this.customTaskTypesService = injector.get(CustomTaskTypesService))
    );
    setTimeout(
      () => (this.authenticationService = injector.get(AuthenticationService))
    );
  }

  getProductsFromStore(
    companyId: string,
    storeId: string
  ): Observable<{ products: Product[]; status: number }> {
    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/products`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );

    return this.http.get(link).pipe(
      map((response: any) => {
        const resp = {
          products: [] as Product[],
          status: response.status,
        };
        if (response.status === 304) {
          return resp;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        resp.products = response.body || [];
        return resp;
      }),
      tap((response) => {
        if (response.status === 304) {
          return;
        }
        this.lastUpdatedTimestamp = moment.now().valueOf();
        response.products.forEach((p) => {
          this.cache.set(p.id, p);
        });
      }),
      catchError((err) => {
        console.error(err);
        return of({ products: [], status: 500 });
      })
    );
  }

  getProduct(productId: string): Product | undefined {
    return this.cache.get(productId);
  }

  newProduct(product: Product, authInfo: AuthenticationInfo): Observable<any> {
    const originalTryTimestamp = moment.now().valueOf();
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;
    if (!storeId) {
      throw new Error('Store ID is undefined');
    }
    product.storeId = storeId;
    if (!product.id) product.id = cuid();
    if (!product.barcode) product.barcode = { value: '', itm8: '' };
    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/products/${
        product.id
      }`,
      JSON_HEADERS
    );

    return this.http.put(link, product, authInfo).pipe(
      tap(() => this.handleNewProductSuccess(product)),
      catchError((err) => {
        this.handleNewProductError(
          err,
          product,
          authInfo,
          originalTryTimestamp
        );
        return of(null);
      })
    );
  }

  updateProduct(
    product: Product,
    authInfo: AuthenticationInfo
  ): Observable<any> {
    const originalTryTimestamp = moment.now().valueOf();
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;
    if (!storeId) {
      throw new Error('Store ID is undefined');
    }
    product.storeId = storeId;
    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/products/${
        product.id
      }`,
      JSON_HEADERS
    );

    return this.http.put(link, product, authInfo).pipe(
      tap(() => this.handleNewProductSuccess(product)),
      catchError((err) => {
        this.handleNewProductError(
          err,
          product,
          authInfo,
          originalTryTimestamp
        );
        return of(null);
      })
    );
  }

  private handleNewProductSuccess(product: Product): void {
    if (
      !this.storesService.store ||
      !this.storesService.store.id ||
      product.storeId !== this.storesService.store.id
    )
      return;
    if (this.uploadAll.uploading) {
      this.removeProductFromCaches(product);
    } else {
      this.addProductToProductsCache(product);
    }
  }

  private addProductToProductsCache(product: Product): void {
    this.cache.set(product.id, product);
  }

  private handleNewProductError(
    err: any,
    product: Product,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ): void {
    this.uploadAll
      .addPendingNewProductRequest(
        new NewProductUploadInfo(product, authInfo, originalTryTimestamp)
      )
      .subscribe(() => {
        if (
          !this.storesService.store ||
          !this.storesService.store.id ||
          product.storeId !== this.storesService.store.id
        )
          return;
        this.cache.set(product.id, product);
        this.cacheSyncing.set(product.id, product);
      });
  }

  private removeProductFromCaches(product: Product): void {
    this.cache.delete(product.id);
    this.cacheSyncing.delete(product.id);
  }

  getProductByBarcode(barcode: string): Product | undefined {
    if (!barcode) return;
    return Array.from(this.cache.values()).find((p) => {
      if (p.barcode) {
        return (
          p.barcode.value === barcode ||
          p.barcode.itm8 === barcode ||
          `00000${p.barcode.itm8}` === barcode ||
          `000000${p.barcode.itm8}` === barcode ||
          `0000000${p.barcode.itm8}` === barcode ||
          `00000000${p.barcode.itm8}` === barcode ||
          `000000000${p.barcode.itm8}` === barcode ||
          `0000000000${p.barcode.itm8}` === barcode ||
          `00000000000${p.barcode.itm8}` === barcode
        );
      }
      return false;
    });
  }

  getProductById(id: string): Product | undefined {
    if (!id) return;
    return this.cache.get(id);
  }

  getProductByName(name: string): Product | undefined {
    return Array.from(this.cache.values()).find(
      (p) => p.name === name && !p.inactive
    );
  }

  getFabricatedProducts(): Product[] {
    return Array.from(this.cache.values()).filter((p) => p.hasTechnicalSheet);
  }

  getProductsWithoutFabricated(): Product[] {
    return Array.from(this.cache.values()).filter((p) => !p.hasTechnicalSheet);
  }

  getProducts(): Product[] {
    return Array.from(this.cache.values());
  }

  patchProduct(
    product: Product,
    updateOps: any[],
    authInfo: AuthenticationInfo
  ): Observable<any> {
    const originalTryTimestamp = moment.now().valueOf();
    const user = authInfo.user as UserInfo;
    const companyId = user.companyId;
    const storeId = user.storeId;
    if (!storeId) {
      throw new Error('Store ID is undefined');
    }
    const link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/products/${
        product.id
      }`,
      JSON_HEADERS
    );

    return this.http.patch(link, updateOps, authInfo).pipe(
      tap((updatedProduct) => this.handleNewProductSuccess(updatedProduct)),
      catchError((err) => {
        this.handleNewProductError(
          err,
          product,
          authInfo,
          originalTryTimestamp
        );
        return of(null);
      })
    );
  }

  /**
   * Retrieves products that the last batch is expiring within the next `nDays`.
   *
   * @param nDays - The number of days from now to check for expiring products.
   * @returns An array of products with the last batch expiring within the next `nDays`.
   */
  getExpiringProductsInNDays(nDays: number): Product[] {
    const targetDate = addDays(new Date(), nDays);

    return this.getProducts().filter((product) => {
      if (
        (product.batches[0] &&
          product.batches[0].status === BatchStatus.Finished) ||
        (product.batches[0] &&
          product.batches[0].status === BatchStatus.Donated)
      ) {
        return false;
      }
      const expirationDate = getLastSecondaryExpirationDate(product);

      // If there's no expiration date, exclude the product
      if (expirationDate === null) {
        return false;
      }

      if (expirationDate <= new Date()) {
        return false;
      }

      // Include the product if its expiration date is on or before the target date
      return expirationDate <= targetDate;
    });
  }

  /**
   * Retrieves products that are expired.
   *
   * @returns An array of products which last batch is expired.
   */
  getExpiredProducts(): Product[] {
    return this.getProducts().filter((p) => {
      if (p.batches[0] && p.batches[0].status === BatchStatus.Expired) {
        return true;
      }
      if (
        (p.batches[0] && p.batches[0].status === BatchStatus.Finished) ||
        (p.batches[0] && p.batches[0].status === BatchStatus.Donated)
      ) {
        return false;
      }
      const expirationDate = getLastSecondaryExpirationDate(p);
      if (!expirationDate) return false;
      return expirationDate <= new Date();
    });
  }

  getIngredientsLastExpirationDates(product: Product): (Date | null)[] {
    if (!product.ingredientsIds) return [];
    return product.ingredientsIds
      .map((id: string) => this.getProductById(id))
      .map((p: Product | undefined) => {
        if (!p) return null;
        const expirationDate = getProductLastBatch(p)?.expirationDate;
        if (expirationDate) return new Date(expirationDate);
        return null;
      });
  }

  calculateExpirationDate(
    preparationDate: Date,
    primaryExpirationDate: Date | null,
    product: Product
  ) {
    const productSecondaryExpirationDate = calculateExpirationDate(
      preparationDate,
      primaryExpirationDate,
      product
    );
    if (!product.ingredientsIds) {
      return productSecondaryExpirationDate;
    } else {
      const ingredientsExpirationDates =
        this.getIngredientsLastExpirationDates(product);
      ingredientsExpirationDates.push(productSecondaryExpirationDate);
      const sortedIngredientsAndPreparedExpirationDates =
        ingredientsExpirationDates.sort(
          (d1, d2) => (d1?.getTime() || Infinity) - (d2?.getTime() || Infinity)
        );
      // No need to round because getSecondaryExpirationDate is already doing that in the "map".
      return sortedIngredientsAndPreparedExpirationDates[0];
    }
  }

  getIngredientsData(product: Product): ProductWithSecondaryExpiration[] {
    if (!product.ingredientsIds) return [];
    return product.ingredientsIds
      .map((id: string) => this.getProductById(id))
      .filter((p): p is Product => p !== undefined)
      .map((p: Product) => {
        return {
          product: p,
          expirationDate: getLastSecondaryExpirationDate(p),
        };
      });
  }

  getBatchById(product: Product, batchId: string): Batch | null {
    return product.batches.filter((b) => b.id === batchId)[0] || null;
  }

  processExpirationDates(
    preparationDate: Date,
    products: ProductWithTagInfo[]
  ) {
    const type = this.customTaskTypesService.getCustomTaskType('printTagsIber');
    if (!type) {
      throw new Error('Didnt find expected type');
    }

    for (const { product, tagInfo } of products) {
      const quantity = tagInfo.quantityTags;
      if (!quantity && quantity !== 0) {
        continue;
      }
      const primaryExpirationDate = tagInfo.batch.expirationDate;
      const newTask = {
        id: cuid(), //use lib to generate id
        type: type.type,
        formId:
          this.storesService.store.modules.traceabilityTags.refrigeratedProducts
            .formId,
        title: type.name,
        subtitle: product.name,
        startDate: moment().valueOf(),
        endDate: moment().add(1, 'hours').valueOf(),
        visualizationDate: moment().add(1, 'year').valueOf(),
        nonConformities: [],
        causes: [],
        corrections: [],
        periodicity: 'Única',
        target: { storeId: this.storesService.store.id, userIds: [] },
        documentsIds: [],
        isCustomType: true,
        productId: product.id,
      };
      const respondContentData = {
        picturesIds: [],
        non_conformity: '',
        causes: '',
        correction: '',
        commentary: '',
        ncCommentary: '',
        Lote: tagInfo.batch.lot,
        Produto: product,
        Quantidade: quantity,
        'Data de preparação': format(
          preparationDate,
          FNSDateFormats.YYYYMMDD_HHmm
        ),
        'Data de expiração': primaryExpirationDate,
        isInConformity: true,
        clientDate: Date.now(),
        employeeId: '-',
        date: Date.now(),
      };

      this.tasksService
        .newTask(newTask, this.authenticationService.getAuthInfo())
        .pipe(
          // Catch errors from newTask to prevent the chain from terminating
          catchError((err) => {
            console.log('Error creating new task:', err);
            // Return a value to allow the chain to continue
            return of(null);
          }),
          // Proceed to respondContent regardless of newTask's success or failure
          mergeMap(() =>
            this.customTaskService
              .respondContent(newTask, respondContentData)
              .pipe(
                catchError((err) => {
                  console.log('Error in respondContent:', err);
                  return of(null);
                })
              )
          ),
          mergeMap(() => {
            const tasksToClose =
              this.tasksService.findTasksWithTypeAndProductId(
                'breakProductIbersol',
                product.id
              );
            return from(tasksToClose).pipe(
              mergeMap((taskToClose) => {
                const breakProductIbersolRespondContentData = {
                  picturesIds: [],
                  non_conformity: '',
                  causes: '',
                  correction: '',
                  commentary: '',
                  ncCommentary: '',
                  Produto: product,
                  Lote: taskToClose.batch,
                  'Data de preparação': taskToClose.batch?.preparationDate,
                  'Data de expiração': taskToClose.batch?.expirationDate,
                  'Condição do Produto': 'Fecho automático',
                  'Tarefa terminada': true,
                  isInConformity: true,
                  clientDate: Date.now(),
                  employeeId: '-',
                  date: Date.now(),
                };
                return this.customTaskService
                  .respondContent(
                    taskToClose,
                    breakProductIbersolRespondContentData
                  )
                  .pipe(
                    catchError((err) => {
                      console.log('Error closing task:', err);
                      return EMPTY;
                    })
                  );
              })
            );
          })
        )
        .subscribe();
    }
  }
}
