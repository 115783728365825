import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { StoresService } from './stores.service';

const { BluetoothLe } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class BluetoothService {
  private onTemperatureRead: (result: any) => void = () => {};
  private onTPMRead: (result: any) => void = () => {};
  private onConnect: () => void = () => {};

  constructor(private storesService: StoresService) {}

  async initiateBluetoothLe() {
    if (!this.storesService.store?.modules?.bluetoothOilReader) return;

    const hasPermission = await BluetoothLe['checkPermissions']();
    if (!hasPermission.hasPermissions) {
      const permission = await BluetoothLe['requestPermissions']();
      if (permission) {
        this.doBluetoothStuff();
      }
    } else {
      this.doBluetoothStuff();
    }
  }

  connectToThermometer() {}

  private async doBluetoothStuff() {
    const bleStatus = await BluetoothLe['initialize']();
    await this.attemptConnectBluetoothDevice();
  }

  private async attemptConnectBluetoothDevice() {
    const isEnabled = await BluetoothLe['isEnabled']();
    if (!isEnabled.value) {
      await BluetoothLe['enable']();
      await this.delay(5000);
      await this.attemptConnectBluetoothDevice();
    } else {
      await this.connectBluetoothDevice(
        this.storesService.store?.modules?.bluetoothOilReader
      );
    }
  }

  private delay(t: number) {
    return new Promise((resolve) => setTimeout(resolve, t));
  }

  private async connectBluetoothDevice(address: string) {
    try {
      await BluetoothLe['connect']({ deviceId: address });
      this.onDeviceConnect(address);
    } catch (error) {
      console.log('Error connecting: ', error);
    }
  }

  private stopBLEScanning(message?: string) {
    BluetoothLe['stopScan']().then(
      () => {
        if (message) console.log(message);
      },
      (err: any) => console.log('Error stopping scan: ', err)
    );
  }

  private bleConnect(address: string) {
    let found = false;
    BluetoothLe['requestLEScan']({
      services: [],
      allowDuplicates: false,
      scanMode: BluetoothLe['SCAN_MODE_LOW_LATENCY'],
      matchNum: BluetoothLe['MATCH_NUM_MAX_ADVERTISEMENT'],
    }).addListener('onScanResult', (result: any) => {
      if (found) return;
      if (result.device.deviceId === address) {
        found = true;
        this.stopBLEScanning('Found device');
        this.connectBluetoothDevice(address);
      }
    });

    setTimeout(() => {
      this.stopBLEScanning('timed out scan');
    }, 10000);
  }

  private hexToArrayBuffer(input: string) {
    if (typeof input !== 'string') {
      throw new TypeError('Expected input to be a string');
    }

    if (input.length % 2 !== 0) {
      throw new RangeError('Expected string to be an even number of characters');
    }

    const view = new Uint8Array(input.length / 2);

    for (let i = 0; i < input.length; i += 2) {
      view[i / 2] = parseInt(input.substring(i, i + 2), 16);
    }

    return view;
  }

  private async onDeviceConnect(address: string) {
    const key = '32DBCEBC064B04713B7F04877563EED4';
    try {
      await BluetoothLe['discover']({ deviceId: address });
      await BluetoothLe['write']({
        deviceId: address,
        service: '000035E0-8EB0-4405-A6AA-800F66D02188',
        characteristic: '000035E1-8EB0-4405-A6AA-800F66D02188',
        value: BluetoothLe['bytesToEncodedString'](this.hexToArrayBuffer(key)),
      });

      this.onConnect();
      BluetoothLe['startNotifications']({
        deviceId: address,
        service: '00004590-81B7-4047-8773-00DBA7A16ACD',
        characteristic: '00004593-81B7-4047-8773-00DBA7A16ACD',
      }).addListener('onNotification', (result: any) => {
        this.onTemperatureRead(result);
      });

      BluetoothLe['startNotifications']({
        deviceId: address,
        service: '00004590-81B7-4047-8773-00DBA7A16ACD',
        characteristic: '00004592-81B7-4047-8773-00DBA7A16ACD',
      }).addListener('onNotification', (result: any) => {
        this.onTPMRead(result);
      });
    } catch (error) {
      console.log('Error on device connect: ', error);
    }
  }

  async connectBleThermometer(
    onTemperatureRead: (result: any) => void,
    onTPMRead: (result: any) => void,
    onConnect: () => void
  ) {
    this.onTemperatureRead = onTemperatureRead;
    this.onTPMRead = onTPMRead;
    this.onConnect = onConnect;

    const hasPermission = await BluetoothLe['checkPermissions']();
    if (hasPermission.hasPermissions) {
      const bleStatus = await BluetoothLe['initialize']();
      console.log(JSON.stringify(bleStatus));
      await this.attemptConnectBluetoothDevice();
    } else {
      const reqPermission = await BluetoothLe['requestPermissions']();
      if (reqPermission)
        await this.connectBleThermometer(onTemperatureRead, onTPMRead, onConnect);
    }
  }
}
