import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Task, TaskResponse } from '../entities/tasks/task';
import { ExecutionState } from '../services/custom-task.service';
import { MayStayPendingHandler } from './handlers/may-stay-pending.handler';
import { ClassifyProductUsersHandler } from './handlers/classify-product-users.handler';
import { RegisterExpirationDate2 } from './handlers/register-expiration-date2.handler';
import { CreateNewBatch } from './handlers/create-new-batch.handler';
import { FollowUpInsertSupplier } from './handlers/follow-up-insert-supplier.handler';
import { FollowUpTaskPlaceTags } from './handlers/follow-up-task-place-tags.handler';
import { GenerateTechnicalSheet2 } from './handlers/generate-technical-sheet2.handler';
import { FollowUpTaskInsertExpiration2 } from './handlers/follow-up-task-insert-expiration2.handler';
import { FollowUpFinishUnfreezing2 } from './handlers/follow-up-finish-unfreezing2.handler';
import { RegisterBatchForProduction } from './handlers/register-batch-for-production.handler';
import { FollowUpTaskProcessExpiration2 } from './handlers/follow-up-task-process-expiration2.handler';
import { CreateSectionsTasks } from './handlers/create-sections-tasks.handler';
import { FollowUpTaskControlWarmTemperature2 } from './handlers/follow-up-task-control-warm-temperature2.handler';
import { FollowUpInsertNonConformities } from './handlers/follow-up-insert-nonConformities.handler';
import { FollowUpTaskPrintTags } from './handlers/follow-up-task-print-tags';
import { CreateSectionsTasksAhresp } from './handlers/create-sections-tasks-ahresp';
import { FollowEquipmentMaintenance } from './handlers/follow-up-equipmentMaintenance';
import { FollowUpInsertHaccpEmailTo } from './handlers/follow-up-insert-haccp-emailTo';
import { FollowUpInsertDocsEmailTo } from './handlers/follow-up-insert-docs-emailTo.handler';
import { FollowUpInserFile } from './handlers/follow-up-insert-file.handler';
import { FollowUpFileValidation } from './handlers/follow-up-file-validation.handler';
import { FollowUpTaskPrintTagsGroupedBatches } from './handlers/follow-up-task-print-tags-grouped-batches';
import { FollowUpFileIntervention } from './handlers/follow-up-intervention.handler';
import { WriteOffBatchHandler } from './handlers/write-off-batch.handler';
import { WriteOffGroupedBatchesHandler } from './handlers/write-off-grouped-batches.handler';
import { CheckCreateEligibilityArea } from './handlers/check-create-eligibility-area';
import { FollowUpConfirmData } from './handlers/follow-up-confirm-data';
import { FollowUpFutureCorrection } from './handlers/follow-up-future-correction';
import { FollowUpSigningDeclarations } from './handlers/follow-up-signing-declarations';
import { FollowUpAccessCounterPlatform } from './handlers/follow-up-access-counter-platform';
import { FollowUpTellAboutProject } from './handlers/follow-up-tell-about-project';
import { FollowUpTagPreview } from './handlers/follow-up-tag-preview';
import { CreateSectionsTasksGeral } from './handlers/create-sections-tasks-geral';
import { FollowUpAccessBalcaoPlatform } from './handlers/follow-up-access-balcao-platform';
import { FollowUpConfirmDataPart2 } from './handlers/follow-up-confirm-data-part2';
import { CreateNewBatchTraceability } from './handlers/create-new-batch-traceability.handler';
import { WriteOffBatchIbersolHandler } from './handlers/write-off-batch-ibersol.handler';
import { FollowUpInsertStatements } from './handlers/follow-up-insert-statements';
import { CreateNewBatchIber } from './handlers/create-new-batch-iber';
import { GeneratePdfPerformanceReviews } from './handlers/generate-pdf-performance-reviews';
import { CreateSectionsTasksSerunion } from './handlers/create-sections-tasks-serunion';
import { FollowUpInserMachine } from './handlers/follow-up-insert-machines';

import { FollowUpInsertHelpEmailTo } from './handlers/follow-up-insert-help-emailTo.handler';

import { FollowUpInternalRequest } from './handlers/follow-up-internal-request';
import { FollowUpInsertFileIbersol } from './handlers/follow-up-insert-file-ibersol';


export interface IMyTaskHandler {
  name: string;
  execute(
    task: Task,
    data: TaskResponse,
    state?: ExecutionState
  ): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class HandlersService {
  constructor(
    mayStayPendingHandler: MayStayPendingHandler,
    classifyProductUsersHandler: ClassifyProductUsersHandler,
    registerExpirationDate2: RegisterExpirationDate2,
    createNewBatch: CreateNewBatch,
    createNewBatchTraceability: CreateNewBatchTraceability,
    followUpInsertSupplier: FollowUpInsertSupplier,
    followUpTaskPlaceTags: FollowUpTaskPlaceTags,
    generateTechnicalSheet2: GenerateTechnicalSheet2,
    followUpTaskInsertExpiration2: FollowUpTaskInsertExpiration2,
    followUpFinishUnfreezing2: FollowUpFinishUnfreezing2,
    registerBatchForProduction: RegisterBatchForProduction,
    followUpTaskProcessExpiration2: FollowUpTaskProcessExpiration2,
    createSectionsTasks: CreateSectionsTasks,
    followUpTaskControlWarmTemperature2: FollowUpTaskControlWarmTemperature2,
    followUpInsertNonConformities: FollowUpInsertNonConformities,
    followUpTaskPrintTags: FollowUpTaskPrintTags,
    followEquipmentMaintenance: FollowEquipmentMaintenance,
    followUpInsertHaccpEmailTo: FollowUpInsertHaccpEmailTo,
    followUpInsertDocsEmailTo: FollowUpInsertDocsEmailTo,
    followUpInserFile: FollowUpInserFile,
    followUpFileValidation: FollowUpFileValidation,
    followUpTaskPrintTagsGroupedBatches: FollowUpTaskPrintTagsGroupedBatches,
    createSectionsTasksAhresp: CreateSectionsTasksAhresp,
    createSectionsTasksGeral: CreateSectionsTasksGeral,
    followUpFileIntervention: FollowUpFileIntervention,
    writeOffBatchHandler: WriteOffBatchHandler,
    writeOffGroupedBatchesHandler: WriteOffGroupedBatchesHandler,
    writeOffBatchIbersolHandler: WriteOffBatchIbersolHandler,
    checkCreateEligibilityArea: CheckCreateEligibilityArea,
    followUpConfirmData: FollowUpConfirmData,
    followUpConfirmDataPart2: FollowUpConfirmDataPart2,
    followUpAccessCounterPlatform: FollowUpAccessCounterPlatform,
    followUpFutureCorrection: FollowUpFutureCorrection,
    followUpSigningDeclarations: FollowUpSigningDeclarations,
    followUpTellAboutProject: FollowUpTellAboutProject,
    followUpTagPreview: FollowUpTagPreview,
    followUpAccessBalcaoPlatform: FollowUpAccessBalcaoPlatform,
    followUpInsertStatements: FollowUpInsertStatements,
    createNewBatchIber: CreateNewBatchIber,
    generatePdfPerformanceReviews: GeneratePdfPerformanceReviews,
    CreateSectionsTasksSerunion: CreateSectionsTasksSerunion,
    followUpInserMachine: FollowUpInserMachine,
    followUpInsertHelpEmailTo: FollowUpInsertHelpEmailTo,
    followUpInternalRequest:FollowUpInternalRequest,
    followUpInsertFileIbersol:FollowUpInsertFileIbersol

  ) {
    this.registerHandler(mayStayPendingHandler);
    this.registerHandler(classifyProductUsersHandler);
    this.registerHandler(registerExpirationDate2);
    this.registerHandler(createNewBatch);
    this.registerHandler(createNewBatchTraceability);
    this.registerHandler(followUpInsertSupplier);
    this.registerHandler(followUpTaskPlaceTags);
    this.registerHandler(generateTechnicalSheet2);
    this.registerHandler(followUpTaskInsertExpiration2);
    this.registerHandler(followUpFinishUnfreezing2);
    this.registerHandler(registerBatchForProduction);
    this.registerHandler(followUpTaskProcessExpiration2);
    this.registerHandler(createSectionsTasks);
    this.registerHandler(followUpTaskControlWarmTemperature2);
    this.registerHandler(followUpInsertNonConformities);
    this.registerHandler(followUpTaskPrintTags);
    this.registerHandler(followUpTaskPrintTagsGroupedBatches);
    this.registerHandler(createSectionsTasksAhresp);
    this.registerHandler(createSectionsTasksGeral);
    this.registerHandler(followEquipmentMaintenance);
    this.registerHandler(followUpInsertHaccpEmailTo);
    this.registerHandler(followUpInsertDocsEmailTo);
    this.registerHandler(followUpInserFile);
    this.registerHandler(followUpFileValidation);
    this.registerHandler(followUpFileIntervention);
    this.registerHandler(writeOffBatchHandler);
    this.registerHandler(writeOffGroupedBatchesHandler);
    this.registerHandler(writeOffBatchIbersolHandler);
    this.registerHandler(checkCreateEligibilityArea);
    this.registerHandler(followUpConfirmData);
    this.registerHandler(followUpConfirmDataPart2);
    this.registerHandler(followUpFutureCorrection);
    this.registerHandler(followUpSigningDeclarations);
    this.registerHandler(followUpAccessCounterPlatform);
    this.registerHandler(followUpTellAboutProject);
    this.registerHandler(followUpTagPreview);
    this.registerHandler(followUpAccessBalcaoPlatform);
    this.registerHandler(followUpInsertStatements);
    this.registerHandler(createNewBatchIber);
    this.registerHandler(generatePdfPerformanceReviews);
    this.registerHandler(CreateSectionsTasksSerunion);
    this.registerHandler(followUpInserMachine);
    this.registerHandler(followUpInsertHelpEmailTo);
    this.registerHandler(followUpInternalRequest);
    this.registerHandler(followUpInsertFileIbersol);


  }

  private handlers: Map<string, IMyTaskHandler> = new Map();

  registerHandler(handler: IMyTaskHandler) {
    this.handlers.set(handler.name, handler);
  }

  getHandlerByName(name: string): IMyTaskHandler | undefined {
    return this.handlers.get(name);
  }
}
