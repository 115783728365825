// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { mergeMap, Observable, of, forkJoin } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import {
  CustomTaskService,
  ExecutionState,
} from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { ModalController } from '@ionic/angular';
import cuid from 'cuid';
import moment from 'moment';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldType } from 'src/app/entities/field';
import { FieldsService } from 'src/app/services/fields.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpAccessCounterPlatform implements IMyTaskHandler {
  name = 'follow-up-access-counter-platform';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  pieceToString(piece: any, type: any, data: any) {
    if (piece === 'type') return type.name;
    if (piece === 'product') return data['Produto']?.name || '';
    if (piece === 'expirationDate')
      return moment(data['Data de fim de validade']).format('DD/MM/YYYY HH:mm');
    return '';
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
    const userInfo = this.authenticationService.getUserInfo();

    if (!userInfo) {
      console.error('User info not found');
      return of(null); // Return an observable with a null value
    }

    const type =
      this.customTaskTypesService.getCustomTaskType('confirmDetails2EFN');
    const typeTask =
      this.customTaskTypesService.getCustomTaskType('accessCounterEFN');

    if (!typeTask || !type) {
      console.error('Task types not found');
      return of(null); // Return an observable with a null value
    }

    if (this.customTaskTypesService.canProceed(task.type, data)) {
      // Create a new task with the provided data
      const typeForm = this.createTypeForm(store);

      // First, create the type form
      const sectionObservable = this.fieldsService
        .newField(typeForm, this.authenticationService.getAuthInfo())
        .pipe(
          mergeMap(() => {
            // After form creation, create the follow-up task
            const followUpTask = this.createFollowUpTask(
              task,
              data,
              typeTask,
              typeForm,
              store
            );
            return this.tasksService.newTask(
              followUpTask,
              this.authenticationService.getAuthInfo()
            );
          })
        );

      return sectionObservable;
    }

    return of(null); // Ensure we return an Observable in all cases
  }

  private createTypeForm(store: any) {
    return {
      id: cuid(),
      title: '4 - Acesso à Plataforma Balcão',
      type: FieldType.Form,
      storeId: store?.id,
      userIds: [store?.modules?.utilizadorUdeId],
      style: {
        imageUrl: 'registo_plataforma.svg',
      },
      titleEmptyForm:
        'Parabéns, foi executada a tarefa. Carregue no botão VOLTAR para aceder ao menu principal e avançar para aceder a etapa 5 - Confirme os seus dados.',
    };
  }

  private createFollowUpTask(
    task: Task,
    data: TaskResponse,
    typeTask: any,
    typeForm: any,
    store: any
  ) {
    return {
      id: cuid(),
      type: typeTask.type,
      formId: typeForm.id,
      title: this.getTitle(typeTask, data),
      subtitle: this.getSubtitle(typeTask, data, task),
      startDate: moment().valueOf(),
      endDate: moment().add(1, 'year').valueOf(),
      visualizationDate: moment().add(1, 'year').valueOf(),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: store.id,
        userIds: [store?.modules?.utilizadorUdeId],
      },
      documentsIds: [],
      isCustomType: true,
      superType: undefined,
      description: {
        url: 'https://techguildadm-my.sharepoint.com/:f:/g/personal/techguild_techguildadm_onmicrosoft_com/EnXnklLjfoVKi6uazxWEU0oBDbJrU1nsB3EwI01Plpk4Kw?e=6YQIEB',
      },
    };
  }
}
