<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>Inserir password</h3>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription ">
        <div class="form-group">
          <label class="label">Loja: {{storeCode}}</label>
        </div>
        <div class="form-group">
          <label class="label">Utilizador: {{username}}</label>
        </div>
        <div class="form-group">
          <label class="label">Password:</label>
          <input type="password" class="form-control" placeholder="password" [(ngModel)]="password">
        </div>
        <div class="modalActions">
          <div class="actionsLeft">
          </div>
          <div class="actionsRight">
            <button class="btn btn-ok" (click)="pressSubmit();">OK</button>
            <button class="btn btn-cancel light-grey" (click)="close()">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
