import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'documents-filters-product-lot-modal',
  templateUrl: './documents-filters-product-lot.modal.html',
})
export class DocumentsFiltersProductLotModalComponent {

  products: any[];
  selectedProduct: any;
  productsResponseField = {
    name: 'Produto',
    type: 'product',
  }

  lots: any[];
  selectedBatch: any;
  batchResponseField = {
    name: 'Lote',
    type: 'batch',
  }

  constructor(
    private modalController: ModalController,
    private productsService: ProductsService,
    private navParams: NavParams
  ) {
    this.selectedProduct = this.navParams.data['product'];
    this.products = this.productsService.getProducts();
    this.lots = []; // Initialize lots array
  }

  close() {
    this.modalController.dismiss('close');
  }

  accept() {
    this.modalController.dismiss({
      product: this.selectedProduct,
      batch: this.selectedBatch
    });
  }
}
