import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { StoresService } from 'src/app/services/stores.service';

@Component({
  selector: 'app-expiring-products-summary',
  templateUrl: './expiring-products-summary.component.html',
  styleUrls: ['./expiring-products-summary.component.scss'],
})
export class ExpiringProductsSummaryComponent {
  @Input() expiringProducts: ProductNameAndExpirationDate[] = [];

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private storeService: StoresService
  ) {}

  async navigateToExpiringProducts() {
    await this.closeAllModals();
    this.router.navigate([
      `/tabs/fields/forms/${this.storeService.store.modules.traceabilityTags.breakProductFormId}`,
    ]);
  }

  async closeAllModals() {
    let topModal = await this.modalCtrl.getTop();
    while (topModal) {
      await topModal.dismiss();
      topModal = await this.modalCtrl.getTop();
    }
  }
}

export interface ProductNameAndExpirationDate {
  name: string;
  expirationDate: number;
}
