import cuid from 'cuid';
import moment from 'moment';
import { Batch } from '../batch';

export class Task {
  nonConformities: string[] = [];
  causes: string[] = [];
  corrections: string[] = [];
  visualizationDate?: any;
  description?: TaskDescription;
  equipmentId?: string;
  machineId?: string;
  zoneId?: string;
  responses?: TaskResponse[];
  documentsIds?: string[];
  printsCount?: number;

  isLoading?: boolean;
  inactive?: boolean;
  isCustomType?: boolean;

  productId?: string;
  batchId?: string;

  generationType?: string;
  createdInStore?: boolean;
  subtitle?: string;
  superType?: string;

  pendingResponses?: TaskResponse[];
  precedingTaskId?: string;
  isChild?: boolean;
  responseProductId?: string;

  okRangeMin?: number | string;
  okRangeMax?: number | string;

  canceled?: boolean;

  batch?: Batch;

  precedingTaskSociety?: string;

  precedingTaskResponse?: { [key: string]: any };
  precedingTaskData?: { [key: string]: any };

  expirationDate?: string;
  groupedBatchesLots?: string;

  [key: string]: any; // Index signature to handle dynamic properties
  public id: string;
  constructor(
    public formId: string,
    public type: string,
    public title: string,
    public startDate: any,
    public endDate: any,
    public periodicity: string,
    public target: { storeId: string; userIds: string[] },
    public page?: string // Added page property
  ) {
    this.id = cuid();
  }
}

export interface TaskDescription {
  documentId?: string;
  url?: string;
  text?: string;
  videoIds?: string[];
  download?: string;
}

export interface InternalTaskResponseFields {
  isInConformity: boolean;
  clientDate: number;
  employeeId: string;
  date?: number;
  isClosed?: boolean;
}

export interface UserInputTaskResponseFields {
  picturesIds: string[];
  invoicePicturesIds?: string[];
  ticketPicturesIds?: string[];
  productPicturesIds?: string[];
  non_conformity?: string;
  causes?: string;
  correction?: string;
  ncCommentary?: string;
  commentary?: string;
  lateCommentary?: string;
}

export function hasNonConformitiesInformation(response: TaskResponse) {
  return (
    response.non_conformity ||
    response.causes ||
    response.correction ||
    response.ncCommentary
  );
}

export class NonProgrammedTask extends Task {
  private constructor(
    public override formId: string,
    public override type: string,
    public override title: string,
    public override startDate: any,
    public override endDate: any,
    public override periodicity: string,
    public override target: { storeId: string; userIds: string[] }
  ) {
    super(formId, type, title, startDate, endDate, periodicity, target);
  }

  static createWithDefaultDates(
    formId: string,
    type: string,
    title: string,
    subtitle: string | null | undefined,
    target: { storeId: string; userIds: string[] }
  ): NonProgrammedTask {
    const task = new NonProgrammedTask(
      formId,
      type,
      title,
      moment().valueOf(),
      moment().add(1, 'hour').valueOf(),
      'Única',
      target
    );
    if (subtitle) task.subtitle = subtitle;
    return task;
  }
}

export function getCleanDesignation(clean: Task) {
  return `${clean.title}`;
}

export interface TaskResponse {
  isInConformity: boolean;
  clientDate: number;
  employeeId: string;
  date?: number;
  isClosed?: boolean;
  picturesIds: string[];
  invoicePicturesIds?: string[];
  ticketPicturesIds?: string[];
  productPicturesIds?: string[];
  non_conformity?: string;
  causes?: string;
  correction?: string;
  ncCommentary?: string;
  commentary?: string;
  lateCommentary?: string;
  [key: string]: any; // Allow dynamic properties
}

export type TaskClosedCallback = (task: Task) => void;
export type TaskCreatedCallback = (task: Task) => void;

export interface ResponseField {
  name: string;
  nameEs?: string;
  placeholder?: string;
  placeholderEs?: string;
  type: string | any;
  required?: boolean;
  autofill?: string;
  condition?: string;
  selectOptions?: string[];
  selectOptionsGetter?: string;
  selectHeader?: string;
  selectSubheader?: string;
  selectMessage?: string;
  selectInterface?: 'alert' | 'popover' | 'action-sheet';
  other?: string;
  multiple?: boolean;
  smart?: boolean;
  countGetter?: string;
  responseValidation?: {
    maxChars?: {
      count: number;
    };
    minChars?: {
      count: number;
    };
    email?: boolean;
    phone?: boolean;
    required?: boolean;
  };
  subfield?: any;
  subfields?: any;
  locked?: boolean;
  isGrouper?: boolean;
  isHiding?: boolean;
  subtype?: string;
  format?: string;
  template?: any;
  noText?: boolean;
  dateFormat?: string;
  batchPhoto?: boolean;
}

export interface TaskAndResponseObject {
  task: Task;
  response: TaskResponse;
}
