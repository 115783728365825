import { Component, Input, OnInit } from '@angular/core';
import { PrintTorribaService } from '../../services/print-torriba.service'; // Update the path as necessary
import { PrintSovenaService } from '../../services/print-sovena.service'; // Update the path as necessary
import { getSociedadeByCode } from '../../sdk/myharvest'; // Update the path as necessary
import { DeviceInfo, Device } from '@capacitor/device'; // Import DeviceInfo and Device



@Component({
  selector: 'app-receipt-torriba',
  templateUrl: './receipt-torriba.component.html',
})
export class ReceiptTorribaComponent implements OnInit {
  @Input() task: any;
  @Input() response: any;
  @Input() producer: any;
  @Input() buyer: any;
  @Input() plot: any;
  @Input() device: any;


  sovenaQRCode: string = ''; // Provide a default value
  society: any;
deviceInfo: any;

  constructor(
    public printTorribaService: PrintTorribaService,
    public printSovenaService: PrintSovenaService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.task.type === 'inProductApanhaAzeitonaNF') {
      this.sovenaQRCode = await this.printSovenaService.generateQRCode(
        this.task,
        this.response,
        this.producer,
        this.buyer
      );
      this.society = this.getSocietyByCode(this.response.Sociedade[0]);
    }
  }

  getSocietyByCode(societyCode: string): any {
    return getSociedadeByCode(this.producer.societies, societyCode);
  }
}
