import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of, from, forkJoin } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { FieldType } from 'src/app/entities/field';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { mergeMap, toArray } from 'rxjs/operators';

// Include your helper functions here
function getListTasksIMQuizTaskTypeFromSectionName(sectionForm: any) {
  if (sectionForm.title === 'Armazém') {
    return 'listPeriodicCleaningTasksArmazemIMQuiz';
  }
  if (sectionForm.title === 'Frescos LS') {
    return 'listPeriodicCleaningTasksFrescosLSIMQuiz';
  }
  if (sectionForm.title === 'Talho') {
    return 'listPeriodicCleaningTasksTalhoIMQuiz';
  }
  if (sectionForm.title === 'Peixaria') {
    return 'listPeriodicCleaningTasksPeixariaIMQuiz';
  }
  if (sectionForm.title === 'Charcutaria e queijo') {
    return 'listPeriodicCleaningTasksCharcutariaEQueijoIMQuiz';
  }
  if (sectionForm.title === 'Frutas e legumes') {
    return 'listPeriodicCleaningTasksFrutasELegumesIMQuiz';
  }
  if (sectionForm.title === 'Pronto a comer') {
    return 'listPeriodicCleaningTasksProntoAComerIMQuiz';
  }
  if (sectionForm.title === 'Bar e cafetaria') {
    return 'listPeriodicCleaningTasksBarECafetariaIMQuiz';
  }
  if (sectionForm.title === 'Padaria') {
    return 'listPeriodicCleaningTasksPadariaIMQuiz';
  }
  return undefined;
}

function getMachineListIMQuizTaskTypeFromSectionName(sectionForm: any) {
  if (sectionForm.title === 'Talho') {
    return 'machineListTalhoIMQuiz';
  }
  if (sectionForm.title === 'Peixaria') {
    return 'machineListPeixariaIMQuiz';
  }
  if (sectionForm.title === 'Charcutaria e queijo') {
    return 'machineListCharcutariaEQueijoIMQuiz';
  }
  if (sectionForm.title === 'Frutas e legumes') {
    return 'machineListFrutasELegumesIMQuiz';
  }
  if (sectionForm.title === 'Pronto a comer') {
    return 'machineListProntoAComerIMQuiz';
  }
  if (sectionForm.title === 'Bar e cafetaria') {
    return 'machineListBarECafetariaIMQuiz';
  }
  if (sectionForm.title === 'Padaria') {
    return 'machineListPadariaIMQuiz';
  }
  return undefined;
}

function getDailyCleaningListIMQuizTaskTypeFromSectionName(sectionForm: any) {
  if (sectionForm.title === 'Talho') {
    return 'dailyCleaningListTalhoIMQuiz';
  }
  if (sectionForm.title === 'Peixaria') {
    return 'dailyCleaningListPeixariaIMQuiz';
  }
  if (sectionForm.title === 'Charcutaria e queijo') {
    return 'dailyCleaningListCharcutariaEQueijoIMQuiz';
  }
  if (sectionForm.title === 'Frutas e legumes') {
    return 'dailyCleaningListFrutasELegumesIMQuiz';
  }
  if (sectionForm.title === 'Pronto a comer') {
    return 'dailyCleaningListProntoAComerIMQuiz';
  }
  if (sectionForm.title === 'Bar e cafetaria') {
    return 'dailyCleaningListBarECafetariaIMQuiz';
  }
  if (sectionForm.title === 'Padaria') {
    return 'dailyCleaningListPadariaIMQuiz';
  }
  return undefined;
}

@Injectable({
  providedIn: 'root',
})
export class CreateSectionsTasks implements IMyTaskHandler {
  name = 'create-sections-tasks';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.warn('User info not found');
      return of(null);
    }
  
    const sections = data['Que secções existem?'];
    if (!sections || !Array.isArray(sections)) {
      console.warn('No sections found in data');
      return of(null);
    }
  
    const existingSections = new Set();
  
    // Obtenha todos os campos existentes para evitar duplicação
    const existingFields = this.fieldsService.getFieldsWithoutChildren(); // ou outro método para obter os campos
  
    const sectionObservables = sections
      .filter((section) => !existingSections.has(section)) // Skip já processados
      .map((section) => {
        existingSections.add(section);
  
        // Verifica se já existe um field com o mesmo título
        const isFieldExisting = existingFields.some((field) => field.title === section);
  
        if (isFieldExisting) {
          return of(null);
        }
  
        // Cria o form para a seção
        const sectionForm = this.createSectionForm(section);
  
        return this.fieldsService.newField(sectionForm, this.authenticationService.getAuthInfo()).pipe(
          mergeMap(() => this.createTasksForSection(sectionForm, task, userInfo, section))
        );
      });
  
    return forkJoin(sectionObservables);
  }
  
  private createSectionForm(section: string): any {
    return {
      id: cuid(),
      title: section,
      type: FieldType.Form,
      storeId: this.storeService.store.id,
    };
  }
  
  private createTasksForSection(
    sectionForm: any,
    task: Task,
    userInfo: any,
    sectionTitle: string
  ): Observable<any[]> {
    const taskTypes = [
      getListTasksIMQuizTaskTypeFromSectionName(sectionForm),
      getMachineListIMQuizTaskTypeFromSectionName(sectionForm),
      getDailyCleaningListIMQuizTaskTypeFromSectionName(sectionForm),
      'coldMachineListIMQuiz',
      'sectionInformationIMQuiz',
    ];
  
    const taskObservables = taskTypes
      .filter((type) => type)
      .map((type:any) => {
        const taskType = this.customTaskTypesService.getCustomTaskType(type);
        if (taskType) {
          return this.createNewTask(taskType, sectionForm, task, userInfo, sectionTitle);
        } else {
          console.warn(`Task type ${type} not found`);
          return of(null);
        }
      });
  
    return forkJoin(taskObservables);
  }
  

  private createNewTask(
    taskType: any,
    sectionForm: any,
    task: Task,
    userInfo: any,
    sectionTitle?: string
  ): Observable<any> {
    const newTaskData = {
      id: cuid(),
      type: taskType.type,
      formId: sectionForm.id,
      title: taskType.name + (sectionTitle ? ' ' + sectionTitle : ''),
      startDate: task.startDate,
      endDate: task.endDate,
      visualizationDate: task.visualizationDate,
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: sectionForm.storeId,
        userIds: getTargetUser(this.storeService.store, userInfo),
      },
      documentsIds: [],
      isCustomType: true,
    };
    return this.tasksService.newTask(newTaskData, this.authenticationService.getAuthInfo());
  }
}
