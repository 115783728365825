import { Injectable, Injector } from '@angular/core';
import { Observable, from, of, forkJoin, firstValueFrom } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { catchError, concatMap, flatMap, mergeMap, retry, tap } from 'rxjs/operators';
import { TasksService } from './tasks.service';
import { MediaService } from './media.service';
import { ProductsService } from './products.service';
import { FieldsService } from './fields.service';
import { Task, TaskResponse } from '../entities/tasks/task';
import { Product } from '../entities/product';
import { Field } from '../entities/field';
import { Media } from '../entities/media';
import { SosProduct } from '../entities/sos-product';
import { AuthenticationInfo } from '../entities/authentication-info';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { MachineIndicator, SensorsService } from './sensors.service';

interface UploadInfo {
  type: string;
  authInfo: AuthenticationInfo;
  originalTryTimestamp: number;
}

export abstract class TaskUploadInfo implements UploadInfo {
  authInfo: AuthenticationInfo;
  originalTryTimestamp: number;
  type: string;
  task: Task;

  constructor(
    task: Task,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number,
    type: string
  ) {
    this.task = task;
    this.authInfo = authInfo;
    this.originalTryTimestamp = originalTryTimestamp;
    this.type = type;
  }
}

export class UpdateTaskUploadInfo extends TaskUploadInfo {
  constructor(
    task: Task,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    super(task, authInfo, originalTryTimestamp, 'updateTask');
  }
}

export class NewTaskUploadInfo extends TaskUploadInfo {
  constructor(
    task: Task,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    super(task, authInfo, originalTryTimestamp, 'newTask');
  }
}

export class RespondTaskUploadInfo extends TaskUploadInfo {
  response: TaskResponse;
  constructor(
    task: Task,
    response: TaskResponse,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    super(task, authInfo, originalTryTimestamp, 'answerTask');
    this.response = response;
  }
}

export class NewMediaUploadInfo implements UploadInfo {
  authInfo: AuthenticationInfo;
  originalTryTimestamp: number;
  type = 'newMedia';
  id: string;
  data?: Media;

  constructor(
    id: string,
    data: Media,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    this.id = id;
    this.data = data;
    this.authInfo = authInfo;
    this.originalTryTimestamp = originalTryTimestamp;
  }
}

export class NewProductUploadInfo implements UploadInfo {
  authInfo: AuthenticationInfo;
  originalTryTimestamp: number;
  type = 'newProduct';
  product: Product;

  constructor(
    product: Product,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    this.product = product;
    this.authInfo = authInfo;
    this.originalTryTimestamp = originalTryTimestamp;
  }
}

export class NewFieldUploadInfo implements UploadInfo {
  authInfo: AuthenticationInfo;
  originalTryTimestamp: number;
  type = 'newField';
  field: Field;

  constructor(
    field: Field,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    this.field = field;
    this.authInfo = authInfo;
    this.originalTryTimestamp = originalTryTimestamp;
  }
}

export class NewMachineUploadInfo implements UploadInfo {
  authInfo: AuthenticationInfo;
  originalTryTimestamp: number;
  type = 'newMachine';
  machineData: MachineIndicator;

  constructor(
    machineData: MachineIndicator,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) {
    this.machineData = machineData;
    this.authInfo = authInfo;
    this.originalTryTimestamp = originalTryTimestamp;
  }
}


@Injectable()
export class UploadAll {
  tasksService!: TasksService;
  mediaService!: MediaService;
  productsService!: ProductsService;
  fieldsService!: FieldsService;
  sensorsService!: SensorsService;
  uploading: boolean = false;

  constructor(private storage: Storage, private injector: Injector) {
    setTimeout(() => {
      this.tasksService = this.injector.get(TasksService);
      this.productsService = this.injector.get(ProductsService);
      this.fieldsService = this.injector.get(FieldsService);
      this.sensorsService = this.injector.get(SensorsService);
      this.mediaService = this.injector.get(MediaService);
      this.mediaService.setUploadAll(this); // Set UploadAll in MediaService
    }, 1000);
  }

  addPendingUpdateTaskRequest(
    taskBundle: UpdateTaskUploadInfo
  ): Observable<any> {
    return from(
      this.storage.set(`update_task_${taskBundle.task.id}`, taskBundle)
    );
  }

  addPendingAnswerTaskRequest(
    taskBundle: RespondTaskUploadInfo
  ): Observable<any> {
    return from(
      this.storage.set(`answer_task_${taskBundle.task.id}`, taskBundle)
    );
  }

  addPendingNewTaskRequest(taskBundle: NewTaskUploadInfo) {
    return from(this.storage.set(`new_task_${taskBundle.task.id}`, taskBundle));
  }

  addPendingNewPictureRequest(pictureBundle: NewMediaUploadInfo) {
    return from(
      this.storage.set(`new_picture_${pictureBundle.id}`, pictureBundle)
    );
  }

  addPendingNewProductRequest(productBundle: NewProductUploadInfo) {
    return from(
      this.storage.set(`new_product_${productBundle.product.id}`, productBundle)
    );
  }

  addPendingNewFieldRequest(fieldBundle: NewFieldUploadInfo) {
    return from(
      this.storage.set(`new_field_${fieldBundle.field.id}`, fieldBundle)
    );
  }

  addPendingNewMachineRequest(machineBundle: NewMachineUploadInfo) {
    return from(
      this.storage.set(`new_machine_${machineBundle.machineData.id}`, machineBundle)
    );
  }

  async upload() {
    this.uploading = true;
    let keyValuePairArray: { key: string; value: UploadInfo }[] = [];
    const keys = (await this.storage.keys()).filter(
      (key) =>
        key.includes('answer_task_') ||
        key.includes('new_task_') ||
        key.includes('new_product_') ||
        key.includes('new_field_') ||
        key.includes('new_picture_') ||
        key.includes('update_task_') ||
        key.includes('new_machine_')
    );
    for (let key of keys) {
      const value = await this.storage.get(key);
      keyValuePairArray.push({ key, value });
    }
    const sortedKeyValuePairArray = keyValuePairArray.sort(
      (a, b) => a.value.originalTryTimestamp - b.value.originalTryTimestamp
    );

    for (const { key, value } of sortedKeyValuePairArray) {
      try {
        await this.processUploadInfo(value, key);
      } catch (error) {
        console.error('Error processing upload:', error);
      }
    }

    this.uploading = false;
  }

  private async processUploadInfo(uploadInfo: UploadInfo, key: string) {
    switch (uploadInfo.type) {
      case 'updateTask':
        await this.handleUpdateTaskUpload(
          uploadInfo as UpdateTaskUploadInfo,
          key
        );
        break;
      case 'answerTask':
        await this.handleAnswerTaskUpload(
          uploadInfo as RespondTaskUploadInfo,
          key
        );
        break;
      case 'newTask':
        await this.handleNewTaskUpload(uploadInfo as NewTaskUploadInfo, key);
        break;
      case 'newMedia':
        await this.handleNewMediaUpload(uploadInfo as NewMediaUploadInfo, key);
        break;
      case 'newProduct':
        await this.handleNewProductUpload(
          uploadInfo as NewProductUploadInfo,
          key
        );
        break;
      case 'newField':
        await this.handleNewFieldUpload(uploadInfo as NewFieldUploadInfo, key);
        break;
      case 'newMachine':
          await this.handleNewMachineUpload(uploadInfo as NewMachineUploadInfo, key);
          break;
      default:
        console.warn('Unknown upload type:', uploadInfo.type);
        console.warn('Key:', key);
        break;
    }
  }

  handleNewFieldUpload(uploadInfo: NewFieldUploadInfo, key: string) {
    return firstValueFrom(
      this.fieldsService.newField(uploadInfo.field, uploadInfo.authInfo).pipe(
        flatMap((result) => {
          if (result !== null) {
            return from(this.storage.remove(key)).pipe(retry(3));
          }
          return of(null);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      )
    );
  }
  handleNewMachineUpload(uploadInfo: NewMachineUploadInfo, key: string) {
    return firstValueFrom(
      this.sensorsService.newMachine(uploadInfo.machineData, uploadInfo.authInfo).pipe(
        flatMap((result) => {
          if (result !== null) {
            return from(this.storage.remove(key)).pipe(retry(3));
          }
          return of(null);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      )
    );
  }

  handleNewProductUpload(uploadInfo: NewProductUploadInfo, key: string) {
    return firstValueFrom(
      this.productsService
        .newProduct(uploadInfo.product, uploadInfo.authInfo)
        .pipe(
          flatMap((result) => {
            if (result !== null) {
              return from(this.storage.remove(key)).pipe(retry(3));
            }
            return of(null);
          }),
          catchError((err) => {
            console.log(err);
            return of(null);
          })
        )
    );
  }

  handleNewMediaUpload(uploadInfo: NewMediaUploadInfo, key: string) {
    if (uploadInfo.data) {
      return firstValueFrom(
        this.mediaService
          .submitPicture(uploadInfo.id, uploadInfo.data, uploadInfo.authInfo)
          .pipe(
            flatMap((result) => {
              if (result !== null) {
                return from(this.storage.remove(key)).pipe(retry(3));
              }
              return of(null);
            }),
            catchError((err) => {
              console.log(err);
              return of(null);
            })
          )
      );
    } else {
      return from(this.mediaService.readPicture(uploadInfo.id)).pipe(
        mergeMap((data) => {
          return this.handleSubmitPicture(data, key);
        })
      );
    }
  }

  handleNewTaskUpload(uploadInfo: NewTaskUploadInfo, key: string) {
    return firstValueFrom(
      this.tasksService.newTask(uploadInfo.task, uploadInfo.authInfo).pipe(
        flatMap((result) => {
          if (result !== null) {
            return from(this.storage.remove(key)).pipe(retry(3));
          }
          return of(null);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      )
    );
  }

  handleUpdateTaskUpload(uploadInfo: UpdateTaskUploadInfo, key: string) {
    return firstValueFrom(
      this.tasksService.updateTask(uploadInfo.task, uploadInfo.authInfo).pipe(
        flatMap((result) => {
          if (result !== null) {
            return from(this.storage.remove(key)).pipe(retry(3));
          }
          return of(null);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      )
    );
  }

  handleAnswerTaskUpload(uploadInfo: RespondTaskUploadInfo, key: string) {
    return firstValueFrom(
      this.tasksService
        .submitTask(uploadInfo.task, uploadInfo.response, uploadInfo.authInfo)
        .pipe(
          flatMap((result) => {
            if (result !== null) {
              return from(this.storage.remove(key)).pipe(retry(3));
            }
            return of(null);
          }),
          catchError((err) => {
            console.log(err);
            return of(null);
          })
        )
    );
  }

  upload2() {
    this.uploading = true;
    let newObservables: Observable<any>[] = [];
    let updateObservables: Observable<any>[] = [];
    let submitObservables: Observable<any>[] = [];
    let deleteObservables: Observable<any>[] = [];
    return from(
      this.storage.forEach((value, key) => {
        if (key.includes('answer_task_')) {
          const obs = this.tasksService
            .submitTask(value.task, value.response, value.authInfo)
            .pipe(
              flatMap((result) => {
                if (result !== null) {
                  return from(this.storage.remove(key)).pipe(retry(3));
                }
                return of(null);
              }),
              catchError((err) => {
                console.log(err);
                return of(null);
              })
            );
          submitObservables.push(obs);
        } else if (key.includes('new_task_')) {
          const obs = this.tasksService
            .newTask(value.task, value.authInfo)
            .pipe(
              flatMap((result) => {
                if (result !== null) {
                  return from(this.storage.remove(key)).pipe(retry(3));
                }
                return of(null);
              }),
              catchError((err) => {
                console.log(err);
                return of(null);
              })
            );
          newObservables.push(obs);
        } else if (key.includes('new_product_')) {
          const obs = this.productsService
            .newProduct(value.product, value.authInfo)
            .pipe(
              flatMap((result) => {
                if (result !== null) {
                  return from(this.storage.remove(key)).pipe(retry(3));
                }
                return of(null);
              }),
              catchError((err) => {
                console.log(err);
                return of(null);
              })
            );
          newObservables.push(obs);
        } else if (key.includes('new_field_')) {
          const obs = this.fieldsService
            .newField(value.field, value.authInfo)
            .pipe(
              flatMap((result) => {
                if (result !== null) {
                  return from(this.storage.remove(key)).pipe(retry(3));
                }
                return of(null);
              }),
              catchError((err) => {
                console.log(err);
                return of(null);
              })
            );
          newObservables.push(obs);
        }else if (key.includes('new_machine_')) {
          const obs = this.sensorsService
            .newMachine(value.machine, value.authInfo)
            .pipe(
              flatMap((result) => {
                if (result !== null) {
                  return from(this.storage.remove(key)).pipe(retry(3));
                }
                return of(null);
              }),
              catchError((err) => {
                console.log(err);
                return of(null);
              })
            );
          newObservables.push(obs);
        } else if (key.includes('new_picture_')) {
          let obs;
          if (!value.data) {
            obs = from(this.mediaService.readPicture(value.id)).pipe(
              flatMap((data) => {
                value.data = data;
                return this.handleSubmitPicture(value, key);
              })
            );
          } else {
            obs = this.handleSubmitPicture(value, key);
          }

          submitObservables.push(obs);
        } else if (key.includes('update_task_')) {
          const obs = this.tasksService
            .updateTask(value.task, value.authInfo)
            .pipe(
              flatMap((result) => {
                if (result !== null) {
                  return from(this.storage.remove(key)).pipe(retry(3));
                }
                return of(null);
              }),
              catchError((err) => {
                console.log(err);
                return of(null);
              })
            );
          updateObservables.push(obs);
        }
      })
    ).pipe(
      concatMap(() => {
        if (newObservables.length === 0) {
          return of(null);
        }
        return forkJoin(newObservables);
      }),
      concatMap(() => {
        if (updateObservables.length === 0) {
          return of(null);
        }
        return forkJoin(updateObservables);
      }),
      concatMap(() => {
        if (submitObservables.length === 0) {
          return of(null);
        }
        return forkJoin(submitObservables);
      }),
      tap(() => {
        this.uploading = false;
      })
    );
  }
  handleSubmitPicture = (value: any, key: any) => {
    return this.mediaService
      .submitPicture(value.id, value.data, value.token)
      .pipe(
        flatMap((result) => {
          if (result !== null) {
            return from(this.storage.remove(key)).pipe(retry(3));
          }
          return of(null);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      );
  };
}
