import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular"; // Updated imports for Ionic 5/6/7/8
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators"; // Import debounceTime operator
import { StoresService } from "src/app/services/stores.service";

@Component({
  selector: 'app-product-instances-modal', // Update selector naming convention
  templateUrl: 'product-instances.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductInstancesModal {

  searchControl = new FormControl();
  searchResult: any[] = [];
  searchValue: string = '';
  product: any; // Adjust type according to your data structure

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private translateService: TranslateService,
    private storeService: StoresService
  ) {
    this.product = this.navParams.get('product');
    this.searchControl.valueChanges.pipe(
      debounceTime(300) // Add debounceTime to delay search input handling
    ).subscribe(search => {
      if (!search) {
        this.searchResult = [];
      } else {
        // TODO: Implement logic to return instances of product based on search
        // Example: this.storeService.getProductInstances(search).subscribe(result => this.searchResult = result);
      }
    });
  }

  close() {
    this.modalController.dismiss();
  }

  pressProductInstance(productInstance: any) {
    // TODO: Handle what happens when a product instance is selected
    console.log('Selected product instance:', productInstance);
  }

  pressSubmit() {
    // TODO: Implement submit logic if needed
    this.modalController.dismiss({ /* pass data if needed */ });
  }

  getTranslation(key: string) {
    return this.translateService.instant(key);
  }

}
