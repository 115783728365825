import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { environment } from 'src/environments/environment';
import { JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';

interface TemperatureIndicator {
  apiId?:string;
  okRange: { min: number; max: number };
  alertRange: { min: number; max: number };
  dangerRange: { min: number; max: number };
}

export interface MachineIndicator {
  lastReading: any;
  id: string;
  machine: string;
  type: string;
  temperatureMeasurements: { value: string; date: string }[];
  temperatureIndicator: TemperatureIndicator;
  zoneId: string;
  infoMachine: {
    categoria: string;
  };
  infoSupplier: Array<{
    name: string;
    email: string[];
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class SensorsService {
  private etag = '';
  private cache = new Map<string, MachineIndicator>();

  tempFiltersMap: any = {
    Ok: (indicator: MachineIndicator) => {
      const result = this.isOkRange(indicator);
      console.log("Checking Ok range for:", indicator, "Result:", result);
      return result;
    },
    
    Alerta: (indicator: MachineIndicator) => {
      const isNotOk = !this.isOkRange(indicator);
      const isAlarm = this.isAlarmRange(indicator);
      const result = isNotOk && isAlarm;
      console.log("Checking Alerta range for:", indicator, "Not Ok:", isNotOk, "Is Alarm:", isAlarm, "Result:", result);
      return result;
    },
    
    Perigo: (indicator: MachineIndicator) => {
      const isNotOk = !this.isOkRange(indicator);
      const isNotAlarm = !this.isAlarmRange(indicator);
      const isDanger = this.isDangerRange(indicator);
      const result = isNotOk && isNotAlarm && isDanger;
      console.log("Checking Perigo range for:", indicator, "Not Ok:", isNotOk, "Not Alarm:", isNotAlarm, "Is Danger:", isDanger, "Result:", result);
      return result;
    }
  };
  

  static getMachineById: any;
  static getMachineByName: any;

  constructor(private http: AuthenticatedHttpService, protected envService: EnvironmentService) {}

  getMachineIndicatorsFromStore(
    companyId: string,
    storeId: string
  ): Observable<void> {
    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/machines`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );
    return this.http.get(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const indicators: MachineIndicator[] = response.body || [];
        indicators.forEach((i) => this.cache.set(i.id, i));
      }),
      map(() => void 0)
    );
  }

  getTemperatureMachinesIndicators(filter: string): MachineIndicator[] {
    console.log(`Applying filter: ${filter}`);
    
    // Verifique se o filtro existe em tempFiltersMap antes de tentar aplicá-lo
    if (!this.tempFiltersMap[filter]) {
      console.warn(`Filter '${filter}' not found in tempFiltersMap`);
      return [];
    }
  
    const filteredIndicators = Array.from(this.cache.values()).filter((indicator) => {
      // Filtra apenas os indicadores que têm temperatureIndicator.apiId definido
      if (!indicator.temperatureIndicator || !indicator.temperatureIndicator.apiId) {
        return false;
      }
  
      // Verifica se lastReading e lastReading.value estão definidos
      if (!indicator.lastReading || typeof indicator.lastReading.value !== 'number') {
        console.warn("Missing lastReading or lastReading.value for indicator:", indicator);
        return false;
      }
  
      const temperatureValue = indicator.lastReading.value;
      let inRange = false;
  
      // Checa o intervalo de acordo com o filtro
      if (filter === 'Ok') {
        const range = indicator.temperatureIndicator.okRange;
        inRange = temperatureValue >= range.min && temperatureValue <= range.max;
      } else if (filter === 'Alerta') {
        const range = indicator.temperatureIndicator.alertRange;
        inRange = temperatureValue >= range.min && temperatureValue <= range.max;
      } else if (filter === 'Perigo') {
        const range = indicator.temperatureIndicator.dangerRange;
        inRange = temperatureValue >= range.min && temperatureValue <= range.max;
      }
  
      console.log(`Indicator with apiId:`, indicator, `Temperature Value: ${temperatureValue}`, `Filter: ${filter}`, `In Range: ${inRange}`);
      return inRange;
    });
  
    console.log(`Filtered Indicators for ${filter} with apiId:`, filteredIndicators);
    return filteredIndicators;
  }
  
  
  

  getMachinesName(): string[] {
    return Array.from(this.cache.values()).map((machine) => machine.machine);
  }

  getMachinesByCategory(categoryMachine: string): string[] {
    return Array.from(this.cache.values())
      .filter(
        (machine) =>
          machine.infoMachine?.categoria?.trim() === categoryMachine.trim()
      ) // Filtra pelas máquinas que correspondem à categoria
      .map((machine) => machine.machine); // Mapeia apenas o nome da máquina
  }

  getMachinesSupplier(machineName: string): string[] {
    // Filtrar para encontrar a máquina correspondente pelo nome
    const machine = Array.from(this.cache.values()).find(
      (machine) => machine.machine.trim() === machineName.trim()
    );

    // Verificar se a máquina foi encontrada e se infoSupplier existe como array de objetos
    if (machine && Array.isArray(machine.infoSupplier)) {
      // Mapear para obter os nomes dos fornecedores
      return machine.infoSupplier.map((supplier) => supplier.name);
    }

    // Se não encontrar ou não houver fornecedores, retornar um array vazio
    return [];
  }

  getMachinesSupplierEmail(supplierName: string): string[] {
    // Iterar sobre todas as máquinas no cache
    const machine = Array.from(this.cache.values()).find(
      (machine) =>
        Array.isArray(machine.infoSupplier) &&
        machine.infoSupplier.some(
          (supplier) => supplier.name.trim() === supplierName.trim()
        )
    );

    // Verificar se a máquina foi encontrada e se o fornecedor existe
    if (machine && Array.isArray(machine.infoSupplier)) {
      // Encontrar o fornecedor pelo nome
      const supplier = machine.infoSupplier.find(
        (supplier) => supplier.name.trim() === supplierName.trim()
      );

      // Verificar se o fornecedor foi encontrado e se ele tem emails
      if (supplier && Array.isArray(supplier.email)) {
        return supplier.email; // Retornar a lista de emails
      }
    }

    // Se não encontrar, retornar um array vazio
    return [];
  }

  getOilMachinesWithNameRegex(): string[] {
    const regex = new RegExp('Fritadeira', 'i'); // 'i' flag for case-insensitive search
    return Array.from(this.cache.values())
      .filter((machine) => regex.test(machine.machine))
      .map((filteredMachine) => filteredMachine.machine);
  }

  getMachineById(id: string): MachineIndicator {
    const machine = this.cache.get(id);
    if (!machine) {
      throw new Error(`Machine with id ${id} not found`);
    }
    return machine;
  }

  getMachinesByType(type: string): MachineIndicator[] {
    return Array.from(this.cache.values()).filter(
      (machine) => machine.type === type
    );
  }

  getMachineByName(machineName: string): MachineIndicator | null {
    const machine = Array.from(this.cache.values()).find(
      (m) => m.machine.trim() === machineName.trim()
    );
    return machine || null; // Retorna a máquina encontrada ou null se não houver correspondência
  }

  private isOkRange(indicator: MachineIndicator): boolean {
    if (
      !indicator.temperatureMeasurements ||
      !indicator.temperatureMeasurements.length
    ) {
      return false;
    }
    const temperatureValue = parseFloat(
      indicator.temperatureMeasurements[0].value
    );
    const { min, max } = indicator.temperatureIndicator.okRange;
    return temperatureValue >= min && temperatureValue < max;
  }

  private isAlarmRange(indicator: MachineIndicator): boolean {
    if (
      !indicator.temperatureMeasurements ||
      !indicator.temperatureMeasurements.length
    ) {
      return false;
    }
    const temperatureValue = parseFloat(
      indicator.temperatureMeasurements[0].value
    );
    const { min, max } = indicator.temperatureIndicator.alertRange;
    return temperatureValue >= min && temperatureValue < max;
  }

  private isDangerRange(indicator: MachineIndicator): boolean {
    if (
      !indicator.temperatureMeasurements ||
      !indicator.temperatureMeasurements.length
    ) {
      return false;
    }
    const temperatureValue = parseFloat(
      indicator.temperatureMeasurements[0].value
    );
    const { min, max } = indicator.temperatureIndicator.dangerRange;
    return temperatureValue >= min && temperatureValue < max;
  }
}
