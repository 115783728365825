import { Injectable, OnDestroy } from '@angular/core';
import {
  LocalNotifications,
  ScheduleOptions,
} from '@capacitor/local-notifications';
import { Platform } from '@ionic/angular';
import { getLastSecondaryExpirationDate, Product } from '../entities/product';
import { ProductsService } from './products.service';
import { AuthenticationService } from './authentication';
import { SyncingService } from './syncing';
import { Subscription } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class ProductExpirationNotificationService implements OnDestroy {
  private notificationChannelId = 'product-expiration-channel';
  private maxScheduledNotifications: number = 8;

  private authSubscription: Subscription | null = null;
  private syncSubscription: Subscription | null = null;
  constructor(
    private platform: Platform,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService,
    private syncingService: SyncingService,
    private envService: EnvironmentService
  ) {
    if (!(this.envService.isMachineIbersol() || this.envService.isMachineAWS)) {
      return;
    }
    this.initialize();

    this.syncSubscription = this.syncingService.syncCompleted$.subscribe(
      async () => {
        await this.startService();
      }
    );

    this.authSubscription = this.authenticationService.authState$.subscribe(
      async (isLoggedIn) => {
        if (!isLoggedIn) {
          await this.stopService();
        }
      }
    );
  }

  private async initialize() {
    await this.platform.ready();

    // Request permission to use local notifications
    const permissionStatus = await LocalNotifications.requestPermissions();
    const isGranted = permissionStatus.display === 'granted';
    if (!isGranted) {
      console.warn('Permission not granted to display notifications');
      return;
    }

    // Create notification channel for Android
    if (this.platform.is('android')) {
      await LocalNotifications.createChannel({
        id: this.notificationChannelId,
        name: 'Product Expiration Notifications',
        description: 'Notifications for product expiration dates',
        importance: 5, // Max importance
        visibility: 1, // Public visibility
        sound: 'expiring_product_c22.wav',
        lights: true,
        vibration: true,
      });
    }
  }

  async scheduleNotificationsForUpcomingExpirations() {
    // Sort products by expiration date
    const products = this.productsService
      .getExpiringProductsInNDays(2)
      .filter((p) => {
        const expirationDate = getLastSecondaryExpirationDate(p);
        if (!expirationDate) {
          return false;
        }
        return expirationDate > new Date();
      });

    // Group products by expiration timestamp
    const productsGroupedByExpiration = products.reduce((groups, product) => {
      const expirationDate = getLastSecondaryExpirationDate(product);
      if (!expirationDate) {
        return groups; // Skip products without an expiration date
      }
      const expirationTime = expirationDate.getTime();
      if (!groups[expirationTime]) {
        groups[expirationTime] = [];
      }
      groups[expirationTime].push(product);
      return groups;
    }, {} as { [key: number]: Product[] });

    // Get sorted expiration times as numbers
    const expirationTimes = Object.keys(productsGroupedByExpiration)
      .map((timeStr) => parseInt(timeStr, 10))
      .sort((a, b) => a - b);

    // Limit to maxScheduledNotifications
    const expirationTimesToSchedule = expirationTimes.slice(
      0,
      this.maxScheduledNotifications
    );

    // Cancel all existing notifications first
    await this.cancelAllNotifications();

    // Schedule notifications for each group
    for (const expirationTime of expirationTimesToSchedule) {
      const products = productsGroupedByExpiration[expirationTime];
      await this.scheduleNotificationForExpirationBatch(
        products,
        expirationTime // Convert timestamp back to Date if needed
      );
    }
  }

  async scheduleNotificationForExpirationBatch(
    products: Product[],
    expirationTime: number
  ) {
    try {
      if (expirationTime <= Date.now()) {
        // Don't schedule notifications in the past
        return;
      }

      const notificationId = this.generateBatchNotificationId(expirationTime);

      // Limit the number of product names displayed in the notification
      const productNames = products.map((p) => p.name);
      const productNamesToDisplay = productNames.slice(0, 3); // Limit to 3 names
      let notificationBody = `${productNamesToDisplay.join(', ')}`;
      if (productNames.length > 3) {
        notificationBody += ` e mais ${productNames.length - 3} produtos`;
      }
      notificationBody += '.';

      console.log(notificationId);

      const notification: ScheduleOptions = {
        notifications: [
          {
            id: notificationId,
            title: 'Notificação produtos a expirar',
            body: notificationBody,
            schedule: { at: new Date(expirationTime) },
            channelId: this.notificationChannelId,
            extra: { type: 'product-expiration-batch', expirationTime },
            sound: 'expiring_product_c22.wav',
          },
        ],
      };

      await LocalNotifications.schedule(notification);
    } catch (error) {
      console.error('Error scheduling batch notification:', error);
    }
  }

  private generateBatchNotificationId(expirationTime: number): number {
    // Use expirationTime as the notification ID to ensure uniqueness per batch
    return expirationTime / 1000;
  }

  async cancelAllNotifications() {
    const pendingNotifications = (await LocalNotifications.getPending())
      .notifications;
    const notificationsToCancel = {
      notifications: pendingNotifications.filter(
        (notification) =>
          notification.extra?.type === 'product-expiration' ||
          notification.extra?.type === 'product-expiration-batch'
      ),
    };
    // Cancel all notifications
    console.log(notificationsToCancel);
    if (notificationsToCancel.notifications.length > 0) {
      await LocalNotifications.cancel(notificationsToCancel);
    }
  }
  async startService() {
    console.log('Running startService');
    let permission = await LocalNotifications.checkPermissions();
    if (permission.display !== 'granted') {
      permission = await LocalNotifications.requestPermissions();
    }

    if (permission.display === 'granted') {
      await this.scheduleNotificationsForUpcomingExpirations();
    } else {
      console.log(
        'Local notifications are not enabled. No action will be taken.'
      );
    }
  }

  async stopService() {
    console.log('Running stopService');
    await this.cancelAllNotifications();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
  }
}
