import { TranslateService } from '@ngx-translate/core';

export function translate(
  key: string,
  translateService: TranslateService
): string {
  let ret = '';
  translateService.get(key).subscribe((res) => (ret = res));
  return ret;
}
