import { Component } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Product } from '../../../entities/product';
import {
  Batch,
  CreatedByMethod,
  getBatchStatus,
} from '../../../entities/batch';

import { translate } from '../../../../utils/translate.utils';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { AuthenticationService } from 'src/app/services/authentication';
import { firstValueFrom } from 'rxjs';
import { StoresService } from 'src/app/services/stores.service';
import { MomentDateFormats } from 'src/utils/date.utils';
import { EmployeeService } from 'src/app/services/employee.service';

import moment from 'moment';
import cuid from 'cuid';

@Component({
  selector: 'app-batch-create-modal',
  templateUrl: 'batch-create.modal.html',
  styleUrls: ['../../../../theme/modals.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BatchCreateModal {
  product: Product;
  lot: string = '';
  expirationDate: string = '';

  expirationDateControl: FormControl;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService,
    private toastController: ToastController,
    private translator: TranslateService,
    private storeService: StoresService,
    private employeeService: EmployeeService
  ) {
    this.product = this.navParams.get('product');

    // Initialize the form control
    this.expirationDateControl = new FormControl('', Validators.required);
  }

  close() {
    this.modalController.dismiss();
  }

  isClicked = false;

  async pressCreateBatch() {
    if (this.isClicked) return;
    this.isClicked = true;
    if (!this.product.batches) this.product.batches = [];

    const batch: Batch = {
      id: cuid(),
      lot: this.lot,
      expirationDate: this.expirationDate,
      status: getBatchStatus(
        this.expirationDate,
        this.storeService.getExpiringWarningsDays()
      ),
      createdBy: {
        method: CreatedByMethod.CreateBatch,
        creationDate: moment().format(MomentDateFormats.YYYYMMDD_HHmm),
        employeeId: this.employeeService.getEmployeeId(),
      },
    };

    this.product.batches.unshift(batch);

    try {
      const data = await firstValueFrom(
        this.productsService.newProduct(
          this.product,
          this.authenticationService.getAuthInfo()
        )
      );
      if (data) {
        const toast = await this.makeBatchAddedToProductSuccessfullyToast();
        toast.present();
      }
      await this.modalController.dismiss(batch);
    } catch (err) {
      console.log(err);
    } finally {
      this.isClicked = false;
    }
  }

  private async makeBatchAddedToProductSuccessfullyToast() {
    return this.toastController.create({
      message: await translate(
        'O lote foi inserido corretamente.',
        this.translator
      ),
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  onDateInputChange(event: any): boolean {
    const input = event.target.value;
    let formattedInput = input.replace(/\D/g, ''); // Remove any non-digit characters

    // Limit to 8 digits (YYYYMMDD)
    if (formattedInput.length > 8) {
      formattedInput = formattedInput.substring(0, 8);
    }

    // Add dashes after the year and month
    if (formattedInput.length > 6) {
      formattedInput =
        formattedInput.substring(0, 4) +
        '-' +
        formattedInput.substring(4, 6) +
        '-' +
        formattedInput.substring(6);
    } else if (formattedInput.length > 4) {
      formattedInput =
        formattedInput.substring(0, 4) + '-' + formattedInput.substring(4);
    }

    // Validate month and day
    const year = parseInt(formattedInput.substr(0, 4), 10);
    const month = parseInt(formattedInput.substr(5, 2), 10);
    const day = parseInt(formattedInput.substr(8, 2), 10);

    if (month > 12) {
      formattedInput =
        formattedInput.substring(0, 5) + '12' + formattedInput.substring(7);
    }

    if (
      day > 31 ||
      (month === 2 && day > 29) ||
      ([4, 6, 9, 11].indexOf(month) >= 0 && day > 30)
    ) {
      formattedInput =
        formattedInput.substring(0, 8) + (month === 2 ? '28' : '30');
    }

    // Truncate input after 10 characters (YYYY-MM-DD)
    formattedInput = formattedInput.substring(0, 10) + '';

    // Update the input value and form control value
    this.expirationDate = formattedInput;
    this.expirationDateControl.setValue(formattedInput);

    return true;
  }
}
