<!-- Remove the container and scroll-content classes as they are not necessary in Ionic 5+ -->
<div class="modalItem">
  <div class="modalHeader">
    <div class="modalTitle">
      <h3>{{ 'KEYBOARD' | translate }}</h3>
      <img src="assets/img/close.svg" (click)="close()" />
    </div>
  </div>
  <div class="modalContent">
    <div class="modalDescription">
      <div class="form-group">
        <label for="val">{{ 'VALUE' | translate }}:</label>
        <input
          id="val"
          type="text"
          readonly
          class="form-control"
          placeholder="{{ 'EXAMPLE_SHORT' | translate }} 1234"
          [(ngModel)]="value"
        />
      </div>

      <!-- Use Ionic components for grid layout -->
      <ion-grid class="keys-grid">
        <ion-row justify-content-center>
          <ion-col size="3" (click)="press('1')">
            <div class="key">1</div>
          </ion-col>
          <ion-col size="3" (click)="press('2')">
            <div class="key">2</div>
          </ion-col>
          <ion-col size="3" (click)="press('3')">
            <div class="key">3</div>
          </ion-col>
          <ion-col size="3" (click)="press('<-')">
            <div class="key"><-</div>
          </ion-col>
        </ion-row>
        <ion-row justify-content-center>
          <ion-col size="3" (click)="press('4')">
            <div class="key">4</div>
          </ion-col>
          <ion-col size="3" (click)="press('5')">
            <div class="key">5</div>
          </ion-col>
          <ion-col size="3" (click)="press('6')">
            <div class="key">6</div>
          </ion-col>
          <ion-col size="3"> <!-- Placeholder column to maintain alignment --> </ion-col>
        </ion-row>
        <ion-row justify-content-center>
          <ion-col size="3" (click)="press('7')">
            <div class="key">7</div>
          </ion-col>
          <ion-col size="3" (click)="press('8')">
            <div class="key">8</div>
          </ion-col>
          <ion-col size="3" (click)="press('9')">
            <div class="key">9</div>
          </ion-col>
          <ion-col size="3"> <!-- Placeholder column to maintain alignment --> </ion-col>
        </ion-row>
        <ion-row justify-content-center>
          <ion-col size="3" (click)="press('-')">
            <div class="key">-</div>
          </ion-col>
          <ion-col size="3" (click)="press('0')">
            <div class="key">0</div>
          </ion-col>
          <ion-col size="3" (click)="press('.')">
            <div class="key">.</div>
          </ion-col>
          <ion-col size="3"> <!-- Placeholder column to maintain alignment --> </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="modalActions">
      <div class="actionsRight">
        <button class="btn btn-ok" (click)="finish()">
          {{ 'OK' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
