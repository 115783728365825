// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of, switchMap } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import cuid from 'cuid';
import moment from 'moment';
import { getTargetUser } from '../../entities/authentication-info';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpTaskControlWarmTemperature2 implements IMyTaskHandler {
  name = 'follow-up-task-control-warm-temperature2';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const productId = task.productId;

    if (typeof productId === 'string') {
      const store = this.storeService.store;
      if (!store) {
        console.log('Store not found');
        return of(null);
      }
      const product = this.productsService.getProductById(productId);
      const productInfo = this.productsService.getProduct(productId);

      // Verifica o primeiro batch que contém "Fabrico:" no campo 'lot'
      const selectedBatch = productInfo?.batches?.find(
        (batch: any) =>
          batch.lot && (batch.lot.includes('Fabrico:') || batch.lot.includes('Pré-embalado:'))
      );
      
      const typeTask = this.customTaskTypesService.getCustomTaskType(
        'warmTemperatureControlIntermarche2'
      );

      const userInfo = this.authenticationService.getUserInfo();
      if (!userInfo) {
        console.log('User info not found');
        return of(null);
      }
      if (!product) {
        console.log('Product info not found');
        return of(null);
      }
      if (!selectedBatch) {
        console.log('Batch not found');
        return of(null);
      }
      if (selectedBatch.controlledWarmTemperature) {
        console.log('Already generated temperature task');
        return of(null);
      }
      if (!typeTask) {
        console.log('typeTask info not found');
        return of(null);
      }

      // Verifica se pode prosseguir e se o produto será conservado no quente
      if (data['O produto vai ser conservado no quente?']) {
        const newTask: Task = {
          id: cuid(), // gera o ID da tarefa
          type: typeTask.type,
          formId: task.formId,
          title: this.getTitle(typeTask, data),
          subtitle: this.getSubtitle(typeTask, data),
          startDate: moment(
            data['Data de desembalamento'] || data['Data de fabrico']
          ).valueOf(),
          endDate: moment(
            data['Data de desembalamento'] || data['Data de fabrico']
          )
            .add(3, 'hour')
            .valueOf(),
          visualizationDate: moment().add(1, 'year').valueOf(),
          nonConformities: [],
          causes: [],
          corrections: [],
          periodicity: 'Única',
          target: {
            storeId: store.id,
            userIds: getTargetUser(store, userInfo),
          },
          documentsIds: [],
          isCustomType: true,
          superType: undefined,
          productId: product.id,
          // Usando null em vez de undefined para o expirationDate
          batch: selectedBatch ? selectedBatch : undefined, // Usa undefined se não encontrar um batch
          precedingTaskId: task.id,
        };

        selectedBatch.controlledWarmTemperature = true;

        return this.tasksService
          .newTask(newTask, this.authenticationService.getAuthInfo())
          .pipe(
            switchMap(() => {
              // Assuming `batchService.updateBatch` is the method to update the batch
              return this.productsService.newProduct(
                product,
                this.authenticationService.getAuthInfo()
              );
            })
          );
      }
    }

    return of(null);
  }
}
