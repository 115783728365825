import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-goods-reception-summary',
  templateUrl: './goods-reception-summary.component.html',
  styleUrls: ['./goods-reception-summary.component.scss'],
})
export class GoodsReceptionSummaryComponent {
  @Input() goodsReception: any[] = [];

  constructor() {}

  navigateToGoodsReception() {}
}
