// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import moment from 'moment';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpTagPreview implements IMyTaskHandler {
  name = 'follow-up-tag-preview';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}
  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
   

    return of(null);
  }
}
