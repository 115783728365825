import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserInfo } from '../entities/authentication-info';
import { SensorsService } from './sensors.service';
import { DashboardService } from './dashboard.service';
import { EmployeeService } from './employee.service';
import { DocumentsService } from './documents.service';
import { ZonesService } from './zones.service';
import { EquipmentsService } from './equipments.service';
import { ProductsService } from './products.service';
import { CustomTaskTypesService } from './custom-task-types.service';
import { StoresService } from './stores.service';
// import { SosProductService } from '../pages/fields/alarms/sosproduct/sosproduct.service';
import { AuthenticationService } from './authentication';
import { FieldsService } from './fields.service';
import { TasksService } from './tasks.service';
import { ManualsService } from './manuals.service';
import { CreateTasksConfigurationService } from './non-programmed-tasks-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadAll {
  cache = {};

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private fieldsService: FieldsService,
    private sensorsService: SensorsService,
    private dashboardService: DashboardService,
    private manualsService: ManualsService,
    private employeeService: EmployeeService,
    private documentsService: DocumentsService,
    private zonesService: ZonesService,
    private equipmentsService: EquipmentsService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService,
    private storesService: StoresService,
    // private alarmsService: SosProductService,
    private nonProgrammedTasksConfigurationService: CreateTasksConfigurationService
  ) {}

  download(): Observable<any> {
    const user: UserInfo | null = this.authenticationService.getUserInfo();
    if (!user || !user.companyId || !user.storeId) {
      return of(null);
    }

    const companyId = user.companyId;
    const storeId = user.storeId;

    const sources: Observable<any>[] = [
      this.storesService.getStoreFromServer(companyId, storeId),
      this.fieldsService.getFieldsFromStore(companyId, storeId),
      this.tasksService.getTasksFromStore(companyId, storeId),
      this.tasksService.getJustificationTasksFromStore(companyId, storeId),
      this.tasksService.getHistoryFromStore(companyId, storeId),
      this.sensorsService.getMachineIndicatorsFromStore(companyId, storeId),
      this.dashboardService.getDashboardFromStore(companyId, storeId),
      this.manualsService.getManualsFromStore(companyId, storeId),
      this.employeeService.getEmployeesFromStore(companyId, storeId),
      this.documentsService.getDocumentsFromStore(companyId, storeId),
      this.zonesService.getZonesFromStore(companyId, storeId),
      this.equipmentsService.getEquipmentsFromStore(companyId, storeId),
      this.productsService.getProductsFromStore(companyId, storeId),
      // this.alarmsService.getAllAlarmsFromStore(companyId, storeId),
      this.nonProgrammedTasksConfigurationService.getCreateTasksConfigurationFromStore(companyId, storeId),
      this.customTaskTypesService.getCustomTaskTypesFromApi(companyId, storeId)
    ];

    return forkJoin(sources).pipe(
      catchError(err => {
        console.log(err);
        return throwError(err);
      })
    );
  }
}
