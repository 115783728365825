<!-- insert-manual-entry.modal.html -->
<div class="container scroll-content">
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ 'Entrada manual' | translate }} - {{ product.name }}</h3>
          <ion-icon name="assets/img/close.svg" (click)="close()" class="close-icon"></ion-icon>
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <ion-item class="form-group">
            <ion-label position="floating">{{ 'Produto' | translate }}</ion-label>
            <ion-input type="text" [(ngModel)]="product.name"></ion-input>
          </ion-item>
          <ion-item class="form-group">
            <ion-label position="floating">{{ 'Data de entrada' | translate }}</ion-label>
            <ion-input type="date" [(ngModel)]="entry.date"></ion-input>
          </ion-item>
          <ion-item class="form-group">
            <ion-label position="floating">{{ 'Data de validade' | translate }}</ion-label>
            <ion-input type="date" [(ngModel)]="entry.expirationDate"></ion-input>
          </ion-item>
          <ion-item class="form-group">
            <ion-label position="floating">{{ 'Lote' | translate }}</ion-label>
            <ion-input type="text" [(ngModel)]="entry.lot"></ion-input>
          </ion-item>
          <ion-item class="form-group">
            <ion-label position="floating">{{ 'Nº identificação' | translate }}</ion-label>
            <ion-input type="text" [(ngModel)]="entry.idNumber"></ion-input>
          </ion-item>
          <ion-item class="form-group">
            <ion-label position="floating">{{ 'Extras' | translate }}</ion-label>
            <ion-textarea rows="5" [(ngModel)]="entry.extras"></ion-textarea>
          </ion-item>
        </div>
        <div class="modalActions">
          <div class="actionsLeft"></div>
          <div class="actionsRight">
            <ion-button expand="block" color="primary" (click)="pressSubmit()">
              {{ 'OK' | translate }}
            </ion-button>
            <ion-button expand="block" color="medium" (click)="close()">
              {{ 'CANCEL' | translate }}
            </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  