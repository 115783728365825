import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "keyvalue",
  pure: false,
})
export class KeyValuePipe implements PipeTransform {

  constructor() {}

  transform(value: any, args: any[] = []): any {
    if(!value) return [];
    return Object.keys(value);    
  }
}
