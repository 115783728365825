<ion-card>
  <ion-card-header>
    <div class="card-title-with-icon">
      <ion-icon name="cart-outline" color="primary"></ion-icon>
      <ion-card-title>Receções de Mercadoria</ion-card-title>
    </div>
  </ion-card-header>

  <ion-card-content>
    <div *ngIf="goodsReception?.length; else noGoodsReception">
      <ion-list>
        <ion-item *ngFor="let rececao of goodsReception">
          <ion-label>
            <strong>{{ rececao.supplier }}</strong>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- Button to navigate to the goods receipts page -->
      <ion-button
        expand="block"
        (click)="navigateToGoodsReception()"
        class="navigate-button"
      >
        <ion-icon name="cart-outline" slot="start"></ion-icon>
        Ver Receções de Mercadoria
      </ion-button>
    </div>

    <ng-template #noGoodsReception>
      <p>Não há receções de mercadoria recentes.</p>
    </ng-template>
  </ion-card-content>
</ion-card>
