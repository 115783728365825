import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { AuthenticatedHttpService } from './authenticated-http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {
  private cache = new Map<string, any>();
  private etag = '';

  constructor(private http: AuthenticatedHttpService, protected envService: EnvironmentService) {}

  getEquipmentsFromStore(companyId: string, storeId: string): Observable<any> {
    let link: Link = new Link(this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/equipments`,
      {
        ...JSON_HEADERS,
        'If-None-Match' : this.etag
      });
      return this.http.get(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const indicators = response.body || [];
        indicators.forEach((i:any) => {
          this.cache.set(i.id, i);
        });
      }),
      catchError(error => throwError(error))
    );
  }

  getEquipments(): any[] {
    return Array.from(this.cache.values());
  }

  getEquipmentById(id: string): any {
    return this.cache.get(id);
  }
}
