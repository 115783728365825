import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password.modal.html',
  styleUrls: ['../../../theme/modals.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PasswordModal {
  storeCode;
  username;
  password = '';
  pressSubmit = () => {
    this.modalCtrl.dismiss(this.password);
  }
  
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  )
  {
    this.storeCode = this.navParams.data['storeCode'];
    this.username = this.navParams.data['username'];
  }
  
  close()
  {
    this.modalCtrl.dismiss();
  }
  }