import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication';
import { inject } from '@angular/core';

export const storeAuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);

  const isStoreAuthenticated = authenticationService.isStoreAuthenticated();

  if (!isStoreAuthenticated) {
    router.navigate(['/']);
    return false;
  }

  const isAuthenticated = authenticationService.isAuthenticated();
  if (state.url !== 'user-login' && !isAuthenticated) {
    router.navigate(['/user-login']);
    return false;
  }
  return true;
};
