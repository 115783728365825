import { Component } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular"; // Updated import from Ionic 3 to Ionic 5/6/7/8
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-missing-required-fields-modal', // Updated selector naming convention
  templateUrl: 'missing-required-fields.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MissingRequiredFieldsModal {

  missingRequiredFields: any[]; // Define type for missingRequiredFields array

  constructor(
    private navParams: NavParams,
    private modalController: ModalController, // Updated modal controller import
    private translateService: TranslateService
  ) {
    this.missingRequiredFields = this.navParams.get('missingRequiredFields');
  }

  close() {
    this.modalController.dismiss();
  }

  getTranslation(object:any) {
    const lang = this.translateService.currentLang;
    if (lang === 'pt') {
      return object.name;
    } else if (lang === 'es') {
      return object.nameEs || object.name;
    }
    return object.name;
  }

}
