import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { Link } from '../../utils/link';
import { JSON_HEADERS } from '../../utils/headers';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';

interface CreateTasksConfiguration {
  creatableTaskTypes: string[];
  [key: string]: any; // Define other properties as needed
}

@Injectable({
  providedIn: 'root',
})
export class CreateTasksConfigurationService {
  private configs: CreateTasksConfiguration | null = null;
  private etag = '';

  constructor(private http: AuthenticatedHttpService, protected envService: EnvironmentService) {}

  getCreateTasksConfigurationFromStore(
    companyId: string,
    storeId: string
  ): Observable<CreateTasksConfiguration | null> {
    const link: Link = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/nonProgrammedTasksConfiguration`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );
    return this.http.get(link).pipe(
      tap((response:any) => {
        if (response.status === 304) {
          return;
        }
        this.etag = response.headers.get('etag') || '';
        this.configs = response.body;
      }),
      map(() => this.configs)
    );
  }

  getConfigurations(): CreateTasksConfiguration | null {
    return this.configs;
  }

  hasTask(type: string): boolean {
    return this.configs?.creatableTaskTypes
      ? this.configs.creatableTaskTypes.indexOf(type) !== -1
      : false;
  }
}
