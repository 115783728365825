import Papa from 'papaparse';
import { TextDecoder } from 'text-encoding';

export async function getPrevisaoFromCsv(file: { base64Content: string }): Promise<Previsao> {
  return getPrevisaoFromCsvInner(file);
}

function getPrevisaoFromCsvInner(file: { base64Content: string }): Promise<Previsao> {
  const base64DataUrl = file.base64Content;
  const base64Data = base64DataUrl.split(',')[1]; // get the base64 data
  const binaryData = base64ToUint8Array(base64Data); // decode base64
  const textDecoder = new TextDecoder('UTF-8'); // replace with your file's encoding
  const csvData = textDecoder.decode(binaryData);

  return new Promise<Previsao>((resolve, reject) => {
    Papa.parse<string[]>(csvData, {
      delimiter: ';',
      header: false,
      complete: (results: Papa.ParseResult<string[]>) => {
        let kgHerdadeRowIndex = -1;
        let kgHerdadeColIndex = -1;
        let dataDate: string | null = null;
        let lagarName: string | null = null;
        let grandTotal: string | null = null;
        const rows = results.data;

        // Identify where " Kg/herdade" is in the first row
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];

          if (row.includes('DATA')) {
            dataDate = row[row.findIndex((cell: string) => cell === 'DATA') + 1];
          }

          if (row.includes(' Kg/herdade')) {
            kgHerdadeRowIndex = i;
            kgHerdadeColIndex = row.findIndex((cell: string) => cell === ' Kg/herdade');
            lagarName = rows[i][kgHerdadeColIndex - 1];
          }

          if (row.includes('Grand Total')) {
            grandTotal = row[row.findIndex((cell: string) => cell === 'Grand Total') + 1];
          }
        }

        if (lagarName !== null) {
          const harvestPrediction: Previsao = {
            lagarName: lagarName,
            grandTotal: grandTotal || '',
            data: [],
          };

          let currentEntry: Data | null = null;
          let currentTransport: Transport | null = null;

          // Loop through the table
          for (let i = kgHerdadeRowIndex + 1; i < rows.length; i++) {
            const row = rows[i];
            const cell1Value = row[kgHerdadeColIndex - 1];
            const cell2Value = row[kgHerdadeColIndex];

            if (cell1Value === 'Grand Total') {
              break;
            } else if (matchCamiao(cell1Value) || cell1Value === 'Reboque') {
              if (currentTransport) {
                currentEntry?.transports.push(currentTransport);
              }
              currentTransport = {
                type: cell1Value,
                quantity: cell2Value,
                herdades: [],
                date: dataDate || '',
              };
            } else if (/H\d+/.test(cell1Value?.toString())) {
              currentTransport?.herdades.push({
                herdadeShortName: cell1Value,
                herdadeShortQuantity: cell2Value,
              });
              const nextRow = rows[i + 1];
              const cell1NextValue = nextRow[kgHerdadeColIndex - 1];
              const cell2NextValue = nextRow[kgHerdadeColIndex];
              currentTransport?.herdades.push({
                herdadeFullName: cell1NextValue,
                herdadeFullQuantity: cell2NextValue,
              });
              i++; // Skip the next row because we've already processed it
            } else {
              // This is a new oliveType, so push the previous entry to data
              if (currentEntry) {
                if (currentTransport) {
                  currentEntry.transports.push(currentTransport);
                  currentTransport = null;
                }
                harvestPrediction.data.push(currentEntry);
              }
              currentEntry = {
                oliveType: cell1Value,
                quantity: cell2Value,
                transports: [],
              };
            }
          }

          // Push remaining currentEntry if it exists
          if (currentEntry) {
            if (currentTransport) {
              currentEntry.transports.push(currentTransport);
            }
            harvestPrediction.data.push(currentEntry);
          }

          resolve(harvestPrediction);
        } else {
          reject('Did not find lagar name');
        }
      },
      error: (error: any) => {
        reject(`Failed to read CSV file: ${error}`);
      },
    });
  });

  function matchCamiao(cell1Value: string): boolean {
    return /^Cami.*o$/.test(cell1Value);
  }
}

function base64ToUint8Array(base64: string): Uint8Array {
  const raw = atob(base64);
  const uint8Array = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i++) {
    uint8Array[i] = raw.charCodeAt(i);
  }
  return uint8Array;
}

export interface GuiaDeTransporte {
  Date: string;
  HerdadeShortName: string;
  HerdadeFullName: string;
  QuantityShort: number;
  QuantityFull: number;
  VehicleType: string;
  OliveType: string;
  LagarName: string;
}

interface Herdade {
  herdadeShortName?: string;
  herdadeShortQuantity?: string;
  herdadeFullName?: string;
  herdadeFullQuantity?: string;
}

interface Transport {
  type: string;
  quantity: string;
  herdades: Herdade[];
  date: string;
}

interface Data {
  oliveType: string;
  quantity: string;
  transports: Transport[];
}

export interface Previsao {
  lagarName: string;
  grandTotal: string;
  data: Data[];
}

export function generateGuiasTransporteFromPrevisao(previsao: Previsao): GuiaDeTransporte[] {
  const guiasDeTransporte: GuiaDeTransporte[] = [];

  for (const data of previsao.data) {
    for (const transport of data.transports) {
      // Iterate by pairs since every two herdades make a complete set
      for (let i = 0; i < transport.herdades.length; i += 2) {
        const herdadeShort = transport.herdades[i];
        const herdadeFull = transport.herdades[i + 1]; // Next item

        guiasDeTransporte.push({
          Date: transport.date || formatDate(new Date()),
          HerdadeShortName: herdadeShort.herdadeShortName || '',
          HerdadeFullName: herdadeFull?.herdadeFullName || '',
          QuantityShort: herdadeShort.herdadeShortQuantity ? parseInt(herdadeShort.herdadeShortQuantity.replace(/,/g, ''), 10) : 0,
          QuantityFull: herdadeFull?.herdadeFullQuantity ? parseInt(herdadeFull.herdadeFullQuantity.replace(/,/g, ''), 10) : 0,
          VehicleType: transport.type,
          OliveType: data.oliveType,
          LagarName: previsao.lagarName,
        });
      }
    }
  }

  return guiasDeTransporte;
}

function padLeft(str: string, length: number, padChar: string): string {
  return str.padStart(length, padChar);
}

function formatDate(date: Date): string {
  const day = padLeft(String(date.getDate()), 2, '0');
  const month = padLeft(String(date.getMonth() + 1), 2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
