import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable()
export class OfflineService {
  isOffline = false;

  constructor() {}

  async isConnectionWifi(): Promise<boolean> {
    return (await Network.getStatus()).connectionType === 'wifi';
  }
  
}
