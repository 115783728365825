import { Injectable } from '@angular/core';
import { PromptModal } from '../components/prompt/prompt.modal';
import { Network } from '@capacitor/network';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { translate } from 'src/utils/translate.utils';

@Injectable({
  providedIn: 'root',
})
export class ConfigsService {
  use3GToDownloadMedia = false;

  constructor(
    private modalController: ModalController,
    private translator: TranslateService
  ) {}

  setUse3GToDownloadMedia(value: boolean) {
    this.use3GToDownloadMedia = value;
  }

  async shouldUse3GToDownloadMedia() {
    const status = await Network.getStatus();
    return status.connectionType === 'wifi' || this.use3GToDownloadMedia;
  }

  async promptUse3GToDownloadMedia() {
    const title = await translate('3G_DOWNLOAD_PROMPT_TITLE', this.translator);
    const text = await translate('3G_DOWNLOAD_PROMPT_TEXT', this.translator);
    const yesText = await translate('3G_DOWNLOAD_PROMPT_YES', this.translator);
    const noText = await translate('3G_DOWNLOAD_PROMPT_NO', this.translator);

    const prompt = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title,
        text,
        yesText,
        noText,
      },
    });

    prompt.onDidDismiss().then(({ data }) => {
      this.setUse3GToDownloadMedia(data);
    });

    await prompt.present();
  }
}
