import { ModalController } from '@ionic/angular';

export interface ConditionalModalCreationInfo {
    condition: () => boolean;
    modalCreator: (modalController: ModalController) => Promise<HTMLIonModalElement>;
}

export async function conditionalCreateModal(
    condition: () => boolean,
    modalCreator: (modalController: ModalController) => Promise<HTMLIonModalElement>,
    modalController: ModalController
): Promise<boolean> {
    if (condition()) {
        let promiseResolve: (value: boolean) => void;
        const promise = new Promise<boolean>(resolve => promiseResolve = resolve);
        const modal = await modalCreator(modalController);
        modal.onDidDismiss().then(({ data }) => {
            promiseResolve(data);
        });
        await modal.present();

        return promise;
    }
    return Promise.resolve(true);
}

export async function checkValidity(
    validityCheckersInfo: ConditionalModalCreationInfo[],
    modalController: ModalController
): Promise<boolean> {
    let continueChecking = true;
    const validityCheckers = validityCheckersInfo.map(info =>
        () => conditionalCreateModal(
            info.condition,
            info.modalCreator,
            modalController
        )
    );
    for (let i = 0; i < validityCheckers.length && continueChecking; i++) {
        continueChecking = await validityCheckers[i]();
    }
    return continueChecking;
}
