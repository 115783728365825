<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3 *ngIf="!this.description.information">{{'REGISTERED_TEMPERATURE'|translate}}</h3>
        <h3 *ngIf="this.description.information">{{'Informação'|translate}}</h3>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription" *ngIf="this.description.text">
        {{this.description.text}}
      </div>
      <div class="modalDescription" *ngIf="this.description.information">
        {{this.description.information}}
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="close()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
