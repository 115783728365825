import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JSON_HEADERS } from '../../utils/headers';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication';
import { Link } from '../../utils/link';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { AuthenticatedHttpService } from './authenticated-http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private http:any;

  private timeout: ReturnType<typeof setTimeout> | undefined;
  private employee: any;
  private cache = new Map<string, any>();
  private getEtag = '';
  

  constructor(
    private injector: Injector,
    private authenticationService: AuthenticationService,
    protected envService: EnvironmentService
  ) {}

  updateEmployeePin(pin: string): Observable<any> {
    this.http = this.injector.get(AuthenticatedHttpService);
    const authInfo: AuthenticationInfo | null = this.authenticationService.getAuthInfo();
    if (!authInfo || !authInfo.user) {
      return throwError('Authentication information is missing');
    }

    const companyId = authInfo.user.companyId;
    let employee = this.employee;
    employee.validatedPassword = true;
    employee.pin = pin;

    let link: Link = new Link(`${this.envService.getApiUri()}/companies/${companyId}/employees/${employee.id}/updatePin`, JSON_HEADERS);


    return this.http.put(link, employee).pipe(
      map((r: any) => {
        this.cache.set(r.id, r);
        return r;
      }),
      catchError(error => throwError(error))
    );
  }

  getEmployeesFromStore(companyId: string, storeId: string): Observable<any> {
    this.http = this.injector.get(AuthenticatedHttpService);
    const headers = {
      ...JSON_HEADERS,
      'If-None-Match': this.getEtag || ''
    };
    let link = new Link(`${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/employees`, headers);

    return this.http.get(link).pipe(
      map((r: any) => {
        if (r.status === 304) {
          return;
        }
        this.cache = new Map();
        this.getEtag = r.headers.get('etag');
        const employees = r.body;
        employees.forEach((e: any) => {
          this.cache.set(e.id, e);
        });
        return employees;
      }),
      catchError(error => {
        console.error('Error fetching employees:', error);
        return throwError(error);
      })
    );
  }

  getEmployeeById(id: string): any {
    return this.cache.get(id);
  }

  getEmployee() {
    return this.employee;
  }

  getEmployees(): any[] {
    return Array.from(this.cache.values());
  }

  getEmployeeId(): string {
    return this.employee.id;
  }

  getEmployeeName(): string {
    return this.employee ? this.employee.name || '' : '';
  }

  cancelEmployeeTimer(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  clearEmployee() {
    this.employee = undefined;
  }
  
  setEmployee(employee: any) {
    this.employee = employee;
  }

  getEmployeesName(): string[] {
    return Array.from(this.cache.values()).map(employee => employee.name);
  }
}
