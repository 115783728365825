<!-- Remove the container and scroll-content classes as they are not necessary in Ionic 5+ -->
<div class="modalItem">
  <div class="modalHeader">
    <div class="modalTitle">
      <h3>{{ 'Campos obrigatórios em falta' | translate }}</h3>
      <img src="assets/img/close.svg" (click)="close()" />
    </div>
  </div>
  <div class="modalContent">
    <div class="modalDescription">
      <div class="form-group" *ngFor="let mrf of missingRequiredFields">
        <ion-label>{{ getTranslation(mrf) }}</ion-label>
      </div>
    </div>
    <div class="modalActions">
      <div class="actionsLeft"></div>
      <div class="actionsRight">
        <button class="btn btn-cancel light-grey" (click)="close()">
          {{'CANCEL'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
