import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { FieldType } from 'src/app/entities/field';
import { getTargetUser } from 'src/app/entities/authentication-info';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CreateSectionsTasksAhresp implements IMyTaskHandler {
  name = 'create-sections-tasks-ahresp';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }

    // Create `sectionLevel`
    const sectionLevel = {
      id: cuid(),
      title: `2º - Detalhes dos espaços`,
      type: FieldType.Level,
      storeId: this.storeService.store.id,
      position: 2,
      style: {
        imageUrl: 'documentos_ude.svg',
      },
    };

    this.fieldsService
      .newField(sectionLevel, this.authenticationService.getAuthInfo())
      .subscribe(
        () => console.log('sectionLevel created successfully'),
        (err) => console.log('Error creating sectionLevel:', err)
      );

    // Add "Confirmar informações" field
    const confirmField = {
      id: cuid(),
      title: 'Confirmar informações',
      type: FieldType.Form,
      storeId: this.storeService.store.id,
      upperLevelId: sectionLevel.id,
      upperLevelIds: [sectionLevel.id],
      style: {
        imageUrl: 'eligibilidade.svg',
      },
    };

    this.fieldsService
      .newField(confirmField, this.authenticationService.getAuthInfo())
      .subscribe(
        () => console.log('Confirmar informações field created successfully'),
        (err) => console.log('Error creating Confirmar informações field:', err)
      );

    // Create `terminateTaskGeralQuiz` task
    this.tasksService
      .newTask(
        {
          id: cuid(),
          type: 'terminateTaskGeralQuiz',
          formId: confirmField.id,
          title: 'Preencher quando terminar o questionário',
          startDate: task.startDate,
          endDate: moment(task.endDate).add(7, 'days').valueOf(),
          visualizationDate: task.visualizationDate,
          nonConformities: [],
          causes: [],
          corrections: [],
          periodicity: 'Única',
          target: {
            storeId: confirmField.storeId,
            userIds: getTargetUser(this.storeService.store, userInfo),
          },
          documentsIds: [],
          isCustomType: true,
        },
        this.authenticationService.getAuthInfo()
      )
      .subscribe(
        () => console.log('terminateTaskGeralQuiz task created successfully'),
        (err) => console.log('Error creating terminateTaskGeralQuiz task:', err)
      );

    // Process additional sections based on `data['Que espaços existem?']`
    data['Que espaços existem?'].forEach((s: any) => {
      // Define o imageUrl com base no valor de `s`
      let imageUrl = '';
      switch (s) {
        case 'Cozinha':
          imageUrl = 'cozinha.svg';
          break;
        case 'Armazém':
          imageUrl = 'armazem.svg';
          break;
        case 'Esplanada':
          imageUrl = 'esplanada.svg';
          break;
        case 'Sala':
          imageUrl = 'sala.svg';
          break;
        case 'WC Clientes':
          imageUrl = 'wc_clientes.svg';
          break;
        case 'WC Pessoal e vestiários':
          imageUrl = 'wc_pessoal.svg';
          break;
        // Sem `default` para deixar `imageUrl` vazio caso não haja correspondência
      }
      const sectionForm: any = {
        id: cuid(),
        title: `Configuração ${s}`,
        type: FieldType.Form,
        storeId: this.storeService.store.id,
        upperLevelId: sectionLevel.id,
        upperLevelIds: [sectionLevel.id],
        ...(imageUrl && { style: { imageUrl: imageUrl } }), // Adiciona `style` condicionalmente
      };

      this.fieldsService
        .newField(sectionForm, this.authenticationService.getAuthInfo())
        .subscribe(
          () => console.log(`sectionForm ${s} created successfully`),
          (err) => console.log(`Error creating sectionForm ${s}:`, err)
        );

      // Create tasks for this section
      this.createTasksForSection(sectionForm, task, s, userInfo);
    });

    return of(null);
  }

  // Helper function to create specific tasks for each section
  private createTasksForSection(
    sectionForm: any,
    task: Task,
    s: string,
    userInfo: any
  ) {
    const types = [
      {
        type: this.getMachineRefrigerationListAhrespTaskType(sectionForm),
        key: this.getMachineRefrigerationListAhrespTaskType(sectionForm),
      },
      {
        type: this.getMachineFreezingListAhrespTaskType(sectionForm),
        key: this.getMachineFreezingListAhrespTaskType(sectionForm),
      },
      {
        type: this.getMachineWaterBathListAhrespTaskType(sectionForm),
        key: this.getMachineWaterBathListAhrespTaskType(sectionForm),
      },
      {
        type: this.getMachineFryerListAhrespTaskType(sectionForm),
        key: this.getMachineFryerListAhrespTaskType(sectionForm),
      },
      {
        type: this.getDailyCleaningListAhrespQuizTaskType(sectionForm),
        key: this.getDailyCleaningListAhrespQuizTaskType(sectionForm),
      },
      {
        type: this.getDailyCleaning2xListAhrespQuizTaskType(sectionForm),
        key: this.getDailyCleaning2xListAhrespQuizTaskType(sectionForm),
      },
      {
        type: this.getPeriodicCleaningListAhrespQuizTaskType(sectionForm),
        key: this.getPeriodicCleaningListAhrespQuizTaskType(sectionForm),
      },
    ];

    types.forEach(({ type, key }) => {
      if (type && key) {
        // Verifica se `key` não é undefined
        const customType = this.customTaskTypesService.getCustomTaskType(key);
        if (!customType) {
          // console.log(`${key} info not found`);
          return;
        }
        this.tasksService.newTask(
          {
            id: cuid(),
            type: customType.type,
            formId: sectionForm.id,
            title: customType.name + ' ' + s,
            startDate: task.startDate,
            endDate: moment(task.endDate).add(7, 'days').valueOf(),
            visualizationDate: moment(task.visualizationDate)
              .add(4, 'years')
              .valueOf(),
            nonConformities: [],
            causes: [],
            corrections: [],
            periodicity: 'Única',
            target: {
              storeId: sectionForm.storeId,
              userIds: getTargetUser(this.storeService.store, userInfo),
            },
            documentsIds: [],
            isCustomType: true,
          },
          this.authenticationService.getAuthInfo()
        );
      }
    });
  }

  // Task type functions with explicit return values
  private getMachineRefrigerationListAhrespTaskType(
    sectionForm: any
  ): 'machineRefrigerationListAhrespQuiz' | undefined {
    if (!sectionForm.title.includes('WC')) {
      return 'machineRefrigerationListAhrespQuiz';
    }
    return undefined;
  }

  private getMachineFreezingListAhrespTaskType(
    sectionForm: any
  ): 'machineFreezingListAhrespQuiz' | undefined {
    if (!sectionForm.title.includes('WC')) {
      return 'machineFreezingListAhrespQuiz';
    }
    return undefined;
  }

  private getMachineWaterBathListAhrespTaskType(
    sectionForm: any
  ): 'machineWaterBathListAhrespQuiz' | undefined {
    return sectionForm.title.includes('Cozinha') ||
      sectionForm.title.includes('Sala')
      ? 'machineWaterBathListAhrespQuiz'
      : undefined;
  }

  private getMachineFryerListAhrespTaskType(
    sectionForm: any
  ): 'machineFryerListAhrespQuiz' | undefined {
    return sectionForm.title.includes('Cozinha') ||
      sectionForm.title.includes('Sala')
      ? 'machineFryerListAhrespQuiz'
      : undefined;
  }

  private getDailyCleaningListAhrespQuizTaskType(sectionForm: any) {
    if (sectionForm.title.includes('Cozinha')) {
      return 'dailyCleaningCozinhaQuizAhresp';
    }
    if (
      sectionForm.title.includes('Sala') ||
      sectionForm.title.includes('Esplanada')
    ) {
      return 'dailyCleaningSalaQuizAhresp';
    }
    if (sectionForm.title.includes('WC Clientes')) {
      return 'dailyCleaningWcClienteQuizAhresp';
    }
    if (
      sectionForm.title.includes('WC Pessoal') ||
      sectionForm.title.includes('Vestiários')
    ) {
      return 'dailyCleaningWcPessoalQuizAhresp';
    }

    return 'dailyCleaningListAhrespQuiz';
  }

  private getDailyCleaning2xListAhrespQuizTaskType(sectionForm: any) {
    if (sectionForm.title.includes('Cozinha'))
      return 'dailyCleaning2xCozinhaQuizAhresp';
    if (
      sectionForm.title.includes('Sala') ||
      sectionForm.title.includes('Esplanada')
    )
      return 'dailyCleaning2xSalaQuizAhresp';
    if (sectionForm.title.includes('WC Clientes'))
      return 'dailyCleaning2xWcClienteQuizAhresp';
    if (
      sectionForm.title.includes('WC Pessoal') ||
      sectionForm.title.includes('Vestiários')
    )
      return 'dailyCleaning2xWcPessoalQuizAhresp';
    if (sectionForm.title.includes('Armazém'))
      return 'dailyCleaning2xArmazemQuizAhresp';
    return 'dailyCleaning2xQuizAhresp';
  }

  private getPeriodicCleaningListAhrespQuizTaskType(sectionForm: any) {
    if (sectionForm.title.includes('Cozinha'))
      return 'periodicCleaningCozinhaQuizAhresp';
    if (
      sectionForm.title.includes('Sala') ||
      sectionForm.title.includes('Esplanada')
    )
      return 'periodicCleaningSalaQuizAhresp';
    if (sectionForm.title.includes('WC Clientes'))
      return 'periodicCleaningWcClienteQuizAhresp';
    if (
      sectionForm.title.includes('WC Pessoal') ||
      sectionForm.title.includes('Vestiários')
    )
      return 'periodicCleaningWcPessoalQuizAhresp';
    if (sectionForm.title.includes('Armazém'))
      return 'periodicCleaningArmazemQuizAhresp';
    return 'periodicCleaningListAhrespQuiz';
  }
}
