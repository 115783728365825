<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle taskTitle">
        <h3 class="modalTitleText">{{ 'Criar novo produto' | translate }}</h3>
        <h2 class="modalSubtitleText">{{ product.name }}</h2>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <text-input
          [responseField]="nameResponseField"
          [(value)]="product.name"
        ></text-input>
        <select-input
          [responseField]="familyResponseField"
          [(ngModel)]="product.family"
          name="family"
          [getSelectOptions]="getFamiliesOptions"
        ></select-input>
        <text-input
          [responseField]="brandResponseField"
          [(value)]="brand"
        ></text-input>
        <text-input
          [responseField]="supplierResponseField"
          [(value)]="product.supplierName"
        ></text-input>
        <text-input
          [responseField]="internalBarcodeResponseField"
          [(value)]="itm8"
        ></text-input>
        <text-input
          [responseField]="eanBarcodeResponseField"
          [(value)]="ean"
        ></text-input>
        <text-input
          [responseField]="pluResponseField"
          [(value)]="plu"
        ></text-input>
      </div>
      <div class="modalActions">
        <div class="actionsLeft"></div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="pressCreateProduct()">
            {{'Criar'|translate}}
          </button>
          <button class="btn btn-cancel light-grey" (click)="close()">
            {{'CANCEL'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
