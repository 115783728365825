import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { KeyValuePipe } from "../../pipes/keyvalue-pipe";
import { ResponseField } from "../../../entities/tasks/task"; // Update path as needed

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "text-input",
  template: `
    <div class="form-group">
      <label>{{ getTranslation(responseField) }}{{ responseField.required ? "*" : "" }}</label>
      <input
        class="form-control"
        type="text"
        [placeholder]="getPlaceholderTranslation(responseField) || ''"
        [(ngModel)]="value"
        (ngModelChange)="valueChange.emit($event)"
        [formControl]="control"
        [readonly]="!!responseField.locked"
      />
      <div *ngIf="control.touched && control.errors">
        <div *ngFor="let error of control.errors | keyvalue">
          <p style="color: red" *ngIf="error.key === 'required'">
            Este campo é obrigatório
          </p>
          <p style="color: red" *ngIf="error.key === 'minlength'">
            O mínimo de caractéres é {{ responseField.responseValidation?.minChars?.count }}
          </p>
          <p style="color: red" *ngIf="error.key === 'maxlength'">
            O máximo de caractéres é {{ responseField.responseValidation?.maxChars?.count }}
          </p>
          <p style="color: red" *ngIf="error.key === 'email'">Email inválido</p>
          <p style="color: red" *ngIf="error.key === 'pattern'">
            Verifique que o número de telefone é válido
          </p>
        </div>
      </div>
    </div>
  `,
  providers: [KeyValuePipe],
})
export class TextInputComponent implements OnInit, OnChanges {
  @Input() responseField!: ResponseField;
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  @Input() getPlaceholderTranslation: (responseField: ResponseField) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.placeholder || '';
    } else if (lang === "es") {
      return responseField.placeholderEs || responseField.placeholder || '';
    }
    return responseField.placeholder || '';
  };

  @Input() getTranslation: (responseField: ResponseField) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.name || '';
    } else if (lang === "es") {
      return responseField.nameEs || responseField.name || '';
    }
    return responseField.name || '';
  };

  control: FormControl = new FormControl('');

  constructor(private translator: TranslateService) {}

  ngOnInit() {
    this.initializeFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['responseField']) {
      this.initializeFormControl();
    }
  }

  private initializeFormControl() {
    const validators = [];
    if (this.responseField && this.responseField.responseValidation) {
      if (this.responseField.responseValidation.required) {
        validators.push(Validators.required);
      }
      if (this.responseField.responseValidation.minChars) {
        validators.push(
          Validators.minLength(this.responseField.responseValidation.minChars.count)
        );
      }
      if (this.responseField.responseValidation.maxChars) {
        validators.push(
          Validators.maxLength(this.responseField.responseValidation.maxChars.count)
        );
      }
      if (this.responseField.responseValidation.email) {
        validators.push(Validators.email);
      }
      if (this.responseField.responseValidation.phone) {
        validators.push(
          Validators.pattern(/^(?:\+\d{1,3}[\s.-]?)?(?:\d[\s.-]*){6,}$/)
        );
      }
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }
}
