import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';

@Component({
  selector: 'app-tasks-modal',
  templateUrl: './tasks.modal.html',
  styleUrls: ['./tasks.modal.scss'], // Ensure you have a styles file
})
export class TasksModalComponent {
  task: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public customTaskTypeService: CustomTaskTypesService
  ) {
    this.task = this.navParams.get('task');
  }

  close() {
    this.modalController.dismiss();
  }

  onTaskClosedSuccessfully = () => {
    this.modalController.dismiss(true);
  };
}
