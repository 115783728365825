import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SummaryModalComponent } from '../components/summary/summary.modal';
import { ProductsService } from './products.service';
import { AuthenticationService } from './authentication';
import { getLastSecondaryExpirationDate } from '../entities/product';
import { environment } from '../../environments/environment';
import { ENV_MACHINES } from '../../environments/env-machines';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryService {
  constructor(
    private modalController: ModalController,
    private authenticationService: AuthenticationService,
    private productsService: ProductsService,
    protected envService: EnvironmentService
  ) {}

  async openDefault() {
    switch (this.envService.getMachine()) {
      case ENV_MACHINES.IBERSOL:
        await this.openIbersolDefault();
        return;
      case ENV_MACHINES.PROD:
        await this.openIbersolDefault();
        return;
      default:
        return;
    }
  }

  async openIbersolDefault() {
    const modal = await this.modalController.create({
      component: SummaryModalComponent,
      componentProps: {
        data: {
          message: `Bem vindo de volta ${
            this.authenticationService.getUserInfo()?.username
          }! Aqui está o seu sumário do dia.`,
          expiringProducts: this.getExpiringProductsFormattedData(),
          expiredProducts: this.getExpiredProductsFormattedData(),
          /*           nonConformTemperatures: [
              {
                machine: 'Arca Congeladora nº1',
                temperature: -5.1,
                date: '2024-10-18 09:12',
              },
              {
                machine: 'Arca Congeladora nº3',
                temperature: 7,
                date: '2024-10-18 09:37',
              },
            ],
            goodsReception: [
              { supplier: 'Bimbo Donuts' },
              { supplier: 'Europans' },
            ], */
        },
      },
    });

    return await modal.present();
  }

  getExpiringProductsFormattedData() {
    return this.productsService.getExpiringProductsInNDays(1).map((p) => {
      return {
        name: p.name,
        expirationDate: getLastSecondaryExpirationDate(p) || '-',
      };
    });
  }

  getExpiredProductsFormattedData() {
    return this.productsService.getExpiredProducts().map((p) => {
      return {
        name: p.name,
        expirationDate: getLastSecondaryExpirationDate(p) || '-',
      };
    });
  }
}
