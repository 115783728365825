// global-tag-container.component.ts
import { Component } from '@angular/core';
import { TagPrintingService } from '../../../services/tags-printing.service';

@Component({
  selector: 'global-tag-container',
  template: `
    <div style="opacity: 0; position: fixed; top: 0; left: 0; z-index: -9999">
      <div
        id="tag"
        style="
        width: 62mm;
        height: 29mm;
        overflow: hidden;
        color: black;
        font-family: Arial, sans-serif;
      "
        *ngIf="currentTag"
      >
        <refrigerated-tag
          *ngIf="
            !currentTag.unfreezingTime &&
            !currentTag.preparationTime &&
            !currentTag.secondaryExpirationHoursSameAsPrimary
          "
          [nonApplicable]="false"
          [product]="currentTag.product"
          [primaryExpirationDate]="currentTag.primaryExpirationDate"
          [lot]="currentTag.lot"
          [preparationDate]="currentTag.preparationDate"
          [secondaryExpirationDate]="currentTag.secondaryExpirationDate"
        ></refrigerated-tag>
        <frozen-tag
          *ngIf="
            (currentTag.unfreezingTime || currentTag.preparationTime) &&
            !currentTag.secondaryExpirationHoursSameAsPrimary
          "
          [nonApplicable]="false"
          [product]="currentTag.product"
          [primaryExpirationDate]="currentTag.primaryExpirationDate"
          [lot]="currentTag.lot"
          [unfrozenDate]="currentTag.unfrozenDate"
          [secondaryExpirationDate]="currentTag.secondaryExpirationDate"
        ></frozen-tag>
        <same-secondary-as-primary-tag
          *ngIf="currentTag.secondaryExpirationHoursSameAsPrimary"
          [nonApplicable]="false"
          [product]="currentTag.product"
          [primaryExpirationDate]="currentTag.primaryExpirationDate"
          [lot]="currentTag.lot"
          [preparationDate]="currentTag.preparationDate"
        ></same-secondary-as-primary-tag>
        <thermal-shock-tag
          *ngIf="currentTag.isThermalShock"
          [nonApplicable]="false"
          [product]="currentTag.product"
          [preparationDate]="currentTag.preparationDate"
        >
        </thermal-shock-tag>
      </div>
    </div>
  `,
  styles: [],
})
export class GlobalTagContainerComponent {
  currentTag: any = null;

  constructor(private tagPrintingService: TagPrintingService) {
    this.tagPrintingService.currentTag$.subscribe((tag: any) => {
      this.currentTag = tag;
    });
  }
}
