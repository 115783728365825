<div class=" scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader task">
      <div class="modalTitle">
        <h3 class="modalTitleText">{{task.title}}</h3>
        <h2 class="modalSubtitleText" *ngIf="task.subtitle">{{task.subtitle}}</h2>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <div class="form-group">
          <label class="label">{{'NON_CONFORMITY'|translate}}:</label>
          <input class="form-control" [(ngModel)]="task.responses[0].non_conformity" type="text" readonly>
        </div>
        <div class="form-group">
          <label class="label">{{'CAUSES'|translate}}:</label>
          <input class="form-control" [(ngModel)]="task.responses[0].causes" type="text" readonly>
        </div>
        <div class="form-group">
          <label class="label">{{'ACTION'|translate}}:</label>
          <input class="form-control" [(ngModel)]="task.responses[0].correction" type="text" readonly>
        </div>
        <div class="form-group">
          <label for="comment">{{'COMMENTARY'|translate}}:</label>
          <textarea readonly class="form-control" rows="2" id="comment"
                    [(ngModel)]="task.responses[0].ncCommentary"></textarea>
        </div>
      </div>
      <div class="modalActions task">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="close()">{{'CANCEL'|translate}}</button>
        </div>
      </div>
      <div class="task">
        <!--<h4>Tarefa:</h4>-->
      </div>
      <!--<yesno-nc-task [task]="task" *ngIf="task.class[1] == 'yesNo'"></yesno-nc-task>-->
    </div>
  </div>
</div>
