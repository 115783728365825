import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StoresService } from '../../services/stores.service';
import { Store } from '../../entities/store';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-traceability-tags-page',
  templateUrl: 'traceability-tags.page.html',
})
export class TraceabilityTagsPage implements OnInit {
  store: Store | null = null;

  constructor(
    private storeService: StoresService,
    private router: Router,
    public translate: TranslateService,
    private navCtrl: NavController
  ) {}

  async ngOnInit() {
    this.store = await this.storeService.getStore();
  }

  pressRefrigeratedProducts() {
    this.navCtrl.navigateForward([
      'tabs/fields/traceability-tags/refrigerated-products',
    ]);
  }

/*   pressAllProducts() {
    this.router.navigate(['./all-products-traceability']);
  }

  pressReception() {
    this.router.navigate(['./reception']);
  }

  pressOpenUnfrozen() {
    this.router.navigate(['./open-unfrozen']);
  } */

  getModuleStyle(moduleButtonStyle: {
    backgroundColor: string;
    borderColor: string;
    imageUrl: string;
  }) {
    return {
      ...moduleButtonStyle,
      imageUrl: moduleButtonStyle?.imageUrl || 'questionario.svg',
    };
  }
}
