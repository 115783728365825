import { Injectable } from '@angular/core';
import { BleClient, BleDevice, numberToUUID } from '@capacitor-community/bluetooth-le';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  isConnected = false;
  selectedPrinter: { macAddress: string; name: string; } = { macAddress: '', name: '' };

  constructor() {}

  async ensureConnection(printerName: string): Promise<boolean> {
    try {
      await BleClient.initialize();
      const device: BleDevice = await BleClient.requestDevice({
        optionalServices: [], // Add the services your printer uses
        name: printerName
      });

      if (device) {
        await BleClient.connect(device.deviceId);
        this.isConnected = true;
        this.selectedPrinter = { macAddress: device.deviceId, name: device.name ?? '' };
        return true;
      } else {
        this.isConnected = false;
        throw new Error('Printer not found');
      }
    } catch (err) {
      this.isConnected = false;
      throw err;
    }
  }

  async searchBluetoothPrinter(): Promise<BleDevice[]> {
    try {
      await BleClient.initialize();
      const device: BleDevice = await BleClient.requestDevice({
        optionalServices: [] // Add the services your printer uses
      });
      console.log(device);
      return [device];
    } catch (error) {
      console.error('Error searching Bluetooth printers:', error);
      throw error;
    }
  }

  async connectToBluetoothPrinter(deviceId: string): Promise<void> {
    try {
      await BleClient.connect(deviceId);
      this.isConnected = true;
    } catch (error) {
      console.error('Error connecting to Bluetooth printer:', error);
      this.isConnected = false;
      throw error;
    }
  }

  async disconnectBluetoothPrinter(deviceId: string): Promise<void> {
    try {
      await BleClient.disconnect(deviceId);
      this.isConnected = false;
    } catch (error) {
      console.error('Error disconnecting Bluetooth printer:', error);
      throw error;
    }
  }

  async sendToBluetoothPrinter(dataView: DataView): Promise<void> {
    if (!this.isConnected || !this.selectedPrinter.macAddress) {
      throw new Error('Printer not connected');
    }
  
    try {
      const deviceId = this.selectedPrinter.macAddress;
      const serviceUUID = numberToUUID(0x180D); // Replace with your service UUID
      const characteristicUUID = numberToUUID(0x2A37); // Replace with your characteristic UUID
  
      await BleClient.write(deviceId, serviceUUID, characteristicUUID, dataView);
  
      console.log('Print successful');
    } catch (err) {
      console.error('Error while trying to send data to printer:', err);
      throw err;
    }
  }
  

  async getPrinters(): Promise<BleDevice[]> {
    try {
      await BleClient.initialize();
      const device: BleDevice = await BleClient.requestDevice({
        optionalServices: [] // Add the services your printer uses
      });
      console.log(device);
      return [device];
    } catch (error) {
      console.error('Error getting printers:', error);
      throw error;
    }
  }
}
