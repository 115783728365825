import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'info-modal',
  templateUrl: 'info.modal.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InfoModal {
  @Input() description: any;

  constructor(private modalCtrl: ModalController) {}

  close() {
    this.modalCtrl.dismiss();
  }
}
