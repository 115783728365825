// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, forkJoin, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import moment from 'moment';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { SensorsService } from 'src/app/services/sensors.service';

@Injectable({
  providedIn: 'root',
})
export class FollowEquipmentMaintenance implements IMyTaskHandler {
  name = 'follow-up-equipmentMaintenance';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService,
    private sensorService: SensorsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any): string {
    return type.getTitle === 'type' ? type.name : `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any): string | undefined {
    if (!type.getSubtitle) return undefined;

    switch (type.getSubtitle) {
      case 'scheduleCollectionProductApanha':
        return `${this.storeService.store.code} - ${data['Parcela']}`;
      case 'ratingApanha':
      case 'collectionProductApanha':
        return task.subtitle;
      case 'complexTask':
        return data['Comentário'];
      case 'NF':
        return `${data['Nº Guia']} - ${data['Herdade']}`;
      default:
        if (type.type === 'inProductApanhaAzeitonaNF') {
          return `${data['Nº Guia']}`;
        }
        if (type.type === 'inTransitAzeitonaNF') {
          return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
        }

        return this.generateSubtitleFromPieces(type, data);
    }
  }

  private generateSubtitleFromPieces(type: any, data: any): string {
    const pieces = type.getSubtitle.split(' ');
    return pieces
      .map((piece: string) => this.pieceToString(piece, type, data))
      .join(' - ');
  }

  private pieceToString(piece: any, type: any, data: any): string {
    switch (piece) {
      case 'type':
        return type.name;
      case 'product':
        return data['Produto'] ? data['Produto'].name : '';
      case 'expirationDate':
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
      default:
        return '';
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const type = this.customTaskTypesService.getCustomTaskType(
      'demoEquipmentMaintenance'
    );

    console.log('ESTOU AQUII');

    if (!type) {
      console.error('Type not found for demoEquipmentMaintenance.');
      return of(null);
    }

    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.error('User info not found.');
      return of(null);
    }

    // Buscar o ID da máquina com base no nome
    const machineName = data['Nome do Equipamento'];
    if (!machineName) {
      console.error('Machine name is missing in data.');
      return of(null);
    }

    // Buscar o módulo de manutenção e scheduledFieldId
    const storeModules = this.storeService.store.modules;
    const equipmentHelp = storeModules?.equipmentMaintenanceHelp;

    if (!equipmentHelp) {
      throw new Error(
        `Nenhum módulo de ajuda de manutenção encontrado para a loja com id: ${task.target.storeId}`
      );
    }

    const matchingEquipment = equipmentHelp.find(
      (help: any) => help.programFieldId === task.formId
    );

    if (!matchingEquipment) {
      console.warn(
        `Nenhum equipamento correspondente encontrado para o formId ${task.formId}.`
      );
      return of(null);
    }

    const scheduledFieldId = matchingEquipment.scheduledFieldId;
    console.log(`ScheduledFieldId encontrado: ${scheduledFieldId}`);

    // Atualizar formId com scheduledFieldId
    task.formId = scheduledFieldId;

    if (data['Tem plano de manutenção?'] !== 'Sim') {
      console.log('No maintenance plan specified.');
      return of(null);
    }

    if (data['Periodicidade'] === 'Única') {
      const newTask = {
        id: cuid(), // Generate unique ID using cuid
        type: type.type,
        formId: scheduledFieldId,
        title: `${this.getTitle(type, data)} ${machineName}`,
        subtitle: this.getSubtitle(type, data),
        startDate: moment().valueOf(),
        endDate: moment().add(1, 'day').valueOf(),
        visualizationDate: moment().add(1, 'year').valueOf(),
        nonConformities: [],
        causes: [],
        corrections: [],
        periodicity: 'Única',
        target: {
          storeId: task.target.storeId,
          userIds: getTargetUser(this.storeService.store, userInfo),
        },
        documentsIds: [],
        isCustomType: true,
        superType: undefined,
        precedingTaskId: task.id,
      };

      console.log('Creating a new task:', newTask);

      return this.tasksService
        .newTask(newTask, this.authenticationService.getAuthInfo())
        .pipe(
          (response) => {
            console.log('Task successfully created:', response);
            return of(response);
          },
          (err) => {
            console.error('Error creating task:', err);
            return of(null);
          }
        );
    } else {
      console.log(
        `Task periodicity is not "Única". Current periodicity: ${data['Periodicidade']}`
      );
    }

    return of(null);
  }
}
