import { Component, Input } from '@angular/core';
import {
  getDayOfTheWeek,
  getDayOfTheWeekAsNumber,
} from '../../../../utils/date.utils';
import { Batch } from 'src/app/entities/batch';
import { Product } from 'src/app/entities/product';
import { BatchTagInfo } from 'src/app/services/tags-printing.service';

@Component({
  selector: 'refrigerated-tag',
  template: `
    <div *ngIf="!nonApplicable" class="tag-container">
      <div class="left-section left-section-center">
        <div class="circle">
          {{ getDayOfTheWeekAsNumber(secondaryExpirationDate) }}
        </div>
      </div>
      <div class="right-section">
        <div class="product-name">
          {{ product.name | slice:0:16 }}
        </div>
        <div>
          <span class="small-text">Prep </span>
          <span class="bold">
            {{ preparationDate | date : 'yyyy-MM-dd HH:mm' }}
          </span>
        </div>
        <div>
          <span class="small-text">Val </span>
          <span class="uppercase bold underline">
            {{ getDayOfTheWeek(secondaryExpirationDate) }}
          </span>
          <span class="bold">
            {{
              secondaryExpirationDate
                ? (secondaryExpirationDate | date : 'yyyy-MM-dd HH:mm')
                : '-'
            }}
          </span>
        </div>
        <div class="small-text" style="margin-top: 2px;">
          Val 1ª
          {{
            getPrimaryExpirationDate()
              ? (getPrimaryExpirationDate() | date : 'yyyy-MM-dd')
              : lot
          }}
        </div>
      </div>
    </div>
  `,
  styleUrl: './tag.scss',
})
export class RefrigeratedTagComponent {
getPrimaryExpirationDate() {
return this.primaryExpirationDate;
}
  @Input() nonApplicable!: boolean;
  @Input() product!: Product;
  @Input() lot?: string;
  @Input() primaryExpirationDate: string | null = null;
  @Input() preparationDate!: Date;
  @Input() secondaryExpirationDate: Date | null = null;

  constructor() {}

  getDayOfTheWeek(date: Date | null): string {
    if (date === null) return '-';
    return getDayOfTheWeek(date);
  }

  getDayOfTheWeekAsNumber(date: Date | null): string {
    if (date === null) return '-';
    return getDayOfTheWeekAsNumber(date);
  }
}
