import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  templateUrl: 'nonconformity.history-modal.html',
  styleUrls: ['../../../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NonConformityHistoryModal {
  
  task: any;
  
  constructor(
    private navParams: NavParams,
    private modalController: ModalController
  ) {
    this.task = navParams.data['task'];
  }
  
  close() {
    this.modalController.dismiss();
  }
  
}
