import { ChangeDetectorRef, Component, forwardRef, Inject, Input, NgZone } from '@angular/core';
import {
  ModalController,
  NavParams,
  ToastController,
  ViewWillEnter,
  ViewWillLeave,
} from '@ionic/angular';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { TaskType } from '../../../entities/tasks/custom-task-type';

import {
  ResponseField,
  Task,
  TaskResponse,
} from '../../../entities/tasks/task';

import { Observable, forkJoin } from 'rxjs';
import { distinct, mergeMap } from 'rxjs/operators';

import { Buffer } from 'buffer';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductListModal } from '../../product-list-modal/product-list.modal';
import { ProductRawMaterialUsedListModal } from '../../product-raw-material-list-modal/product-raw-material-list.modal';
import { BatchListModal } from '../../batch/batch-list-modal/batch-list.modal';

import { BatchSelectModal } from '../../batch/batch-select-modal/batch-select.modal';
import { TasksService } from 'src/app/services/tasks.service';
import { FieldsService } from 'src/app/services/fields.service';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { AuthenticationService } from 'src/app/services/authentication';
import { CustomTaskService } from 'src/app/services/custom-task.service';
import { ProductsService } from 'src/app/services/products.service';
import { BluetoothService } from 'src/app/services/bluetoothle.service';
import { AlertService } from 'src/app/services/alert.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { SensorsService } from 'src/app/services/sensors.service';
import { PhotoModalComponent } from '../../photo/photo.modal';
import { PromptModal } from '../../prompt/prompt.modal';
import { translate } from 'src/utils/translate.utils';
import { MissingRequiredFieldsModal } from 'src/app/fields/form/missing-required-fields/missing-required-fields.modal';
import { myParseFloat } from 'src/utils/number.utils';
import { arrayAddDistinct, arrayRemove } from 'src/utils/array.utils';
import { PrinterPage } from 'src/app/printer/printer.page';
import { checkValidity } from 'src/utils/conditional-modal-creator/conditional-modal-creator';
import { CustomTypeChildTaskModal } from 'src/app/fields/form/custom-type-child-task/custom-type-child-task.modal';
import { FabricatedProduct } from 'src/app/entities/product';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/services/environment.service';
import { FormUtils } from 'src/utils/form.utils';

@Component({
  selector: 'app-form-task-modal',
  templateUrl: 'form-task-modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FormTaskModal implements ViewWillEnter, ViewWillLeave {
  task: Task;
  type: TaskType;
  response: TaskResponse;
  extras: any;

  @Input() taskTypeObject: any;

  isEarlySubmitValidator = {
    condition: () => this.isEarlySubmit(),
    modalCreator: () => this.createEarlySubmitModal(),
  };
  isTemperatureOutOfBoundsValidator = {
    condition: () => this.isTemperatureOutOfBounds(),
    modalCreator: async () => await this.createTemperatureOutOfBoundsModal(),
  };
  isChlorineOutOfBoundsValidator = {
    condition: () => this.isChlorineOutOfBounds(),
    modalCreator: () => this.createChlorineOutOfBoundsModal(),
  };
  areYouSureValidator = {
    condition: () => this.isTaskFinished(),
    modalCreator: () => this.createAreYouSureModal(),
  };

  isClicked = false;

  form: any;
  childrenTasks: any[] = [];

  inBeefRegisterTasks: any[] = [];
  prepackageTasks: any[] = [];
  unfreezingTasks: any[] = [];
  warmTemperatureControlTasks: any[] = [];

  checkPestActivityExteriorTasks: any[] = [];
  checkPestStructuralFlawsTasks: any[] = [];
  checkPestActivityInteriorTasks: any[] = [];
  checkPestStructuralFlawsInteriorTasks: any[] = [];
  checkPestPreventionMeasuresTasks: any[] = [];

  globalBrandStandardsPretTasks: any[] = [];
  customerServicePretTasks: any[] = [];
  informationPretTasks: any[] = [];
  globalEvaluationPretTasks: any[] = [];

  pendingTasksAndResponses: any[] = [];

  isConnected = false;

  questionsAndAnswersExterior: any[] = [];
  questionsAndAnswersStructuralFlaws: any[] = [];
  questionsAndAnswersInterior: any[] = [];
  questionsAndAnswersStructuralFlawsInterior: any[] = [];
  questionsAndAnswersPestPreventionMeasures: any[] = [];

  questionsAndAnswersGlobalBrandStandardsPretTasks: any[] = [];
  questionsAndAnswersCustomerServicePretTasks: any[] = [];
  questionsAndAnswersInformationPretTasks: any[] = [];
  questionsAndAnswersGlobalEvaluationPretTasks: any[] = [];

  onTaskClosedSuccessfully: any;
  characterCounts: { [key: string]: number } = {};

  totalFields: number = 0; // Total de campos com classificação
  questionsAnswered: number = 0; // Total de perguntas respondidas
  progress: string = '0/0'; // Progresso no formato 'respondidas/total'


  constructor(
    private viewCtrl: ModalController,
    private navParams: NavParams,
    private modalController: ModalController,
    private translator: TranslateService,
    private tasksService: TasksService,
    private fieldsService: FieldsService,
    public storesService: StoresService,
    private toastController: ToastController,
    private customTaskTypesService: CustomTaskTypesService,
    private authenticationService: AuthenticationService,
    @Inject(forwardRef(() => CustomTaskService))
    private customTasksService: CustomTaskService,
    private productsService: ProductsService,
    private bluetoothService: BluetoothService,
    private zone: NgZone,
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private sensorService: SensorsService,
    private filedService: FieldsService,
    protected envService: EnvironmentService,
    private cdr: ChangeDetectorRef
  ) {
    this.task = this.navParams.get('task') as Task;
    this.type = this.navParams.get('taskTypeObject') as TaskType;
    this.response = this.navParams.get('response') as TaskResponse;
    this.form = this.fieldsService.getField(this.task.formId);
    this.extractTasks();
    this.prepareQuestionsAndAnswers();
    const newChildrenTasksIds: string[] = [];

    if (this.response && this.response['childrenTasksIds']) {
      this.childrenTasks = this.response['childrenTasksIds'].map(
        (id: string) => {
          const task = this.tasksService.getTask(id);
          if (task) {
            newChildrenTasksIds.push(id);
            return {
              task,
              response: task.responses
                ? task.responses[0]
                : task.pendingResponses
                ? task.pendingResponses[0]
                : undefined,
            };
          } else {
            return { task: undefined, response: undefined };
          }
        }
      );
      this.prepackageTasks = this.childrenTasks.filter((t) => {
        return t.task
          ? t.task.type === 'prepackagedPDVIntermarcheShort2'
          : false;
      });
      this.unfreezingTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'unfreezingProductsShort2' : false;
      });

      this.warmTemperatureControlTasks = this.childrenTasks.filter((t) => {
        return t.task
          ? t.task.type === 'warmTemperatureControlIntermarcheShort2'
          : false;
      });
      this.inBeefRegisterTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'inBeefRegisterShort2' : false;
      });
      this.checkPestActivityExteriorTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'checkPestActivityExterior' : false;
      });
      this.checkPestStructuralFlawsTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'checkPestStructuralFlaws' : false;
      });
      this.checkPestActivityInteriorTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'checkPestActivityInterior' : false;
      });
      this.checkPestStructuralFlawsInteriorTasks = this.childrenTasks.filter(
        (t) => {
          return t.task
            ? t.task.type === 'checkPestStructuralFlawsInterior'
            : false;
        }
      );
      this.checkPestPreventionMeasuresTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'checkPestPreventionMeasures' : false;
      });

      this.globalBrandStandardsPretTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'globalBrandStandardsPret' : false;
      });
      this.customerServicePretTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'customerServicePret' : false;
      });
      this.informationPretTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'informationPret' : false;
      });
      this.globalEvaluationPretTasks = this.childrenTasks.filter((t) => {
        return t.task ? t.task.type === 'globalEvaluationPret' : false;
      });
    }
    this.response['childrenTasksIds'] = newChildrenTasksIds;
    this.extras = this.navParams.get('extras') || {};
    if (this.task.productId) {
      this.extras.product = this.productsService
        .getProducts()
        .filter((p) => this.task.productId === p.id)[0];
    }

    if (
      (this.type.type === 'oil' || this.type.type === 'oilPans') &&
      this.storesService.store &&
      this.storesService.store.modules.bluetoothOilReader
    ) {
      this.bluetoothService.connectBleThermometer(
        (res) => {
          if (res.value) {
            let base64Value = res.value;
            let buff = Buffer.from(base64Value, 'base64');
            zone.run(() => {
              this.response['Temperatura (ºC):'] = buff.toString('ascii');
              this.response['AUTOMATIC_READING'] = true;
            });
          }
        },
        (res) => {
          if (res.value) {
            let base64Value = res.value;
            let buff = Buffer.from(base64Value, 'base64');
            zone.run(() => {
              this.response['Resultado da leitura'] = buff.toString('ascii');
              this.response['AUTOMATIC_READING'] = true;
            });
          }
        },
        () => (this.isConnected = true)
      );
    }
  }

  ionViewWillEnter() {
    // Add logic that needs to be executed when the view will enter
  }

  ionViewWillLeave() {
    // Add logic that needs to be executed when the view will leave
  }

  extractTasks() {
    if (this.response && this.response['childrenTasksIds']) {
      this.childrenTasks = this.response['childrenTasksIds'].map((id: any) => {
        const task = this.tasksService.getTask(id);
        return task
          ? {
              task: task,
              response: task.responses
                ? task.responses[0]
                : task.pendingResponses
                ? task.pendingResponses[0]
                : undefined,
            }
          : { task: undefined, response: undefined };
      });

      this.filterTasksByType();
    }
  }

  filterTasksByType() {
    const taskFilters: { [key: string]: string } = {
      checkPestActivityExteriorTasks: 'checkPestActivityExterior',
      checkPestStructuralFlawsTasks: 'checkPestStructuralFlaws',
      checkPestActivityInteriorTasks: 'checkPestActivityInterior',
      checkPestStructuralFlawsInteriorTasks: 'checkPestStructuralFlawsInterior',
      checkPestPreventionMeasuresTasks: 'checkPestPreventionMeasures',
      globalBrandStandardsPretTasks: 'globalBrandStandardsPret',
      customerServicePretTasks: 'customerServicePret',
      informationPretTasks: 'informationPret',
      globalEvaluationPretTasks: 'globalEvaluationPret',
    };

    for (const key in taskFilters) {
      if (taskFilters.hasOwnProperty(key)) {
        const type = taskFilters[key];
        // Use type assertion to let TypeScript know `this[key]` is valid.
        (this as any)[key] = this.childrenTasks.filter(
          (t) => t.task && t.task.type === type
        );
      }
    }
  }

  prepareQuestionsAndAnswers() {
    this.questionsAndAnswersExterior = [];
    this.questionsAndAnswersStructuralFlaws = [];
    this.questionsAndAnswersInterior = [];
    this.questionsAndAnswersStructuralFlawsInterior = [];
    this.questionsAndAnswersPestPreventionMeasures = [];

    this.questionsAndAnswersGlobalBrandStandardsPretTasks = [];
    this.questionsAndAnswersCustomerServicePretTasks = [];
    this.questionsAndAnswersInformationPretTasks = [];
    this.questionsAndAnswersGlobalEvaluationPretTasks = [];

    this.checkPestActivityExteriorTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersExterior.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.checkPestStructuralFlawsTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersStructuralFlaws.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.checkPestActivityInteriorTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersInterior.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.checkPestStructuralFlawsInteriorTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersStructuralFlawsInterior.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });
    this.checkPestPreventionMeasuresTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersPestPreventionMeasures.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.globalBrandStandardsPretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersGlobalBrandStandardsPretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.customerServicePretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersCustomerServicePretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.informationPretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersInformationPretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });

    this.globalEvaluationPretTasks.forEach((taskObj) => {
      const response = taskObj.response;
      for (const key in response) {
        if (response.hasOwnProperty(key) && key.includes('?')) {
          this.questionsAndAnswersGlobalEvaluationPretTasks.push({
            question: key,
            answer: response[key],
          });
        }
      }
    });
  }

  processCondition(field: any, response?: TaskResponse): boolean {
    response = response || this.response;

    // Check if the condition is valid
    if (!field.condition) return true;

    const conditions = field.condition.split(', ');

    // Check if conditions array is valid
    if (conditions.length === 0) return true;

    return (
      conditions.filter((c: any) => {
        // Check for OR condition
        if (c.includes('||')) {
          const orConditions = c.split('||');
          return orConditions.some((orCond: any) => {
            const [left, right] = orCond.split('===').map((s: any) => s.trim());
            return response![left] === right;
          });
        }
        // Original conditions checking...
        else if (c.includes('===')) {
          const sides = c.split('===').map((s: any) => s.trim());
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response![leftSide] === rightSide;
        } else if (c.includes('!==')) {
          const sides = c.split('!==').map((s: any) => s.trim());
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response![leftSide] !== rightSide;
        } else if (c === 'expirationDate') {
          if (
            response!['Data de validade'] &&
            moment(response!['Data de validade']).isBefore(
              moment().add(4, 'days')
            )
          ) {
            return true;
          }
          return false;
        }
        if (c[0] === '!') {
          const condition = c.substring(1, c.length);
          return !response![condition];
        }
        return !!response![c];
      }).length === conditions.length
    );
  }

  getInput(responseField: ResponseField) {
    return "response['" + responseField.name + "']";
  }

  close() {
    this.viewCtrl.dismiss();
  }

  async pressCamera(response: TaskResponse, responseField: ResponseField) {
    const photos = response[responseField.name as keyof TaskResponse]; // Type assertion

    const photoModal = await this.modalController.create({
      component: PhotoModalComponent,
      componentProps: {
        photos: photos,
      },
    });

    photoModal.onDidDismiss().then((result: any) => {
      if (result.data) {
        this.response[responseField.name as keyof TaskResponse] = result.data;
      } else {
        this.response[responseField.name as keyof TaskResponse] = [];
      }
    });

    return await photoModal.present();
  }

  async pressListCamera(object: any, propertyKey: string) {
    const photos = object[propertyKey];

    const photoModal = await this.modalController.create({
      component: PhotoModalComponent,
      componentProps: {
        photos: photos,
      },
    });

    photoModal.onDidDismiss().then((result) => {
      if (result.data) {
        object[propertyKey] = result.data;
      } else {
        object[propertyKey] = [];
      }
    });

    return await photoModal.present();
  }

  async pressSubmit() {
    if (this.isClicked) return;
    this.isClicked = true;
    const missingRequiredFields = this.getMissingRequiredFields();
    let isFrozenStop = false;
    let isRefrigerateStop = false;
    if (
      this.task.type === 'goodsReception' ||
      this.task.type === 'goodsReceptionNP' ||
      this.task.type === 'goodsReception2' ||
      this.task.type === 'goodsReceptionNP2'
    ) {
      if (
        (this.response['Temp. Refrigerados Amostra 1 (ºC)'] !== '' &&
          (this.response['Temp. Refrigerados Amostra 1 (ºC)'] < 0 ||
            this.response['Temp. Refrigerados Amostra 1 (ºC)'] > 5)) ||
        (this.response['Temp. Refrigerados Amostra 2 (ºC)'] !== '' &&
          (this.response['Temp. Refrigerados Amostra 2 (ºC)'] < 0 ||
            this.response['Temp. Refrigerados Amostra 2 (ºC)'] > 5))
      ) {
        const refrigeratePromise = new Promise<boolean>((resolve) => {
          const promptOutOfBounds = this.modalController.create({
            component: PromptModal,
            componentProps: {
              title: translate(
                'OUTOFBOUNDS_REFRIGERATE_TEMPERATURE_TITLE',
                this.translator
              ),
              text: translate(
                'OUTOFBOUNDS_REFRIGERATE_TEMPERATURE_TEXT',
                this.translator
              ),
            },
          });
          promptOutOfBounds.then((modal) =>
            modal.onDidDismiss().then((data: any) => {
              resolve(data);
            })
          );
          promptOutOfBounds.then((modal) => modal.present());
        });
        isRefrigerateStop = !(await refrigeratePromise);
      }
      if (
        (this.response['Temp. Congelados Amostra 1 (ºC)'] !== '' &&
          (this.response['Temp. Congelados Amostra 1 (ºC)'] < -21 ||
            this.response['Temp. Congelados Amostra 1 (ºC)'] > -15)) ||
        (this.response['Temp. Congelados Amostra 2 (ºC)'] !== '' &&
          (this.response['Temp. Congelados Amostra 2 (ºC)'] < -21 ||
            this.response['Temp. Congelados Amostra 2 (ºC)'] > -15))
      ) {
        const frozenPromise = new Promise<boolean>((resolve) => {
          const promptOutOfBounds = this.modalController.create({
            component: PromptModal,
            componentProps: {
              title: translate(
                'OUTOFBOUNDS_FROZEN_TEMPERATURE_TITLE',
                this.translator
              ),
              text: translate(
                'OUTOFBOUNDS_FROZEN_TEMPERATURE_TEXT',
                this.translator
              ),
            },
          });
          promptOutOfBounds.then((modal) =>
            modal.onDidDismiss().then((data: any) => {
              resolve(data);
            })
          );
          promptOutOfBounds.then((modal) => modal.present());
        });
        isFrozenStop = !(await frozenPromise);
      }
    }
    if (missingRequiredFields.length > 0) {
      const missingRequiredFieldsModal = await this.modalController.create({
        component: MissingRequiredFieldsModal,
        componentProps: { missingRequiredFields },
      });
      await missingRequiredFieldsModal.present();
      this.isClicked = false;
    } else if (!isFrozenStop && !isRefrigerateStop) {
      const isValid = await checkValidity(
        [
          this.isTemperatureOutOfBoundsValidator,
          this.isChlorineOutOfBoundsValidator,
          this.areYouSureValidator,
        ],
        this.modalController
      );
      if (isValid) {
        await this.successfullySubmit();
      } else {
        this.isClicked = false;
      }
    } else {
      this.isClicked = false;
    }
  }

  async successfullySubmit() {
    if (this.pendingTasksAndResponses.map((data) => data.task.id).length > 0) {
      forkJoin(
        this.pendingTasksAndResponses.map((data) => {
          return this.tasksService
            .newTask(data.task, this.authenticationService.getAuthInfo())
            .pipe(
              mergeMap(() => {
                return this.customTasksService.respondContent(data.task, {
                  ...data.response,
                  'Cód. Barras': this.response['Cód. Barras'],
                });
              })
            );
        })
      ).subscribe(
        () => {
          this.viewCtrl.dismiss(this.response).then(() => {
            this.isClicked = false;
          });
        },
        (err) => {
          console.log(err);
          this.isClicked = false;
        }
      );
    } else {
      await this.viewCtrl.dismiss(this.response);
      this.isClicked = false;
    }
  }

  isEarlySubmit(): boolean {
    return moment.now() < this.task.startDate;
  }

  async createEarlySubmitModal(): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('BEFORE_SCHEDULE_ANSWERING_TEXT', this.translator),
      },
    });
    await modal.present();
    return modal;
  }

  isTemperatureOutOfBounds(): boolean {
    const { type } = this.task;
    const temperature = myParseFloat(this.response['Temperatura (ºC):']);
    const temperatureSerunion = myParseFloat(
      this.response['Temperatura Actual (ºC):']
    );
    const okRangeMax = myParseFloat(this.task.okRangeMax ?? 0);
    const okRangeMin = myParseFloat(this.task.okRangeMin ?? 0);

    if (type === 'singleTemperature' || type === 'singleTemperatureEs') {
      return (
        !this.response['Máquina desligada?'] &&
        (temperature > okRangeMax || temperature < okRangeMin)
      );
    }
    if (type === 'serunionSingleTemperature') {
      return (
        !this.response['Máquina desligada?'] &&
        (temperatureSerunion > okRangeMax || temperatureSerunion < okRangeMin)
      );
    }
    return false;
  }

  async createTemperatureOutOfBoundsModal() {
    const title = await translate('INVALID_TEMPERATURE_TITLE', this.translator);
    const text = await translate('INVALID_ARE_YOU_SURE', this.translator);

    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title,
        text,
        task: this.task,
      },
    });
  }

  isChlorineOutOfBounds(): boolean {
    if (this.task.type === 'chlorineControl') {
      return (
        !this.response['Máquina desligada?'] &&
        (myParseFloat(this.response['Cloro (ppm):']) > 0.6 ||
          myParseFloat(this.response['Cloro (ppm):']) < 0.2)
      );
    }
    return false;
  }

  isTaskFinished(): boolean {
    return (
      this.task.type === 'inProductApanhaAzeitonaNF' &&
      !!this.response['Tarefa terminada']
    );
  }

  async createChlorineOutOfBoundsModal(): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('INVALID_CHLORINE_TITLE', this.translator),
        text: translate('INVALID_ARE_YOU_SURE', this.translator),
      },
    });
    await modal.present();
    return modal;
  }

  async createAreYouSureModal(): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate(
          'Tem a certeza que quer fechar a tarefa?',
          this.translator
        ),
      },
    });
    await modal.present();
    return modal;
  }

  getTitle(): string {
    if (this.type.getTitle === 'typeFailure') {
      return `${this.type.name} - ${this.response['Tipo da Avaria']}`;
    }
    if (this.type.getTitle === 'documentName') {
      return `${this.type.name} - ${this.response['Nome documento']}`;
    }
    return this.task.title;
  }

  getSubtitle(): string | undefined {
    return this.task.subtitle;
  }

  addToList(responseField: ResponseField): void {
    if (!this.response[responseField.name]) {
      this.response[responseField.name] = [];
    }
  
    const newItem: any = {
      name: '',
      quantity:  1,
      unit: 'kg',
    };
  
    if (this.type?.type === 'serunionProductionNewModel') {
      newItem.photos = [];
      newItem.isNew = true;
    }
  
  
    this.response[responseField.name].push(newItem);
  }

  async removeFromList(
    responseField: ResponseField,
    index: number
  ): Promise<void> {
    if (this.response[responseField.name].length <= 1) {
      const toast = await this.toastController.create({
        message: 'A lista tem que conter pelo menos 1 ingrediente.',
        duration: 2000,
        position: 'top',
        buttons: [
          {
            text: this.translator.instant('Fechar'),
            role: 'cancel',
          },
        ],
      });
      await toast.present(); // Ensure to await the present method
      return;
    }
    this.response[responseField.name] = this.arrayRemoveIdx(
      this.response[responseField.name],
      index
    );
  }

  arrayRemoveIdx(arr: any[], index: number): any[] {
    return arr.filter((_, i) => i !== index);
  }

  addOrRemoveArray(event: any, value: string, responseArray: any[]) {
    if (event.checked) {
      arrayAddDistinct(responseArray, value);
    } else if (!event.checked) {
      arrayRemove(responseArray, value);
    }
  }

  addToGroup(responseField: ResponseField, group: any[]) {
    if (responseField.subfields) {
      group.push(this.autofillSubfields(responseField.subfields));
    }
  }

  removeFromGroup(group: any[], item: any) {
    const index = group.indexOf(item);
    group.splice(index, 1);
  }

  autofillSubfields(subfields: any[]): any {
    const response: any = {};
    subfields.forEach((sf) => {
      if (response[sf.name] || sf.autofill) return;
      if (sf.type === 'boolean') {
        response[sf.name] = false;
      } else if (sf.type === 'date') {
        response[sf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (sf.type === 'list') {
        response[sf.name] = [
          {
            name: '',
            quantity: 1,
            unit: 'kg',
          },
        ];
      } else if (sf.type === 'checklist') {
        response[sf.name] = [];
      } else if (sf.type === 'group') {
        response[sf.name] = [this.autofillSubfields(sf.subfields)];
      } else if (sf.type === 'photo') {
        response[sf.name] = [];
      } else if (sf.type === 'textarea' && sf.type.count === true) {
        this.characterCounts[sf.name] = sf.autofill.length;
        response[sf.name] = sf.autofill;
      } else {
        response[sf.name] = '';
      }
    });
    return response;
  }

  updateCharacterCount(fieldName: string, text: string): void {
    this.characterCounts[fieldName] = text ? text.length : 0;
  }

  toggleHideGroupFields(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ) {
    if (!responseField.isGrouper) return;
    this.toggleHideGroupsField2(responseField, index, responseFields);
  }

  toggleHideGroupsField2(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ) {
    for (let i = index; i < responseFields.length; ++i) {
      if (responseFields[i] !== responseField && responseFields[i].isGrouper) {
        return;
      }
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleHideGroupsField3(
    responseField: ResponseField,
    index: number,
    responseFields: ResponseField[]
  ) {
    for (let i = index + 1; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) return;
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleOffHideGroupFields(
    responseField: ResponseField,
    responseFields: ResponseField[]
  ): void {
    let startingIndex: number | undefined;
    let isValid = false;

    for (let i = 0; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) {
        startingIndex = i;
      }
      if (responseFields[i] === responseField) {
        isValid = true;
        break;
      }
    }
    if (!isValid || startingIndex === undefined) return;
    this.toggleHideGroupsField3(responseField, startingIndex, responseFields);
  }

  onChangeNumberInput(value: number, responseField: ResponseField) {
    const product = this.extras.product;
    if (responseField.name === 'Quantidade fabricada') {
      if (this.response['Lista de ingredientes']) {
        this.response['Lista de ingredientes'].forEach((i: any) => {
          if (
            product.technicalSheet['Lista de ingredientes'] &&
            product.technicalSheet['Lista de ingredientes'].find(
              (p: any) => p.name === i.name
            )
          ) {
            i.quantity =
              product.technicalSheet['Lista de ingredientes'].find(
                (p: any) => p.name === i.name
              ).quantity * value;
          } else {
            i.quantity = i.quantity * value;
          }
        });
      }
    }
  }

  onChangeSelectInput(
    value: any,
    responseField: ResponseField,
    response: TaskResponse
  ) {
    if (responseField.name === 'Ação') {
      switch (value) {
        case 'Orientação e Segurança no Trabalho':
          response['Duração da formação em horas'] = 1.83;
          break;
        case 'Segurança Alimentar':
          response['Duração da formação em horas'] = 1.67;
          break;
        case 'Introdução às funções da KFC':
          response['Duração da formação em horas'] = 1.17;
          break;
        case 'Valores HWWT²':
          response['Duração da formação em horas'] = 1.33;
          break;
        case 'Exame Básicos':
          response['Duração da formação em horas'] = 0.5;
          break;
        case 'Hospitalidade':
          response['Duração da formação em horas'] = 9.17;
          break;
        case 'Funções Fusion':
          response['Duração da formação em horas'] = 8.67;
          break;
        case 'Registo do Pedido':
          response['Duração da formação em horas'] = 5.33;
          break;
        case 'Preparação do Pedido':
          response['Duração da formação em horas'] = 8.67;
          break;
        case 'Entrega do Pedido':
          response['Duração da formação em horas'] = 6.67;
          break;
        case 'Gestão de Clientes':
          response['Duração da formação em horas'] = 6;
          break;
        case 'Exame FOH':
          response['Duração da formação em horas'] = 0.5;
          break;
        case 'Observation Checklist FOH':
          response['Duração da formação em horas'] = 8;
          break;
        case 'Manipulação do Frango':
          response['Duração da formação em horas'] = 5.67;
          break;
        case 'Marinar':
          response['Duração da formação em horas'] = 6.5;
          break;
        case 'Receita Original':
          response['Duração da formação em horas'] = 10.67;
          break;
        case 'Receita Crocante':
          response['Duração da formação em horas'] = 10.67;
          break;
        case 'Exame BOH':
          response['Duração da formação em horas'] = 0.5;
          break;
        case 'Observation Checklist BOH':
          response['Duração da formação em horas'] = 8;
          break;
        case 'FTF - freezer to fryer':
          response['Duração da formação em horas'] = 10.33;
          break;
        case 'Preparação de Acompanhamentos':
          response['Duração da formação em horas'] = 11.33;
          break;
        case 'Sanduíches':
          response['Duração da formação em horas'] = 11.5;
          break;
        case 'Introdução a Embalar':
          response['Duração da formação em horas'] = 10.33;
          break;
        case 'Exame MOH':
          response['Duração da formação em horas'] = 0.5;
          break;
        case 'Observation Checklist MOH':
          response['Duração da formação em horas'] = 8;
          break;

        //IBERSOL
        case 'PROGR. FORM. PRODUÇÃO':
          response['Duração da formação em horas'] = 66;
          break;
        case 'PROGR. FORM. RESTAURANTE':
          response['Duração da formação em horas'] = 56;
          break;
        case 'PROGR. FORM. DELIVERY':
          response['Duração da formação em horas'] = 26;
          break;
        case 'PROGR. FORM. BALCÃO':
          response['Duração da formação em horas'] = 26;
          break;
        case 'CLEAN 2 SAFE - PH':
          response['Duração da formação em horas'] = 6;
          break;
        case 'INTEGRAÇÃO':
          response['Duração da formação em horas'] = 4;
          break;
        case 'SEGURANÇA ALIMENTAR':
          response['Duração da formação em horas'] = 2;
          break;
        case 'SEGURANÇA SAÚDE  TRAB.':
          response['Duração da formação em horas'] = 2;
          break;
        case 'HSA RECERTIFICAÇÃO':
          response['Duração da formação em horas'] = 2;
          break;
        case 'SST RECERTIFICAÇÃO':
          response['Duração da formação em horas'] = 2;
          break;
        case 'PROGR. FORM. PROD. RECERT.':
          response['Duração da formação em horas'] = 70;
          break;
        case 'PROGR. FORM. REST. RECERT.':
          response['Duração da formação em horas'] = 60;
          break;
        case 'PROGR. FORM. DEL.  RECERT.':
          response['Duração da formação em horas'] = 30;
          break;
        case 'PROGR. FORM. BALC.  RECERT.':
          response['Duração da formação em horas'] = 30;
          break;
        case 'RECERT. CLEAN 2 SAFE - PH':
          response['Duração da formação em horas'] = 6;
          break;
      }
    }
  }

  getSelectOptions = (
    responseField: ResponseField,
    response = this.response
  ) => {
    if (responseField.selectOptionsGetter === 'getFormTasks.info') {
      const form = this.fieldsService.getFormByName(response['Área']);
      const tasks = this.tasksService.getTasksFromForm(form.id);
      return tasks.map(
        (t) =>
          `${t.title}${t.subtitle ? ` - ${t.subtitle}` : ''} - ${moment(
            t.startDate
          ).format('YYYY-MM-DD HH:mm')} - ${t.id}`
      );
    } else if (
      responseField.selectOptionsGetter === 'getRastreabilidadeProductTasks'
    ) {
      const tasks = this.tasksService.getTasksByType(
        'unpackgedProductsIntermarche2'
      );
      return tasks.map(
        (t) =>
          `${t.subtitle} - ${moment(t.startDate).format(
            'YYYY-MM-DD HH:mm'
          )} - ${t.id}`
      );
    } else if (
      responseField.selectOptionsGetter === 'getFabricatedProductTasks'
    ) {
      const tasks = this.tasksService.getTasksByType('productionIM2');
      return tasks.map(
        (t) =>
          `${t.subtitle} - ${moment(t.startDate).format(
            'YYYY-MM-DD HH:mm'
          )} - ${t.id}`
      );
    } else if (responseField.selectOptionsGetter === 'store.machines.oil') {
      return this.sensorService.getMachinesByType('oil').map((m) => m.machine);
    } else if (responseField.selectOptionsGetter === 'store.users')
      return this.authenticationService.getStoreUsersUsername();
    else if (responseField.selectOptionsGetter === 'destinationNF') {
      return this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .buyers.map((b: any) => `${b.fullName} (${b.companyFullName})`);
    } else if (
      responseField.selectOptionsGetter === 'store.modules.gasMixtures'
    ) {
      return this.storesService.store.modules.gasMixtures;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.packageMaterial'
    ) {
      return this.storesService.store.modules.packageMaterial;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.expiration.productDestinations'
    ) {
      return this.storesService.store.modules.expiration.productDestinations;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.desinfectionProducts'
    ) {
      return this.storesService.store.modules.desinfectionProducts;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.pestSector'
    ) {
      return this.storesService.store.modules.pestSector;
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.goodsReception.directSupplierList'
    ) {
      return (
        this.storesService.store.modules.goodsReception.directSupplierList || []
      );
    } else if (responseField.selectOptionsGetter === 'product.supplier') {
      const product = this.task?.productId
        ? this.productsService.getProductById(this.task.productId)
        : null;

      let supplierNames = product?.supplierName;

      if (!supplierNames) {
        return [];
      } else if (Array.isArray(supplierNames)) {
        return supplierNames;
      } else if (typeof supplierNames === 'string') {
        return [supplierNames];
      } else {
        return [];
      }
    } else if (
      responseField.selectOptionsGetter ===
      'store.modules.goodsReception.nonConformitiesList'
    ) {
      return (
        this.storesService.store.modules.goodsReception.nonConformitiesList ||
        []
      );
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.haccp'
    ) {
      return this.storesService.store.modules.emailsReport.haccp.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.help'
    ) {
      return this.storesService.store.modules.emailsReport.help.emailsTo;
    } else if (
      responseField.selectOptionsGetter === 'store.modules.emailsReport.docs'
    ) {
      return this.storesService.store.modules.emailsReport.docs.emailsTo;
    } else if (responseField.selectOptionsGetter === 'getMachines.name') {
      const machines = this.sensorService.getMachinesName();
      return machines;
    } else if (
      responseField.selectOptionsGetter === 'getMachinesByCategory.name'
    ) {
      const categoryMachine = response['Categoria do Equipamento']?.trim();
      const filteredMachines =
        this.sensorService.getMachinesByCategory(categoryMachine);
      return filteredMachines;
    } else if (
      responseField.selectOptionsGetter === 'getMachinesSupplier.name'
    ) {
      const machine =
        response['Selecione o Equipamento para Incidência']?.trim();
      const filteredMachines = this.sensorService.getMachinesSupplier(machine);
      return filteredMachines;
    } else if (
      responseField.selectOptionsGetter === 'getMachinesSupplier.email'
    ) {
      const supplierName = response['Selecione o Equipamento para Incidência']?.trim();
      const filteredMachines =
        this.sensorService.getMachinesSupplierEmail(supplierName);
      return filteredMachines;
    } else if (responseField.selectOptionsGetter === 'getEmployees.name') {
      const allEmployeeNames = this.employeeService.getEmployeesName();
      return allEmployeeNames;
    } else if (responseField.selectOptionsGetter === 'getField.name') {
      const field = this.filedService.getFieldName('form');
      return field;
    } else if (responseField.selectOptionsGetter === 'getFolder.name') {
      const folder = this.storesService.getFolderTitles();
      return folder;
    } else if (responseField.selectOptionsGetter === 'getReportFolder.name') {
      const folder = this.storesService.getReportFolderTitles();
      return folder;
    } else if (responseField.selectOptionsGetter === 'getReportFiles.name') {
      const folderTitle = response['Pasta do relatório'].trim();
      const files = this.storesService.getReportFilesTitles(folderTitle);

      return files;
    } else if (responseField.selectOptionsGetter === 'getForms.name') {
      const field = this.filedService.getFieldName('form');
      return field;
    } else if (responseField.selectOptionsGetter === 'getFields.name') {
      const fields = this.filedService.getFieldNames();
      return fields;
    } else if (
      responseField.selectOptionsGetter === 'getTechnicalSheets.name'
    ) {
      const products = this.productsService
        .getFabricatedProducts()
        .map(
          (p) =>
            `${p['technicalSheet']['Designação do produto']} - ${p['technicalSheet']['Versão']} - ${p.id}`
        );
      return products;
    } else if (this.type.type === 'trainingIbersolKFC') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PFI BÁSICOS') {
          return [
            'Orientação e Segurança no Trabalho',
            'Segurança Alimentar',
            'Introdução às funções da KFC',
            'Valores HWWT²',
            'Exame Básicos',
          ];
        } else if (response && response['Módulo'] === 'PFO BÁSICOS') {
          return ['Exame Básicos'];
        } else if (response && response['Módulo'] === 'PFI CAIXA (FOH)') {
          return [
            'Hospitalidade',
            'Funções Fusion',
            'Registo do Pedido',
            'Preparação do Pedido',
            'Entrega do Pedido',
            'Gestão de Clientes',
            'Exame FOH',
            'Observation Checklist FOH',
          ];
        } else if (response && response['Módulo'] === 'PFO CAIXA (FOH)') {
          return ['Exame FOH', 'Observation Checklist FOH'];
        } else if (response && response['Módulo'] === 'PFI COZINHA (BOH)') {
          return [
            'Manipulação do Frango',
            'Marinar',
            'Receita Original',
            'Receita Crocante',
            'Exame BOH',
            'Observation Checklist BOH',
          ];
        } else if (response && response['Módulo'] === 'PFO COZINHA (BOH)') {
          return ['Exame BOH', 'Observation Checklist BOH'];
        } else if (response && response['Módulo'] === 'PFI PACK (MOH)') {
          return [
            'FTF - freezer to fryer',
            'Preparação de Acompanhamentos',
            'Sanduíches',
            'Introdução a Embalar',
            'Exame MOH',
            'Observation Checklist MOH',
          ];
        } else if (response && response['Módulo'] === 'PFO PACK (MOH)') {
          return ['Exame MOH', 'Observation Checklist MOH'];
        } else if (response && response['Módulo'] === 'PFI DRIVE THRU') {
          return [
            'Drive Thru',
            'PT KFC EXAM DRIVE THRU',
            'Observation Checklist Drive Thru',
          ];
        } else if (response && response['Módulo'] === 'PFO DRIVE THRU') {
          return ['PT KFC EXAM DRIVE THRU', 'Observation Checklist Drive Thru'];
        } else if (response && response['Módulo'] === 'PFI SALA') {
          return ['Empregado de Sala', 'Exame Sala'];
        } else if (response && response['Módulo'] === 'PFO SALA') {
          return ['Exame Sala'];
        } else if (
          response &&
          response['Módulo'] === 'PFI  LIDERAR UM TURNO (LAS)'
        ) {
          return [
            'Introdução ao LAS',
            'Experiência do cliente',
            'Instrução',
            'Planeamento de Produto',
            'Gestão de Inventário',
            'Gestão de Desempenho',
            'Qualidade de Produto',
            'Tudo sob Controlo',
            'Serviço com velocidade',
            'Segurança',
            'Exame LAS',
            'Observation Checklist LAS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO  LIDERAR UM TURNO (LAS)'
        ) {
          return ['Exame LAS', 'Observation Checklist LAS'];
        } else if (response && response['Módulo'] === 'PFI Alergeneos') {
          return ['Alergeneos'];
        } else if (
          response &&
          response['Módulo'] === 'PFI Gestão de crises (Loja)'
        ) {
          return ['Gestão de crises (Loja)'];
        } else if (response && response['Módulo'] === 'PFI Kiosks') {
          return ['Kiosks'];
        } else if (
          response &&
          response['Módulo'] === 'PFI  LIDERAR UMA LOJA (LAR)'
        ) {
          return [
            'Introdução ao LAR',
            'Impulsionar Vendas',
            'Como formar a sua equipa',
            'Previsão',
            'Gerir Pessoal',
            'Liderança',
            'GES',
            'ROCC',
            'Desafio Final',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO  LIDERAR UMA LOJA (LAR)'
        ) {
          return ['Desafio Final'];
        } else if (response && response['Módulo'] === 'PFI RGM ACADEMY') {
          return [
            'Liderança com o Coração',
            'Trabalhar com Eficiência',
            'Introdução a RGM',
            'Planeamento de Brigadas',
            'Coaching com o Coração',
            'Formar e Desenvolver a tua Equipa',
            'A Experiência do Cliente',
            'Protecção da Marca',
            'Gerir com se fosse Teu',
            'Gestão Financeira',
            'Controlo de Custos',
            'Previsão de Vendas e Horários',
            'Cria uma Cultura de Família e Diversão',
            'Trabalhar Melhor, Juntos',
            'Ótima comunicação',
            'Celebra o Sucesso',
          ];
        }
      } else if (responseField.name === 'Módulo') {
        let modules: string[] = responseField.selectOptions ?? [];
        if (
          response &&
          (response['Nível interno'] === 'OPERADOR(A)' ||
            (response && response['Nível interno'] === 'OPERADOR(A) DD'))
        ) {
          return modules.filter((s) => {
            return (
              s !== 'PFI  LIDERAR UM TURNO (LAS)' &&
              s !== 'PFO  LIDERAR UM TURNO (LAS)' &&
              s !== 'PFI  LIDERAR UMA LOJA (LAR)' &&
              s !== 'PFO  LIDERAR UMA LOJA (LAR)' &&
              s !== 'PFI RGM ACADEMY'
            );
          });
        }
        if (
          response &&
          (response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B')
        ) {
          return modules.filter((s) => {
            return (
              s !== 'PFO  LIDERAR UMA LOJA (LAR)' && s !== 'PFI RGM ACADEMY'
            );
          });
        }
      }
    } else if (this.type.type === 'trainingIbersolPH') {
      if (responseField.name === 'Ação') {
        if (
          response &&
          response['Módulo'] === 'PFO INICIAL PROGRAMAS FORMAÇÃO SECÇÕES'
        ) {
          return [
            'PROGRAMA FORMAÇÃO PRODUÇÃO',
            'PROGRAMA FORMAÇÃO RESTAURANTE',
            'PROGRAMA FORMAÇÃO DELIVERY',
            'PROGRAMA FORMAÇÃO BALCÃO',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL CLEAN 2 SAFE'
        ) {
          return ['CLEAN 2 SAFE - PH'];
        } else if (response && response['Módulo'] === 'PFI ACOLHIMENTO') {
          return [
            'INTEGRAÇÃO',
            'SEGURANÇA ALIMENTAR',
            'SEGURANÇA E SAÚDE NO TRABALHO',
          ];
        } else if (response && response['Módulo'] === 'PFO CONTINUA HSA/SST') {
          return ['RECERTIFICAÇÃO HSA -2022', 'RECERTIFICAÇÃO SST-2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA PROGRAMAS FORMAÇÃO SECÇÕES'
        ) {
          return [
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT PRODUÇÃO - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT ATENDIMENTO NO RESTAURANTE - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT ATENDIMENTO AO BALCÃO - 2022',
            'RECERTIFICAÇÃO PROGRAMA FORMAÇÃO @EXPERT DELIVERY - 2022',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONT. CLEAN 2 SAFE REC.'
        ) {
          return ['RECERTIFICAÇÃO CLEAN TO SAFE - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL HSA @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL HSA @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO INICIAL SST @LEADING A SHIFT'
        ) {
          return ['FORMAÇÃO INICIAL SST @LEADING A SHIFT'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO @ LEADING A SHIFT - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA HSA @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO  HSA @ LEADING A SHIFT - 2022'];
        } else if (
          response &&
          response['Módulo'] === 'PFO CONTINUA SST @LEADING A SHIFT'
        ) {
          return ['RECERTIFICAÇÃO  SST @ LEADING A SHIFT - 2022'];
        }
      }
    } else if (this.type.type === 'trainingIbersolMM') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'BASICS') {
          return [
            'WELCOME',
            'WORK SAFETY',
            'FOOD SAFETY',
            'CLEAN AND SAFE',
            'FOOD ALLERGIES AND INTOLERENCES',
            'CERTIFICAÇÃO INICIAL BASICS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FRONT OF THE HOUSE (FOH)'
        ) {
          return [
            'SERVICE LOVERS',
            'GUEST EXPERIENCE',
            'SALES OPERATIONS',
            'CERTIFICAÇÃO INICIAL FOH',
          ];
        } else if (
          response &&
          response['Módulo'] === 'BACK OF THE HOUSE (BOH)'
        ) {
          return ['FOOD LOVERS'];
        }
      }
    } else if (this.type.type === 'trainingIbersolPANS') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PANS_PFI_BASICS') {
          return [
            'PANS_BASICS_ACOLHIMENTO',
            'SEGURANÇA E SAÚDE NO TRABALHO : PRINCIPIOS BÁSICOS',
            'ACOLHIMENTO E INTEGRAÇÃO',
            'SEGURANÇA ALIMENTAR : PRINCIPIOS BÁSICOS',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_BACK OF HOUSE'
        ) {
          return [
            'PANS_BOH_FOLHA DE PREPARAÇÃO',
            'PANS_BOH_PÃO',
            'PANS_BOH_SANDES',
            'PANS_BOH_PRODUTOS FRITOS',
            'PANS_BOH_SALADAS E SOPAS',
            'PANS_BOH_SOBREMESAS',
            'PANS_BOH_PREPARAÇÃO',
            'PANS_BOH_SUMOS',
            'PANS_BOH_MAKE-TABLE',
            'PANS_BOH_PASTELARIA E SALGADOS',
            'PANS_CERTIFICAÇÃO_BOH',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_FRONT OF HOUSE'
        ) {
          return [
            'PANS_FOH_DISTRIBUIÇÃO AO CLIENTE',
            'PANS_FOH_INTERAÇÃO COM O CLIENTE',
            'PANS_FOH_REGISTO DE POS',
            'PANS_FOH_CAFÉ PANS',
            'PANS_CERTIFICAÇÃO_FOH',
            'PANS APP',
            'Delivery',
          ];
        } else if (response && response['Módulo'] === 'PANS_PFC_NOVIDADE') {
          return ['PANS_INVERNO_2023', 'PANS_PRIMAVERA_2023'];
        } else if (response && response['Módulo'] === 'PANS_PFC_BASICS') {
          return [
            'RECERTIFICAÇÃO BASICS 3º TRIMESTRE 2022',
            'RECERTIFICAÇÃO BASICS 4º TRIMESTRE 2022',
          ];
        } else if (response && response['Módulo'] === 'PANS_PFC_OPERAÇÕES') {
          let ret: any[] = [];
          if (response['Nível interno'] === 'OPERADOR(A)') {
            ret = ret.concat([
              'RECERTIFICAÇÃO CHECKLIST OPERAÇÃO 2023',
              'Prova Recertificação Operadores 1º semestre 2023',
              'Prova Recertificação Operadores 2º semestre 2023',
            ]);
          }
          if (
            response['Nível interno'] !== 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] !== 'DIRECTOR(A) UNIDADE - B' ||
            response['Nível interno'] !== 'DIRECTOR(A) UNIDADE - C'
          ) {
            ret = ret.concat([
              'Fazemos sempre melhor-Normas apresent_Op-GT',
              "Equipamentos de trabalho e uso de EPI's",
            ]);
          }
          return ret;
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFI_EQUIPAS DIREÇÃO'
        ) {
          let ret: any[] = [];

          if (
            response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B'
          )
            ret = ret.concat(['Prova de Certificação de Gestão de Turno 2023']);
          if (
            response['Nível interno'] !== 'OPERADOR(A)' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - A' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Certificação de Direção de Unidade 2023',
            ]);
          }
          return ret;
        } else if (
          response &&
          response['Módulo'] === 'PANS_PFC_EQUIPAS DIREÇÃO'
        ) {
          let ret: any[] = [];

          if (
            response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - A' ||
            response['Nível interno'] === 'GESTOR(A) TURNO - B'
          )
            ret = ret.concat([
              'Prova de Recertificação de Gestão de Turno 2023',
            ]);
          if (
            response['Nível interno'] !== 'OPERADOR(A)' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - A' &&
            response['Nível interno'] !== 'GESTOR(A) TURNO - B'
          ) {
            ret = ret.concat([
              'Prova de Recertificação de Direção de Unidade 2023',
            ]);
          }
          if (response['Nível interno'] !== 'OPERADOR(A)')
            ret = ret.concat([
              'Recertificação Checklist Equipas de Direção 2023',
              'Prova Recertificação Equipas Direção 2023',
            ]);
          return ret;
        }
      } else if (responseField.name === 'Módulo') {
        let modules: string[] = responseField.selectOptions ?? [];
        if (response && response['Nível interno'] === 'OPERADOR(A)') {
          return modules.filter((s) => {
            return (
              s !== 'PANS_PFI_EQUIPAS DIREÇÃO' &&
              s !== 'PANS_PFC_EQUIPAS DIREÇÃO'
            );
          });
        } else if (
          response &&
          (response['Nível interno'] === 'GESTOR(A) UNIDADE' ||
            response['Nível interno'] === 'DIRECTOR(A) UNIDADE - B' ||
            response['Nível interno'] === 'DIRECTOR(A) UNIDADE - C')
        ) {
          return modules.filter((s) => {
            return s !== 'PANS_PFC_OPERAÇÕES';
          });
        }
      }
    } else if (this.type.type === 'trainingIbersolMIIT') {
      if (responseField.name === 'Ação') {
        if (
          response &&
          response['Módulo'] === 'FORMAÇÃO HIGIENE E SEGURANÇA ALIMENTAR MIIT'
        ) {
          return [
            'Tipos de contaminações e como evitá-las',
            'Norma de Higiene - Manipuladores',
            'Norma de Higiene - Lavagem das mãos',
            'Norma de Higiene- Visitantes',
            'Norma de Higiene - Diferença entre limpezas diárias e periódicas',
            'Norma de Higiene - Como limpar',
            'Norma de Higiene - Quem verifica',
            'Alergias e Intolerâncias Alimentares',
            'Acrilamida nos Alimentos',
            'Check list - Lavagem das mãos',
            'Check list - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO SEGURANÇA E SAÚDE NO TRABALHO MIIT'
        ) {
          return [
            'Riscos no posto de trabalho',
            'Organização da Segurança contra Incêndios',
            'Combate a incêndios',
            'Evacuação',
            'Primeiros Socorros',
            'Acidentes de trabalho',
            'Medicina no trabalho',
            'Checklist - Observação ao turno',
          ];
        } else if (response && response['Módulo'] === 'GRELHA') {
          return [
            'Grelhar',
            'Retenção',
            'Abertura e Fecho de grelha',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'PREPARAÇÃO DE CARNES') {
          return [
            'Preparação de carnes',
            'Preparação de temperos para a carne',
            'Limpeza e mantenção da área de produção',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] === 'CONFEÇÃO DE ACOMPANHAMENTOS E EMPRATAMENTO'
        ) {
          return [
            'Regeneração de Molhos',
            'Preparação do acompanhamento Batata rústica ',
            'Preparação do acompanhamento Arroz aromatizado',
            'Preparação dos acompanhamentos de frutas e legumes',
            'Preparação do acompanhamento Ovo estrelado',
            'Montagem da maketable e banho-maria, empratamento e entrega',
            'Confeção de Pão',
            'Outros acompanhamentos',
            'Limpeza e manutenção da área de confeção e empratamento',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] ===
            'PREPARAÇÃO DE VEGETAIS, FRUTA, SOBREMESAS, BEBIDAS E MOLHOS'
        ) {
          return [
            'Higienização de frutas, legumes e ervas aromáticas',
            'Preparação do Acompanhamento Salada',
            'Pré - preparação do Acompanhamento Legumes grelhados',
            'Preparação das frutas',
            'Preparação das sobremesas doces',
            'Preparação de bebidas',
            'Preparação de Molhos',
            'Limpeza e manutenção da área de preparação',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'COPA') {
          return [
            'Gestão de resíduos e óleo alimentar',
            'Lavagem de louça, utensílios, armazenamento e montagem de tabuleiros',
            'Limpeza e manutenção da máquina de lavar',
            'Limpeza e manutenção da área da copa',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'CAIXA E BALCÃO') {
          return [
            'Apresentação e farda',
            'Caixa',
            'Entrega de pedidos',
            'Abertura e fecho do balcão',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        } else if (response && response['Módulo'] === 'HOSPEDEIRA/HOSPEDEIRO') {
          return [
            'Apresentação e farda',
            'Acolhimento do cliente',
            'Avaliação de conhecimentos',
            'Avaliação de competências',
          ];
        }
      }
    } else if (this.type.type === 'trainingIbersolRIBS') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'ACOLHIMENTO') {
          return ['BEM VINDO AO RIBS'];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO HIGIENE E SEGURANÇA ALIMENTAR RIBS'
        ) {
          return [
            'Introdução',
            'Segurança Alimentar',
            'Tipos de contaminações e como evitá-las',
            'Normas de Higiene - Manipuladores',
            'Normas de Higiene - Lavagem das mãos',
            'Normas de Higiene - Visitantes',
            'Normas de higiene - Diferença entre Limpeza e Higienização',
            'Normas de higiene - O que Limpar',
            'Normas de higiene - Diferença entres limpezas diárias e periódicas',
            'Normas de higiene - Como limpar',
            'Normas de higiene - Quem Verifica',
            'Alergias e intolerâncias Alimentares',
            'Acrilamida nos alimentos',
            'Check List - Lavagem das mãos',
            'Check List - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO SEGURANÇA E SAÚDE NO TRABALHO RIBS'
        ) {
          return [
            'Noções Básicas de Segurança e Saúde no Trabalho',
            'Riscos no posto de trabalho',
            'Organização da Segurança contra incêndios',
            'Combate a Incêndios',
            'Evacuação',
            'Primeiros Socorros',
            'Acidentes de Trabalho',
            'Medicina no Trabalho',
            'Check List - Observação ao turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'COZINHA - Formação Cozinheiro(a)'
        ) {
          return [
            'Norma de Manipuladores',
            'Rastreabilidade',
            'Tarefas de Abertura',
            'Limpeza de Fritadeiras',
            'Tarefas de passagem de turno',
            'Tarefas de fecho de turno',
            'Operação durante o turno',
          ];
        } else if (
          response &&
          response['Módulo'] === 'COZINHA - Formação Churrasqueiro(a)'
        ) {
          return [
            'Norma de Manipuladores',
            'Rastreabilidade',
            'Tarefas de Abertura',
            'Limpeza de Fritadeiras',
            'Tarefas de passagem de turno',
            'Tarefas de fecho de turno',
            'Preparação e confeção de carnes',
            'Operação durante o turno',
          ];
        } else if (response && response['Módulo'] === 'COZINHA FORMAÇÃO COPA') {
          return [
            'Gestão de resíduos e óleo alimentar',
            'Lavagem de louça, utensílios, armazenamento e montagem de tabuleiros',
            'Limpeza e manutenção da máquina de lavar',
            'Limpeza e manutenção da área da copa',
            'Avaliação de competências',
          ];
        } else if (
          response &&
          response['Módulo'] === 'FORMAÇÃO  CAIXA E  BALCÃO'
        ) {
          return [
            'Apresentação e farda',
            'Caixa',
            'Entrega de pedidos',
            'Abertura e fecho do balcão',
            'Avaliação de competências',
          ];
        }
      }
    } else if (this.type.type === 'trainingIbersolPC') {
      if (responseField.name === 'Ação') {
        if (response && response['Módulo'] === 'PFI Módulo de Integração') {
          return ['Bem-Vindo ao Pasta Caffé'];
        } else if (
          response &&
          response['Módulo'] === 'PFO Inicial Segurança Alimentar'
        ) {
          return [
            'Higiene e Segurança Alimentar - Pasta Caffé',
            'Alergias e Intolerãncias Alimentares',
            'Acrilamida',
          ];
        } else if (
          response &&
          response['Módulo'] === 'PFO Inicial Segurança e Saúde no Trabalho'
        ) {
          return ['Segurança e Saúde no Trabalho- Pasta Caffé'];
        } else if (response && response['Módulo'] === 'PFO Inicial Sala') {
          return [
            'Bar',
            'Organização de sala',
            'Momentos de Verdade',
            'Agregadores - Sala',
          ];
        } else if (response && response['Módulo'] === 'PFO Inicial Cozinha') {
          return [
            'Make table 2',
            'Make table 1',
            'Saida de Forno',
            'Copa',
            'Pesagens',
            'Preparações de Cozinha',
            'Agregadores - cozinha',
          ];
        }
      }
    } else if (responseField.selectOptionsGetter === 'store.plots.name') {
      return this.storesService.store.plots.map((p: any) => p.name);
    } else if (responseField.selectOptionsGetter === 'societyNF') {
      // Step 1: Check if 'Grupo de Colheita' exists and it has contents
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        // Step 2: Get the array of groups matching names in the 'Grupo de Colheita' array
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );

        // Step 3: Get the distinct 'sociedade' values from each group
        const distinctSocieties = Array.from(
          new Set(matchingGroups.map((group: any) => group.codigo_sociedade))
        );

        response['Sociedade'] = distinctSocieties;
        // Step 5: Return the joined string
        return distinctSocieties;
      }

      // If 'Grupo de Colheita' does not exist or has no contents, return some fallback value (e.g., an empty string)
      return '';
    } else if (responseField.selectOptionsGetter === 'varietyNF') {
      // Step 1: Check if 'Grupo de Colheita' exists and it has contents
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        // Step 2: Get the array of groups matching names in the 'Grupo de Colheita' array
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );

        // Step 3: Get the distinct 'sociedade' values from each group
        const distinctVarieties = Array.from(
          new Set(matchingGroups.map((group: any) => group.variedade))
        );

        response['Variedade'] = distinctVarieties;
        // Step 5: Return the joined string
        return distinctVarieties.sort();
      }

      // If 'Grupo de Colheita' does not exist or has no contents, return some fallback value (e.g., an empty string)
      return '';
    } else if (responseField.selectOptionsGetter === 'field.plots.name') {
      return this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.map((p: any) => p.codigo_grupo_colheita)
        .sort();
    } else if (
      responseField.selectOptionsGetter === 'field.plots.parcels.name'
    ) {
      // Step 1: Check if 'Grupo de Colheita' exists and it has contents
      if (
        response['Grupo de Colheita'] &&
        response['Grupo de Colheita'].length > 0
      ) {
        // Step 2: Get the array of groups matching names in the 'Grupo de Colheita' array
        const matchingGroups = this.fieldsService
          .getClosestHerdadeField(this.form.id)
          .plots.filter((plot: any) =>
            response['Grupo de Colheita'].includes(plot.codigo_grupo_colheita)
          );

        // Step 3: Join all the 'plots.parcelas' array items in a single array
        const parcelasArray = matchingGroups.reduce(
          (acc: any, currentGroup: any) =>
            acc.concat(currentGroup.parcelas.map((p: any) => p.codigo_parcela)),
          []
        );

        // Step 4: Return the single array
        return parcelasArray.sort();
      }

      // If 'Grupo de Colheita' does not exist or has no contents, return some fallback value (e.g., an empty array)
      return [];
    } else if (responseField.selectOptionsGetter === 'store.plots.fullName') {
      return this.storesService.store.plots.map((p: any) => p.fullName);
    } else if (
      responseField.selectOptionsGetter === 'store.plots.varieties' &&
      response['Parcela']
    ) {
      let parcel = this.storesService.store.plots.filter(
        (p: any) =>
          p.name === response['Parcela'] || p.fullName === response['Parcela']
      )[0];
      if (!parcel) return [];
      return parcel.varieties;
    } else if (
      responseField.selectOptionsGetter === 'store.plots.varieties' &&
      response['Grupo de Colheita']
    ) {
      let parcels = this.storesService.store.plots.filter(
        (p: any) =>
          !!response['Grupo de Colheita'].filter(
            (gc: any) => gc === p.name || gc === p.fullName
          )[0]
      );
      const varieties = distinct(parcels.map((p: any) => p.varieties).flat());
      if (!(varieties.length > 0)) return [];
      return varieties;
    } else if (
      responseField.selectOptionsGetter === 'field.plots.varieties' &&
      response['Parcela']
    ) {
      let parcel = this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.filter(
          (p: any) =>
            p.name === response['Parcela'] || p.fullName === response['Parcela']
        )[0];
      if (!parcel) return [];
      return parcel.varieties.sort();
    } else if (
      responseField.selectOptionsGetter === 'field.plots.varieties' &&
      response['Grupo de Colheita']
    ) {
      let parcels = this.fieldsService
        .getClosestHerdadeOrLagarField(this.form.id)
        .plots.filter(
          (p: any) =>
            !!response['Grupo de Colheita'].filter(
              (gc: any) => gc === p.name || gc === p.fullName
            )[0]
        );
      const varieties = distinct(parcels.map((p: any) => p.varieties).flat());
      if (!(varieties.length > 0)) return [];
      return varieties;
    } else if (responseField.selectOptionsGetter === 'store.plots.buyers') {
      return this.storesService.store.buyers.map((b: any) => b.code);
    } else if (responseField.selectOptionsGetter === 'suppliersLJ') {
      return [
        '1 Gelvalados - Produtos Alimentares Lda',
        '2 Pérola da Ilha',
        '3 Repraçores - Comércio e Representações AS',
        '4 Finançor Cach & Carry, Lda',
        '13 Finançor Distribuição Alimentar, Lda',
        '5 Sodril - Representações Insulares, Lda',
        '6 Insco - Insular de Hiper AS',
        '7 Higiaçores - Comercio e Serviços Lda',
        '8 Melo Abreu',
        '9 Paulo Teixeira',
        '10 Grupo Nabeiro',
        '11 Ocean Gin Company Lda',
        '12 O Rei dos Queijos',
        '13 Finançor Distribuição Alimentar, Lda',
        '14 Garcias Wine & Spirits',
        '15 Bom Sumo Lda',
        'Outro',
      ];
    } else if (responseField.selectOptionsGetter === 'productsLJ') {
      if (
        response &&
        response['Fornecedor'] === '5 Sodril - Representações Insulares, Lda'
      ) {
        return [
          'CRF',
          'Martel',
          'Zakapa',
          'Omelca - white',
          'Omelca - reposado',
          'Omelca - choc',
          'Eristoff Black',
          'Stolichnaya - Red',
          'Stolichnaya - Elit',
          'Black Label',
          'Baileys',
          'Beirão',
          'Porto - 20 anos',
          'Porto - branco',
          'Porto - Jockey Club',
          'Porto - LBV',
          'Burmester - Branco',
          'Burmester - Tinto',
          'Casa Burmester - B',
          'Casa Burmester - T',
          'Pedras limão',
          'Pedras tangerina',
          'Somersby',
          'Super bock',
          'S.B Sout',
          'S.B Weiss',
          'S.B Munich',
          'S.B IPA',
          'Não carne picada',
          'Hamburguer não carne',
          'Hamburguer não frango',
          'Nuggets Vegan',
          'Juliana Pimentos',
          'Falafel',
          'Brownie',
          'Sumo limão',
          'Preparado Mojito',
          'Pasta de Manjericão',
          'Nata vegan',
          'Clara de ovo',
          'Gema de ovo',
          "Hellmann's maionese",
          "Hellmann's mostarda",
          'Calve Barbecue',
          'Salada Bulgur & Whet',
          'Cabola pré frita (Kg)',
          'Arroz Basmati (12kg)',
          'Azeite (€/L)',
          'Pasta de Manjericão (340gr)',
          'Bechamel desidratado (800gr)',
          'Massa lasanha Knorr (5kg)',
          'Queijo fatiado flamengo (1KG)',
          'Queijo mozarella ralado (500gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '3 Repraçores - Comércio e Representações AS'
      ) {
        return [
          'Remi Martin',
          'Amicis',
          'Brockmans',
          'Hendricks',
          'Martin Millers',
          'Sailor Jerry',
          'Brugal (White)',
          'Brugal (Añejo)',
          'Brugal (Extra)',
          'Brugal (1888)',
          'Famous Grouse',
          'Glenfidish',
          "Grant's",
          "Grant's - 12 anos",
          'Monkey Shoulder',
          'Naked',
          'Tullamore',
          'Drambuie',
          'Rocim - Branco',
          'Rocim - Tinto',
          'pepsi',
          '7up',
          'compal laranja',
          'compal pessego',
          'compal ananas',
          'compal maça',
          'compal limão',
          'REVO',
        ];
      } else if (response && response['Fornecedor'] === '9 Paulo Teixeira') {
        return [
          'Courvoisier',
          'Citadelle',
          'Nordés',
          'Plantation (3Stars)',
          'Plantation (Dark)',
          'Plantation 5 años',
          'Plantation 20 años',
          'Plantation Pinaple',
          "Tito's",
          'Cutty Sark',
          "Jack Daniel's",
          'Batida de côco',
          'Dry Curaçao',
          'Grenadine',
          'Kahlúa',
          'Licor amora',
          'Licor maracujá',
          'Ginger Ale',
          'Ginger Beer',
          'Elderflower',
          'Mediterrain',
          'Indian',
          'Light',
          'Soda',
          'Cola',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '14 Garcias Wine & Spirits'
      ) {
        return [
          'Courvoisier',
          'CRF',
          'Citadelle',
          "Tito's",
          'Black Label',
          'Jim Beam',
          'Batida de côco',
          'Beirão',
          'Campari',
          'Cointreau',
          'Kahlúa',
          'Licor amora',
          'Licor maracujá',
          'Vermute',
          'Ginger Ale',
          'Ginger Beer',
          'Elderflower',
          'Mediterrain',
          'Indian',
          'Light',
          'Soda',
          'Cola',
        ];
      } else if (response && response['Fornecedor'] === '15 Bom Sumo Lda') {
        return [
          'Baleia',
          'Licor ananás (500ml)',
          'Licor ananás (100ml)',
          'Compota ananás',
          'Ananás',
        ];
      } else if (response && response['Fornecedor'] === '8 Melo Abreu') {
        return ['Baleia', 'Licor ananás (500ml)', 'Licor ananás (100ml)'];
      } else if (
        response &&
        response['Fornecedor'] === '4 Finançor Cach & Carry, Lda'
      ) {
        return [
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Amendoim',
          'Cajú frito',
          'Sortidos',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Noz moscada',
          'Cereja em calda',
          'Arroz',
          'Cogumelos',
          'Compota uva',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Tostas finas',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Iogurte grego',
          'Pato',
          'Atum natural enlatado (420gr)',
          'Azeite (€/L)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Miolo de noz',
          'Molho inglês Savora (120ml)',
          'Noz moscada (Margão)',
          'Pasta de Manjericão (340gr)',
          'Pimenta preta Margão (grão)',
          'Segredos do méxico (Mrg)',
          'sementes de chia (150gr)',
          'sementes de sêsamo (200gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '13 Finançor Distribuição Alimentar, Lda'
      ) {
        return [
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Pasta de Manjericão',
          'Óleo de côco',
          'Cereja em calda',
          'Arroz',
          'Cogumelos',
          'molho de soja',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Chocolate negro',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Iogurte grego',
          'Molho iogurte',
          'Queijo Parmesão',
          'Queijo fatiado flamengo',
          'Queijo Ilha ralado',
          'Alho picado (100gr)',
          'Atum natural enlatado (420gr)',
          'Azeite (€/L)',
          'Azeitonas preta picadas (200gr)',
          'Batata assar (Kg)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Cogumelos (congelados 400gr)',
          'Feijão preto enlatado (820gr)',
          'Molho soja Kikkoman (150ml)',
          'Molho inglês Savora (120ml)',
          'Óleo de côco (200ml vs 500ml)',
          'Pão cereais (650gr)',
          'Pimenta preta Margão (grão)',
          'sementes de chia (150gr)',
          'sementes de sêsamo (200gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
          'Tostas finas',
          'Aipo (500Kg)',
          'Salada Azores Fresh',
          'Alho frances (kg)',
          'Canoura (Kg)',
          'Gengibre (Kg)',
          'salsa (Kg)',
          'Iogurte grego  (Oîkos)',
          'Chouriço de Carne do Minho Ponte de Lima P.D.',
          'Pato (Kg)',
          'Bacon extra (naco) (Kg)',
          'Queijo Parmesão (150gr)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '6 Insco - Insular de Hiper AS'
      ) {
        return [
          'Espinafres com alho',
          'Polpa manga',
          'Polpa maracujá',
          'Açúcar amarelo',
          'Anis Estrelado',
          'Cravinho',
          'Louro',
          'Sal ',
          'Pimentas Margão',
          'Pimenta Cayenne',
          'Caril',
          'Canela (Pau)',
          'Pasta de Manjericão',
          'Óleo de côco',
          'Azeite (seleção)',
          'Arroz',
          'Cogumelos',
          'molho de soja',
          'molho inglês',
          'sementes de chia',
          'sementes de sêsamo',
          'Chocolate negro',
          'Pão cereais',
          'Pão Thin',
          'Laranja',
          'Limão',
          'Lima',
          'Pepino',
          'Maça verde',
          'Morango',
          'Mirtilos',
          'Limão Galego',
          'Aipo',
          'Alho seco',
          'Alho frances',
          'Cabola',
          'Canoura',
          'salsa',
          'hortelã',
          'tomilho fresco',
          'Gengibre',
          'Uva branca',
          'tomate (cacho)',
          'Alface',
          'Massa lasanha',
          'Massa tenra',
          'Iogurte grego',
          'Chouriço carne',
          'Presunto (naco)',
          'Pato',
          'Queijo Parmesão',
          'Queijo fatiado flamengo',
          'Queijo Ilha ralado',
          'Espinafres com alho (750gr)',
          'Azeite (€/L)',
          'Azeitonas preta picadas (200gr)',
          'Cabola tostada (100gr)',
          'Caril suave (Margão)',
          'Cogumelos (congelados 400gr)',
          'Feijão preto enlatado (820gr)',
          'Molho inglês Savora (120ml)',
          'Noz moscada (Margão)',
          'Óleo de côco (200ml vs 500ml)',
          'Segredos do méxico (Mrg)',
          'sementes de chia (150gr)',
          'MolhoTomate Manj. Oregãos e Alecrim Compal (500gr)',
          'Alho frances (kg)',
          'Canoura (Kg)',
          'Iogurte grego  (Oîkos)',
        ];
      } else if (
        response &&
        response['Fornecedor'] === '12 O Rei dos Queijos'
      ) {
        return [
          'Compota uva',
          'Queijo Velho S.Miguel',
          'Queijo do Morro',
          'Queijo das Furnas',
          'Queijo S.João',
        ];
      } else if (response && response['Fornecedor'] === '2 Pérola da Ilha') {
        return ['Amendoim', 'Cajú frito', 'Sortidos'];
      } else if (response && response['Fornecedor'] === '10 Grupo Nabeiro') {
        return ['Café', 'Descafeinado'];
      } else if (
        response &&
        response['Fornecedor'] === '7 Higiaçores - Comercio e Serviços Lda'
      ) {
        return [
          'Filtro perfumado para Urinol',
          'Loção de mãos Chá de limão',
          'Sabão de mãos Chá de limão',
          'Pano microfibra amarelo',
          'Pano microfibra azul',
          'Pano microfibra rosa',
          'Pano microfibra verde',
          'Pano microfibra roxo',
          'Suma 4n1',
          'Papel hig folha a folha',
          'Bricer',
          'Suma Alcool Spray D4.12',
          'Suma Bac',
          'Suma Chlorsan D10.4',
          'Suma Multipurpose',
          'Toalhete de Mãos ZZ',
          'Fairy',
          'Saco lixo (15L)',
          'Saco lixo (30L)',
          'Saco lixo (50L)',
          'Saco lixo (100L)',
          'Guardanapo Grd',
          'Guardanapo Peq',
          'Guardanapo (preto)',
          'Papel de mãos autocorte',
          'Rolo térmico 57x40x11',
          'Rolo térmico 80x80x11',
          'Sacos plástico AD neutro 20x30',
          'Sacos plástico AD neutro 30x40',
          'Sacos plástico AD neutro 35x50',
          'Toalha individual mesa (preto)',
        ];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'problems') {
      // Acessando os dados de ocorenci
      const ocorenciData = this.storesService.store.modules.ocorenci;

      // Extrair todos os problemas
      const problemsList = ocorenciData.map((item: any) => item.problems);

      // Retorna o array com todos os problemas
      return problemsList;
    } else if (responseField.selectOptionsGetter === 'suppliers') {
      const ocorenciData = this.storesService.store.modules.ocorenci;
      const selectedProblem = response['Tipo da Avaria'];
      // console.log("selectedProblem", selectedProblem);
      const matchingProblemEntry = ocorenciData.find(
        (item: any) => item.problems === selectedProblem
      );

      // Resetar a seleção de fornecedor sempre que o problema for alterado
      if (response['previousSelectedProblem'] !== selectedProblem) {
        response[responseField.name] = ''; // Limpa a seleção do fornecedor
        response['previousSelectedProblem'] = selectedProblem; // Atualiza o problema anterior
      }

      response['allSuppliers'] = [];

      if (
        matchingProblemEntry &&
        Array.isArray(matchingProblemEntry.suppliers)
      ) {
        // Armazena todos os fornecedores na lista allSuppliers
        response['allSuppliers'] = matchingProblemEntry.suppliers.map(
          (entry: any) => entry.supplier
        );

        // Se o campo do fornecedor estiver vazio e houver apenas um fornecedor, defina-o automaticamente
        if (
          response[responseField.name] === '' &&
          matchingProblemEntry.suppliers.length === 1
        ) {
          response[responseField.name] =
            matchingProblemEntry.suppliers[0].supplier;
        }
      } else {
        // Se nenhum fornecedor correspondente for encontrado, ou se não houver fornecedores
        response[responseField.name] = ''; // Define o campo de fornecedor como vazio
      }

      // Retorna a lista de fornecedores (mesmo que seja um array vazio)
      return response['allSuppliers'];
    } else if (responseField.selectOptionsGetter === 'emailSuppliers') {
      const ocorenciData = this.storesService.store.modules.ocorenci;

      // Encontrar o fornecedor selecionado
      const selectedSupplier = response['Fornecedor'];
      // console.log("selectedSupplier", selectedSupplier);
      // Encontrar o objeto de ocorrência que corresponde ao fornecedor
      const matchingSupplierEntry = ocorenciData.find((item: any) =>
        item.suppliers.some(
          (supplier: any) => supplier.supplier === selectedSupplier
        )
      );

      const selectedProblem = response['Tipo da Avaria'];
      // console.log("selectedProblem", selectedProblem);
      // Encontrar o objeto de ocorrência que corresponde ao problema selecionado
      const matchingProblemEntry = ocorenciData.find(
        (item: any) => item.problems === selectedProblem
      );

      response['allEmailSuppliers'] = [];

      if (matchingProblemEntry && matchingSupplierEntry) {
        // Se fornecedor correspondente for encontrado
        const suppliersWithinProblem = matchingProblemEntry.suppliers;
        const selectedSupplierEntry = suppliersWithinProblem.find(
          (supplier: any) => supplier.supplier === selectedSupplier
        );

        if (selectedSupplierEntry && selectedSupplierEntry.emailsupplier) {
          // Se um fornecedor correspondente foi encontrado
          const emails = selectedSupplierEntry.emailsupplier || [];

          if (emails.length > 1) {
            // Se houver mais de um email, retorna a lista de emails
            response['allEmailSuppliers'] = emails;
            return response['allEmailSuppliers'];
          } else {
            // Se houver apenas um email, define-o como padrão
            response[responseField.name] = emails[0] || '';
            return [emails[0] || ''];
          }
        }
      }

      // Se nenhum fornecedor correspondente for encontrado ou se não houver emails
      response[responseField.name] = '';
      return [''];
    } else if (responseField.selectOptionsGetter === 'problemsNC') {
      return [
        'Caixas de Gordura',
        'Filtros da Hotte',
        'Desentupimentos',
        'Toldos',
        'A/C',
        'Motor Hotte',
        'Centrais de Incêndio',
        'Extintores',
        'Carreteis de Água',
        'Problemas POS',
        'Assistências',
        'Camaras CCTV',
        'Música Ambiente',
        'Equipamento',
      ];
    } else if (responseField.selectOptionsGetter === 'suppliersNC') {
      if (
        (response && response['Tipo da Avaria'] === 'Caixas de Gordura') ||
        response['Tipo da Avaria'] === 'Filtros da Hotte' ||
        response['Tipo da Avaria'] === 'Desentupimentos'
      ) {
        return ['Desentop / Assist'];
      } else if (response && response['Tipo da Avaria'] === 'Toldos') {
        return ['Tolniber'];
      } else if (
        (response && response['Tipo da Avaria'] === 'A/C') ||
        response['Tipo da Avaria'] === 'Motor Hotte'
      ) {
        return ['Efi3ene'];
      } else if (
        (response && response['Tipo da Avaria'] === 'Centrais de Incêndio') ||
        response['Tipo da Avaria'] === 'Extintores' ||
        response['Tipo da Avaria'] === 'Carreteis de Água'
      ) {
        return ['Alarmes 24'];
      } else if (response && response['Tipo da Avaria'] === 'Problemas POS') {
        return ['Codisys'];
      } else if (response && response['Tipo da Avaria'] === 'Assistências') {
        return [
          'Assistência Refrige',
          'Assistência Nicola',
          'Assistência Superbock',
        ];
      } else if (response && response['Tipo da Avaria'] === 'Camaras CCTV') {
        return ['Smart Solutions'];
      } else if (response && response['Tipo da Avaria'] === 'Música Ambiente') {
        return ['Sonoro Legal'];
      } else if (response && response['Tipo da Avaria'] === 'Equipamento') {
        return ['TecniHotel'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'emailSuppliersNC') {
      if (
        (response &&
          response['Fornecedor'] === 'Desentop / Assist' &&
          response['Tipo da Avaria'] === 'Caixas de Gordura') ||
        response['Tipo da Avaria'] === 'Filtros da Hotte' ||
        response['Tipo da Avaria'] === 'Desentupimentos'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.desentopAssist;
      } else if (
        response &&
        response['Fornecedor'] === 'Tolniber' &&
        response['Tipo da Avaria'] === 'Toldos'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.tolniber;
      } else if (
        (response &&
          response['Fornecedor'] === 'Efi3ene' &&
          response['Tipo da Avaria'] === 'A/C') ||
        response['Tipo da Avaria'] === 'Motor Hotte'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.efi3ene;
      } else if (
        (response &&
          response['Fornecedor'] === 'Alarmes 24' &&
          response['Tipo da Avaria'] === 'Centrais de Incêndio') ||
        response['Tipo da Avaria'] === 'Extintores' ||
        response['Tipo da Avaria'] === 'Carreteis de Água'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.alarmes24;
      } else if (
        response &&
        response['Fornecedor'] === 'Codisys' &&
        response['Tipo da Avaria'] === 'Problemas POS'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.codisys;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Refrige' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaRefrige;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Nicola' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaNicola;
      } else if (
        response &&
        response['Fornecedor'] === 'Assistência Superbock' &&
        response['Tipo da Avaria'] === 'Assistências'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.assistênciaSuperbock;
      } else if (
        response &&
        response['Fornecedor'] === 'Smart Solutions' &&
        response['Tipo da Avaria'] === 'Camaras CCTV'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.smartSolutions;
      } else if (
        response &&
        response['Fornecedor'] === 'Sonoro Legal' &&
        response['Tipo da Avaria'] === 'Música Ambiente'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.sonoroLegal;
      } else if (
        response &&
        response['Fornecedor'] === 'TecniHotel' &&
        response['Tipo da Avaria'] === 'Equipamento'
      ) {
        return this.storesService.store.modules
          .equipmentMaintenanceEmailSuppliers.tecniHotel;
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'problemsLJ2') {
      return [
        'Dispensadores Higiene feminina',
        'Extintores',
        'Carretel',
        'Alarme de incêncio',
        'Alarme de intrusão',
        'Equipamentos de frio',
      ];
    } else if (responseField.selectOptionsGetter === 'suppliersLJ2') {
      if (
        response &&
        response['Tipo da Avaria'] === 'Dispensadores Higiene feminina'
      ) {
        return ['Higiaçores'];
      } else if (
        (response && response['Tipo da Avaria'] === 'Extintores') ||
        response['Tipo da Avaria'] === 'Carretel' ||
        response['Tipo da Avaria'] === 'Alarme de incêncio' ||
        response['Tipo da Avaria'] === 'Alarme de intrusão'
      ) {
        return ['Extinçor'];
      } else if (
        response &&
        response['Tipo da Avaria'] === 'Equipamentos de frio'
      ) {
        return ['ASR Santos'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (responseField.selectOptionsGetter === 'emailSuppliersLJ2') {
      if (
        response &&
        response['Fornecedor'] === 'Higiaçores' &&
        response['Tipo da Avaria'] === 'Dispensadores Higiene feminina'
      ) {
        return ['geral@higiacores.com'];
      } else if (
        (response &&
          response['Fornecedor'] === 'Extinçor' &&
          response['Tipo da Avaria'] === 'Extintores') ||
        response['Tipo da Avaria'] === 'Carretel' ||
        response['Tipo da Avaria'] === 'Alarme de incêncio' ||
        response['Tipo da Avaria'] === 'Alarme de intrusão'
      ) {
        return ['bruno.serra@extincor.pt'];
      } else if (
        response &&
        response['Fornecedor'] === 'ASR Santos' &&
        response['Tipo da Avaria'] === 'Equipamentos de frio'
      ) {
        return ['tecnicaamilcarsantos@gmail.com'];
      }
      if (response && response['Fornecedor'] === 'Outro') {
        responseField.type = 'text';
        return '';
      }
    } else if (
      responseField &&
      responseField.subfield &&
      responseField.subfield.selectOptionsGetter ===
        "response['Que secções existem?]"
    ) {
      return response['Que secções existem?'];
    }
    return responseField.selectOptions || [];
  };

  async pressAddRegister(taskType: any) {
    const type = this.customTaskTypesService.getCustomTaskType(taskType);
    if (!type) return;

    let product;
    if (type.product) {
      product = await this.getProduct(type);
      if (!product) return;
    }
    let batch;
    if (type.batch) {
      batch = await this.getBatch(product);
      if (!batch) return;
    }
    const modal = await this.modalController.create({
      component: CustomTypeChildTaskModal,
      componentProps: {
        type: this.customTaskTypesService.getCustomTaskType(taskType),
        form: this.form,
        precedingTaskResponse: this.response,
        precedingTask: this.task,
        extras: {
          ...this.extras,
          product,
          batch,
        },
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.response['childrenTasksIds']
        ? this.response['childrenTasksIds'].push(data.task.id)
        : (this.response['childrenTasksIds'] = [data.task.id]);
      this.pendingTasksAndResponses.push(data);
      if (data.task.type === 'prepackagedPDVIntermarcheShort2') {
        this.prepackageTasks.push(data);
      }
      if (data.task.type === 'unfreezingProductsShort2') {
        this.unfreezingTasks.push(data);
      }
      if (data.task.type === 'warmTemperatureControlIntermarcheShort2') {
        this.warmTemperatureControlTasks.push(data);
      }
      if (data.task.type === 'inBeefRegisterShort2') {
        this.inBeefRegisterTasks.push(data);
      }
      if (data.task.type === 'checkPestActivityExterior') {
        this.checkPestActivityExteriorTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'checkPestStructuralFlaws') {
        this.checkPestStructuralFlawsTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'checkPestActivityInterior') {
        this.checkPestActivityInteriorTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'checkPestStructuralFlawsInterior') {
        this.checkPestStructuralFlawsInteriorTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'checkPestPreventionMeasures') {
        this.checkPestPreventionMeasuresTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'globalBrandStandardsPret') {
        this.globalBrandStandardsPretTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'customerServicePret') {
        this.customerServicePretTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'informationPret') {
        this.informationPretTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
      if (data.task.type === 'globalEvaluationPret') {
        this.globalEvaluationPretTasks.push(data);
        this.prepareQuestionsAndAnswers();
      }
    }
  }

  async getProduct(type: any) {
    let includeProductsWithoutTechnicalSheet: boolean = !type.production;

    const modal = await this.modalController.create({
      component: ProductListModal,
      componentProps: {
        includeProductsWithoutTechnicalSheet,
      },
    });

    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  async getBatch(product: any) {
    const modal = await this.modalController.create({
      component: BatchListModal,
      componentProps: { product },
    });
    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  getTranslation(object: any) {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.name;
    } else if (lang === 'es') {
      return object.nameEs || object.name;
    }
    return object.name;
  }

  getPlaceholderTranslation = (object: any) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return object.placeholder;
    } else if (lang === 'es') {
      return object.placeholderEs || object.placeholder;
    }
    return object.placeholder;
  };

  async openSelectRawMaterialUsed(row: any) {
    const productListModal = await this.modalController.create({
      component: ProductListModal,
    });
    await productListModal.present();
    const product = (await productListModal.onDidDismiss()).data;
    if (!product) return;
    const batchSelectModal = await this.modalController.create({
      component: BatchSelectModal,
      componentProps: {
        product,
      },
    });
    await batchSelectModal.present();
    const batches = (await batchSelectModal.onDidDismiss()).data;
    if (!batches || batches.length === 0) return;

    row.selectedProductInfo =
      product.name +
      ' - ' +
      batches.map((b: any) => `${b.lot} - ${b.expirationDate}`).join('; ');
  }

  async pressPrint(task: Task, response: TaskResponse) {
    const modal = await this.modalController.create({
      component: PrinterPage,
      componentProps: {
        task,
        response,
        store: this.storesService.store,
        buyer:
          this.storesService.getBuyerByName(this.response['Comprador']) ||
          this.storesService.getBuyerByName(this.response['Cliente']) ||
          this.storesService.getBuyerByFullNameAndCompanyFullName(
            this.response['Destinatário']
          ),
        plot: this.storesService.getPlotByName(this.response['Parcela']),
      },
    });
    await modal.present();
  }

  pressTrackGPS() {
    this.alertService.present(
      'Em desenvolvimento',
      'No futuro poderá dar track do percurso'
    );
  }

  async pressCancel() {
    const prompt = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: 'Tem a certeza que quer cancelar a tarefa?',
        text: "Poderá desfazer o cancelamento no histórico. Na tab 'Canceladas.'",
      },
    });
    await prompt.present();
    const { data } = await prompt.onDidDismiss();
    if (data) {
      this.task.canceled = true;
      this.tasksService
        .updateTask(this.task, this.authenticationService.getAuthInfo())
        .subscribe(() => this.viewCtrl.dismiss());
    }
  }

  private getMissingRequiredFields(): ResponseField[] {
    if (
      this.type.metadata.responseFields
        .map((rf: any) => rf.name)
        .indexOf('Tarefa terminada') !== -1
    ) {
      if (!this.response['Tarefa terminada']) return [];
    }

    return this.getArrayOfMissingRequiredFields(
      this.response,
      this.type.metadata.responseFields
    );
  }

  getArrayOfMissingRequiredFields(
    response: any,
    responseFields: ResponseField[]
  ): ResponseField[] {
    return responseFields.filter((rf: ResponseField) => {
      if (rf.required && this.processCondition(rf)) {
        const responseValue = response[rf.name];

        if (rf.type === 'text' && !response[rf.name]) {
          return true;
        }
        if (rf.type === 'textarea' && !response[rf.name]) {
          return true;
        }
        if (rf.type === 'boolean' && !responseValue) {
          return true;
        }
        if (
          rf.type === 'photo' &&
          (!responseValue || responseValue.length === 0)
        ) {
          return true;
        }
        
        if (
          rf.type === 'number' &&
          (responseValue === undefined ||
            responseValue === null ||
            responseValue === '')
        ) {
          return true;
        }
        if (rf.type === 'date' && !responseValue) {
          return true;
        }
        if (rf.type === 'select') {
          if (rf.multiple) {
            if (
              !Array.isArray(responseValue) ||
              responseValue.length === 0 ||
              responseValue.every((item: string) => item === '')
            ) {
              return true;
            }
          } else {
            if (!responseValue || responseValue.length <= 0) {
              return true;
            }
          }
        }
        if (
          rf.type === 'checklist' &&
          (!responseValue || responseValue.length <= 0)
        ) {
          return true;
        }
        if (
          rf.type === 'file' &&
          (!responseValue || responseValue.length <= 0)
        ) {
          return true;
        }
      }
      if (rf.type === 'list') {
        for (let i = 0; i < response[rf.name].length; ++i) {
          const row = response[rf.name][i];
          if (!row.name) {
            return true;
          }

          if (!row.quantity) {
            return true;
          }

          if (!row.unit) {
            return true;
          }
          if (
            this.type.type!=="serunionProductionNewModel" &&
            rf.batchPhoto &&
            (!row.photos ||
              (Array.isArray(row.photos) && row.photos.length === 0))
          ) {
            return true; // Validation fails
          }
          if (
            ![
              'technicalSheetIntermarche',
              'technicalSheetIM2',
              'technicalSheetAL',
              'technicalSheetLJ',
              'serunionTechnicalSheet',
              'greenTechnicalSheet',
              'industrialManufacturingIM',
              'industrialManufacturingIM2',
              'serunionProductionNewModel',
              'greenProductionNewModel',
            ].includes(this.type.type) &&
            !row.selectedProductInfo
          ) {
            return true;
          }
        }
      }
      if (rf.type === 'group' && this.processCondition(rf)) {
        for (let group of response[rf.name]) {
          return (
            this.getArrayOfMissingRequiredFields(group, rf.subfields)?.length >
            0
          );
        }
      }

      return false;
    });
  }

  getCount(responseField: ResponseField): number {
    if (responseField.countGetter === 'numberOfEquipments') {
      if (!this.response['Quantos equipamentos vão ser utilizados?']) return 0;
      return parseInt(
        this.response['Quantos equipamentos vão ser utilizados?']
      );
    }
    return 0;
  }

  async onNewOptionAdded(newOption: string, responseField: ResponseField) {
    // Process the new option here.
    // For example, save it to the store object or make an API call to save it to a database.
    // console.log("New option added:", newOption);

    // Create missing properties
    if (!this.storesService.store.modules) {
      this.storesService.store.modules = { fieldsSelectOptions: {} };
    }
    if (!this.storesService.store.modules.fieldsSelectOptions) {
      this.storesService.store.modules.fieldsSelectOptions = {};
    }
    if (responseField && responseField.name) {
      if (
        !this.storesService.store.modules.fieldsSelectOptions[
          responseField.name
        ]
      ) {
        this.storesService.store.modules.fieldsSelectOptions[
          responseField.name
        ] = [];
      }

      // Save the new option
      this.storesService.store.modules.fieldsSelectOptions[
        responseField.name
      ].push(newOption);
    } else {
      console.error(
        'Unable to save the new option due to null/undefined values'
      );
    }
  }

  getUserAddedOptions(responseField: ResponseField): string[] {
    if (
      this.storesService &&
      this.storesService.store &&
      this.storesService.store.modules &&
      this.storesService.store.modules.fieldsSelectOptions &&
      responseField &&
      responseField.name &&
      this.storesService.store.modules.fieldsSelectOptions[responseField.name]
    ) {
      return this.storesService.store.modules.fieldsSelectOptions[
        responseField.name
      ];
    } else {
      return [];
    }
  }

  isChecked(value: any): boolean {
    return !!value;
  }

  setChecked(group: any, fieldName: string, isChecked: boolean): void {
    group[fieldName] = isChecked;
  }

  recalculateAllData() {
    this.type.metadata.responseFields.forEach((rf) => {
      if (rf.calculateValue) {
        this.response[rf.name] = FormUtils.calculateValue(
          rf.calculateValue,
          this.response[rf.name],
          this.response
        );
      }
    });
  }
}
