import {
  addDays,
  format,
  isBefore,
  isValid,
  parse,
  set,
  subDays,
} from 'date-fns';
import { FNSDateFormats, REFERENCE_DATE } from '../../utils/date.utils';

export interface Batch {
  id: string;
  lot: string;
  expirationDate?: string | null;
  isTableExpirationDate?: boolean;
  createdBy: CreatedBy;
  status: BatchStatus;
  updateReason?: {
    issue: string;
    timestamp: number;
  };
  controlledWarmTemperature?: boolean;

  batchId?: string; // Identifica lote original de sub-lotes
  preparationDate?: string;
  primaryExpirationDate?: string;
  unfrozenDate?: string | null;

  quantityTags?: number;
}

export enum BatchStatus {
  Good = 'good',
  Expiring = 'expiring',
  LastDay = 'lastDay',
  Expired = 'expired',
  NoExpirationDate = 'no-expiration-date',
  Donated = 'donated',
  Finished = 'finished',
}

export enum CreatedByMethod {
  Laps = 'laps',
  Task = 'task',
  CreateBatch = 'create_batch',
}

export type CreatedBy =
  | {
      method: CreatedByMethod.Laps;
      lapId: string;
      transportGuideId: string;
      creationDate: string;
    }
  | {
      method: CreatedByMethod.Task;
      taskId: string;
      creationDate: string;
    }
  | {
      method: CreatedByMethod.CreateBatch;
      employeeId: string;
      creationDate: string;
    };

export function calculateExpirationDate(input: string): string | null {
  const match = input.match(/^D\+?(\d*)$/);
  let daysToAdd = 0;

  if (match) {
    daysToAdd = match[1] ? parseInt(match[1], 10) : 0;
  } else {
    return null; // Return null for invalid input format
  }

  if (daysToAdd === 0) {
    return null; // Return null for 0 days (no expiration date)
  }

  const expirationDate = addDays(new Date(), daysToAdd);
  const expirationString = format(expirationDate, FNSDateFormats.YYYYMMDD_HHmm);
  return expirationString;
}

export function getExpirationDate(batch: Batch) {
  if (!batch.isTableExpirationDate) {
    return batch.expirationDate;
  }
  return '';
}

export function getBatchStatus(
  expirationDate: string | null,
  expiringWarningDays: number = 4
): BatchStatus {
  if (expirationDate) {
    // Parse the expiration date string into a Date object
    let parsedExpirationDate = parse(
      expirationDate,
      FNSDateFormats.YYYYMMDD,
      REFERENCE_DATE
    );

    // Check if the parsed date is valid
    if (!isValid(parsedExpirationDate)) {
      parsedExpirationDate = parse(
        expirationDate,
        FNSDateFormats.YYYYMMDD_HHmm,
        REFERENCE_DATE
      );
      if (!isValid(parsedExpirationDate)) {
        return BatchStatus.NoExpirationDate;
      }
    }

    // Set times for comparison dates
    const endOfYesterday = set(subDays(new Date(), 1), {
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 0,
    });

    const endOfToday = set(new Date(), {
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 0,
    });

    const endOfWarningDay = set(addDays(new Date(), expiringWarningDays), {
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 0,
    });

    // Compare the expiration date with the computed dates
    if (isBefore(parsedExpirationDate, endOfYesterday)) {
      return BatchStatus.Expired;
    } else if (isBefore(parsedExpirationDate, endOfToday)) {
      return BatchStatus.LastDay;
    } else if (isBefore(parsedExpirationDate, endOfWarningDay)) {
      return BatchStatus.Expiring;
    } else {
      return BatchStatus.Good;
    }
  } else {
    return BatchStatus.NoExpirationDate;
  }
}
