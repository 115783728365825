<ion-content>
  <ng-container *ngIf="task.type !== 'inProductApanhaAzeitonaNF' && task.type !== 'inProductApanhaPea2'">
    <ion-card>
      <ion-card-header>
        <ion-img src="assets/img/logo_torriba.jpg" slot="start" style="width: 20%;"></ion-img>
        <ion-card-title style="text-align: center;">DOCUMENTO COMPROVATIVO DO TRANSPORTE DE BENS AGRÍCOLAS</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p style="font-weight: bold; text-align: center;">
          (n.º1, alínea d) e n.º3 e 4 do art. 3.º do DL n.º 147/2003, de 11 de julho)
        </p>
        <ion-row>
          <ion-col>Guia Nº {{ response.clientDate }}</ion-col>
          <ion-col style="text-align: end;">Data: {{ response.clientDate | date: "dd/MM/yyyy" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Cópia física x{{ task.printsCount || 0 }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Produtor: {{ producer.code }}</ion-col>
          <ion-col style="text-align: end;">NIF: {{ producer.nif }}</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type !== 'inProductApanhaPea2'">
          <ion-col>N.Produtor {{ producer.producerNumber }}</ion-col>
          <ion-col style="text-align: end;">GGN: {{ producer.ggn }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Avaliação Transporte: Conforme</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Produto Certificado em GLOBAL G.A.P.</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Produto: {{ response["Produto"] }}</ion-col>
          <ion-col style="text-align: end;">Variedade: {{ response["Variedade"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Quantidade: a granel</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type !== 'inProductApanhaPimento'">
          <ion-col>Peso: {{ response["Peso"] ? response["Peso"] : "a pesar no destino" }}</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type === 'inProductApanhaMilho' || task.type === 'inProductApanhaPea2'">
          <ion-col>Tipo de produto: {{ response["Tipo de produto"] }}</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type === 'inProductApanhaPimento' || task.type === 'inProductApanhaMilho' || task.type === 'inProductApanhaPea2'">
          <ion-col>Método de colheita: {{ response["Método de colheita"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Observações: {{ response["Observações"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Parcela: {{ response["Parcela"] }}</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type !== 'inProductApanhaPimento'">
          <ion-col>Colhedora: {{ response["Colhedora"] }}</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type !== 'inProductApanhaPimento'">
          <ion-col>Operador: {{ response["Operador"] }}</ion-col>
        </ion-row>
        <ion-row *ngIf="task.type === 'inProductApanhaPimento'">
          <ion-col>Contentores: {{ response["Contentores"] }}</ion-col>
        </ion-row>
        <ng-container *ngIf="task.type === 'inProductApanhaPimento'">
          <ion-row *ngFor="let productType of response['Detalhes do produto']">
            <ion-col>Tipo de produto: {{ productType["Tipo de produto"] }} Número: {{ productType["Número"] }}</ion-col>
          </ion-row>
        </ng-container>
        <ion-row>
          <ion-col>ID do dispositivo: {{ deviceInfo.uuid || "web" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Cliente: {{ buyer.fullName }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>NIF do Cliente: {{ buyer.nif }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>País: {{ buyer.country }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Código Postal: {{ buyer.postalCode }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Morada: {{ buyer.address }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Data de carga: {{ response["Data de carga"] | date: "dd/MM/yyyy" }}</ion-col>
          <ion-col style="text-align: end;">Hora: {{ response["Data de carga"] | date: "HH:mm" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Matrícula galera: {{ response["Matrícula galera"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Local de carga: {{ plot?.parish }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Local de descarga: {{ buyer.deliveryLocation }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Transportador: {{ response["Transportador"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>CMR:</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Ass. do Transportador:______________________</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Ass. do Responsável:________________________</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="code39">{{ printTorribaService.getBarcode(response, producer, plot) }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">{{ printTorribaService.getBarcode(response, producer, plot) }}</ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ng-container>

  <ng-container *ngIf="task.type === 'inProductApanhaAzeitonaNF'">
    <ion-card>
      <ion-card-header>
        <ion-card-title style="text-align: center;">MyHARVEST</ion-card-title>
        <ion-card-subtitle style="text-align: center;">Guia de Transporte</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-row>
          <ion-col style="text-align: center;">
            <h5>{{ society?.nombre_sociedade }}</h5>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">Sede: {{ society?.direccion }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">Escritórios: {{ society?.morada_faturacao }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">{{ society?.registo_comercial }} e Contribuinte Nº {{ society?.nif }} - Capital Social {{ society?.capital_social }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Guia Nº: {{ producer.sapCode }}{{ producer.producerNumber }}{{ response["Nº Guia"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Herdade: {{ response["Herdade"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Data: {{ response.clientDate | date: "dd/MM/yyyy" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Destinatário: {{ buyer.companyFullName }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Morada: {{ buyer.address }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>NIF: {{ buyer.nif }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>De: {{ buyer.deliveryLocation }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Saída da parcela: {{ response.clientDate | date: "HH:mm" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Motorista: {{ response["Motorista"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Matrícula Transporte: {{ response["Matrícula Transporte"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Grupo de Colheita: {{ response["Grupo de Colheita"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Parcela: {{ response["Parcela"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Variedade: {{ response["Variedade"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Estado Fitossanitário: {{ response["Estado Fitossanitário"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Qualidade: {{ response["Qualidade"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Tipo de Colheita: {{ response["Tipo de Colheita"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Observações: {{ response["Observações Transporte"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Assinatura:______________________</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="code39">{{ printSovenaService.getBarcode(producer, response) }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">{{ printSovenaService.getBarcode(producer, response) }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-img [src]="sovenaQRCode" alt="QR Code"></ion-img>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="response['Pesar 100 azeitonas']">
          <ion-col>
            <ion-img src="assets/img/aceituna_pesar.jpeg"></ion-img>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ng-container>

  <ng-container *ngIf="task.type === 'inProductApanhaPea2'">
    <ion-card>
      <ion-card-header>
        <ion-card-title style="text-align: center;">MyHARVEST</ion-card-title>
        <ion-card-subtitle style="text-align: center;">Guia de Transporte</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-row>
          <ion-col style="text-align: center;">
            <h5>{{ producer.fullName }}</h5>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">
            <h6>{{ producer.groupNumber }}</h6>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Guia Nº: {{ producer.sapCode }}{{ producer.producerNumber }}{{ response["Nº Guia"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Data: {{ response.clientDate | date: "dd/MM/yyyy" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Destinatário: {{ buyer.companyFullName }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Morada: {{ buyer.address }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>NIF: {{ buyer.nif }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>De: {{ buyer.deliveryLocation }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Saída da parcela: {{ response.clientDate | date: "HH:mm" }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Motorista: {{ response["Motorista"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Matrícula Transporte: {{ response["Matrícula Transporte"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Grupo de Colheita: {{ response["Grupo de Colheita"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Variedade: {{ response["Variedade"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Estado Fitossanitário: {{ response["Estado Fitossanitário"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Qualidade: {{ response["Qualidade"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Tipo de Colheita: {{ response["Tipo de Colheita"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Observações: {{ response["Observações Transporte"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Emissor da Guia: {{ response["Emissor da Guia"] }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Assinatura:______________________</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="code39">{{ printTorribaService.getBarcode(response, producer, plot) }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="text-align: center;">{{ printTorribaService.getBarcode(response, producer, plot) }}</ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ng-container>
</ion-content>
