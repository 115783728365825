import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-reception-tag",
  template: `
    <div
      *ngIf="!nonApplicable"
      [style.width]="'200pt'"
      [style.border]="'1px solid black'"
      [style.border-radius]="'20px'"
      [style.text-align]="'center'"
      [style.margin-left]="'auto'"
      [style.margin-right]="'auto'"
      [style.background-color]="'white'"
    >
      <div
        [style.font]="'12pt Arial'"
        [style.display]="'block'"
        [style.text-transform]="'uppercase'"
      >
        {{ product.name }}
      </div>
      <div [style.font]="'12pt Arial'">
        <span [style.font]="'8pt Arial'">Receção: </span
        ><span [style.font]="'12pt Arial'" [style.font-weight]="'bold'">{{
          receptionDate | date : "yyyy-MM-dd"
        }}</span>
      </div>
    </div>
  `,
})
export class ReceptionTagComponent {
  @Input() nonApplicable: boolean = false;
  @Input() product: any = {};
  @Input() receptionDate: string = '';

  constructor() {}
}
