import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { FieldsService } from 'src/app/services/fields.service';
import { StoresService } from 'src/app/services/stores.service';
import { SyncingService } from 'src/app/services/syncing';
import { TasksService } from 'src/app/services/tasks.service';

interface ExpirationDate {
    date: string;
    status: string;
    isTreated: boolean;
  }

@Component({
  selector: 'app-expiration-product-detail-modal',
  templateUrl: 'expiration-product-detail.modal.html',
  styleUrls: ['expiration-product-detail.modal.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ExpirationProductDetailModal {
   store: any;
  product: any;
  dates: ExpirationDate[] = [];

  constructor(
    private fieldsService: FieldsService,
    private syncing: SyncingService,
    public translate: TranslateService,
    private modalController: ModalController,
    private storesService: StoresService,
    private tasksService: TasksService,
    private navParams: NavParams
  ) {
    this.product = this.navParams.data['product'];
    const nextExpirationDates: string[] = this.product.nextExpirationDates || [];
    const processedExpirationDates: string[] = this.product.processedExpirationDates || [];
    this.dates = nextExpirationDates.map((d: string) => this.mapDateToDateAndStatus(d, false))
      .concat(processedExpirationDates.map((d: string) => this.mapDateToDateAndStatus(d, true)))
      .sort((d1: ExpirationDate, d2: ExpirationDate) => {
        if (moment(d1.date).isBefore(moment(d2.date))) {
          return -1;
        } else if (moment(d2.date).isBefore(moment(d1.date))) {
          return 1;
        } else return 0;
      });
  }

  mapDateToDateAndStatus(d: any, isTreated: boolean) {
    if (moment().add(4, 'days').isBefore(moment(d))) {
      return { date: d, status: 'Ok', isTreated };
    } else if (moment().add(1, 'days').isBefore(moment(d))) {
      return { date: d, status: 'A expirar em menos de 4 dias', isTreated };
    } else if (moment().isBefore(moment(d))) {
      return { date: d, status: 'A expirar hoje', isTreated };
    } else {
      return { date: d, status: 'Expirado', isTreated };
    }
  }

  getNextExpirationDate(product: any) {
    return product.nextExpirationDates ? product.nextExpirationDates[0] : null;
  }

  async close() {
    await this.modalController.dismiss();
  }
}
