import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 *
 *
 * https://dev.to/debug_mode/the-simplest-way-to-disable-consolelog-for-production-build-in-angular-1h1f
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ConsoleToggleService {
  constructor() {}

  disableConsoleInProduction(): void {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void {};
      console.debug = function (): void {};
      console.warn = function (): void {};
      console.info = function (): void {};
      console.error = function (): void {};
    }
  }
}
