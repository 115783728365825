import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { ResponseField } from '../../../entities/tasks/task';
import { BatchDetailModal } from '../../batch/batch-detail-modal/batch-detail.modal';
import { BatchListModal } from '../../batch/batch-list-modal/batch-list.modal';
import { getExpirationDate } from 'src/app/entities/batch';

@Component({
  selector: 'app-batch-input',
  templateUrl: './batch.input.html',
})
export class BatchInputComponent {
  @Input() responseField!: ResponseField;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() product: any;
  @Input() locked: boolean = false;

  @Input() getPlaceholderTranslation: Function = (responseField: any) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.placeholder;
    } else if (lang === 'es') {
      return responseField.placeholderEs || responseField.placeholder;
    }
    return responseField.placeholder;
  };

  @Input() getTranslation: Function = (responseField: any) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.name;
    } else if (lang === 'es') {
      return responseField.nameEs || responseField.name;
    }
    return responseField.name;
  };

  constructor(
    private modalController: ModalController,
    private translator: TranslateService
  ) {}

  async openBatchDetail() {
    if (this.responseField.locked || this.locked) {
      const modal = await this.modalController.create({
        component: BatchDetailModal,
        componentProps: {
          product: this.product,
          batch: this.value,
        },
      });
      await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: BatchListModal,
        componentProps: {
          product: this.product,
          batch: this.value,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.value = data;
        this.valueChange.emit(this.value);
      }
    }
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  getValue() {
    if (!this.value) return '';

    const lot = this.value.lot || '';
    const expirationDate = getExpirationDate(this.value);

    return expirationDate ? `${lot} ${expirationDate}`.trim() : lot;
  }
}
