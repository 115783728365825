import { Component, Input, OnInit } from '@angular/core';

import { NonConformityHistoryModal } from '../nonconformity-history-modal/nonconformity.history-modal';
import { hasNonConformitiesInformation } from '../../../entities/tasks/task';
import { HistoryTaskModal } from '../history-modal/history-task-modal';
import { ZonesService } from 'src/app/services/zones.service';
import { ModalController } from '@ionic/angular';
import { EquipmentsService } from 'src/app/services/equipments.service';
import { SensorsService } from 'src/app/services/sensors.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { FieldsService } from 'src/app/services/fields.service';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'custom-history-task',
  templateUrl: 'history-task.html',
  styleUrls: ['../form-task/form-task.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HistoryTask implements OnInit {
  @Input() task: any;
  lastResponse: any;
  taskTypeObject: any;
  constructor(
    private modalCtrl: ModalController,
    public zonesService: ZonesService,
    public equipmentsService: EquipmentsService,
    public sensorsService: SensorsService,
    private customTaskTypeService: CustomTaskTypesService,
    public storesService: StoresService,
    public productsService: ProductsService,
    public fieldsService: FieldsService,
    protected envService: EnvironmentService
  ) {}

  ngOnInit() {
    const type = this.customTaskTypeService.getCustomTaskType(this.task.type);
    this.taskTypeObject = type;
    this.lastResponse = this.task.responses
      ? this.task.responses[0]
        ? this.task.responses[0]
        : {}
      : {};
  }

  async pressNo() {
    const nonConformityModal = await this.modalCtrl.create({
      component: NonConformityHistoryModal,
      componentProps: {
        task: this.task,
      },
    });
    await nonConformityModal.present();
  }

  isSingleTemperatureType(): boolean {
    const regex = new RegExp('singleTemperature');
    return regex.test(this.task.type);
  }

  async pressContent() {
    const modal = await this.modalCtrl.create({
      component: HistoryTaskModal,
      componentProps: {
        task: this.task,
        taskTypeObject: this.taskTypeObject,
      },
    });
    await modal.present();
  }

  hasNoButton() {
    return hasNonConformitiesInformation(this.lastResponse);
  }

  getReceptionFamilies(task: any, response: any) {
    const includedFamilies = [];
    if (task.type !== '2itmGoodsReceptionBasePerGuide') return '';
    if (response) {
      if (response['Lacticínios']) includedFamilies.push('Lacticínios');
      if (response['Ultra-congelados'])
        includedFamilies.push('Ultra-congelados');
      if (response['Charcutaria']) includedFamilies.push('Charcutaria');
      if (response['Aves']) includedFamilies.push('Aves');
      if (response['Peixe fresco']) includedFamilies.push('Peixe fresco');
      if (response['Frutas e legumes'])
        includedFamilies.push('Frutas e legumes');
      if (response['Mercearia']) includedFamilies.push('Mercearia');
      if (response['Carnes']) includedFamilies.push('Carnes');
      if (response['Novilho']) includedFamilies.push('Novilho');
      if (response['Queijo']) includedFamilies.push('Queijo');
      if (response['Líquida']) includedFamilies.push('Líquida');
      if (response['MG']) includedFamilies.push('MG');
      if (response['DPH']) includedFamilies.push('DPH');
      if (response['IV Gama']) includedFamilies.push('IV Gama');
      if (response['Pastelaria industrial'])
        includedFamilies.push('Pastelaria industrial');
      if (response['Bacalhau']) includedFamilies.push('Bacalhau');
      if (response['Pão']) includedFamilies.push('Pão');
      if (response['Pastelaria tradicional'])
        includedFamilies.push('Pastelaria tradicional');
      if (response['Pronto a comer / take-away'])
        includedFamilies.push('Pronto a comer / take-away');
      if (response['Consumiveis loja'])
        includedFamilies.push('Consumiveis loja');
    }
    return includedFamilies.join(', ');
  }
}
