// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import {
  CustomTaskService,
  ExecutionState,
} from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { ModalController } from '@ionic/angular';
import cuid from 'cuid';
import moment from 'moment';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { machine } from 'os';

@Injectable({
  providedIn: 'root',
})
export class FollowUpFileIntervention implements IMyTaskHandler {
  name = 'follow-up-intervention';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store.code} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data de fim de validade']).format(
          'DD/MM/YYYY HH:mm'
        );
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    // Acessa a loja atual
    const store = this.storeService.store;

    // Obtém as informações do usuário logado
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.error('User info not found');
      return of(null);
    }

    // Obtém o tipo de tarefa personalizada 'expirationRegister2'
    const typeTask = this.customTaskTypesService.getCustomTaskType(
      'demoIncidencesVerification'
    );
    if (!typeTask) {
      console.error('typeTask not found');
      return of(null);
    }

    // Cria uma nova tarefa baseada nos dados fornecidos
    const newTask = {
      id: cuid(),
      type: typeTask.type,
      formId: task.formId,
      title: this.getTitle(typeTask, data),
      subtitle: this.getSubtitle(typeTask, data, task),
      startDate: moment(data['Data']).valueOf(),
      endDate: moment().add(1, 'day').valueOf(),
      visualizationDate: moment(data['Data']).add(1, 'year').valueOf(),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: store.id,
        userIds: getTargetUser(store, userInfo),
      },
      documentsIds: [],
      isCustomType: true,
      superType: undefined,
      precedingTaskId: task.id,
      precedingTaskData: {
        category: data['Categoria do Equipamento'],
        machine: data['Selecione o Equipamento para Incidência'],
        supplier: data['Fornecedor Responsável'],
        email: data['Email Incidência para Fornecedores'],
        date: data['Data'],
        description: data['Descrição da avaria'],
        fotografia: data['Foto da avaria'],
        observations: data['Observações'],
        priority: data['Prioridade'],
      },
    };

    // Retorna a criação de uma nova tarefa
    return this.tasksService.newTask(
      newTask,
      this.authenticationService.getAuthInfo()
    );
  }
}
