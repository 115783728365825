import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Link } from '../../utils/link';
import { environment } from 'src/environments/environment';
import { TaskType } from '../entities/tasks/custom-task-type';
import { AuthenticatedHttpService } from './authenticated-http';
import { JSON_HEADERS } from 'src/utils/headers';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CustomTaskTypesService {
  private cache = new Map<string, TaskType>();
  private etag = '';

  constructor(private http: AuthenticatedHttpService, protected envService: EnvironmentService) {}

  getCustomTaskTypesFromApi(companyId: string, storeId: string): Observable<any> {
   

    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/custom-task-types`,
      {
        ...JSON_HEADERS,
        "If-None-Match": this.etag,
      }
    );
    return this.http.get(link).pipe(
      tap((response:any) => {
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const types: TaskType[] = response.body as TaskType[];
        types.forEach(t => {
          this.cache.set(t.type, t);
        });
      })
    );
  }

  getCustomTaskTypes(): TaskType[] {
    return Array.from(this.cache.values());
  }

  getCustomTaskType(type: string): TaskType | undefined {
    return this.cache.get(type);
  }

  canProceed(taskType: any, taskResponse: any): boolean {

    // Buscando o tipo da tarefa usando o serviço injetado
    const resolvedTaskType = this.getCustomTaskType(taskType);


    // Verifica se o resolvedTaskType foi encontrado
    if (!resolvedTaskType) {
      console.error(`Task type "${taskType}" não encontrado.`);
      return false; // ou um valor padrão, dependendo do que você quiser fazer
    }


    const hasMayStayPendingHandler = (resolvedTaskType.metadata?.handlers ?? []).includes('may-stay-pending');

    if (!hasMayStayPendingHandler) {
      return true;
    }

    const tarefaTerminada = taskResponse?.['Tarefa terminada'];

    if (tarefaTerminada === true) {
      return true;
    }

    return false;
  }
}
