import { format } from 'date-fns';
import moment from 'moment';

export enum MomentDateFormats {
  YYYYMMDD_HHmm = 'YYYY-MM-DD HH:mm',
  YYYYMMDD = 'YYYY-MM-DD',
}

export enum FNSDateFormats {
  YYYYMMDD_HHmm = 'yyyy-MM-dd HH:mm',
  YYYYMMDD = 'yyyy-MM-dd',
}

export const REFERENCE_DATE = (() => {
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, 0, 1, 0, 0, 0, 0);
})();

export const getPrevious4Am = (): number => {
  const now = moment();
  const today4am = moment().startOf('day').add(4, 'hours');

  if (now.isAfter(today4am)) {
    return today4am.valueOf();
  } else {
    return today4am.subtract(1, 'day').valueOf();
  }
};

export const getNext4Am = (): number => {
  const now = moment();
  const today4am = moment().startOf('day').add(4, 'hours');

  if (now.isAfter(today4am)) {
    return today4am.add(1, 'day').valueOf();
  } else {
    return today4am.valueOf();
  }
};

export const getPreviousMonth4Am = (): number => {
  const now = moment();
  const today4am = moment().startOf('day').add(4, 'hours');

  if (now.isAfter(today4am)) {
    return today4am.subtract(30, 'days').valueOf();
  } else {
    return today4am.subtract(1, 'day').subtract(30, 'days').valueOf();
  }
};

export function getDayOfTheWeek(date: Date): string {
  switch (format(date, 'eeee')) {
    case 'Monday':
      return 'SEG';
    case 'Tuesday':
      return 'TER';
    case 'Wednesday':
      return 'QUA';
    case 'Thursday':
      return 'QUI';
    case 'Friday':
      return 'SEX';
    case 'Saturday':
      return 'SAB';
    case 'Sunday':
      return 'DOM';
    default:
      return '';
  }
}

export function getDayOfTheWeekAsNumber(date: Date): string {
  switch (format(date, 'eeee')) {
    case 'Monday':
      return '2ª';
    case 'Tuesday':
      return '3ª';
    case 'Wednesday':
      return '4ª';
    case 'Thursday':
      return '5ª';
    case 'Friday':
      return '6ª';
    case 'Saturday':
      return 'Sa';
    case 'Sunday':
      return 'D';
  }
  return '-';
}
