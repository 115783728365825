import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of, from, forkJoin } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { FieldType } from 'src/app/entities/field';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { mergeMap, toArray } from 'rxjs/operators';

// Include your helper functions here
function getListTasksIMQuizTaskTypeFromSectionName(sectionForm: any) {
  if (sectionForm.title === 'Armazém') {
    return 'listPeriodicCleaningTasksArmazemIMQuiz';
  }
  if (sectionForm.title === 'Frescos LS') {
    return 'listPeriodicCleaningTasksFrescosLSIMQuiz';
  }
  if (sectionForm.title === 'Talho') {
    return 'listPeriodicCleaningTasksTalhoIMQuiz';
  }
  if (sectionForm.title === 'Peixaria') {
    return 'listPeriodicCleaningTasksPeixariaIMQuiz';
  }
  if (sectionForm.title === 'Charcutaria e queijo') {
    return 'listPeriodicCleaningTasksCharcutariaEQueijoIMQuiz';
  }
  if (sectionForm.title === 'Frutas e legumes') {
    return 'listPeriodicCleaningTasksFrutasELegumesIMQuiz';
  }
  if (sectionForm.title === 'Pronto a comer') {
    return 'listPeriodicCleaningTasksProntoAComerIMQuiz';
  }
  if (sectionForm.title === 'Bar e cafetaria') {
    return 'listPeriodicCleaningTasksBarECafetariaIMQuiz';
  }
  if (sectionForm.title === 'Padaria') {
    return 'listPeriodicCleaningTasksPadariaIMQuiz';
  }
  return undefined;
}

function getMachineListIMQuizTaskTypeFromSectionName(sectionForm: any) {
  if (sectionForm.title === 'Talho') {
    return 'machineListTalhoIMQuiz';
  }
  if (sectionForm.title === 'Peixaria') {
    return 'machineListPeixariaIMQuiz';
  }
  if (sectionForm.title === 'Charcutaria e queijo') {
    return 'machineListCharcutariaEQueijoIMQuiz';
  }
  if (sectionForm.title === 'Frutas e legumes') {
    return 'machineListFrutasELegumesIMQuiz';
  }
  if (sectionForm.title === 'Pronto a comer') {
    return 'machineListProntoAComerIMQuiz';
  }
  if (sectionForm.title === 'Bar e cafetaria') {
    return 'machineListBarECafetariaIMQuiz';
  }
  if (sectionForm.title === 'Padaria') {
    return 'machineListPadariaIMQuiz';
  }
  return undefined;
}

function getDailyCleaningListIMQuizTaskTypeFromSectionName(sectionForm: any) {
  if (sectionForm.title === 'Talho') {
    return 'dailyCleaningListTalhoIMQuiz';
  }
  if (sectionForm.title === 'Peixaria') {
    return 'dailyCleaningListPeixariaIMQuiz';
  }
  if (sectionForm.title === 'Charcutaria e queijo') {
    return 'dailyCleaningListCharcutariaEQueijoIMQuiz';
  }
  if (sectionForm.title === 'Frutas e legumes') {
    return 'dailyCleaningListFrutasELegumesIMQuiz';
  }
  if (sectionForm.title === 'Pronto a comer') {
    return 'dailyCleaningListProntoAComerIMQuiz';
  }
  if (sectionForm.title === 'Bar e cafetaria') {
    return 'dailyCleaningListBarECafetariaIMQuiz';
  }
  if (sectionForm.title === 'Padaria') {
    return 'dailyCleaningListPadariaIMQuiz';
  }
  return undefined;
}

@Injectable({
  providedIn: 'root',
})
export class CreateSectionsTasks implements IMyTaskHandler {
  name = 'create-sections-tasks';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const existingSections = new Set();

    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }

    const sections = data['Que secções existem?'];

    if (!sections || !Array.isArray(sections)) {
      console.log('No sections found in data');
      return of(null);
    }

    // Collect all section processing Observables
    const sectionObservables = sections.map((s: any) => {
      if (existingSections.has(s)) {
        // Skip if the section already exists
        return of(null);
      }
      existingSections.add(s);

      // Create the section form
      const sectionForm = {
        id: cuid(),
        title: s,
        type: FieldType.Form,
        storeId: this.storeService.store.id,
      };

      // Create new field
      return this.fieldsService
        .newField(sectionForm, this.authenticationService.getAuthInfo())
        .pipe(
          mergeMap(() => {
            const taskObservables = [];

            // List Tasks IM Quiz
            const listTasksIMQuizTypeString = getListTasksIMQuizTaskTypeFromSectionName(sectionForm);
            if (listTasksIMQuizTypeString) {
              const listTasksIMQuiz = this.customTaskTypesService.getCustomTaskType(listTasksIMQuizTypeString);

              if (listTasksIMQuiz) {
                const newTask = this.createNewTask(
                  listTasksIMQuiz,
                  sectionForm,
                  task,
                  userInfo
                );
                taskObservables.push(newTask);
              } else {
                console.log('listTasksIMQuiz info not found');
              }
            }

            // Machine List IM Quiz
            const machineListIMQuizTypeString = getMachineListIMQuizTaskTypeFromSectionName(sectionForm);
            if (machineListIMQuizTypeString) {
              const machineListIMQuizType = this.customTaskTypesService.getCustomTaskType(machineListIMQuizTypeString);

              if (machineListIMQuizType) {
                const newTask = this.createNewTask(
                  machineListIMQuizType,
                  sectionForm,
                  task,
                  userInfo
                );
                taskObservables.push(newTask);
              } else {
                console.log('machineListIMQuizType info not found');
              }
            }

            // Daily Cleaning List IM Quiz
            const dailyCleaningListIMQuizTypeString = getDailyCleaningListIMQuizTaskTypeFromSectionName(sectionForm);
            if (dailyCleaningListIMQuizTypeString) {
              const dailyCleaningListIMQuizType = this.customTaskTypesService.getCustomTaskType(dailyCleaningListIMQuizTypeString);

              if (dailyCleaningListIMQuizType) {
                const newTask = this.createNewTask(
                  dailyCleaningListIMQuizType,
                  sectionForm,
                  task,
                  userInfo
                );
                taskObservables.push(newTask);
              } else {
                console.log('dailyCleaningListIMQuizType info not found');
              }
            }

            // Cold Machine List IM Quiz
            const coldMachineListIMQuiz = this.customTaskTypesService.getCustomTaskType('coldMachineListIMQuiz');
            if (coldMachineListIMQuiz) {
              const newTask = this.createNewTask(
                coldMachineListIMQuiz,
                sectionForm,
                task,
                userInfo,
                s // Pass section title if needed
              );
              taskObservables.push(newTask);
            } else {
              console.log('coldMachineListIMQuiz info not found');
            }

            // Section Information IM Quiz
            const sectionInformationIMQuiz = this.customTaskTypesService.getCustomTaskType('sectionInformationIMQuiz');
            if (sectionInformationIMQuiz) {
              const newTask = this.createNewTask(
                sectionInformationIMQuiz,
                sectionForm,
                task,
                userInfo,
                s // Pass section title if needed
              );
              taskObservables.push(newTask);
            } else {
              console.log('sectionInformationIMQuiz info not found');
            }

            // Return all task creation Observables
            if (taskObservables.length > 0) {
              return forkJoin(taskObservables);
            } else {
              return of(null);
            }
          })
        );
    });

    // Execute all section Observables in parallel
    return forkJoin(sectionObservables);
  }

  private createNewTask(
    taskType: any,
    sectionForm: any,
    task: Task,
    userInfo: any,
    sectionTitle?: string
  ): Observable<any> {
    const newTaskData = {
      id: cuid(),
      type: taskType.type,
      formId: sectionForm.id,
      title: taskType.name + (sectionTitle ? ' ' + sectionTitle : ''),
      startDate: task.startDate,
      endDate: task.endDate,
      visualizationDate: task.visualizationDate,
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: sectionForm.storeId,
        userIds: getTargetUser(this.storeService.store, userInfo),
      },
      documentsIds: [],
      isCustomType: true,
    };
    return this.tasksService.newTask(newTaskData, this.authenticationService.getAuthInfo());
  }
}
