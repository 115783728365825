import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-summary-modal',
  templateUrl: './summary.modal.html',
  styleUrls: ['./summary.modal.scss'],
})
export class SummaryModalComponent {
  @Input() data: any;

  constructor(private modalController: ModalController) {
    console.log(this.data);
  }

  close() {
    this.modalController.dismiss();
  }
}
