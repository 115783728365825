import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-list-input',
  templateUrl: 'list.input.html'
})
export class ListInputComponent implements OnInit, OnChanges {
  @Input() responseField: any;
  @Input() count: number = 0;
  @Input() value: any[] = [];
  @Output() valueChange = new EventEmitter<any[]>();
  @Input() getSelectOptions!: Function;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.adjustValueLength();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['count'] || changes['value']) {
      this.adjustValueLength();
    }
  }

  private adjustValueLength(): void {
    while (this.value.length > this.count) {
      this.value.pop();
    }
    while (this.value.length < this.count) {
      this.value.push([]);
    }
    this.valueChange.emit(this.value);
  }
}
