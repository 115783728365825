import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  templateUrl: 'filtered-tasks.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FilteredTasksModal {
  filterType: string;
  filterValue: any;
  tasks: any[] = [];

  constructor(
    private navParams: NavParams,
    private tasksService: TasksService,
    private modalCtrl: ModalController
  ) {
    this.filterType = navParams.get('filterType');
    this.filterValue = navParams.get('filterValue');
  }

  init() {
    if (this.filterType && this.filterValue !== undefined && this.filterValue !== null) {
      this.tasks = this.tasksService.getFilteredTasks(this.filterType, this.filterValue);
    }
  }

  ionViewWillEnter() {
    this.init();
  }

  onTaskClosedSuccessfully = (task: any) => {
    const index = this.tasks.indexOf(task);
    if (index > -1) {
      this.tasks.splice(index, 1);
    }
    this.init();
  };

  close() {
    this.modalCtrl.dismiss();
  }
}
