import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-button',
  templateUrl: 'field-button.component.html',
  styleUrl: 'field-button.component.scss',
})
export class FieldButtonComponent {
  @Input() title: string = '';
  @Input() buttonStyle?: {
    backgroundColor?: string;
    borderColor?: string;
    imageUrl?: string;
  };
  @Input() noTasksCounter = false;
  @Input() tasksCount = 0;
  @Input() isAnyTaskLate = false;
  constructor() {}
}
