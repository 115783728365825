<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader task">
      <div class="modalTitle taskTitle">
        <h3 class="modalTitleText">{{task.title}}</h3>
        <h2 class="modalSubtitleText" *ngIf="task.subtitle">
          {{task.subtitle}}
        </h2>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <ng-container
          *ngFor="let responseField of taskTypeObject?.metadata?.responseFields; index as i"
        >
          <ng-container *ngIf="processCondition(responseField, lastResponse)">
            <product-input
              *ngIf="responseField.type === 'product'"
              [value]="lastResponse[responseField.name]"
              [responseField]="responseField"
            ></product-input>
            <app-batch-input
              *ngIf="responseField.type === 'batch'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [product]="extras.product"
              [locked]="true"
            ></app-batch-input>
            <app-file-input
              *ngIf="responseField.type === 'file'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [readonly]="true"
            ></app-file-input>
            <app-previsao-input
              *ngIf="responseField.type === 'previsao'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [readonly]="true"
            ></app-previsao-input>
            <boolean-input
              *ngIf="responseField.type === 'boolean'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [locked]="true"
            ></boolean-input>
            <date-input
              *ngIf="responseField.type === 'date'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [locked]="true"
            ></date-input>
            <datetime-input
              style="padding: 1px"
              *ngIf="responseField.type === 'datetime'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [locked]="true"
            ></datetime-input>
            <time-input
              *ngIf="responseField.type === 'time'"
              [responseField]="responseField"
              [(value)]="lastResponse[responseField.name]"
              [locked]="true"
            ></time-input>
            <div
              class="form-group"
              *ngIf="responseField.type !== 'boolean' && responseField.type !== 'datetime' && responseField.type !== 'date' && responseField.type !== 'time' && responseField.type !== 'product' && responseField.type !== 'batch' && responseField.type !== 'divider' && responseField.type !== 'group' && responseField.type !== 'file' && responseField.type !== 'previsao' && !responseField.isHiding"
            >
              <label
                >{{getTranslation(responseField)}} {{responseField.required ?
                '*' : ''}}</label
              >
              <input
                readonly
                *ngIf="responseField.type === 'number'"
                class="form-control"
                type="number"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="lastResponse[responseField.name]"
              />
              <select *ngIf="responseField.units" class="form-control">
                <option
                  *ngFor="let unit of responseField.units"
                  [ngValue]="unit"
                >
                  {{unit}}
                </option>
              </select>
              <textarea
                readonly
                *ngIf="responseField.type === 'textarea'"
                class="form-control"
                rows="5"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="lastResponse[responseField.name]"
              ></textarea>
              <input
                readonly
                *ngIf="responseField.type === 'text'"
                class="form-control"
                type="text"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="lastResponse[responseField.name]"
              />
              <img
                *ngIf="responseField.type==='photo'"
                src="assets/img/camera.svg"
                (click)="pressCamera(lastResponse, responseField)"
              />

              <ion-badge *ngIf="responseField.type === 'photo'"
                >{{lastResponse[responseField.name]?.length}}</ion-badge
              >
              <input
                readonly
                *ngIf="responseField.type === 'select'"
                class="form-control"
                type="text"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="lastResponse[responseField.name]"
              />
              <ng-container
                *ngIf="responseField.type === 'list' && responseField.name === 'Lista de ingredientes'"
              >
                <ion-row>
                  <ion-col size="6">Ingrediente</ion-col>
                  <ion-col
                    size="6"
                    *ngIf="taskTypeObject?.type !== 'productionIM2' && 
                          taskTypeObject?.type !== 'productionLJ' && 
                          taskTypeObject?.type !== 'industrialManufacturingIM' && 
                          taskTypeObject?.type !== 'serunionProductionNewModel' && 
                          taskTypeObject?.type !== 'greenProductionNewModel'"
                  >
                    Quantidade (total)
                  </ion-col>
                  <ion-col
                    size="6"
                    *ngIf="taskTypeObject?.type !== 'productionIM2' && 
                    taskTypeObject?.type !== 'productionLJ' && 
                    taskTypeObject?.type !== 'industrialManufacturingIM' && 
                    taskTypeObject?.type !== 'serunionProductionNewModel' && 
                    taskTypeObject?.type !== 'greenProductionNewModel'"
                  >
                    >Lote e validade</ion-col
                  >
                </ion-row>
                <ion-row
                  *ngFor="let row of lastResponse[responseField.name]; let i = index"
                >
                  <ion-col
                    size="12"
                    *ngIf="taskTypeObject?.type === 'industrialManufacturingIM'"
                  >
                    <input
                      class="form-control"
                      [(ngModel)]="row.name"
                      type="text"
                      [readonly]="taskTypeObject?.type === 'productionIM2' || taskTypeObject?.type === 'productionLJ' || taskTypeObject?.type==='serunionProductionNewModel' || taskTypeObject?.type==='greenProductionNewModel'"
                    />
                  </ion-col>
                  <ion-col
                    size="6"
                    *ngIf="taskTypeObject?.type !== 'industrialManufacturingIM'"
                  >
                    <input
                      class="form-control"
                      [(ngModel)]="row.name"
                      type="text"
                      [readonly]="taskTypeObject?.type === 'productionIM2' || taskTypeObject?.type === 'productionLJ' || taskTypeObject?.type==='serunionProductionNewModel' || taskTypeObject?.type==='greenProductionNewModel'"
                    />
                  </ion-col>
                  <ion-col
                    size="3"
                    *ngIf="taskTypeObject?.type !== 'productionIM2' && 
                    taskTypeObject?.type !== 'productionLJ' && 
                    taskTypeObject?.type !== 'industrialManufacturingIM' && 
                    taskTypeObject?.type !== 'serunionProductionNewModel' && 
                    taskTypeObject?.type !== 'greenProductionNewModel'"
                  >
                    >
                    <input
                      class="form-control"
                      [(ngModel)]="row.quantity"
                      type="number"
                    />
                  </ion-col>
                  <ion-col
                    size="3"
                    *ngIf="taskTypeObject?.type !== 'productionIM2' && 
                    taskTypeObject?.type !== 'productionLJ' && 
                    taskTypeObject?.type !== 'industrialManufacturingIM' && 
                    taskTypeObject?.type !== 'serunionProductionNewModel' && 
                    taskTypeObject?.type !== 'greenProductionNewModel'"
                  >
                    >
                    <select [(ngModel)]="row.unit" class="form-control">
                      <option [ngValue]="'kg'">kg</option>
                      <option [ngValue]="'Unidades'">Unidades</option>
                      <option [ngValue]="'l'">l</option>
                      <option [ngValue]="'g'">g</option>
                    </select>
                  </ion-col>
                  <ion-col
                    size="6"
                    *ngIf="taskTypeObject?.type === 'productionIM2' || taskTypeObject?.type === 'productionLJ' "
                  >
                    <input
                      class="form-control"
                      type="text"
                      readonly
                      [(ngModel)]="row.selectedProductInfo"
                    />
                  </ion-col>
                  <ion-col
                    size="6"
                    *ngIf="taskTypeObject?.type === 'serunionProductionNewModel' || taskTypeObject?.type==='greenProductionNewModel'"
                  >
                    <img
                      src="assets/img/camera.svg"
                      (click)="pressListCamera(row, 'photos')"
                    />
                    <ion-badge>{{row?.photos?.length || 0}}</ion-badge>
                  </ion-col>
                </ion-row>
              </ng-container>
              <ng-container *ngIf="responseField.type === 'checklist'">
                <ion-item>
                  <ion-select
                    style="width: 100%; max-width: 100%"
                    [multiple]="true"
                    [(ngModel)]="lastResponse[responseField.name]"
                  >
                    <ion-select-option
                      *ngFor="let selectOption of responseField.checklistOptions"
                      [value]="selectOption"
                    >
                      {{selectOption}}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ng-container>
            </div>
            <div
              *ngIf="taskTypeObject?.type === 'printTagsIber' && responseField.type === 'number'"
              class="form-group"
            >
              <label>Quantidade a imprimir</label>
              <input
                class="form-control"
                type="number"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="quantityTags"
              />
            </div>
            <ng-container
              *ngIf="responseField.type === 'addRegister' && responseField.taskType === 'prepackagedPDVIntermarcheShort2'"
            >
              <ion-item>
                <ion-grid>
                  <ion-row>
                    <ion-col size="6">Quantidade</ion-col>
                    <ion-col size="6">Data</ion-col>
                  </ion-row>
                  <ion-row *ngIf="prepackageTasks.length === 0">
                    <ion-col
                      >Sem registos de embalamento para este lote.</ion-col
                    >
                  </ion-row>
                  <ion-row *ngFor="let t of prepackageTasks">
                    <ion-col
                      >{{t.response['Quantidade Pré-embaladas']}}</ion-col
                    >
                    <ion-col
                      >{{t.response['Data'] | date: 'yyyy-MM-dd'}}</ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ng-container>
            <ng-container
              *ngIf="responseField.type === 'addRegister' && responseField.taskType === 'unfreezingProductsShort2'"
            >
              <ion-item>
                <ion-grid>
                  <ion-row>
                    <ion-col size="4">Temperatura Início</ion-col>
                    <ion-col size="4">Quantidade</ion-col>
                    <ion-col size="4">Data</ion-col>
                  </ion-row>
                  <ion-row *ngIf="unfreezingTasks.length === 0">
                    <ion-col
                      >Sem registos de descongelação para este lote.</ion-col
                    >
                  </ion-row>
                  <ion-row *ngFor="let t of unfreezingTasks">
                    <ion-col>{{t.response['Temperatura Início']}}</ion-col>
                    <ion-col>{{t.response['Quantidade']}}</ion-col>
                    <ion-col
                      >{{t.response['Início de Descongelação'] | date:
                      'yyyy-MM-dd'}}</ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ng-container>
            <ng-container
              *ngIf="responseField.type === 'addRegister' && responseField.taskType === 'warmTemperatureControlIntermarcheShort2'"
            >
              <ion-item>
                <ion-grid>
                  <ion-row>
                    <ion-col>Quantidade</ion-col>
                    <ion-col>Fim de confeção</ion-col>
                  </ion-row>
                  <ion-row *ngIf="warmTemperatureControlTasks.length === 0">
                    <ion-col
                      >Sem registos de controlo de temperatura para este
                      lote.</ion-col
                    >
                  </ion-row>
                  <ion-row *ngFor="let t of warmTemperatureControlTasks">
                    <ion-col>{{t.response['Quantidade']}}</ion-col>
                    <ion-col
                      >{{t.response['Fim de confeção'] | date:
                      "yyyy-MM-dd"}}</ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ng-container>
            <ng-container
              *ngIf="responseField.type === 'addRegister' && responseField.taskType === 'inBeefRegisterShort2'"
            >
              <ion-item>
                <ion-grid>
                  <ion-row>
                    <ion-col size="6">Designação</ion-col>
                    <ion-col size="6">Nº Identificação/Cód. Referência</ion-col>
                  </ion-row>
                  <ion-row *ngIf="inBeefRegisterTasks.length === 0">
                    <ion-col>Sem registos de entrada de bovino.</ion-col>
                  </ion-row>
                  <ion-row *ngFor="let t of inBeefRegisterTasks">
                    <ion-col>{{t.response['Produto'].name}}</ion-col>
                    <ion-col>{{t.response['Lote'].lot}}</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ng-container>
            <span *ngIf="responseField.type==='addPest'">
              <img
                src="assets/img/pest_control.svg"
              />
              <!-- Seção para checkPestActivityExterior -->
              <ion-item
                *ngIf="responseField.type === 'addPest' && responseField.taskType === 'checkPestActivityExterior'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersExterior.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersExterior"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkPestStructuralFlaws -->
              <ion-item
                *ngIf="responseField.type === 'addPest' && responseField.taskType === 'checkPestStructuralFlaws'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersStructuralFlaws.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersStructuralFlaws"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkPestActivityInterior -->
              <ion-item
                *ngIf="responseField.type === 'addPest' && responseField.taskType === 'checkPestActivityInterior'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersInterior.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersInterior"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkPestStructuralFlawsInterior -->
              <ion-item
                *ngIf="responseField.type === 'addPest' && responseField.taskType === 'checkPestStructuralFlawsInterior'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersStructuralFlawsInterior.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersStructuralFlawsInterior"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkPestPreventionMeasures -->
              <ion-item
                *ngIf="responseField.type === 'addPest' && responseField.taskType === 'checkPestPreventionMeasures'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersPestPreventionMeasures.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersPestPreventionMeasures"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>
            </span>
            <span *ngIf="responseField.type==='checkMultibrand'">
              <img
                *ngIf="responseField.taskType === 'checkMandatorySigns'"
                src="assets/img/sinaléticas_obrigatórias_cores.svg"
              />
              <img
                *ngIf="responseField.taskType === 'checkEquipment'"
                src="assets/img/equipamentos_cores.svg"
              />
              <img
                *ngIf="responseField.taskType === 'checkCleaning'"
                src="assets/img/limpezas_cores.svg"
              />
              <img
                *ngIf="responseField.taskType === 'checkRefrigerated'"
                src="assets/img/refrigerados_cores.svg"
              />
              <!-- Seção para checkMandatorySigns -->
              <ion-item
                *ngIf="responseField.type === 'checkMultibrand' && responseField.taskType === 'checkMandatorySigns'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersExterior.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersExterior"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkEquipment -->
              <ion-item
                *ngIf="responseField.type === 'checkMultibrand' && responseField.taskType === 'checkEquipment'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersStructuralFlaws.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersStructuralFlaws"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkCleaning -->
              <ion-item
                *ngIf="responseField.type === 'checkMultibrand' && responseField.taskType === 'checkCleaning'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersInterior.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersInterior"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <!-- Seção para checkRefrigerated -->
              <ion-item
                *ngIf="responseField.type === 'checkMultibrand' && responseField.taskType === 'checkRefrigerated'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersStructuralFlawsInterior.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersStructuralFlawsInterior"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>
            </span>
            <span *ngIf="responseField.type==='addPret'">
              <img
                src="assets/img/task_expand_icon.svg"
              />

              <!-- Seção para bestPret -->
              <ion-item
                *ngIf="responseField.type === 'addPret' && responseField.taskType === 'globalBrandStandardsPret'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersGlobalBrandStandardsPretTasks.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersGlobalBrandStandardsPretTasks"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <ion-item
                *ngIf="responseField.type === 'addPret' && responseField.taskType === 'customerServicePret'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersCustomerServicePretTasks.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersCustomerServicePretTasks"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <ion-item
                *ngIf="responseField.type === 'addPret' && responseField.taskType === 'informationPret'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersInformationPretTasks.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersInformationPretTasks"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>

              <ion-item
                *ngIf="responseField.type === 'addPret' && responseField.taskType === 'globalEvaluationPret'"
              >
                <style>
                  .header-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    background-color: #d9edf7;
                    align-items: center;
                    border-bottom: 2px solid #ccc;
                    padding: 10px 0;
                  }

                  .header-col {
                    font-weight: bold;
                    text-align: center;
                  }

                  .grid-container {
                    display: grid;
                    grid-template-columns: 70% 30%;
                    width: 100%;
                    gap: 0;
                    align-items: start;
                    border-collapse: collapse;
                  }

                  .data-row,
                  .no-records {
                    display: contents;
                  }

                  .data-col {
                    padding: 10px;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    vertical-align: top;
                  }

                  .question-col {
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  }

                  .answer-col {
                    text-align: center;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    position: sticky;
                    right: 0;
                    background: white;
                  }

                  .no-records .data-col {
                    text-align: center;
                    border-bottom: none;
                  }

                  @media (max-width: 767px) {
                    .header-container {
                      grid-template-columns: 1fr;
                    }

                    .grid-container {
                      grid-template-columns: 1fr;
                    }

                    .header-col,
                    .data-col {
                      text-align: left;
                    }

                    .answer-col {
                      position: static;
                      background: none;
                    }
                  }
                </style>
                <ion-grid>
                  <div class="header-container">
                    <div class="header-col">Pergunta</div>
                    <div class="header-col">Validação</div>
                  </div>
                  <div class="grid-container">
                    <div
                      *ngIf="questionsAndAnswersGlobalEvaluationPretTasks.length === 0"
                      class="no-records"
                    >
                      <div class="data-col question-col" colspan="2">
                        Sem registos.
                      </div>
                    </div>
                    <ng-container
                      *ngFor="let item of questionsAndAnswersGlobalEvaluationPretTasks"
                    >
                      <div class="data-row">
                        <div class="data-col question-col">
                          {{ item.question }}
                        </div>
                        <div class="data-col answer-col">{{ item.answer }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ion-grid>
              </ion-item>
            </span>
            <div *ngIf="responseField.type === 'trackGPS'">
              <ion-icon
                name="navigate-outline"
                (click)="pressTrackGPS()"
              ></ion-icon>
            </div>
            <app-divider-input
              *ngIf="responseField.type === 'divider'"
              (click)="
              toggleHideGroupFields(responseField, i, taskTypeObject.metadata.responseFields)
            "
              [responseField]="responseField"
            ></app-divider-input>
            <ng-container
              *ngIf="responseField.type === 'group' && !responseField.isHiding"
            >
              <div class="modalTextSection">
                <h4 class="justification-modal-header-text">
                  <!-- {{getTranslation(responseField)}} -->
                </h4>
              </div>
              <ng-container
                *ngFor="let group of lastResponse[responseField.name]"
              >
                <ng-container
                  *ngFor="let sf of responseField.subfields; index as i"
                >
                  <ng-container *ngIf="processCondition(sf, group)">
                    <div
                      class="form-group"
                      *ngIf="sf.type !== 'divider' && sf.type !== 'group' && !sf.isHiding"
                    >
                      <label
                        >{{getTranslation(sf)}} {{sf.required ? '*' :
                        ''}}</label
                      >
                      <input
                        *ngIf="sf.type === 'number'"
                        class="form-control"
                        type="number"
                        [placeholder]="getPlaceholderTranslation(sf) || ''"
                        [(ngModel)]="group[sf.name]"
                      />
                      <select *ngIf="sf.units" class="form-control">
                        <option *ngFor="let unit of sf.units" [ngValue]="unit">
                          {{unit}}
                        </option>
                      </select>
                      <ion-checkbox
                        *ngIf="sf.type === 'boolean'"
                        [(ngModel)]="group[sf.name]"
                      ></ion-checkbox>
                      <textarea
                        *ngIf="sf.type === 'textarea'"
                        class="form-control"
                        rows="5"
                        [placeholder]="getPlaceholderTranslation(sf) || ''"
                        [(ngModel)]="group[sf.name]"
                      ></textarea>
                      <input
                        *ngIf="sf.type === 'text'"
                        class="form-control"
                        type="text"
                        [placeholder]="getPlaceholderTranslation(sf) || ''"
                        [(ngModel)]="group[sf.name]"
                      />
                      <input
                        *ngIf="sf.type === 'date'"
                        class="form-control"
                        type="datetime-local"
                        [placeholder]="getPlaceholderTranslation(sf) || ''"
                        [(ngModel)]="group[sf.name]"
                      />
                      <img
                        *ngIf="sf.type==='photo'"
                        src="assets/img/camera.svg"
                        (click)="pressCamera(group, sf)"
                      />
                      <ion-badge *ngIf="sf.type === 'photo'"
                        >{{group[sf.name]?.length}}</ion-badge
                      >
                      <select
                        *ngIf="sf.type === 'select'"
                        class="form-control"
                        [(ngModel)]="group[sf.name]"
                      >
                        <option
                          *ngFor="let selectOption of sf.selectOptions"
                          [ngValue]="selectOption"
                        >
                          {{selectOption}}
                        </option>
                      </select>
                      <ng-container
                        *ngIf="sf.type === 'list' && sf.name === 'Lista de ingredientes'"
                      >
                        <ion-row>
                          <ion-col size="6">Ingrediente</ion-col>
                          <ion-col size="6">Quantidade (total)</ion-col>
                        </ion-row>
                        <ion-row
                          *ngFor="let row of group[sf.name]; let i = index"
                        >
                          <ion-col size="6">
                            <input
                              class="form-control"
                              [(ngModel)]="row.name"
                              type="text"
                            />
                          </ion-col>
                          <ion-col size="3">
                            <input
                              class="form-control"
                              [(ngModel)]="row.quantity"
                              type="number"
                            />
                          </ion-col>
                          <ion-col size="2">
                            <select class="form-control" [(ngModel)]="row.unit">
                              <option [ngValue]="'kg'">kg</option>
                              <option [ngValue]="'g'">g</option>
                              <option [ngValue]="'Unidades'">Unidades</option>
                              <option [ngValue]="'l'">l</option>
                            </select>
                          </ion-col>
                          <ion-col size="1"></ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col size="8"></ion-col>
                          <ion-col></ion-col>
                        </ion-row>
                      </ng-container>
                      <ng-container *ngIf="sf.type === 'checklist'">
                        <ion-item>
                          <ion-select
                            style="width: 100%; max-width: 100%"
                            [multiple]="true"
                            [(ngModel)]="group[sf.name]"
                          >
                            <ion-select-option
                              *ngFor="let selectOption of sf.checklistOptions"
                              [value]="selectOption"
                            >
                              {{selectOption}}
                            </ion-select-option>
                          </ion-select>
                        </ion-item>
                      </ng-container>
                    </div>

                    <app-divider-input
                      *ngIf="sf.type === 'divider'"
                      (click)="
            toggleHideGroupFields(sf, i, responseField.subfields)
          "
                      [responseField]="sf"
                    ></app-divider-input>
                    <ng-container
                      *ngIf="sf.type === 'group' && !sf.isHiding && processCondition(sf, group)"
                    >
                      <div class="modalTextSection">
                        <h4 class="justification-modal-header-text">
                          <!-- {{getTranslation(sf)}} -->
                        </h4>
                      </div>
                      <ng-container *ngFor="let group2 of group[sf.name]">
                        <ng-container
                          *ngFor="let sf2 of sf.subfields; index as i"
                        >
                          <ng-container *ngIf="processCondition(sf2, group2)">
                            <div
                              class="form-group"
                              *ngIf="sf2.type !== 'divider' && !sf2.isHiding"
                            >
                              <label
                                >{{getTranslation(sf2)}} {{sf2.required ? '*' :
                                ''}}</label
                              >
                              <input
                                *ngIf="sf2.type === 'number'"
                                class="form-control"
                                type="number"
                                [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                [(ngModel)]="group2[sf2.name]"
                              />
                              <select *ngIf="sf2.units" class="form-control">
                                <option
                                  *ngFor="let unit of sf2.units"
                                  [ngValue]="unit"
                                >
                                  {{unit}}
                                </option>
                              </select>
                              <ion-checkbox
                                *ngIf="sf2.type === 'boolean'"
                                [(ngModel)]="group2[sf2.name]"
                              ></ion-checkbox>
                              <textarea
                                *ngIf="sf2.type === 'textarea'"
                                class="form-control"
                                rows="5"
                                [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                [(ngModel)]="group2[sf2.name]"
                              ></textarea>
                              <input
                                *ngIf="sf2.type === 'text'"
                                class="form-control"
                                type="text"
                                [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                [(ngModel)]="group2[sf2.name]"
                              />
                              <input
                                *ngIf="sf2.type === 'date'"
                                class="form-control"
                                type="datetime-local"
                                [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                [(ngModel)]="group2[sf2.name]"
                              />
                              <img
                                *ngIf="sf2.type==='photo'"
                                src="assets/img/camera.svg"
                                (click)="pressCamera(group2, sf2)"
                              />
                              <ion-badge *ngIf="sf2.type === 'photo'"
                                >{{group2[sf2.name]?.length}}</ion-badge
                              >
                              <select
                                *ngIf="sf2.type === 'select'"
                                class="form-control"
                                [(ngModel)]="group2[sf2.name]"
                              >
                                <option
                                  *ngFor="let selectOption of sf2.selectOptions"
                                  [ngValue]="selectOption"
                                >
                                  {{selectOption}}
                                </option>
                              </select>
                              <ng-container
                                *ngIf="sf2.type === 'list' && sf2.name === 'Lista de ingredientes'"
                              >
                                <ion-row>
                                  <ion-col size="6">Ingrediente</ion-col>
                                  <ion-col size="6">Quantidade (total)</ion-col>
                                </ion-row>
                                <ion-row
                                  *ngFor="let row of group2[sf2.name]; let i = index"
                                >
                                  <ion-col size="6">
                                    <input
                                      class="form-control"
                                      [(ngModel)]="row.name"
                                      type="text"
                                    />
                                  </ion-col>
                                  <ion-col size="3">
                                    <input
                                      class="form-control"
                                      [(ngModel)]="row.quantity"
                                      type="number"
                                    />
                                  </ion-col>
                                  <ion-col size="2">
                                    <select
                                      class="form-control"
                                      [(ngModel)]="row.unit"
                                    >
                                      <option [ngValue]="'kg'">kg</option>
                                      <option [ngValue]="'g'">g</option>
                                      <option [ngValue]="'Unidades'">
                                        Unidades
                                      </option>
                                      <option [ngValue]="'l'">l</option>
                                    </select>
                                  </ion-col>
                                  <ion-col size="1"></ion-col>
                                </ion-row>
                                <ion-row>
                                  <ion-col size="8"></ion-col>
                                  <ion-col></ion-col>
                                </ion-row>
                              </ng-container>
                              <ng-container *ngIf="sf2.type === 'checklist'">
                                <ion-item>
                                  <ion-select
                                    style="width: 100%; max-width: 100%"
                                    [multiple]="true"
                                    [(ngModel)]="group2[sf2.name]"
                                  >
                                    <ion-select-option
                                      *ngFor="let selectOption of sf2.checklistOptions"
                                      [value]="selectOption"
                                    >
                                      {{selectOption}}
                                    </ion-select-option>
                                  </ion-select>
                                </ion-item>
                              </ng-container>
                            </div>

                            <app-divider-input
                              *ngIf="sf2.type === 'divider'"
                              (click)="
                                toggleHideGroupFields(sf2, i, sf2.subfields)
                              "
                              [responseField]="sf2"
                            ></app-divider-input>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="taskTypeObject?.type === 'printTagsIber'">
          <refrigerated-tag
            *ngIf="(!product?.unfreezingTime && !product?.['preparationTime']) && !product.secondaryExpirationHoursSameAsPrimary"
            [product]="product"
            [primaryExpirationDate]="getPrimaryExpirationDate()"
            [lot]="lastResponse.lot"
            [preparationDate]="getPreparationDate()"
            [secondaryExpirationDate]="getSecondaryExpirationDate()"
          ></refrigerated-tag>
          <frozen-tag
            *ngIf="(product?.unfreezingTime || product?.['preparationTime']) && !product.secondaryExpirationHoursSameAsPrimary"
            [product]="product"
            [primaryExpirationDate]="getPrimaryExpirationDate()"
            [lot]="lastResponse.lot"
            [unfrozenDate]="getUnfrozenDate()"
            [secondaryExpirationDate]="getSecondaryExpirationDate()"
          ></frozen-tag>
          <same-secondary-as-primary-tag
            *ngIf="product.secondaryExpirationHoursSameAsPrimary"
            [product]="product"
            [lot]="lastResponse.lot"
            [primaryExpirationDate]="getPrimaryExpirationDate()"
            [preparationDate]="task.preparationDate"
          ></same-secondary-as-primary-tag>
        </div>
      </div>
      <div class="modalActions task">
        <div class="actionsLeft">
          <!-- <img
            *ngIf="taskTypeObject?.type === 'printTagsIber'"
            (click)="pressPrintTag()"
            src="assets/img/print.svg"
          /> -->
          <ion-icon
            name="print-outline"
            (click)="pressPrint()"
            *ngIf="task.type === 'inProductApanha' || task.type === 'inProductApanhaPimento' || task.type === 'inProductApanhaMilho' || task.type === 'inProductApanhaAzeitonaNF' || task.type === 'inProductApanhaPea2'"
            src="assets/img/print.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="!task.canceled && envService.isMachineMyHarvest()"
            (click)="pressCancel()"
            src="assets/img/recicle_bin.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="task.canceled && envService.isMachineMyHarvest()"
            (click)="pressUncancel()"
            src="assets/img/undo_delete.svg"
          ></ion-icon>
        </div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="close()">
            {{'CANCEL'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
