<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle taskTitle">
        <h3 class="modalTitleText">
          {{(batch.lot || '') + ' ' + (batch.expirationDate || '')}}
        </h3>
        <h2 class="modalSubtitleText">{{product.name}}</h2>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <div class="form-group">
          <label>Lote</label>
          <input
            class="form-control"
            type="text"
            [(ngModel)]="batch.lot"
            readonly
          />
        </div>

        <div class="form-group">
          <label>Data de validade</label>
          <input
            class="form-control"
            type="text"
            [(ngModel)]="batch.expirationDate"
            readonly
          />
        </div>
      </div>
      <div class="modalActions">
        <div class="actionsLeft"></div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="close()">
            {{'CANCEL'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
