<ion-content padding *ngIf="product" style="margin-bottom: 10%;">
  <ion-card>
    <ion-card-header>
      <ion-card-title>{{ 'Informações do Produto' | translate }}</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <!-- Nome -->
      <h5>{{ 'Nome' | translate }}</h5>
      <ion-item>
        {{ product?.name || 'N/A' }}
      </ion-item>

      <!-- Família -->
      <h5>{{ 'Família' | translate }}</h5>
      <ion-item>
        {{ product?.family || 'N/A' }}
      </ion-item>

      <!-- Marca -->
      <h5>{{ 'Marca' | translate }}</h5>
      <ion-item>
        {{ product?.brand || 'N/A' }}
      </ion-item>

      <!-- Fornecedor -->
      <h5>{{ 'Fornecedor' | translate }}</h5>
      <ion-item>
        {{ product?.supplierName || 'N/A' }}
      </ion-item>

      <!-- Código de Barras Interno -->
      <h5>{{ 'Cód. barras interno' | translate }}</h5>
      <ion-item>
        {{ product?.barcode?.itm8 || 'N/A' }}
      </ion-item>

      <!-- EAN -->
      <h5>{{ 'EAN' | translate }}</h5>
      <ion-item>
        {{ product?.barcode?.value || 'N/A' }}
      </ion-item>

      <!-- PLU -->
      <h5>{{ 'PLU' | translate }}</h5>
      <ion-item>
        {{ product?.barcode?.plu || 'N/A' }}
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>
