// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';

@Injectable({
  providedIn: 'root',
})
export class MayStayPendingHandler implements IMyTaskHandler {
  name = 'may-stay-pending';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (!data['Tarefa terminada']) {
      state.submit = false;
      const newTask = task;
  
      newTask.pendingResponses = newTask.pendingResponses
        ? [data, ...newTask.pendingResponses]
        : [data];
      return this.tasksService.newTask(
        newTask,
        this.authenticationService.getAuthInfo()
      );
    }
    return of(null);
  }
}
