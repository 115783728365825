import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PhotoModalComponent } from './photo.modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'photo-button',
  templateUrl: './photo.button.html',
  styleUrls: ['./photo.button.scss'],


})
export class PhotoButtonComponent {

  @Input() picturesIds: any;
  @Input() task: any;

  constructor(private modalCtrl: ModalController) { }

  async pressCamera() {
    const modal = await this.modalCtrl.create({
      component: PhotoModalComponent,
      componentProps: { photos: this.picturesIds }
    });

    modal.onDidDismiss().then((result) => {
      if (result.data) {
        this.picturesIds = result.data;
      }
    });

    return await modal.present();
  }
}
