import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { Link } from '../../utils/link';
import { JSON_HEADERS } from '../../utils/headers';
import { catchError, firstValueFrom, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ZonesService {
  private cache = new Map();
  private etag = '';

  constructor(private http: AuthenticatedHttpService, protected envService: EnvironmentService) {}

  getZonesFromStore(companyId: string, storeId: string) {
    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/zones`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );
    return this.http.get(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.cache = new Map();
        this.etag = response.headers.get('etag') || '';
        const indicators = response.body;
        indicators.forEach((i: any) => {
          this.cache.set(i.id, i);
        });
      }),
      catchError((error) => {
        console.error('Error fetching zones:', error);
        return of(null);
      })
    );
  }

  getZones(): any[] {
    return Array.from(this.cache.values());
  }

  getZoneById(id: string): any {
    return this.cache.get(id);
  }
}
