import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HandlersService, IMyTaskHandler } from './handlers.service';
import { MayStayPendingHandler } from './handlers/may-stay-pending.handler';
import { ClassifyProductUsersHandler } from './handlers/classify-product-users.handler';
import { RegisterExpirationDate2 } from './handlers/register-expiration-date2.handler';
import { CreateNewBatch } from './handlers/create-new-batch.handler';
import { FollowUpInsertSupplier } from './handlers/follow-up-insert-supplier.handler';
import { FollowUpTaskPlaceTags } from './handlers/follow-up-task-place-tags.handler';
import { GenerateTechnicalSheet2 } from './handlers/generate-technical-sheet2.handler';
import { FollowUpTaskInsertExpiration2 } from './handlers/follow-up-task-insert-expiration2.handler';
import { FollowUpFinishUnfreezing2 } from './handlers/follow-up-finish-unfreezing2.handler';
import { RegisterBatchForProduction } from './handlers/register-batch-for-production.handler';
import { FollowUpTaskProcessExpiration2 } from './handlers/follow-up-task-process-expiration2.handler';
import { CreateSectionsTasks } from './handlers/create-sections-tasks.handler';
import { FollowUpTaskControlWarmTemperature2 } from './handlers/follow-up-task-control-warm-temperature2.handler';
import { FollowUpInsertNonConformities } from './handlers/follow-up-insert-nonConformities.handler';
import { FollowUpTaskPrintTags } from './handlers/follow-up-task-print-tags';
import { CreateSectionsTasksAhresp } from './handlers/create-sections-tasks-ahresp';
import { FollowEquipmentMaintenance } from './handlers/follow-up-equipmentMaintenance';
import { FollowUpInsertHaccpEmailTo } from './handlers/follow-up-insert-haccp-emailTo';
import { FollowUpInsertDocsEmailTo } from './handlers/follow-up-insert-docs-emailTo.handler';
import { FollowUpInserFile } from './handlers/follow-up-insert-file.handler';
import { FollowUpFileValidation } from './handlers/follow-up-file-validation.handler';
import { FollowUpTaskPrintTagsGroupedBatches } from './handlers/follow-up-task-print-tags-grouped-batches';
import { FollowUpFileIntervention } from './handlers/follow-up-intervention.handler';
import { WriteOffBatchHandler } from './handlers/write-off-batch.handler';
import { WriteOffGroupedBatchesHandler } from './handlers/write-off-grouped-batches.handler';
import { CheckCreateEligibilityArea } from './handlers/check-create-eligibility-area';
import { FollowUpConfirmData } from './handlers/follow-up-confirm-data';
import { FollowUpFutureCorrection } from './handlers/follow-up-future-correction';
import { FollowUpSigningDeclarations } from './handlers/follow-up-signing-declarations';
import { FollowUpAccessCounterPlatform } from './handlers/follow-up-access-counter-platform';
import { FollowUpTellAboutProject } from './handlers/follow-up-tell-about-project';
import { FollowUpTagPreview } from './handlers/follow-up-tag-preview';
import { CreateSectionsTasksGeral } from './handlers/create-sections-tasks-geral';
import { FollowUpAccessBalcaoPlatform } from './handlers/follow-up-access-balcao-platform';
import { FollowUpConfirmDataPart2 } from './handlers/follow-up-confirm-data-part2';
import { CreateNewBatchTraceability } from './handlers/create-new-batch-traceability.handler';
import { WriteOffBatchIbersolHandler } from './handlers/write-off-batch-ibersol.handler';
import { FollowUpInsertStatements } from './handlers/follow-up-insert-statements';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [],
  providers: [
    HandlersService,
    MayStayPendingHandler,
    ClassifyProductUsersHandler,
    RegisterExpirationDate2,
    CreateNewBatch,
    CreateNewBatchTraceability,
    FollowUpInsertSupplier,
    FollowUpTaskPlaceTags,
    GenerateTechnicalSheet2,
    FollowUpTaskInsertExpiration2,
    FollowUpFinishUnfreezing2,
    RegisterBatchForProduction,
    FollowUpTaskProcessExpiration2,
    CreateSectionsTasks,
    FollowUpTaskControlWarmTemperature2,
    FollowUpInsertNonConformities,
    FollowUpTaskPrintTags,
    CreateSectionsTasksAhresp,
    FollowEquipmentMaintenance,
    FollowUpInsertHaccpEmailTo,
    FollowUpInsertDocsEmailTo,
    FollowUpInserFile,
    FollowUpFileValidation,
    FollowUpTaskPrintTagsGroupedBatches,
    CreateSectionsTasksGeral,
    FollowUpFileIntervention,
    WriteOffBatchHandler,
    WriteOffGroupedBatchesHandler,
    WriteOffBatchIbersolHandler,
    CheckCreateEligibilityArea,
    FollowUpConfirmData,
    FollowUpConfirmDataPart2,
    FollowUpFutureCorrection,
    FollowUpSigningDeclarations,
    FollowUpAccessCounterPlatform,
    FollowUpTellAboutProject,
    FollowUpTagPreview,
    FollowUpAccessBalcaoPlatform,
    FollowUpInsertStatements
  ],
})
export class HandlersModule {}
