<ion-content style="margin-bottom: 10%;">
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ 'Ficha Técnica' | translate }}</ion-card-title>
      </ion-card-header>
  
      <ion-card-content>
        <!-- Informações principais -->
        <h5>{{ 'Designação do produto' | translate }}</h5>
        <ion-item>
          {{ product.technicalSheet['Designação do produto'] || 'N/A' }}
        </ion-item>
        
        <h5>{{ 'PLU' | translate }}</h5>
        <ion-item>
          {{ product.technicalSheet.PLU || 'N/A' }}
        </ion-item>
  
        <h5>{{ 'Consumidores Alvo' | translate }}</h5>
        <ion-item>
          {{ product.technicalSheet['Consumidores Alvo'] || 'N/A' }}
        </ion-item>
  
        <h5>{{ 'Versão' | translate }}</h5>
        <ion-item>
          {{ product.technicalSheet['Versão'] || 'N/A' }}
        </ion-item>
      </ion-card-content>
    </ion-card>
  
    <!-- Composição do produto -->
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ 'Composição do produto' | translate }}</ion-card-title>
      </ion-card-header>
  
      <ion-card-content>
        <h5>{{ 'Lista de ingredientes' | translate }}</h5>
        <ion-list>
          <ion-item *ngFor="let ingredient of product.technicalSheet['Lista de ingredientes']">
            {{ ingredient.name }} - {{ ingredient.quantity }} {{ ingredient.unit }}
          </ion-item>
        </ion-list>
  
        <h5>{{ 'Alergénios' | translate }}</h5>
        <ion-list>
          <ion-item *ngFor="let allergen of product.technicalSheet['Alergénios']">
            {{ allergen }}
          </ion-item>
        </ion-list>
  
        <h5>{{ 'Pode conter vestígios de' | translate }}</h5>
        <ion-list>
          <ion-item *ngFor="let trace of product.technicalSheet['Pode conter vestígios de']">
            {{ trace }}
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  
    <!-- Instruções de preparação -->
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ 'Instruções de preparação' | translate }}</ion-card-title>
      </ion-card-header>
  
      <ion-card-content>
        <p>{{ product.technicalSheet['Instruções de preparação'] || 'N/A' }}</p>
      </ion-card-content>
    </ion-card>
  
    <!-- Parâmetros de conservação e rotulagem -->
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ 'Parâmetros de conservação e rotulagem' | translate }}</ion-card-title>
      </ion-card-header>
  
      <ion-card-content>
        <h5>{{ 'Tempo de preparação' | translate }}</h5>
        <ion-item>
          
          {{ product.technicalSheet['Tempo de preparação'] || 'N/A' }}
        </ion-item>
  
        <h5>{{ 'Durabilidade de vida (Balcão tradicional)' | translate }}</h5>
        <ion-item>
            
            {{ product.technicalSheet['Durabilidade de vida (Balcão tradicional)'] || 'N/A' }}
          </ion-item>
      </ion-card-content>
    </ion-card>
  
    <!-- Pré-embalamento -->
    <ion-card>
        <ion-card-header>
          <ion-card-title>{{ 'Pré-embalamento' | translate }}</ion-card-title>
        </ion-card-header>
      
        <ion-card-content>
          <!-- Material de embalagem LS -->
          <h5>{{ 'Material de embalagem LS' | translate }}</h5>
          <ion-item>
            {{ product.technicalSheet['Material de embalagem LS'] || 'N/A' }}
          </ion-item>
      
          <!-- Condicional para Película-simples -->
          <ng-container *ngIf="product.technicalSheet['Película-simples'] === true">
            <h5>{{ 'Película-simples' | translate }}</h5>
            <h2 style="color: black">{{ 'DLC para pré-embalamento Película-simples:' | translate }}</h2>
            <ion-item>
              {{ product.technicalSheet['DLC para pré-embalamento Película-simples'] || 'N/A' }}
            </ion-item>
          </ng-container>
      
          <!-- Condicional para Embalamento simples -->
          <ng-container *ngIf="product.technicalSheet['Embalamento simples'] === true">
            <h5>{{ 'Embalamento simples' | translate }}</h5>
            <h2 style="color: black">{{ 'DLC para pré-embalamento Embalamento simples:' | translate }}</h2>
            <ion-item>
              {{ product.technicalSheet['DLC para pré-embalamento Embalamento simples'] || 'N/A' }}
            </ion-item>
          </ng-container>
      
          <!-- Condicional para Vácuo -->
          <ng-container *ngIf="product.technicalSheet['Vácuo'] === true">
            <h5>{{ 'Vácuo' | translate }}</h5>
            <h2 style="color: black">{{ 'DLC para pré-embalamento Vácuo:' | translate }}</h2>
            <ion-item>
              {{ product.technicalSheet['DLC para pré-embalamento Vácuo'] || 'N/A' }}
            </ion-item>
          </ng-container>
      
          <!-- Condicional para Gás ATM -->
          <ng-container *ngIf="product.technicalSheet['Gás ATM'] === true">
            <h5>{{ 'Gás ATM' | translate }}</h5>
            <h2 style="color: black">{{ 'DLC para pré-embalamento Gás ATM:' | translate }}</h2>
            <ion-item>
              {{ product.technicalSheet['DLC para pré-embalamento Gás ATM'] || 'N/A' }}
            </ion-item>
            <h5>{{ 'Mistura de gás' | translate }}</h5>
            <ion-item>
              {{ product.technicalSheet['Mistura de gás'] || 'N/A' }}
            </ion-item>
          </ng-container>
      
        </ion-card-content>
      </ion-card>
      
    <!-- Conservação -->
    <ion-card style="margin-bottom: 10%">
      <ion-card-header>
        <ion-card-title>{{ 'Conservação' | translate }}</ion-card-title>
      </ion-card-header>
  
      <ion-card-content>
        <h5>{{ 'Tipo de conservação' | translate }}</h5>
        <ion-item>
          {{ product.technicalSheet['Tipo de conservação'] || 'N/A' }}
        </ion-item>
  
        <!-- Condicional baseado no tipo de conservação -->
        <ng-container *ngIf="product.technicalSheet['Tipo de conservação'] === 'Frio'">
            <h5>{{ 'Temperatura máxima de conservação (ºC)' | translate }}</h5>
          <ion-item>
            {{ product.technicalSheet['Temperatura máxima de conservação (ºC)'] || 'N/A' }}
          </ion-item>
        </ng-container>
  
        <ng-container *ngIf="product.technicalSheet['Tipo de conservação'] === 'Quente'">
            <h5>{{ 'Temperatura mínima de conservação (ºC)' | translate }}</h5>
          <ion-item>
            +65ºC
          </ion-item>
        </ng-container>
      </ion-card-content>
    </ion-card>
  </ion-content>
  