import {
  Component,
  ElementRef,
  NgZone,
  ViewChild,
  OnInit,
} from '@angular/core';
import {
  NavParams,
  Platform,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';

import { translate } from '../../../utils/translate.utils';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import cuid from 'cuid';
import { MediaService } from 'src/app/services/media.service';
import { AuthenticationService } from 'src/app/services/authentication';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-photo-modal',
  templateUrl: './photo.modal.html',
  styleUrls: ['./photo.modal.scss'],
})
export class PhotoModalComponent implements OnInit {
  @ViewChild('file') fileElementRef!: ElementRef;
  photos: string[] = [];
  current = 0;
  currentPhotoPath = '';

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private mediaService: MediaService,
    private authenticationService: AuthenticationService,
    private translator: TranslateService,
    public platform: Platform,
    private zone: NgZone
  ) {}

  async ngOnInit() {
    this.photos = this.navParams.get('photos') || [];
    if (this.photos.length === 0 && this.platform.is('android')) {
      await this.pressGreenPlusButton();
    }
    await this.updateCurrentPhotoPath();
  }

  close() {
    this.modalCtrl.dismiss(this.photos);
  }

  pressArrowRight() {
    if (this.current < this.photos.length - 1) {
      ++this.current;
    }
    this.updateCurrentPhotoPath().then(() => console.log('Update'));
  }

  pressArrowLeft() {
    if (this.current > 0) {
      --this.current;
    }
    this.updateCurrentPhotoPath().then(() => console.log('Update'));
  }

  async pressGreenPlusButton() {
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        source: this.platform.is('android')
          ? CameraSource.Camera
          : CameraSource.Prompt,
        correctOrientation: true,
        webUseInput: true,
        resultType: CameraResultType.DataUrl,
        height: 1000,
      });

      const picId = cuid();

      if (image.dataUrl)
        Filesystem.writeFile({
          path: `${picId}.jpeg`,
          data: image.dataUrl,
          directory: Directory.Data,
        })
          .then((response) => {
            this.updateCurrentPhotoPath().then(() => console.log('Update'));
          })
          .catch((error) => console.error('Error writing file', error));

      this.mediaService
        .submitPicture(
          picId,
          {
            id: picId,
            data: image.dataUrl as string,
            contentType: image.format,
          },
          this.authenticationService.getAuthInfo()
        )
        .subscribe(() => {
          console.log('Submitted');
        });
      console.log(this);
      console.log(this.photos);
      this.photos.push(picId);
      this.current = this.photos.length - 1;
    } catch (error) {
      console.error('Camera issue:', error);
      const alert = await this.alertCtrl.create({
        header: await translate('NO_PHOTO_TAKEN', this.translator),
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  pressRedMinusButton() {
    const oldCurrent = this.current--;
    if (oldCurrent === 0) {
      this.current = 0;
    }
    this.photos.splice(oldCurrent, 1);
    if (!this.platform.is('android') || !this.platform.is('capacitor')) {
      const elementRef = this.fileElementRef.nativeElement as HTMLInputElement;
      elementRef.value = '';
    }
    this.updateCurrentPhotoPath().then(() => console.log('Update'));
  }

  async updateCurrentPhotoPath() {
    if (this.photos.length === 0) this.currentPhotoPath = '';
    else if (this.platform.is('android') && this.platform.is('capacitor')) {
      const filePath = `${this.photos[this.current]}.jpeg`;

      const result = await Filesystem.readFile({
        path: filePath,
        directory: Directory.Data, // Ensure this matches where your file is stored
      });
      this.currentPhotoPath = `data:image/jpeg;base64,${result.data}`;
    } else {
      this.mediaService
        .getSingleMediaDataFromServer(this.photos[this.current])
        .subscribe(
          (dataString) => {
            this.currentPhotoPath = dataString;
          },
          (err) => {
            console.error('Error fetching photo data', err);
            this.currentPhotoPath = ''; // Fallback in case of an error
          }
        );
    }
  }
}
