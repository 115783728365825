    <div class="scroll-content" #scrollContent>
      <div class="modalItem">
        <div class="modalHeader">
          <div class="modalTitle">
            <h3>{{ 'HISTORY' | translate }}</h3>
            <img src="assets/img/close.svg" (click)="close()" />
          </div>
        </div>
        <div class="modalContent">
          <custom-history-task [task]="task"></custom-history-task>
        </div>
      </div>
    </div>
  