import { Component, Input } from '@angular/core';

@Component({
  selector: 'thermal-shock-tag',
  template: `
    <div *ngIf="!nonApplicable" class="tag-container">
      <div class="right-section">
        <div class="product-name">
          {{ product.name | slice:0:16 }}
        </div>
        <div>
          <span class="small-text">Prep </span>
          <span class="bold">
            {{ preparationDate | date : 'yyyy-MM-dd HH:mm' }}
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrl: './tag.scss',
})
export class ThermalShockTagComponent {
  @Input() nonApplicable!: boolean;
  @Input() product!: any;
  @Input() preparationDate!: Date;
}
