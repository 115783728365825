import { Component } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeveloperNotesModal } from '../developer-notes/developer-notes.modal';
import { AlertService } from '../../services/alert.service';
import { EmployeeService } from '../../services/employee.service';
import { AuthenticationService } from '../../services/authentication';
import { StoresService } from '../../services/stores.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { translate } from '../../../utils/translate.utils';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-pinpad',
  templateUrl: './pinpad.html',
  styleUrls: ['../../../theme/modals.scss'],
})
export class PinpadComponent {
  isValidatingPassword: boolean = false;
  pin: string = '';
  newPin: string = ''; // Adicione um valor inicial para evitar o erro

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private navCtrl: NavController,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private storesService: StoresService, // Inject StoresService,
    protected envService: EnvironmentService
  ) {
    if (this.envService.isProduction()) {
      this.pin = '';
    } else {
      this.pin = this.envService.getDefaultStoreCredentials()?.employeePin || '';
    }
  }

  getEmployee() {
    let employee = this.employeeService.getEmployees().filter((u) => {
      if (!u || !u.pin) return false;
      const pinAsString = String(u.pin);
      return pinAsString.match('^' + this.pin + '$');
    })[0]; //está a vir o primeiro
    return employee || null;
  }

  async loginEmployee() {
    let employee = this.getEmployee();
    if (employee) {
      if (employee.validatedPassword) {
        this.employeeService.setEmployee(employee);
        this.modalCtrl.dismiss();
      } else {
        this.employeeService.setEmployee(employee);
        this.isValidatingPassword = true;
      }
    } else {
      const title = await translate('INVALID_PIN_TITLE', this.translateService);
      const text = await translate('INVALID_PIN_TEXT', this.translateService);
      this.alertService.present(title, text);
    }
  }

  async updateEmployeePin() {
    if (this.newPin && this.newPin !== this.pin) {
      this.employeeService.updateEmployeePin(this.newPin).subscribe(
        async (e) => {
          this.employeeService.setEmployee(e);
          this.modalCtrl.dismiss();
        },
        async (error) => {
          if (error.json) {
            let errorJson = error.json();
            if (
              errorJson.message.includes(
                'E11000 duplicate key error collection'
              )
            ) {
              const title = await translate(
                'INVALID_NEW_PIN_TITLE_2',
                this.translateService
              );
              const text = await translate(
                'INVALID_NEW_PIN_TEXT_2',
                this.translateService
              );
              this.alertService.present(title, text);
            }
          } else {
            this.alertService.presentError();
          }
        }
      );
    } else if (!this.newPin) {
      const title = await translate(
        'INVALID_NO_NEW_PIN_TITLE',
        this.translateService
      );
      const text = await translate(
        'INVALID_NO_NEW_PIN_TEXT',
        this.translateService
      );
      this.alertService.present(title, text);
    } else {
      const title = await translate(
        'INVALID_NEW_PIN_TITLE',
        this.translateService
      );
      const text = await translate(
        'INVALID_NEW_PIN_TEXT',
        this.translateService
      );
      this.alertService.present(title, text);
    }
  }

  close() {
    this.modalCtrl.dismiss('close');
  }

  showDeveloperNotes() {
    const notesModal = this.modalCtrl.create({
      component: DeveloperNotesModal,
      componentProps: {
        notes: this.storesService.store.foNotes,
      },
    });
    notesModal.then((modal) => modal.present());
  }
}
