<div class="formTask">
  <div class="taskItem">
    <div class="taskHeader">
      <img class="tashHeaderImg" src="assets/img/head_datehour_bar.png" />
      <div
        class="taskTime"
        *ngIf="!(storesService?.store?.code.includes('Quiz') && envService.isMachineGreenselection())"
      >
        <div class="taskStartTime">
          <h3>
            <span style="color: var(--ion-color-primary)">Início: </span>
            <span>{{task.startDate | date: "dd/MM/yy HH:mm"}}</span>
          </h3>
        </div>
        <div
          class="taskEndTime"
          *ngIf="!(task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF')"
        >
          <h3>
            <span style="color: var(--ion-color-primary)">Fim: </span>
            <span>{{task.endDate | date: "dd/MM/yy HH:mm"}}</span>
          </h3>
        </div>
        <div
          class="taskEndTime"
          *ngIf="(task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF') && lastResponse && lastResponse['clientDate']"
        >
          <img src="assets/img/date.svg" />
          <h3>
            {{(lastResponse && lastResponse['clientDate']) | date: "dd/MM/yy"}}
          </h3>
          <img src="assets/img/clock_red.svg" />
          <h3>
            {{(lastResponse && lastResponse['clientDate']) | date: "HH:mm"}}
          </h3>
        </div>
      </div>
    </div>
    <div class="taskContent">
      <div class="taskDescription">
        <p>
          <span *ngIf="task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
            >{{'TASK'|translate}}:
          </span>
          <span *ngIf="task.target.storeId === 'clku0roe800003q74ta64e1gz'"
            >{{'Estado'|translate}}:
          </span>
          <span>{{task.title}}</span>
        </p>
        <p *ngIf="task.subtitle">
          <span *ngIf="task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
            >Subtítulo:
          </span>
          <span *ngIf="task.target.storeId === 'clku0roe800003q74ta64e1gz'"
            >Guia:
          </span>
          <span>{{task.subtitle}}</span>
        </p>
        <p *ngIf="task.productId">
          <span>{{'Produto'|translate}}: </span>
          <span class="light-grey"
            >{{productsService.getProductById(task.productId)?.name}}</span
          >
        </p>

        <p *ngIf="task.batch">
          <span>{{'Lote'|translate}}: </span>
          <span class="light-grey"
            >{{(task.batch.lot || '') + ' ' + (task.batch.expirationDate ||
            '')}}</span
          >
        </p>
        <p *ngIf="task.productId">
          <span>{{'Marca'|translate}}: </span>
          <span class="light-grey"
            >{{productsService.getProductById(task.productId)?.brand}}</span
          >
        </p>
        <p *ngIf="task.productId">
          <span>{{'Fornecedor'|translate}}: </span>
          <span class="light-grey"
            >{{productsService.getProductById(task.productId)?.supplierName ||
            productsService.getProductById(task.productId)?.supplierName
            }}</span
          >
        </p>
        <p
          *ngIf="task.productId && productsService.getProductById(task.productId)?.barcode?.value"
        >
          <span>{{'EAN'|translate}}: </span>
          <span class="light-grey"
            >{{productsService.getProductById(task.productId)?.barcode?.value}}</span
          >
        </p>
        <p
          *ngIf="task.productId && productsService.getProductById(task.productId)?.barcode?.itm8"
        >
          <span>{{'ITM8'|translate}}: </span>
          <span class="light-grey"
            >{{productsService.getProductById(task.productId)?.barcode?.itm8}}</span
          >
        </p>
        <p *ngIf="task.expirationDate">
          <span>{{'Data Validade'|translate}}: </span>
          <span class="light-grey"
            >{{task.expirationDate | date:"dd/MM/yy HH:mm"}}</span
          >
        </p>
        <p *ngIf="task['groupedBatchesLots']">
          <span>{{'Lote(s)'|translate}}: </span>
          <span class="light-grey">{{task['groupedBatchesLots']}}</span>
        </p>
        <p *ngIf="task.tagsToPrint">
          <span>{{'Quantidade etiquetas'|translate}}: </span>
          <span class="light-grey">{{task.tagsToPrint}}</span>
        </p>
        <p *ngIf="task.tagsReason">
          <span>{{'Destino do lote'|translate}}: </span>
          <span class="light-grey">{{task.tagsReason}}</span>
        </p>
        <p *ngIf="task.idNumber">
          <span>{{'Nº de identificação'|translate}}: </span>
          <span class="light-grey">{{task.idNumber}}</span>
        </p>
        <p *ngIf="task.type === '2itmGoodsReceptionBasePerGuide'">
          <span>{{'Famílias de produto'|translate}}: </span>
          <span class="light-grey"
            >{{getReceptionFamilies(task, lastResponse)}}</span
          >
        </p>
        <!--        <p *ngIf="task.periodicity">
          <span>{{'TASK_PERIODICITY'|translate}}: </span>
          <span class="light-grey">{{task.periodicity}}</span>
        </p>-->
        <p *ngIf="task.zoneId">
          <span>{{'TASK_ZONE'|translate}}: </span>
          <span class="light-grey"
            >{{zonesService.getZoneById(task.zoneId).name}}</span
          >
        </p>
        <p *ngIf="task.equipmentId">
          <span>{{'TASK_EQUIPMENT'|translate}}: </span>
          <span class="light-grey"
            >{{equipmentsService.getEquipmentById(task.equipmentId).name}}</span
          >
        </p>
        <p *ngIf="task.machineId">
          <span>{{'TASK_MACHINE'|translate}}: </span>
          <span class="light-grey"
            >{{sensorsService.getMachineById(task.machineId).machine}}</span
          >
        </p>
        <p *ngIf="task.product">
          <span>{{'PRODUCT'|translate}}: </span>
          <span class="light-grey">{{task.product}}</span>
        </p>

        <p
          *ngIf="task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF'"
        >
          <span>{{'Sociedade'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Sociedade'] || task.precedingTaskSociety ||
            storesService.store.company.fullName ||
            fieldsService.getClosestLagarField(task.formId).society}}</span
          >
        </p>
        <p
          *ngIf="task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF'"
        >
          <span>{{'Herdade'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Herdade'] || task.precedingTaskParcel ||
            storesService.store.code + ' ' +
            storesService.store.fullName}}</span
          >
        </p>
        <p
          *ngIf="(lastResponse && lastResponse['Nº Guia']) || (task.precedingTaskResponse && task.precedingTaskResponse['Nº Guia']) && task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
        >
          <span>{{'Nº Guia'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Nº Guia'] || task.precedingTaskResponse['Nº
            Guia']}}</span
          >
        </p>
        <p
          *ngIf="(lastResponse && lastResponse['Matrícula Transporte']) || (task.precedingTaskResponse && task.precedingTaskResponse['Matrícula'])"
        >
          <span>{{'Matrícula Transporte'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Matrícula Transporte'] ||
            task.precedingTaskResponse['Matrícula Transporte']}}</span
          >
        </p>
        <p
          *ngIf="(lastResponse && lastResponse['Grupo de Colheita']) || (task.precedingTaskResponse && task.precedingTaskResponse['Grupo de Colheita'])"
        >
          <span>{{'Grupo de Colheita'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Grupo de Colheita'] ||
            task.precedingTaskResponse['Grupo de Colheita']}}</span
          >
        </p>
        <p
          *ngIf="(lastResponse && lastResponse['Variedade']) || (task.precedingTaskResponse && task.precedingTaskResponse['Variedade'])"
        >
          <span>{{'Variedade'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Variedade'] ||
            task.precedingTaskResponse['Variedade']}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Quantidade aproximada (kg)']"
        >
          <span>{{'Quantidade aproximada (kg)'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Quantidade aproximada (kg)'] ||
            task.precedingTaskResponse['Quantidade aproximada (kg)']}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Nº Ticket sujo']"
        >
          <span>{{'Nº Ticket sujo'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Nº Ticket sujo'] || task.precedingTaskResponse['Nº
            Ticket sujo']}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Quantidade (peso sujo em kg)']"
        >
          <span>{{'Quantidade (peso sujo em kg)'|translate}}: </span>
          <span class="light-grey"
            >{{lastResponse['Quantidade (peso sujo em kg)'] ||
            task.precedingTaskResponse['Quantidade (peso sujo em kg)']}}</span
          >
        </p>
        <p
          *ngIf="(lastResponse && lastResponse['Última carga do dia']) || task.precedingTaskResponse && task.precedingTaskResponse['Última carga do dia']"
        >
          <span>{{'Última carga do dia'|translate}}: </span>
          <span class="light-grey" style="color: red"
            >{{(lastResponse['Última carga do dia'] ||
            task.precedingTaskResponse['Última carga do dia'])?'SIM':''}}</span
          >
        </p>
      </div>
      <div class="taskActions">
        <div class="actionsLeft">
          <app-photo-history-button
            *ngIf="task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
            [task]="task"
            [picturesIds]="(task.responses && task.responses[0])?.picturesIds || []"
          ></app-photo-history-button>
          <info-button [task]="task" style="margin-left: 12px"></info-button>
        </div>
        <div class="actionsRight">
          <img
            src="assets/img/not_according.svg"
            *ngIf="hasNoButton() && task.target.storeId !== 'clku0roe800003q74ta64e1gz' && (task.type === 'yesNo' || isSingleTemperatureType())"
            (click)="pressNo()"
          />
          <img
            *ngIf="taskTypeObject.metadata.responseFields && taskTypeObject.metadata.responseFields.length > 0"
            (click)="pressContent()"
            src="assets/img/task_expand_icon.svg"
          />
          <img
            *ngIf="!(taskTypeObject.metadata.responseFields && taskTypeObject.metadata.responseFields.length > 0)"
            src="assets/img/according.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
