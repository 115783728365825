export const ENV_MACHINES = {
  DEV: '.dev',
  PROD: '.prod',
  IBERSOL: '.ibersol',
  INTERMARCHE: '.intermarche',
  GREENSELECTION: '.greenselection',
  MYTASK: '.mytask',
  EFN: '.efn',
  MYHARVEST: '.harvest',
} as const;

export type MachineType = (typeof ENV_MACHINES)[keyof typeof ENV_MACHINES];
