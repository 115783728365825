<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Close</ion-button>
    </ion-buttons>
    <ion-title>{{ 'Produtos mais usados' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let product of products | slice:0:counter" (click)="pressProduct(product)">
      <div>
        <h2>{{product.name}}</h2>
      </div>
      <div style="margin-top: 8%;
    margin-left: -7%;">
        <p *ngIf="product.barcode.itm8">Cód. barras interno: {{product.barcode.itm8s || product.barcode.itm8}}</p>
        <p *ngIf="product.barcode.value">EAN: {{product.barcode.value}}</p>
      </div>
    </ion-item>
    <ion-item *ngIf="products.length === 0">
      {{ "Sem resultados" | translate }}
    </ion-item>
  </ion-list>
  <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer></ion-footer>
