import { Component } from '@angular/core';
import {
  ModalController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BatchCreateModal } from '../batch-create-modal/batch-create.modal';

@Component({
  selector: 'app-batch-select-modal',
  templateUrl: 'batch-select.modal.html',
  styleUrls: ['../../../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BatchSelectModal {
  product: any;
  searchValue: string = '';

  searchControl = new FormControl();
  searchResult: any[] = [];

  selectedBatches: any[] = [];

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.product = this.navParams.get('product');

    this.searchResult = this.filterBatches(this.product.batches || []).sort(
      (a, b) => {
        if (!a.expirationDate) return 1;
        if (!b.expirationDate) return -1;
        return a.expirationDate.localeCompare(b.expirationDate);
      }
    );
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((search) => {
      this.searchResult = this.filterBatches(this.product.batches || [], search).sort((a, b) => {
        if (!a.expirationDate) return 1;
        if (!b.expirationDate) return -1;
        return a.expirationDate.localeCompare(b.expirationDate);
      });
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async pressCreateNew() {
    const modal = await this.modalCtrl.create({
      component: BatchCreateModal,
      componentProps: {
        product: this.product,
      }
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.searchResult.push(data.data);
      }
    });
    await modal.present();
  }

  filterBatches(batches: any[], search: string = ''): any[] {
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    return batches.filter((b) => {
      if (b.expirationDate) {
        let batchExpiryDate: Date;

        if (b.expirationDate.match(/^\d{4}$/)) {
          batchExpiryDate = new Date(`${b.expirationDate}-12-31`);
        } else if (b.expirationDate.match(/^\d{4}-\d{2}$/)) {
          const [year, month] = b.expirationDate.split('-');
          const lastDay = new Date(parseInt(year), parseInt(month), 0).getDate();
          batchExpiryDate = new Date(`${b.expirationDate}-${lastDay}`);
        } else {
          batchExpiryDate = new Date(b.expirationDate);
        }

        if (isNaN(batchExpiryDate.getTime()) || batchExpiryDate < twoWeeksAgo) {
          return false;
        }
      }

      if (b.lot && b.lot.includes(search)) {
        return true;
      }
      if (b.expirationDate && b.expirationDate.includes(search)) {
        return true;
      }

      return false;
    });
  }

  toggleBatchSelection(batch: any) {
    const index = this.selectedBatches.indexOf(batch);
    if (index === -1) {
      this.selectedBatches.push(batch);
    } else {
      this.selectedBatches.splice(index, 1);
    }
  }

  submitSelectedBatches() {
    this.modalCtrl.dismiss(this.selectedBatches);
  }
}
