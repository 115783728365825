<ion-header>
    <ion-toolbar>
      <ion-title>{{ 'TAG_MODAL_TITLE' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <div class="container scroll-content" #scrollContent>
      <div class="modalItem">
        <div class="modalHeader">
          <div class="modalTitle">
            <h3>{{product.name}}{{product.brand ? ' ' + product.brand : ''}} {{product.supplierName || product.supplier?.name}}</h3>
            <img src='assets/img/close.svg' (click)="close()" />
          </div>
        </div>
        <div class="modalContent">
          <div class="modalDescription">
            <div class="tag">
              <h3>FILETES RO/ZINGER (Marinado)</h3>
              <h4>DESCON/PREPA 01/04/2022 15:58</h4>
              <h4>UTILIZAÇÃO 01/04/2022 15:58</h4>
              <h4>VALIDADE 03/04/2022 15:58</h4>
              <p>Refrigerado</p>
              <h5>Refrigerado /1ºC - 4ºC</h5>
            </div>
          </div>
          <div class="modalActions">
            <div class="actionsLeft">
            </div>
            <div class="actionsRight">
              <button class="btn btn-cancel light-grey" (click)="close()">{{'CLOSE' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
  