<ion-card>
  <ion-card-header>
    <div class="card-title-with-icon">
      <ion-icon
        [name]="expiringProducts.length ? 'warning' : 'checkmark-circle'"
        [color]="expiringProducts.length ? 'warning' : 'success'"
      ></ion-icon>
      <ion-card-title color="primary">Produtos a Expirar</ion-card-title>
    </div>
  </ion-card-header>

  <ion-card-content>
    <div *ngIf="expiringProducts.length; else noProducts">
      <ion-list>
        <ion-item *ngFor="let product of expiringProducts">
          <ion-label>
            <strong>{{ product.name }}</strong> - Expira em
            <strong>{{
              product.expirationDate | date : "YYYY-MM-dd HH:mm"
            }}</strong>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- Button to navigate to expiring products page -->
      <ion-button
        expand="block"
        (click)="navigateToExpiringProducts()"
        class="navigate-button"
      >
        <ion-icon name="alert-circle-outline" slot="start"></ion-icon>
        Ver Produtos a Expirar
      </ion-button>
    </div>

    <ng-template #noProducts>
      <p>Não há produtos a expirar hoje.</p>
    </ng-template>
  </ion-card-content>
</ion-card>
