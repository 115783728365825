// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import moment from 'moment';
import { ProductsService } from 'src/app/services/products.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterBatchForProduction implements IMyTaskHandler {
  name = 'register-batch-for-production';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private productsService: ProductsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const productId = task.productId;

    
    if (typeof productId === 'string') {
      const product = this.productsService.getProductById(productId);
      if (!product) {
        console.log('Product not found');
        return of(null);
      }
      

      const batch = {
        ...data['Lote e Validade'],
        registrationDate: moment.now(),
      };
      product['rawMaterialUsedBatches']
        ? product['rawMaterialUsedBatches'].unshift(batch)
        : (product['rawMaterialUsedBatches'] = [batch]);
      this.productsService
        .newProduct(product, this.authenticationService.getAuthInfo())
        .subscribe(
          (data) => {},
          (err) => console.log(err)
        );
    }
    return of(null);
  }
}
