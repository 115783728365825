import { Component } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { StoresService } from '../../services/stores.service';
import { ProductCreateModal } from '../product-create-modal/product-create.modal';
import { IbersolProductCreateModal } from '../product-create-modal/ibersol/ibersol-product-create.modal';
import { ProductsService } from '../../services/products.service';
import {
  Product,
  isProductFabricated,
  productMatchesSearchTermNormalized,
} from '../../entities/product';
import { AuthenticationService } from '../../services/authentication';
import { debounceTime } from 'rxjs/operators';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { scanBarcode } from 'src/utils/barcode.utils';
import { BarcodeScanningModalComponent } from '../barcode-scanning/barcode-scanning.modal';

@Component({
  selector: 'app-product-list-modal',
  templateUrl: './product-list.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductListModal {
  product: Product | undefined;
  searchTerm: string = '';

  searching = false;
  isSearched = false;
  searchControl = new FormControl();
  allProducts: Product[] = [];
  products: Product[] = [];
  counter = 0;
  private ITEMS_PER_TICK = 20;

  includeProductsWithTechnicalSheet = true;
  includeProductsWithoutTechnicalSheet = true;

  productsListTitle: string = 'Lista de produtos';

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private storeService: StoresService,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService,
    private toastController: ToastController
  ) {
    const includeProductsWithoutTechnicalSheet = this.navParams.get(
      'includeProductsWithoutTechnicalSheet'
    );
    if (includeProductsWithoutTechnicalSheet !== undefined)
      this.includeProductsWithoutTechnicalSheet =
        includeProductsWithoutTechnicalSheet;

    this.searchTerm = '';


    const productsListTitleFromNav = this.navParams.get('productsListTitle');
  if (productsListTitleFromNav) {
    this.productsListTitle = productsListTitleFromNav;
  }

  
    const barcode = this.navParams.get('barcode');
    if (barcode) {
      this.searchTerm = barcode;
    }
    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((search: string) => {
        this.products = this.allProducts.filter((p) =>
          search.trim() === '' || productMatchesSearchTermNormalized(p, search)
        );
        
        this.counter = this.ITEMS_PER_TICK;
        this.searching = false;
      });
    this.allProducts = this.productsService
      .getProducts()
      .filter((p: Product) => {
        if (!this.includeProductsWithTechnicalSheet && p.hasTechnicalSheet)
          return false;
        if (
          !this.includeProductsWithoutTechnicalSheet &&
          !isProductFabricated(p) /* ||
            !doesTechnicalSheetBelongsToUser(
              p,
              this.authenticationService.getUserInfo()
            ) */
        )
          return false;
        return true;
      })
      .sort((p1, p2) => {
        return p1?.name?.localeCompare(p2?.name);
      });
    this.products = this.allProducts;
    this.counter = this.ITEMS_PER_TICK;
  }

  onSearchInput() {
    this.isSearched = true;
    this.searching = true;
  }

  doInfinite(event: any) {
    this.counter =
      this.counter + this.ITEMS_PER_TICK <= this.allProducts.length
        ? this.counter + this.ITEMS_PER_TICK
        : this.allProducts.length;
    event.target.complete();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pressProduct(product: Product) {
    this.modalCtrl.dismiss(product);
  }

  async pressCreateNew() {
    let modal;
    if (this.storeService.store.modules.productsType === 'ibersol') {
      modal = await this.modalCtrl.create({
        component: IbersolProductCreateModal,
      });
    } else {
      modal = await this.modalCtrl.create({
        component: ProductCreateModal,
        componentProps: {
          barcode: this.searchTerm,
        },
      });
    }
    modal.onDidDismiss().then((data) => {
      if (data.data) this.modalCtrl.dismiss(data.data);
    });
    await modal.present();
  }

  async pressBarcodeScanner() {
    try {
      const permissionStatus = await BarcodeScanner.checkPermissions();
      if (permissionStatus.camera !== 'granted') {
        await BarcodeScanner.requestPermissions();
      }

      // const result = await scanBarcode(BarcodeScanner);
      const modal = await this.modalCtrl.create({
        component: BarcodeScanningModalComponent,
        cssClass: 'barcode-scanning-modal',
        componentProps: {},
      });
      await modal.present();
      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.searchTerm = result;
        // Trigger the search manually
        this.searchControl.setValue(this.searchTerm);
        // You can also add any additional processing here if needed
      } else {
        const toast = await this.toastController.create({
          message: 'No barcode content found.',
          duration: 2000,
          position: 'top',
        });
        await toast.present();
      }
    } catch (err) {
      console.error('Error during barcode scan:', err);
      const toast = await this.toastController.create({
        message: 'Error during barcode scan.',
        duration: 2000,
        position: 'top',
      });
      await toast.present();
    }
  }
}
