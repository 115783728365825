import { Component, OnInit, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Printer } from '../../interfaces/Printer';
import { ViewController } from '@ionic/core';
import { ModalController, Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { BrotherPrinterService } from '../../services/brother-printer.service';

declare var cordova: any;

@Component({
  selector: 'printer-config-modal',
  templateUrl: 'printer-config.modal.html',
})
export class PrinterConfigModal implements OnInit {
  refreshing: boolean = false;
  printersList: Printer[] = [];
  selectedPrinter: Printer | null = null;
  selectedOrientation: 'PORTRAIT' | 'LANDSCAPE' = 'PORTRAIT';
  selectedPaperSize: string = 'W62H29';
  errorText = '';

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private brotherPrinterService: BrotherPrinterService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.platform.is('android') && this.platform.is('cordova')) {
      this.printersList = await this.findPrinters();
      this.selectedPrinter = await this.getSavedPrinter();
    }
    if (this.selectedPrinter) {
      this.selectedOrientation = this.selectedPrinter.orientation;
      this.selectedPaperSize = this.selectedPrinter.paperLabelName;
    } else {
      this.selectedOrientation = 'PORTRAIT';
      this.selectedPaperSize = 'W62H29';
    }
  }

  //This will list all of your bluetooth devices
  async findPrinters(): Promise<Printer[]> {
    this.refreshing = true;
    const printers = await this.brotherPrinterService.findPrinters();
    this.refreshing = false;
    return printers;
  }

  async getSavedPrinter(): Promise<Printer | null> {
    return this.brotherPrinterService.getSavedPrinter();
  }

  //This will store selected bluetooth device mac address
  async selectPrinter(ev: any) {
    if (ev === null) return;
    this.selectedPrinter = ev;
    if (this.selectedPrinter === null) return;
    this.selectedPrinter.orientation = this.selectedOrientation;
    this.selectedPrinter.paperLabelName = this.selectedPaperSize;
    this.brotherPrinterService.savePrinter(this.selectedPrinter);
  }

  async clearSelected() {
    this.brotherPrinterService.clearSavedPrinter();
    this.selectedPrinter = null;
  }

  close() {
    this.modalController.dismiss();
  }

  onPaperLabelNameChange() {
    console.log(this.selectedPaperSize);
    if (!this.selectedPrinter) return;
    this.selectedPrinter.paperLabelName = this.selectedPaperSize;
    this.brotherPrinterService.savePrinter(this.selectedPrinter);
  }

  onOrientationChange() {
    console.log(this.selectedOrientation);
    if (!this.selectedPrinter) return;
    this.selectedPrinter.orientation = this.selectedOrientation;
    this.brotherPrinterService.savePrinter(this.selectedPrinter);
  }
}
