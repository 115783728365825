import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoresService } from './stores.service';
import { AuthenticatedHttpService } from './authenticated-http';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { PromptModal } from '../components/prompt/prompt.modal';
import { JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { translate } from '../../utils/translate.utils';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private cache = new Map<string, any>();
  private etag = '';

  constructor(
    private http: AuthenticatedHttpService,
    private storesService: StoresService,
    private translater: TranslateService,
    private modalController: ModalController,
    protected envService: EnvironmentService
  ) {}

  getDocumentsFromStore(companyId: string, storeId: string): Observable<any> {
    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/foDocuments`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );

    return this.http.get(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const documents = response.body || [];
        documents.forEach((doc: any) => {
          this.cache.set(doc.id, doc);
        });
      }),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  //TODO tipificar
  getDocuments(): any {
    const replaceContents = (node: any, reports: any[]): any => {
      if (node.type === 'report') {
        const matchingReport = reports.find(
          (report) => report.name === node.title
        );
        return matchingReport ? { ...node, contents: matchingReport } : node;
      } else {
        return {
          ...node,
          contents: node.contents.map((content: any) =>
            replaceContents(content, reports)
          ),
        };
      }
    };

    const replaceReportsInFolders = (
      folders: any[],
      reports: any[]
    ): any[] => {
      return folders.map((folder) => replaceContents(folder, reports));
    };

    if (this.storesService.store?.reports) {
      return replaceReportsInFolders(
        this.storesService.getReports(),
        Array.from(this.cache.values())
      );
    } else {
      return Array.from(this.cache.values());
    }
  }

  getDocument(id: string): any {
    return this.cache.get(id);
  }

  browserDownloadDocument(url: string): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('GET', url, {
      responseType: 'blob',
      reportProgress: true,
    });
    return this.http.request(req);
  }

  async requestMobileDataUse(): Promise<boolean> {
    const title = translate('3G_DOWNLOAD_TITLE', this.translater);
    const text = translate('3G_DOWNLOAD_TITLE', this.translater);
    const yesText = translate('3G_DOWNLOAD_BUTTON_YES', this.translater);
    const noText = translate('3G_DOWNLOAD_BUTTON_NO', this.translater);
    const prompt = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title,
        text,
        yesText,
        noText,
      },
    });
    await prompt.present();
    const { data } = await prompt.onWillDismiss();
    return data;
  }
}
