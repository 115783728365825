import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  templateUrl: 'history.modal.html',
  styleUrls: ['../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HistoryModal {
  task: any;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.task = this.navParams.get('task');
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
