import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { FieldsService } from "../../services/fields.service";

@Component({
  selector: 'documents-filters-modal',
  templateUrl: './documents-filters.modal.html',
})
export class DocumentsFiltersModalComponent {
  fields: any[];
  selectedField: any;

  constructor(
    private modalController: ModalController,
    private fieldsService: FieldsService,
    private translate: TranslateService,
    private navParams: NavParams
  ) {
    this.fields = this.fieldsService.getFieldsWithoutChildren();
  }

  close() {
    this.modalController.dismiss('close');
  }

  accept() {
    this.modalController.dismiss(this.selectedField);
  }
}
