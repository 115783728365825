<ion-header>
  <ion-toolbar>
    <ion-title>{{ displayLabel }}</ion-title> <!-- Usa a variável displayLabel -->
        <ion-buttons slot="end">
      <ion-button (click)="confirmSelection()" *ngIf="multiple">OK</ion-button>
      <ion-button (click)="close()">Fechar</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="none">
    <ion-searchbar
      [(ngModel)]="searchTerm"
      [formControl]="searchControl"
      (ionInput)="onSearchInput()"
    ></ion-searchbar>
  </ion-item>

  <div *ngIf="searching" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>

  <ion-item lines="none" style="display: flex; justify-content: flex-end">
    <ion-select
      placeholder="Ordenar por"
      (ionChange)="onOrderChange($event)"
      interface="popover"
    >
      <ion-select-option value="asc">Ascendente</ion-select-option>
      <ion-select-option value="desc">Descendente</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-list>
    <ion-item *ngFor="let option of filteredOptions" (click)="toggleSelection(option)">

      <ion-label>{{ option }}</ion-label>
      <ion-checkbox
        slot="end"
        [checked]="isSelected(option)"
        *ngIf="multiple"
      ></ion-checkbox>
    </ion-item>
  </ion-list>       
</ion-content>
