// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, forkJoin, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import moment from 'moment';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';

@Injectable({
  providedIn: 'root',
})
export class FollowEquipmentMaintenance implements IMyTaskHandler {
  name = 'follow-up-equipmentMaintenance';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any): string {
    return type.getTitle === 'type' ? type.name : `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any): string | undefined {
    if (!type.getSubtitle) return undefined;

    switch (type.getSubtitle) {
      case 'scheduleCollectionProductApanha':
        return `${this.storeService.store.code} - ${data['Parcela']}`;
      case 'ratingApanha':
      case 'collectionProductApanha':
        return task.subtitle;
      case 'complexTask':
        return data['Comentário'];
      case 'NF':
        return `${data['Nº Guia']} - ${data['Herdade']}`;
      default:
        if (type.type === 'inProductApanhaAzeitonaNF') {
          return `${data['Nº Guia']}`;
        }
        if (type.type === 'inTransitAzeitonaNF') {
          return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
        }

        return this.generateSubtitleFromPieces(type, data);
    }
  }

  private generateSubtitleFromPieces(type: any, data: any): string {
    const pieces = type.getSubtitle.split(' ');
    return pieces
      .map((piece: string) => this.pieceToString(piece, type, data))
      .join(' - ');
  }

  private pieceToString(piece: any, type: any, data: any): string {
    switch (piece) {
      case 'type':
        return type.name;
      case 'product':
        return data['Produto'] ? data['Produto'].name : '';
      case 'expirationDate':
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
      default:
        return '';
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const type = this.customTaskTypesService.getCustomTaskType(
      'demoEquipmentMaintenance'
    );

    if (!type) {
      console.log('Type not found');
      return of(null);
    }

    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }


    if (data['Periodicidade'] === 'Única') {
        const newTask = {
        id: cuid(), // Generate unique ID using cuid
        type: type.type,
        formId: task.formId,
        title: this.getTitle(type, data) + ' ' + data['Nome do Equipamento'],
        subtitle: this.getSubtitle(type, data),
        startDate: moment().valueOf(),
        endDate: moment().add(1, 'day').valueOf(),
        visualizationDate: moment().add(1, 'year').valueOf(),
        nonConformities: [],
        causes: [],
        corrections: [],
        periodicity: 'Única',
        target: {
          storeId: task.target.storeId,
          userIds: getTargetUser(this.storeService.store, userInfo),
        },
        documentsIds: [],
        isCustomType: true,
        superType: undefined,
        precedingTaskId: task.id,
      };

      return this.tasksService
        .newTask(newTask, this.authenticationService.getAuthInfo())
        .pipe(
          (data) => of(data),
          (err) => {
            console.log(err);
            return of(null);
          }
        );
    }
    return of(null);
  }
}
