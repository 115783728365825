<div class="form-group">
  <label
    >{{ getTranslation(responseField) }} {{ responseField.required ? "*" : "" }}
  </label>
  <span style="display: flex" (click)="openBatchDetail()">
    <input
      class="form-control"
      style="margin-right: 11px; margin-top: 6.578px; height: 46.844px"
      type="text"
      [placeholder]="'Escolher lote'"
      [readonly]="true"
      [value]="getValue()"
    />
    <img src="assets/img/barcode.svg" style="width: 60px; height: 60px" />
  </span>
</div>
