<div class="modalTask">
  <div class="taskItem">
    <div class="taskHeader">
      <div class="taskTime">
        <div class="taskStartTime">
          <img src="assets/img/date.svg" />
          <h3>{{task.startDate | date: "dd/MM/yy"}}</h3>
          <img src="assets/img/clock_green.svg" />
          <h3>{{task.startDate | date: "HH:mm"}}</h3>
        </div>
        <div class="taskEndTime">
          <img src="assets/img/date.svg" />
          <h3>{{task.endDate | date: "dd/MM/yy"}}</h3>
          <img src="assets/img/clock_red.svg" />
          <h3>{{task.endDate | date: "HH:mm"}}</h3>
        </div>
      </div>
    </div>
    <div class="taskContent">
      <div class="taskDescription">
        <p>
          <span>{{'TASK'|translate}}: </span>
          <span>{{task.title}}{{task.subtitle?" " + task.subtitle:""}}</span>
        </p>
        <!--        <p *ngIf="task.periodicity">
          <span>{{'TASK_PERIODICITY'|translate}}: </span>
          <span class="light-grey">{{task.periodicity}}</span>
        </p>-->
        <p *ngIf="task.zoneId">
          <span>{{'TASK_ZONE'|translate}}: </span>
          <span class="light-grey"
            >{{zonesService.getZoneById(task.zoneId).name}}</span
          >
        </p>
        <p *ngIf="task.equipmentId">
          <span>{{'TASK_EQUIPMENT'|translate}}: </span>
          <span class="light-grey"
            >{{equipmentsService.getEquipmentById(task.equipmentId).name}}</span
          >
        </p>
        <p *ngIf="task.machineId">
          <span>{{'TASK_MACHINE'|translate}}: </span>
          <span class="light-grey"
            >{{sensorsService.getMachineById(task.machineId).machine}}</span
          >
        </p>
      </div>
      <div class="taskActions">
        <div class="actionsLeft">
          <info-button [task]="task" style="margin-left: 12px"></info-button>
          <photo-button
            [task]="task"
            [picturesIds]="response.picturesIds"
            *ngIf="task.type === 'yesNo'"
          ></photo-button>
        </div>
        <div class="actionsRight">
          <img
            *ngIf="taskTypeObject.metadata.responseFields && taskTypeObject.metadata.responseFields.length > 0"
            (click)="pressContent()"
            src="assets/img/task_expand_icon.svg"
          />
          <img
            *ngIf="!(taskTypeObject.metadata.responseFields && taskTypeObject.metadata.responseFields.length > 0)"
            (click)="pressYes()"
            src="assets/img/according.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
