import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-smart-list-modal',
  templateUrl: './select-smart.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SmartListModal implements OnInit {
  @Input() selectOptions: string[] = [];
  @Input() selectedValues: string[] = [];
  @Input() multiple: boolean = false;
  order: string = 'asc'; // Ordem padrão é ascendente
  @Input() label: string = '';

  displayLabel: string = ''; // Variável para armazenar o título final

  searchTerm: string = '';
  searchControl: FormControl = new FormControl();
  searching: boolean = false;
  filteredOptions: string[] = [];

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    if (this.label.toLowerCase().includes('selecione')) {
      this.displayLabel = this.label; // Se já contém "Selecione", use o label como está
    } else {
      this.displayLabel = `Selecione ${this.label}`; // Caso contrário, adicione "Selecione" antes
    }
    
    this.sortOptions();
    this.filteredOptions = this.selectOptions; // Inicializa com todas as opções

    this.searchControl.valueChanges
      .pipe(debounceTime(300)) // Aguarda 300ms antes de realizar a busca
      .subscribe((value) => {
        this.onSearchInput();
      });
  }

  // Método para ordenar as opções
  sortOptions() {
    this.selectOptions = this.selectOptions.filter(option => 
      typeof option === 'string' && option.trim() !== ""
    );

    this.selectOptions.sort((a, b) => 
      this.order === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
    );

    this.filteredOptions = this.selectOptions; // Atualiza as opções filtradas após ordenação
  }

  onSearchInput() {
    this.searching = true; // Ativa o indicador de carregamento

    // Simulação de uma busca, substitua pela sua lógica real
    setTimeout(() => {
      this.filteredOptions = this.selectOptions.filter(option =>
        option.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      
      this.searching = false; // Desativa o indicador de carregamento
    }, 1000); // Simulando um atraso de 1 segundo
  }

  onOrderChange(event: CustomEvent) {
    this.order = event.detail.value;
    this.sortOptions();
  }

  isSelected(option: string): boolean {
    return this.selectedValues.includes(option);
  }

  toggleSelection(option: string) {
    if (this.multiple) {
      if (this.isSelected(option)) {
        this.selectedValues = this.selectedValues.filter(value => value !== option);
      } else {
        this.selectedValues.push(option);
      }
    } else {
      this.selectedValues = [option];
      this.modalController.dismiss({
        selectedValues: this.selectedValues
      });
    }
  }

  confirmSelection() {
    this.modalController.dismiss({
      selectedValues: this.selectedValues,
    });
  }

  close() {
    this.modalController.dismiss();
  }
}
