// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, forkJoin, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import cuid from 'cuid';
import moment from 'moment';
import { getTargetUser } from '../../entities/authentication-info';
import {
  isProductExpiringBeforeEndOfDay,
  isProductExpiringInLessThan4Days,
} from '../../entities/product';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { ExecutionState } from '../../services/custom-task.service';
import { ProductsService } from '../../services/products.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpTaskPrintTagsGroupedBatches implements IMyTaskHandler {
  name = 'follow-up-task-print-tags-grouped-batches';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private productsService: ProductsService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}
  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (!data[`Validade já não existe`]) {
      if (isProductExpiringBeforeEndOfDay(data['Data de expiração'])) {
        console.log('The end date is within the next 24h.');
      } else if (isProductExpiringInLessThan4Days(data['Data de expiração'])) {
        console.log('The end date is within the next four days.');

        const productId = task.productId;
        if (typeof productId === 'string') {
          const product = this.productsService.getProductById(productId);
          const type = this.customTaskTypesService.getCustomTaskType(
            'expirationPrintTags'
          );
          if (!product) {
            console.log('Product not found');
            return of(null);
          }
          if (!type) {
            console.log('Type not found');
            return of(null);
          }
          const userInfo = this.authenticationService.getUserInfo();
          if (!userInfo) {
            console.log('User info not found');
            return of(null);
          }
          const store = this.storeService.store;
          if (!store) {
            console.log('Store not found');
            return of(null);
          }

          if (!store.modules?.expiration?.printTagsFormId) {
            console.log('Module information not found');
            return of(null);
          }

          const newTask = {
            id: cuid(), //use lib to generate id
            type: type.type,
            formId: store.modules.expiration.printTagsFormId,
            title: this.getTitle(type, data),
            subtitle: this.getSubtitle(type, data),
            startDate: moment().valueOf(),
            endDate: moment().add(1, 'day').valueOf(),
            visualizationDate: moment().add(1, 'year').valueOf(),
            nonConformities: [],
            causes: [],
            corrections: [],
            periodicity: 'Única',
            target: {
              storeId: task.target.storeId,
              userIds: getTargetUser(store, userInfo),
            },
            documentsIds: [],
            isCustomType: true,
            superType: undefined,
            precedingTaskId: task.id,
            productId: product.id,
            tagsToPrint: data[`Quantidade etiquetas a emitir`],
            tagsReason: data[`Destino do lote`],
            expirationDate: data['Data de expiração'],
            groupedBatchesLots: task.groupedBatchesLots,
          };
          this.tasksService
            .newTask(newTask, this.authenticationService.getAuthInfo())
            .subscribe(
              (data) => {},
              (err) => console.log(err)
            );
        }
      }
    }

    return of(null);
  }
}
