import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldButtonComponent } from '../fields/field-button/field-button.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [FieldButtonComponent],
  imports: [CommonModule, IonicModule],
  exports: [FieldButtonComponent],
})
export class SharedModule {}
