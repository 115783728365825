<div class=" scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{'NON_PROGRAMMED_TASK'|translate}}</h3>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <div class="form-group">
          <label class="label">{{'TITLE'|translate}}:</label>
          <input class="form-control" [(ngModel)]="task.title" type="text">
        </div>
        <label class='input-w' for='dataInício'>
          <span class='label'>{{'START_DATE'|translate}}:</span>
          <input class='form-control with-right-icon' type='datetime-local' id='dataInício'
                 placeholder='dd/mm/aa hh:mm:ss' [(ngModel)]="task.startDate">
          <img for='dataInício' src='assets/img/clock_button.svg'/>
        </label>
        <label class='input-w' for='dataFim'>
          <span class='label'>{{'END_DATE'|translate}}:</span>
          <input class='form-control with-right-icon' type='datetime-local' id='dataFim'
                 placeholder='dd/mm/aa hh:mm:ss' [(ngModel)]="task.endDate">
          <img for='dataFim' src='assets/img/clock_button.svg'/>
        </label>
        <div class="form-group">
          <label class="label">{{'FORM'|translate}}:</label>
          <select class="form-control" [(ngModel)]="task.formId">
            <option [ngValue]="'yesNo'">{{'TYPE_YESNO'|translate}}</option>
            <option *ngFor="let form of forms" [ngValue]="form.id">{{form.title}}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="label">{{'TASK_TYPE'|translate}}:</label>
          <select class="form-control" [(ngModel)]="task.type">
            <option *ngFor="let type of types" [ngValue]="type.type">{{type.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="label with-checkbox">
            {{'IS_CLEANING_TASK'|translate}}:
          </label>
          <ion-checkbox [(ngModel)]="isCleaningTask"></ion-checkbox>
        </div>
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="pressSubmit()">{{'OK'|translate}}</button>
          <button class="btn btn-cancel light-grey" (click)="close()">{{'CANCEL'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
