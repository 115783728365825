<ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="close()">Fechar</ion-button>
      </ion-buttons>
      <ion-title>{{ getProductDesignation(product) }}</ion-title>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <form [formGroup]="formGroup">
      <product-input
        [responseField]="productNameResponseField"
        [(value)]="product"
      ></product-input>
      
      <!-- <boolean-input
        [responseField]="nonApplicableResponseField"
        [(value)]="nonApplicable"
      ></boolean-input> -->
      
      <datetime-input
        *ngIf="!nonApplicable"
        [responseField]="receptionDateResponseField"
        [(value)]="receptionDate"
        [control]="receptionDateControl"
      ></datetime-input>
      
      <number-input
        *ngIf="!nonApplicable"
        [responseField]="quantityTagsResponseField"
        [(value)]="quantityTags"
      ></number-input>
      
      <app-reception-tag
        [product]="product"
        [receptionDate]="receptionDate"
      ></app-reception-tag>
    </form>
  </ion-content>
  
  <ion-footer>
    <ion-toolbar>
      <ion-buttons slot="start" style="order: 1">
        <ion-button (click)="pressPrint()">
          <ion-icon slot="start" name="print"></ion-icon>
          Imprimir
        </ion-button>
      </ion-buttons>
      <!-- <ion-buttons slot="end">
        <ion-button>Imprimir mais tarde</ion-button>
      </ion-buttons> -->
    </ion-toolbar>
  </ion-footer>
  