<div class="container scroll-content" #scrollContent>
  <div
    class="modalItem"
    [class.background-color-orange]="type.metadata.backgroundColor === 'orange'"
    [class.background-color-red]="type.metadata.backgroundColor === 'red'"
    *ngIf="initialized"
  >
    <div class="modalHeader task">
      <div class="modalTitle taskTitle">
        <h3 class="modalTitleText">{{getTitle()}}</h3>
        <h2 class="modalSubtitleText" *ngIf="type.getSubtitle">
          {{getSubtitle()}}
        </h2>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <span
          *ngFor="let responseField of type?.metadata?.responseFields; index as i;"
        >
          <span *ngIf="processCondition(responseField)">
            <text-input
              *ngIf="responseField.type === 'text' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></text-input>
            <select-input
              *ngIf="responseField.type==='select' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
              [getSelectOptions]="getSelectOptions.bind(this)"
              (saveNewOption)="onNewOptionAdded($event, responseField)"
              [userAddedOptions]="getUserAddedOptions(responseField)"
              [response]="response"
            ></select-input>
            <number-input
              *ngIf="responseField.type==='number' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
              (valueChange)="recalculateAllData()"
            ></number-input>
            <boolean-input
              *ngIf="responseField.type==='boolean' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></boolean-input>
            <date-input
              *ngIf="responseField.type==='date' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></date-input>
            <datetime-input
              style="padding: 1px"
              *ngIf="responseField.type==='datetime' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></datetime-input>
            <time-input
              *ngIf="responseField.type==='time' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></time-input>
            <product-input
              *ngIf="responseField.type==='product' && !responseField.isHiding"
              [value]="response[responseField.name]"
              [responseField]="responseField"
            ></product-input>
            <app-batch-input
              *ngIf="responseField.type==='batch' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
              [product]="extras.product"
            ></app-batch-input>
            <app-file-input
              *ngIf="responseField.type === 'file' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></app-file-input>
            <app-previsao-input
              *ngIf="responseField.type === 'previsao' && !responseField.isHiding"
              [responseField]="responseField"
              [(value)]="response[responseField.name]"
            ></app-previsao-input>

            <div
              class="form-group"
              *ngIf="responseField.type !== 'file' && responseField.type !== 'previsao' && responseField.type !== 'product' && responseField.type !== 'text' && responseField.type !== 'select' && responseField.type !== 'number'&& responseField.type !== 'boolean'&& responseField.type !== 'datetime'&& responseField.type !== 'date' && responseField.type !== 'time' && responseField.type !== 'batch' &&  responseField.type !== 'divider' && responseField.type !== 'group' && !responseField.isHiding"
            >
              <label
                >{{getTranslation(responseField)}} {{responseField.required ?
                '*' : ''}}</label
              >
              <app-list-input
                *ngIf="responseField.type==='list' && responseField.name!=='Lista de ingredientes'"
              ></app-list-input>
              <select *ngIf="responseField.units" class="form-control">
                <option
                  *ngFor="let unit of responseField.units"
                  [ngValue]="unit"
                >
                  {{unit}}
                </option>
              </select>
              <textarea
                *ngIf="responseField.type==='textarea'"
                class="form-control"
                rows="5"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="response[responseField.name]"
                [readonly]="!!responseField.locked"
                (ngModelChange)="updateCharacterCount(responseField.name, $event)"
              ></textarea>
              <label
                *ngIf="responseField.type==='textarea' && responseField.count===true"
                >{{ characterCounts[responseField.name] }} Caracteres</label
              >
              <input
                *ngIf="responseField.type==='url'"
                class="form-control"
                type="url"
                [placeholder]="getPlaceholderTranslation(responseField) || ''"
                [(ngModel)]="response[responseField.name]"
                [readonly]="!!responseField.locked"
              />
              <img
                *ngIf="responseField.type==='photo'"
                src="assets/img/camera.svg"
                (click)="pressCamera(response, responseField)"
              />
              <ion-badge
                style="position: relative; top: -3vh"
                *ngIf="responseField.type==='photo'"
                >{{response[responseField.name].length}}</ion-badge
              >
              <span
                *ngIf="responseField.type==='list' && responseField.name==='Lista de ingredientes'"
              >
                <ion-row>
                  <ion-col size="6">Ingrediente</ion-col>
                  <ion-col
                    size="6"
                    *ngIf="type.type==='technicalSheetIM2' || type.type==='technicalSheetAL' || type.type==='technicalSheetLJ' || type.type==='serunionTechnicalSheet' || this.type.type === 'greenTechnicalSheet'"
                    >Quantidade (total)</ion-col
                  >
                  <ion-col
                    size="6"
                    *ngIf="type?.type==='productionIM2' || type?.type==='productionAL' || type?.type==='productionLJ'"
                    >Lote e validade</ion-col
                  >
                  <ion-col
                    size="6"
                    *ngIf="type?.type==='serunionProductionNewModel' || type?.type==='greenProductionNewModel'"
                    >Lote</ion-col
                  >
                </ion-row>
                <ion-row
                  *ngFor="let row of response[responseField.name], let i = index"
                >
                  <ion-col
                    size="12"
                    *ngIf="type?.type==='industrialManufacturingIM'"
                    ><input
                      class="form-control"
                      [(ngModel)]="row.name"
                      type="text"
                      [readonly]="type.type==='industrialManufacturingIM'"
                  /></ion-col>
                  <ion-col
                    size="6"
                    *ngIf="type?.type!=='industrialManufacturingIM'"
                    ><input
                      class="form-control"
                      [(ngModel)]="row.name"
                      type="text"
                      [readonly]="type.type==='productionIM2' || type.type==='productionAL' || type.type==='productionLJ' || type.type==='serunionProductionNewModel' || type.type==='greenProductionNewModel'"
                  /></ion-col>
                  <ion-col
                    size="2"
                    *ngIf="type.type==='technicalSheetIM2' || type.type==='technicalSheetAL' || type.type==='technicalSheetLJ' || type.type==='serunionTechnicalSheet' || this.type.type === 'greenTechnicalSheet'"
                    ><input
                      class="form-control"
                      [(ngModel)]="row.quantity"
                      type="number"
                  /></ion-col>
                  <ion-col
                    size="3"
                    *ngIf="type.type==='technicalSheetIM2' || type.type==='technicalSheetAL' || type.type==='technicalSheetLJ' || type.type==='serunionTechnicalSheet' || this.type.type === 'greenTechnicalSheet'"
                  >
                    <select
                      *ngIf="type.type==='technicalSheetIM2' || type.type==='technicalSheetAL' || type.type==='technicalSheetLJ' || type.type==='serunionTechnicalSheet' || this.type.type === 'greenTechnicalSheet'"
                      [(ngModel)]="row.unit"
                      class="form-control"
                    >
                      <option [ngValue]="'kg'">kg</option>
                      <option [ngValue]="'Unidades'">Unidades</option>
                      <option [ngValue]="'l'">l</option>
                      <option [ngValue]="'g'">g</option>
                    </select>
                  </ion-col>
                  <ion-col
                    size="5"
                    *ngIf="type?.type==='productionIM2' || type?.type==='productionAL' || type?.type==='productionLJ'"
                  >
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Adicionar"
                      readonly
                      (click)="openSelectRawMaterialUsed(row)"
                      [(ngModel)]="row.selectedProductInfo"
                    />
                  </ion-col>
                  <ion-col
                    size="5"
                    *ngIf="type?.type ==='serunionProductionNewModel' || type?.type==='greenProductionNewModel'"
                  >
                    <img
                      src="assets/img/camera.svg"
                      (click)="pressListCamera(row, 'photos')"
                    />
                    <ion-badge style="position: relative; top: -3vh"
                      >{{row?.photos?.length || 0}}</ion-badge
                    >
                  </ion-col>
                  <!-- <ion-col
                    size="1"
                    *ngIf="type?.type==='productionIM2' && row.selectedProductInfo !== 'Automático' || type?.type==='productionAL' && row.selectedProductInfo !== 'Automático' || type?.type==='productionLJ' && row.selectedProductInfo !== 'Automático'"
                  >
                    <button
                      ion-button
                      icon-only
                      (click)="openSelectRawMaterialUsed(row)"
                    >
                    <ion-icon name="newspaper-outline"></ion-icon>
                  </button>
                  </ion-col> -->
                  <ion-col
                    size="1"
                    *ngIf="type.type==='technicalSheetIM2' || type.type==='technicalSheetAL' || type.type==='technicalSheetLJ' || type.type==='serunionTechnicalSheet' || this.type.type === 'greenTechnicalSheet'"
                    (click)="removeFromList(responseField, i)"
                  >
                    <ion-icon
                      name="remove-circle"
                      style="color: red"
                    ></ion-icon>
                  </ion-col>
                </ion-row>
                <ion-row
                  *ngIf="type.type==='technicalSheetIM2' || type.type==='technicalSheetAL' || type.type==='technicalSheetLJ' || type.type==='serunionTechnicalSheet' || this.type.type === 'greenTechnicalSheet'"
                >
                  <ion-col size="8"></ion-col>
                  <ion-col (click)="addToList(responseField)">
                    <span
                      style="float: right; font-size: 1.2em; font-weight: bold"
                      >Adicionar novo
                      <ion-icon
                        name="add-circle"
                        style="color: green; font-size: 2em"
                      ></ion-icon></span
                  ></ion-col>
                </ion-row>
              </span>

              <span *ngIf="responseField.type==='checklist'">
                <ion-item>
                  <ion-select
                    style="width: 100%; max-width: 100%"
                    [multiple]="true"
                    [(ngModel)]="response[responseField.name]"
                    interface="popover"
                  >
                    <ion-select-option
                      *ngFor="let selectOption of responseField.checklistOptions"
                      [value]="selectOption"
                      >{{selectOption}}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </span>
              <span
                *ngIf="responseField.type==='addRegister' && responseField.taskType==='prepackagedPDVIntermarcheShort2'"
              >
                <img
                  src="assets/img/trace_confection.svg"
                  (click)="pressAddRegister(responseField.taskType)"
                />
                <ion-item>
                  <ion-grid>
                    <ion-row>
                      <ion-col>Quantidade</ion-col>
                      <ion-col>Data</ion-col>
                    </ion-row>
                    <ion-row *ngIf="prepackageTasks.length === 0">
                      <ion-col
                        >Sem registos de embalamento para este lote.</ion-col
                      >
                    </ion-row>
                    <ion-row *ngFor="let t of prepackageTasks">
                      <ion-col
                        >{{t.response['Quantidade Pré-embaladas']}}</ion-col
                      >
                      <ion-col
                        >{{t.response['Data'] | date: "yyyy-MM-dd"}}</ion-col
                      >
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </span>
              <span
                *ngIf="responseField.type==='addRegister' && (responseField.taskType==='unfreezingProductsShort' || responseField.taskType==='unfreezingProductsShort2' || responseField.taskType==='unfreezingProductsRepsol')"
              >
                <img
                  src="assets/img/trace_confection.svg"
                  (click)="pressAddRegister(responseField.taskType)"
                  style="width: 60px; height: 60px"
                />
                <ion-item>
                  <ion-grid>
                    <ion-row>
                      <ion-col>Temperatura Início</ion-col>
                      <ion-col>Quantidade</ion-col>
                      <ion-col>Data</ion-col>
                    </ion-row>
                    <ion-row *ngIf="unfreezingTasks.length === 0">
                      <ion-col
                        >Sem registos de descongelação para este lote.</ion-col
                      >
                    </ion-row>
                    <ion-row *ngFor="let t of unfreezingTasks">
                      <ion-col>{{t.response['Temperatura Início']}}</ion-col>
                      <ion-col>{{t.response['Quantidade']}}</ion-col>
                      <ion-col
                        >{{t.response['Início de Descongelação'] | date:
                        "yyyy-MM-dd HH:mm"}}</ion-col
                      >
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </span>
              <span
                *ngIf="responseField.type==='addRegister' && (responseField.taskType==='warmTemperatureControlIntermarcheShort2' || responseField.taskType==='warmTemperatureControlRepsol')"
              >
                <img
                  src="assets/img/trace_confection.svg"
                  (click)="pressAddRegister(responseField.taskType)"
                />
                <ion-item>
                  <ion-grid>
                    <ion-row>
                      <ion-col>Quantidade</ion-col>
                      <ion-col>Fim de confeção</ion-col>
                    </ion-row>
                    <ion-row *ngIf="warmTemperatureControlTasks.length === 0">
                      <ion-col
                        >Sem registos de controlo de temperatura para este
                        lote.</ion-col
                      >
                    </ion-row>
                    <ion-row *ngFor="let t of warmTemperatureControlTasks">
                      <ion-col>{{t.response['Quantidade']}}</ion-col>
                      <ion-col
                        >{{t.response['Fim de confeção'] | date:
                        "yyyy-MM-dd"}}</ion-col
                      >
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </span>

              <span
                *ngIf="responseField.type==='addRegister' && responseField.taskType==='inBeefRegisterShort2'"
              >
                <img
                  src="assets/img/trace_confection.svg"
                  (click)="pressAddRegister(responseField.taskType)"
                />
                <ion-item>
                  <ion-grid
                    *ngIf="responseField.taskType==='inBeefRegisterShort2'"
                  >
                    <ion-row>
                      <ion-col>Designação</ion-col>
                      <ion-col>Nº Identificação/Cód. Referência</ion-col>
                    </ion-row>
                    <ion-row *ngIf="inBeefRegisterTasks.length === 0">
                      <ion-col>Sem registos de entrada de bovino.</ion-col>
                    </ion-row>
                    <ion-row *ngFor="let t of inBeefRegisterTasks">
                      <ion-col>{{t.response['Produto'].name}}</ion-col>
                      <ion-col>{{t.response['Lote'].lot}}</ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </span>

              <span
                *ngIf="responseField.type==='addRegister' &&  responseField.taskType==='fermentationProductsRepsol'"
              >
                <img
                  src="assets/img/trace_confection.svg"
                  (click)="pressAddRegister(responseField.taskType)"
                />
                <ion-item>
                  <ion-grid
                    *ngIf="responseField.taskType==='fermentationProductsRepsol'"
                  >
                    <ion-row>
                      <ion-col>Quantidade</ion-col>
                      <ion-col>Data</ion-col>
                    </ion-row>
                    <ion-row *ngIf="fermentationProductsTasks.length === 0">
                      <ion-col
                        >Sem registos de controlo de fermentação para este
                        lote.</ion-col
                      >
                    </ion-row>
                    <ion-row *ngFor="let t of fermentationProductsTasks">
                      <ion-col>{{t.response['Quantidade']}}</ion-col>
                      <ion-col
                        >{{t.response['Início de Fermentação'] | date:
                        "yyyy-MM-dd"}}</ion-col
                      >
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </span>

              <span *ngIf="responseField.type==='addPest'">
                <img
                  src="assets/img/bug-outline.svg"
                  (click)="pressAddRegister(responseField.taskType)"
                />
              </span>
            </div>
            <div *ngIf="responseField.type === 'trackGPS'">
              <img
                style="width: 50px; height: 50px"
                (click)="pressTrackGPS()"
                src="assets/img/gps.svg"
              />
            </div>
            <app-divider-input
              *ngIf="responseField.type === 'divider'"
              (click)="
                toggleHideGroupFields(responseField, i, type.metadata.responseFields)
              "
              [responseField]="responseField"
            ></app-divider-input>
            <div
              *ngIf="responseField.type === 'group' && !responseField.isHiding"
            >
              <div class="modalTextSection" *ngIf="!responseField.noText">
                <div class="text-container">
                  <div class="text">{{ getTranslation(responseField) }}</div>
                </div>
              </div>
              <ion-card
                *ngFor="let group of response[responseField.name]"
                style="--background: '#e2f2f7'"
              >
                <ion-card-content>
                  <app-remove-button-component
                    (handleRemove)="removeFromGroup(response[responseField.name], group)"
                  ></app-remove-button-component>
                  <span *ngFor="let sf of responseField.subfields; index as i;">
                    <span *ngIf="processCondition(sf, group)">
                      <text-input
                        *ngIf="sf.type === 'text' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                      ></text-input>
                      <select-input
                        *ngIf="sf.type==='select' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                        [getSelectOptions]="getSelectOptions.bind(this)"
                        (saveNewOption)="onNewOptionAdded($event, group)"
                        [userAddedOptions]="getUserAddedOptions(group)"
                        [response]="group"
                      ></select-input>
                      <number-input
                        *ngIf="sf.type==='number' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                      ></number-input>
                      <boolean-input
                        *ngIf="sf.type==='boolean' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                      ></boolean-input>
                      <date-input
                        *ngIf="sf.type==='date' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                      ></date-input>
                      <datetime-input
                        style="padding: 1px"
                        *ngIf="sf.type==='datetime' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                      ></datetime-input>
                      <time-input
                        *ngIf="sf.type==='time' && !sf.isHiding"
                        [responseField]="sf"
                        [(value)]="group[sf.name]"
                      ></time-input>
                      <!--                     <app-product-input
                      *ngIf="sf.type==='product'"
                      [value]="group[sf.name]"
                      [responseField]="group[sf.name]"
                    ></app-product-input>
                    <app-batch-input
                      *ngIf="sf.type==='batch'"
                      [responseField]="sf"
                      [(value)]="group[sf.name]"
                      [product]="extras.product"
                    ></app-batch-input> -->
                      <div
                        class="form-group"
                        *ngIf="sf.type !== 'text' && sf.type !== 'select' && sf.type !== 'number'&& sf.type !== 'boolean'&& sf.type !== 'datetime'&& sf.type !== 'date' && sf.type !== 'time' && sf.type !== 'batch' &&  sf.type !== 'divider' && sf.type !== 'group' && !sf.isHiding"
                      >
                        <label
                          >{{getTranslation(sf)}} {{sf.required ? '*' :
                          ''}}</label
                        >
                        <select *ngIf="sf.units" class="form-control">
                          <option
                            *ngFor="let unit of sf.units"
                            [ngValue]="unit"
                          >
                            {{unit}}
                          </option>
                        </select>
                        <textarea
                          *ngIf="sf.type==='textarea'"
                          class="form-control"
                          rows="5"
                          [placeholder]="getPlaceholderTranslation(sf) || ''"
                          [(ngModel)]="group[sf.name]"
                          (ngModelChange)="updateCharacterCount(sf.name, $event)"
                        ></textarea>
                        <label *ngIf="sf.type==='textarea' && sf.count===true"
                          >{{ characterCounts[sf.name] }} caracteres</label
                        >
                        <img
                          *ngIf="sf.type==='photo'"
                          src="assets/img/camera.svg"
                          (click)="pressCamera(group, sf)"
                        />
                        <ion-badge
                          style="position: relative; top: -3vh"
                          *ngIf="sf.type==='photo'"
                          >{{group[sf.name].length}}</ion-badge
                        >
                        <span
                          *ngIf="sf.type==='list' && sf.name==='Lista de ingredientes'"
                        >
                          <ion-row>
                            <ion-col size="6">Ingrediente</ion-col>
                            <ion-col size="6">Quantidade (total)</ion-col>
                          </ion-row>
                          <ion-row
                            *ngFor="let row of group[sf.name], let i = index"
                          >
                            <ion-col size="6"
                              ><input
                                class="form-control"
                                [(ngModel)]="row.name"
                                type="text"
                            /></ion-col>
                            <ion-col size="2"
                              ><input
                                class="form-control"
                                [(ngModel)]="row.quantity"
                                type="number"
                            /></ion-col>
                            <ion-col size="2">
                              <select
                                class="form-control"
                                [(ngModel)]="row.unit"
                              >
                                <option [ngValue]="'kg'">kg</option>
                                <option [ngValue]="'g'">g</option>
                                <option [ngValue]="'Unidades'">Unidades</option>
                                <option [ngValue]="'l'">l</option>
                              </select></ion-col
                            >
                            <ion-col size="1" (click)="removeFromList(sf, i)">
                              <ion-icon name="remove-circle"></ion-icon>
                            </ion-col>
                          </ion-row>
                          <ion-row>
                            <ion-col size="8"></ion-col>
                            <ion-col (click)="addToList(sf)">
                              <span style="float: right"
                                >Adicionar novo
                                <ion-icon name="add-circle"></ion-icon></span
                            ></ion-col>
                          </ion-row>
                        </span>
                        <span *ngIf="sf.type==='checklist'">
                          <ion-item>
                            <ion-select
                              style="width: 100%; max-width: 100%"
                              [multiple]="true"
                              [(ngModel)]="group[sf.name]"
                              interface="popover"
                            >
                              <ion-select-option
                                *ngFor="let selectOption of sf.checklistOptions"
                                [value]="selectOption"
                                >{{selectOption}}</ion-select-option
                              >
                            </ion-select>
                          </ion-item>
                        </span>
                      </div>
                      <app-divider-input
                        *ngIf="sf.type === 'divider'"
                        (click)="
                      toggleHideGroupFields(sf, i, responseField.subfields ?? [])
                    "
                        [responseField]="sf"
                      >
                      </app-divider-input>
                      <div
                        *ngIf="sf.type === 'group' && !sf.isHiding && processCondition(sf, group)"
                      >
                        <span *ngFor="let group2 of group[sf.name]">
                          <span
                            (click)="removeFromGroup(group[sf.name], group2)"
                            style="float: right; color: rgb(255, 0, 0, 0.5)"
                          >
                            Remover
                          </span>
                          <span *ngFor="let sf2 of sf.subfields; index as i;">
                            <span *ngIf="processCondition(sf2, group2)">
                              <div
                                class="form-group"
                                *ngIf="sf2.type !== 'divider' && !sf2.isHiding"
                              >
                                <label
                                  >{{getTranslation(sf2)}} {{sf2.required ? '*'
                                  : ''}}</label
                                >
                                <number-input
                                  *ngIf="sf2.type==='number'"
                                  [responseField]="sf2"
                                  [(value)]="group2[sf2.name]"
                                ></number-input>
                                <select *ngIf="sf2.units" class="form-control">
                                  <option
                                    *ngFor="let unit of sf2.units"
                                    [ngValue]="unit"
                                  >
                                    {{unit}}
                                  </option>
                                </select>
                                <ion-checkbox
                                  *ngIf="sf2.type==='boolean'"
                                  [(ngModel)]="group2[sf2.name]"
                                ></ion-checkbox>
                                <textarea
                                  *ngIf="sf2.type==='textarea' && sf2.count===true"
                                  class="form-control"
                                  rows="5"
                                  [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                  [(ngModel)]="group2[sf2.name]"
                                ></textarea>
                                <input
                                  *ngIf="sf2.type==='text'"
                                  class="form-control"
                                  type="text"
                                  [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                  [(ngModel)]="group2[sf2.name]"
                                />
                                <input
                                  *ngIf="sf2.type==='date'"
                                  class="form-control"
                                  type="datetime-local"
                                  [placeholder]="getPlaceholderTranslation(sf2) || ''"
                                  [(ngModel)]="group2[sf2.name]"
                                />
                                <img
                                  *ngIf="sf2.type==='photo'"
                                  src="assets/img/camera.svg"
                                  (click)="pressCamera(group2, sf2)"
                                />
                                <ion-badge
                                  style="position: relative; top: -3vh"
                                  *ngIf="sf2.type==='photo'"
                                  >{{group2[sf2.name].length}}</ion-badge
                                >
                                <select
                                  *ngIf="sf2.type==='select'"
                                  class="form-control"
                                  [(ngModel)]="group2[sf2.name]"
                                >
                                  <option
                                    *ngFor="let selectOption of sf2.selectOptions"
                                    [ngValue]="selectOption"
                                  >
                                    {{selectOption}}
                                  </option>
                                </select>
                                <span
                                  *ngIf="sf2.type==='list' && sf2.name==='Lista de ingredientes'"
                                >
                                  <ion-row>
                                    <ion-col size="6">Ingrediente</ion-col>
                                    <ion-col size="6"
                                      >Quantidade (total)</ion-col
                                    >
                                  </ion-row>
                                  <ion-row
                                    *ngFor="let row of group2[sf2.name], let i = index"
                                  >
                                    <ion-col size="6"
                                      ><input
                                        class="form-control"
                                        [(ngModel)]="row.name"
                                        type="text"
                                    /></ion-col>
                                    <ion-col size="2"
                                      ><input
                                        class="form-control"
                                        [(ngModel)]="row.quantity"
                                        type="number"
                                    /></ion-col>
                                    <ion-col size="2">
                                      <select
                                        class="form-control"
                                        [(ngModel)]="row.unit"
                                      >
                                        <option [ngValue]="'kg'">kg</option>
                                        <option [ngValue]="'g'">g</option>
                                        <option [ngValue]="'Unidades'">
                                          Unidades
                                        </option>
                                        <option [ngValue]="'l'">l</option>
                                      </select></ion-col
                                    >
                                    <ion-col
                                      size="1"
                                      (click)="removeFromList(sf2, i)"
                                    >
                                      <ion-icon name="remove-circle"></ion-icon>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row>
                                    <ion-col size="8"></ion-col>
                                    <ion-col (click)="addToList(sf2)">
                                      <span style="float: right"
                                        >Adicionar novo
                                        <ion-icon
                                          name="add-circle"
                                        ></ion-icon></span
                                    ></ion-col>
                                  </ion-row>
                                </span>
                                <span *ngIf="sf2.type==='checklist'">
                                  <ion-item>
                                    <ion-select
                                      style="width: 100%; max-width: 100%"
                                      [multiple]="true"
                                      [(ngModel)]="group2[sf2.name]"
                                      interface="popover"
                                    >
                                      <ion-select-option
                                        *ngFor="let selectOption of sf2.checklistOptions"
                                        [value]="selectOption"
                                        >{{selectOption}}</ion-select-option
                                      >
                                    </ion-select>
                                  </ion-item>
                                </span>
                              </div>

                              <app-divider-input
                                *ngIf="sf2.type === 'divider'"
                                (click)="toggleHideGroupFields(sf2, i, responseField.subfields ?? [])"
                                [responseField]="sf2"
                              ></app-divider-input>
                            </span>
                          </span>
                        </span>
                        <app-add-button-component
                          (handleAdd)="addToGroup(sf, group[sf.name])"
                        ></app-add-button-component>
                      </div>
                    </span>
                  </span>
                </ion-card-content>
              </ion-card>

              <app-add-button-component
                (handleAdd)="addToGroup(responseField, response[responseField.name])"
              ></app-add-button-component>
            </div>
          </span>
        </span>
      </div>
      <div class="modalActions task">
        <div class="actionsLeft">
          <span
            *ngIf="type.type === 'oil' && storesService.store && storesService.store.modules.bluetoothOilReader"
            [style.color]="isConnected?'green':'red'"
            >CONNECTED</span
          >
          <!--<div>
            <img (click)='pressPrint()' *ngIf="task.type==='inProductApanha'" src='assets/img/print.svg'/>
          </div>-->
        </div>
        <div class="actionsRight">
          <button
            class="btn btn-ok"
            (click)="pressSubmit();"
            [disabled]="isClicked"
          >
            {{ (response['Tarefa terminada'] === false) ? 'Guardar' : 'OK' |
            translate }}
          </button>

          <button class="btn btn-cancel light-grey" (click)="close()">
            {{'CANCEL'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
