<ion-content padding>
  <select-input
    [responseField]="productExpirationTypeResponseField"
    [(value)]="product.expirationType"
    [getSelectOptions]="getProductExpirationTypes"
  ></select-input>
  <boolean-input *ngIf="product.secondaryExpirationHoursSameAsPrimaryResponseField"
    [responseField]="secondaryExpirationHoursSameAsPrimaryResponseField"
    [(value)]="product.secondaryExpirationHoursSameAsPrimaryResponseField"
  >
  </boolean-input>
  <number-input *ngIf="product.secondaryExpirationHours"
    [responseField]="secondaryExpirationHoursResponseField"
    [(value)]="product.secondaryExpirationHours"
  ></number-input>
  <number-input *ngIf="product.expirationHoursAfterOpening"
    [responseField]="expirationHoursAfterOpeningResponseField"
    [(value)]="product.expirationHoursAfterOpening"
  ></number-input>
  <number-input *ngIf="product.unfreezingTime"
    [responseField]="unfreezingTimeResponseField"
    [(value)]="product.unfreezingTime"
  ></number-input>
</ion-content>
