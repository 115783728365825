import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: 'app-select-custom-type-task-modal',
  templateUrl: './select-custom-type-task.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SelectCustomTypeTaskModal {

  type: any;
  creatableTaskTypes: any[];
  form: any;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {
    this.creatableTaskTypes = this.navParams.get('creatableTaskTypes');
    this.type = this.creatableTaskTypes[0];
    this.form = this.navParams.get('form');
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pressSubmit() {
    this.modalCtrl.dismiss(this.type);
  }

}
