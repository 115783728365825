<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Close</ion-button>
    </ion-buttons>
    <ion-title>{{ 'Detalhe do produto' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="custom-tabs">
    <button (click)="setSegment('info-detail')" [class.active]="segment === 'info-detail'">
      {{ 'Detalhes' | translate }}
    </button>
    <button (click)="setSegment('batches')" [class.active]="segment === 'batches'">
      {{ 'Lotes' | translate }}
    </button>
    <button (click)="setSegment('expiration')" [class.active]="segment === 'expiration'">
      {{ 'Validades' | translate }}
    </button>
    <button *ngIf="product?.hasTechnicalSheet" (click)="setSegment('technicalSheet')" [class.active]="segment === 'technicalSheet'">
      {{ 'Ficha Tecnica' | translate }}
    </button>
  </div>

  <ng-container *ngIf="segment === 'info-detail'">
    <app-product-info-detail></app-product-info-detail>
  </ng-container>
  <ng-container *ngIf="segment === 'batches'">
    <app-product-batches-tab></app-product-batches-tab>
  </ng-container>
  <ng-container *ngIf="segment === 'expiration'">
    <app-product-expiration-tab></app-product-expiration-tab>
  </ng-container>
  <ng-container *ngIf="segment === 'technicalSheet'">
    <app-technical-sheet-info-detail></app-technical-sheet-info-detail>
  </ng-container>
</ion-content>
