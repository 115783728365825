<div *ngFor="let obj of value; let i = index">
  <label>Equipamento #{{i+1}}</label>
  <ion-item>
    <ion-select
      style="width: 100%; max-width: 100%"
      [style.pointer-events]="''"
      [multiple]="true"
      [(ngModel)]="value[i]"
      [placeholder]=""
      interface="popover"
    >
    <ion-select-option 
    *ngFor="let selectOption of getSelectOptions(responseField)" 
    [value]="selectOption"
  >
    {{selectOption}}
  </ion-select-option>
    </ion-select>
  </ion-item>
</div>
