import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { FormTaskModal } from '../form-task-modal/form-task-modal';
import { JustificationModal } from '../justification-modal/justification-modal';
import { PromptModal } from '../../prompt/prompt.modal';
import { TranslateService } from '@ngx-translate/core';
import { CustomTaskService } from 'src/app/services/custom-task.service';
import { TasksService } from 'src/app/services/tasks.service';
import { ZonesService } from 'src/app/services/zones.service';
import { EquipmentsService } from 'src/app/services/equipments.service';
import { SensorsService } from 'src/app/services/sensors.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { FieldsService } from 'src/app/services/fields.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { FabricatedProduct, Product } from 'src/app/entities/product';
import { TaskType } from 'src/app/entities/tasks/custom-task-type';
import { translate } from 'src/utils/translate.utils';
import {
  TaskClosedCallback,
  Task,
  ResponseField,
  TaskResponse,
} from 'src/app/entities/tasks/task';
import { Device } from '@capacitor/device';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-custom-form-task',
  templateUrl: './form-task.html',
  styleUrls: ['./form-task.scss'],
})
export class FormTaskComponent implements OnInit {
  @Input() task!: Task;
  @Input() onTaskClosedSuccessfully!: TaskClosedCallback;
  type: TaskType | undefined;
  response!: TaskResponse;
  isClicked = false;
  product?: any;

  constructor(
    private customTaskService: CustomTaskService,
    private tasksService: TasksService,
    private element: ElementRef,
    private modalController: ModalController,
    public zonesService: ZonesService,
    public equipmentsService: EquipmentsService,
    public sensorsService: SensorsService,
    private customTaskTypeService: CustomTaskTypesService,
    public storesService: StoresService,
    private translator: TranslateService,
    private productsService: ProductsService,
    private fieldsService: FieldsService,
    private employeeService: EmployeeService,
    protected envService: EnvironmentService
  ) {}

  ngOnInit() {
    const type = this.customTaskTypeService.getCustomTaskType(this.task.type);
    if (type) {
      this.type = type;
      this.response = this.getResponseFromTaskType(this.type);
      if (!this.product) {
        this.getProduct(this.task);
      }
      if (type.metadata && type.metadata.responseFields) {
        this.type.metadata.responseFields.forEach((rf: any) =>
          this.autofillResponse(this.response, rf, this.task)
        );
      }
    }
  }

  getResponseFromTaskType(taskTypeObject: TaskType): TaskResponse {
    const response: TaskResponse = {
      picturesIds: [],
      non_conformity: '',
      causes: '',
      correction: '',
      commentary: '',
      ncCommentary: '',
      clientDate: 0,
      employeeId: '',
      isInConformity: true,
    };

    if (taskTypeObject.metadata && taskTypeObject.metadata.responseFields) {
      taskTypeObject.metadata.responseFields.forEach((rf: ResponseField) => {
        if (rf.type === 'boolean') {
          response[rf.name] = false;
        } else if (rf.type === 'date' && !rf.autofill) {
          if (rf.subtype === 'noHour') {
            response[rf.name] = moment().seconds(0).format('YYYY-MM-DD');
          } else {
            response[rf.name] = moment()
              .seconds(0)
              .format('YYYY-MM-DDTHH:mm:ss');
          }
        } else if (rf.type === 'list') {
          if (rf.name === 'Lista de ingredientes') {
            response[rf.name] = [{ name: '', quantity: 1, unit: 'kg' }];
          } else {
            response[rf.name] = [];
          }
        } else if (rf.type === 'checklist') {
          response[rf.name] = [];
        } else if (rf.type === 'group' && rf.subfields) {
          response[rf.name] = [this.autofillSubfields(rf.subfields)];
        } else {
          response[rf.name] = '';
        }
      });
    }

    if (this.task.pendingResponses && this.task.pendingResponses[0]) {
      const result = Object.assign(response, this.task.pendingResponses[0]);
      return result;
    }
    return response;
  }

  autofillSubfields(subfields: ResponseField[]): { [key: string]: any } {
    const response: { [key: string]: any } = {};
    subfields.forEach((sf: ResponseField) => {
      if (response[sf.name] || sf.autofill) return;
      if (sf.type === 'boolean') {
        response[sf.name] = false;
      } else if (sf.type === 'date') {
        response[sf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (sf.type === 'list') {
        response[sf.name] = [{ name: '', quantity: 1, unit: 'kg' }];
      } else if (sf.type === 'checklist') {
        response[sf.name] = [];
      } else if (sf.type === 'photo') {
        response[sf.name] = [];
      } else if (sf.type === 'group' && sf.subfields) {
        response[sf.name] = [this.autofillSubfields(sf.subfields)];
      } else {
        response[sf.name] = '';
      }
    });
    return response;
  }

  autofillResponse(response: any, responseField: any, task?: any) {
    const batch = task ? task.batch : null;
    if (task && task[responseField.name]) {
      response[responseField.name] = task[responseField.name];
    } else if (!response[responseField.name] || responseField.subfields) {
      if (
        responseField.autofill &&
        this.processCondition(responseField, response)
      ) {
        if (responseField.autofill === 'batchPreparationDate') {
          response[responseField.name] = batch.preparationDate;
        } else if (responseField.autofill === 'batchExpirationDate') {
          response[responseField.name] = batch.expirationDate;
        } else if (responseField.autofill === 'dlcBalcaoFT') {
          response[responseField.name] =
            this.product.technicalSheet[
              'Durabilidade de vida (Balcão tradicional)'
            ];
        } else if (responseField.autofill === 'precedingTaskLastCargoOfDayNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Última carga do dia'];
        } else if (responseField.autofill === 'dlcPrepackageFT') {
          response[responseField.name] = this.product.technicalSheet[''];
        } else if (responseField.autofill === 'precedingTaskVendor') {
          if (task && task.precedingTaskData && task.precedingTaskData.vendor)
            response[responseField.name] = task.precedingTaskData.vendor;
        } else if (responseField.autofill === 'precedingTaskProduct') {
          if (task && task.precedingTaskData && task.precedingTaskData.product)
            response[responseField.name] = task.precedingTaskData.product;
        } else if (responseField.autofill === 'lsPackagingMaterialFT') {
          response[responseField.name] =
            this.product.technicalSheet['Material de embalagem LS'];
        } else if (responseField.autofill === 'foodType') {
          response[responseField.name] =
            this.product.technicalSheet['Tipo de alimento'] ?? '';
        } else if (responseField.autofill === 'orderType') {
          response[responseField.name] =
            this.product.technicalSheet['Ordem'] ?? '';
        } else if (responseField.autofill === 'portionSize') {
          response[responseField.name] =
            this.product.technicalSheet['Gramas Aproximadas por porcao'] ?? '';
        } else if (responseField.autofill === 'consumerType') {
          response[responseField.name] =
            this.product.technicalSheet['Tipo de comensal'] ?? '';
        } else if (responseField.autofill === 'preparationTime') {
          response[responseField.name] =
            this.product.technicalSheet['Tempo aproximado de preparacao'] ?? '';
        } else if (responseField.autofill === 'cookingTechnique') {
          response[responseField.name] =
            this.product.technicalSheet['Tecnica culinaria principal'] ?? '';
        } else if (responseField.autofill === 'preparation') {
          response[responseField.name] =
            this.product.technicalSheet['Instruções de preparação'] ?? '';
        } else if (responseField.autofill === 'precedingTaskParcel') {
          if (task && task.precedingTaskData && task.precedingTaskData.parcel)
            response[responseField.name] = task.precedingTaskData.parcel;
        } else if (responseField.autofill === 'precedingTaskBuyer') {
          if (task && task.precedingTaskData && task.precedingTaskData.buyer)
            response[responseField.name] = task.precedingTaskData.buyer;
        } else if (responseField.autofill === 'precedingTaskVariety') {
          if (task && task.precedingTaskData && task.precedingTaskData.variety)
            response[responseField.name] = task.precedingTaskData.variety;
        } else if (responseField.autofill === 'precedingTaskHarvester') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.harvester
          )
            response[responseField.name] = task.precedingTaskData.harvester;
        } else if (responseField.autofill === 'precedingTaskOperator') {
          if (task && task.precedingTaskData && task.precedingTaskData.operator)
            response[responseField.name] = task.precedingTaskData.operator;
        } else if (responseField.autofill === 'precedingTaskTrailerPhoto') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.photoTrailer
          )
            response[responseField.name] = task.precedingTaskData.photoTrailer;
        } else if (responseField.autofill === 'precedingTaskLicensePlate') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.licensePlate
          )
            response[responseField.name] = task.precedingTaskData.licensePlate;
        } else if (responseField.autofill === 'precedingTaskProductType') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.productType
          )
            response[responseField.name] = task.precedingTaskData.productType;
        } else if (responseField.autofill === 'precedingTaskMethod') {
          if (task && task.precedingTaskData && task.precedingTaskData.method)
            response[responseField.name] = task.precedingTaskData.method;
        } else if (responseField.autofill === 'precedingTaskWeight') {
          if (task && task.precedingTaskData && task.precedingTaskData.weight)
            response[responseField.name] = task.precedingTaskData.weight;
        } else if (responseField.autofill === 'precedingTaskObservations') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.observations
          )
            response[responseField.name] = task.precedingTaskData.observations;
        } else if (responseField.autofill === 'precedingTaskData') {
          if (task && task.precedingTaskData && task.precedingTaskData.data) {
            response[responseField.name] = moment(
              task.precedingTaskData.data
            ).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else if (responseField.autofill === 'precedingTaskSetor') {
          if (task && task.precedingTaskData && task.precedingTaskData.setor) {
            response[responseField.name] = task.precedingTaskData.setor;
          }
        } else if (responseField.autofill === 'precedingTaskPraga') {
          if (task && task.precedingTaskData && task.precedingTaskData.praga) {
            response[responseField.name] = task.precedingTaskData.praga;
          }
        } else if (responseField.autofill === 'precedingTaskFotografia') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.fotografia
          ) {
            response[responseField.name] =
              task.precedingTaskData.fotografia || [];
          } else {
            response[responseField.name] = [];
          }
        } else if (responseField.autofill === 'precedingTaskProvidencias') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.providencias
          ) {
            response[responseField.name] = task.precedingTaskData.providencias;
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (task && task.precedingTaskData && task.precedingTaskData.email) {
            response[responseField.name] = task.precedingTaskData.email;
          }
        } else if (responseField.autofill === 'precedingTaskEnviar') {
          if (task && task.precedingTaskData && task.precedingTaskData.enviar) {
            response[responseField.name] = task.precedingTaskData.enviar;
          }
        } else if (responseField.autofill === 'precedingTaskCorrection') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.correctionType
          ) {
            response[responseField.name] =
              task.precedingTaskData.correctionType;
          }
        } else if (
          responseField.autofill === 'precedingTaskActionDescription'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.actionDescription
          ) {
            response[responseField.name] =
              task.precedingTaskData.actionDescription;
          }
        } else if (
          responseField.autofill === 'precedingTaskImplementationResponsible'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.implementationResponsible
          ) {
            response[responseField.name] =
              task.precedingTaskData.implementationResponsible;
          }
        } else if (responseField.autofill === 'precedingTaskObservacoes') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.observacoes
          ) {
            response[responseField.name] = task.precedingTaskData.observacoes;
          }
        } else if (responseField.autofill === 'precedingTaskTitulo') {
          if (task && task.precedingTaskData && task.precedingTaskData.titulo) {
            response[responseField.name] = task.precedingTaskData.titulo;
          }
        } else if (responseField.autofill === 'precedingTaskPedido') {
          if (task && task.precedingTaskData && task.precedingTaskData.pedido) {
            response[responseField.name] = task.precedingTaskData.pedido;
          }
        } else if (responseField.autofill === 'precedingTaskNomeEquipamento') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.nomeEquipamento
          ) {
            response[responseField.name] =
              task.precedingTaskData.nomeEquipamento;
          }
        } else if (responseField.autofill === 'precedingTaskTipoAvaria') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.tipoAvaria
          ) {
            response[responseField.name] = task.precedingTaskData.tipoAvaria;
          }
        } else if (responseField.autofill === 'precedingTaskDescricaoAvaria') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.descricaoAvaria
          ) {
            response[responseField.name] =
              task.precedingTaskData.descricaoAvaria;
          }
        } else if (responseField.autofill === 'precedingTaskDentroGarantia') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.dentroGarantia
          ) {
            response[responseField.name] =
              task.precedingTaskData.dentroGarantia;
          }
        } else if (responseField.autofill === 'precedingTaskNivelUrgencia') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.nivelUrgencia
          ) {
            response[responseField.name] = task.precedingTaskData.nivelUrgencia;
          }
        } else if (responseField.autofill === 'precedingTaskEmployee') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.employee
          ) {
            response[responseField.name] = task.precedingTaskData.employee;
          }
        } else if (responseField.autofill === 'precedingTaskFornecedor') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.fornecedor
          ) {
            response[responseField.name] = task.precedingTaskData.fornecedor;
          }
        } else if (responseField.autofill === 'precedingTaskComentarios') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.comentarios
          ) {
            response[responseField.name] = task.precedingTaskData.comentarios;
          }
        } else if (responseField.autofill === 'precedingTaskNomeLoja') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.storeName
          ) {
            response[responseField.name] = task.precedingTaskData.storeName;
          }
        } else if (responseField.autofill === 'precedingTaskPacotePro') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.pacotePro
          ) {
            response[responseField.name] = task.precedingTaskData.pacotePro;
          }
        } else if (responseField.autofill === 'precedingTaskPacotePremium') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.pacotePremium
          ) {
            response[responseField.name] = task.precedingTaskData.pacotePremium;
          }
        } else if (
          responseField.autofill === 'precedingTaskEnviarEmailTechguild'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.sendEmailTechguild
          ) {
            response[responseField.name] =
              task.precedingTaskData.sendEmailTechguild;
          }
        } else if (responseField.autofill === 'precedingTaskNomeDocumento') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.documentName
          ) {
            response[responseField.name] = task.precedingTaskData.documentName;
          }
        } else if (responseField.autofill === 'precedingTaskNumeroDocumento') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.documentNumber
          ) {
            response[responseField.name] =
              task.precedingTaskData.documentNumber;
          }
        } else if (responseField.autofill === 'precedingTaskDataValidade') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.dataEnd
          ) {
            response[responseField.name] = task.precedingTaskData.dataEnd;
          }
        } else if (responseField.autofill === 'precedingTaskComent') {
          if (task && task.precedingTaskData && task.precedingTaskData.coment) {
            response[responseField.name] = task.precedingTaskData.coment;
          }
        } else if (responseField.autofill === 'precedingTaskStartDate') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.startDate
          ) {
            response[responseField.name] = task.precedingTaskData.startDate;
          }
        } else if (responseField.autofill === 'precedingTaskStart') {
          if (task && task.precedingTaskData && task.precedingTaskData.start) {
            response[responseField.name] = task.precedingTaskData.start;
          }
        } else if (responseField.autofill === 'precedingTaskOption') {
          if (task && task.precedingTaskData && task.precedingTaskData.option) {
            response[responseField.name] = task.precedingTaskData.option;
          }
        } else if (
          responseField.autofill === 'precedingTaskGivePasswordToConsultant'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.givePasswordToConsultant
          ) {
            response[responseField.name] =
              task.precedingTaskData.givePasswordToConsultant;
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (task && task.precedingTaskData && task.precedingTaskData.email) {
            response[responseField.name] = task.precedingTaskData.email;
          }
        } else if (responseField.autofill === 'precedingTaskPassword') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.password
          ) {
            response[responseField.name] = task.precedingTaskData.password;
          }
        } else if (
          responseField.autofill === 'precedingTaskCreateExternalProfile'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.createExternalProfile
          ) {
            response[responseField.name] =
              task.precedingTaskData.createExternalProfile;
          }
        } else if (responseField.autofill === 'precedingTaskMachine') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.machine
          ) {
            response[responseField.name] = task.precedingTaskData.machine;
          }
        } else if (responseField.autofill === 'precedingTaskSupporteOther') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.supporteOther
          ) {
            response[responseField.name] = task.precedingTaskData.supporteOther;
          }
        } else if (responseField.autofill === 'precedingTaskDate') {
          if (task && task.precedingTaskData && task.precedingTaskData.date) {
            response[responseField.name] = task.precedingTaskData.date;
          }
        } else if (responseField.autofill === 'precedingTaskCompanie') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.companie
          ) {
            response[responseField.name] = task.precedingTaskData.companie;
          }
        } else if (responseField.autofill === 'precedingTaskStore') {
          if (task && task.precedingTaskData && task.precedingTaskData.store) {
            response[responseField.name] = task.precedingTaskData.store;
          }
        } else if (responseField.autofill === 'precedingTaskStoreEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.storeEmail
          ) {
            response[responseField.name] = task.precedingTaskData.storeEmail;
          }
        } else if (responseField.autofill === 'precedingTaskTitleOrder') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.titleOrder
          ) {
            response[responseField.name] = task.precedingTaskData.titleOrder;
          }
        } else if (responseField.autofill === 'precedingTaskDescription') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.description
          ) {
            response[responseField.name] = task.precedingTaskData.description;
          }
        } else if (responseField.autofill === 'precedingTaskSendEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.sendEmail
          ) {
            response[responseField.name] = task.precedingTaskData.sendEmail;
          }
        } else if (responseField.autofill === 'precedingTaskEmailConsultant') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.emailConsultant
          ) {
            response[responseField.name] =
              task.precedingTaskData.emailConsultant;
          }
        } else if (
          responseField.autofill === 'precedingTaskEmailCreatedPlatform'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.emailCreatedPlatform
          ) {
            response[responseField.name] =
              task.precedingTaskData.emailCreatedPlatform;
          }
        } else if (responseField.autofill === 'precedingTaskAccessOk') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.accessOk
          ) {
            response[responseField.name] = task.precedingTaskData.accessOk;
          }
        } else if (
          responseField.autofill === 'precedingTaskNewEmailCreatedPlatform'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.newEmailCreatedPlatform
          ) {
            response[responseField.name] =
              task.precedingTaskData.newEmailCreatedPlatform;
          }
        } else if (responseField.autofill === 'precedingTaskRegisterNumber') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.registerNumber
          ) {
            response[responseField.name] =
              task.precedingTaskData.registerNumber;
          }
        } else if (
          responseField.autofill === 'precedingTaskNewRegisterNumber'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.newRegisterNumber
          ) {
            response[responseField.name] =
              task.precedingTaskData.newRegisterNumber;
          }
        } else if (responseField.autofill === 'precedingTaskSumaryProject') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.sumaryProject
          ) {
            response[responseField.name] = task.precedingTaskData.sumaryProject;
          }
        } else if (responseField.autofill === 'precedingTaskNewSumaryProject') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.newSumaryProject
          ) {
            response[responseField.name] =
              task.precedingTaskData.newSumaryProject;
          }
        } else if (
          responseField.autofill === 'precedingTaskNewVersionSumaryProject'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.newVersionSumaryProject
          ) {
            response[responseField.name] =
              task.precedingTaskData.newVersionSumaryProject;
          }
        } else if (responseField.autofill === 'precedingTaskAclaraciones') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.aclaraciones
          ) {
            response[responseField.name] = task.precedingTaskData.aclaraciones;
          }
        } else if (responseField.autofill === 'precedingTaskValidation') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.validation
          ) {
            response[responseField.name] = task.precedingTaskData.validation;
          }
        } else if (responseField.autofill === 'precedingTaskTextAprover') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.textAprover
          ) {
            response[responseField.name] = task.precedingTaskData.textAprover;
          }
        } else if (responseField.autofill === 'precedingTaskSelectFolder') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.selectFolder
          ) {
            response[responseField.name] = task.precedingTaskData.selectFolder;
          }
        } else if (responseField.autofill === 'precedingTaskResponsible') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.responsible
          ) {
            response[responseField.name] = task.precedingTaskData.responsible;
          }
        } else if (responseField.autofill === 'precedingTaskStateDebt') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.stateDebt
          ) {
            response[responseField.name] = task.precedingTaskData.stateDebt;
          }
        } else if (responseField.autofill === 'precedingTaskDebtsToState') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.debtsToState
          ) {
            response[responseField.name] = task.precedingTaskData.debtsToState;
          }
        } else if (responseField.autofill === 'precedingTaskHasStateDebts') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.hasStateDebts
          ) {
            response[responseField.name] = task.precedingTaskData.hasStateDebts;
          }
        } else if (responseField.autofill === 'precedingTaskIsEligibility') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.isEligibility
          ) {
            response[responseField.name] = task.precedingTaskData.isEligibility;
          }
        } else if (
          responseField.autofill === 'precedingTaskEnquadramentoProjeto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.enquadramentoProjeto
          ) {
            response[responseField.name] =
              task.precedingTaskData.enquadramentoProjeto;
          }
        } else if (responseField.autofill === 'precedingTaskSumario') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.sumario
          ) {
            response[responseField.name] = task.precedingTaskData.sumario;
          }
        } else if (
          responseField.autofill === 'precedingTaskDescricaoAcoesInvestimentos'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.descricaoAcoesInvestimentos
          ) {
            response[responseField.name] =
              task.precedingTaskData.descricaoAcoesInvestimentos;
          }
        } else if (
          responseField.autofill === 'precedingTaskContributoIgualdadeGenero'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.contributoIgualdadeGenero
          ) {
            response[responseField.name] =
              task.precedingTaskData.contributoIgualdadeGenero;
          }
        } else if (
          responseField.autofill === 'precedingTaskContributoTransicao'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.contributoTransicao
          ) {
            response[responseField.name] =
              task.precedingTaskData.contributoTransicao;
          }
        } else if (responseField.autofill === 'precedingTaskJustificacaoDnsh') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.justificacaoDnsh
          ) {
            response[responseField.name] =
              task.precedingTaskData.justificacaoDnsh;
          }
        } else if (
          responseField.autofill === 'precedingTaskCriteriosSelecaoRelevancia'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.criteriosSelecaoRelevancia
          ) {
            response[responseField.name] =
              task.precedingTaskData.criteriosSelecaoRelevancia;
          }
        } else if (
          responseField.autofill ===
          'precedingTaskCriteriosSelecaoImpactoDigital'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.criteriosSelecaoImpactoDigital
          ) {
            response[responseField.name] =
              task.precedingTaskData.criteriosSelecaoImpactoDigital;
          }
        } else if (
          responseField.autofill === 'precedingTaskTransicaoCapacitacaoDigital'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.transicaoCapacitacaoDigital
          ) {
            response[responseField.name] =
              task.precedingTaskData.transicaoCapacitacaoDigital;
          }
        } else if (
          responseField.autofill ===
          'precedingTaskCriteriosSelecaoCoerenciaTecnica'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.criteriosSelecaoCoerenciaTecnica
          ) {
            response[responseField.name] =
              task.precedingTaskData.criteriosSelecaoCoerenciaTecnica;
          }
        } else if (
          responseField.autofill === 'precedingTaskExcedeuValor50mil'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.excedeuValor50mil
          ) {
            response[responseField.name] =
              task.precedingTaskData.excedeuValor50mil;
          }
        } else if (
          responseField.autofill === 'precedingTaskValorTotalCandidatura'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.valorTotalCandidatura
          ) {
            response[responseField.name] =
              task.precedingTaskData.valorTotalCandidatura;
          }
        } else if (
          responseField.autofill === 'precedingTaskValorExcedidoNaoFinanciado'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.valorExcedidoNaoFinanciado
          ) {
            response[responseField.name] =
              task.precedingTaskData.valorExcedidoNaoFinanciado;
          }
        } else if (
          responseField.autofill === 'precedingTaskCaeNaoListadoAnexoI'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.caeNaoListadoAnexoI
          ) {
            response[responseField.name] =
              task.precedingTaskData.caeNaoListadoAnexoI;
          }
        } else if (
          responseField.autofill ===
          'precedingTaskFinanciamentoDuplicadoImpacto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.financiamentoDuplicadoImpacto
          ) {
            response[responseField.name] =
              task.precedingTaskData.financiamentoDuplicadoImpacto;
          }
        } else if (responseField.autofill === 'precedingTaskConfirmData') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.confirmData
          ) {
            response[responseField.name] = task.precedingTaskData.confirmData;
          }
        } else if (responseField.autofill === 'precedingTaskNomeOrganizacao') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.nomeOrganizacao
          ) {
            response[responseField.name] =
              task.precedingTaskData.nomeOrganizacao;
          }
        } else if (responseField.autofill === 'precedingTaskAcessoConsultor') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.acessoConsultor
          ) {
            response[responseField.name] =
              task.precedingTaskData.acessoConsultor;
          }
        } else if (responseField.autofill === 'precedingTaskIsAssociatedo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.isAssociated
          ) {
            response[responseField.name] = task.precedingTaskData.isAssociated;
          }
        } else if (
          responseField.autofill === 'precedingTaskNumeroOrganizacao'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.numeroOrganizacao
          ) {
            response[responseField.name] =
              task.precedingTaskData.numeroOrganizacao;
          }
        } else if (responseField.autofill === 'precedingTaskNome') {
          if (task && task.precedingTaskData && task.precedingTaskData.nome) {
            response[responseField.name] = task.precedingTaskData.nome;
          }
        } else if (responseField.autofill === 'precedingTaskTelemovel') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.telemovel
          ) {
            response[responseField.name] = task.precedingTaskData.telemovel;
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (task && task.precedingTaskData && task.precedingTaskData.email) {
            response[responseField.name] = task.precedingTaskData.email;
          }
        } else if (responseField.autofill === 'precedingTaskCargo') {
          if (task && task.precedingTaskData && task.precedingTaskData.cargo) {
            response[responseField.name] = task.precedingTaskData.cargo;
          }
        } else if (
          responseField.autofill === 'precedingTaskBreveResumoEntidade'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.breveResumoEntidade
          ) {
            response[responseField.name] =
              task.precedingTaskData.breveResumoEntidade;
          }
        } else if (
          responseField.autofill === 'precedingTaskSelecaoProdutosCatalogo'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.selecaoProdutosCatalogo
          ) {
            response[responseField.name] =
              task.precedingTaskData.selecaoProdutosCatalogo;
          }
        } else if (
          responseField.autofill === 'precedingTaskPequenaExplicacaoProjeto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData.pequenaExplicacaoProjeto
          ) {
            response[responseField.name] =
              task.precedingTaskData.pequenaExplicacaoProjeto;
          }
        } else if (responseField.autofill === 'precedingTaskSelectTitle') {
          response[responseField.name] = task?.['title']; // Acessa o título da tarefa anterior
        } else if (responseField.autofill === 'TODAY') {
          response[responseField.name] = moment().format('YYYY-MM-DD');
        } else if (responseField.autofill === 'TODAY') {
          response[responseField.name] = moment().format('YYYY-MM-DD');
        } else if (responseField.autofill === 'NONE') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'waybillCounterNF') {
          response[
            responseField.name
          ] = `${this.getHerdadeOrLagarCountersString()}`;
        } else if (responseField.autofill === 'destinationNF') {
          response[responseField.name] = `${
            this.fieldsService
              .getClosestHerdadeOrLagarField(this.task.formId)
              .buyers.map((b: any) => `${b.fullName} (${b.companyFullName})`)[0]
          }`;
        } else if (responseField.autofill === 'societyNF') {
          response[responseField.name] = `${
            this.fieldsService.getClosestHerdadeOrLagarField(this.task.formId)
              .society
          }`;
        } else if (responseField.autofill === 'herdadeNF') {
          response[responseField.name] = `${
            this.fieldsService.getClosestHerdadeOrLagarField(this.task.formId)
              .fullName
          }`;
        } else if (responseField.autofill === 'varietyNF') {
        } else if (responseField.autofill === 'waybillEmitterNF') {
          response[
            responseField.name
          ] = `${this.employeeService.getEmployeeName()}`;
        } else if (responseField.autofill === 'problemName') {
          response[responseField.name] = task.problemName;
        } else if (responseField.autofill === 'problemDate') {
          response[responseField.name] = task.problemDate;
        } else if (responseField.autofill === 'problemDescription') {
          response[responseField.name] = task.problemDescription;
        } else if (responseField.autofill === 'precedingTaskQuantityNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Quantidade aproximada (kg)'];
        } else if (responseField.autofill === 'precedingTaskDirtyQuantityNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Quantidade (peso sujo em kg)'];
        } else if (responseField.autofill === 'precedingTaskWaybillNumberNF') {
          response[responseField.name] = task.precedingTaskResponse['Nº Guia'];
        } else if (responseField.autofill === 'precedingTaskExitDateNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Data e Hora de Saída'];
        } else if (responseField.autofill === 'precedingTaskDestinationNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Destinatário'];
        } else if (responseField.autofill === 'precedingTaskGroupNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Grupo de Colheita'];
        } else if (responseField.autofill === 'precedingTaskParcelNF') {
          response[responseField.name] = task.precedingTaskResponse['Parcela'];
        } else if (responseField.autofill === 'precedingTaskProductNF') {
          response[responseField.name] = task.precedingTaskResponse['Produto'];
        } else if (responseField.autofill === 'precedingTaskVarietyNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Variedade'];
        } else if (responseField.autofill === 'precedingTaskQualityNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Qualidade'];
        } else if (
          responseField.autofill === 'precedingTaskSanitariumStateNF'
        ) {
          response[responseField.name] =
            task.precedingTaskResponse['Estado Fitossanitário'];
        } else if (
          responseField.autofill ===
          'precedingTaskSanitariumStateConfirmationNF'
        ) {
          response[responseField.name] =
            task.precedingTaskResponse[
              'Confirmação de Estado Fitossanitário Não Conforme'
            ];
        } else if (responseField.autofill === 'precedingTaskObservationsNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Observações Transporte'];
        } else if (responseField.autofill === 'precedingTaskWaybillEmitterNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Emissor da Guia'];
        } else if (
          responseField.autofill === 'precedingTaskDirtyTicketNumberNF'
        ) {
          response[responseField.name] =
            task.precedingTaskResponse['Nº Ticket sujo'];
        } else if (responseField.autofill === 'precedingTaskCleanTicketsNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Tickets Limpos'];
        } else if (responseField.autofill === 'precedingTaskHarvestTypeNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Tipo de Colheita'];
        } else if (
          responseField.autofill === 'precedingTaskExitTemperatureNF'
        ) {
          response[responseField.name] =
            task.precedingTaskResponse['Temperatura de Saída'];
        } else if (responseField.autofill === 'precedingTaskSocietyNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Sociedade'];
        } else if (responseField.autofill === 'precedingTaskHerdadeNF') {
          response[responseField.name] = task.precedingTaskResponse['Herdade'];
        } else if (responseField.autofill === 'precedingTaskDriverNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Motorista'];
        } else if (responseField.autofill === 'precedingTaskLicensePlateNF') {
          response[responseField.name] =
            task.precedingTaskResponse['Matrícula Transporte'];
        } else if (
          responseField.autofill === 'precedingTaskReboqueLicensePlateNF'
        ) {
          response[responseField.name] =
            task.precedingTaskResponse['Matrícula Reboque'];
        } else if (responseField.autofill === 'expirationDate') {
          response[responseField.name] = task.expirationDate || task.groupEx;
        } else if (responseField.autofill === 'groupedBatchesLots') {
          response[responseField.name] = task.groupedBatchesLots;
        } else {
          response[responseField.name] = responseField.autofill;
        }
      }
      if (this.product && this.product.technicalSheet) {
        if (responseField.name === 'O produto vai ser conservado no quente?') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Para embalar?') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (
          responseField.name === 'Durabilidade de vida (Balcão tradicional)'
        ) {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (responseField.name === 'DLC para pré-embalamento') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Tempo de preparação') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (responseField.name === 'Material de embalagem') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (responseField.type === 'list') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        } else if (responseField.type === 'checklist') {
          response[responseField.name] =
            this.product.technicalSheet[responseField.name];
        }
      } else if (
        responseField.autofill === 'precedingTaskInternalIdentification'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.internalIdentification
        ) {
          response[responseField.name] =
            task.precedingTaskData.internalIdentification;
        }
      } else if (responseField.autofill === 'precedingTaskMeatCutDesignation') {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.meatCutDesignation
        ) {
          response[responseField.name] =
            task.precedingTaskData.meatCutDesignation;
        }
      } else if (responseField.autofill === 'precedingTaskAnimalBreed') {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.animalBreed
        ) {
          response[responseField.name] = task.precedingTaskData.animalBreed;
        }
      } else if (
        responseField.autofill === 'precedingTaskBeefTraceabilityNumber'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.beefTraceabilityNumber
        ) {
          response[responseField.name] =
            task.precedingTaskData.beefTraceabilityNumber;
        }
      } else if (responseField.autofill === 'precedingTaskSupplier') {
        if (task && task.precedingTaskData && task.precedingTaskData.supplier) {
          response[responseField.name] = task.precedingTaskData.supplier;
        }
      } else if (
        responseField.autofill === 'precedingTaskSupplierBatchNumber'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.supplierBatchNumber
        ) {
          response[responseField.name] =
            task.precedingTaskData.supplierBatchNumber;
        }
      } else if (responseField.autofill === 'precedingTaskSlaughterDate') {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.slaughterData
        ) {
          response[responseField.name] = task.precedingTaskData.slaughterData;
        }
      } else if (
        responseField.autofill === 'precedingTaskReceiptDateAtSalePoint'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.receiptDateAtSalePoint
        ) {
          response[responseField.name] =
            task.precedingTaskData.receiptDateAtSalePoint;
        }
      } else if (
        responseField.autofill === 'precedingTaskShippingGuideInvoiceNumber'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.shippingGuideInvoiceNumber
        ) {
          response[responseField.name] =
            task.precedingTaskData.shippingGuideInvoiceNumber;
        }
      } else if (
        responseField.autofill === 'precedingTaskMeatTemperatureUponReceipt'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.meatTemperatureUponReceipt
        ) {
          response[responseField.name] =
            task.precedingTaskData.meatTemperatureUponReceipt;
        }
      } else if (responseField.autofill === 'precedingTaskFishCutDesignation') {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.fishCutDesignation
        ) {
          response[responseField.name] =
            task.precedingTaskData.fishCutDesignation;
        }
      } else if (responseField.autofill === 'precedingTaskProductSmoked') {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.productSmoked
        ) {
          response[responseField.name] = task.precedingTaskData.productSmoked;
        }
      } else if (responseField.autofill === 'precedingTaskProductionMethod') {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.productionMethod
        ) {
          response[responseField.name] =
            task.precedingTaskData.productionMethod;
        }
      } else if (
        responseField.autofill === 'precedingTaskInitialWeightSmoking'
      ) {
        if (
          task &&
          task.precedingTaskData &&
          task.precedingTaskData.initialWeightSmoking
        ) {
          response[responseField.name] =
            task.precedingTaskData.initialWeightSmoking;
        }
      } else if (responseField.autofill === 'precedingTaskCategory') {
        if (task && task.precedingTaskData && task.precedingTaskData.category) {
          response[responseField.name] = task.precedingTaskData.category;
        }
      } else if (responseField.autofill === 'precedingTaskMachine') {
        if (task && task.precedingTaskData && task.precedingTaskData.machine) {
          response[responseField.name] = task.precedingTaskData.machine;
        }
      } else if (responseField.autofill === 'precedingTaskSupplier') {
        if (task && task.precedingTaskData && task.precedingTaskData.supplier) {
          response[responseField.name] = task.precedingTaskData.supplier;
        }
      } else if (responseField.autofill === 'precedingTaskEmail') {
        if (task && task.precedingTaskData && task.precedingTaskData.email) {
          response[responseField.name] = task.precedingTaskData.email;
        }
      }

      if (batch && responseField.type === 'batch') {
        response[responseField.name] = batch;
      }
      if (this.product && responseField.type === 'product') {
        response[responseField.name] = this.product;
      }
      if (responseField.autofill === 'NOW') {
        if (responseField.type === 'date') {
          response[responseField.name] = moment()
            .seconds(0)
            .format('YYYY-MM-DD');
        } else if (responseField.type === 'datetime') {
          response[responseField.name] = moment()
            .seconds(0)
            .format('YYYY-MM-DDTHH:mm:ss');
        } else {
          response[responseField.name] = moment().format('HH:mm');
        }
      }
      if (responseField.autofill === 'NOFILL') {
        response[responseField.name] = '';
      }
    }
  }

  handleAutofill(responseField: ResponseField, task: any) {
    // Add your logic to handle different autofill values here
    return responseField.autofill;
  }

  handleTechnicalSheetAutofill(responseField: ResponseField) {
    if (this.isFabricatedProduct(this.product)) {
      return this.product.technicalSheet[responseField.name];
    }
    return null; // or some default value
  }

  getClosestHerdadeField(formId: string) {
    return this.fieldsService.getClosestHerdadeOrLagarField(formId);
  }
  getMachineName(machineId: string): string {
    const machine = this.sensorsService.getMachineById(machineId);
    return machine ? machine.machine : '';
  }

  isFabricatedProduct(
    product: Product | undefined
  ): product is FabricatedProduct {
    return (
      !!product &&
      'hasTechnicalSheet' in product &&
      (product as FabricatedProduct).hasTechnicalSheet === true
    );
  }

  handleNowAutofill(responseField: ResponseField) {
    if (responseField.type === 'date') {
      return moment().seconds(0).format('YYYY-MM-DD');
    } else if (responseField.type === 'datetime') {
      return moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return moment().format('HH:mm');
    }
  }

  private getProduct(task: any) {
    this.product = task
      ? task.productId
        ? this.productsService.getProductById(task.productId)
        : undefined
      : undefined;
  }

  processCondition(field: ResponseField, response?: { [key: string]: any }) {
    response = response || this.response;
    if (
      field.condition === undefined ||
      field.condition === null ||
      field.condition === ''
    )
      return true;
    const conditions = field.condition.split(', ');
    if (conditions.length === 0) return true;
    return (
      conditions.filter((c) => {
        if (c.includes('===')) {
          const sides = c.split('===');
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response[leftSide] === rightSide;
        } else if (c.includes('!==')) {
          const sides = c.split('!==');
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response[leftSide] !== rightSide;
        } else if (c === 'expirationDate') {
          if (
            response['Data de validade'] &&
            moment(response['Data de validade']).isBefore(
              moment().add(4, 'days')
            )
          ) {
            return true;
          }
          return false;
        }
        if (c[0] === '!') {
          const condition = c.substring(1, c.length);
          return !response[condition];
        }
        return !!response[c];
      }).length === conditions.length
    );
  }

  async pressContent() {
    if (Date.now() < this.task.startDate) {
      const isBeforeStartDateStop = await this.showBeforeStartDatePrompt();
      if (isBeforeStartDateStop) return;
    }

    const modal = await this.modalController.create({
      component: FormTaskModal,
      componentProps: {
        task: this.task,
        taskTypeObject: this.type,
        response: this.response,
      },
    });

    await modal.present();
    const result = await modal.onDidDismiss();

    const data = result.data;
    if (data) {
      this.customTaskService
        .respondContent(
          this.task,
          this.response,
          this.onTaskClosedSuccessfully || undefined
        )
        .subscribe({
          next: (result: any) => {
            console.log('Next');
            console.log(result);
          },
          error: (err) => {
            console.log('Error');
            console.log(err);
          },
          complete: () => {
            console.log('I am completed');
          },
        });
    }
  }

  async showBeforeStartDatePrompt(): Promise<boolean> {
    const promptBeforeStartDate = await this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: await translate(
          'BEFORE_SCHEDULE_ANSWERING_TITLE',
          this.translator
        ),
        text: await translate(
          'BEFORE_SCHEDULE_ANSWERING_TEXT',
          this.translator
        ),
      },
    });
    const beforeStartDatePromise = new Promise<boolean>((resolve) => {
      promptBeforeStartDate.onDidDismiss().then((data) => resolve(data.data));
    });
    await promptBeforeStartDate.present();
    return !(await beforeStartDatePromise);
  }

  async pressYes() {
    if (this.isClicked) return;
    if (Date.now() < this.task.startDate) {
      const isBeforeStartDateStop = await this.showBeforeStartDatePrompt();
      if (isBeforeStartDateStop) return;
    }
    this.isClicked = true;
    this.task.isLoading = true;

    try {
      this.customTaskService
        .respondContent(
          this.task,
          this.response,
          this.onTaskClosedSuccessfully || undefined
        )
        .subscribe({
          next: (result: any) => {
            console.log('Next');
            console.log(result);
          },
          error: (err) => {
            console.log('Error');
            console.log(err);
          },
          complete: () => {
            console.log('I am completed');
          },
        });
    } catch (err) {
      console.log(err);
      if (this.onTaskClosedSuccessfully)
        this.onTaskClosedSuccessfully(this.task);
    } finally {
      this.task.isLoading = false;
      this.isClicked = false;
    }
  }

  async pressNo() {
    if (Date.now() < this.task.startDate) {
      const isBeforeStartDateStop = await this.showBeforeStartDatePrompt();
      if (isBeforeStartDateStop) return;
    }
    const nonConformityModal = await this.modalController.create({
      component: JustificationModal,
      componentProps: {
        task: this.task,
        response: this.response,
      },
    });
    await nonConformityModal.present();
    const data = await nonConformityModal.onDidDismiss();
    if (data.data) {
      if (this.onTaskClosedSuccessfully)
        this.onTaskClosedSuccessfully(this.task);
      this.task.isLoading = false;
    }
  }

  isSingleTemperatureType(): boolean {
    return /singleTemperature/.test(this.task.type);
  }

  isTaskLate() {
    return this.task.endDate < moment.now();
  }

  isLateTask(task: Task) {
    return task.endDate < moment.now();
  }

  getTitle() {
    if (this.type?.getTitle === 'type') {
      return this.type.name;
    }
    if (this.type?.getTitle === 'typeFailure') {
      return `${this.type.name} - ${this.response['Tipo da Avaria']}`;
    }
    if (this.type?.getTitle === 'documentName') {
      return `${this.type.name} - ${this.response['Nome documento']}`;
    }
    if (this.type?.getTitle === 'titleOrder') {
      return `${this.response['Titulo do Pedido']}`;
    }
    return this.type?.name || '';
  }

  getStates(): string | undefined {
    if (this.type && typeof this.type.getStates === 'string') {
      return `${this.type.getStates}`;
    }
    return undefined; // Explicitly return undefined if the condition is not met
  }

  get supplierDisplayName(): string {
    if (Array.isArray(this.product?.supplierName)) {
      // Se supplierName for um array, use response['Fornecedor']
      return this.response['Fornecedor'] || '';
    } else {
      // Se supplierName não for um array, use product?.supplierName ou product?.supplier?.name
      return this.product?.supplierName || this.product?.supplier?.name || '';
    }
  }

  getSubtitle() {
    if (this.type?.getSubtitle === 'product') {
      return `${this.response['Designação do produto'] || ''}`;
    }
    if (this.type?.getSubtitle === 'productFamily') {
      return `${this.response['Natureza do produto controlado'] || ''}${
        this.response['Nome do fornecedor']
          ? ' - ' + this.response['Nome do fornecedor']
          : ''
      }`;
    }
    if (this.type?.getSubtitle === 'supplier') {
      return `${this.response['Fornecedor'] || ''}`;
    }
    if (this.type?.getSubtitle === 'nonProgrammed') {
      return `${this.response['Tipo']}`;
    }
    if (this.type?.getSubtitle === 'NF') {
      return `${this.storesService.store.code} - ${this.response['Nº Guia']} - ${this.response['Matrícula Transporte']}`;
    }
    if (this.type?.getSubtitle === 'guideNumber') {
      return `Guia ${this.response['Nº Guia de Remessa']}`;
    }
    if (this.type?.getSubtitle === 'nameMachine') {
      return `${this.response['Nome da Máquina']}`;
    }
    if (this.type?.getSubtitle === 'pestSector') {
      return `${this.response['Setor']}`;
    }
    if (this.type?.getSubtitle === 'equipmentName') {
      return `${this.response['Nome do equipamento']}`;
    }
    if (this.type?.getSubtitle === 'typeFailure') {
      return `${this.response['Tipo da Avaria']}`;
    }
    if (this.type?.getSubtitle === 'titleRequest') {
      return `${this.response['Título do pedido']}`;
    }
    if (this.type?.getSubtitle === 'titleProduct') {
      return `${this.response['Produtos']}`;
    }
    if (this.type?.getSubtitle === 'nameEquipment') {
      return `${this.response['Equipamento']}`;
    }
    if (this.type?.getSubtitle === 'productName') {
      return `${this.response['Produto']}`;
    }
    if (this.type?.getSubtitle === 'nameNewStore') {
      return `${this.response['Nome da Loja']}`;
    }
    if (this.type?.getSubtitle === 'nameStore') {
      return `${this.response['Loja']}`;
    }
    return '';
  }

  getReceptionFamilies(task: any, response: TaskResponse) {
    if (task.type !== '2itmGoodsReceptionBasePerGuide') return '';
    const includedFamilies = [];
    if (response) {
      if (response['Lacticínios']) includedFamilies.push('Lacticínios');
      if (response['Ultra-congelados'])
        includedFamilies.push('Ultra-congelados');
      if (response['Charcutaria']) includedFamilies.push('Charcutaria');
      if (response['Aves']) includedFamilies.push('Aves');
      if (response['Peixe fresco']) includedFamilies.push('Peixe fresco');
      if (response['Frutas e legumes'])
        includedFamilies.push('Frutas e legumes');
      if (response['Mercearia']) includedFamilies.push('Mercearia');
      if (response['Carnes']) includedFamilies.push('Carnes');
      if (response['Novilho']) includedFamilies.push('Novilho');
      if (response['Queijo']) includedFamilies.push('Queijo');
      if (response['Líquida']) includedFamilies.push('Líquida');
      if (response['MG']) includedFamilies.push('MG');
      if (response['DPH']) includedFamilies.push('DPH');
      if (response['IV Gama']) includedFamilies.push('IV Gama');
      if (response['Pastelaria industrial'])
        includedFamilies.push('Pastelaria industrial');
      if (response['Bacalhau']) includedFamilies.push('Bacalhau');
      if (response['Pão']) includedFamilies.push('Pão');
      if (response['Pastelaria tradicional'])
        includedFamilies.push('Pastelaria tradicional');
      if (response['Pronto a comer / take-away'])
        includedFamilies.push('Pronto a comer / take-away');
      if (response['Consumiveis loja'])
        includedFamilies.push('Consumiveis loja');
    }
    return includedFamilies.join(', ');
  }

  hasResponseFields(): boolean {
    return !!this.type?.metadata?.responseFields?.length;
  }

  async getHerdadeOrLagarCountersString() {
    const field = this.fieldsService.getClosestHerdadeOrLagarField(
      this.task.formId
    );
    if (field.waybillCounters) {
      const deviceInfo = await Device.getId();
      const deviceUUID = deviceInfo.identifier;
      let idx = field.waybillCounters.findIndex(
        (e: any) => e.dId === deviceUUID
      );
      if (idx === -1) {
        idx = field.waybillCounters.length;
        return `${idx + 1}${parseInt('1').toLocaleString('en-US', {
          minimumIntegerDigits: 4,
          useGrouping: false,
        })}`;
      }
      return `${idx + 1}${parseInt(
        `${field.waybillCounters[idx].counter + 1}`
      ).toLocaleString('en-US', {
        minimumIntegerDigits: 4,
        useGrouping: false,
      })}`;
    } else {
      return '10001';
    }
  }

  getSocietyNF() {
    return (
      this.task.precedingTaskSociety ||
      this.storesService.store.company.fullName ||
      (this.response ? this.response['Sociedade'] : '')
    );
  }

  machineNotIntermarcheOrIsIntermarcheAndTypeYesNoOrSingleTemperature() {
    return (
      !this.envService.isMachineIntermarche() ||
      (this.envService.isMachineIntermarche() &&
        (this.task.type === 'yesNo' || this.task.type === 'singleTemperature'))
    );
  }
}
