import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoginCredentials } from '../entities/login-credentials';
import {
  AuthenticationInfo,
  StoreAuthInfo,
  UserInfo,
} from '../entities/authentication-info';
import { StoreLoginCredentials } from '../entities/store-login-credentials';
import { OfflineService } from './offline.service';
import { Device } from '@capacitor/device';
import { SyncingService } from './syncing';
import { JSON_HEADERS } from '../../utils/headers';
import { Link } from '../../utils/link';
import { AUTHENTICATION, STORE_AUTH } from '../../utils/paths';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _authInfo: AuthenticationInfo | null = null;
  private _storeAuthInfo: StoreAuthInfo | null = null;
  private storeCode: string | null = null;

  private authState = new BehaviorSubject<boolean>(false);
  authState$ = this.authState.asObservable();

  constructor(
    private http: HttpClient,
    private offlineService: OfflineService,
    protected envService: EnvironmentService
  ) {}

  authStore(
    companyName: string,
    storeCode: string,
    password: string
  ): Observable<StoreAuthInfo> {
    const link: Link = new Link(STORE_AUTH, JSON_HEADERS);
    const body: StoreLoginCredentials = {
      companyName,
      storeCode,
      password,
    };
    if (this.offlineService.isOffline) {
      return throwError(() => new Error('offline'));
    }

    if (!link.target) {
      return throwError(() => new Error('Invalid URL'));
    }

    return this.http
      .post(link.target, body, {
        headers: link.headers,
        responseType: 'arraybuffer', // Expecting ArrayBuffer response
      })
      .pipe(
        map((response: ArrayBuffer) => {
          // Manually parse the ArrayBuffer to JSON
          const textDecoder = new TextDecoder();
          const jsonResponse = textDecoder.decode(new Uint8Array(response));
          const parsedResponse: StoreAuthInfo = JSON.parse(jsonResponse);

          if (parsedResponse.success) {
            this._storeAuthInfo = parsedResponse;
            localStorage.setItem(
              '_storeAuthInfo',
              JSON.stringify(this._storeAuthInfo)
            );
            return this._storeAuthInfo;
          } else {
            throw new Error('INVALID CREDENTIALS');
          }
        }),
        catchError((error) =>
          throwError(() => new Error(error.message || 'Server error'))
        )
      );
  }

  async authenticate(
    username: string,
    password: string
  ): Promise<Observable<AuthenticationInfo>> {
    const link: Link = new Link(AUTHENTICATION, JSON_HEADERS);
    const deviceId = await Device.getId();
    const body: LoginCredentials = {
      username,
      password,
      deviceUUID: deviceId.identifier,
    };

    if (this.offlineService.isOffline) {
      console.error('Offline error');
      return throwError(() => new Error('offline'));
    }

    if (!link.target) {
      console.error('Invalid URL error');
      return throwError(() => new Error('Invalid URL'));
    }

    // Ensure headers are set correctly and not null
    let headers = link.headers;
    return this.http
      .post<AuthenticationInfo>(link.target, body, { headers })
      .pipe(
        map((response) => {
          const authResponse = response as AuthenticationInfo;
          if (authResponse.success) {
            this._authInfo = authResponse;
            localStorage.setItem('_authInfo', JSON.stringify(this._authInfo));
            if (authResponse.token) {
              localStorage.setItem('authToken', authResponse.token); // Store the token
            } else {
              console.error('Token is undefined');
            }
            this.authState.next(true);
            return this._authInfo;
          } else if (
            !authResponse.success &&
            authResponse.message === 'DEVICE_UNAUTHORIZED'
          ) {
            console.error('Device unauthorized error');
            throw new Error('DEVICE_UNAUTHORIZED');
          } else {
            console.error('Invalid credentials error');
            throw new Error('INVALID CREDENTIALS');
          }
        }),
        catchError((error) => {
          console.error('Server error:', error);
          return throwError(() => new Error(error.message || 'Server error'));
        })
      );
  }

  logout() {
    this._authInfo = null;
    localStorage.removeItem('authToken');
    localStorage.removeItem('userCredentials');
    sessionStorage.clear();
    this.authState.next(false);
  }

  isAuthenticated(): boolean {
    if (this.envService.isProduction()) {
      if (!this._authInfo) {
        return false;
      }
      return this._authInfo.success;
    } else {
      if (this._authInfo === null) {
        const authInfo: string | null = localStorage.getItem('_authInfo');
        if (authInfo) this._authInfo = JSON.parse(authInfo);
      }
      return this._authInfo !== null && this._authInfo.success;
    }
  }

  isStoreAuthenticated(): boolean {
    if (this._storeAuthInfo === null) {
      const storeAuthInfoString: string | null =
        localStorage.getItem('_storeAuthInfo');
      if (storeAuthInfoString)
        this._storeAuthInfo = JSON.parse(storeAuthInfoString);
    }
    return this._storeAuthInfo !== null;
  }

  getAuthToken(): string {
    if (this.isAuthenticated()) {
      return this._authInfo!.token!;
    }
    throw new Error('UNAUTHENTICATED');
  }

  getAuthInfo(): AuthenticationInfo {
    if (this.isAuthenticated()) {
      return this._authInfo!;
    }
    throw new Error('UNAUTHENTICATED');
  }

  getUserInfo(): UserInfo | null {
    return this._authInfo && this._authInfo.user ? this._authInfo.user : null;
  }

  getAuthStoreInfo(): StoreAuthInfo | null {
    return this._storeAuthInfo;
  }

  getStoreUsers(): UserInfo[] {
    return this._storeAuthInfo ? this._storeAuthInfo.users : [];
  }

  getStoreCode(): string | null {
    return this.storeCode;
  }

  setStore(storeCode: string) {
    this.storeCode = storeCode;
  }

  getStoreUsersUsername(): string[] {
    return this._storeAuthInfo
      ? this._storeAuthInfo.users.map((u: UserInfo) => u.username)
      : [];
  }

  getUserByName(uName: string): UserInfo | undefined {
    return this._storeAuthInfo
      ? this._storeAuthInfo.users.find((u: UserInfo) => u.username === uName)
      : undefined;
  }

  getCurrentUserId(): string | null {
    // Supondo que `user` é um objeto do tipo `UserInfo` contendo o `id` do usuário atual.
    return this._authInfo && this._authInfo.user ? this._authInfo.user.id : null;
  }
  

}
