// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, catchError, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { ProductsService } from 'src/app/services/products.service';
import {
  productContainsExpirationDate,
  productExpirationDateAlreadyProcessed,
} from 'src/app/entities/product';
import { arrayAddDistinct } from 'src/utils/array.utils';

@Injectable({
  providedIn: 'root',
})
export class RegisterExpirationDate2 implements IMyTaskHandler {
  name = 'register-expiration-date2';

  constructor(
    private authenticationService: AuthenticationService,
    private productsService: ProductsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (!data['Produto já não existe']) {
      const productId = task.productId;
      if (typeof productId === 'string') {
        const product = this.productsService.getProductById(productId);

        if (!product) {
          console.log('Product not found');
          return of(null);
        } else {
          if (!data['Lote']) {
            throw new Error('Lote data is missing');
          }

          if (
            !productContainsExpirationDate(product, data['Lote'].expirationDate)
          ) {
            if (
              !productExpirationDateAlreadyProcessed(
                product,
                data['Lote'].expirationDate
              )
            ) {
              product.nextExpirationDates = arrayAddDistinct(
                product.nextExpirationDates,
                data['Lote'].expirationDate
              );

              return this.productsService
                .newProduct(product, this.authenticationService.getAuthInfo())
                .pipe(
                  catchError((err) => {
                    console.log(err);
                    return of(null);
                  })
                );
            }
          }
        }
      }
    }
    return of(null);
  }
}
