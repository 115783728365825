<ion-list>
  <div style="float: inline-end; padding-right: 3%; padding-top: 2%">
    <img src="assets/img/close.svg" (click)="close()" />
  </div>
  <ion-list-header class="list-header"
    >{{envService.getMachineDisplay() }}
  </ion-list-header>

  <ion-item class="version-item"
    >{{'VERSION' | translate }}
    {{envService.getVersion()}}{{envService.getMachine()}}</ion-item
  >
  <button
    ion-item
    class="menu-item"
    (click)="switchToReports()"
    *ngIf="authenticationService.isAuthenticated() && storesService?.store?.id !== 'clku0roe800003q74ta64e1gz' && !(storesService?.store?.code.includes('Quiz') && envService.isMachineGreenselection()) && !envService.isMachineEFN()"
  >
    {{'REPORTS' | translate }}
  </button>
  <button
    ion-item
    class="menu-item"
    (click)="switchToDashboard()"
    *ngIf="authenticationService.isAuthenticated() && storesService?.store?.id !== 'clku0roe800003q74ta64e1gz' && !(storesService?.store?.code.includes('Quiz') && envService.isMachineGreenselection()) && !envService.isMachineEFN()"
  >
    {{'DASHBOARD' | translate }}
  </button>
  <button
    ion-item
    class="menu-item"
    (click)="switchToManuals()"
    *ngIf="authenticationService.isAuthenticated()"
  >
    {{'DOCUMENTS' | translate }}
  </button>
  <button
    ion-item
    class="menu-item"
    (click)="showDeveloperNotes()"
    *ngIf="authenticationService.isAuthenticated() && !envService.isMachineEFN()"
  >
    {{'Notas do Desenvolvedor' | translate }}
  </button>
  <button
    ion-item
    class="menu-item"
    (click)="showSummary()"
    *ngIf="authenticationService.isAuthenticated() && (envService.isMachineIbersol() || envService.isMachineAWS())"
  >
    {{'Produtos expirados e a expirar no dia' | translate }}
  </button>
  <button ion-item class="menu-item" (click)="showPrinterConfig()" *ngIf="envService.isMachineIbersol()">
    {{'PRINTER_CONFIG' | translate }}
  </button>
  <button ion-item class="menu-item" (click)="exit()">
    {{'MENU_LOGOUT' | translate }}
  </button>
  <button ion-item class="menu-item" (click)="close()">
    {{'Fechar menu' | translate }}
  </button>
  <ion-item class="flag-container">
    <span
      class="flag-icon flag-icon-pt country-flag"
      (click)="translate.use('pt')"
    ></span>
    <span
      class="flag-icon flag-icon-gb country-flag"
      (click)="translate.use('en')"
    ></span>
    <span
      class="flag-icon flag-icon-es country-flag"
      (click)="translate.use('es')"
    ></span>
  </ion-item>
</ion-list>
