<ion-header>
  <ion-toolbar>
    <ion-button
      *ngIf="!form?.creatableTaskTypes"
      (click)="goBack()"
      color="medium"
    >
      <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
      Voltar
    </ion-button>

    <div
      style="
        height: 50px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
      *ngIf="form && form.creatableTaskTypes"
    >
      <!-- Left side: Voltar button -->
      <ion-button (click)="goBack()" color="medium">
        <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
        Voltar
      </ion-button>

      <!-- Right side: Plus icon and text -->
      <div
        style="display: flex; align-items: center; padding-right: 3px"
        (click)="pressNewRegister()"
      >
        <span style="margin-left: 5px; text-align: right">
          {{ this.form.creatableTaskTypesLabel ?
          this.form.creatableTaskTypesLabel : ("Adicionar nova tarefa" |
          translate) }} {{ this.form.allowsBarcodeUse ? " / pesquisa rápida" :
          "" }}
        </span>

        <img src="assets/img/plus.svg" class="fa-btn newTaskButton" />
      </div></div
  ></ion-toolbar>
</ion-header>
<ion-content>
  <!-- Div container para o botão que aparecerá em ambos os casos: com ou sem creatableTaskTypes -->

  <div style="display: flex">
    <app-field-button
      *ngIf="this.form && this.form.allowsBarcodeUse"
      (click)="pressBarcodeScanner()"
      [title]="'Pesquisa Cód. Barras' | translate"
      [buttonStyle]="{imageUrl: 'barcode.svg'}"
    ></app-field-button>
    <app-field-button
      *ngIf="this.form && this.form.allowsBarcodeUse"
      (click)="pressCommonlyUsedProducts()"
      [title]="'Produtos mais usados' | translate"
      [buttonStyle]="{imageUrl: 'product_control.svg'}"
    ></app-field-button>
  </div>
  <ion-searchbar
    [(ngModel)]="searchTerm"
    placeholder="{{'SEARCH'|translate}}"
    [formControl]="searchControl"
    (ionInput)="onSearchInput()"
  ></ion-searchbar>

  <div *ngIf="searching" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>

  <div
    *ngIf="searchTerm.length > 0"
    style="text-align: right; margin-right: 10px"
  >
    {{ tasks.length }} {{ correspondencesLabel }}
  </div>

  <!-- Replaced ion-infinite-scroll with a load more button -->
  <ion-list class="list-padding">
    <app-custom-form-task
      *ngFor="let task of tasks | slice:0:counter"
      [task]="task"
      [onTaskClosedSuccessfully]="onTaskClosedSuccessfully"
    ></app-custom-form-task>
  </ion-list>

  <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content> </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <h3
  (click)="pressNewRegister()"
  *ngIf="tasks.length === 0"
  style="padding-left: 3px; padding-right: 3px"
>
  {{ form && form.titleEmptyForm ? form.titleEmptyForm : ('EMPTY_FORM' | translate) }}
</h3>

</ion-content>
