import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ProductDetailService } from '../product-detail.service';

@Component({
  selector: 'app-technical-sheet-info-detail',
  templateUrl: './technical-sheet-info-detail.component.html',
  styleUrls: ['./technical-sheet-info-detail.component.scss'],

})
export class TechnicalSheetInfoDetailComponent implements OnInit {
  product: any;

  constructor(private productDetailService: ProductDetailService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.product = this.productDetailService.product;
    console.log( this.product)
  }
}