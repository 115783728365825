<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ product.name | translate }}</h3>
          <img src='assets/img/close.svg' (click)="close()" />
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <div class="form-group">
            <label>{{ 'Pesquisa:' | translate }}</label>
            <input class="form-control" type="text" [(ngModel)]="searchValue" [placeholder]="" [formControl]="searchControl" />
          </div>
          <ion-content style="height: 300px; overflow-y: scroll;">
            <ion-list>
              <ion-item *ngFor="let productInstance of searchResult" (click)="pressProductInstance(productInstance)">
                <ion-label>{{ productInstance.name }}</ion-label>
                <ion-icon name="arrow-dropright" slot="end"></ion-icon>
              </ion-item>
              <ion-item *ngIf="searchResult.length === 0">
                {{ 'Sem resultados' | translate }}
              </ion-item>
            </ion-list>
          </ion-content>
          
        </div>
        <div class="modalActions">
          <div class="actionsLeft">
          </div>
          <div class="actionsRight">
            <ion-button class="btn btn-ok" (click)="pressSubmit();">
              {{ 'OK' | translate }}
            </ion-button>
            <ion-button class="btn btn-cancel light-grey" (click)="close()">
              {{ 'CANCEL' | translate }}
            </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  