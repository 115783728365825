import { JPEG, JSON } from './content-types';

export const JSON_HEADERS = {
  'Content-Type': JSON,
  Accept:
    'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
};

export const JPEG_HEADERS = {
  'Content-Type': JPEG,
  Accept:
    'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
};

export const BLOB_HEADERS = {
  'Content-Type': 'blob',
};

export const ACCESS_TOKEN = 'Access-Token';
export const APP_VERSION = 'app-version';
