<div class="container alert scroll-content" #scrollContent>
  <div class="modalItem alert">
    <div class="modalHeader">
      <div class="modalTitle alert">
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription alert">
        <span class="alert-title">{{title}}</span>
        <p class="alert-text" *ngIf="text">{{text}}</p>
      </div>
      <div class="modalActions alert">
        <div class="actionsRight">
          <button *ngIf="hasYesButton" class="btn btn-ok" (click)="pressYes()">{{yesText || ('YES'|translate)}}</button>
          <button *ngIf="hasNoButton" class="btn btn-cancel light-grey" (click)="pressNo()">{{noText || ('NO'|translate)}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
