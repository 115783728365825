import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResponseField } from '../../../entities/tasks/task';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { translate } from 'src/utils/translate.utils';
import { SmartListModal } from '../../select-smart-modal/select-smart.modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'select-input',
  template: `
    <div *ngIf="responseField" class="form-group">
      <label>
        {{ getTranslation(responseField) }}
        {{ responseField.required ? '*' : '' }}
      </label>
      <ion-item>
        <ion-select
          #select
          style="width: 100%; max-width: 100%"
          [multiple]="responseField.multiple"
          [(ngModel)]="value"
          (ngModelChange)="onValueChange($event)"
          [formControl]="control"
          [placeholder]="getPlaceholderTranslation(responseField)"
          [interface]="interface"
          (click)="checkAndOpenSelect($event)"
          *ngIf="!responseField?.smart"
          [interfaceOptions]="getInterfaceOptions()"
        >
          <ion-select-option
            *ngFor="let selectOption of updateSelectOptions()"
            [value]="selectOption"
          >
            {{ selectOption }}
          </ion-select-option>
        </ion-select>
        <div *ngIf="responseField.smart" style="width: 100%; max-width: 100%">
          <ion-item (click)="openSmartListModal($event)" button>
            <ion-select
              class="custom-select-color"
              [multiple]="responseField.multiple"
              [(ngModel)]="value"
              (ngModelChange)="onValueChange($event)"
              placeholder="{{ getPlaceholderTranslation(responseField) }}"
              (click)="checkAndOpenSelect($event)"
              interface="none"
              style="pointer-events: none;"
            >
              <ion-select-option *ngFor="let option of updateSelectOptions()">
                {{ option }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </div>
      </ion-item>

      <button
        style="border-radius: 3px; overflow: hidden; height: 1.9rem; font-size: 1rem; font-weight: 500; text-transform: uppercase; color: #fff; background-color: #45a1c8; padding: 5px; margin-top: 2%;"
        button
        *ngIf="responseField.other"
        (click)="addOption()"
      >
        {{ 'Adicionar opções' | translate }}
      </button>
      <div *ngIf="control.touched">
        <div *ngFor="let key of control.errors | keyvalue">
          <p style="color: red" *ngIf="key.key === 'required'">
            Este campo é obrigatório
          </p>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  @ViewChild('select') select: any;

  @Input() responseField!: ResponseField;
  @Input() getPlaceholderTranslation: Function = (responseField: any) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.placeholder;
    } else if (lang === 'es') {
      return responseField.placeholderEs || responseField.placeholder;
    }
    return responseField.placeholder;
  };
  @Input() getTranslation: Function = (responseField: any) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.name;
    } else if (lang === 'es') {
      return responseField.nameEs || responseField.name;
    }
    return responseField.name;
  };
  @Input() getSelectOptions: Function = (responseField: any) => {
    return responseField.getSelectOptions() || [];
  };
  @Input() interface: string = this.responseField?.selectInterface || 'alert';

  @Input() value: string[] = [];

  @Output() valueChange = new EventEmitter<string[]>();

  @Output() saveNewOption = new EventEmitter<string>();
  @Input() userAddedOptions: string[] = [];

  @Input() response?: any;

  control!: FormControl;

  selectOptions: string[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    public alertController: AlertController,
    public toastController: ToastController,
    private translator: TranslateService,
    private modalController: ModalController // Injetar o ModalController
  ) {}

  ngOnInit(): void {
    this.initializeFormControl();
    this.updateSelectOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['responseField'] && !changes['responseField'].firstChange) {
      this.initializeFormControl();
      this.updateSelectOptions();
    }
  }

  initializeFormControl(): void {
    if (this.control) {
      if (this.responseField.locked) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      const validators = [];
      if (this.responseField && this.responseField.responseValidation) {
        if (this.responseField.responseValidation.required) {
          validators.push(Validators.required);
        }
      }
      this.control = new FormControl(
        { value: this.value, disabled: this.responseField.locked || false },
        Validators.compose(validators)
      );
    }

    if (this.responseField.multiple) {
      this.value = Array.isArray(this.value) ? this.value : [this.value];
    }
  }

  writeValue(value: any): void {
    this.value = value;
    if (this.control) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onValueChange(value: any) {
    if (this.responseField.multiple) {
      // Se for múltiplo, mantém como array
      this.value = Array.isArray(value) ? value : [value];
    } else {
      // Se não for múltiplo, converte para string (primeiro item, caso seja array)
      this.value = Array.isArray(value) ? value[0] : value;
    }

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  updateSelectOptions() {
    return [
      ...this.getSelectOptions(this.responseField, this.response),
      ...this.userAddedOptions,
    ];
  }

  async openSmartListModal(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    const modal = await this.modalController.create({
      component: SmartListModal,
      componentProps: {
        selectOptions: this.updateSelectOptions(),
        selectedValues: this.value,
        multiple: this.responseField.multiple, // Passa a propriedade multiple
        label: this.getTranslation(this.responseField),
      },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {
      if (this.responseField.multiple) {
        this.value = data.selectedValues.filter(
          (val: string) => val.trim() !== ''
        );
      } else {
        this.value = data.selectedValues[0]; // Se não for múltiplo, retorna o primeiro valor
      }

      this.onValueChange(this.value);
    }
  }

  async addOption() {
    const alert = this.alertController.create({
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: 'Nova opção',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (data) => {
            if (this.responseField.multiple) {
              this.value.push(data.name);
            } else {
              this.value = data.name;
            }
            this.onValueChange(this.value);
            this.selectOptions = [...this.selectOptions, data.name];
            this.saveNewOption.emit(data.name);
          },
        },
      ],
    });

    (await alert).present();
  }

  async checkAndOpenSelect(event: MouseEvent) {
    if (this.updateSelectOptions().length === 0) {
      event.preventDefault(); // Prevent the select from opening
      event.stopPropagation(); // Stop propagation to prevent triggering any parent click handlers
      const toast = this.makeNoSelectOptionsToast();
      (await toast).present();
    }
    // If there are options, the select will open normally
  }

  private async makeNoSelectOptionsToast() {
    const translatedMessage = await translate(
      'Sem opções para escolher.',
      this.translator
    );

    return this.toastController.create({
      message: translatedMessage,
      duration: 2000,
      position: 'top',
      buttons: [
        {
          text: await translate('Fechar', this.translator),
          role: 'cancel',
        },
      ],
    });
  }

  getInterfaceOptions() {
    return {
      header: this.responseField.selectHeader || this.responseField.name,
      subHeader: this.responseField.selectSubheader,
      message: this.responseField.selectMessage,
    };
  }
}
