import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { from, Observable, of } from 'rxjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class GeneratePdfPerformanceReviews implements IMyTaskHandler {
  name = 'generate-pdf-performance-reviews';

  constructor() {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    if (data['Tarefa terminada']) {
      const doc = new jsPDF();

      // Adicionar a imagem ao lado esquerdo do título com tamanho menor
      const imgPath = '/assets/img/mytask_logo.png'; // Caminho da imagem
      doc.addImage(imgPath, 'PNG', 8, 8, 15, 15); //

      // Cabeçalho do PDF
      doc.setFontSize(12); // Reduzindo o tamanho da fonte do título
      doc.text('Ficha SDG - Operador', 105, 20, { align: 'center' });

      // Linha divisória após o cabeçalho
      doc.setLineWidth(0.5);
      doc.line(10, 35, 200, 35); // Linha horizontal abaixo do cabeçalho

      // Ajustar a fonte para os campos menores
      doc.setFontSize(10);

      // Informações do lado esquerdo
      doc.text('Nome do Avaliado:', 15, 45);
      doc.text(data['Nome do Avaliado'] || '__________________', 60, 45); // Valor do Nome do Avaliado

      doc.text('Documento do Avaliado:', 15, 55);
      doc.text(data['Documento do Avaliado'] || '__________________', 60, 55); // Valor do Documento

      doc.text('Número do Documento:', 15, 65);
      doc.text(data['Número do Documento'] || '__________________', 60, 65); // Valor do Número do Documento

      doc.text('Nome do Avaliador:', 15, 75);
      doc.text(data['Nome do Avaliador'] || '__________________', 60, 75); // Valor do Nome do Avaliador

      // Informações do lado direito
      doc.text('Data de Antiguidade:', 110, 45);
      doc.text(data['Data de Antiguidade'] || '__________________', 150, 45); // Valor da Data de Antiguidade

      doc.text('Marca:', 110, 55);
      doc.text(data['Marca'] || '__________________', 150, 55); // Valor da Marca

      doc.text('Unidade:', 110, 65);
      doc.text(data['Unidade'] || '__________________', 150, 65); // Valor da Unidade

      doc.text('Total meses:', 110, 75);
      doc.text(data['Total meses'] || '__________________', 150, 75); // Valor do Total de Meses

      // Finalizar o PDF

      // Configurar os dados da tabela
      const tableData = [
        [
          'Valor',
          'Competência',
          'Indicadores Comportamentais',
          'Avaliação',
          'Média',
        ],
        // Seção A
        [
          {
            content: 'A) Somos sustentáveis e inclusivos.',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: 'Diversidade e inclusão',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Trata todos os clientes e colegas com justiça, respeitando a diversidade.',
          data['Pontuação A1'] || '-',
          {
            content: data['Média - Somos sustentáveis e inclusivos'] || '-',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          'Partilha ideias e escuta os contributos dos colegas, promovendo um bom ambiente de trabalho.',
          data['Pontuação A2'] || '-',
          '',
        ],
        [
          'Cumpre as políticas da empresa com integridade, mantendo uma conduta ética e honesta em todas as situações.',
          data['Pontuação A3'] || '-',
          '',
        ],
        [
          {
            content: 'Responsabilidade ambiental',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Cumpre as regras instituídas para minimizar o desperdício no seu posto de trabalho.',
          data['Pontuação A4'] || '-',
          '',
        ],
        [
          'Separa corretamente os materiais recicláveis e garante o encaminhamento adequado, seguindo as orientações de reciclagem.',
          data['Pontuação A5'] || '-',
          '',
        ],
        [
          'Cumpre as regras para a poupança de energia e água, sendo consciente do impacto ambiental das suas tarefas.',
          data['Pontuação A6'] || '-',
          '',
        ],
        // Seção B
        [
          {
            content: 'B) Valorizamos as nossas pessoas',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: 'Autoconfiança',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Executa a sua função de forma segura e determinada, demonstrando conhecimento e domínio das tarefas e sendo uma referência para os colegas.',
          data['Pontuação B1'] || '-',
          {
            content: data['Média - Valorizamos as nossas pessoas'] || '-',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          'Mantém a calma mesmo em situações de maior estresse e complexidade.',
          data['Pontuação B2'] || '-',
          '',
        ],
        [
          'Demonstra comportamentos adequados no dia a dia, incentivando os colegas a fazer bem, apoiando-os nas dificuldades.',
          data['Pontuação B3'] || '-',
          '',
        ],
        [
          {
            content: 'Desenvolvimento e valorização do talento',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Demonstra interesse pelo seu plano de formação e empenha-se em cumpri-lo.',
          data['Pontuação B4'] || '-',
          '',
        ],
        [
          'Aceita o feedback de forma positiva e ajusta o seu comportamento conforme necessário.',
          data['Pontuação B5'] || '-',
          '',
        ],
        [
          'Participa na formação dos colegas, com empenho e dedicação.',
          data['Pontuação B6'] || '-',
          '',
        ],
        // Seção C
        [
          {
            content: 'C) Existimos para o Cliente',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: 'Identificação com a Ibersol',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Cumpre as normas de apresentação e imagem tendo consciência da sua responsabilidade como embaixador da marca/grupo.',
          data['Pontuação C1'] || '-',
          {
            content: data['Média - Existimos para o cliente'] || '-',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          'Participa ativamente no cumprimento dos objetivos da unidade (ex Venda Sugestiva/ Cartão Continente/ GES/VOG).',
          data['Pontuação C2'] || '-',
          '',
        ],
        [
          'Ajuda os colegas sempre que necessário, oferecendo apoio quando percebe que alguém precisa.',
          data['Pontuação C3'] || '-',
          '',
        ],
        [
          {
            content: 'Orientação para o cliente',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Cumpre rigorosamente os standards de serviço ao cliente e de produto, assegurando a satisfação dos clientes.',
          data['Pontuação C4'] || '-',
          '',
        ],
        [
          'Demonstra sentido de urgência, sendo ágil, rápido e eficiente no atendimento ao cliente.',
          data['Pontuação C5'] || '-',
          '',
        ],
        [
          'Está atento ao feedback do cliente e comunica-o imediatamente ao responsável para assegurar a satisfação do cliente.',
          data['Pontuação C6'] || '-',
          '',
        ],
        // Seção D
        [
          {
            content: 'D) Gostamos de empreender e inovar somos criativos.',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: 'Iniciativa',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Demonstra interesse pelo desempenho da unidade colaborando para cumprir os objetivos e fazendo sugestões de melhoria.',
          data['Pontuação D1'] || '-',
          {
            content:
              data['Média - Gostamos de empreender e inovar somos criativos'] ||
              '-',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          'Adapta-se rapidamente às mudanças que lhe são propostas na organização e nos métodos de trabalho.',
          data['Pontuação D2'] || '-',
          '',
        ],
        [
          'Realiza as suas tarefas de forma proativa cumprindo com os seus objetivos e os da unidade.',
          data['Pontuação D3'] || '-',
          '',
        ],
        [
          {
            content: 'Criatividade',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Demonstra espírito crítico e sugere novas formas de realizar as suas tarefas habituais.',
          data['Pontuação D4'] || '-',
          '',
        ],
        [
          'Participa no desenvolvimento de soluções originais e criativas que permitam a resolução de problemas.',
          data['Pontuação D5'] || '-',
          '',
        ],
        [
          'Adapta o seu comportamento conforme o interlocutor seja cliente ou fornecedor ou colega ou chefia.',
          data['Pontuação D6'] || '-',
          '',
        ],
        // Seção E
        [
          {
            content: 'E) Promovemos a excelência e fazemos sempre melhor',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: 'Orientação para a Qualidade',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Cumpre rigorosamente os standards de produto e/ou serviço incluindo a higiene e limpeza do local de trabalho.',
          data['Pontuação E1'] || '-',
          {
            content:
              data['Média - Promovemos a excelência e fazemos sempre melhor'] ||
              '-',
            rowSpan: 6,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          'Assegura que cada tarefa é realizada com atenção ao detalhe garantindo um resultado final de qualidade.',
          data['Pontuação E2'] || '-',
          '',
        ],
        [
          'Cumpre os procedimentos de segurança e saúde no trabalho não se colocando em situações de risco (ex uso dos EPI).',
          data['Pontuação E3'] || '-',
          '',
        ],
        [
          {
            content: 'Competências Técnicas e Aprendizagem',
            rowSpan: 3,
            styles: { halign: 'center', valign: 'middle' },
          },
          'Mostra vontade de aprender e interesse em áreas além da sua responsabilidade.',
          data['Pontuação E4'] || '-',
          '',
        ],
        [
          'Demonstra segurança na utilização das ferramentas de trabalho colocando em prática os conhecimentos adquiridos.',
          data['Pontuação E5'] || '-',
          '',
        ],
        [
          'Demonstra capacidade de aprender e aplicar o que aprendeu nas tarefas do dia a dia.',
          data['Pontuação E6'] || '-',
          '',
        ],
        [
          '',
          '',
          '',
          {
            content: 'Resultado média:',
            styles: {
              halign: 'right',
              fillColor: [173, 216, 230],
              fontStyle: 'bold',
            },
          },
          {
            content: data['Resultado média:'] || '-',
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          '',
          '',
          '',
          {
            content: 'Resultado média ponderada:',
            styles: {
              halign: 'right',
              fillColor: [173, 216, 230],
              fontStyle: 'bold',
            },
          },
          {
            content: data['Resultado média ponderada:'] || '-',
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
      ];

      const y = 80;
      // Adicionar a tabela ao PDF
      autoTable(doc, {
        head: [tableData[0]], // Cabeçalhos
        body: tableData.slice(1), // Linhas da tabela
        startY: y + 10, // Onde começar após as informações gerais
        theme: 'grid', // Estilo da tabela
        headStyles: {
          fillColor: [69, 161, 200], // Cor do cabeçalho
          halign: 'center',
          valign: 'middle',
          lineWidth: 0.5, // Largura das linhas do cabeçalho
          lineColor: [0, 0, 0], // Cor das bordas do cabeçalho (preto)
        },
        bodyStyles: {
          fontSize: 10,
          halign: 'center',
          valign: 'middle',
          lineWidth: 0.5, // Largura das linhas do corpo
          lineColor: [0, 0, 0], // Cor das bordas do corpo (preto mais escuro)
        },
        styles: {
          fontSize: 10,
          halign: 'center',
          valign: 'middle',
          lineColor: [0, 0, 0], // Cor geral das bordas (mais escura)
          lineWidth: 0.5, // Espessura das linhas gerais
        },
      });

      // Configurar cor padrão para o texto no documento
      doc.setTextColor(0, 0, 0); // Define o texto para preto (RGB: 0, 0, 0)

      // Dados das ações de desenvolvimento
      const developmentActions = data['Ações de desenvolvimento'] || [];

      // Configuração para os cabeçalhos e linhas da tabela
      const actionsTableHead = [['Ação a concretizar', 'Data']];
      const actionsTableBody = developmentActions.map((action: any) => [
        action['Ação a concretizar'] || '',
        action['Data'] || '',
      ]);

      // Adicionar a tabela ao PDF
      autoTable(doc, {
        head: actionsTableHead,
        body: actionsTableBody,
        startY: (doc as any).lastAutoTable?.finalY + 20 || 40, // Posicionar após a última tabela
        theme: 'grid', // Estilo da tabela
        headStyles: {
          fillColor: [211, 211, 211], // Cinza claro no cabeçalho
          textColor: [0, 0, 0], // Texto do cabeçalho em preto
          halign: 'center', // Alinhar texto ao centro
          fontStyle: 'bold', // Negrito no cabeçalho
        },
        bodyStyles: {
          fontSize: 10, // Tamanho da fonte
          textColor: [0, 0, 0], // Texto do corpo da tabela em preto
          halign: 'center', // Alinhamento central para o texto
        },
        styles: {
          lineWidth: 0.5, // Espessura das linhas
          lineColor: [0, 0, 0], // Preto para bordas
        },
      });

      // Evitar redeclaração, reutilizar as variáveis existentes ou renomeá-las

      // Posição inicial da tabela de comentários
      let finalCommentsY = (doc as any).lastAutoTable?.finalY + 20;

      // Largura da página (já declarada, reutilizada com um novo nome para evitar conflitos)
      const pageWidthComments = doc.internal.pageSize.getWidth();

      // Configurar dimensões e margens
      const tableWidthComments = pageWidthComments - 20; // Margem de 10 de cada lado
      const startXComments = 10; // Margem esquerda
      const columnWidthComments = tableWidthComments / 2; // Largura de cada coluna
      const startYComments = finalCommentsY;

      // Adicionar título da tabela
      doc.setFillColor(211, 211, 211); // Cor de fundo cinza claro
      doc.rect(startXComments, startYComments, tableWidthComments, 10, 'F'); // Título
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'COMENTÁRIOS FINAIS',
        pageWidthComments / 2,
        startYComments + 7,
        {
          align: 'center',
        }
      );

      // Adicionar cabeçalhos das colunas
      doc.setFontSize(9);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'Avaliador',
        startXComments + columnWidthComments / 2,
        startYComments + 17,
        { align: 'center' }
      );
      doc.text(
        'Avaliado',
        startXComments + columnWidthComments + columnWidthComments / 2,
        startYComments + 17,
        { align: 'center' }
      );

      // Desenhar as linhas e bordas
      doc.setDrawColor(0, 0, 0); // Cor preta para bordas
      doc.rect(startXComments, startYComments + 10, columnWidthComments, 30); // Avaliador
      doc.rect(
        startXComments + columnWidthComments,
        startYComments + 10,
        columnWidthComments,
        30
      ); // Avaliado

      // Inserir comentários
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      doc.text(
        data['Avaliador'] || '',
        startXComments + 5,
        startYComments + 25,
        {
          maxWidth: columnWidthComments - 10,
        }
      );
      doc.text(
        data['Avaliado'] || '',
        startXComments + columnWidthComments + 5,
        startYComments + 25,
        { maxWidth: columnWidthComments - 10 }
      );

      // Ajustar posição inicial das assinaturas
      let signaturesY = startYComments + 50; // Ajustar para começar abaixo da tabela de comentários

      // Configuração para assinaturas
      const signatureWidth = 50; // Largura da linha de assinatura
      const boxWidth = 30; // Largura da caixa de data
      const boxHeight = 10; // Altura da caixa de data
      const signatureSpacing = 20; // Espaço entre cada seção de assinatura
      const lineToTextSpacing = 20; // Espaço entre a linha e o texto "Assinatura da..."

      // Verificar se as assinaturas ultrapassam o limite da página
      if (signaturesY + 60 > doc.internal.pageSize.getHeight()) {
        doc.addPage();
        signaturesY = 40;
      }

      // Posição inicial das assinaturas
      let signatureX =
        (pageWidthComments - (3 * signatureWidth + 2 * signatureSpacing)) / 2;

      // Assinatura do Avaliado
      doc.setFontSize(10);
      doc.text(
        'Assinatura do Avaliado',
        signatureX + signatureWidth / 2,
        signaturesY,
        { align: 'center' }
      );
      doc.line(
        signatureX,
        signaturesY + lineToTextSpacing,
        signatureX + signatureWidth,
        signaturesY + lineToTextSpacing
      );
      doc.text(
        'Data',
        signatureX + signatureWidth / 2,
        signaturesY + lineToTextSpacing + 20,
        { align: 'center' }
      );
      doc.rect(
        signatureX + signatureWidth / 2 - boxWidth / 2,
        signaturesY + lineToTextSpacing + 25,
        boxWidth,
        boxHeight
      );

      // Ajustar para a próxima assinatura
      signatureX += signatureWidth + signatureSpacing;

      // Assinatura do Avaliador
      doc.text(
        'Assinatura do Avaliador',
        signatureX + signatureWidth / 2,
        signaturesY,
        { align: 'center' }
      );
      doc.line(
        signatureX,
        signaturesY + lineToTextSpacing,
        signatureX + signatureWidth,
        signaturesY + lineToTextSpacing
      );
      doc.text(
        'Data',
        signatureX + signatureWidth / 2,
        signaturesY + lineToTextSpacing + 20,
        { align: 'center' }
      );
      doc.rect(
        signatureX + signatureWidth / 2 - boxWidth / 2,
        signaturesY + lineToTextSpacing + 25,
        boxWidth,
        boxHeight
      );

      // Ajustar para a próxima assinatura
      signatureX += signatureWidth + signatureSpacing;

      // Assinatura da Chefia do Avaliador
      doc.text(
        'Assinatura da Chefia do Avaliador',
        signatureX + signatureWidth / 2,
        signaturesY,
        { align: 'center' }
      );
      doc.line(
        signatureX,
        signaturesY + lineToTextSpacing,
        signatureX + signatureWidth,
        signaturesY + lineToTextSpacing
      );
      doc.text(
        'Data',
        signatureX + signatureWidth / 2,
        signaturesY + lineToTextSpacing + 20,
        { align: 'center' }
      );
      doc.rect(
        signatureX + signatureWidth / 2 - boxWidth / 2,
        signaturesY + lineToTextSpacing + 25,
        boxWidth,
        boxHeight
      );

      const fileName = 'avaliacao_desempenho.pdf';
      if (Capacitor.isNativePlatform()) {
        return from(this.savePdfOnDevice(doc, fileName));
      } else {
        this.savePdfOnWeb(doc, fileName);
        return of(true);
      }
    }
    return of(null);
  }

  async savePdfOnDevice(doc: jsPDF, fileName: string): Promise<void> {
    // Gerar o PDF como Blob
    const pdfBlob = doc.output('blob');

    // Converter Blob para Base64
    const base64Data = await this.convertBlobToBase64(pdfBlob);

    // Salvar o PDF no dispositivo
    await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
    });

    console.log('PDF salvo com sucesso no dispositivo!');

    // Obter o URI completo do arquivo
    const uriResult = await Filesystem.getUri({
      directory: Directory.Documents,
      path: fileName,
    });
    console.log('Caminho completo do arquivo:', uriResult.uri);
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Erro ao converter Blob para Base64.'));
      };
      reader.onloadend = () => {
        const base64data = (reader.result as string).split(',')[1];
        resolve(base64data);
      };
      reader.readAsDataURL(blob);
    });
  }

  savePdfOnWeb(doc: jsPDF, fileName: string): void {
    // Gerar o PDF como Blob
    const pdfBlob = doc.output('blob');

    // Criar um link para download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = fileName;
    link.click();

    console.log('PDF disponibilizado para download no navegador.');
  }
}
