import { Component } from '@angular/core';
import {
  ModalController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { translate } from '../../../../utils/translate.utils';
import { TranslateService } from '@ngx-translate/core';
import cuid from 'cuid';

import { Product, PRODUCT_EXPIRATION_TYPES } from '../../../entities/product';
import { ResponseField } from '../../../entities/tasks/task';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { AuthenticationService } from 'src/app/services/authentication';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-ibersol-product-create-modal',
  templateUrl: 'ibersol-product-create.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class IbersolProductCreateModal {
  product: Product;

  nameResponseField: ResponseField = {
    name: 'Designação',
    type: 'text',
    responseValidation: {
      required: true,
    },
    placeholder: 'Designação',
    required: true,
    autofill: 'NONE',
  };

  brandResponseField: ResponseField = {
    name: 'Marca',
    type: 'text',
    placeholder: 'Marca',
    required: false,
    autofill: 'NONE',
  };

  supplierResponseField: ResponseField = {
    name: 'Fornecedor',
    type: 'text',
    responseValidation: {
      required: true,
    },
    placeholder: 'Fornecedor',
    required: true,
    autofill: 'NONE',
  };

  internalBarcodeResponseField: ResponseField = {
    name: 'Cód. barras interno',
    type: 'text',
    placeholder: 'Cód. barras interno',
    autofill: 'NONE',
    required: false,
  };

  productExpirationTypeResponseField: ResponseField = {
    name: 'Tipo de expiração do produto',
    type: 'select',
    responseValidation: {
      required: true,
    },
    placeholder: 'Tipo de expiração do produto',
    required: true,
  };

  secondaryExpirationHoursSameAsPrimaryResponseField: ResponseField = {
    name: 'Validade secundária é a mesma que a primária',
    type: 'boolean',
    responseValidation: {},
    placeholder: 'Validade secundária é a mesma que a primária',
  };

  secondaryExpirationHoursResponseField: ResponseField = {
    name: 'Horas até expiração secundária',
    type: 'number',
    responseValidation: {},
    placeholder: 'Horas até expiração secundária',
  };

  expirationHoursAfterOpeningResponseField: ResponseField = {
    name: 'Horas até expiração após abertura',
    type: 'number',
    responseValidation: {},
    placeholder: 'Horas até expiração após abertura',
  };

  unfreezingTimeResponseField: ResponseField = {
    name: 'Tempo de descongelamento',
    type: 'number',
    responseValidation: {},
    placeholder: 'Tempo de descongelamento',
  };

  noExpirationResponseField: ResponseField = {
    name: 'Sem validade',
    type: 'boolean',
    responseValidation: {},
  };

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private storeService: StoresService,
    private toastController: ToastController,
    private translator: TranslateService,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService
  ) {
    this.product = {
      name: '',
      id: cuid(),
      barcode: {
        plu: '',
        itm8: '',
        value: '',
      },
      batches: [],
      createdInStore: true,
      supplierName: '',
      storeId: '',
      brand: '', // Initialize brand property with an empty string
      expirationType: '', // Initialize expirationType property with an empty string
      secondaryExpirationHoursSameAsPrimary: false, // Initialize secondaryExpirationHoursSameAsPrimary property with a boolean
      secondaryExpirationHours: 0, // Initialize secondaryExpirationHours property with a number
      expirationHoursAfterOpening: 0, // Initialize expirationHoursAfterOpening property with a number
      unfreezingTime: 0, // Initialize unfreezingTime property with a number
      noExpiration: false, // Initialize noExpiration property with a boolean
    };
  }

  get brand(): string {
    return this.product.brand ?? '';
  }

  set brand(value: string) {
    this.product.brand = value;
  }

  get itm8(): string {
    return this.product.barcode.itm8 ?? '';
  }

  set itm8(value: string) {
    this.product.barcode.itm8 = value;
  }

  get expirationType(): string[] {
    return this.product.expirationType ? [this.product.expirationType] : [];
  }

  set expirationType(value: string[]) {
    this.product.expirationType = value[0] ?? '';
  }

  get secondaryExpirationHoursSameAsPrimary(): boolean {
    return this.product.secondaryExpirationHoursSameAsPrimary ?? false;
  }

  set secondaryExpirationHoursSameAsPrimary(value: boolean) {
    this.product.secondaryExpirationHoursSameAsPrimary = value;
  }

  get noExpiration(): boolean {
    return this.product.noExpiration ?? false;
  }

  set noExpiration(value: boolean) {
    this.product.noExpiration = value;
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async pressCreateProduct() {
    let existingProduct = this.productsService.getProducts().find((p) => {
      return (
        p.barcode &&
        (p.barcode.itm8 === this.product.barcode.itm8 ||
          `00000${p.barcode.itm8}`.slice(-8) === this.product.barcode.itm8)
      );
    });

    if (!existingProduct) {
      try {
        const data = await firstValueFrom(this.productsService
          .newProduct(this.product, this.authenticationService.getAuthInfo())
          );
        if (data) {
          const toast = await this.makeProductCreatedSuccessfullyToast();
          toast.present();
        }
        this.modalCtrl.dismiss(this.product);
      } catch (err) {
        console.log(err);
      }
    } else {
      if (existingProduct.barcode.value === this.product.barcode.value) {
        const toast = await this.makeProductAlreadyExistsValueToast();
        toast.present();
      } else {
        const toast = await this.makeProductAlreadyExistsITM8Toast();
        toast.present();
      }
    }
  }

  getProductExpirationTypesOptions = () => {
    return PRODUCT_EXPIRATION_TYPES;
  };

  private async makeProductCreatedSuccessfullyToast() {
    const message = await translate('O produto foi criado corretamente.', this.translator);
    return this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      buttons: [{
        text: await translate('Fechar', this.translator),
        role: 'cancel'
      }]
    });
  }

  private async makeProductAlreadyExistsITM8Toast() {
    return this.toastController.create({
      message: 'Já existe um produto com este código de barras interno.',
      duration: 2000,
      position: 'top',
      buttons: [{
        text: await translate('Fechar', this.translator),
        role: 'cancel'
      }]
    });
  }

  private async makeProductAlreadyExistsValueToast() {
    return this.toastController.create({
      message: 'Já existe um produto com este EAN.',
      duration: 2000,
      position: 'top',
      buttons: [{
        text: await translate('Fechar', this.translator),
        role: 'cancel'
      }]
    });
  }
}
