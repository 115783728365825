<ion-content scroll="false">
  <ion-searchbar
    [(ngModel)]="searchTerm"
    placeholder="{{'SEARCH'|translate}}"
    [formControl]="searchControl"
    (ionInput)="onSearchInput()"
  ></ion-searchbar>
  <div *ngIf="searching" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>
  <div
    *ngIf="searchTerm.length > 0"
    style="text-align: right; margin-right: 10px"
  >
    {{products.length}} {{'correspondências' | translate}}
  </div>
  <ion-list-header class="tasks-list-header" no-margin>
    <ion-row class="tasks-row" align-items-center>
      <ion-col size="3" class="tasks-title-col"
        >{{'Designação'|translate}}</ion-col
      >
      <ion-col size="3" class="tasks-title-col">{{'Lote'|translate}}</ion-col>
      <ion-col size="4" class="tasks-title-col"
        >{{'Validade'|translate}}</ion-col
      >
      <ion-col size="2" class="tasks-title-col">{{'Qtd.'|translate}}</ion-col>
    </ion-row>
  </ion-list-header>
  <ion-list>
    <ion-item
      class="tasks-item"
      *ngFor="let product of products | slice:0:counter"
    >
      <ion-row
        [formGroup]="formGroups[product.id]"
        class="tasks-row inner-tasks-item"
        align-items-center
        no-margin
      >
        <ion-col
          size="3"
          class="tasks-col"
          (click)="openProductDetail(product)"
        >
          <div class="task-content">
            <span>
              <ion-chip color="light">Visualizar</ion-chip>
            </span>
            <span>{{ getProductDesignation(product) }}</span>
          </div></ion-col
        >
        <ion-col size="3" class="tasks-col">
          <ion-item>
            <ion-input
              [(ngModel)]="tagsInfo[product.id].batch.lot"
              placeholder="Lote"
              (change)="touch(product.id)"
              [formControl]="formGroups[product.id].controls.lot"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="4" class="tasks-col">
          <ion-item>
            <ion-input
              type="date"
              [(ngModel)]="tagsInfo[product.id].batch.expirationDate"
              placeholder="Validade Primária (YYYY-MM-DD)"
              (change)="touch(product.id)"
              [formControl]="formGroups[product.id].controls.expirationDate"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="2" class="tasks-col">
          <ion-item>
            <ion-input
              type="number"
              [(ngModel)]="tagsInfo[product.id].quantityTags"
              placeholder="Número de etiquetas"
              (change)="touch(product.id, 'quantity', tagsInfo[product.id].quantityTags)"
              [formControl]="formGroups[product.id].controls.quantityTags"
            ></ion-input>
          </ion-item>
        </ion-col>
        <p
          *ngIf="formGroups[product.id].errors?.atLeastOneFilled && formGroups[product.id].touched"
          style="color: red; background-color: white; margin-left: 5px; font-weight: bold;"
        >
          Pelo menos um dos campos "Validade" ou "Lote" precisa ser preenchido.
        </p>
        <p
          *ngIf="formGroups[product.id].controls.quantityTags.errors && formGroups[product.id].touched"
          style="color: red; background-color: white; margin-left: 5px; font-weight: bold;"
        >
          A quantidade de etiquetas deve ser 0 ou maior. 0 não imprimirá, mas criará o lote e rastreabilidade.
        </p></ion-row
      >
    </ion-item>
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content> </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start" style="order: 1">
      <ion-button
        icon-start
        (click)="printAll()"
        style="background-color: #45a1c8; color: white"
      >
        <ion-icon name="print"></ion-icon>
        {{'Imprimir todos'|translate}}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="showInfo()">
        <img src="assets/img/info.svg" style="width: 40px; height: 40px" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
