import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Link } from '../../utils/link';
import { JSON_HEADERS } from '../../utils/headers';
import { environment } from 'src/environments/environment';
import { TasksService } from './tasks.service';
import { AuthenticatedHttpService } from './authenticated-http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private cache = new Map<string, any>();
  private etag = '';

  constructor(
    private http: AuthenticatedHttpService,
    private tasksService: TasksService,
    protected envService: EnvironmentService
  ) {}

  getDashboardFromStore(companyId: string, storeId: string): Observable<any> {
    

    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/dashboard`,
      {
        ...JSON_HEADERS,
        "If-None-Match": this.etag,
      }
    );

    return this.http.get(link).pipe(
      tap((response: any) => {
        this.tasksService.isDashboardOutdated = false;
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const indicators = response.body as any[];
        indicators.forEach(i => {
          this.cache.set(i.type, i);
        });
      })

    );
  }

  getDashboard(): any[] {
    return Array.from(this.cache.values());
  }
}
