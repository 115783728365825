import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  concat,
  concatMap,
  defaultIfEmpty,
  endWith,
  from,
  last,
  mergeMap,
  of,
  tap,
  throwError,
} from 'rxjs';
import {
  ResponseField,
  Task,
  TaskClosedCallback,
  TaskResponse,
} from '../entities/tasks/task';
import { TaskType } from '../entities/tasks/custom-task-type';
import { CustomTaskTypesService } from './custom-task-types.service';
import { HandlersService } from '../handlers/handlers.service';
import moment from 'moment';
import { EmployeeService } from './employee.service';
import { FieldsService } from './fields.service';
import { ProductsService } from './products.service';
import { TasksService } from './tasks.service';
import { AuthenticationService } from './authentication';

export interface ExecutionState {
  submit: boolean;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CustomTaskService {
  constructor(
    private customTaskTypesService: CustomTaskTypesService,
    private handlersService: HandlersService,
    private employeeService: EmployeeService,
    private fieldsService: FieldsService,
    private productsService: ProductsService,
    private tasksService: TasksService,
    private authenticationService: AuthenticationService
  ) {}
  respondContent(
    task: Task,
    data: TaskResponse,
    onTaskClosedSuccessfully?: TaskClosedCallback
  ): Observable<any> {
    const state: ExecutionState = {
      submit: true,
    };

    data.clientDate = Math.floor(Date.now());

    const taskType = this.customTaskTypesService.getCustomTaskType(task.type);
    if (!taskType)
      return throwError(() => new Error('Task type does not exist'));

    const handlers = taskType?.metadata?.handlers || [];
    return from(handlers).pipe(
      defaultIfEmpty(null),
      concatMap((hName: string) => {
        if (hName === null) {
          return of('No handlers to process');
        }
        const handler = this.handlersService.getHandlerByName(hName);
        if (!handler) {
          return of(`Missing handler ${hName}`);
        }
        return handler
          .execute(task, data, state)
          .pipe(catchError((err: Error) => throwError(() => err)));
      }),
      last(),
      mergeMap(() => {
        if (state.submit) {
          return this.tasksService
            .submitTask(
              task,
              {
                ...data,
                isInConformity: true,
                clientDate: data.clientDate,
                employeeId: this.employeeService.getEmployeeId(),
              } as TaskResponse,
              this.authenticationService.getAuthInfo(),
              onTaskClosedSuccessfully
            )
            .pipe(catchError((err) => throwError(() => err)));
        } else {
          return of('notSubmit');
        }
      })
    );
  }

  respondNo(task: Task, data: TaskResponse) {
    const taskType = this.customTaskTypesService.getCustomTaskType(task.type);
    return this.tasksService.submitTask(
      task,
      {
        ...data,
        isInConformity: false,
        clientDate: Math.floor(Date.now()),
        employeeId: this.employeeService.getEmployeeId(),
      } as TaskResponse,
      this.authenticationService.getAuthInfo()
    );
  }

  getResponseFromTaskType(
    task: Task,
    type: TaskType,
    response?: TaskResponse | any
  ) {
    if (!response) response = {};
    type.metadata.responseFields.forEach((rf) => {
      this.autofillResponse(response, rf, task);
      if (response[rf.name] || rf.autofill) return;
      if (rf.type === 'boolean') {
        response[rf.name] = false;
      } else if (rf.type === 'date' && !rf.autofill) {
        response[rf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (rf.type === 'list') {
        if (rf.name === 'Lista de ingredientes')
          response[rf.name] = [
            {
              name: '',
              quantity: 1,
              unit: 'kg',
            },
          ];
        else response[rf.name] = [];
      } else if (rf.type === 'checklist') {
        response[rf.name] = [];
      } else if (rf.type === 'group') {
        response[rf.name] = [
          this.autofillSubfields(rf.subfields as ResponseField[]),
        ];
      } else {
        response[rf.name] = '';
      }
    });
    return response;
  }

  autofillResponse(
    response: TaskResponse,
    responseField: ResponseField,
    task?: Task
  ) {
    const product = task
      ? task.productId
        ? this.productsService.getProductById(task.productId)
        : null
      : null;
    const batch = task ? task.batch : null;
    if (task && task[responseField.name]) {
      response[responseField.name] = task[responseField.name];
    } else if (!response[responseField.name]) {
      if (
        responseField.autofill &&
        this.processCondition(responseField, response)
      ) {
        if (responseField.autofill === 'precedingTaskVendor') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['vendor']
          )
            response[responseField.name] = task.precedingTaskData['vendor'];
        } else if (responseField.autofill === 'precedingTaskProduct') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['product']
          )
            response[responseField.name] = task.precedingTaskData['product'];
        } else if (responseField.autofill === 'precedingTaskParcel') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['parcel']
          )
            response[responseField.name] = task.precedingTaskData['parcel'];
        } else if (responseField.autofill === 'precedingTaskBuyer') {
          if (task && task.precedingTaskData && task.precedingTaskData['buyer'])
            response[responseField.name] = task.precedingTaskData['buyer'];
        } else if (responseField.autofill === 'precedingTaskVariety') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['variety']
          )
            response[responseField.name] = task.precedingTaskData['variety'];
        } else if (responseField.autofill === 'precedingTaskHarvester') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['harvester']
          )
            response[responseField.name] = task.precedingTaskData['harvester'];
        } else if (responseField.autofill === 'precedingTaskOperator') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['operator']
          )
            response[responseField.name] = task.precedingTaskData['operator'];
        } else if (responseField.autofill === 'precedingTaskTrailerPhoto') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['photoTrailer']
          )
            response[responseField.name] =
              task.precedingTaskData['photoTrailer'];
        } else if (responseField.autofill === 'precedingTaskLicensePlate') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['licensePlate']
          )
            response[responseField.name] =
              task.precedingTaskData['licensePlate'];
        } else if (responseField.autofill === 'precedingTaskProductType') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['productType']
          )
            response[responseField.name] =
              task.precedingTaskData['productType'];
        } else if (responseField.autofill === 'precedingTaskMethod') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['method']
          )
            response[responseField.name] = task.precedingTaskData['method'];
        } else if (responseField.autofill === 'precedingTaskWeight') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['weight']
          )
            response[responseField.name] = task.precedingTaskData['weight'];
        } else if (responseField.autofill === 'precedingTaskObservations') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['observations']
          )
            response[responseField.name] =
              task.precedingTaskData['observations'];
        } else if (responseField.autofill === 'precedingTaskData') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['data']
          ) {
            response[responseField.name] = moment(
              task.precedingTaskData['data']
            ).format('YYYY-MM-DDTHH:mm:ss');
          }
        } else if (responseField.autofill === 'precedingTaskSetor') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['setor']
          ) {
            response[responseField.name] = task.precedingTaskData['setor'];
          }
        } else if (responseField.autofill === 'precedingTaskPraga') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['praga']
          ) {
            response[responseField.name] = task.precedingTaskData['praga'];
          }
        } else if (responseField.autofill === 'precedingTaskFotografia') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['fotografia']
          ) {
            response[responseField.name] =
              task.precedingTaskData['fotografia'] || [];
          } else {
            response[responseField.name] = [];
          }
        } else if (responseField.autofill === 'precedingTaskProvidencias') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['providencias']
          ) {
            response[responseField.name] =
              task.precedingTaskData['providencias'];
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['email']
          ) {
            response[responseField.name] = task.precedingTaskData['email'];
          }
        } else if (responseField.autofill === 'precedingTaskEnviar') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['enviar']
          ) {
            response[responseField.name] = task.precedingTaskData['enviar'];
          }
        } else if (responseField.autofill === 'precedingTaskObservacoes') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['observacoes']
          ) {
            response[responseField.name] =
              task.precedingTaskData['observacoes'];
          }
        } else if (responseField.autofill === 'precedingTaskTitulo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['titulo']
          ) {
            response[responseField.name] = task.precedingTaskData['titulo'];
          }
        } else if (responseField.autofill === 'precedingTaskPedido') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['pedido']
          ) {
            response[responseField.name] = task.precedingTaskData['pedido'];
          }
        } else if (responseField.autofill === 'precedingTaskNomeEquipamento') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['nomeEquipamento']
          ) {
            response[responseField.name] =
              task.precedingTaskData['nomeEquipamento'];
          }
        } else if (responseField.autofill === 'precedingTaskTipoAvaria') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['tipoAvaria']
          ) {
            response[responseField.name] = task.precedingTaskData['tipoAvaria'];
          }
        } else if (responseField.autofill === 'precedingTaskDescricaoAvaria') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['descricaoAvaria']
          ) {
            response[responseField.name] =
              task.precedingTaskData['descricaoAvaria'];
          }
        } else if (responseField.autofill === 'precedingTaskDentroGarantia') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['dentroGarantia']
          ) {
            response[responseField.name] =
              task.precedingTaskData['dentroGarantia'];
          }
        } else if (responseField.autofill === 'precedingTaskNivelUrgencia') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['nivelUrgencia']
          ) {
            response[responseField.name] =
              task.precedingTaskData['nivelUrgencia'];
          }
        } else if (responseField.autofill === 'precedingTaskEmployee') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['employee']
          ) {
            response[responseField.name] = task.precedingTaskData['employee'];
          }
        } else if (responseField.autofill === 'precedingTaskFornecedor') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['fornecedor']
          ) {
            response[responseField.name] = task.precedingTaskData['fornecedor'];
          }
        } else if (responseField.autofill === 'precedingTaskComentarios') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['comentarios']
          ) {
            response[responseField.name] =
              task.precedingTaskData['comentarios'];
          }
        } else if (responseField.autofill === 'precedingTaskNomeLoja') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['storeName']
          ) {
            response[responseField.name] = task.precedingTaskData['storeName'];
          }
        } else if (responseField.autofill === 'precedingTaskPacotePro') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['pacotePro']
          ) {
            response[responseField.name] = task.precedingTaskData['pacotePro'];
          }
        } else if (responseField.autofill === 'precedingTaskPacotePremium') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['pacotePremium']
          ) {
            response[responseField.name] =
              task.precedingTaskData['pacotePremium'];
          }
        } else if (
          responseField.autofill === 'precedingTaskEnviarEmailTechguild'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['sendEmailTechguild']
          ) {
            response[responseField.name] =
              task.precedingTaskData['sendEmailTechguild'];
          }
        } else if (responseField.autofill === 'precedingTaskNomeDocumento') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['documentName']
          ) {
            response[responseField.name] =
              task.precedingTaskData['documentName'];
          }
        } else if (responseField.autofill === 'precedingTaskNumeroDocumento') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['documentNumber']
          ) {
            response[responseField.name] =
              task.precedingTaskData['documentNumber'];
          }
        } else if (responseField.autofill === 'precedingTaskDataValidade') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['dataEnd']
          ) {
            response[responseField.name] = task.precedingTaskData['dataEnd'];
          }
        } else if (responseField.autofill === 'precedingTaskComent') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['coment']
          ) {
            response[responseField.name] = task.precedingTaskData['coment'];
          }
        } else if (
          responseField.autofill === 'precedingTaskInternalIdentification'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['internalIdentification']
          ) {
            response[responseField.name] =
              task.precedingTaskData['internalIdentification'];
          }
        } else if (
          responseField.autofill === 'precedingTaskMeatCutDesignation'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['meatCutDataDesignation']
          ) {
            response[responseField.name] = task['meatCutDataDesignation'];
          }
        } else if (responseField.autofill === 'precedingTaskanimalBreed') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['animalDataBreed']
          ) {
            response[responseField.name] = task['animalDataBreed'];
          }
        } else if (
          responseField.autofill === 'precedingTaskBeefTraceabilityNumber'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['beefTraceabilityNumber']
          ) {
            response[responseField.name] = task['beefTraceabilityNumber'];
          }
        } else if (
          responseField.autofill === 'precedingTaskSupplierBatchNumber'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['supplierBatchNumber']
          ) {
            response[responseField.name] = task['supplierBatchNumber'];
          }
        } else if (responseField.autofill === 'precedingTaskSlaughterDate') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['slaughterData']
          ) {
            response[responseField.name] = task['slaughterData'];
          }
        } else if (
          responseField.autofill === 'precedingTaskReceiptDateAtSalePoint'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['receiptDateAtSalePoint']
          ) {
            response[responseField.name] = task['receiptDateAtSalePoint'];
          }
        } else if (
          responseField.autofill === 'precedingTaskShippingGuideInvoiceNumber'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['shippingGuideInvoiceNumber']
          ) {
            response[responseField.name] = task['shippingGuideInvoiceNumber'];
          }
        } else if (
          responseField.autofill === 'precedingTaskMeatTemperatureUponReceipt'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['meatTemperatureUponReceipt']
          ) {
            response[responseField.name] =
              task['receiptData'].meatTemperatureUponReceipt;
          }
        } else if (
          responseField.autofill === 'precedingTaskFishCutDesignation'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['fishCutDesignation']
          ) {
            response[responseField.name] =
              task.precedingTaskData['fishCutDesignation'];
          }
        } else if (responseField.autofill === 'precedingTaskProductSmoked') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['productSmoked']
          ) {
            response[responseField.name] =
              task.precedingTaskData['productSmoked'];
          }
        } else if (responseField.autofill === 'precedingTaskProductionMethod') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['productionMethod']
          ) {
            response[responseField.name] =
              task.precedingTaskData['productionMethod'];
          }
        } else if (responseField.autofill === 'precedingTaskOption') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['option']
          ) {
            response[responseField.name] = task.precedingTaskData['option'];
          }
        } else if (
          responseField.autofill === 'precedingTaskGivePasswordToConsultant'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['givePasswordToConsultant']
          ) {
            response[responseField.name] =
              task.precedingTaskData['givePasswordToConsultant'];
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['email']
          ) {
            response[responseField.name] = task.precedingTaskData['email'];
          }
        } else if (responseField.autofill === 'precedingTaskPassword') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['password']
          ) {
            response[responseField.name] = task.precedingTaskData['password'];
          }
        } else if (
          responseField.autofill === 'precedingTaskCreateExternalProfile'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['createExternalProfile']
          ) {
            response[responseField.name] =
              task.precedingTaskData['createExternalProfile'];
          }
        } else if (responseField.autofill === 'precedingTaskMachine') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['machine']
          ) {
            response[responseField.name] = task.precedingTaskData['machine'];
          }
        } else if (responseField.autofill === 'precedingTaskSupporteOther') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['supporteOther']
          ) {
            response[responseField.name] =
              task.precedingTaskData['supporteOther'];
          }
        } else if (responseField.autofill === 'precedingTaskDate') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['date']
          ) {
            response[responseField.name] = task.precedingTaskData['date'];
          }
        } else if (responseField.autofill === 'precedingTaskCompanie') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['companie']
          ) {
            response[responseField.name] = task.precedingTaskData['companie'];
          }
        } else if (responseField.autofill === 'precedingTaskStore') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['store']
          ) {
            response[responseField.name] = task.precedingTaskData['store'];
          }
        } else if (responseField.autofill === 'precedingTaskStoreEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['storeEmail']
          ) {
            response[responseField.name] = task.precedingTaskData['storeEmail'];
          }
        } else if (responseField.autofill === 'precedingTaskTitleOrder') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['titleOrder']
          ) {
            response[responseField.name] = task.precedingTaskData['titleOrder'];
          }
        } else if (responseField.autofill === 'precedingTaskDescription') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['description']
          ) {
            response[responseField.name] =
              task.precedingTaskData['description'];
          }
        } else if (responseField.autofill === 'precedingTaskSendEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['sendEmail']
          ) {
            response[responseField.name] = task.precedingTaskData['sendEmail'];
          }
        } else if (responseField.autofill === 'precedingTaskEmailConsultant') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['emailConsultant']
          ) {
            response[responseField.name] =
              task.precedingTaskData['emailConsultant'];
          }
        } else if (
          responseField.autofill === 'precedingTaskEmailCreatedPlatform'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['emailCreatedPlatform']
          ) {
            response[responseField.name] =
              task.precedingTaskData['emailCreatedPlatform'];
          }
        } else if (responseField.autofill === 'precedingTaskCorrection') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['correctionType']
          ) {
            response[responseField.name] =
              task.precedingTaskData['correctionType'];
          }
        } else if (
          responseField.autofill === 'precedingTaskActionDescription'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['actionDescription']
          ) {
            response[responseField.name] =
              task.precedingTaskData['actionDescription'];
          }
        } else if (
          responseField.autofill === 'precedingTaskImplementationResponsible'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['implementationResponsible']
          ) {
            response[responseField.name] =
              task.precedingTaskData['implementationResponsible'];
          }
        } else if (responseField.autofill === 'precedingTaskAccessOk') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['accessOk']
          ) {
            response[responseField.name] = task.precedingTaskData['accessOk'];
          }
        } else if (
          responseField.autofill === 'precedingTaskNewEmailCreatedPlatform'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['newEmailCreatedPlatform']
          ) {
            response[responseField.name] =
              task.precedingTaskData['newEmailCreatedPlatform'];
          }
        } else if (responseField.autofill === 'precedingTaskRegisterNumber') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['registerNumber']
          ) {
            response[responseField.name] =
              task.precedingTaskData['registerNumber'];
          }
        } else if (
          responseField.autofill === 'precedingTaskNewRegisterNumber'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['newRegisterNumber']
          ) {
            response[responseField.name] =
              task.precedingTaskData['newRegisterNumber'];
          }
        } else if (responseField.autofill === 'precedingTaskSumaryProject') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['sumaryProject']
          ) {
            response[responseField.name] =
              task.precedingTaskData['sumaryProject'];
          }
        } else if (responseField.autofill === 'precedingTaskNewSumaryProject') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['newSumaryProject']
          ) {
            response[responseField.name] =
              task.precedingTaskData['newSumaryProject'];
          }
        } else if (
          responseField.autofill === 'precedingTaskNewVersionSumaryProject'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['newVersionSumaryProject']
          ) {
            response[responseField.name] =
              task.precedingTaskData['newVersionSumaryProject'];
          }
        } else if (responseField.autofill === 'precedingTaskAclaraciones') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['aclaraciones']
          ) {
            response[responseField.name] =
              task.precedingTaskData['aclaraciones'];
          }
        } else if (responseField.autofill === 'precedingTaskValidation') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['validation']
          ) {
            response[responseField.name] = task.precedingTaskData['validation'];
          }
        } else if (responseField.autofill === 'precedingTaskTextAprover') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['textAprover']
          ) {
            response[responseField.name] =
              task.precedingTaskData['textAprover'];
          }
        } else if (responseField.autofill === 'precedingTaskSelectFolder') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['selectFolder']
          ) {
            response[responseField.name] =
              task.precedingTaskData['selectFolder'];
          }
        } else if (
          responseField.autofill === 'precedingTaskInitialWeightSmoking'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['initialWeightSmoking']
          ) {
            response[responseField.name] =
              task.precedingTaskData['initialWeightSmoking'];
          }
        } else if (responseField.autofill === 'precedingTaskStateDebt') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['stateDebt']
          ) {
            response[responseField.name] = task.precedingTaskData['stateDebt'];
          }
        } else if (responseField.autofill === 'precedingTaskDebtsToState') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['debtsToState']
          ) {
            response[responseField.name] =
              task.precedingTaskData['debtsToState'];
          }
        } else if (responseField.autofill === 'precedingTaskHasStateDebts') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['hasStateDebts']
          ) {
            response[responseField.name] =
              task.precedingTaskData['hasStateDebts'];
          }
        } else if (responseField.autofill === 'precedingTaskIsEligibility') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['isEligibility']
          ) {
            response[responseField.name] =
              task.precedingTaskData['isEligibility'];
          }
        } else if (
          responseField.autofill === 'precedingTaskSumario'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['sumario']
          ) {
            response[responseField.name] =
              task.precedingTaskData['sumario'];
          }
        } else if (
          responseField.autofill === 'precedingTaskEnquadramentoProjeto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['enquadramentoProjeto']
          ) {
            response[responseField.name] =
              task.precedingTaskData['enquadramentoProjeto'];
          }
        } else if (
          responseField.autofill === 'precedingTaskDescricaoAcoesInvestimentos'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['descricaoAcoesInvestimentos']
          ) {
            response[responseField.name] =
              task.precedingTaskData['descricaoAcoesInvestimentos'];
          }
        } else if (
          responseField.autofill === 'precedingTaskContributoIgualdadeGenero'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['contributoIgualdadeGenero']
          ) {
            response[responseField.name] =
              task.precedingTaskData['contributoIgualdadeGenero'];
          }
        } else if (
          responseField.autofill === 'precedingTaskContributoTransicao'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['contributoTransicao']
          ) {
            response[responseField.name] =
              task.precedingTaskData['contributoTransicao'];
          }
        } else if (responseField.autofill === 'precedingTaskJustificacaoDnsh') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['justificacaoDnsh']
          ) {
            response[responseField.name] =
              task.precedingTaskData['justificacaoDnsh'];
          }
        } else if (
          responseField.autofill === 'precedingTaskCriteriosSelecaoRelevancia'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['criteriosSelecaoRelevancia']
          ) {
            response[responseField.name] =
              task.precedingTaskData['criteriosSelecaoRelevancia'];
          }
        } else if (
          responseField.autofill ===
          'precedingTaskCriteriosSelecaoImpactoDigital'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['criteriosSelecaoImpactoDigital']
          ) {
            response[responseField.name] =
              task.precedingTaskData['criteriosSelecaoImpactoDigital'];
          }
        } else if (
          responseField.autofill === 'precedingTaskTransicaoCapacitacaoDigital'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['transicaoCapacitacaoDigital']
          ) {
            response[responseField.name] =
              task.precedingTaskData['transicaoCapacitacaoDigital'];
          }
        } else if (
          responseField.autofill ===
          'precedingTaskCriteriosSelecaoCoerenciaTecnica'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['criteriosSelecaoCoerenciaTecnica']
          ) {
            response[responseField.name] =
              task.precedingTaskData['criteriosSelecaoCoerenciaTecnica'];
          }
        } else if (
          responseField.autofill === 'precedingTaskExcedeuValor50mil'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['excedeuValor50mil']
          ) {
            response[responseField.name] =
              task.precedingTaskData['excedeuValor50mil'];
          }
        } else if (
          responseField.autofill === 'precedingTaskValorTotalCandidatura'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['valorTotalCandidatura']
          ) {
            response[responseField.name] =
              task.precedingTaskData['valorTotalCandidatura'];
          }
        } else if (
          responseField.autofill === 'precedingTaskValorExcedidoNaoFinanciado'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['valorExcedidoNaoFinanciado']
          ) {
            response[responseField.name] =
              task.precedingTaskData['valorExcedidoNaoFinanciado'];
          }
        } else if (
          responseField.autofill === 'precedingTaskCaeNaoListadoAnexoI'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['caeNaoListadoAnexoI']
          ) {
            response[responseField.name] =
              task.precedingTaskData['caeNaoListadoAnexoI'];
          }
        } else if (
          responseField.autofill === 'precedingTaskFinanciamentoDuplicadoImpacto'
        ) {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['financiamentoDuplicadoImpacto']
          ) {
            response[responseField.name] =
              task.precedingTaskData['financiamentoDuplicadoImpacto'];
          }
        }  else if (responseField.autofill === 'precedingTaskNomeOrganizacao') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['nomeOrganizacao']
          ) {
            response[responseField.name] = task.precedingTaskData['nomeOrganizacao'];
          }
        } else if (responseField.autofill === 'precedingTaskIsAssociatedo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['isAssociated']
          ) {
            response[responseField.name] = task.precedingTaskData['isAssociated'];
          }
        } else if (responseField.autofill === 'precedingTaskNumeroOrganizacao') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['numeroOrganizacao']
          ) {
            response[responseField.name] = task.precedingTaskData['numeroOrganizacao'];
          }
        } else if (responseField.autofill === 'precedingTaskNome') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['nome']
          ) {
            response[responseField.name] = task.precedingTaskData['nome'];
          }
        } else if (responseField.autofill === 'precedingTaskTelemovel') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['telemovel']
          ) {
            response[responseField.name] = task.precedingTaskData['telemovel'];
          }
        } else if (responseField.autofill === 'precedingTaskEmail') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['email']
          ) {
            response[responseField.name] = task.precedingTaskData['email'];
          }
        } else if (responseField.autofill === 'precedingTaskCargo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['cargo']
          ) {
            response[responseField.name] = task.precedingTaskData['cargo'];
          }
        } else if (responseField.autofill === 'precedingTaskBreveResumoEntidade') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['breveResumoEntidade']
          ) {
            response[responseField.name] = task.precedingTaskData['breveResumoEntidade'];
          }
        } else if (responseField.autofill === 'precedingTaskSelecaoProdutosCatalogo') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['selecaoProdutosCatalogo']
          ) {
            response[responseField.name] = task.precedingTaskData['selecaoProdutosCatalogo'];
          }
        } else if (responseField.autofill === 'precedingTaskPequenaExplicacaoProjeto') {
          if (
            task &&
            task.precedingTaskData &&
            task.precedingTaskData['pequenaExplicacaoProjeto']
          ) {
            response[responseField.name] = task.precedingTaskData['pequenaExplicacaoProjeto'];
          }
        } else if (responseField.autofill === 'product' && product) {
          if (product) {
            response[responseField.name] = product.name;
          }
        } else if (responseField.autofill === 'brand') {
          if (product) {
            response[responseField.name] = product.brand;
          }
        } else if (responseField.autofill === 'supplier' && product) {
          if (product) {
            response[responseField.name] = product.supplierName;
          }
        } else if (responseField.autofill === 'origin') {
          response[responseField.name] = product?.origin;
        } else if (responseField.autofill === 'expirationDate') {
          response[responseField.name] = task?.['expirationDate'];
        } else if (responseField.autofill === 'nextExpirationDate') {
          response[responseField.name] = product
            ? product.nextExpirationDates
              ? product.nextExpirationDates[0]
              : null
            : null;
        } else if (responseField.autofill === 'nextNextExpirationDate') {
          response[responseField.name] = product
            ? product.nextExpirationDates
              ? product.nextExpirationDates[1]
              : null
            : null;
        } else if (responseField.autofill === 'TODAY') {
          response[responseField.name] = moment().format('YYYY-MM-DD');
        } else if (responseField.autofill === 'NONE') {
          response[responseField.name] = null;
        } else if (responseField.autofill === 'problemName') {
          response[responseField.name] = task?.['problemName'];
        } else if (responseField.autofill === 'problemDate') {
          response[responseField.name] = task?.['problemDate'];
        } else if (responseField.autofill === 'problemDescription') {
          response[responseField.name] = task?.['problemDescription'];
        } else if (responseField.autofill === 'precedingTaskLastCargoOfDayNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Última carga do dia'];
        } else if (responseField.autofill === 'precedingTaskQuantityNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Quantidade'];
        } else if (responseField.autofill === 'precedingTaskDirtyQuantityNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Quantidade (peso sujo em kg)'];
        } else if (responseField.autofill === 'precedingTaskCleanTicketsNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Tickets Limpos'];
        } else if (responseField.autofill === 'precedingTaskWaybillNumberNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Nº Guia'];
        } else if (responseField.autofill === 'precedingTaskExitDateNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Data e Hora de Saída'];
        } else if (responseField.autofill === 'precedingTaskDestinationNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Destinatário'];
        } else if (responseField.autofill === 'precedingTaskDriverNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Motorista'];
        } else if (responseField.autofill === 'precedingTaskLicensePlateNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Matrícula Transporte'];
        } else if (
          responseField.autofill === 'precedingTaskReboqueLicensePlateNF'
        ) {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Matrícula Reboque'];
        } else if (responseField.autofill === 'precedingTaskGroupNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Grupo de Colheita'];
        } else if (responseField.autofill === 'precedingTaskParcelNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Parcela'];
        } else if (responseField.autofill === 'precedingTaskProductNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Produto'];
        } else if (responseField.autofill === 'precedingTaskVarietyNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Variedade'];
        } else if (responseField.autofill === 'precedingTaskQualityNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Qualidade'];
        } else if (
          responseField.autofill === 'precedingTaskSanitariumStateNF'
        ) {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Estado Fitossanitário'];
        } else if (
          responseField.autofill ===
          'precedingTaskSanitariumStateConfirmationNF'
        ) {
          response[responseField.name] =
            task?.precedingTaskResponse?.[
              'Confirmação de Estado Fitossanitário Não Conforme'
            ];
        } else if (responseField.autofill === 'precedingTaskObservationsNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Observações Transporte'];
        } else if (responseField.autofill === 'precedingTaskWaybillEmitterNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Emissor da Guia'];
        } else if (
          responseField.autofill === 'precedingTaskDirtyTicketNumberNF'
        ) {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Nº Ticket sujo'];
        } else if (responseField.autofill === 'precedingTaskCleanTicketsNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Tickets Limpos'];
        } else if (responseField.autofill === 'precedingTaskHarvestTypeNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Tipo de Colheita'];
        } else if (
          responseField.autofill === 'precedingTaskExitTemperatureNF'
        ) {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Temperatura de Saída'];
        } else if (responseField.autofill === 'precedingTaskSocietyNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Sociedade'];
        } else if (responseField.autofill === 'precedingTaskHerdadeNF') {
          response[responseField.name] =
            task?.precedingTaskResponse?.['Herdade'];
        } else if (responseField.autofill === 'destinationNF') {
          response[responseField.name] = `${
            this.fieldsService
              .getClosestHerdadeOrLagarField(task?.formId as string)
              .buyers.map((b: any) => `${b.fullName} (${b.companyFullName})`)[0]
          }`;
        } else if (responseField.autofill === 'societyNF') {
          response[responseField.name] = `${
            this.fieldsService.getClosestHerdadeOrLagarField(
              task?.formId as string
            ).fullName
          }`;
        } else if (responseField.autofill === 'herdadeNF') {
          response[responseField.name] = `${
            this.fieldsService.getClosestHerdadeOrLagarField(
              task?.formId as string
            ).fullName
          }`;
        } else if (responseField.autofill === 'waybillEmitterNF') {
          response[
            responseField.name
          ] = `${this.employeeService.getEmployeeName()}`;
        } else {
          response[responseField.name] = responseField.autofill;
        }
      }
      if (product && product['technicalSheet']) {
        if (responseField.name === 'O produto vai ser conservado no quente?') {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (responseField.name === 'Para embalar?') {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (
          responseField.name === 'Durabilidade de vida (Balcão tradicional)'
        ) {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (responseField.name === 'DLC para pré-embalamento') {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (responseField.name === 'Tempo de preparação') {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (responseField.name === 'Material de embalagem') {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (
          responseField.type === 'list' &&
          responseField.name === 'Lista de ingredientes'
        ) {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        } else if (responseField.type === 'checklist') {
          response[responseField.name] =
            product['technicalSheet'][responseField.name];
        }
      }

      if (product && responseField.type === 'product') {
        response[responseField.name] = product;
      }
      if (batch && responseField.type === 'batch') {
        response[responseField.name] = batch;
      }
    }
  }

  autofillSubfields(subfields: any[]) {
    const response: any = {};
    subfields.forEach((sf) => {
      if (response[sf.name] || sf.autofill) return;
      if (sf.type === 'boolean') {
        response[sf.name] = false;
      } else if (sf.type === 'date') {
        response[sf.name] = moment().seconds(0).format('YYYY-MM-DDTHH:mm:ss');
      } else if (sf.type === 'list') {
        response[sf.name] = [
          {
            name: '',
            quantity: 1,
            unit: 'kg',
          },
        ];
      } else if (sf.type === 'checklist') {
        response[sf.name] = [];
      } else if (sf.type === 'group') {
        response[sf.name] = [this.autofillSubfields(sf.subfields)];
      } else if (sf.type === 'photo') {
        response[sf.name] = [];
      } else {
        response[sf.name] = '';
      }
    });
    return response;
  }
  processCondition(field: ResponseField, response: TaskResponse) {
    if (
      field.condition === undefined ||
      field.condition === null ||
      field.condition === ''
    )
      return true;
    const conditions = field.condition.split(', ');
    if (conditions.length === 0) return true;
    return (
      conditions.filter((c: string) => {
        if (c.includes('===')) {
          const sides = c.split('===');
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response[leftSide] === rightSide;
        } else if (c.includes('!==')) {
          const sides = c.split('!==');
          const leftSide = sides[0];
          const rightSide = sides[1];
          return response[leftSide] !== rightSide;
        } else if (c === 'expirationDate') {
          if (
            response['Data de validade'] &&
            moment(response['Data de validade']).isBefore(
              moment().add(4, 'days')
            )
          ) {
            return true;
          }
          return false;
        }
        if (c[0] === '!') {
          const condition = c.substring(1, c.length);
          return !response[condition];
        }
        return !!response[c];
      }).length === conditions.length
    );
  }
}
