import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductNameAndExpirationDate } from '../components/summary/expiring-products/expiring-products-summary.component';

@Component({
  selector: 'app-expiring-products-alert-modal',
  templateUrl: './expiring-products-alert.modal.html',
  styleUrls: ['./expiring-products-alert.modal.scss'],
})
export class ExpiringProductsAlertModal {
  @Input() expiringProducts: ProductNameAndExpirationDate[] = [
    {
      name: 'BAGUETE SEMENTES CONGELADO',
      expirationDate: 1729593000000,
    },
  ];

  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss();
  }
}
