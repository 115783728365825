import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt.modal.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PromptModal {
  title;
  text;
  hasYesButton;
  yesText;
  hasNoButton;
  noText;


  constructor(private modalCtrl: ModalController, private navParams: NavParams)  {
    this.title = navParams.data['title'];
    this.text = navParams.data['text'];
    this.hasYesButton = navParams.data['hasYesButton'] === undefined ? true : navParams.data['hasYesButton'];
    this.yesText = navParams.data['yesText'];
    this.hasNoButton = navParams.data['hasNoButton'] === undefined ? true : navParams.data['hasNoButton'];
    this.noText = navParams.data['noText'];
  }

  close() {
    this.modalCtrl.dismiss(false);
  }

  pressYes() {
    this.modalCtrl.dismiss(true);
  }

  pressNo() {
    this.modalCtrl.dismiss(false);
  }
}
