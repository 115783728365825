import { Injectable } from '@angular/core';
import { Loading } from '../../utils/loading/loading';
import { AuthenticationService } from './authentication';
import { ConfigsService } from './configs.service';
import { StoresService } from './stores.service';
import { environment } from 'src/environments/environment';
import { JSON_HEADERS } from '../../utils/headers';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { Observable, from, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { AuthenticatedHttpService } from './authenticated-http';
import { Link } from '../../utils/link';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class ManualsService {
  private cache = new Map<string, any>();
  private etag = '';

  constructor(
    private http: AuthenticatedHttpService,
    private loading: Loading,
    private authenticationService: AuthenticationService,
    private configsService: ConfigsService,
    private storesService: StoresService,
    protected envService: EnvironmentService
  ) {}

  getManualsFromStore(companyId: string, storeId: string): Observable<any> {
    if (!companyId || !storeId) {
      return throwError(() => new Error('Company ID or Store ID is missing'));
    }
    let link: Link = new Link(
      this.envService.getApiUri() + `/companies/${companyId}/stores/${storeId}/manuals`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.etag,
      }
    );

    return this.http.get(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.cache.clear();
        this.etag = response.headers.get('etag') || '';
        const manuals = response.body || [];
        this.downloadManuals(manuals, companyId, storeId);
      }),
      catchError((error) => throwError(() => error))
    );
  }

  async downloadManuals(
    manuals: any[],
    companyId: string,
    storeId: string
  ): Promise<void> {
    for (const doc of manuals) {
      if (doc.type === 'file') {
        await this.downloadFile(doc, companyId, storeId);
      } else if (doc.type === 'folder' && doc.contents) {
        await this.downloadManuals(doc.contents, companyId, storeId);
      } else {
        await this.downloadFile(doc, companyId, storeId);
      }
    }
  }
  async downloadFile(
    doc: any,
    companyId: string,
    storeId: string
  ): Promise<void> {
    const filePath = `${doc.id}.pdf`;

    try {
      const readFile = await Filesystem.readFile({
        path: filePath,
        directory: Directory.Data,
      });
      console.log('File already exists:', readFile);
    } catch (error: any) {
      if (error.message === 'File does not exist') {
        await Filesystem.downloadFile({
          url: `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/manuals/${doc.id}/download`,
          headers: {
            'Access-token': this.authenticationService.getAuthToken(),
          },
          path: filePath,
          directory: Directory.Data,
        });
      }
    }
  }

  newManual(
    manual: any,
    authInfo: AuthenticationInfo,
    folderSelect: string
  ): Observable<any> {
    if (!authInfo.user) {
      return throwError(() => new Error('User info is missing in authInfo'));
    }

    const user: UserInfo = authInfo.user;
    const companyId = user.companyId;
    const storeId = user.storeId;

    if (!companyId || !storeId) {
      return throwError(() => new Error('Company ID or Store ID is missing'));
    }

    const link: Link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/manuals/${manual.id}`,
      JSON_HEADERS
    );

    return this.http.put(link, manual).pipe(
      tap(() => {
        if (this.storesService.store && !this.storesService.store?.manuals) {
          this.storesService.store.manuals = [];
        }

        const folderTitle = folderSelect;
        if (folderTitle) {
          const folder = this.storesService.store?.manuals?.find(
            (m: any) => m.title === folderTitle && m.type === 'folder'
          );
          if (folder) {
            if (!folder.contents) {
              folder.contents = [];
            }
            folder.contents.push({
              title: manual.title,
              type: 'file',
              id: manual.id,
              number: manual.number,
              validation: manual.validation
            });
            
          } else {
            console.log(`Folder '${folderTitle}' not found.`);
          }
        } else {
          console.log('No folder title provided.');
        }

        this.updateStoreManuals(
          companyId,
          storeId,
          this.storesService.store?.manuals,
          authInfo
        ).subscribe();
      }),
      catchError((error) => throwError(() => error))
    );
  }

  updateStoreManuals(
    companyId: string,
    storeId: string,
    manuals: any[] | undefined,
    authInfo: AuthenticationInfo
  ): Observable<any> {
    if (!companyId || !storeId) {
      return throwError(() => new Error('Company ID or Store ID is missing'));
    }

    const updateLink = new Link(
      `${this.envService.getApiUri()}/companies/${companyId}/stores/${storeId}/update-manuals`,
      JSON_HEADERS
    );
    return this.http.post(updateLink, { manuals }, authInfo);
  }

  getManuals(): any[] {
    if (this.storesService.store?.manuals) {
      return this.storesService.getManuals();
    } else {
      return Array.from(this.cache.values());
    }
  }

  getManual(id: string): any {
    return this.cache.get(id);
  }
}
