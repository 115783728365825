// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { ExecutionState } from '../../services/custom-task.service';
import { StoresService } from '../../services/stores.service';
import { TasksService } from '../../services/tasks.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpInsertSupplier implements IMyTaskHandler {
  name = 'follow-up-insert-supplier';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const nameSupplier = data['Fornecedor'];
    // Correção: Verifica diretamente sem usar um array
    const existingSupplier =
      this.storeService.store.modules.goodsReception.directSupplierList.includes(
        nameSupplier
      );

    if (!existingSupplier) {
      // Parece que você está reatribuindo o mesmo valor aqui, o que pode ser desnecessário
      return this.storeService.newSupplier(
        nameSupplier,
        this.authenticationService.getAuthInfo()
      );
    }

    return of(null);
  }
}
