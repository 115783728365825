import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductDetailService } from './product-detail.service';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: 'product-detail.modal.html',
  styleUrls: ['./product-detail.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductDetailModal implements OnInit {
  @Input() product: any;
  @Input() initialSegment: string = 'info-detail'; // Accept the initial segment as an input
  segment: string = 'info-detail'; // Default segment

  constructor(
    private productDetailService: ProductDetailService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.productDetailService.product = this.product;

    // Set the segment based on the initialSegment input
    if (this.initialSegment) {
      this.segment = this.initialSegment;
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  setSegment(segment: string) {
    this.segment = segment;
  }
}
