import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import {
  CustomTaskService,
  ExecutionState,
} from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { ProductsService } from 'src/app/services/products.service';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { ModalController } from '@ionic/angular';
import cuid from 'cuid';
import moment from 'moment';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpFutureCorrection implements IMyTaskHandler {
  name = 'follow-up-future-correction';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    return type.getTitle === 'type' ? type.name : `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;

    switch (type.getSubtitle) {
      case 'scheduleCollectionProductApanha':
        return `${this.storeService.store.code} - ${data['Parcela']}`;
      case 'ratingApanha':
      case 'collectionProductApanha':
        return task.subtitle;
      case 'complexTask':
        return data['Comentário'];
      case 'NF':
        return `${data['Nº Guia']} - ${data['Herdade']}`;
      case 'inProductApanhaAzeitonaNF':
        return `${data['Nº Guia']}`;
      case 'inTransitAzeitonaNF':
        return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
      default: {
        const pieces = type.getSubtitle?.split(' ');
        return pieces
          ? pieces
              .map((piece: any) => this.pieceToString(piece, type, data))
              .join(' - ')
          : '';
      }
    }
  }

  pieceToString(piece: any, type: any, data: any): string {
    switch (piece) {
      case 'type':
        return type.name;
      case 'product':
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      case 'expirationDate':
        return moment(data['Data de fim de validade']).format(
          'DD/MM/YYYY HH:mm'
        );
      default:
        return '';
    }
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    // Acessa a loja atual
    const store = this.storeService.store;

    // Obtém as informações do usuário logado
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.error('User info not found');
      return of(null);
    }

    // Obtém o tipo de tarefa personalizada 'serunionInternalAuditVerification'
    const typeTask = this.customTaskTypesService.getCustomTaskType(
      'serunionInternalAuditVerification'
    );
    if (!typeTask) {
      console.error('typeTask not found');
      return of(null);
    }

    // Verifica se o array "Correção Futura" existe e tem itens
    const futureCorrections =
      data['Correção Futura'] && data['Correção Futura'].length > 0
        ? data['Correção Futura']
        : [];

    // Itera sobre cada correção futura e cria uma nova tarefa para cada item no array
    const tasksObservables: Observable<any>[] = futureCorrections.map(
      (futureCorrection: any) => {
        if (
          this.customTaskTypesService.canProceed(task.type, data) &&
          futureCorrection['Data da Correção Futura'] &&
          futureCorrection['Prazo Limite de Implementação'] &&
          futureCorrection['Responsável pela Implementação'] &&
          futureCorrection['Descrição da Ação Corretiva Futura']
        ) {
          console.log(futureCorrection);

          const newTask = {
            id: cuid(),
            type: typeTask.type,
            formId: task.formId,
            title: this.getTitle(typeTask, data),
            subtitle: this.getSubtitle(typeTask, data, task),
            startDate: moment(
              futureCorrection['Data da Correção Futura']
            ).valueOf(),
            endDate: moment(
              futureCorrection['Prazo Limite de Implementação']
            ).valueOf(),
            visualizationDate: moment(
              futureCorrection['Prazo Limite de Implementação']
            )
              .add(1, 'year')
              .valueOf(),
            nonConformities: [],
            causes: [],
            corrections: [],
            periodicity: 'Única',
            target: {
              storeId: store.id,
              userIds: getTargetUser(store, userInfo),
            },
            documentsIds: [],
            isCustomType: true,
            superType: undefined,
            precedingTaskId: task.id,
            precedingTaskData: {
              correctionType: futureCorrection['Titulo da Correção'],
              actionDescription: futureCorrection['Descrição da Ação Corretiva Futura'],
              implementationResponsible: futureCorrection['Responsável pela Implementação'],
            },
          };

          // Retorna a criação de uma nova tarefa
          return this.tasksService.newTask(
            newTask,
            this.authenticationService.getAuthInfo()
          );
        }
        return of(null);
      }
    );

    // Combina todas as observables para criar todas as tarefas
    return tasksObservables.length
      ? tasksObservables[tasksObservables.length - 1]
      : of(null);
  }
}
