import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { catchError, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import cuid from 'cuid';
import { StoresService } from 'src/app/services/stores.service';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldsService } from 'src/app/services/fields.service';
import { FieldType } from 'src/app/entities/field';
import { getTargetUser } from 'src/app/entities/authentication-info';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CreateSectionsTasksSerunion implements IMyTaskHandler {
  name = 'create-sections-tasks-serunion';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const existingSections = new Set();
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.log('User info not found');
      return of(null);
    }

    const sections = data['Que secções existem?'];
    if (!sections || !Array.isArray(sections)) {
      console.log('No sections found in data');
      return of(null);
    }

    // Processamento das secções
    const sectionObservables = sections.map((s: string) => {
      if (existingSections.has(s)) return of(null);
      existingSections.add(s);

      // Define o imageUrl com base no valor de `s`
      let imageUrl = '';
      switch (s) {
        case 'Armazém':
          imageUrl = 'armazem.svg';
          break;
        case 'Cozinha':
          imageUrl = 'cozinha.svg';
          break;
        case 'Despensa Dia':
          imageUrl = 'despensa_dia.svg';
          break;
        case 'Linha Self':
          imageUrl = 'linha_self.svg';
          break;
        case 'Sala Refeições':
          imageUrl = 'sala_refeições.svg';
          break;
        case 'WC Cliente':
          imageUrl = 'wc_cliente.svg';
          break;
        case 'WC Funcionário':
          imageUrl = 'wc_funcionário.svg';
          break;
        case 'Zona de Câmaras de Frio':
          imageUrl = 'zona_de_camaras_de_frio.svg';
          break;
        case 'Zona de Expedição':
          imageUrl = 'zona_de_expedição.svg';
          break;
        case 'Zona de Lavagem de Loiça Fina':
          imageUrl = 'zona_de_lavagem_de_loiça_fina.svg';
          break;
        case 'Zona de Lavagem de Loiça Grossa':
          imageUrl = 'zona_de_lavagem_de_loiça_grossa.svg';
          break;
        case 'Zona de Preparação de Carne':
          imageUrl = 'zona_de_preparação_de_carne.svg';
          break;
        case 'Zona de Preparação de Legumes':
          imageUrl = 'zona_de_preparação_de_legumes.svg';
          break;
        case 'Zona de Preparação de Peixe':
          imageUrl = 'zona_de_preparação_de_peixe.svg';
          break;
        case 'Zona de Preparação de Sobremesa':
          imageUrl = 'zona_de_preparação_de_sobremesas.svg';
          break;
        default:
          imageUrl = 'areas_empresa.svg'; // Caso padrão
          break;
      }

      const sectionForm = {
        id: cuid(),
        title: s,
        type: FieldType.Form,
        storeId: this.storeService.store.id,
        imageUrl, // Adiciona o campo `imageUrl` ao formulário
      };

      return this.fieldsService
        .newField(sectionForm, this.authenticationService.getAuthInfo())
        .pipe(
          catchError((err) => {
            console.log(`Erro ao criar o formulário da seção ${s}:`, err);
            return of(null);
          }),
          switchMap(() =>
            forkJoin([
              this.createTask(
                sectionForm,
                'coldMachineListGeralQuiz',
                task,
                userInfo,
                s
              ),
              this.createTask(
                sectionForm,
                'machineListGeralQuiz',
                task,
                userInfo,
                s
              ),
              this.createTask(
                sectionForm,
                'dailyCleaningListGeralQuiz',
                task,
                userInfo,
                s
              ),
              this.createTask(
                sectionForm,
                'periodicCleaningListGeralQuiz',
                task,
                userInfo,
                s
              ),
            ])
          )
        );
    });

    return forkJoin(sectionObservables);
  }

  private createTask(
    sectionForm: any,
    taskTypeKey: string,
    task: Task,
    userInfo: any,
    sectionSuffix: string
  ): Observable<any> {
    const taskType = this.customTaskTypesService.getCustomTaskType(taskTypeKey);
    if (!taskType) {
      console.log(`Task type ${taskTypeKey} not found`);
      return of(null);
    }

    const newTask = {
      id: cuid(),
      type: taskType.type,
      formId: sectionForm.id,
      title: `${taskType.name} ${sectionSuffix}`,
      startDate: task.startDate,
      endDate: task.endDate,
      visualizationDate: task.visualizationDate,
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: sectionForm.storeId,
        userIds: getTargetUser(this.storeService.store, userInfo),
      },
      documentsIds: [],
      isCustomType: true,
    };

    return this.tasksService
      .newTask(newTask, this.authenticationService.getAuthInfo())
      .pipe(
        catchError((err) => {
          console.log(`Erro ao criar a tarefa ${taskType.name}:`, err);
          return of(null);
        })
      );
  }
}
