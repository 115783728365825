<ion-content padding>
  <button ion-button style="width: 90%;
  height: 5%;
  margin: 4%;
  color: #fff;
    background-color: #32db64;
  " full (click)="pressCreateNewBatch()">Adicionar lote</button>

  <ion-list>
    <ion-item *ngFor="let batch of product.batches">
      <h2>{{batch.lot}}</h2>
      <h3>{{batch.expirationDate}}</h3>
    </ion-item>
  </ion-list>
</ion-content>
