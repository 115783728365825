import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { KeyValuePipe } from '../../pipes/keyvalue-pipe';
import { ResponseField } from '../../../entities/tasks/task'; // Update the path according to your project structure

// TODO PAssar div *ngIf="control.touched" para ion-note. https://ionicframework.com/docs/api/item#metadata
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'boolean-input',
  template: `
    <div>
      <ion-item
        justify="space-between"
        style="margin-top: 5px; margin-bottom: 5px;"
      >
        <ion-label style="white-space: normal;">
          {{ getTranslation(responseField)
          }}{{ responseField.required ? '*' : '' }}</ion-label
        >
        <ion-checkbox
          slot="end"
          style="--size: 32px"
          [(ngModel)]="value"
          (ngModelChange)="valueChange.emit($event)"
          [formControl]="control"
        ></ion-checkbox>
      </ion-item>
      <div *ngIf="control.touched">
        <div *ngFor="let key of control.errors | keyvalue">
          <p style="color: red" *ngIf="key.key === 'required'">
            Este campo é obrigatório
          </p>
        </div>
      </div>
    </div>
  `,
  providers: [KeyValuePipe],
})
export class BooleanInputComponent implements OnInit {
  @Input() responseField!: ResponseField;
  @Input() value: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();
  @Input() getTranslation: Function = (responseField: ResponseField) => {
    const lang = this.translator.currentLang;
    if (lang === 'pt') {
      return responseField.name;
    } else if (lang === 'es') {
      return responseField.nameEs || responseField.name;
    }
    return responseField.name;
  };
  @Input() locked: boolean = false;

  control!: FormControl;

  constructor(private translator: TranslateService) {}

  ngOnInit() {
    const validators = [];
    if (this.responseField && this.responseField.responseValidation) {
      if (this.responseField.responseValidation.required) {
        validators.push(Validators.required);
      }
    }
    this.control = new FormControl(
      {
        value: this.value,
        disabled: !!this.responseField.locked || this.locked,
      },
      Validators.compose(validators)
    );
  }
}
