import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  NavParams,
  Platform,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-photo-history-modal',
  templateUrl: './photo.history-modal.html',
})
export class PhotoHistoryModalComponent implements OnInit {
  @ViewChild('file') fileElementRef!: ElementRef;
  photos: string[] = [];
  current = 0;
  currentPhotoPath = '';

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private mediaService: MediaService,
    private platform: Platform
  ) {}

  async ngOnInit() {
    this.photos = this.navParams.get('photos') || [];
    await this.updateCurrentPhotoPath();
  }

  close() {
    this.modalCtrl.dismiss(this.photos);
  }

  pressArrowRight() {
    if (this.current < this.photos.length - 1) {
      ++this.current;
    }
    this.updateCurrentPhotoPath().then(() => console.log('Update'));
  }

  pressArrowLeft() {
    if (this.current > 0) {
      --this.current;
    }
    this.updateCurrentPhotoPath().then(() => console.log('Update'));
  }

  async updateCurrentPhotoPath() {
    console.log('DEBUG HERE');
    if (this.photos.length === 0) this.currentPhotoPath = '';
    else if (this.platform.is('android') && this.platform.is('capacitor')) {
      const filePath = `${this.photos[this.current]}.jpeg`;

      const result = await Filesystem.readFile({
        path: filePath,
        directory: Directory.Data, // Ensure this matches where your file is stored
      });
      this.currentPhotoPath = `data:image/jpeg;base64,${result.data}`;
    } else {
      this.mediaService
        .getSingleMediaDataFromServer(this.photos[this.current])
        .subscribe(
          (dataString) => {
            this.currentPhotoPath = dataString;
          },
          (err) => {
            console.error('Error fetching photo data', err);
            this.currentPhotoPath = ''; // Fallback in case of an error
          }
        );
    }
  }
}
