export const LEVEL = 'level';
export const FORM = 'form';

export enum FieldType {
  Level = 'level',
  Form = 'form',
}
export interface Field {
  id: string;
  storeId: string;
  title: string;
  type: FieldType;
  upperLevelId?: string;
  supplier?: string;
  origin?: string;
  product?: any;
  position?: number;
  condition?: string;
  creatableTaskTypes?: string[]
  upperLevelIds?: string[]; // Optional if it's applicable
  userIds?: string[];
}

export interface Level extends Field  {
  childrenIds?: string[];
}
