<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBack()" color="medium" fill="solid">
        <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
        Voltar
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button
        color="primary"
        (click)="closeAllHandler()"
        [disabled]="numberOfTasksToClose() <= 0"
        fill="solid"
      >
        <ion-icon slot="start" name="checkmark"></ion-icon>
        Concluir Todas ( {{ numberOfTasksToClose() }} )
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-searchbar
    [(ngModel)]="searchTerm"
    placeholder="{{ 'SEARCH' | translate }}"
    [formControl]="searchControl"
    (ionInput)="onSearchInput()"
  >
  </ion-searchbar>
  <div *ngIf="searching" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>
  <div
    *ngIf="searchTerm.length > 0"
    style="text-align: right; margin-right: 10px"
  >
    {{ tasks.length }} {{ 'correspondências' | translate }}
  </div>

  <ion-list-header class="fixed-header tasks-list-header" no-margin>
    <ion-row class="tasks-row" align-items-center>
      <ion-col size="3" class="tasks-title-col">{{'DATE'|translate}}</ion-col>
      <ion-col size="6" class="tasks-title-col"
        >{{'TASK_MACHINE'|translate}}</ion-col
      >
      <ion-col size="2" class="tasks-title-col">{{'(ºC)'|translate}}</ion-col>
      <ion-col size="1" class="tasks-title-col"></ion-col>
    </ion-row>
  </ion-list-header>
  <ion-list>
    <ion-item class="tasks-item" *ngFor="let task of tasks | slice:0:counter">
      <ion-row
        class="tasks-row inner-tasks-item"
        align-items-center
        no-margin
        [class.tasksUnanswered]="!isLateTask(task)"
        [class.lateTask]="isLateTask(task)"
        [class.temp-out-bounds]="responses[task.id]['Temperatura (ºC):']?.length > 0 && isTemperatureOutOfBounds(task) || responses[task.id]['Temperatura Actual (ºC):']?.length > 0 && isTemperatureOutOfBounds(task)"
      >
        <ion-col size="3" class="tasks-col" (click)="pressRow(task)">
          <div class="task-content">
            <span>{{ task.startDate | date:"dd-MM-yy HH:mm" }}</span>
          </div>
        </ion-col>
        <ion-col size="6" class="tasks-col" (click)="pressRow(task)">
          <div
            class="task-content"
            *ngIf="task.machineId && (envService.isMachineMyTask() || envService.isMachineGreenselection())"
          >
            <span>
              {{sensorsService.getMachineById(task.machineId!).machine }}
              ({{sensorsService.getMachineById(task.machineId!).temperatureIndicator.okRange.min}}
              a
              {{sensorsService.getMachineById(task.machineId!).temperatureIndicator.okRange.max}})
            </span>
            <span *ngIf="isLateTask(task)" class="late-text">
              <ion-chip>Em atraso</ion-chip></span
            >
          </div>
          <div
            *ngIf="task.machineId && !envService.isMachineMyTask() && !envService.isMachineGreenselection()"
            class="title-height"
          >
            {{ task.title }}
          </div>
        </ion-col>

        <ion-col size="2" class="tasks-col">
          <!--<ion-item style="border-radius: 5px">
             <ion-input
              [(ngModel)]="responses[task.id]['Temperatura (ºC):']"
              type="number"
              placeholder="ºC"
              errorText="Fora de parâmetros"
              [min]="task.okRangeMin ?? 0"
              [max]="task.okRangeMax ?? 0"
            >
            </ion-input> 
          </ion-item>-->
          <number-input
            [noLabel]="true"
            [responseField]="tempResponseField"
            [(value)]="responses[task.id][getTemperatureField(task)]"
            class="input-temp"
          ></number-input>
        </ion-col>
        <ion-col size="1" class="tasks-col" (click)="pressRow(task)">
          <ion-icon name="search-sharp" style="font-size: 30px"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-item>

    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-list>
</ion-content>
