<div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ product.name }}{{ product.brand ? ' ' + product.brand : '' }} {{ product.supplierName || product.supplier?.name }}</h3>
          <img src="assets/img/close.svg" (click)="close()" />
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <div id="contentTable" align="center">
            <div class="table-header">
              <img class="tashHeaderImg" src="assets/img/bar_info_gray.svg">
            </div>
            <table class="pretty">
              <thead class="theTargethead">
                <tr>
                  <th style="width: 110px;">
                    <span>
                      {{ 'Data' | translate }}
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th>
                    <span>
                      {{ 'Status' | translate }}
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                  <th>
                    <span>
                      {{ 'Processado' | translate }}
                      <i class="fa fa-sort"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="dates.length > 0">
                <ng-container *ngFor="let d of dates">
                  <tr>
                    <td>{{ d.date | date: 'yyyy/MM/dd' }}</td>
                    <td>{{ d.status }}</td>
                    <td>{{ d.isTreated ? 'Etiquetas pedidas' : 'Por pedir etiquetas' }}</td>
                  </tr>
                </ng-container>
              </tbody>
              <tbody *ngIf="dates.length === 0">
                <tr>
                  <td colspan="3">{{ 'Sem datas inseridas.' | translate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modalActions">
          <div class="actionsLeft">
          </div>
          <div class="actionsRight">
            <button class="btn btn-cancel light-grey" (click)="close()">{{ 'CLOSE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  