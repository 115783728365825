import { Component } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { IbersolProductCreateModal } from 'src/app/components/product-create-modal/ibersol/ibersol-product-create.modal';
import { ProductCreateModal } from 'src/app/components/product-create-modal/product-create.modal';
import {
  isCommonlyUsedProductInSection,
  searchMatchesProductBarcode,
} from 'src/app/entities/product';
import { ProductsService } from 'src/app/services/products.service';
import { StoresService } from 'src/app/services/stores.service';
import { getIMSectionFromUsername } from 'src/app/entities/authentication-info';
import { AuthenticationService } from 'src/app/services/authentication';
import { scanBarcode } from 'src/utils/barcode.utils';

@Component({
  selector: 'app-commonly-used-products-modal',
  templateUrl: './commonly-used-products.modal.html',
  styleUrls: ['./commonly-used-products.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CommonlyUsedProductsModal {
  product: any;

  allProducts: any[] = [];
  products: any[] = [];
  counter = 0;
  private ITEMS_PER_TICK = 12;

  constructor(
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private storeService: StoresService,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService
  ) {
    this.initializeProducts();
  }

  initializeProducts() {
    const username = this.authenticationService.getAuthInfo()?.user?.username;
    if (username) {
      this.allProducts = this.productsService
        .getProducts()
        .filter((p) => {
          return isCommonlyUsedProductInSection(
            p.barcode.value ?? '', // Handle possible undefined
            this.storeService.store,
            getIMSectionFromUsername(username)
          );
        })
        .sort((p1, p2) => {
          return p1.name.localeCompare(p2.name);
        });
      this.products = this.allProducts;
      this.counter = this.ITEMS_PER_TICK;
    }
  }

  doInfinite(infiniteScroll: any) {
    this.counter =
      this.counter + this.ITEMS_PER_TICK <= this.allProducts.length
        ? this.counter + this.ITEMS_PER_TICK
        : this.allProducts.length;
    infiniteScroll.target.complete();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  pressProduct(product: any) {
    this.modalCtrl.dismiss(product);
  }

  async pressBarcodeScanner() {
    try {
      const permissionStatus = await BarcodeScanner.checkPermissions();
      if (permissionStatus.camera !== 'granted') {
        await BarcodeScanner.requestPermissions();
      }

      const result = await scanBarcode(BarcodeScanner);

      if (result) {
        console.log('Scanned text:', result);

        // Perform actions with scannedText here

        const modal = await this.modalCtrl.create({
          component: ProductCreateModal,
          componentProps: { scannedText: result },
        });
        return await modal.present();
      } else {
        const toast = await this.toastController.create({
          message: 'No barcode content found.',
          duration: 2000,
          position: 'top',
        });
        await toast.present();
      }
    } catch (err) {
      console.error('Error during barcode scan:', err);
      const toast = await this.toastController.create({
        message: 'Error during barcode scan.',
        duration: 2000,
        position: 'top',
      });
      await toast.present();
    }
  }
}
