import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { of, from } from 'rxjs';
import { switchMap, filter, debounceTime, toArray } from 'rxjs/operators';
import { SelectEntryModal } from '../select-entry/select-entry.modal';
import { InsertManualEntryModal } from '../insert-manual-entry/insert-manual-entry.modal';
import { Product } from 'src/app/entities/product'; // Adjust the import path as necessary

@Component({
  templateUrl: 'select-ingredient-menu.modal.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SelectIngredientMenu {
  productName: string = '';
  searchControl = new FormControl();
  searchResult: Product[] = [];

  constructor(
    private modalCtrl: ModalController,
    private productsService: ProductsService
  ) {
    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      switchMap((search: string) => {
        if (!search) {
          return of([]); // Use `of` to return an empty observable array
        } else {
          const products = this.productsService.getProducts();
          return from(products).pipe(
            filter((p: Product) => p.name.toLowerCase().includes(search.toLowerCase())),
            toArray() // Collect the filtered products into an array
          );
        }
      })
    ).subscribe((filteredProducts: Product[]) => {
      this.searchResult = filteredProducts;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async pressProduct(product: Product) {
    const modal = await this.modalCtrl.create({
      component: SelectEntryModal,
      componentProps: { product }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.entry) {
      this.modalCtrl.dismiss({
        product,
        entry: data.entry,
        newEntry: data.newEntry
      });
    }
  }

  async pressNew() {
    const product = { name: '' };
    const modal = await this.modalCtrl.create({
      component: InsertManualEntryModal,
      componentProps: { product }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.entry) {
      this.modalCtrl.dismiss({
        product,
        entry: data.entry,
        newEntry: data.newEntry
      });
    }
  }
}
