<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle taskTitle">
        <h3 class="modalTitleText">{{'Inserir novo lote' | translate}}</h3>
        <h2 class="modalSubtitleText">{{product.name}}</h2>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <div class="form-group">
          <label>Lote</label>
          <input class="form-control" type="text" [(ngModel)]="lot">
        </div>

        <div class="form-group">
          <label>Data de validade</label>
          <input class='form-control'
                 type='text'
                 [formControl]="expirationDateControl"
                 (input)="onDateInputChange($event)"
                 placeholder="yyyy-mm-dd"
                 inputmode="numeric">
        </div>

 <!--        <div class="modalTextSection">
          <h4
            class="justification-modal-header-text">{{"Nota: Caso não tenha lote, repetir data de validade no campo do lote"}}</h4>
        </div>

        <div class="form-group">
          <label>Nº identificação</label>
          <input class="form-control" type="text" [(ngModel)]="batch.idNumber">
        </div>

        <div class="form-group">
          <label>Origem</label>
          <input class="form-control" type="text" [(ngModel)]="batch.origin">
        </div> -->

        <!-- <div class="modalTextSection">
          <h4
            class="justification-modal-header-text">{{"Detalhes do produto"}}</h4>
        </div>
        <div class="form-group">
          <label>Produto</label>
        <app-product-input [value]="product"></app-product-input>
        </div> -->
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="pressCreateBatch()">{{'Criar'|translate}}</button>
          <button class="btn btn-cancel light-grey" (click)="close()">{{'CANCEL'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
