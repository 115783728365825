import {
  Barcode,
  BarcodeScannerPlugin,
} from '@capacitor-mlkit/barcode-scanning';

export async function scanBarcode(
  barcodeScanner: BarcodeScannerPlugin
): Promise<string> {
  return new Promise(async (resolve) => {
    document.querySelector('body')?.classList.add('barcode-scanner-active');

    const listener = await barcodeScanner.addListener(
      'barcodeScanned',
      async (result) => {
        await listener.remove();
        document
          .querySelector('body')
          ?.classList.remove('barcode-scanner-active');
        await barcodeScanner.stopScan();
        resolve(result.barcode.displayValue);
      }
    );

    await barcodeScanner.startScan();
  });
}
