import { Component, Input, OnInit } from '@angular/core';
import {
  getDayOfTheWeek,
  getDayOfTheWeekAsNumber,
} from '../../../../utils/date.utils';
import { Product } from 'src/app/entities/product';
import { Batch } from 'src/app/entities/batch';
import { BatchTagInfo } from 'src/app/services/tags-printing.service';

@Component({
  selector: 'frozen-tag',
  template: `<div *ngIf="!nonApplicable" class="tag-container">
    <div class="left-section">
      <div class="circle">
        {{ getDayOfTheWeekAsNumber(unfrozenDate) }}
      </div>
      <div class="circle">
        {{ getDayOfTheWeekAsNumber(secondaryExpirationDate) }}
      </div>
    </div>
    <div class="right-section">
      <div class="product-name">
        {{ product.name | slice:0:16 }}
      </div>
      <div>
        <span class="small-text">Pré-pre </span>
        <span class="uppercase bold underline">
          {{ getDayOfTheWeek(unfrozenDate) }}
        </span>
        <span class="bold">
          {{ unfrozenDate | date : 'yyyy-MM-dd HH:mm' }}
        </span>
      </div>
      <div>
        <span class="small-text">Val </span>
        <span class="uppercase bold underline">
          {{ getDayOfTheWeek(secondaryExpirationDate) }}
        </span>
        <span class="bold">
          {{ secondaryExpirationDate | date : 'yyyy-MM-dd HH:mm' }}
        </span>
      </div>
      <div class="small-text" style="margin-top: 2px;">
        Val 1ª
        {{
          primaryExpirationDate
            ? (primaryExpirationDate | date : 'yyyy-MM-dd')
            : lot
        }}
      </div>
    </div>
  </div>`,
  styleUrl: './tag.scss',
})
export class FrozenTagComponent {
  @Input() nonApplicable!: boolean;
  @Input() product!: Product;
  @Input() lot?: string;
  @Input() primaryExpirationDate?: string;
  @Input() unfrozenDate!: Date | null;
  @Input() secondaryExpirationDate: Date | null = null;

  getDayOfTheWeek(date: Date | null): string {
    if (date === null) return '-';
    return getDayOfTheWeek(date);
  }

  getDayOfTheWeekAsNumber(date: Date | null): string {
    if (date === null) return '-';
    return getDayOfTheWeekAsNumber(date);
  }
}
