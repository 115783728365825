export function getHerdadeOrLagarCountersString(field: any, deviceUUID: string): string {
  console.log("HERE");
  if (!field || !field.waybillCounters) return "10001";
  let idx = field.waybillCounters.findIndex((e: any) => e.dId === deviceUUID);
  if (idx === -1) {
    idx = field.waybillCounters.length;
    return (
      `${idx + 1}` +
      parseInt(`1`).toLocaleString("en-US", {
        minimumIntegerDigits: 4,
        useGrouping: false,
      })
    );
  }
  const ret = parseInt(
    `${field.waybillCounters[idx].counter + 1}`
  ).toLocaleString("en-US", {
    minimumIntegerDigits: 4,
    useGrouping: false,
  });
  return ret;
}

export function getClosestHerdadeOrLagarField(fields: any[], fieldId: string | number): any {
  return fields.find((field) => field.id === fieldId);
}

export function getSociedadeByCode(societies: any[], societyCode: string | number): any {
  return societies.find((society) => society.codigo_sociedade === societyCode);
}
