// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, of } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import { ExecutionState } from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import cuid from 'cuid';
import { SensorsService } from 'src/app/services/sensors.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpInserMachine implements IMyTaskHandler {
  name = 'follow-up-insert-machine';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private sensorsService: SensorsService
  ) {}

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
  
    const nameMachine = data['Nome do Equipamento'];
    const category = data['Categoria'];
    const brand = data['Marca'];
    const model = data['Modelo'];
    const serialNumber = data['Número de Série ou Foto'];
    const acquisitionDate = data['Data de Aquisição'];
    const warrantyEndDate = data['Data de Término da Garantia'];


    
    const sellerDataArray = data['Descrição do Vendedores'].map(
      (vendedor: any) => ({
        nomeVendedor: vendedor['Nome do Vendedor'] || '',
        contatoPrincipal: vendedor['Contato Principal'] || '',
        telefone: vendedor['Telefone'] || '',
        email: vendedor['Email'] || '',
        areaEspecializacao: vendedor['Área de Especialização'] || '',
        endereco: vendedor['Endereço'] || '',
      })
    );
    
    const descricaoPecaArray = data['Descrição da Peça'].map((peca: any) => ({
      nomePeca: peca['Nome da Peça'] || '',
      quantidadeAtual: peca['Quantidade Atual'] || '',
      quantidadeMinima: peca['Quantidade Mínima'] || '',
      localizacaoEstoque: peca['Localização no Estoque'] || '',
      fornecedorPreferencial: peca['Fornecedor'] || '',
    }));
  
    const newMachine = {
      id: cuid(),
      machine: nameMachine,
      infoMachine: {
        categoria: category || '', // Incluindo categoria da máquina
        brand: brand || '', // Incluindo marca da máquina
        model: model || '', // Incluindo modelo da máquina
        serialNumber: serialNumber || '', // Incluindo número de série
        acquisitionDate: acquisitionDate || '', // Incluindo número de série
        warrantyEndDate: warrantyEndDate || '', // Incluindo número de série
      },
      sellerData: sellerDataArray,
      descricaoPeca: descricaoPecaArray,
      isMachineForHelp: true, // Indicando que faz parte do myhhacpHelp
      storeId: store.id,
      createdByUser: true, // Indica que foi criada pelo utilizador
      system: 'myHacpHelp', // Nome do sistema em que será registrada
    };
  
    // Create a new manual using the manual service
    this.sensorsService
      .newMachine(newMachine, this.authenticationService.getAuthInfo())
      .subscribe(
        (response) => {
          console.log('Success:', response);
        },
        (error) => {
          console.error('Error:', error);
        }
      );

    return of(null);
  }
  
}
