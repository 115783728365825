<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{'Lista de lotes' | translate}} - {{product.name}}</h3>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <div class="form-group">
          <label>{{"Pesquisa:" | translate}}</label>
          <input
            class="form-control"
            type="text"
            [(ngModel)]="searchValue"
            [placeholder]="'Lote ou data de validade'"
            [formControl]="searchControl"
          />
        </div>

        <ion-list style="height: 60vh; overflow-y: auto">
          <ion-item
            *ngFor="let batch of searchResult"
            [class.selected]="selectedBatches.includes(batch)"
            (click)="toggleBatchSelection(batch)"
            detail="false"
          >
            <ion-label>
              <h2>Lote: {{ batch.lot }}</h2>
              <p>Data Validade: {{ batch.expirationDate }}</p>
            </ion-label>
            <ion-icon
              slot="end"
              name="square-outline"
              *ngIf="!selectedBatches.includes(batch)"
            ></ion-icon>
            <ion-icon
              slot="end"
              name="checkbox"
              *ngIf="selectedBatches.includes(batch)"
            ></ion-icon>
          </ion-item>

          <ion-item *ngIf="searchResult.length === 0">
            <ion-label>{{ "Sem resultados" | translate }}</ion-label>
          </ion-item>
        </ion-list>
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
          <button class="btn btn-cancel light-grey" (click)="pressCreateNew()">
            {{'Criar novo lote'}}
          </button>
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="submitSelectedBatches()">
            {{'OK'|translate}}
          </button>
          <button class="btn btn-cancel light-grey" (click)="close()">
            {{'CANCEL'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
