import { Component, OnDestroy } from '@angular/core';
import { BleClient } from '@capacitor-community/bluetooth-le';
import { ModalController, Platform } from '@ionic/angular';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { AlertService } from './services/alert.service';
import { PromptModal } from './components/prompt/prompt.modal';
import { fromEvent, Subscription } from 'rxjs';
import { SyncingService } from './services/syncing';
import { AuthenticationService } from './services/authentication';
import { TasksService } from './services/tasks.service';
import { UserInfo } from './entities/authentication-info';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { EnvironmentService } from './services/environment.service';
import { ConsoleToggleService } from './services/console-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isDesktop = true;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private syncingService: SyncingService,
    private storage: Storage,
    public translate: TranslateService, // Change access modifier to public
    protected envService: EnvironmentService,
    private consoleToggleService: ConsoleToggleService
  ) {
    this.consoleToggleService.disableConsoleInProduction();
    this.platform.ready().then(() => {
      this.translate.setDefaultLang('pt'); // Set default language to Portuguese
      this.translate.use('pt'); // Use Portuguese as the default language
      console.log(this.platform.platforms());
      this.detectPlatform();
      this.storage.create();
      if (this.platform.is('android')) {
        this.requestNotificationPermission();
        if (!envService.isMachineAWS()) {
          this.checkForUpdates();
          setInterval(() => {
            this.checkForUpdates();
          }, 60 * 1000);
        }
        this.initializeApp();
      }
    });
  }

  detectPlatform() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.isDesktop = false;
    }
  }

  async requestNotificationPermission() {
    const permission = await LocalNotifications.requestPermissions();

    // Verifica se a permissão foi concedida
    if (permission.display !== 'granted') {
      console.error('Notification permissions not granted!');
    }
  }

  initializeApp() {
    BleClient.initialize();
  }

  async checkForUpdates() {
    try {
      await LiveUpdate.setChannel({
        channel: environment.channel,
      });
      const result = await LiveUpdate.sync();
      console.log('Live Update check result: ', result);

      if (result.nextBundleId) {
        const modal = await this.modalController.create({
          component: PromptModal,
          componentProps: {
            title: 'Nova atualização disponível',
            text: 'Descarregue a nova atualização.',
            hasYesButton: false,
            noText: 'Descarregar',
          },
        });
        await modal.present();
        await modal.onDidDismiss();
        await LiveUpdate.reload();
      } else {
        await LiveUpdate.ready();
      }
    } catch (error) {
      console.error('Live Update check failed', error);
    }
  }
}
