<!-- <div class="container scroll-content" #scrollContent> -->
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{'EMPLOYEE_PIN' | translate}}</h3>
        <img src="assets/img/close.svg" (click)="close()" />
      </div>
    </div>
    <div class="modalContent" *ngIf="!isValidatingPassword">
      <div class="modalDescription">
        <div class="form-group">
          <label for="pin">{{'PIN' | translate}}:</label>
          <input
            id="pin"
            type="tel"
            class="form-control"
            placeholder="{{'EXAMPLE_SHORT' | translate}} 1234"
            [(ngModel)]="pin"
          />
        </div>
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
          <button class="btn btn-cancel" (click)="showDeveloperNotes()">
            Notas do Desenvolvedor
          </button>
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="loginEmployee()">OK</button>
        </div>
      </div>
    </div>

    <div class="modalContent" *ngIf="isValidatingPassword">
      <div class="modalDescription">
        <div class="form-group">
          <label for="newPin">{{'NEW_PIN' | translate}}</label>
          <input
            id="newPin"
            type="tel"
            class="form-control"
            placeholder="{{'EXAMPLE_SHORT' | translate}} 1234"
            [(ngModel)]="newPin"
          />
        </div>
      </div>
      <div class="modalActions">
        <div class="actionsLeft">
          <button class="btn btn-cancel" (click)="showDeveloperNotes()">
            Notas do Desenvolvedor
          </button>
        </div>
        <div class="actionsRight">
          <button class="btn btn-ok" (click)="updateEmployeePin()">OK</button>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
