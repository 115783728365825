<div class="container scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader task">
      <div class="modalTitle">
        <h3 class="modalTitleText">{{task.title}}</h3>
        <h2 class="modalSubtitleText" *ngIf="task.subtitle">{{task.subtitle}}</h2>
        <img src='assets/img/close.svg' slot="end" (click)="close()"/> 

      </div>
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        <div class="modalTextSection">
          <h4 class="justification-modal-header-text">{{'FIRST_STEP'|translate}}</h4>
        </div>
        <div class="form-group">
          <label class="label">{{'NON_CONFORMITY'|translate}}</label>
          <select class="form-control" [(ngModel)]="non_conformitySelector"
                  (ngModelChange)="changeNonConformityValue()">
            <option [ngValue]="'OUTRA'">{{'OTHER'|translate}}</option>
            <option *ngFor="let option of task.nonConformities" [ngValue]="option">{{option}}</option>
          </select>
          <input class="form-control" [(ngModel)]="response.non_conformity" type="text"
                 *ngIf="non_conformitySelector==='OUTRA'">
        </div>
        <div class="form-group">
          <label class="label">{{'CAUSES'|translate}}</label>
          <select class="form-control" [(ngModel)]="causesSelector" (ngModelChange)="changeCausesValue()">
            <option [ngValue]="'OUTRA'">{{'OTHER'|translate}}</option>
            <option *ngFor="let option of task.causes" [ngValue]="option">{{option}}</option>
          </select>
          <input class="form-control" [(ngModel)]="response.causes" type="text" *ngIf="causesSelector==='OUTRA'">
        </div>
        <div class="form-group">
          <label class="label">{{'ACTION'|translate}}:</label>
          <select class="form-control" [(ngModel)]="correctionSelector" (ngModelChange)="changeCorrectionValue()">
            <option [ngValue]="'OUTRA'">{{'OTHER'|translate}}</option>
            <option *ngFor="let option of task.corrections" [ngValue]="option">
              {{option}}
            </option>
          </select>
          <input class="form-control" [(ngModel)]="response.correction" type="text" *ngIf="correctionSelector==='OUTRA'">
        </div>
        <div class="form-group">
          <label for="comment">{{'COMMENTARY'|translate}}:</label>
          <textarea class="form-control" rows="2" id="comment" [(ngModel)]="response.ncCommentary"></textarea>
        </div>
      </div>
      <div class="task">
      </div>
      <div class="modalTextSection">
        <h4 class="justification-modal-header-text">{{'SECOND_STEP'|translate}}</h4>
      </div>
      <justification-modal-task [task]="task" [response]="response" [isValid]="isValid"></justification-modal-task>
      <div class="modalActions task">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="pressSubmitAndCloseTaskLater()">{{'CLOSE_LATER'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
