<div class=" scroll-content" #scrollContent>
  <div class="modalItem">
    <div class="modalHeader">
      <div class="modalTitle">
        <h3>{{'Tarefas filtradas'|translate}}</h3>
        <img src='assets/img/close.svg' (click)="close()"/>
      </div>
    </div>
    <div class="modalContent">
      <app-custom-form-task *ngFor="let task of tasks" [task]="task" (taskClosedSuccessfully)="onTaskClosedSuccessfully(tasks)"></app-custom-form-task>
      <div class="modalActions">
        <div class="actionsLeft">
        </div>
        <div class="actionsRight">
          <button class="btn btn-cancel light-grey" (click)="close()">{{'CANCEL'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
