import { Component, NgZone } from '@angular/core';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import {
  NavController,
  ModalController,
  ToastController,
  Platform,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import cuid from 'cuid';
import moment from 'moment-timezone';
import { Batch } from '../../../entities/batch';
import {
  Product,
  getLastBatchInformation,
  getProductDesignation,
  getProductLastBatch,
} from '../../../entities/product';
import { NonProgrammedTask } from '../../../entities/tasks/task';
import { AuthenticationService } from '../../../services/authentication';
import { CustomTaskTypesService } from '../../../services/custom-task-types.service';
import { ProductsService } from '../../../services/products.service';
import { StoresService } from '../../../services/stores.service';
import { SyncingService } from '../../../services/syncing';
import { TasksService } from '../../../services/tasks.service';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { BrotherPrinterService } from '../../../services/brother-printer.service';
import { RefrigeratedProductDetailModal } from './refrigerated-product-detail/refrigerated-product-detail.modal';

import {
  BatchTagInfo,
  PrinterNotSelectedError,
  ProductWithTagInfo,
  TagInfo,
  TagPrintingService,
} from '../../../services/tags-printing.service';
import {
  FNSDateFormats,
  MomentDateFormats,
} from '../../../../utils/date.utils';
import { format, isBefore, parse, parseISO, startOfDay } from 'date-fns';
import { CustomTaskService } from 'src/app/services/custom-task.service';
import { PrinterConfigModal } from 'src/app/components/modals/printer-config.modal';

interface ProductWithPrintedStatus extends Product {
  status?: 'printed';
  preparationDate?: Date;
}

@Component({
  selector: 'refrigerated-products-page',
  templateUrl: 'refrigerated-products.page.html',
  styleUrls: ['./refrigerated-products.page.scss'],
})
export class RefrigeratedProductsPage {
  searchTerm: string = '';
  searchControl: FormControl;
  searching = false;
  isSearched = false;
  filter: any;

  printProduct: any = null;
  preparationDate: Date = new Date();

  allProducts: Product[] = [];
  products: ProductWithPrintedStatus[] = [];
  tagsInfo: Record<string, TagInfo> = {};
  formGroups: any = {};
  counter = 0;
  private ITEMS_PER_TICK = 12;

  init = () => {
    this.searchTerm = '';
    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search) => {
        this.products = this.allProducts.filter((p: Product) => {
          if (!p || !p.name) return false;
          return (
            p.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
          );
        });
        this.counter = this.ITEMS_PER_TICK;
        this.searching = false;
      });
    this.allProducts = this.productsService.getProducts().sort((p1, p2) => {
      return p1.name.localeCompare(p2.name);
    });
    // .filter((p) => p.expirationType === "Refrigerado");
    this.products = this.allProducts;

    this.products.map((p) => {
      if (!this.tagsInfo[p.id]) {
        const lastBatch: Batch | null = getLastBatchInformation(p);
        let expirationDate = lastBatch?.primaryExpirationDate?.substring(0, 10);
        this.tagsInfo[p.id] = {
          batch: {
            lot: lastBatch?.lot,
            expirationDate: expirationDate,
          },
          quantityTags: lastBatch?.quantityTags,
        };
        this.formGroups[p.id] = this.formBuilder.group(
          {
            lot: [''],
            expirationDate: [''],
            quantityTags: [
              '',
              [
                Validators.required,
                Validators.min(0),
                Validators.pattern(/^\d+$/),
              ],
            ],
          },
          {
            validator: (g: FormGroup) =>
              this.isCompositeProduct(p) || this.atLeastOneFilledValidator(g),
          }
        );
        if (p.ingredientsIds && p.ingredientsIds.length) {
          this.formGroups[p.id].controls.lot.disable();
          this.formGroups[p.id].controls.expirationDate.disable();
        }
      }
    });
    this.counter = this.ITEMS_PER_TICK;
    this.syncing.initer = this.init;
  };

  constructor(
    private nav: NavController,
    private syncing: SyncingService,
    public translate: TranslateService,
    private modalController: ModalController,
    private storesService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private tasksService: TasksService,
    private customTaskService: CustomTaskService,
    private productsService: ProductsService,
    private authenticationService: AuthenticationService,
    private platform: Platform,
    private zone: NgZone,
    private printerBrotherService: BrotherPrinterService,
    private tagPrintingService: TagPrintingService
  ) {
    this.searchControl = new FormControl();
  }

  ionViewWillEnter() {
    this.init();
  }

  onSearchInput() {
    this.isSearched = true;
    this.searching = true;
  }

  async openProductDetail(product: Product) {
    const modal = await this.modalController.create({
      component: RefrigeratedProductDetailModal,
      componentProps: {
        product,
        tagInfo: this.tagsInfo[product.id],
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (!data) return;
    this.tagsInfo[product.id] = data;
    this.formGroups[product.id].touched =
      data.quantityTags === undefined ? false : true;
  }

  doInfinite(event: any) {
    this.counter =
      this.counter + this.ITEMS_PER_TICK <= this.allProducts.length
        ? this.counter + this.ITEMS_PER_TICK
        : this.allProducts.length;

    // Complete the infinite scroll action
    event.target.complete();
  }

  getProductDesignation(product: Product) {
    return getProductDesignation(product);
  }

  touch(productId: string, type?: string, value?: any) {
    this.formGroups[productId].touched = true;
  }

  isProcessing: boolean = false;
  async printAll() {
    if (this.isProcessing) return;
    this.isProcessing = true;

    const preparationDate: Date = new Date();

    const productsToPrint: ProductWithTagInfo[] = this.allProducts
      .filter(
        (p) =>
          this.formGroups[p.id].touched &&
          this.tagsInfo[p.id].quantityTags !== undefined &&
          (this.tagsInfo[p.id].quantityTags as number) > 0
      )
      .map((p) => {
        return { product: p, tagInfo: { ...this.tagsInfo[p.id] } };
      })
      .filter(
        (p) =>
          this.isCompositeProduct(p.product) ||
          this.isValidBatch(p.tagInfo.batch)
      );

    let toast: HTMLIonToastElement;

    if (productsToPrint.length === 0) {
      toast = await this.toastController.create({
        message: 'Preencher a quantidade nos produtos para imprimir',
        duration: 3000,
        position: 'top',
      });
      await toast.present();
    } else {
      this.tagPrintingService
        .printMany(preparationDate, productsToPrint)
        .subscribe({
          next: async (p: ProductWithTagInfo) => {
            this.processExpirationDates(preparationDate, [p]);
            this.tagsInfo[p.product.id].quantityTags = undefined;
            this.formGroups[p.product.id].touched = false;
            toast = await this.toastController.create({
              message: p?.product?.name,
              duration: 2000,
              position: 'top',
            });
            await toast.present();
          },
          error: async (error) => {
            if (error instanceof PrinterNotSelectedError) {
              const errorToast = await this.toastController.create({
                message:
                  'Nenhuma impressora selecionada. Por favor selecione uma impressora e volte a mandar imprimir.',
                color: 'warning',
                duration: 3000,
                position: 'top',
              });
              await errorToast.present();
              await this.showPrinterConfig();
            } else {
              const errorToast = await this.toastController.create({
                message: error.message || error,
                color: 'danger',
                duration: 3000,
                position: 'top',
              });
              await errorToast.present();
            }
          },
          complete: async () => {
            if (toast) await toast.dismiss();
            this.isProcessing = false;
          },
        });
    }

    this.isProcessing = false;
  }

  getIngredientsSecondaryExpirationDates(product: Product): (Date | null)[] {
    return this.productsService.getIngredientsLastExpirationDates(product);
  }

  atLeastOneFilledValidator(group: FormGroup) {
    const lot = group.controls['lot'];
    const expirationDate = group.controls['expirationDate'];

    if (!lot.value && !expirationDate.value) {
      return { atLeastOneFilled: true };
    }
    return false;
  }

  async processExpirationDates(
    preparationDate: Date,
    products: ProductWithTagInfo[]
  ) {
    return this.productsService.processExpirationDates(
      preparationDate,
      products
    );
  }

  async showInfo() {
    const toast = await this.toastController.create({
      message: 'Pdf em desenvolvimento',
      duration: 2000,
      position: 'top',
    });
    await toast.present();
  }

  onDateInputChange(event: any, productId: string) {
    const input = event.value;
    let formattedInput = input.replace(/\D/g, ''); // Remove any non-digit characters

    // Limit to 8 digits (YYYYMMDD)
    if (formattedInput.length > 8) {
      formattedInput = formattedInput.substring(0, 8);
    }

    // Add dashes after the year and month
    if (formattedInput.length > 6) {
      formattedInput =
        formattedInput.substring(0, 4) +
        '-' +
        formattedInput.substring(4, 6) +
        '-' +
        formattedInput.substring(6);
    } else if (formattedInput.length > 4) {
      formattedInput =
        formattedInput.substring(0, 4) + '-' + formattedInput.substring(4);
    }

    // Validate month and day
    const year = parseInt(formattedInput.substr(0, 4), 10);
    const month = parseInt(formattedInput.substr(5, 2), 10);
    const day = parseInt(formattedInput.substr(8, 2), 10);

    if (month > 12) {
      formattedInput =
        formattedInput.substring(0, 5) + '12' + formattedInput.substring(7);
    }

    if (
      day > 31 ||
      (month === 2 && day > 29) ||
      ([4, 6, 9, 11].indexOf(month) >= 0 && day > 30)
    ) {
      formattedInput =
        formattedInput.substring(0, 8) + (month === 2 ? '28' : '30');
    }

    // Truncate input after 10 characters (YYYY-MM-DD)
    formattedInput = formattedInput.substring(0, 10) + '';

    // Update the input value
    this.tagsInfo[productId].batch.expirationDate = formattedInput;
  }

  isValidBatch(batch: BatchTagInfo): boolean {
    return !!batch.lot || !!batch.expirationDate;
  }

  isCompositeProduct(p: Product): boolean {
    return !!(p.ingredientsIds && p.ingredientsIds.length > 0);
  }

  async showPrinterConfig() {
    const modal = await this.modalController.create({
      component: PrinterConfigModal,
    });
    await modal.present();
  }
}
