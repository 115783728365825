import { Injectable, Injector, NgZone } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http';
import { Link } from '../../utils/link';
import { JSON_HEADERS } from '../../utils/headers';
import { AuthenticationInfo, UserInfo } from '../entities/authentication-info';
import { AuthenticationService } from './authentication';
import {
  NewTaskUploadInfo,
  RespondTaskUploadInfo,
  TaskUploadInfo,
  UpdateTaskUploadInfo,
  UploadAll,
} from './upload-all';
import moment from 'moment';
import { MediaService } from './media.service';
import { UrlEncodedString } from '../../utils/url-encoded-string';
import { FieldsService } from './fields.service';
import { Observable, forkJoin, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import {
  getNext4Am,
  getPrevious4Am,
  getPreviousMonth4Am,
} from '../../utils/date.utils';
import { Task, TaskResponse } from '../entities/tasks/task';
import { StoresService } from './stores.service';
import { environment } from '../../environments/environment';
import { arrayAddOrReplace } from '../../utils/array.utils';
import { EnvironmentService } from './environment.service';

export type OnTaskClosedSuccessfully = (task: Task) => void;
export type OnTaskCreatedSuccessfully = (task: Task) => void;

@Injectable()
export class TasksService {
  tasksCache: Task[] = [];
  tasksCacheToday: Task[] = [];
  tasksCacheOther: Task[] = [];
  tasksCacheSyncing: Task[] = [];
  historyCache: Task[] = [];
  historyCacheOnTime: Task[] = [];
  historyCacheLate: Task[] = [];
  historyCacheWithPhoto: Task[] = [];
  historyCacheWithCommentary: Task[] = [];
  historyCacheToday: Task[] = [];
  justificationCache: Task[] = [];
  justificationCacheToday: Task[] = [];
  justificationCacheNonConform: Task[] = [];
  justificationCacheLate: Task[] = [];
  justificationCacheWithPhoto: Task[] = [];
  justificationCacheWithCommentary: Task[] = [];

  canceledTasksCache: Task[] = [];

  historyEtag = '';
  nonConformFromStoreEtag = '';
  lateFromStoreEtag = '';
  tasksFromStoreEtag = '';
  automaticTasksWereGenerated = false;
  isDashboardOutdated = false;

  handleTaskSubmitSuccess = (oldTask: Task, newTask: Task) => {
    this.updateTaskInCache(oldTask, newTask);
  };
  handleTaskSubmitError = (
    err: any,
    task: Task,
    response: any,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number,
    onTaskClosedSuccessfully?: OnTaskClosedSuccessfully
  ) => {
    this.uploadAll
      .addPendingAnswerTaskRequest(
        new RespondTaskUploadInfo(
          task,
          response,
          authInfo,
          originalTryTimestamp
        )
      )
      .subscribe(() => {
        if (
          !this.storesService.store ||
          !this.storesService.store.id ||
          task.target.storeId !== this.storesService.store.id
        )
          return;
        const newTask = {
          ...task,
          responses: [response],
        };
        this.updateTaskInCache(task, newTask);
        if (onTaskClosedSuccessfully) onTaskClosedSuccessfully(task);
      });
  };

  handleTaskUpdateSuccess = (oldTask: Task, newTask: Task) => {
    this.updateTaskInCache(oldTask, newTask);
  };
  handleTaskUpdateError = (
    err: any,
    task: Task,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) => {
    this.uploadAll
      .addPendingUpdateTaskRequest(
        new UpdateTaskUploadInfo(task, authInfo, originalTryTimestamp)
      )
      .subscribe(() => {
        if (
          !this.storesService.store ||
          !this.storesService.store.id ||
          task.target.storeId !== this.storesService.store.id
        )
          return;
        this.updateTaskInCache(task, task);
      });
  };

  handleNewTaskSuccess = (task: Task) => {
    if (
      !this.storesService.store ||
      !this.storesService.store.id ||
      task.target.storeId !== this.storesService.store.id
    )
      return;
    if (this.uploadAll.uploading) {
      this.removeTaskFromCaches(task);
    } else {
      this.addTaskToTasksCache(task);
    }
  };
  handleNewTaskError = (
    err: any,
    task: Task,
    authInfo: AuthenticationInfo,
    originalTryTimestamp: number
  ) => {
    this.uploadAll
      .addPendingNewTaskRequest(
        new NewTaskUploadInfo(task, authInfo, originalTryTimestamp)
      )
      .subscribe(() => {
        if (
          !this.storesService.store ||
          !this.storesService.store.id ||
          task.target.storeId !== this.storesService.store.id
        )
          return;
        arrayAddOrReplace(this.tasksCache, task, task.id);
        arrayAddOrReplace(this.tasksCacheSyncing, task, task.id);
      });
  };

  updateTaskInCache = (oldTask: Task, newTask: Task) => {
    this.removeTaskFromCaches(oldTask);
    const id = this.authenticationService.getUserInfo()?.id;
    const userFound = newTask.target.userIds.find((uid) => uid === id);
    if (
      newTask.target.userIds &&
      newTask.target.userIds.length !== 0 &&
      !userFound
    )
      return;
    if (newTask.canceled) {
      if (!newTask.responses || !newTask.responses[0]) {
        this.addTaskToTasksCache(newTask);
      } else if (newTask.responses[0].isInConformity) {
        this.addTaskToHistoryCache(newTask);
      } else {
        this.addTaskToJustificationCache(newTask);
      }
    } else if (
      newTask.responses &&
      newTask.responses[0] &&
      newTask.responses[0].isInConformity
    ) {
      this.addTaskToHistoryCache(newTask);
    } else if (
      newTask.responses &&
      newTask.responses[0] &&
      !newTask.responses[0].isInConformity
    ) {
      this.addTaskToJustificationCache(newTask);
    }
  };

  removeTaskFromCaches(task: Task) {
    removeTaskFromCache(task, this.tasksCacheSyncing);
    if (task.page === 'tasks') {
      removeTaskFromCache(task, this.tasksCache);
      removeTaskFromCache(task, this.tasksCacheToday);
      removeTaskFromCache(task, this.tasksCacheOther);
    } else if (task.page === 'justification') {
      removeTaskFromCache(task, this.justificationCache);
      removeTaskFromCache(task, this.justificationCacheLate);
      removeTaskFromCache(task, this.justificationCacheNonConform);
      removeTaskFromCache(task, this.justificationCacheToday);
      removeTaskFromCache(task, this.justificationCacheWithCommentary);
      removeTaskFromCache(task, this.justificationCacheWithPhoto);
    } else if (task.page === 'history') {
      removeTaskFromCache(task, this.historyCache);
      removeTaskFromCache(task, this.historyCacheLate);
      removeTaskFromCache(task, this.historyCacheOnTime);
      removeTaskFromCache(task, this.historyCacheWithCommentary);
      removeTaskFromCache(task, this.historyCacheWithPhoto);
      removeTaskFromCache(task, this.historyCacheToday);
    } else {
      removeTaskFromCache(task, this.tasksCache);
      removeTaskFromCache(task, this.tasksCacheToday);
      removeTaskFromCache(task, this.tasksCacheOther);
      removeTaskFromCache(task, this.justificationCache);
      removeTaskFromCache(task, this.justificationCacheLate);
      removeTaskFromCache(task, this.justificationCacheNonConform);
      removeTaskFromCache(task, this.justificationCacheToday);
      removeTaskFromCache(task, this.justificationCacheWithCommentary);
      removeTaskFromCache(task, this.justificationCacheWithPhoto);
      removeTaskFromCache(task, this.historyCache);
      removeTaskFromCache(task, this.historyCacheLate);
      removeTaskFromCache(task, this.historyCacheOnTime);
      removeTaskFromCache(task, this.historyCacheWithCommentary);
      removeTaskFromCache(task, this.historyCacheWithPhoto);
      removeTaskFromCache(task, this.historyCacheToday);
    }

    function removeTaskFromCache(task: Task, cache: Task[]) {
      const i = findWithAttr(cache, 'id', task.id);
      if (i > -1) {
        cache.splice(i, 1);
      }

      function findWithAttr(array: Task[], attr: keyof Task, value: any) {
        for (let i = 0; i < array.length; i += 1) {
          if (array[i][attr] === value) {
            return i;
          }
        }
        return -1;
      }
    }
  }

  private uploadAll: UploadAll;
  private fieldsService: FieldsService;

  constructor(
    private http: AuthenticatedHttpService,
    private authenticationService: AuthenticationService,
    private zone: NgZone,
    private mediaService: MediaService,
    private injector: Injector,
    private storesService: StoresService,
    protected envService: EnvironmentService
  ) {
    this.uploadAll = injector.get(UploadAll);
    this.fieldsService = injector.get(FieldsService);
  }

  getTasksFromServer(link: Link) {
    return this.http.get(link).pipe(
      map((response: any) => {
        this.automaticTasksWereGenerated = false;
        const resp = {
          tasks: [] as Task[],
          etag: response.headers.get('etag') || '',
          status: response.status,
        };
        if (response.status === 304) {
          return resp;
        }
        const tasks = response.body; // Directly access the response body
        /* tasks.forEach((t: Task) => {
          this.mediaService.getPicturesFromTask(t);
          this.mediaService.getInfoFromTask(t);
        }); */
        resp.tasks = tasks;
        return resp;
      })
    );
  }

  getTaskFormTitleByFormId(task: Task) {
    return this.fieldsService.getFormTitle(task.formId);
  }

  clearTasksCache() {
    this.tasksCacheSyncing.length = 0;
    this.tasksCache.length = 0;
    this.tasksCacheToday.length = 0;
    this.tasksCacheOther.length = 0;
  }

  getTasks(filter?: string): Task[] {
    switch (filter) {
      case 'TODAY':
        return this.tasksCacheToday; // Return tasks for today
      case 'OTHER':
        return this.tasksCacheOther; // Return other tasks
      case 'SYNC':
        return this.tasksCacheSyncing; // Return syncing tasks
      case 'IN_TRANSIT':
        return this.tasksCache.filter(
          (t) => t.formId === 'clku3obef000c3q74zyoqm788'
        ); // Filter tasks in transit
      case 'PATIO':
        return this.tasksCache.filter(
          (t) => t.formId === 'clku3oyy9000f3q74h2spq65r'
        ); // Filter tasks in patio
      case 'DIRTY':
        return this.tasksCache.filter(
          (t) => t.formId === 'clku3paq0000g3q74gxqksaf4'
        ); // Filter dirty tasks
      case 'CLEAN':
        return this.tasksCache.filter(
          (t) => t.formId === 'clku3ppc3000j3q7472ye0pq1'
        ); // Filter clean tasks
      case 'ANALYTICS':
        return this.tasksCache.filter(
          (t) => t.formId === 'clku4pxj6000k3q74n8qr4q0g'
        ); // Filter analytics tasks
      case 'productionIM2':
      case 'unpackgedProductsIntermarche2':
        return this.tasksCache.filter((t) => t.type === filter); // Filter by type
      default:
        return this.tasksCache; // Return all tasks if no filter provided or unrecognized filter
    }
  }

  getTasksByType(taskType: string): Task[] {
    return this.tasksCache.filter((t) => t.type === taskType);
  }

  getTasksCount(filter?: string): number {
    return this.getTasks(filter).length;
  }

  fillTasksCaches(tasks: Task[]) {
    tasks.forEach((t) => {
      this.addTaskToTasksCache(t);
    });
  }

  addTaskToTasksCache = (t: Task) => {
    t.page = 'tasks';
    if (this.tasksCache.find((t1) => t.id === t1.id)) return;

    if (t.canceled) {
      this.canceledTasksCache.push(t);
      return;
    }

    this.tasksCache.push(t);
    if (t.endDate < getNext4Am()) {
      this.tasksCacheToday.push(t);
    } else {
      this.tasksCacheOther.push(t);
    }
  };

  addTaskToJustificationCache = (t: Task) => {
    t.page = 'justification';

    if (t.canceled) {
      this.canceledTasksCache.push(t);
      return;
    }

    this.justificationCache.push(t);

    if (t.responses) {
      this.justificationCacheNonConform.push(t);
    }

    if (!t.responses) {
      this.justificationCacheLate.push(t);
    }

    if (t.responses) {
      if (!(t.responses[0].clientDate < getPrevious4Am())) {
        this.justificationCacheToday.push(t);
      }
    } else {
      if (!(t.endDate < getPrevious4Am())) {
        this.justificationCacheToday.push(t);
      }
    }

    if (
      t.responses &&
      t.responses[0].clientDate > getPrevious4Am() &&
      t.responses[0].clientDate < getNext4Am()
    ) {
      if (
        (t.responses[0].picturesIds && t.responses[0].picturesIds.length > 0) ||
        (t.responses[0].invoicePicturesIds &&
          t.responses[0].invoicePicturesIds.length > 0) ||
        (t.responses[0].ticketPicturesIds &&
          t.responses[0].ticketPicturesIds.length > 0)
      ) {
        this.justificationCacheWithPhoto.push(t);
      }

      if (t.responses[0].ncCommentary) {
        this.justificationCacheWithCommentary.push(t);
      }
    }
  };

  addTaskToHistoryCache = (t: Task) => {
    t.page = 'history';

    if (t.canceled) {
      this.canceledTasksCache.push(t);
      return;
    }

    this.historyCache.unshift(t);
    if (t.responses && t.responses[0].clientDate <= t.endDate) {
      this.historyCacheOnTime.unshift(t);
    }
    if (t.responses && t.responses[0].clientDate > t.endDate) {
      this.historyCacheLate.unshift(t);
    }
    if (
      t.responses &&
      t.responses[0].clientDate < getNext4Am() &&
      t.responses[0].clientDate > getPrevious4Am()
    ) {
      this.historyCacheToday.unshift(t);
    }
    if (t.responses && t.responses[0].clientDate < getNext4Am()) {
      if (
        (t.responses[0].picturesIds && t.responses[0].picturesIds.length > 0) ||
        (t.responses[0].invoicePicturesIds &&
          t.responses[0].invoicePicturesIds.length > 0) ||
        (t.responses[0].ticketPicturesIds &&
          t.responses[0].ticketPicturesIds.length > 0) ||
        (t.responses[0].productPicturesIds &&
          t.responses[0].productPicturesIds.length > 0)
      ) {
        this.historyCacheWithPhoto.unshift(t);
      }
      if (t.responses[0].ncCommentary || t.responses[0].commentary) {
        this.historyCacheWithCommentary.unshift(t);
      }
    }
  };

  sortTasksCaches() {
    this.tasksCache.sort((a, b) => sortTaskFunc(a, b));
    this.tasksCacheToday.sort((a, b) => sortTaskFunc(a, b));
    this.tasksCacheOther.sort((a, b) => sortTaskFunc(a, b));

    function sortTaskFunc(a: Task, b: Task) {
      if (moment(a.startDate).isBefore(moment(b.startDate))) {
        return -1;
      } else if (moment(a.startDate).isAfter(moment(b.startDate))) {
        return 1;
      } else {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }

  getTasksFromForm(formId: string): Task[] {
    return this.getTasks().filter((t) =>
      this.isTaskFromFormPredicate(t, formId)
    );
  }

  getTasksFromFormCount(formId: string): number {
    return this.getTasks().filter((t) =>
      this.isTaskFromFormPredicate(t, formId)
    ).length;
  }

  isTaskFromFormPredicate = (t: Task, formId: string) =>
    t.formId === formId && !t.responses;

  clearHistoryCache() {
    this.historyCache.length = 0;
    this.historyCacheLate.length = 0;
    this.historyCacheOnTime.length = 0;
    this.historyCacheWithCommentary.length = 0;
    this.historyCacheWithPhoto.length = 0;
    this.historyCacheToday.length = 0;
  }

  getHistoryFromStore(companyId: string, storeId: string): Observable<any> {
    const queryString = this.getQueryStringForHistory();
    const link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.historyEtag,
      },
      queryString
    );
    return this.getTasksFromServer(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.clearHistoryCache();
        this.historyEtag = response.etag;
        const tasks = response.tasks;
        tasks.forEach(this.addTaskToHistoryCache);
        this.historyCache.sort((a, b) => sortHistoryFunc(a, b));
        this.historyCacheOnTime.sort((a, b) => sortHistoryFunc(a, b));
        this.historyCacheLate.sort((a, b) => sortHistoryFunc(a, b));
        this.historyCacheWithPhoto.sort((a, b) => sortHistoryFunc(a, b));
        this.historyCacheWithCommentary.sort((a, b) => sortHistoryFunc(a, b));
        this.historyCacheToday.sort((a, b) => sortHistoryFunc(a, b));

        function sortHistoryFunc(a: Task, b: Task) {
          if (a.responses && b.responses) {
            if (a.responses[0].clientDate < b.responses[0].clientDate) {
              return 1;
            } else if (a.responses[0].clientDate > b.responses[0].clientDate) {
              return -1;
            } else {
              return 0;
            }
          }
          return 0;
        }
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getHistory(filter?: string): Task[] {
    switch (filter) {
      case 'LATE':
        return this.historyCacheLate;
      case 'ON_TIME':
        return this.historyCacheOnTime;
      case 'WITH_PHOTO':
        return this.historyCacheWithPhoto;
      case 'WITH_COMMENT':
        return this.historyCacheWithCommentary;
      case 'CANCELED':
        return this.canceledTasksCache;
      case 'TODAY':
        return this.historyCacheToday;
      /* Sovena*/
      case 'IN_TRANSIT':
        return this.historyCache.filter(
          (t) => t.formId === 'clku3obef000c3q74zyoqm788'
        );
      case 'PATIO':
        return this.historyCache.filter(
          (t) => t.formId === 'clku3oyy9000f3q74h2spq65r'
        );
      case 'DIRTY':
        return this.historyCache.filter(
          (t) => t.formId === 'clku3paq0000g3q74gxqksaf4'
        );
      case 'CLEAN':
        return this.historyCache.filter(
          (t) => t.formId === 'clku3ppc3000j3q7472ye0pq1'
        );
      case 'ANALYTICS':
        return this.historyCache.filter(
          (t) => t.formId === 'clku4pxj6000k3q74n8qr4q0g'
        );
      /* End of Sovena */
      default:
        return this.historyCache;
    }
  }

  getHistoryCount(filter: string): number {
    return this.getHistory(filter).length;
  }

  getNonConformFromStore(companyId: string, storeId: string): Observable<any> {
    const queryString = this.getQueryStringForNonConform();
    const link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.nonConformFromStoreEtag,
      },
      queryString
    );

    return this.getTasksFromServer(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.nonConformFromStoreEtag = response.etag;
        return response.tasks;
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getLateFromStore(companyId: string, storeId: string): Observable<any> {
    const queryString = this.getQueryStringForLate();
    const link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.lateFromStoreEtag,
      },
      queryString
    );

    return this.getTasksFromServer(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.lateFromStoreEtag = response.etag;
        return response.tasks;
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  clearJustificationCache(toClear: Task[]) {
    this.justificationCache = this.justificationCache.filter(
      (el) => !toClear.includes(el)
    );
    this.justificationCacheToday = this.justificationCacheToday.filter(
      (el) => !toClear.includes(el)
    );
    this.justificationCacheWithCommentary =
      this.justificationCacheWithCommentary.filter(
        (el) => !toClear.includes(el)
      );
    this.justificationCacheWithPhoto = this.justificationCacheWithPhoto.filter(
      (el) => !toClear.includes(el)
    );
  }

  getJustificationTasksFromStore(
    companyId: string,
    storeId: string
  ): Observable<any> {
    const nonConformSource = this.getNonConformFromStore(
      companyId,
      storeId
    ).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.clearJustificationCache(this.justificationCacheNonConform);
        this.justificationCacheNonConform.length = 0;
        const tasks = response.tasks;
        tasks.forEach(this.addTaskToJustificationCache);
      })
    );

    return forkJoin([nonConformSource]).pipe(
      tap(() => {
        this.justificationCache.sort((a, b) => sortJustificationFunc(a, b));
        this.justificationCacheToday.sort((a, b) =>
          sortJustificationFunc(a, b)
        );
        this.justificationCacheLate.sort((a, b) => sortJustificationFunc(a, b));
        this.justificationCacheNonConform.sort((a, b) =>
          sortJustificationFunc(a, b)
        );
        this.justificationCacheWithCommentary.sort((a, b) =>
          sortJustificationFunc(a, b)
        );
        this.justificationCacheWithPhoto.sort((a, b) =>
          sortJustificationFunc(a, b)
        );

        function sortJustificationFunc(a: Task, b: Task) {
          if (a.responses && b.responses) {
            if (a.responses[0].clientDate < b.responses[0].clientDate) {
              return -1;
            } else if (a.responses[0].clientDate > b.responses[0].clientDate) {
              return 1;
            } else {
              return 0;
            }
          } else if (a.responses) {
            if (a.responses[0].clientDate < b.endDate) {
              return -1;
            } else if (a.responses[0].clientDate > b.endDate) {
              return 1;
            } else {
              return 0;
            }
          } else if (b.responses) {
            if (a.endDate < b.responses[0].clientDate) {
              return -1;
            } else if (a.endDate > b.responses[0].clientDate) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (a.endDate < b.endDate) {
              return -1;
            } else if (a.endDate > b.endDate) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getJustification(filter?: string): Task[] {
    switch (filter) {
      case 'NON_CONFORM':
        return this.justificationCacheNonConform;
      case 'LATE':
        return this.justificationCacheLate;
      case 'TODAY':
        return this.justificationCacheToday;
      case 'WITH_PHOTO':
        return this.justificationCacheWithPhoto;
      case 'WITH_COMMENT':
        return this.justificationCacheWithCommentary;
      case 'SYNC':
        return this.tasksCacheSyncing;
      default:
        return this.justificationCache;
    }
  }

  getJustificationCount(filter?: string): number {
    switch (filter) {
      case 'NON_CONFORM':
        return this.justificationCacheNonConform.length;
      case 'LATE':
        return this.justificationCacheLate.length;
      case 'TODAY':
        return this.justificationCacheToday.length;
      case 'WITH_PHOTO':
        return this.justificationCacheWithPhoto.length;
      case 'WITH_COMMENT':
        return this.justificationCacheWithCommentary.length;
      case 'SYNC':
        return this.tasksCacheSyncing.length;
      default:
        return this.justificationCache.length;
    }
  }

  submitTask(
    task: Task,
    response: any,
    authInfo: AuthenticationInfo,
    onTaskClosedSuccessfully?: OnTaskClosedSuccessfully
  ): Observable<any> {
    const originalTryTimestamp = Date.now();
    const user: UserInfo = authInfo.user!;
    const companyId = user.companyId;
    const storeId = user.storeId;
    const link: Link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks/${task.id}`,
      JSON_HEADERS
    );
    this.isDashboardOutdated = true;
    return this.http.post(link, response, authInfo).pipe(
      tap(
        (value: any) => {
          this.handleTaskSubmitSuccess(task, value);
          if (onTaskClosedSuccessfully) onTaskClosedSuccessfully(task);
        },
        (err: any) => {
          this.handleTaskSubmitError(
            err,
            task,
            response,
            authInfo,
            originalTryTimestamp,
            onTaskClosedSuccessfully
          );
        }
      ),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  updateTask(task: Task, authInfo: AuthenticationInfo) {
    const originalTryTimestamp = Date.now();
    const user: UserInfo = authInfo.user!;
    const companyId = user.companyId;
    const storeId = user.storeId;
    const link: Link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks/${task.id}`,
      JSON_HEADERS
    );
    return this.http.put(link, task, authInfo).pipe(
      tap(
        (value: any) => {
          this.handleTaskUpdateSuccess(task, value);
        },
        (err: any) =>
          this.handleTaskUpdateError(err, task, authInfo, originalTryTimestamp)
      ),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  updateDynamicPropertiesTask(
    task: Task,
    action: string,
    operations: any[],
    authInfo: AuthenticationInfo
  ) {
    const originalTryTimestamp = Date.now();
    const user: UserInfo = authInfo.user!;
    const companyId = user.companyId;
    const storeId = user.storeId;
    const link: Link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks/${task.id}/dynamic`,
      JSON_HEADERS
    );

    const body = {
      id: task.id,
      action,
      operations,
    };

    return this.http.put(link, body, authInfo).pipe(
      tap(
        (value: any) => {
          this.handleTaskUpdateSuccess(task, value);
        },
        (err: any) =>
          this.handleTaskUpdateError(err, task, authInfo, originalTryTimestamp)
      ),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  existsPendingTask() {
    return (
      this.tasksCacheSyncing.length > 0 || this.automaticTasksWereGenerated
    );
  }

  newTask(
    task: Task,
    authInfo: AuthenticationInfo,
    onTaskCreatedSuccessfully?: OnTaskCreatedSuccessfully
  ) {
    const originalTryTimestamp = Date.now();
    const user: UserInfo = authInfo.user!;
    const companyId = user.companyId;
    const storeId = user.storeId;
    const link: Link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks/${task.id}`,
      JSON_HEADERS
    );
    task.createdInStore = true;
    this.isDashboardOutdated = true;
    return this.http.put(link, task, authInfo).pipe(
      tap(
        () => {
          this.handleNewTaskSuccess(task);
          if (onTaskCreatedSuccessfully) onTaskCreatedSuccessfully(task);
        },
        (err: any) =>
          this.handleNewTaskError(err, task, authInfo, originalTryTimestamp)
      ),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getTasksFromStore(companyId: string, storeId: string) {
    const queryString = this.getQueryStringForTasks();
    const link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks`,
      {
        ...JSON_HEADERS,
        'If-None-Match': this.tasksFromStoreEtag,
      },
      queryString
    );

    return this.getTasksFromServer(link).pipe(
      tap((response: any) => {
        if (response.status === 304) {
          return;
        }
        this.clearTasksCache();
        this.tasksFromStoreEtag = response.etag;
        const tasks = response.tasks;
        this.fillTasksCaches(tasks);
        this.sortTasksCaches();
      }),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getTask(id: string): Task | undefined {
    let task: Task | undefined;
    task = this.tasksCache.find((t) => t.id === id);
    if (task) return task;
    task = this.justificationCache.find((t) => t.id === id);
    if (task) return task;
    task = this.historyCache.find((t) => t.id === id);
    if (task) return task;
    return undefined; // Ensure a value is always returned
  }

  getFilteredTasks(filterType: string, filterValue: string): Task[] {
    if (filterType === 'machine') {
      return this.tasksCache.filter((t) => t.machineId === filterValue);
    }
    return [];
  }

  patchTask(task: Task, updateOps: any[], authInfo: AuthenticationInfo) {
    const originalTryTimestamp = Date.now();
    const user: UserInfo = authInfo.user!;
    const companyId = user.companyId;
    const storeId = user.storeId;

    const link: Link = new Link(
      this.envService.getApiUri() +
        `/companies/${companyId}/stores/${storeId}/tasks/${task.id}`,
      JSON_HEADERS
    );

    return this.http.patch(link, updateOps, authInfo).pipe(
      tap(
        (updatedTask: any) =>
          this.handleTaskUpdateSuccess(task, updatedTask.json()),
        (err: any) =>
          this.handleTaskUpdateError(err, task, authInfo, originalTryTimestamp)
      ),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getFormQuizId(formQuiz: string): string | null {
    const field = this.fieldsService.getFormByName(formQuiz);
    return field ? field.id : null;
  }

  getTasksToReopen(formQuiz: string): string[] {
    const formQuizId = this.getFormQuizId(formQuiz);
    if (!formQuizId) {
      return [];
    }

    const tasks = this.getTasksFromFormQuizWithCriteria(formQuizId);

    const filteredTasks = tasks.filter(
      (task) =>
        Array.isArray(task.responses) &&
        task.responses.length > 0 &&
        task.inactive !== true
    );
    return filteredTasks.map((task) => task.title);
  }

  getTasksFromFormQuizWithCriteria(formQuizId: string): Task[] {
    return this.historyCache.filter(
      (task) =>
        task.formId === formQuizId &&
        Array.isArray(task.responses) &&
        task.responses.length > 0 &&
        task.inactive !== true
    );
  }

  findTasksWithTypeAndProductId(type: string, productId: string): Task[] {
    return this.getTasks().filter(
      (task) => task.type === type && task.productId === productId
    );
  }

  private getQueryStringForHistory(): string {
    const urlEncodedString = new UrlEncodedString();
    urlEncodedString.append('mostRecentResponseConformity', 'true');
    urlEncodedString.appendD(
      'betweenMostRecentResponseClientDate',
      this.envService.isMachineMyHarvest()
        ? getPreviousMonth4Am().toString()
        : getPrevious4Am().toString(),
      getNext4Am().toString()
    );
    return urlEncodedString.toString();
  }

  private getQueryStringForNonConform(): string {
    const urlEncodedString = new UrlEncodedString();
    urlEncodedString.append('mostRecentResponseConformity', 'false');
    urlEncodedString.append(
      'afterVisualizationDate',
      moment().valueOf().toString()
    );
    return urlEncodedString.toString();
  }

  private getQueryStringForLate(): string {
    const now = moment().valueOf().toString();
    const urlEncodedString = new UrlEncodedString();
    urlEncodedString.appendS('notExistsResponses');
    urlEncodedString.append('beforeOrEqualsEndDate', now);
    urlEncodedString.append('afterVisualizationDate', now);
    return urlEncodedString.toString();
  }

  private getQueryStringForTasks(): string {
    const urlEncodedString = new UrlEncodedString();
    urlEncodedString.appendS('notExistsResponses');
    urlEncodedString.append('beforeOrEqualsStartDate', getNext4Am().toString());
    urlEncodedString.append(
      'afterVisualizationDate',
      moment().valueOf().toString()
    );
    return urlEncodedString.toString();
  }
}
