// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { mergeMap, Observable, of, forkJoin } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import {
  CustomTaskService,
  ExecutionState,
} from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { ModalController } from '@ionic/angular';
import cuid from 'cuid';
import moment from 'moment';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldType } from 'src/app/entities/field';
import { FieldsService } from 'src/app/services/fields.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpTellAboutProject implements IMyTaskHandler {
  name = 'follow-up-tell-about-project';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  pieceToString(piece: any, type: any, data: any) {
    if (piece === 'type') return type.name;
    if (piece === 'product') return data['Produto']?.name || '';
    if (piece === 'expirationDate')
      return moment(data['Data de fim de validade']).format('DD/MM/YYYY HH:mm');
    return '';
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
    const userInfo = this.authenticationService.getUserInfo();

    if (!userInfo) {
      console.error('User info not found');
      return of(null);
    }

    const typeTask =
      this.customTaskTypesService.getCustomTaskType('talkProjectEFN2');
    if (!typeTask) {
      console.error('typeTask not found');
      return of(null);
    }

    if (this.customTaskTypesService.canProceed(task.type, data)) {
      // Criação da tarefa para o utilizadorUdeId com o formId da tarefa original
      const taskForUser = this.createNewTask(
        typeTask,
        data,
        task,
        store,
        store?.modules?.utilizadorUdeId,
        task.formId
      );

      // Criar o form para o adminUdeId
      const typeForm = this.createTypeForm(store);

      const sectionObservable = this.fieldsService
        .newField(typeForm, this.authenticationService.getAuthInfo())
        .pipe(
          mergeMap(() => {
            // Criar a tarefa para o adminUdeId com o formId do form criado
            const taskForAdmin = this.createNewTask(
              typeTask,
              data,
              task,
              store,
              store?.modules?.adminUdeId,
              typeForm.id
            );
            // Criar as duas tarefas (utilizadorUdeId e adminUdeId) em paralelo
            return this.tasksService
              .newTask(taskForUser, this.authenticationService.getAuthInfo())
              .pipe(
                mergeMap(() =>
                  this.tasksService.newTask(
                    taskForAdmin,
                    this.authenticationService.getAuthInfo()
                  )
                )
              );
          })
        );

      return sectionObservable;
    }

    return of(null);
  }

  private createNewTask(
    typeTask: any,
    data: TaskResponse,
    task: Task,
    store: any,
    userId: string,
    formId: string
  ): any {
    return {
      id: cuid(),
      type: typeTask.type,
      formId: formId,
      title: this.getTitle(typeTask, data),
      subtitle: this.getSubtitle(typeTask, data, task),
      startDate: moment().valueOf(),
      endDate: moment().add(1, 'day').valueOf(),
      visualizationDate: moment().add(1, 'year').valueOf(),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: {
        storeId: task.target.storeId,
        userIds: [userId],
      },
      documentsIds: [],
      isCustomType: true,
      superType: undefined,
      precedingTaskId: task.id,
      precedingTaskData: this.mapPrecedingTaskData(data),
    };
  }

  private createTypeForm(store: any): any {
    return {
      id: cuid(),
      title: 'Resposta Descrição do Projeto',
      type: FieldType.Form,
      storeId: store?.id,
      userIds: [store?.modules?.adminUdeId],

      style: {
        imageUrl: 'descricao_do_projeto_ude.svg',
        backgroundColor: '#08a853',
      },
    };
  }

  private mapPrecedingTaskData(data: TaskResponse): any {
    return {
      nomeOrganizacao: data['Nome do restaurante ou do alojamento'],
      isAssociated: data['É associado a AHRESP'],
      numeroOrganizacao: data['Número associado da AHRESP'],
      nome: data['Nome'],
      telemovel: data['Telemóvel'],
      email: data['E-mail'],
      cargo: data['Cargo'],
      breveResumoEntidade: data['Breve resumo da entidade'],
      selecaoProdutosCatalogo: data['Seleção dos produtos do catálogo'],
      pequenaExplicacaoProjeto:
        data['Pequena explicação do que o seu projeto pretende fazer'],
    };
  }
}
