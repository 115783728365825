<div class="formTask">
  <div class="taskItem">
    <div class="taskHeader">
      <img class="tashHeaderImg" src="assets/img/head_datehour_bar.png" />
      <div
        class="taskTime"
        [class.formLateTask]="isTaskLate()"
        *ngIf="!(storesService?.store?.code.includes('Quiz') && envService.isMachineGreenselection())"
      >
        <div class="taskStartTime">
          <h3>
            <span style="color: var(--ion-color-primary)">Início: </span>
            <span>{{task.startDate | date: "dd/MM/yy HH:mm"}}</span>
          </h3>
        </div>
        <div
          class="taskEndTime"
          *ngIf="!(task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF')"
        >
          <h3>
            <span style="color: var(--ion-color-primary)">Fim: </span>
            <span>{{task.endDate | date: "dd/MM/yy HH:mm"}}</span>
          </h3>
        </div>
        <div
          class="taskEndTime"
          *ngIf="(task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF') && response && response['clientDate']"
        >
          <img src="assets/img/date.svg" />
          <h3>{{(response && response['clientDate']) | date: "dd/MM/yy"}}</h3>
          <img src="assets/img/clock_red.svg" />
          <h3>{{(response && response['clientDate']) | date: "HH:mm"}}</h3>
        </div>
      </div>
    </div>
    <div class="taskContent">
      <div class="taskDescription">
        <div
          class="chip-container"
          *ngIf="isTaskLate() && isTypeYesNoOrSingleTemperature()"
        >
          <ion-chip>Em atraso</ion-chip>
        </div>
        <p *ngIf="task.title || getTitle()">
          <span *ngIf="task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
            >{{'TASK'|translate}}:
          </span>
          <span *ngIf="task.target.storeId === 'clku0roe800003q74ta64e1gz'"
            >{{'Estado'|translate}}:
          </span>
          <span>{{task.title || getTitle()}}</span>
        </p>
        <p *ngIf="task.subtitle || getSubtitle()">
          <span>Subtítulo: </span>
          <span>{{task.subtitle || getSubtitle()}}</span>
        </p>
        <p *ngIf="getStates()">
          <span>Estado </span>
          <span>{{ getStates() }}</span>
        </p>
        <p *ngIf="task.productId && product?.barcode?.reference">
          <span>{{'Referência'|translate}}: </span>
          <span class="light-grey">{{product?.barcode?.reference}}</span>
        </p>
        <p *ngIf="task.productId">
          <span>{{'Produto'|translate}}: </span>
          <span class="light-grey">{{product?.name}}</span>
        </p>
        <p *ngIf="task.batch">
          <span>{{'Lote'|translate}}: </span>
          <span class="light-grey"
            >{{(task.batch.lot || '') + ' ' + (task.batch.expirationDate ||
            '')}}</span
          >
        </p>
        <p *ngIf="task.productId">
          <span>{{'Marca'|translate}}: </span>
          <span class="light-grey">{{product?.brand}}</span>
        </p>
        <p *ngIf="task.productId">
          <span>{{ 'Fornecedor' | translate }}: </span>
          <span class="light-grey">{{ supplierDisplayName }}</span>
        </p>

        <p *ngIf="task.productId && product?.barcode?.value">
          <span>{{'EAN'|translate}}: </span>
          <span class="light-grey">{{product?.barcode?.value}}</span>
        </p>
        <p *ngIf="task.productId && product?.barcode?.itm8">
          <span>{{'Cód. barras interno'|translate}}: </span>
          <span class="light-grey">{{product?.barcode?.itm8}}</span>
        </p>
        <p *ngIf="task['expirationDate']">
          <span>{{'Data Validade'|translate}}: </span>
          <span class="light-grey"
            >{{task['expirationDate'] | date:"dd/MM/yy"}}</span
          >
        </p>
        <p *ngIf="task['groupedBatchesLots']">
          <span>{{'Lote(s)'|translate}}: </span>
          <span class="light-grey">{{task['groupedBatchesLots']}}</span>
        </p>
        <p *ngIf="task['tagsToPrint']">
          <span>{{'Quantidade etiquetas'|translate}}: </span>
          <span class="light-grey">{{task['tagsToPrint']}}</span>
        </p>
        <p *ngIf="task['tagsReason']">
          <span>{{'Destino do lote'|translate}}: </span>
          <span class="light-grey">{{task['tagsReason']}}</span>
        </p>
        <p *ngIf="task['idNumber']">
          <span>{{'Nº de identificação'|translate}}: </span>
          <span class="light-grey">{{task['idNumber']}}</span>
        </p>
        <p *ngIf="task.type === '2itmGoodsReceptionBasePerGuide'">
          <span>{{'Famílias de produto'|translate}}: </span>
          <span class="light-grey"
            >{{getReceptionFamilies(task, response)}}</span
          >
        </p>
        <p *ngIf="task['Identificação da Não-Conformidade']">
          <span>{{'Identificação da Não Conformidade'|translate}}: </span>
          <span class="light-grey"
            >{{task["Identificação da Não-Conformidade"]}}</span
          >
        </p>
        <p *ngIf="task['problemName']">
          <span>{{'Problema detetado'|translate}}: </span>
          <span class="light-grey">{{task['problemName']}}</span>
        </p>
        <p
          *ngIf="response && response['Parcela'] && task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
        >
          <span>{{'Parcela'|translate}}: </span>
          <span class="light-grey">{{response['Parcela']}}</span>
        </p>
        <p *ngIf="response && (response['Comprador'] || response['Cliente'])">
          <span>{{'Cliente'|translate}}: </span>
          <span class="light-grey"
            >{{response['Comprador'] || response['Cliente']}}</span
          >
        </p>
        <p *ngIf="response['Nome do documento']">
          <span>{{'Nome do documento'|translate}}: </span>
          <span class="light-grey"
            >{{response['Nome do documento'] ||
            task['precedingTaskNumeroDocumento']}}</span
          >
        </p>
        <p *ngIf="response['Número do documento']">
          <span>{{'Número do documento'|translate}}: </span>
          <span class="light-grey"
            >{{response['Número do documento'] ||
            task['precedingTaskNumeroDocumento']}}</span
          >
        </p>
        <p *ngIf="response['Tipo da Avaria']">
          <span>{{'Tipo da Avaria'|translate}}: </span>
          <span class="light-grey"
            >{{response['Tipo da Avaria'] ||
            task['precedingTaskTipoAvaria']}}</span
          >
        </p>
        <p *ngIf="response['Nome do equipamento']">
          <span>{{'Equipamento'|translate}}: </span>
        </p>

        <p
          *ngIf="response['Data de fim de validade'] || task['precedingTaskDataValidade']"
        >
          <span>{{ 'Data de fim de validade' | translate }}: </span>
          <span class="light-grey">
            {{ (response['Data de fim de validade'] ||
            task['precedingTaskDataValidade']) | date:'dd/MM/yyyy' }}
          </span>
        </p>

        <p
          *ngIf="response['Titulo do Pedido'] || task['precedingTaskTitleOrder']"
        >
          <span>{{ 'Titulo do Pedido' | translate }}: </span>
          <span class="light-grey">
            {{ (response['Titulo do Pedido'] || task['precedingTaskTitleOrder'])
            }}
          </span>
        </p>
        <p
          *ngIf="task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF'"
        >
          <span>{{'Sociedade'|translate}}: </span>
          <span class="light-grey">{{getSocietyNF()}}</span>
        </p>
        <p
          *ngIf="task.type === 'inProductApanhaAzeitonaNF'|| task.type==='collectionProductApanhaNF'|| task.type==='ratingApanhaNF' || task.type==='inTransitAzeitonaNF'"
        >
          <span>{{'Herdade'|translate}}: </span>
          <span class="light-grey"
            >{{(response?response['Herdade']: undefined) ||
            task['precedingTaskParcel'] || storesService.store.code + ' ' +
            getClosestHerdadeField(task.formId).society}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Nº Guia']) || (task.precedingTaskResponse && task.precedingTaskResponse['Nº Guia']) && task.target.storeId !== 'clku0roe800003q74ta64e1gz'"
        >
          <span>{{'Nº Guia'|translate}}: </span>
          <span class="light-grey"
            >{{response['Nº Guia'] || (task.precedingTaskResponse &&
            task.precedingTaskResponse['Nº Guia'])}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Matrícula Transporte']) || (task.precedingTaskResponse && task.precedingTaskResponse['Matrícula Transporte'])"
        >
          <span>{{'Matrícula Transporte'|translate}}: </span>
          <span class="light-grey"
            >{{response['Matrícula Transporte'] || (task.precedingTaskResponse
            && task.precedingTaskResponse['Matrícula Transporte'])}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Almoço ou Jantar']) || (task.precedingTaskResponse && task.precedingTaskResponse['Almoço ou Jantar'])"
        >
          <span>{{'Almoço ou Jantar'|translate}}: </span>
          <span class="light-grey"
            >{{response['Almoço ou Jantar'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Almoço ou Jantar']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Tipo de Documento']) || (task.precedingTaskResponse && task.precedingTaskResponse['Tipo de Documento'])"
        >
          <span>{{'Tipo de Documento'|translate}}: </span>
          <span class="light-grey"
            >{{response['Tipo de Documento'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Tipo de Documento']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Número do Documento']) || (task.precedingTaskResponse && task.precedingTaskResponse['Número do Documento'])"
        >
          <span>{{'Número do Documento'|translate}}: </span>
          <span class="light-grey"
            >{{response['Número do Documento'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Número do Documento']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Identificação da Fritadeira']) || (task.precedingTaskResponse && task.precedingTaskResponse['Identificação da Fritadeira'])"
        >
          <span>{{'Identificação da Fritadeira'|translate}}: </span>
          <span class="light-grey"
            >{{response['Identificação da Fritadeira'] ||
            task.precedingTaskResponse &&
            task.precedingTaskResponse['Identificação da Fritadeira']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Identificação da Não Conformidade']) || (task.precedingTaskResponse && task.precedingTaskResponse['Identificação da Não Conformidade'])"
        >
          <span>{{'Identificação da Não Conformidade'|translate}}: </span>
          <span class="light-grey"
            >{{response['Identificação da Não Conformidade'] ||
            task.precedingTaskResponse &&
            task.precedingTaskResponse['Identificação da Não
            Conformidade']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Praga']) || (task.precedingTaskResponse && task.precedingTaskResponse['Praga'])"
        >
          <span>{{'Pragas'|translate}}: </span>
          <span class="light-grey"
            >{{response['Praga'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Praga']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Tipo de Pedido']) || (task.precedingTaskResponse && task.precedingTaskResponse['Tipo de Pedido'])"
        >
          <span>{{'Tipo de Pedido'|translate}}: </span>
          <span class="light-grey"
            >{{response['Tipo de Pedido'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Tipo de Pedido']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Colaborador']) || (task.precedingTaskResponse && task.precedingTaskResponse['Colaborador'])"
        >
          <span>{{'Colaborador'|translate}}: </span>
          <span class="light-grey"
            >{{response['Colaborador'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Colaborador']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Praga Detetada']) || (task.precedingTaskResponse && task.precedingTaskResponse['Praga Detetada'])"
        >
          <span>{{'Praga Detetada'|translate}}: </span>
          <span class="light-grey"
            >{{response['Praga Detetada'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Praga Detetada']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Nome da Maquina']) || (task.precedingTaskResponse && task.precedingTaskResponse['Nome da Maquina'])"
        >
          <span>{{'Nome da Maquina'|translate}}: </span>
          <span class="light-grey"
            >{{response['Nome da Maquina'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Nome da Maquina']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Empresa externa']) || (task.precedingTaskResponse && task.precedingTaskResponse['Empresa externa'])"
        >
          <span>{{'Empresa externa'|translate}}: </span>
          <span class="light-grey"
            >{{response['Empresa externa'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Empresa externa']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Nome da Empresa']) || (task.precedingTaskResponse && task.precedingTaskResponse['Nome da Empresa'])"
        >
          <span>{{'Nome da Empresa'|translate}}: </span>
          <span class="light-grey"
            >{{response['Nome da Empresa'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Nome da Empresa']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Auditor']) || (task.precedingTaskResponse && task.precedingTaskResponse['Auditor'])"
        >
          <span>{{'Auditor'|translate}}: </span>
          <span class="light-grey"
            >{{response['Auditor'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Auditor']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Chefe de Área / Supervisor']) || (task.precedingTaskResponse && task.precedingTaskResponse['Chefe de Área / Supervisor'])"
        >
          <span>{{'Chefe de Área / Supervisor'|translate}}: </span>
          <span class="light-grey"
            >{{response['Chefe de Área / Supervisor'] ||
            task.precedingTaskResponse && task.precedingTaskResponse['Chefe de
            Área / Supervisor']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Data Auditoria']) || (task.precedingTaskResponse && task.precedingTaskResponse['Data Auditoria'])"
        >
          <span>{{'Data Auditoria'|translate}}: </span>
          <span class="light-grey"
            >{{response['Data Auditoria'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Data Auditoria']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Tipo de Auditoria']) || (task.precedingTaskResponse && task.precedingTaskResponse['Tipo de Auditoria'])"
        >
          <span>{{'Tipo de Auditoria'|translate}}: </span>
          <span class="light-grey"
            >{{response['Tipo de Auditoria'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Tipo de Auditoria']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Titulo da Correção']) || (task.precedingTaskResponse && task.precedingTaskResponse['Titulo da Correção'])"
        >
          <span>{{'Titulo da Correção'|translate}}: </span>
          <span class="light-grey"
            >{{response['Titulo da Correção'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Titulo da Correção']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Responsável pela Implementação']) || (task.precedingTaskResponse && task.precedingTaskResponse['Responsável pela Implementação'])"
        >
          <span>{{'Responsável pela Implementação'|translate}}: </span>
          <span class="light-grey"
            >{{response['Responsável pela Implementação'] ||
            task.precedingTaskResponse &&
            task.precedingTaskResponse['Responsável pela Implementação']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Grupo de Colheita']) || (task.precedingTaskResponse && task.precedingTaskResponse['Grupo de Colheita'])"
        >
          <span>{{'Grupo de Colheita'|translate}}: </span>
          <span class="light-grey"
            >{{response['Grupo de Colheita'] || task.precedingTaskResponse &&
            task.precedingTaskResponse['Grupo de Colheita']}}</span
          >
        </p>
        <p
          *ngIf="(response && response['Variedade']) || (task.precedingTaskResponse && task.precedingTaskResponse['Variedade'])"
        >
          <span>{{'Variedade'|translate}}: </span>
          <span class="light-grey"
            >{{response['Variedade'] || (task.precedingTaskResponse &&
            task.precedingTaskResponse['Variedade'])}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Quantidade aproximada (kg)']"
        >
          <span>{{'Quantidade aproximada (kg)'|translate}}: </span>
          <span class="light-grey"
            >{{response['Quantidade aproximada (kg)'] ||
            task.precedingTaskResponse['Quantidade aproximada (kg)']}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Nº Ticket sujo']"
        >
          <span>{{'Nº Ticket sujo'|translate}}: </span>
          <span class="light-grey"
            >{{response['Nº Ticket sujo'] || task.precedingTaskResponse['Nº
            Ticket sujo']}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Quantidade (peso sujo em kg)']"
        >
          <span>{{'Quantidade (peso sujo em kg)'|translate}}: </span>
          <span class="light-grey"
            >{{response['Quantidade (peso sujo em kg)'] ||
            task.precedingTaskResponse['Quantidade (peso sujo em kg)']}}</span
          >
        </p>
        <p
          *ngIf="task.precedingTaskResponse && task.precedingTaskResponse['Última carga do dia']"
        >
          <span style="color: red">{{'Última carga do dia'|translate}}: </span>
          <span class="light-grey"
            >{{(response['Última carga do dia'] ||
            task.precedingTaskResponse['Última carga do dia'])?'SIM':''}}</span
          >
        </p>
        <!--        <p *ngIf="task.periodicity">-->
        <!--          <span>{{'TASK_PERIODICITY'|translate}}: </span>-->
        <!--          <span class="light-grey">{{task.periodicity}}</span>-->
        <!--        </p>-->
        <p *ngIf="task.zoneId">
          <span>{{'TASK_ZONE'|translate}}: </span>
          <span class="light-grey"
            >{{zonesService.getZoneById(task.zoneId).name}}</span
          >
        </p>
        <p *ngIf="task.equipmentId">
          <span>{{'TASK_EQUIPMENT'|translate}}: </span>
          <span class="light-grey"
            >{{equipmentsService.getEquipmentById(task.equipmentId).name}}</span
          >
        </p>
        <p *ngIf="task.machineId">
          <span>{{'TASK_MACHINE'|translate}}: </span>
          <span class="light-grey"
            >{{sensorsService.getMachineById(task.machineId).machine}}</span
          >
        </p>
      </div>
      <div class="taskActions">
        <div class="actionsLeft">
          <photo-button
            *ngIf="task.type === 'yesNo' && task.target.storeId !== 'clku0roe800003q74ta64e1gz' && task.target.storeId !== 'cls1zmbws00013b7gi9j52es1'"
            [task]="task"
            [picturesIds]="response['picturesIds']"
          ></photo-button>
          <info-button [task]="task" style="margin-left: 12px"></info-button>
        </div>
        <div class="actionsRight">
          <img
            *ngIf="envService.isMachineIbersol() &&  (task.type !== 'goodsReceptionEs' && task.type !== 'controlePest' && task.type !== 'breakProductIbersol') || task.type === 'yesNo'"
            (click)="pressNo()"
            src="assets/img/not_according.svg"
          />
          <img
            *ngIf="hasResponseFields()"
            (click)="pressContent()"
            src="assets/img/task_expand_icon.svg"
          />
          <img
            *ngIf="!hasResponseFields()"
            (click)="pressYes()"
            src="assets/img/according.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
