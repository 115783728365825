// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { Observable, throwError } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { ProductsService } from '../../services/products.service';
import { RequiredResponseFieldError } from '../../../utils/errors/required-response-field.error';

@Injectable({
  providedIn: 'root',
})
export class ClassifyProductUsersHandler implements IMyTaskHandler {
  name = 'classify-product-users';

  constructor(
    private authenticationService: AuthenticationService,
    private productsService: ProductsService
  ) {}

  execute(task: Task, data: TaskResponse): Observable<any> {
    const productId = task.productId;
    if (!productId)
      return throwError(() => new RequiredResponseFieldError('Produto'));

    const product = this.productsService.getProductById(productId);
    if (!product) return throwError(() => new Error('Product not found'));

    const productClassificationResponseFieldName = 'Classificação do produto';
    const productClassificationResponseFieldData =
      data[productClassificationResponseFieldName];
    if (!productClassificationResponseFieldData) {
      return throwError(
        () =>
          new RequiredResponseFieldError(productClassificationResponseFieldName)
      );
    }
    product.users = productClassificationResponseFieldData
      .map(
        (uName: string) => this.authenticationService.getUserByName(uName)?.id
      )
      .filter((uName: string) => !!uName);
    return this.productsService.newProduct(
      product,
      this.authenticationService.getAuthInfo()
    );
  }
}
