import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MyKeyboard } from "../../keyboard/keyboard";
import { ModalController } from "@ionic/angular";
import { ProductDetailModal } from "../../product-detail-modal/product-detail.modal";
import { TranslateService } from "@ngx-translate/core";
import { ResponseField } from "../../../entities/tasks/task";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "product-input",
  templateUrl: "product.input.html",
})
export class ProductInputComponent {
  @Input() responseField!: ResponseField;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  @Input() getPlaceholderTranslation: (responseField: ResponseField) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.placeholder || '';
    } else if (lang === "es") {
      return responseField.placeholderEs || responseField.placeholder || '';
    }
    return responseField.placeholder || '';
  };

  @Input() getTranslation: (responseField: ResponseField) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.name || '';
    } else if (lang === "es") {
      return responseField.nameEs || responseField.name || '';
    }
    return responseField.name || '';
  };

  constructor(
    private modalController: ModalController,
    private translator: TranslateService
  ) {}


  async openProductDetail() {
    const modal = await this.modalController.create({
      component: ProductDetailModal,
      componentProps: { product: this.value },
    });
    await modal.present();
  }
}
