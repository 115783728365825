// src/app/handlers/example-handler2.service.ts
import { Injectable } from '@angular/core';
import { IMyTaskHandler } from '../handlers.service';
import { mergeMap, Observable, of, forkJoin } from 'rxjs';
import { Task, TaskResponse } from '../../entities/tasks/task';
import { AuthenticationService } from '../../services/authentication';
import { TasksService } from 'src/app/services/tasks.service';
import {
  CustomTaskService,
  ExecutionState,
} from 'src/app/services/custom-task.service';
import { StoresService } from 'src/app/services/stores.service';
import { getTargetUser } from 'src/app/entities/authentication-info';
import { ModalController } from '@ionic/angular';
import cuid from 'cuid';
import moment from 'moment';
import { CustomTaskTypesService } from 'src/app/services/custom-task-types.service';
import { FieldType } from 'src/app/entities/field';
import { FieldsService } from 'src/app/services/fields.service';

@Injectable({
  providedIn: 'root',
})
export class FollowUpConfirmData implements IMyTaskHandler {
  name = 'follow-up-confirm-data';

  constructor(
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private storeService: StoresService,
    private customTaskTypesService: CustomTaskTypesService,
    private fieldsService: FieldsService
  ) {}

  getTitle(type: any, data: any, task?: any, product?: any) {
    if (type.getTitle === 'type') {
      return type.name;
    }
    return `${type.name}`;
  }

  getSubtitle(type: any, data: any, task?: any) {
    if (!type.getSubtitle) return;
    if (type.getSubtitle === 'scheduleCollectionProductApanha') {
      return `${this.storeService.store?.code || ''} - ${data['Parcela']}`;
    }
    if (type.getSubtitle === 'ratingApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'collectionProductApanha') {
      return task.subtitle;
    }
    if (type.getSubtitle === 'complexTask') {
      return data['Comentário'];
    }
    if (type.getSubtitle === 'NF') {
      return `${data['Nº Guia']} - ${data['Herdade']}`;
    }
    if (type.type === 'inProductApanhaAzeitonaNF') {
      return `${data['Nº Guia']}`;
    }
    if (type.type === 'inTransitAzeitonaNF') {
      return `${data['Nº Guia']} - ${data['Matrícula Transporte']}`;
    }
    const pieces = type.getSubtitle.split(' ');
    if (!pieces) return;
    let subtitle = pieceToString(pieces[0]);
    for (let i = 1; i < pieces.length; ++i) {
      subtitle += ' - ' + pieceToString(pieces[i]);
    }
    return subtitle;

    function pieceToString(piece: any) {
      if (piece === 'type') return type.name;
      if (piece === 'product')
        return `${data['Produto'] ? data['Produto'].name : ''}`;
      if (piece === 'expirationDate')
        return moment(data['Data Validade']).format('DD/MM/YYYY HH:mm');
    }
  }

  pieceToString(piece: any, type: any, data: any) {
    if (piece === 'type') return type.name;
    if (piece === 'product') return data['Produto']?.name || '';
    if (piece === 'expirationDate')
      return moment(data['Data de fim de validade']).format('DD/MM/YYYY HH:mm');
    return '';
  }

  execute(
    task: Task,
    data: TaskResponse,
    state: ExecutionState
  ): Observable<any> {
    const store = this.storeService.store;
    const userInfo = this.authenticationService.getUserInfo();
    if (!userInfo) {
      console.error('User info not found');
      return of(null);
    }

    const typeTask =
      this.customTaskTypesService.getCustomTaskType('confirmDetailsEFN');
    if (!typeTask) {
      console.error('typeTask not found');
      return of(null);
    }

    if (this.customTaskTypesService.canProceed(task.type, data)) {
      const typeForm = {
        id: cuid(),
        title: '5 - Confirme os seus dados',
        type: FieldType.Form,
        storeId: store?.id,
        userIds: [store?.modules?.utilizadorUdeId],
        style: { imageUrl: 'documentos_ude.svg' },
        
            titleEmptyForm:
            'Parabéns, foi executada a tarefa. Carregue no botão VOLTAR para aceder ao menu principal e avançar para aceder a etapa 6 - Exportar declarações.',
      
      };

      const sectionObservable = this.fieldsService
        .newField(typeForm, this.authenticationService.getAuthInfo())
        .pipe(
          mergeMap(() => {
            const newTask = {
              id: cuid(),
              type: typeTask.type,
              formId: typeForm.id,
              title: this.getTitle(typeTask, data),
              subtitle: this.getSubtitle(typeTask, data, task),
              startDate: moment().valueOf(),
              endDate: moment().add(1, 'year').valueOf(),
              visualizationDate: moment().add(1, 'year').valueOf(),
              nonConformities: [],
              causes: [],
              corrections: [],
              periodicity: 'Única',
              target: {
                storeId: store.id,
                userIds: [store?.modules?.utilizadorUdeId],
              },
              documentsIds: [],
              isCustomType: true,
              superType: undefined,
              precedingTaskId: task.id,
              precedingTaskData: {
                sumario: data['Sumário'],
                enquadramentoProjeto: data['Enquadramento do projeto no aviso'],
                descricaoAcoesInvestimentos:
                  data['Descrição das ações e investimentos do projeto'],
                contributoIgualdadeGenero:
                  data[
                    'Contributo e/ou cumprimento princípios para a promoção da igualdade de género, de oportunidades e não discriminação'
                  ],
                contributoTransicao:
                  data['Contributo para a Transição Climática e/ou Digital'],
                justificacaoDnsh:
                  data[
                    'Justificar o respeito pelo princípio do DNSH, não apoiar ou realizar atividades económicas que causem danos significativos a qualquer objetivo ambiental (art.° 17.° Regulamento UE 2020/852)'
                  ],
                criteriosSelecaoRelevancia:
                  data[
                    'Fundamentação Critérios de Seleção: Relevância do projeto face aos objetivos da medida. Alinhamento com os objetivos e prioridades definidos no ponto 4 do AAC'
                  ],
                criteriosSelecaoImpactoDigital:
                  data[
                    'Fundamentação Critérios de Seleção: Impacto do projeto na transição digital da empresa. Grau de inovação da solução apresentada para a atividade da empresa'
                  ],
                transicaoCapacitacaoDigital:
                  data['Alinhamento com a transição e capacitação digital'],
                criteriosSelecaoCoerenciaTecnica:
                  data[
                    'Fundamentação Critérios de Seleção: Coerência técnica da candidatura'
                  ],
                valorTotalCandidatura: data['Valor total da candidatura (€)']
              },
            };

            return this.tasksService.newTask(
              newTask,
              this.authenticationService.getAuthInfo()
            );
          })
        );

      return sectionObservable;
    }

    return of(null);
  }
}
