import { Component, input } from '@angular/core';
import moment from 'moment';
import { ModalController, NavController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { checkValidity } from '../../../utils/conditional-modal-creator/conditional-modal-creator';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../../utils/translate.utils';
import { myParseFloat } from '../../../utils/number.utils';
import { debounceTime } from 'rxjs/operators';
import { PromptModal } from '../../components/prompt/prompt.modal';
import { Task, ResponseField, TaskResponse } from '../../entities/tasks/task';
import { CustomTaskTypesService } from '../../services/custom-task-types.service';
import { FieldsService } from '../../services/fields.service';
import { SensorsService } from '../../services/sensors.service';
import { StoresService } from '../../services/stores.service';
import { SyncingService } from '../../services/syncing';
import { TabsService } from '../../services/tabs.service';
import { TasksService } from '../../services/tasks.service';
import { TasksModalComponent } from '../tasks.modal/tasks.modal';
import { CustomTaskService } from '../../services/custom-task.service';
import { environment } from 'src/environments/environment';
import { ENV_MACHINES } from '../../../environments/env-machines';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  templateUrl: 'tasks-quick-filling.page.html',
  styleUrls: ['tasks-quick-filling.page.scss'],
})
export class TasksQuickFillingPage {
  searchTerm: string = '';
  searchControl: FormControl;
  searching = false;
  isSearched = false;
  filter: any;
  allTasks: Task[] = [];
  tasks: Task[] = [];
  counter = 0;

  responses: any = {};
  data: any;
  response: any;

  ENV_MACHINES = ENV_MACHINES;

  tempResponseField: ResponseField = {
    name: `Temperatura (ºC): *`,
    type: 'number',
    responseValidation: {
      required: true,
    },
    required: true,
    placeholder: 'ºC',
  };

  private ITEMS_PER_TICK = 12;

  init = () => {
    this.filter = this.tabsService.tasksFilter;
    this.searchTerm = '';
    this.searchControl.valueChanges.pipe(debounceTime(700)).subscribe(() => {
      this.tasks = this.allTasks.filter((t) => {
        const searchTermNormalized = this.searchTerm
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const titleNormalized = t.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const subtitleNormalized = t.subtitle
          ? t.subtitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          : '';
        const formTitleNormalized = this.tasksService
          .getTaskFormTitleByFormId(t)
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        return (
          moment(t.startDate)
            .format('DD-MM-YYYY HH:mm')
            .toLowerCase()
            .includes(searchTermNormalized.toLowerCase()) ||
          titleNormalized
            .toLowerCase()
            .includes(searchTermNormalized.toLowerCase()) ||
          subtitleNormalized
            .toLowerCase()
            .includes(searchTermNormalized.toLowerCase()) ||
          formTitleNormalized
            .toLowerCase()
            .includes(searchTermNormalized.toLowerCase())
        );
      });
      this.counter = this.ITEMS_PER_TICK;
      this.searching = false;
    });
    this.allTasks = this.tasksService
      .getTasks(this.filter)
      .filter((t) => t.machineId)
      .filter(
        (t) =>
          t.type === 'singleTemperature' ||
          t.type === 'singleTemperatureEs' ||
          t.type === 'serunionSingleTemperature' ||
          t.type === 'greenSingleTemperature'
      )
      .sort((a, b) => {
        if (this.storesService.store?.id === 'clku0roe800003q74ta64e1gz') {
          // Sort only by title
          const getCombinedTitle = (task: Task) => {
            return (
              task.title +
              (task.subtitle || this.getSubtitle(task)
                ? ' - ' + (task.subtitle || this.getSubtitle(task))
                : '')
            );
          };

          const titleA = getCombinedTitle(a);
          const titleB = getCombinedTitle(b);

          if (titleA < titleB) {
            return -1;
          } else if (titleA > titleB) {
            return 1;
          }
          return 0;
        } else {
          // Sort by startDate ascending
          if (moment(a.startDate).isBefore(b.startDate)) {
            return -1;
          } else if (moment(a.startDate).isAfter(b.startDate)) {
            return 1;
          }
          // If startDate is the same, compare by title
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();

          if (titleA < titleB) {
            return -1;
          } else if (titleA > titleB) {
            return 1;
          }
          return 0;
        }
      });
    this.counter = this.ITEMS_PER_TICK;

    // Create responses for tasks
    for (const task of this.allTasks) {
      const type = this.customTaskTypesService.getCustomTaskType(task.type);
      if (type) {
        this.responses[task.id] =
          this.customTaskService.getResponseFromTaskType(task, type);
      } else {
        // Handle the case where type is undefined
        console.error(`Task type for task ${task.id} is undefined`);
      }
    }

    this.tasks = this.allTasks;

    this.syncingService.initer = this.init;
  };

  constructor(
    private customTaskService: CustomTaskService,
    private tasksService: TasksService,
    private syncingService: SyncingService,
    private fieldsService: FieldsService,
    private modalCtrl: ModalController,
    private translator: TranslateService,
    private tabsService: TabsService,
    private customTaskTypesService: CustomTaskTypesService,
    private storesService: StoresService,
    public sensorsService: SensorsService,
    private navCtrl: NavController,
    protected envService: EnvironmentService
  ) {
    this.searchControl = new FormControl();
  }

  onSearchInput() {
    this.isSearched = true;
    this.searching = true;
  }

  ionViewWillEnter() {
    this.init();
  }

  async pressRow(task: Task) {
    const taskModal = await this.modalCtrl.create({
      component: TasksModalComponent,
      componentProps: { task: task },
    });

    await taskModal.present();

    const { data } = await taskModal.onDidDismiss();
    if (data) {
      const i = this.tasks.indexOf(task);
      this.tasks.splice(i, 1);
    }
  }

  doInfinite(event: any) {
    this.counter =
      this.counter + this.ITEMS_PER_TICK <= this.allTasks.length
        ? this.counter + this.ITEMS_PER_TICK
        : this.allTasks.length;
    event.target.complete();
  }

  getTasksCount(filter: string) {
    return this.tasksService.getTasksCount(filter);
  }

  isLateTask(task: Task) {
    return task.endDate < moment.now();
  }

  getSubtitle(task: Task): string | undefined {
    const type = this.customTaskTypesService.getCustomTaskType(task.type);
    if (task.pendingResponses && task.pendingResponses[0]) {
      const response = task.pendingResponses[0] as TaskResponse;

      if (type?.getSubtitle && typeof type.getSubtitle === 'function') {
        const subtitleType = type.getSubtitle(
          this.data,
          this.response,
          this.tasks
        );

        if (subtitleType === 'product') {
          return `${response['Designação do produto'] || ''}`;
        }
        if (subtitleType === 'productFamily') {
          return `${response['Natureza do produto controlado'] || ''}${
            response['Nome do fornecedor']
              ? ' - ' + response['Nome do fornecedor']
              : ''
          }`;
        }
        if (subtitleType === 'supplier') {
          return `${response['Fornecedor'] || ''}`;
        }
        if (subtitleType === 'nonProgrammed') {
          return `${response['Tipo']}`;
        }
        if (subtitleType === 'NF') {
          return `${this.storesService.store?.code} - ${response['Nº Guia']} - ${response['Matrícula Transporte']}`;
        }
        if (subtitleType === 'guideNumber') {
          return `Guia ${response['Nº Guia de Remessa']}`;
        }
        if (subtitleType === 'nameMachine') {
          return `Máquina - ${response['Nome da Máquina']}`;
        }
        if (subtitleType === 'pestSector') {
          return `${response['Setor']}`;
        }
        if (subtitleType === 'equipmentName') {
          return `${response['Nome do equipamento']}`;
        }
        if (subtitleType === 'typeFailure') {
          return `${response['Tipo da Avaria']}`;
        }
        if (subtitleType === 'titleRequest') {
          return `${response['Título do pedido']}`;
        }
        if (subtitleType === 'titleProduct') {
          return `${response?.['Produtos']}`;
        }
        if (subtitleType === 'nameEquipment') {
          return `${response?.['Equipamento']}`;
        }
        if (subtitleType === 'productName') {
          return `${response?.['Produto']}`;
        }
      }
    }
    return ''; // Default return value if no other condition is met
  }

  async closeTask(task: Task) {
    const isValid = await checkValidity(
      [
        {
          condition: () => this.isTemperatureOutOfBounds(task),
          modalCreator: () => this.createTemperatureOutOfBoundsModal(),
        },
      ],
      this.modalCtrl
    );

    if (isValid) {
      task.isLoading = true;
      this.customTaskService
        .respondContent(task, this.responses[task.id])
        .subscribe(
          (res: any) => {
            task.isLoading = false;
            delete this.responses[task.id];
            const i = this.tasks.indexOf(task);
            this.tasks.splice(i, 1);
          },
          (err: any) => {
            task.isLoading = false;
            console.log(err);
          }
        );
    }
  }

  numberOfTasksToClose() {
    let total = 0;
    for (const key of Object.keys(this.responses)) {
      if (
        this.checkValidNumberInputValue(
          this.responses[key]['Temperatura (ºC):'] || this.responses[key]['Temperatura Actual (ºC):'] 
        )
      )
        total++;
    }
    return total;
  }

  async closeAllHandler() {
    console.log('I am here');
    const wrongTemps =
      this.tasks.filter((t) => this.isTemperatureOutOfBounds(t)).length || 0;

    if (wrongTemps > 0) {
      const taskModal = await this.modalCtrl.create({
        component: PromptModal,
        componentProps: {
          title: translate('INVALID_TEMPERATURE_TITLE', this.translator),
          text:
            `${wrongTemps} temperatura(s) fora dos parâmetros.\n` +
            translate('INVALID_ARE_YOU_SURE', this.translator),
        },
      });
      await taskModal.present();
      const { data } = await taskModal.onDidDismiss();
      if (data) {
        this.closeAllTasks();
      }
    } else {
      this.closeAllTasks();
    }
  }

  closeAllTasks() {
    console.log('why is it blowing up ?');
    console.log(this.tasks);
    for (const task of this.tasks) {
      const response = this.responses[task.id];
      if (
        this.checkValidNumberInputValue(
          response['Temperatura (ºC):'] || response['Temperatura Actual (ºC):']
        )
      ) {
        this.customTaskService.respondContent(task, response).subscribe(
          (res: any) => {
            task.isLoading = false;
            delete this.responses[task.id];
            const i = this.tasks.indexOf(task);
            if (i !== -1) {
              this.tasks.splice(i, 1);
            }
          },
          (err: any) => {
            task.isLoading = false;
            console.log(err);
          }
        );
      }
    }
  }

  createTemperatureOutOfBoundsModal() {
    return this.modalCtrl.create({
      component: PromptModal,
      componentProps: {
        title: translate('INVALID_TEMPERATURE_TITLE', this.translator),
        text: translate('INVALID_ARE_YOU_SURE', this.translator),
      },
    });
  }

  isTemperatureOutOfBounds(task: Task) {
    if (
      task.type === 'singleTemperature' ||
      task.type === 'singleTemperatureEs' ||
      task.type === 'greenSingleTemperature'
    ) {
      return (
        !this.responses[task.id]['Máquina desligada?'] &&
        (myParseFloat(this.responses[task.id]['Temperatura (ºC):']) >
          myParseFloat(task.okRangeMax ?? 0) ||
          myParseFloat(this.responses[task.id]['Temperatura (ºC):']) <
            myParseFloat(task.okRangeMin ?? 0))
      );
    }
    if (task.type === 'serunionSingleTemperature') {
      console.log("Temperatura Actual (ºC):");
      return (
        
        !this.responses[task.id]['Máquina desligada?'] &&
        (myParseFloat(this.responses[task.id]['Temperatura Actual (ºC):']) >
          myParseFloat(task.okRangeMax ?? 0) ||
          myParseFloat(this.responses[task.id]['Temperatura Actual (ºC):']) <
            myParseFloat(task.okRangeMin ?? 0))
      );
    }
    return false;
  }

  checkValidNumberInputValue(inputValue: string): boolean {
    return inputValue !== null && inputValue !== undefined && inputValue !== '';
  }

  getTemperatureField(task: Task): string {
    return task.type === 'serunionSingleTemperature' ? 'Temperatura Actual (ºC):' : 'Temperatura (ºC):';
  }
  

  goBack() {
    this.navCtrl.back();
  }
}
