import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-developer-notes",
  templateUrl: "developer-notes.modal.html",
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeveloperNotesModal implements OnInit {
  @Input() notes: string | undefined;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    // Default message in case notes are not provided
    this.notes = this.notes || "Ao haver novidades referentes à aplicação poderão ser visualizadas aqui.";
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
