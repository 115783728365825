import moment from 'moment';
import { StoreAuthInfo, UserInfo } from './authentication-info';
import { Store } from './store';
import { Batch } from './batch';
import { arrayContains } from '../../utils/array.utils';
import {
  addHours,
  addMinutes,
  getHours,
  getMinutes,
  subDays,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  endOfDay,
  isAfter,
  isSameDay,
  set,
  isBefore,
  parseISO,
  startOfDay,
} from 'date-fns';

export interface Product {
  id: string; // Unique identifier for the product
  name: string; // Name of the product
  supplierName: string; // Name of the supplier
  supplierId?: string;
  family?: string; // Optional family category for the product
  barcode: {
    plu?: string; // Optional Price Look-Up code
    itm8?: string; // Optional internal item code
    value?: string; // Optional barcode value
    plus?: string[]; // Optional array of PLU codes
    itm8s?: string[]; // Optional array of internal item codes
    reference?: string; // Optional reference value
  };
  storeId: string; // Identifier for the store associated with the product
  batches: Batch[]; // Array of batches associated with the product
  hasTechnicalSheet?: boolean; // Optional flag indicating if the product has a technical sheet
  users?: string[]; // Optional array of user identifiers associated with the product
  createdInStore?: boolean; // Optional flag indicating if the product was created in the store
  inactive?: boolean; // Optional flag indicating if the product is inactive
  brand?: string; // Optional brand name of the product
  expirationType?: string; // Optional type of product expiration
  secondaryExpirationHoursSameAsPrimary?: boolean; // Optional flag for secondary expiration hours
  secondaryExpirationHours?: number; // Optional number of hours until secondary expiration
  expirationHoursAfterOpening?: number; // Optional number of hours until expiration after opening
  unfreezingTime?: number; // Optional unfreezing time
  preparationTime?: number;
  noExpiration?: boolean; // Optional flag for no expiration
  origin?: string;
  usersIgnoringValidities?: string[];
  [key: string]: any;
  version?: string;
  ingredientsIds?: string[];
  isThermalShock?: boolean;
  isPreparedInStore?: boolean;
}

export interface FabricatedProduct extends Product {
  hasTechnicalSheet: boolean;
  technicalSheet: { [key: string]: any };
}

export function findBatchInProduct(
  product: Product,
  batch: { lot?: string; expirationDate?: string }
): Batch | undefined {
  if (product && product.batches)
    return product.batches.find(
      (b) => b.lot === batch.lot && b.expirationDate === batch.expirationDate
    );
  return undefined;
}

export function isProductExpiringBeforeEndOfDay(date: string | Date): boolean {
  if (!date) return false;

  const expirationDate = new Date(date);
  const now = new Date();

  // Set to end of today
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);

  return expirationDate <= endOfToday;
}

export function isProductExpiringInLessThan4Days(date: string | Date): boolean {
  if (!date) return false;
  const nextExpirationDateMomentMinus4Days = moment(date).subtract(4, 'days');
  return moment().isAfter(nextExpirationDateMomentMinus4Days);
}

export function productContainsExpirationDate(
  product: any,
  date: string | Date
): boolean {
  if (!product || !date) throw new Error();
  if (arrayContains(product.nextExpirationDates, date)) return true;
  if (arrayContains(product.processedExpirationDates, date)) return true;
  return false;
}

export function productExpirationDateAlreadyProcessed(
  product: any,
  date: string | Date
): boolean {
  if (!product || !date) throw new Error();
  return arrayContains(product.processedExpirationDates, date);
}

export function getProductDesignation(product: any): string {
  return `${product.name}${product.brand ? ' ' + product.brand : ''}`;
}

export const PRODUCT_EXPIRATION_TYPES = [
  'Refrigerado',
  'Preparado',
  'Com processo de descongelação',
  'Congelados',
  'Outros',
];

export function productHasBarcode(p: Product, barcodeValue: string): boolean {
  if (!p || !p.barcode || !barcodeValue) return false;
  if (p.barcode.value === barcodeValue) return true;
  if (p.barcode.itm8 === barcodeValue) return true;
  if (p.barcode.reference === barcodeValue) return true;
  if (p.barcode.plu && pluMatches(p.barcode.plu, barcodeValue)) return true;
  if (p.barcode.itm8s && p.barcode.itm8s.includes(barcodeValue)) return true;
  if (
    p.barcode.plus &&
    p.barcode.plus.some((plu) => pluMatches(plu, barcodeValue))
  )
    return true;
  return false;
}

export function searchMatchesProductBarcode(
  p: Product,
  barcodeValue: string
): boolean {
  if (!p || !p.barcode || !barcodeValue) return false;
  if (p.barcode.value && p.barcode.value.includes(barcodeValue)) return true;
  if (p.barcode.itm8 && p.barcode.itm8.includes(barcodeValue)) return true;
  if (p.barcode.reference && p.barcode.reference.includes(barcodeValue))
    return true;
  if (p.barcode.plu && searchMatchesPlu(p.barcode.plu, barcodeValue))
    return true;
  if (
    p.barcode.itm8s &&
    p.barcode.itm8s.some((itm) => itm && itm.includes(barcodeValue))
  )
    return true;
  if (
    p.barcode.plus &&
    p.barcode.plus.some((plu) => searchMatchesPlu(plu, barcodeValue))
  )
    return true;
  return false;
}

export function productHasBarcodeOld(p: Product, barcodeValue: any): boolean {
  if (p.barcode && p.barcode.itm8 && p.barcode.itm8 === barcodeValue.text) {
    return true;
  }
  if (p.barcode && p.barcode.value) {
    for (let i = 0; i < p.barcode.value.length; ++i) {
      if (p.barcode.value[i].toString() === barcodeValue.text) return true;
      if (p.barcode.value[i].length < 13) {
        if (
          p.barcode.value[i].toString() === barcodeValue.text.substring(0, 7)
        ) {
          return true;
        }
      }
      if (p.barcode.value[i].toString().substring(7, 13) === '000000') {
        if (
          p.barcode.value[i].toString().substring(0, 7) ===
          barcodeValue.text.substring(0, 7)
        ) {
          return true;
        }
      }
    }
    if (p.barcode.value.toString() === barcodeValue.text) return true;
    if (p.barcode.value.length < 13) {
      if (p.barcode.value.toString() === barcodeValue.text.substring(0, 7)) {
        return true;
      }
    }
    if (p.barcode.value.toString().substring(7, 13) === '000000') {
      if (
        p.barcode.value.toString().substring(0, 7) ===
        barcodeValue.text.substring(0, 7)
      ) {
        return true;
      }
    }
  }
  return false;
}

function pluMatches(plu: string | null, barcodeValue: string | null): boolean {
  if (!plu || !barcodeValue) return false; // Verifica valores nulos ou indefinidos
  return plu.substring(0, 7) === barcodeValue.substring(0, 7);
}


function searchMatchesPlu(plu: string, barcodeValue: string): boolean {
  return !!(plu && plu.substring(0, 7).includes(barcodeValue.substring(0, 7)));
}

export function isCommonlyUsedProductInSection(
  ean: string,
  store: Store,
  sectionName: string
): boolean {
  if (!ean || !store || !sectionName) return false;
  if (!store.modules || !store.modules.commonlyUsedProductsEans) return false;
  if (!store.modules.commonlyUsedProductsEans[sectionName]) return false;
  return store.modules.commonlyUsedProductsEans[sectionName].includes(ean);
}

export function doesTechnicalSheetBelongsToUser(
  product: Product,
  user: UserInfo
): boolean {
  if (!product || !user) return false;
  if (!product.users) return true;
  return product.users.includes(user.id);
}

export function isProductFabricated(product: Product): boolean {
  return product.hasTechnicalSheet ?? false;
}

export function productMatchesSearchTermNormalized(
  p: any,
  search: string
): boolean {
  if (!p || !search) return false; // Verificação de null ou undefined

  const searchNormalized = search
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  if (searchMatchesProductBarcode(p, searchNormalized)) {
    return true;
  }

  if (
    p.brand &&
    typeof p.brand === 'string' &&
    p.brand.toLowerCase().includes(searchNormalized.toLowerCase())
  ) {
    return true;
  }

  if (!p.name || typeof p.name !== 'string') return false;

  const nameNormalized = p.name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return nameNormalized.toLowerCase().includes(searchNormalized.toLowerCase());
}

/**
 * Calculates the secondary expiration date based on the preparation date,
 * product details, batch information, and timezone.
 *
 * @param preparationDate - The preparation date as a Unix timestamp (milliseconds since epoch).
 * @param product - The product details.
 * @param batch - The batch details.
 * @returns The secondary expiration date as a Date object
 */
export function calculateExpirationDate(
  preparationDate: Date,
  primaryExpirationDate: Date | null,
  product: Product
): Date | null {
  if (product.noExpiration && !primaryExpirationDate) {
    return null;
  }
  // Start with the preparation date
  let secondaryExpirationDate = new Date(preparationDate);

  // Add secondary expiration hours
  secondaryExpirationDate = addHours(
    secondaryExpirationDate,
    product.secondaryExpirationHours || 0
  );

  // If there is unfreezing time, add it to the expiration date
  if (product.unfreezingTime) {
    secondaryExpirationDate = addHours(
      secondaryExpirationDate,
      product.unfreezingTime
    );
  }

  // If there is preparation time, add it to the expiration date
  if (product.preparationTime) {
    secondaryExpirationDate = addMinutes(
      secondaryExpirationDate,
      product.preparationTime
    );
  }

  // If there's no primary expiration date in the batch, return the rounded secondary expiration date
  if (!primaryExpirationDate) {
    return roundExpirationDate(secondaryExpirationDate, 15); // Adjust interval as needed
  }

  // Set primary expiration to the end of the day (23:59:59)
  const primaryExpirationEndOfDay = endOfDay(primaryExpirationDate);

  // Get the current date
  const currentDate = new Date();

  // Check if the primary expiration date is today
  if (isSameDay(primaryExpirationEndOfDay, currentDate)) {
    // Return the primary expiration date (today at 23:59:59)
    return primaryExpirationEndOfDay;
  }

  // If the secondary expiration hours are the same as the primary expiration, return the primary expiration date
  if (product.secondaryExpirationHoursSameAsPrimary) {
    return primaryExpirationEndOfDay;
  }

  // If the secondary expiration date is after the primary expiration date, return the primary expiration date
  if (isAfter(secondaryExpirationDate, primaryExpirationEndOfDay)) {
    return primaryExpirationEndOfDay;
  }

  // Otherwise, return the rounded secondary expiration date
  return roundExpirationDate(secondaryExpirationDate, 15); // Adjust interval as needed
}

export function getLastSecondaryExpirationDate(product: Product): Date | null {
  const lastBatch = getProductLastBatch(product);
  if (!lastBatch || !lastBatch.expirationDate) return null;
  return new Date(lastBatch.expirationDate);
}

/**
 * Rounds the expiration date to the nearest 15-minute interval.
 *
 * Special Rules:
 * - If the time is exactly 00:00, it sets the time to 23:59 of the previous day.
 * - Otherwise, it rounds to the nearest 15 minutes based on the minutes.
 *
 * @param expirationDate - The date to round.
 * @returns The rounded Date object.
 */
export function roundExpirationDateTo15Minutes(expirationDate: Date): Date {
  return roundExpirationDate(expirationDate, 15);
}

/**
 * Rounds the expiration date to the nearest specified minute interval.
 *
 * Special Rules:
 * - If the time is exactly 00:00, it sets the time to 23:59 of the previous day.
 * - Otherwise, it rounds to the nearest interval based on the minutes.
 *
 * @param expirationDate - The date to round.
 * @param interval - The minute interval to round to (e.g., 15 for 15 minutes).
 * @returns The rounded Date object.
 */
export function roundExpirationDate(
  expirationDate: Date,
  interval: number = 15
): Date {
  let roundedDate = new Date(expirationDate);

  const hours = getHours(roundedDate);
  const minutes = getMinutes(roundedDate);

  if (hours < 10 || (hours === 0 && minutes === 0)) {
    // If exactly 00:00, set to 23:59 of the previous day
    roundedDate = subDays(roundedDate, 1);
    roundedDate = setHours(roundedDate, 23);
    roundedDate = setMinutes(roundedDate, 59);
    roundedDate = setSeconds(roundedDate, 0);
    roundedDate = setMilliseconds(roundedDate, 0);
  } else {
    // Calculate how many intervals have passed in the current hour
    const totalMinutes = hours * 60 + minutes;
    const roundedTotalMinutes = Math.floor(totalMinutes / interval) * interval;

    const newHours = Math.floor(roundedTotalMinutes / 60);
    const newMinutes = roundedTotalMinutes % 60;

    roundedDate = setHours(roundedDate, newHours);
    roundedDate = setMinutes(roundedDate, newMinutes);
    roundedDate = setSeconds(roundedDate, 0);
    roundedDate = setMilliseconds(roundedDate, 0);
  }

  return roundedDate;
}

export function roundUpDate(date: Date): Date {
  const minutes = date.getMinutes();

  let roundedDate: Date;

  if (minutes === 0) {
    // Keep the same hour and minute, set seconds and milliseconds to zero
    roundedDate = set(date, { seconds: 0, milliseconds: 0 });
  } else if (minutes <= 15) {
    // Round up to 15 minutes
    roundedDate = set(date, {
      minutes: 15,
      seconds: 0,
      milliseconds: 0,
    });
  } else if (minutes <= 30) {
    // Round up to 30 minutes
    roundedDate = set(date, {
      minutes: 30,
      seconds: 0,
      milliseconds: 0,
    });
  } else if (minutes <= 45) {
    // Round up to 45 minutes
    roundedDate = set(date, {
      minutes: 45,
      seconds: 0,
      milliseconds: 0,
    });
  } else {
    // Round up to the next hour
    const nextHour = addHours(date, 1);
    roundedDate = set(nextHour, { minutes: 0, seconds: 0, milliseconds: 0 });
  }

  return roundedDate;
}

export function getProductLastBatch(p: Product): Batch | null {
  if (p.batches && p.batches[0]) return p.batches[0];
  return null;
}

export function calculateUnfrozenDate(
  preparationDate: Date,
  p: Product
): Date | null {
  if (p.preparationTime) {
    return roundUpDate(addMinutes(preparationDate, p.preparationTime || 0));
  } else if (p.unfreezingTime) {
    return roundUpDate(addHours(preparationDate, p.unfreezingTime || 0));
  } else {
    return null;
  }
}

export function getLastBatchInformation(product: any): Batch | null {
  const lastBatch = getProductLastBatch(product);

  if (!lastBatch) {
    return null;
  }

  const { primaryExpirationDate } = lastBatch;

  if (primaryExpirationDate) {
    const expirationDate = parseISO(primaryExpirationDate);
    const today = startOfDay(new Date());

    if (isBefore(expirationDate, today)) {
      return null;
    }
  }

  return lastBatch;
}
