import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PhotoHistoryModalComponent } from './photo.history-modal';

@Component({
  selector: 'app-photo-history-button',
  templateUrl: './photo.history-button.html',
})
export class PhotoHistoryButtonComponent {

  @Input() picturesIds: any;
  @Input() task: any;

  constructor(private modalCtrl: ModalController) { }

  async pressCamera() {
    const modal = await this.modalCtrl.create({
      component: PhotoHistoryModalComponent,
      componentProps: { photos: this.picturesIds }
    });

    return await modal.present();
  }
}
