import { Store } from "./store";

export interface StoreAuthInfo {
  success: boolean;
  users: UserInfo[];
}

export interface AuthenticationInfo {
  success: boolean;
  token?: string;
  user?: UserInfo;
  message?: string;
}

export interface UserInfo {
  id: string;
  username: string;
  role: string;
  companyId?: string;
  storeId?: string;
  storeIds?: string[];
  hasNoPassword?: boolean;
  style: {
    borderColor?: string;
    backgroundColor?: string;
    imageUrl?: string;
  }
}

export function getTargetUser(store: Store | undefined, currentUser: UserInfo): string[] {
  if (store?.modules?.privateTasks) return [currentUser.id];
  return [];
}

export function getIMSectionFromUsername(username: string): string {
  const sectionRegex = /IM\s(\d+)\s(.+)/;
  const matches = username.match(sectionRegex);
  if (matches && matches.length === 3) {
    return matches[2];
  }
  return '';
}
