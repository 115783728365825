import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import cuid from 'cuid';
import { TranslateService } from "@ngx-translate/core";
import { checkValidity } from "../../utils/conditional-modal-creator/conditional-modal-creator";
import { translate } from "../../utils/translate.utils";
import { UserInfo } from '../entities/authentication-info';
import { Task } from '../entities/tasks/task';
import { AuthenticationService } from '../services/authentication';
import { TasksService } from '../services/tasks.service';
import { FieldsService } from '../services/fields.service';
import { CustomTaskTypesService } from '../services/custom-task-types.service';
import { CreateTasksConfigurationService } from '../services/non-programmed-tasks-configuration.service';
import { PromptModal } from '../components/prompt/prompt.modal';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.modal.html',
  styleUrls: ['../../theme/modals.scss'],

})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateTaskModal implements OnInit {

  userInfo!: UserInfo;  // Using definite assignment assertion
  task!: Task;  // Using definite assignment assertion
  forms: any[] = [];
  types: any[] = [];
  isCleaningTask = false;

  isStartDateAfterOrTheSameAsEndDateValidator = {
    condition: () => this.isStartDateAfterOrTheSameAsEndDate(),
    modalCreator: () => this.createEndDateMustBeAfterStartDateModal()
  };

  isEndDateBeforeOrTheSameAsActualDateValidator = {
    condition: () => this.isEndDateBeforeOrTheSameAsActualDate(),
    modalCreator: () => this.createEndDateMustBeAfterActualDateModal()
  };

  isVisualizationDateBeforeEndDateValidator = {
    condition: () => this.isVisualizationDateBeforeEndDate(),
    modalCreator: () => this.createVisualizationDateBeforeEndDateModal()
  };

  isTypeSelected = {
    condition: () => !this.task.type,
    modalCreator: () => this.createIsTypeSelectedModal()
  };

  isFormSelected = {
    condition: () => !this.task.formId,
    modalCreator: () => this.createIsFormSelectedModal()
  };

  isTaskTitleNotNullValidator = {
    condition: () => this.isTaskTitleNotNull(),
    modalCreator: () => this.createIsTaskTitleNotNullValidator()
  };

  constructor(
    private modalController: ModalController,
    private authenticationService: AuthenticationService,
    private tasksService: TasksService,
    private fieldsService: FieldsService,
    private customTaskTypesService: CustomTaskTypesService,
    private translator: TranslateService,
    private nonProgrammedTasksConfigurationService: CreateTasksConfigurationService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.authenticationService.getUserInfo() as UserInfo;
    const storeId = this.userInfo?.storeId || '';  // Ensure storeId is not undefined
    this.task = {
      id: cuid(),
      type: '',
      formId: '',
      title: '',
      startDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
      visualizationDate: moment().add(1, 'hour').add(1, 'year').format('YYYY-MM-DDTHH:mm:ss'),
      nonConformities: [],
      causes: [],
      corrections: [],
      periodicity: 'Única',
      target: { storeId, userIds: [] },
      documentsIds: [],
      generationType: "nonProgrammed"
    };
    this.forms = this.fieldsService.getForms();
    let allowedTypes: any[] = [];
    const config = this.nonProgrammedTasksConfigurationService.getConfigurations();
    if (config) {
      allowedTypes = config.creatableTaskTypes;
    }
    this.types = this.customTaskTypesService.getCustomTaskTypes().filter(t => allowedTypes.includes(t.type));
  }

  close() {
    this.modalController.dismiss();
  }

  async pressSubmit() {
    const isValid = await checkValidity([
      this.isTaskTitleNotNullValidator,
      this.isStartDateAfterOrTheSameAsEndDateValidator,
      this.isEndDateBeforeOrTheSameAsActualDateValidator,
      this.isVisualizationDateBeforeEndDateValidator,
      this.isFormSelected,
      this.isTypeSelected
    ], this.modalController);
    if (isValid) {
      this.successfullySubmit();
    }
  }

  successfullySubmit() {
    if (this.isCleaningTask) {
      this.task.documentsIds?.push('CleaningDoc');
    }
    this.task.startDate = moment(this.task.startDate).valueOf();
    this.task.endDate = moment(this.task.endDate).valueOf();
    this.task.visualizationDate = moment(this.task.visualizationDate).valueOf();
    this.tasksService.newTask(this.task, this.authenticationService.getAuthInfo()).subscribe(() => {
      this.modalController.dismiss(this.task);
    }, err => {
      this.modalController.dismiss(this.task);
      console.log(err);
    });
  }

  isStartDateAfterOrTheSameAsEndDate() {
    return moment(this.task.endDate).valueOf() <= moment(this.task.startDate).valueOf();
  }

  isEndDateBeforeOrTheSameAsActualDate() {
    return moment(this.task.endDate).valueOf() <= moment.now();
  }

  createEndDateMustBeAfterStartDateModal() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('INVALID_DATES_ORDER', this.translator),
        hasYesButton: false,
        noText: translate('CANCEL', this.translator)
      }
    });
  }

  createEndDateMustBeAfterActualDateModal() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('INVALID_END_DATE_TITLE', this.translator),
        text: translate('INVALID_END_DATE_TEXT', this.translator)
      }
    });
  }

  isVisualizationDateBeforeEndDate() {
    return moment(this.task.visualizationDate).valueOf() <= moment(this.task.endDate).valueOf();
  }

  createVisualizationDateBeforeEndDateModal() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('INVALID_VISUALIZATION_DATE_TITLE', this.translator),
        text: translate('INVALID_VISUALIZATION_DATE_TEXT', this.translator),
        hasYesButton: false,
        noText: translate('CANCEL', this.translator)
      }
    });
  }

  createIsTypeSelectedModal() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('Tipo de tarefa por preencher', this.translator),
        text: translate('É necessário preencher o tipo de tarefa para avançar', this.translator),
        hasYesButton: false,
        noText: translate('CANCEL', this.translator)
      }
    });
  }

  createIsFormSelectedModal() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('Formulário por preencher', this.translator),
        text: translate('É necessário preencher o formulário para avançar', this.translator),
        hasYesButton: false,
        noText: translate('CANCEL', this.translator)
      }
    });
  }

  isTaskTitleNotNull() {
    return this.task.title === '';
  }

  createIsTaskTitleNotNullValidator() {
    return this.modalController.create({
      component: PromptModal,
      componentProps: {
        title: translate('NULL_TASK_TITLE_TITLE', this.translator),
        hasYesButton: false,
        noText: translate('CANCEL', this.translator)
      }
    });
  }
}
