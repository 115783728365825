import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { KeyValuePipe } from "../../pipes/keyvalue-pipe";
import { ResponseField } from "../../../entities/tasks/task"; // Update the path according to your project structure

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "date-input",
  template: `
    <div class="form-group">
      <label>{{ getTranslation(responseField) }} {{ responseField.required ? "*" : "" }}</label>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="value"
        [pattern]="getPattern()"
        (ngModelChange)="valueChange.emit($event)"
        [formControl]="control"
        (input)="onDateInputChange($event)"
        [placeholder]="getPlaceholder()"
        inputmode="numeric"
        [readonly]="!!responseField.locked || locked"
      />
      <div *ngIf="control.touched && control.errors">
        <div *ngFor="let error of control.errors | keyvalue">
          <p style="color: red" *ngIf="error.key === 'required'">
            Este campo é obrigatório
          </p>
        </div>
      </div>
    </div>
  `,
  providers: [KeyValuePipe],
})
export class DateInputComponent implements OnInit {
  @Input() responseField!: ResponseField;
  @Input() value: string = "";
  @Output() valueChange = new EventEmitter<string>();
  @Input() getTranslation: (responseField: ResponseField) => string = (responseField) => {
    const lang = this.translator.currentLang;
    if (lang === "pt") {
      return responseField.name;
    } else if (lang === "es") {
      return responseField.nameEs || responseField.name;
    }
    return responseField.name;
  };
  @Input() locked: boolean = false;

  control!: FormControl;

  constructor(private translator: TranslateService) {}

  ngOnInit() {
    const validators = [];
    if (this.responseField && this.responseField.responseValidation) {
      if (this.responseField.responseValidation.required) {
        validators.push(Validators.required);
      }
    }
    this.control = new FormControl(
      { value: this.value, disabled: !!this.responseField.locked || this.locked },
      Validators.compose(validators)
    );
  }

  getPattern() {
    return this.responseField.format === "dd/mm/yyyy" ? "\\d{2}-\\d{2}-\\d{4}" : "\\d{4}-\\d{2}-\\d{2}";
  }

  getPlaceholder() {
    return this.responseField.format === "dd/mm/yyyy" ? "dd-mm-yyyy" : "yyyy-mm-dd";
  }

  onDateInputChange(event: any): boolean {
    const input = event.target.value;
    let formattedInput = input.replace(/\D/g, ""); // Remove any non-digit characters

    if (this.responseField.format === "dd/mm/yyyy") {
      if (formattedInput.length > 8) {
        formattedInput = formattedInput.substring(0, 8);
      }
      if (formattedInput.length > 6) {
        formattedInput = formattedInput.substring(0, 2) + "-" + formattedInput.substring(2, 4) + "-" + formattedInput.substring(4);
      } else if (formattedInput.length > 4) {
        formattedInput = formattedInput.substring(0, 2) + "-" + formattedInput.substring(2, 4) + "-" + formattedInput.substring(4);
      }

      const day = parseInt(formattedInput.substr(0, 2), 10);
      const month = parseInt(formattedInput.substr(3, 2), 10);
      const year = parseInt(formattedInput.substr(6, 4), 10);

      if (month > 12) {
        formattedInput = formattedInput.substring(0, 3) + "12" + formattedInput.substring(5);
      }

      if (day > 31 || (month === 2 && day > 29) || ([4, 6, 9, 11].indexOf(month) >= 0 && day > 30)) {
        formattedInput = formattedInput.substring(0, 2) + (month === 2 ? "28" : "30") + formattedInput.substring(5);
      }

      formattedInput = formattedInput.substring(0, 10);
    } else {
      if (formattedInput.length > 8) {
        formattedInput = formattedInput.substring(0, 8);
      }
      if (formattedInput.length > 6) {
        formattedInput = formattedInput.substring(0, 4) + "-" + formattedInput.substring(4, 6) + "-" + formattedInput.substring(6);
      } else if (formattedInput.length > 4) {
        formattedInput = formattedInput.substring(0, 4) + "-" + formattedInput.substring(4);
      }

      const year = parseInt(formattedInput.substr(0, 4), 10);
      const month = parseInt(formattedInput.substr(5, 2), 10);
      const day = parseInt(formattedInput.substr(8, 2), 10);

      if (month > 12) {
        formattedInput = formattedInput.substring(0, 5) + "12" + formattedInput.substring(7);
      }

      if (day > 31 || (month === 2 && day > 29) || ([4, 6, 9, 11].indexOf(month) >= 0 && day > 30)) {
        formattedInput = formattedInput.substring(0, 8) + (month === 2 ? "28" : "30");
      }

      formattedInput = formattedInput.substring(0, 10);
    }

    this.value = formattedInput;
    this.control.setValue(formattedInput);
    this.valueChange.emit(this.value);
    return true;
  }
}
