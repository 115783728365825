<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">Close</ion-button>
    </ion-buttons>
    <ion-title>{{ 'Lista de lotes registados como matéria prima' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container scroll-content" #scrollContent>
    <div class="modalItem">
      <div class="modalHeader">
        <div class="modalTitle">
          <h3>{{ 'Lista de lotes registados como matéria prima' | translate }}</h3>
          <img src="assets/img/close.svg" (click)="close()" />
        </div>
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          <div class="form-group">
            <label>{{ 'Pesquisa:' | translate }}</label>
            <input class="form-control" type="text" [(ngModel)]="searchValue" placeholder="Código de barras ou nome" [formControl]="searchControl" />
          </div>
          <ion-content style="height: 60vh; overflow-y: scroll;">
            <ion-list>
              <ion-item *ngFor="let batch of searchResult" (click)="pressBatch(batch)">
                <p>Lote: {{ batch.lot }}</p>
                <p>Validade: {{ batch.expirationDate | date: 'yyyy-MM-dd' }}</p>
                <p>Data de registo: {{ batch.registrationDate | date: 'yyyy-MM-dd' }}</p>
                <ion-icon name="arrow-dropright" slot="end"></ion-icon>
              </ion-item>
              <ion-item *ngIf="searchResult.length === 0">
                {{ 'Sem resultados' | translate }}
              </ion-item>
            </ion-list>
          </ion-content>          
        </div>
        <div class="modalActions">
          <div class="actionsLeft">
            <!-- <button class="btn btn-cancel light-grey" (click)="pressCreateNew()">{{'Criar novo produto'}}</button> -->
          </div>
          <div class="actionsRight">
            <button class="btn btn-cancel light-grey" (click)="close()">{{ 'CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
