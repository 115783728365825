import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { Browser } from '@capacitor/browser';
import { DeveloperNotesModal } from '../developer-notes/developer-notes.modal';
import { PrinterConfigModal } from '../modals/printer-config.modal';
import { StoresService } from '../../services/stores.service';
import { AuthenticationService } from '../../services/authentication';
import { SyncingService } from '../../services/syncing';
import { EnvironmentService } from '../../services/environment.service';
import { SummaryService } from 'src/app/services/summary.service';

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.html',
  styleUrls: ['./menu.scss'],
})
export class MenuComponent {
  // Renamed to MenuComponent

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public translate: TranslateService,
    private storage: Storage,
    protected storesService: StoresService,
    private modalCtrl: ModalController,
    private syncingService: SyncingService,
    private navCtrl: NavController,
    protected envService: EnvironmentService,
    private summaryService:SummaryService
  ) {}

  switchToManuals() {
    if (typeof this.storesService.store.manuals === 'string') {
      Browser.open({ url: this.storesService.store.manuals });
    } else {
      this.close().then(() => {
        this.router.navigate(['/manuals']);
      });
    }
  }

  switchToReports() {
    this.close().then(() => {
      this.router.navigate(['/tabs/documents']);
    });
  }

  switchToDashboard() {
    this.close().then(() => {
      this.router.navigate(['/tabs/dashboard']);
    });
  }

  switchToTags() {
    this.close().then(() => {
      this.router.navigate(['/tags']);
    });
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  async exit() {
    await this.syncingService.clearSyncInterval();
    this.authenticationService.logout();
    await this.storage.remove('credentials');
    await this.close();

    this.navCtrl.navigateRoot(['/store-login']);
  }

  async showDeveloperNotes() {
    const notesModal = await this.modalCtrl.create({
      component: DeveloperNotesModal,
      componentProps: {
        notes:
          this.storesService.store.foNotes ||
          'Ao haver novidades referentes à aplicação poderão ser visualizadas aqui.',
      },
    });
    await notesModal.present();
  }

  async showSummary(){
    return this.summaryService.openDefault();
  }


  async showPrinterConfig() {
    const modal = await this.modalCtrl.create({
      component: PrinterConfigModal,
    });
    await modal.present();
  }
}
